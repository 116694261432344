import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { strings } from '../../../constants/localization';
import '../styles.scss';
import {
  setCurrentManagerCompany,
  getPublicCompanyCategories,
} from '../../../actions/companyActions';
import CompanyMasterData from './CompanyMasterData';
import { brandConfig } from '../../../config';
import ClinicIndexSection from '../../companies-page/company-details/ClinicIndex/ClinicIndexSection';
import { isManager } from '../../../constants/utils';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import EntityStatus from '../../common/EntityStatus/EntityStatus ';

const CompanyDetails = ({ company }) => {
  const dispatch = useDispatch();
  const [currentCompany, setCurrentCompany] = useState(null);
  const currentUser = useSelector((state) => state.user.user);
  const companyLoading = useSelector((state) => state.company.companyLoading);

  useEffect(() => {
    if (company) {
      setCurrentCompany(company);
    }
  }, [company]);

  // when component mounts get the public company categories
  useEffect(() => {
    dispatch(getPublicCompanyCategories());
  }, [dispatch]);

  return (
    <>
      {companyLoading && <LoadingMessage />}
      <Scrollbars>
        {currentCompany && (
          <>
            {isManager(currentUser) && (
              <button
                type='button'
                className='btn btn-white go-back-to-list'
                onClick={() => dispatch(setCurrentManagerCompany(undefined))}
              >
                <i className='lnil lnil-chevron-left'></i>
                <span>{strings.goToClinicsList}</span>
              </button>
            )}

            <div className='main-content'>
              <Row>
                <Col xs='12' lg='12' className='centered'>
                  <CompanyMasterData companyDetails={currentCompany} />
                </Col>
              </Row>
              <Row>
                <Col xs='12' lg='12' className='centered'>
                  <EntityStatus
                    entityDetails={currentCompany}
                    fieldClass='CompanyStatusComponent'
                    settingsPage
                  />
                </Col>
              </Row>

              {brandConfig.appName === 'Sundhed+' && (
                <Row>
                  <Col xs='12' lg='12' className='centered'>
                    <ClinicIndexSection companyDetails={currentCompany} />
                  </Col>
                </Row>
              )}
            </div>
          </>
        )}
      </Scrollbars>
    </>
  );
};

export default CompanyDetails;
