import React from 'react';
import { Button } from 'reactstrap';
import { Sundhedplus } from '../../../config';

import './style.scss';
import { strings } from '../../../constants/localization';

const Matches = ({ isMultiple }) => (
  <div className='MultipleMatches'>
    <div className='MultipleMatches_content'>
      <div className='title'>
        {isMultiple ? strings.multipleMatches : strings.noMatch}
      </div>
      <div className='note'>
        {isMultiple ? strings.multipleMatchesNote : strings.noMatchNote}
      </div>
      <div className='button-area'>
        <Button tag='a' href={Sundhedplus.mainPageUrl} target='_blank'>
          {strings.openDAM}
        </Button>
      </div>
    </div>
  </div>
);

export default Matches;
