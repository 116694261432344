import React from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Row, Col,
  } from 'reactstrap';
import { moment, momentFormatLong } from '../../../../constants';
import { strings } from '../../../../constants/localization';
import './style.scss';
import EditCreditFacility from './EditCreditFacility';

const CreditFacility = (props) =>  {
  const {createdDate, number} = props.creditDetails;  
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='title-text'>{strings.creditDetailsHeading}</div>
        <div className='credit-details'>
          <Row xs='2'>
            <Col>
              <label>{strings.creditDetailsCreatedDate}</label>
              <span>
                {createdDate && moment(createdDate).format(momentFormatLong).toLowerCase()}
              </span>
            </Col>
            <Col>
              <label>{strings.creditDetailsLatestEval}</label>
              <span>
                {props.creditDetails.renewalDate && moment(props.creditDetails.renewalDate).format(momentFormatLong).toLowerCase()}
              </span>
            </Col>
          </Row>

          <Row xs='2'>
            <Col>
              <label>{strings.creditDetailsNumber}</label>
              <span>{number}</span>
            </Col>
            <Col>
              <label>{strings.creditDetailsLatestChange}</label>
              <span>
                {props.creditDetails.modifiedDate && moment(props.creditDetails.modifiedDate).format(momentFormatLong).toLowerCase()}
              </span>
            </Col>
          </Row>
        </div>

        <div>
          <Row
            className={`tab-row credit-facility-information credit-facility-information-container credit-facility-information-tabs card`}
          >
            <Col xs='12' className='credit-facility-options'>
              <div className='nav-tabs-section'>
                <Nav tabs className='justify-content-left'>
                  <NavItem>
                    <NavLink>{strings.creditDetailsEdit}</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={true}>
                  <TabPane className='active'>
                    <Row>
                      <Col sm='12'>
                        <EditCreditFacility
                          creditDetails={props.creditDetails}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default CreditFacility;