import { Button, Row, Col } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { strings } from '../../../constants/localization';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';

export default function ModalSupportNote({
  modalClass,
  setModalCanNotBeClosed,
  modalCanNotBeClosed,
  setModaIsOpen,

  handleButtonSubmit,
  loading,
}) {
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [partiallyClosedModal, setPartiallyClosedModal] = useState(false);
  const [note, setNote] = useState('');

  const handleInputChange = (event) => {
    const updatedNote = event.target.value;
    setNote(updatedNote);

    if (updatedNote !== '') {
      setFormIsTouched(true);
    } else {
      setFormIsTouched(false);
    }
  };

  useEffect(() => {
    if (formIsTouched) {
      // listening user click outside the modal
      document.addEventListener('click', (e) => {
        if (e.target.className !== 'documentation-note-modal') {
          setPartiallyClosedModal(true);
        }
      });
    }
  }, [modalCanNotBeClosed]);

  return (
    <section className={` document-note-section ${modalClass}`}>
      <div className='wrapper '>
        {!partiallyClosedModal ? (
          <>
            <div>
              <h3 className='modal-title text-center'>
                {strings.documentationNoteModalTitle}
              </h3>
            </div>
            <Row className='detailsArea'>
              <Col xs='12' lg='12'>
                <div className=''>
                  <div className='support-note-editor'>
                    <input
                      type='text'
                      className='support-note-input'
                      placeholder={strings.notePlaceholder}
                      value={note || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className='text-center'>
              {!loading && (
                <Button
                  color='blue'
                  className='button-uppercase'
                  disabled={!formIsTouched}
                  onClick={() => {
                    handleButtonSubmit(note);
                    setButtonLoading(true);
                    setModalCanNotBeClosed(false);
                  }}
                >
                  <i className='lnil lnil-envelope' />
                  {strings.documentationNoteModalButtonSend}
                </Button>
              )}
              {loading && (
                <Button color='blue' disabled className='button-uppercase'>
                  <ButtonLoader />
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <h3 className='modal-title text-center'>
                {strings.documentationNoteModalCloseTitle}
              </h3>
            </div>
            <Row className='detailsArea'>
              <Col xs='12' lg='12'>
                <div className='quill-editor'>
                  <div className='ql-editor pt-0'>{note}</div>
                </div>
              </Col>
            </Row>

            <div className='justify-content-center m-auto'>
              <div className='col-12 mt-auto mb-4'>
                <div className='actions-buttons mb-2 d-flex justify-content-center '>
                  <Button
                    className='btn btn-blue btn-cancel'
                    disabled={buttonLoading}
                    onClick={() => {
                      setPartiallyClosedModal(false);
                    }}
                  >
                    {strings.documentationNoteModalButtonNo}
                  </Button>
                  <Button
                    className='btn btn-blue btn-success'
                    onClick={() => {
                      setButtonLoading(true);
                      setPartiallyClosedModal(false);
                      setModaIsOpen(false);
                      setNote('');
                    }}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? (
                      <ButtonLoader />
                    ) : (
                      strings.documentationNoteModalButtonYes
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
