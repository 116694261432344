import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  getChainLocations,
  getChainAssociatedUsers,
} from '../../../actions/companyChainActions';
import { getCompanyChainLogs } from '../../../actions/logActions';
import { strings } from '../../../constants/localization';
import EntityInformationTabs from '../../common/EntityInformationTabs';
import ListOfCompanyChains from './ListOfCompanyChains/ListOfCompanyChains';
import ListOfAssociatedUsers from './ListOfAssociatedUsers/ListOfAssociatedUsers';
import ChainLogDetails from './ChainLogDetails';

const ChainOptions = (props) => {
  const { id, activeChain } = props;
  const dispatch = useDispatch();

  return (
    <EntityInformationTabs
      id={id}
      otherClass='forChain'
      tabsContent={[
        {
          name: strings.flial_tab_name_filialer,
          getContent: () => (
            <ListOfCompanyChains
              activeChain={activeChain}
              updateLocations={() => dispatch(getChainLocations(id))}
            />
          ),
          show: true,
        },
        {
          name: strings.chainUsers,
          getContent: () => (
            <ListOfAssociatedUsers
              companyChainId={id}
              update={() => dispatch(getChainAssociatedUsers(id))}
            />
          ),
          clickCallback: () => {
            dispatch(getChainAssociatedUsers(id));
          },
          show: true,
        },
        {
          name: strings.logTab,
          getContent: (activeTab) => (
            <ChainLogDetails activeTab={activeTab} activeChain={activeChain} />
          ),
          clickCallback: () => {
            dispatch(getCompanyChainLogs(id));
          },
          show: true,
        },
      ]}
    />
  );
};

ChainOptions.propTypes = {
  id: PropTypes.number,
  chains: PropTypes.array,
  activeChain: PropTypes.object,
};

export default ChainOptions;
