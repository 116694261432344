import React, { useEffect, useState } from 'react';
import { success, error as notifError } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { updateCompanyStatus } from '../../../actions/companyActions';
import {
  updateCompanyChainStatusFromCompany,
  updateCompanyChainStatus,
  updateCompanyStatusInActiveChain,
  setChainLoading,
} from '../../../actions/companyChainActions';
import {
  getLogsforCompanies,
  getCompanyChainLogs,
} from '../../../actions/logActions';
import {
  DEPRECATED,
  REQUIRES_ACTION,
  VIEW_ALL,
  notificationDismissDuration,
  statusArrayForCompanyAndCompanyChain,
} from '../../../constants';
import { strings } from '../../../constants/localization';
import { ROUTE_COMPANY_CHAINS } from '../../../constants/routes';
import { addNotification, isAdmin, isManager } from '../../../constants/utils';
import DropdownList from '../../leads-page/lead-details/DropdownList';
import DentiModal from '../DentiModal';

export default function EntityStatus(props) {
  const [status, setStatus] = useState({});
  const [showModalActivateChain, setShowModalActivateChain] = useState(false);
  const [statusOptionsList, setStatusOptionsList] = useState([]);
  const [showOptions, setShowOptions] = useState([]);

  const [screenSize, setScreenSize] = useState(null);
  const user = useSelector((state) => state.user.user);
  const currentSelectedChain = useSelector(
    (state) => state.companyChain?.activeChain
  );
  const dispatch = useDispatch();

  const title = props.companyChainPage
    ? strings.chainStatus
    : strings.filialStatus;

  useEffect(() => {
    setStatus({
      currentStatus: props.entityDetails.status,
      label: strings[props.entityDetails.status],
    });
  }, [props.entityDetails.status]);

  const updateStatus = (value) => {
    if (props.companyPage || props.settingsPage) {
      if (props.entityDetails.status !== value) {
        dispatch(updateCompanyStatus(props.entityDetails.id, value))
          .then((result) => {
            if (result.status === 200) {
              // Show notification modal if a clinic is set ACTIVE, and set the company chain ACTIVE
              if (
                isAdmin(user) &&
                value === 'ACTIVE' &&
                props.entityDetails.companyChain.status !== 'ACTIVE'
              ) {
                dispatch(
                  updateCompanyChainStatusFromCompany(
                    props.entityDetails?.companyChain.id,
                    value
                  )
                ).then((response) => {
                  if (response?.status === 200) {
                    setShowModalActivateChain(true);
                  }
                });
              }

              dispatch(
                success(
                  addNotification(
                    `${strings.successStatusChange3} ${props.entityDetails.companyName} ${strings.successStatusChange2}`,
                    strings.statusSuccessTitle,
                    notificationDismissDuration
                  )
                )
              );
              // if there's an activeChain related to this selected company, then update also the status in the activeChain->company in redux
              if (isAdmin(user) && currentSelectedChain) {
                dispatch(
                  updateCompanyStatusInActiveChain(
                    props.entityDetails.id,
                    value
                  )
                );
              }
              if (props.companyPage) {
                dispatch(
                  getLogsforCompanies(props.entityDetails.referenceNumber)
                );
              }
            }
          })
          .catch(() => {
            dispatch(
              notifError(
                addNotification(
                  strings.errorStatusChange3 + ' ' + strings.errorStatusChange2,
                  strings.statusErrorTitle,
                  notificationDismissDuration
                )
              )
            );
          });
      }
    }
    if (props.companyChainPage) {
      if (props.entityDetails.status !== value) {
        dispatch(setChainLoading(true));
        dispatch(updateCompanyChainStatus(props.entityDetails.id, value))
          .then((response) => {
            if (response?.status === 200) {
              dispatch(setChainLoading(false));
              dispatch(
                success(
                  addNotification(
                    `${strings.successStatusChange4} ${props.entityDetails.companyChainName} ${strings.successStatusChange2}`,
                    strings.statusSuccessTitle,
                    notificationDismissDuration
                  )
                )
              );
              dispatch(getCompanyChainLogs(props.entityDetails.id));
            }
          })
          .catch(() => {
            dispatch(setChainLoading(false));
            dispatch(
              notifError(
                addNotification(
                  `${strings.errorStatusChange4} ${strings.errorStatusChange2}`,
                  strings.statusErrorTitle,
                  notificationDismissDuration
                )
              )
            );
          });
      }
    }
  };
  useEffect(() => {
    const options = [];
    const exceptions = [
      REQUIRES_ACTION,
      DEPRECATED,
      VIEW_ALL,
      'SMARTLINK',
      'undefined',
      'PASSIVE',
      'DEACTIVE',
    ];
    if (statusArrayForCompanyAndCompanyChain !== undefined) {
      statusArrayForCompanyAndCompanyChain.map((item) => {
        if (exceptions.indexOf(item) < 0) {
          if (!(isManager(user) && item === 'PASSIVE')) {
            const option = {};
            option.value = item;
            option.label = strings[item];
            return options.push(option);
          }
        }
        return null;
      });
    }
    setStatusOptionsList(options);
  }, [props.entityDetails.status, props.companyPage, user]);

  useEffect(() => {
    const resize = () => {
      const windowsSize = window.innerWidth;
      if (windowsSize < 768) {
        setScreenSize(true);
        const newStatusOptionsList = [];
        statusOptionsList.forEach((newStatus) => {
          if (newStatus.value !== 'PASSIVE') {
            newStatusOptionsList.push(newStatus);
          }
        });
        setShowOptions(newStatusOptionsList.reverse());
      } else {
        setScreenSize(false);
        setShowOptions(statusOptionsList);
      }
    };
    window.addEventListener('resize', resize);
    resize();
    return () => window.removeEventListener('resize', resize);
  }, [statusOptionsList]);

  return (
    <>
      <div
        id={props.fieldClass !== undefined ? props.fieldClass : ''}
        className='entity-status-card lead-information custom-card card'
      >
        <div className='wrapper'>
          <div className='descriptionWraper'>
            <div className='iconArea'>
              <i className='lnir lnir-power-switch' />
            </div>
            <div className='titleArea'>{title}</div>
          </div>

          {screenSize ? (
            <DropdownList
              options={showOptions}
              onSelect={updateStatus}
              value={status}
            />
          ) : (
            <>
              <div className='buttonArea'>
                <span className='created'>
                  {status.currentStatus === 'PASSIVE' ? strings.PASSIVE : null}
                </span>
                {showOptions.map((option) => (
                  <Button
                    key={option.value}
                    className={`${
                      option.value === status.currentStatus
                        ? 'ACTIVE btn-blue'
                        : 'btn-light'
                    } brandButton `}
                    onClick={() =>
                      option.value !== status.currentStatus &&
                      updateStatus(option.value)
                    }
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {showModalActivateChain && (
        <DentiModal
          key={1}
          close={() => setShowModalActivateChain(false)}
          modalClass='notification-modal'
          modalClassName='confirm-action'
          closeBtn={false}
        >
          <div>
            <div className='icon'>
              <i className='lnil lnil-checkmark-circle' />
            </div>
            <div>
              <p className='notification-message'>
                {strings.setCompanyChainActiveMessage}
              </p>
              <p>
                <Link
                  to={{
                    pathname: ROUTE_COMPANY_CHAINS,
                    state: { ref: props.entityDetails.companyChain },
                  }}
                >
                  {strings.setCompanyChainActiveLink}
                </Link>
              </p>
            </div>
            <div className='bottom-section action-btn cf'>
              <button
                className='submit-btn btn btn-blue center-block'
                type='button'
                onClick={() => setShowModalActivateChain(false)}
              >
                <span>{strings.setCompanyChainActiveBtn}</span>
              </button>
            </div>
          </div>
        </DentiModal>
      )}
    </>
  );
}
