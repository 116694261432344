import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as FilterIcon } from '../../../resources/icons/filter-icon.svg';
import { ReactComponent as CrossIcon } from '../../../resources/icons/cross-circle.svg';

const InputSearch = ({
  searchBarPlaceholder,
  storedSearchKeyword,
  toggleFilterMenu,
  searchSubmit,
}) => {
  const location = useLocation();
  const history = useHistory();
  const formikRef = useRef(null);

  useEffect(() => {
    // if there is a stored search keyword, set it in the formik field
    if (storedSearchKeyword) {
      formikRef.current.setFieldValue('keyword', storedSearchKeyword);
    }
  }, [storedSearchKeyword]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        keyword: '',
      }}
      onSubmit={(values) => {
        if (values.keyword) {
          searchSubmit({ flag: true, searchInput: values.keyword });
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className='search-filter-group'>
          <button type='submit' className='btn-search'>
            <span className='lnir lnir-search-alt' />
          </button>
          <input
            id='keyword'
            name='keyword'
            placeholder={searchBarPlaceholder}
            value={values.keyword}
            onChange={(evt) => {
              setFieldValue('keyword', evt.target.value);
            }}
            onKeyDown={(e) => {
              // if the user presses backspace or delete key and the input field is empty, clear the search
              const keyword = values.keyword;
              if (e.key === 'Backspace' || e.key === 'Delete') {
                const { selectionStart, selectionEnd } = e.target;
                if (selectionStart === 0 && selectionEnd === keyword.length) {
                  searchSubmit({ flag: false, searchInput: '' });
                }
              }
            }}
          />
          {/* Show clear button if there is a value in the input field */}
          {(values.keyword.trim() || storedSearchKeyword) && (
            <button
              type='button'
              className={`btn-clear ${
                values.keyword !== '' || storedSearchKeyword !== ''
                  ? 'show'
                  : ''
              }`}
              onClick={() => {
                setFieldValue('keyword', '');
                searchSubmit({ flag: false, searchInput: '' });
                if (location && location?.search) {
                  history.replace(location.pathname);
                }
              }}
            >
              <CrossIcon className='icon' />
            </button>
          )}

          <button
            type='button'
            id='filterResultsIcon'
            className='btn-filter'
            onClick={() => toggleFilterMenu()}
          >
            <FilterIcon className='icon' />
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default InputSearch;
