import {
  calculateTotalToBePaidInInterest,
  formatCurrencyDaStandard,
  formatInterestRate,
} from '../../constants/utils';
import { strings } from '../../constants/localization';
import React, { useEffect } from 'react';
import { Button, Label } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  thumbVerticalStyle,
  trackVerticalStyle,
} from '../../constants/styleScrollbar';
import moment from 'moment';
import TermsConditions from '../acceptance/TermsConditions';

interface PaymentDetail {
  monthlyPayment: number;
  apr: number;
  firstPaymentDate: string;
  lastPaymentDate: string;
  duration: number;
  extraPayment: number;
  newRemainingBalance: number;
  interestUntilFirstPayment: number;
}

type AcceptNewLoanAgreementProps = {
  onNextStep: () => void;
  setIsLoading: (value: boolean) => void;
  handlePreviousStep: () => void;
  newPaymentDetails: {
    monthlyPayment: number;
    apr: number;
    firstPaymentDate: string;
    lastPaymentDate: string;
    duration: number;
  };
  clientData: {
    creditFacility: {
      remainingBalance?: number;
      interestRate?: number;
      latestAcceptedInstallmentLoanNumber?: string;
    };
    latestAcceptedInstallmentLoanNumber?: string;
  };

  extraPayment?: string;
  setNewPaymentDetails: (details: PaymentDetail | undefined) => void;
};

const AcceptNewLoanAgreement: React.FC<AcceptNewLoanAgreementProps> = ({
  onNextStep,
  setIsLoading,
  newPaymentDetails,
  clientData,
  extraPayment,
  setNewPaymentDetails,
  handlePreviousStep,
}) => {
  useEffect(() => {
    setIsLoading(false);
  }, []);
  const { monthlyPayment, apr, firstPaymentDate, lastPaymentDate, duration } =
    newPaymentDetails;

  const {
    remainingBalance,
    interestRate,
    latestAcceptedInstallmentLoanNumber,
  } = clientData.creditFacility;
  const totalRepay = monthlyPayment * duration;

  const newCreditAmount = remainingBalance - parseFloat(extraPayment);

  const totalCreditCost = totalRepay - newCreditAmount;

  const newRemainingBalance = remainingBalance - parseFloat(extraPayment);

  // Calculate total interest to be paid
  const totalInterest = calculateTotalToBePaidInInterest(
    newRemainingBalance,
    interestRate,
    firstPaymentDate
  );

  console.log('newPaymentDetails', newPaymentDetails);

  const incrementInvoiceNumber = (invoiceNumber: string): string => {
    const parts = invoiceNumber.split('-');
    const lastPart = parts.pop();
    if (lastPart) {
      const incrementedLastPart = (parseInt(lastPart, 10) + 1).toString();
      parts.push(incrementedLastPart);
    }
    return parts.join('-');
  };

  const invoiceNumber = incrementInvoiceNumber(
    latestAcceptedInstallmentLoanNumber || 'not found'
  );

  const remainingBalanceWithTotalInterest = newRemainingBalance + totalInterest;

  const handleNextStep = () => {
    setNewPaymentDetails({
      monthlyPayment,
      apr,
      firstPaymentDate,
      lastPaymentDate,
      duration,
      extraPayment: parseFloat(extraPayment),
      newRemainingBalance: remainingBalanceWithTotalInterest,
      interestUntilFirstPayment: totalInterest,
    });
    onNextStep();
  };

  return (
    <>
      <div className='step-content acceptloanAgreement'>
        <h1 className='step-title center'>
          {strings.partialRedemptionAcceptLoanAgreementTitle}
        </h1>

        <div className='payment-info-container'>
          <Scrollbars
            // eslint-disable-next-line
            renderTrackVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  ...trackVerticalStyle,
                }}
              />
            )}
            // eslint-disable-next-line
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalStyle,
                }}
              />
            )}
          >
            <div className='scrollbar-wrapper'>
              <h3 className='payment-info-text center capsized'>
                {strings.partialRedemptionAcceptLoanAgreementContainerTitle}
              </h3>
              <h4 className='payment-info-text center'>
                {strings.partialRedemptionAcceptLoanAgreementContainerSubTitle}
              </h4>
              <p className='center invoice-number'>
                {strings.formatString(
                  strings.partialRedemptionAcceptLoanAgreementContainerInvoiceNumber,
                  invoiceNumber
                )}
              </p>

              <div className='payment-info font-size-14'>
                <Label>
                  {strings.agreementLoanAmount}
                </Label>
                <span>
                  {newRemainingBalance > 0
                    ? formatCurrencyDaStandard(newRemainingBalance)
                    : formatCurrencyDaStandard(remainingBalance)}
                </span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInfo3}</Label>
                <span>
                  {strings.formatString(
                    strings.partialRemptionPaymentDuration,
                    duration
                  )}
                </span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInfo4}</Label>
                <span>{formatInterestRate(interestRate.toFixed(2))}</span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInfo5}</Label>
                <span> {formatInterestRate(apr.toFixed(2))}</span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInfo6}</Label>
                <span>{formatCurrencyDaStandard(totalCreditCost)}</span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInfo7}</Label>
                <span>{formatCurrencyDaStandard(totalRepay)}</span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInfo8}</Label>
                <span>{formatCurrencyDaStandard(monthlyPayment)}</span>
              </div>

              <div className='payment-info font-size-14 margin-top-20'>
                <Label>{strings.partialRedemptionPaymentInfo9}</Label>
                <span>
                  {moment(firstPaymentDate, 'YYYY.MM.DD').format('DD-MM-YYYY')}
                </span>
              </div>

              <div className='payment-info font-size-14'>
                <Label>{strings.partialRedemptionPaymentInf10}</Label>
                <span>
                  {moment(lastPaymentDate, 'DD.MM.YYYY').format('DD-MM-YYYY')}
                </span>
              </div>

              <TermsConditions />
            </div>
          </Scrollbars>
        </div>

        <div className='step-actions'>
          <Button color='light' className='reject' onClick={handlePreviousStep}>
            {strings.partialRedemptionButtonBack}
          </Button>
          <Button color='blue' className='continue' onClick={handleNextStep}>
            {strings.acceptStep}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AcceptNewLoanAgreement;
