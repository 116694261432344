import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import dentiLogo from '../../resources/images/denti-logo-2.png';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { ROUTE_HOME } from '../../constants/routes';
import { Sundhedplus } from '../../config';
import { strings } from '../../constants/localization';

const ErrorPage = (props) => {
  const brandEnvVariable = process.env.REACT_APP_BRAND;
  useEffect(() => {
    document.body.classList.add('error-page');
    document.body.classList.add('public-content');
    document.body.id = brandEnvVariable;
    return () => {
      // cleanup
      document.body.classList.remove('error-page');
      document.body.classList.remove('public-content');
      document.body.id = brandEnvVariable;
      if (brandEnvVariable === 'Denti') {
        document.title = 'Denti';
      }
      if (brandEnvVariable === 'Sundhedplus') {
        document.title = 'Sundhed+';
      }
    };
  }, [brandEnvVariable]);

  return (
    <div className='error-canvas'>
      <div className='error-container'>
        <div className='error-content'>{props.children}</div>
        {!props.isClientPortalError ? (
          <NavLink to={ROUTE_HOME} className='public-page-logo'>
            {brandEnvVariable === 'Denti' && (
              <img className='brandLogo' src={dentiLogo} alt='Denti' />
            )}
            {brandEnvVariable === 'Sundhedplus' && (
              <img className='sundhedLogo' src={sundhedLogo} alt='Sundhed+' />
            )}
          </NavLink>
        ) : (
          <footer>
            <h4>{strings.clientPortalErrorHelpMsg}</h4>
            <a href={`mailto:${Sundhedplus.contactEmail}`}>
              {Sundhedplus.contactEmail}
            </a>{' '}
            |{' '}
            <a href={`tel:${Sundhedplus.contactPhone}`}>
              {`tlf. ${Sundhedplus.contactPhoneHtmlFormated} `}
            </a>
          </footer>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
