import React from 'react';

const FiltersDropdown = ({ filterOpen, toggleFilterMenu, children }) => {
  return (
    <div className={`filter-content ${filterOpen ? 'opened' : ''}`}>
      {/* CLOSE BUTTON FOR RESPONSIVE  */}
      <button
        type='button'
        className='btn btn-white menu-toggler close-action'
        onClick={() => toggleFilterMenu()}
      >
        <div className='close-toggler-icon'>
          <span />
          <span />
        </div>
      </button>
      {/* FILTERS FORM */}
      <div className='filter-form'>{children}</div>
    </div>
  );
};

export default FiltersDropdown;
