import { cprNoFormat, phoneNoFormat } from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

interface ClientDataProps {
  clientData: {
    cpr: number;
    firstName: string;
    lastName: string;
    address: string;
    zipCode: number;
    city: string;
    phone: number;
    email: string;
  };
}

const MemberProfileInformation: React.FC = () => {
  const clientDataInfo = useSelector(
    (state: { memberPortal: { clientDataInformation: ClientDataProps } }) =>
      state.memberPortal?.clientDataInformation
  );

  const { cpr, firstName, lastName, address, zipCode, city, phone, email } =
    clientDataInfo.clientData;

  return (
    <>
      <Card className='custom-card'>
        <CardBody className='credit-info-card'>
          <div className='headline-section'>
            {strings.mitPersonalInformation}
          </div>
          <Row className='lead-personal-data'>
            <Col className='col-12 col-md-4'>
              <div className='media'>
                <div className='logo media-left'>
                  <div className='d-logo'>
                    <i className='lnir lnir-house'></i>
                  </div>
                </div>
                <div className='media-body'>
                  <div className='media-body-title'>
                    <span>{strings.nameAndPhone}</span>
                  </div>
                  <div className='media-body-content'>
                    {firstName || ''} {lastName || ''}
                  </div>
                  <div className='media-body-content'>{address || ''}</div>
                  <div className='media-body-content'>
                    {zipCode || ''} {city || ''}
                  </div>
                </div>
              </div>
            </Col>

            <Col className='col-12 col-md-4'>
              <div className='media'>
                <div className='logo media-left'>
                  <div className='d-logo'>
                    <i className='lnir lnir-mobile-alt-1'></i>
                  </div>
                </div>
                <div className='media-body'>
                  <div className='media-body-title'>
                    <span>{strings.applicationClientPhone}</span>
                  </div>
                  <div className='media-body-content'>
                    {phoneNoFormat(phone)}
                  </div>
                </div>
              </div>

              <div className='media'>
                <div className='logo media-left'>
                  <div className='d-logo'>
                    <i className='lnir lnir-envelope'></i>
                  </div>
                </div>
                <div className='media-body'>
                  <div className='media-body-title'>
                    <span>{strings.emailLabel}</span>
                  </div>
                  <div className='media-body-content'>
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                </div>
              </div>
            </Col>

            <Col className='col-12 col-md-4'>
              <div className='media'>
                <div className='logo media-left'>
                  <div className='d-logo'>
                    <i className='lnir lnir-shield'></i>
                  </div>
                </div>
                <div className='media-body'>
                  <div className='media-body-title'>
                    <span>{strings.applicationClientCPR}</span>
                  </div>

                  <div className='media-body-content'>
                    {cprNoFormat(cpr) || ''}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default MemberProfileInformation;
