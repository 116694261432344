import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import * as animationData from '../../resources/animations/add-patient_accept_lottie.json';
import LottieControl from './LottieControl';

const StepSendAppSuccess = (props) => {
  return (
    <div className='step-content step-final'>
      <div className='step-header'>
        <LottieControl animationData={animationData} height={312} width={340} />
        <div className='title-text'>{strings.apLinkSuccessTitle}</div>
      </div>
      <div className='step-body'>
        <div className='text-information'>
          <p>{strings.apLinkSuccessText1}</p>
          <p>{strings.apLinkSuccessText2}</p>
          {props.email !== '' && props.phone === '' && (
            <p>{strings.apLinkSuccessTextEmail}</p>
          )}
          {props.email === '' && props.phone !== '' && (
            <p>{strings.apLinkSuccessTextSms}</p>
          )}
          {props.email !== '' && props.phone !== '' && (
            <p>{strings.apLinkSuccessTextSms}</p>
          )}
        </div>
        <div className='button-actions'>
          <Button color='blue' onClick={() => props.closeModal()}>
            {strings.buttonTextClose}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepSendAppSuccess;
