import {
  requestAxios as axios,
  formatReasonWithTimestampAndUser,
} from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import { store } from '../store';

import {
  SET_CURRENT_APPLICATION_ITEM,
  SET_CURRENT_APPLICATION_UUID,
  APPLICATIONS_LOADING,
  GET_ALL_APPLICATIONS,
  APPLICATIONS_EMPTY,
  APPLICATIONS_SUCCESS,
  APPLICATIONS_FAILURE,
  SERVER_URL,
  UPDATE_APPLICATIONS_FILTER_PARAMS,
  GET_APPLICATION_DATA,
  UPDATE_APPLICATION_IN_APPLICATION_LIST,
  GET_ESKAT_DATA_PREVIEW_LINK,
  UPDATE_BUDGET_ITEM_FINAL,
  SET_APPLICATION_LOADING,
  APPROVE_CREDIT_APPLICATION,
  SET_APPLICATION_ACTIVE_PAGE,
  SET_APPLICATION_DENY_REASON,
  DENY_CREDIT_APPLICATION,
} from '../constants';

export function setCurrentApplicationItem(applicationItem) {
  return {
    type: SET_CURRENT_APPLICATION_ITEM,
    payload: applicationItem,
  };
}
export function setCurrentApplicationUuid(applicationUuid) {
  return {
    type: SET_CURRENT_APPLICATION_UUID,
    payload: applicationUuid,
  };
}

export function setApplicationLoading(loadingStatus) {
  return {
    type: SET_APPLICATION_LOADING,
    payload: loadingStatus,
  };
}

export function setActivePage(activePage) {
  return {
    type: SET_APPLICATION_ACTIVE_PAGE,
    payload: activePage,
  };
}

export function updateApplicationFilterParams(filterParams) {
  return {
    type: UPDATE_APPLICATIONS_FILTER_PARAMS,
    payload: filterParams,
  };
}

export function getAllApplications(
  cpr,
  creditApplicationStatus,
  dateLower,
  dateUpper,
  searchInput,
  page = 0
) {
  let finalSearchInput = searchInput?.replace(/\s/g, '');
  // check if searchInput is a not empty string
  if (typeof searchInput === 'string' && finalSearchInput !== '') {
    // check if searchInput is a cpr, to clear the '-' if it needs
    if (/^\d{6}(-)?\d{4}$/.test(finalSearchInput)) {
      finalSearchInput = finalSearchInput?.split('-')?.join('');
    }
  }

  const data = {
    cpr: cpr || null,
    creditApplicationStatus: creditApplicationStatus || null,
    dateLower: dateLower || null,
    dateUpper: dateUpper || null,
    page: page > 0 ? page : 0,
    searchInput: finalSearchInput || null,
  };

  return async (dispatch) => {
    dispatch({
      type: APPLICATIONS_LOADING,
      payload: APPLICATIONS_LOADING,
    });

    dispatch(setActivePage(data.page + 1 || 1));

    try {
      const response = await axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/getCreditApplicationList`,
        auth: getLoggedInUserCredentials(),
        withCredentials: true,
        data,
      });

      const applicationsList = response?.data;
      dispatch({
        type: GET_ALL_APPLICATIONS,
        payload: applicationsList,
      });

      if (applicationsList?.content.length > 0) {
        dispatch({
          type: APPLICATIONS_SUCCESS,
          payload: APPLICATIONS_SUCCESS,
        });
      } else {
        dispatch({
          type: APPLICATIONS_EMPTY,
          payload: APPLICATIONS_EMPTY,
        });
      }
      if (applicationsList?.content?.length === 1) {
        const { creditApplicationType, creditApplicationUuid } =
          applicationsList.content[0] || {};
        dispatch(
          creditApplicationType === null || creditApplicationType === 'MONTHIO'
            ? getMonthioApplicationData(creditApplicationUuid)
            : getNoitsoApplicationData(creditApplicationUuid)
        )
          .then((res) => {
            dispatch(setCurrentApplicationItem(res?.data || {}));
          })
          .catch((err) => {
            console.log(err);
          });
      }

      dispatch(
        updateApplicationFilterParams({
          creditApplicationStatus: data.creditApplicationStatus || null,
          dateLower: data.dateLower || null,
          dateUpper: data.dateUpper || null,
          searchInput: data.searchInput || null,
        })
      );
    } catch (error) {
      console.log('error', error);
      dispatch({
        type: APPLICATIONS_FAILURE,
        payload: error,
      });
    }
  };
}

export function getNoitsoApplicationData(creditApplicationUuid, signal = null) {
  return (dispatch) => {
    const response = axios({
      method: 'get',
      url: `${SERVER_URL}/api/v1/getCreditApplicationData/${creditApplicationUuid}`,
      auth: getLoggedInUserCredentials(),
      withCredentials: true,
      ...(signal && { signal }), // add the signal to the request if it exists; used to abort the request
    });

    dispatch({
      type: GET_APPLICATION_DATA,
      payload: response,
    });

    return response;
  };
}

export function getMonthioApplicationData(
  creditApplicationUuid,
  signal = null
) {
  return (dispatch) => {
    const response = axios({
      method: 'get',
      url: `${SERVER_URL}/api/v1/getCreditApplicationDataForMonthio/${creditApplicationUuid}`,
      auth: getLoggedInUserCredentials(),
      withCredentials: true,
      ...(signal && { signal }), // add the signal to the request if it exists; used to abort the request
    });

    dispatch({
      type: GET_APPLICATION_DATA,
      payload: response,
    });

    return response;
  };
}

export function updateApplicationInApplicationList(newApplicationStatus) {
  return {
    type: UPDATE_APPLICATION_IN_APPLICATION_LIST,
    payload: newApplicationStatus,
  };
}

export function getEskatDataPreviewLink(cpr, creditApplicationUuid) {
  return (dispatch) => {
    const response = axios({
      method: 'get',
      url: `${SERVER_URL}/api/v1/creditApplication/downloadEskatDataPDF?cpr=${cpr}&creditApplicationUuid=${creditApplicationUuid}`,
      auth: getLoggedInUserCredentials(),
      withCredentials: true,
    });

    dispatch({
      type: GET_ESKAT_DATA_PREVIEW_LINK,
      payload: response,
    });

    return response;
  };
}

function extractStreetAndHouseNumber(stringAddress) {
  const addressParts = stringAddress.split(' ');
  let streetName = '';
  let houseNr = '';

  for (const part of addressParts) {
    // check if the part is an integer or a number with a single capital letter at the end
    if (/^\d+[A-Z]?$/.test(part)) {
      houseNr = part;
      break;
    } else {
      streetName += part + ' ';
    }
  }
  return { streetName: streetName.trim(), houseNr };
}

export function getPropertyCorrectAddress(incompleteAddress, municipalityCode) {
  const { streetName, houseNr } = incompleteAddress
    ? extractStreetAndHouseNumber(incompleteAddress)
    : {};
  const streetNameEncoded = encodeURIComponent(streetName);
  return axios({
    method: 'get',
    url: `https://api.dataforsyningen.dk/adresser?vejnavn=${streetNameEncoded}&kommunekode=${municipalityCode}&husnr=${houseNr}`,
  });
}

export function updateApplicationBudgetItem(data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/budget/final`,
    data,
  });
  return {
    type: UPDATE_BUDGET_ITEM_FINAL,
    payload: request,
  };
}

export function approveCreditApplication(data) {
  return (dispatch) => {
    const response = axios({
      method: 'put',
      url: `${SERVER_URL}/api/v1/creditApplication/activate`,
      auth: getLoggedInUserCredentials(),
      withCredentials: true,
      data,
    });

    dispatch({
      type: APPROVE_CREDIT_APPLICATION,
      payload: response,
    });

    return response;
  };
}

export function denyCreditApplication(creditApplicationUuid, cpr) {
  const { denyReason } = store.getState().onboardingApplication || {};
  const loggedUser = store.getState().user?.user;
  const { firstName, lastName } = loggedUser || {};
  const formattedReason = formatReasonWithTimestampAndUser(
    denyReason,
    firstName,
    lastName
  );
  return (dispatch) => {
    const response = axios({
      method: 'post',
      url: `${SERVER_URL}/public/v1/creditApplication/denied`,
      data: {
        creditApplicationUuid,
        cpr,
        reason: formattedReason || '',
      },
    });

    dispatch({
      type: DENY_CREDIT_APPLICATION,
      payload: response,
    });

    return response;
  };
}

export function setApplicationDenyReason(reason) {
  return {
    type: SET_APPLICATION_DENY_REASON,
    payload: reason,
  };
}
