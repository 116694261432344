// eslint-disable-next-line
import { downloadCreditTermsPDF } from '../../../actions/memberPortalActions';
import { strings } from '../../../constants/localization';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import LoadingMessage from '../../statusMessages/LoadingMessage';

const DocumentationCard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleAction = () => {
    // open this document in a different tab    Sundhedplus.termsAndConditionsWithoutBnpl
    window.open('https://sundhedplus.dk/assets/aftalebestemmelser.html');
  };

  const handleActionCrediterms = async () => {
    setLoading(true);
    try {
      // Dispatch the action and await the response containing the file URL
      const response = (await dispatch(downloadCreditTermsPDF())) as {
        data: string;
      };

      // Get the file URL from the response
      const fileUrl = response.data;
      if (fileUrl) {
        setLoading(false);
      }

      // Open the file in a new tab (the browser will handle downloading or viewing)
      window.open(fileUrl, '_blank');
    } catch (error) {
      setLoading(false);
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <Card className='custom-card'>
      {loading && <LoadingMessage />}
      <CardBody className='credit-info-card'>
        <div className='title'>{strings.mitDocumentSectionTitle}</div>
        <div className='content'>
          <ul>
            <li onClick={handleAction}>{strings.mitCreditTermsLabel}</li>
            <li onClick={handleActionCrediterms}>
              {strings.mitCreditTermsCreditDownload}
            </li>
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default DocumentationCard;
