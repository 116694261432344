import React from 'react';

import './style.scss'

const Checkbox = ({ content = '', checked = false, handleClick }) => (
  <div className={`Checkbox_component ${checked && 'checked'}`} onClick={handleClick}>
    <div className='colored-backround' />
    <div className='Checkbox_component-icon'>
      {checked ? <i className='lnir lnir-checkmark' /> : ''}
    </div>
    <div className='Checkbox_component-content'>{content}</div>
  </div>
)
export default Checkbox;
