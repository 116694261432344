import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

import userReducer from './userReducer';
import leadReducer from './leadReducer';
import statusReducer from './statusReducer';
import createCreditReducer from './createCreditReducer';
import installmentLoanReducer from './installmentLoanReducer';
import uiElementsReducer from './uiElementsReducer';
import companyReducer from './companyReducer';
import logReducer from './logReducer';
import patientDunningReducer from './patientDunningReducer';
import companyChainReducer from './companyChainReducer';
import creditMigrateReducer from './creditMigrateReducer';
import onboardingReducer from './onboardingReducer';
import onboardingDocumentationReducer from './onboardingDocumentationReducer';
import websocketReducer from './websocketReducer';
import onboardingApplicationReducer from './onboardingApplicationReducer';

import { LOG_OUT, CLEAR_NOTIFICATIONS } from '../constants';
import initialState from '../store/initialState';
import registerInvoiceFlowReducer from './registerInvoiceFlowReducer';
import memberPortalReducer from './memberPortalReducer';

const appReducer = combineReducers({
  user: userReducer,
  form: formReducer,
  lead: leadReducer,
  status: statusReducer,
  createCredit: createCreditReducer,
  installmentLoan: installmentLoanReducer,
  company: companyReducer,
  patientsDunning: patientDunningReducer,
  ui: uiElementsReducer,
  notifications,
  log: logReducer,
  companyChain: companyChainReducer,
  migrateCredit: creditMigrateReducer,
  onboarding: onboardingReducer,
  onboardingDocumentation: onboardingDocumentationReducer,
  websocketMessages: websocketReducer,
  onboardingApplication: onboardingApplicationReducer,
  registerInvoiceFlow: registerInvoiceFlowReducer,
  memberPortal: memberPortalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    // const { routing } = state;
    // eslint-disable-next-line
    state = { ...initialState };
  }
  if (action.type === CLEAR_NOTIFICATIONS) {
    state = { ...state, notifications: [] };
  }
  return appReducer(state, action);
};

export default rootReducer;
