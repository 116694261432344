import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
  setCurrentLead,
  setOpenLead,
  getInstalmentLoanByCreditFacility,
  getUpdatedPatientCreditFacility,
  removeWsPatientToUpdate,
} from '../../../actions/leadActions';
import { getLogsCreditFacility } from '../../../actions/logActions';
import {
  isManager,
  isDentist,
  isDeletedOrNotAcceptedOrWaiting,
  currencyFormatDA,
  isAdmin,
} from '../../../constants/utils';
import IconStatus from './leadItem/IconStatus';
import CreditStatus from './leadItem/CreditStatus';
import { ReactComponent as Coin } from '../../../resources/icons/coin.svg';

class LeadListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lead: this.props.lead,
      read: this.props.lead.leadOpened !== null,
      clinicsListForCompanyChain: [],
    };

    this.getExtraDataForLead = this.getExtraDataForLead.bind(this);
  }

  componentDidMount() {
    if (isManager(this.props.user)) {
      let companies = this.props.managersCompaniesList;
      if (companies?.length > 0) {
        companies = this.props.managersCompaniesList.map(
          (item) => item.referenceNumber
        );
        this.setState({ clinicsListForCompanyChain: companies });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lead.leadClientInformation !==
      this.props.lead.leadClientInformation
    ) {
      this.setState({ lead: this.props.lead });
    }
  }

  setReadItem() {
    if (!this.state.read && this.state.lead?.id) {
      this.props.setOpenLead(this.state.lead.id).then((result) => {
        if (result.payload.data !== null) {
          this.setState({
            read: true,
          });
        }
      });
    }
  }

  getExtraDataForLead() {
    const { lead } = this.state;
    this.props.setCurrentLead(lead);
    this.setReadItem();

    if (lead.creditFacilityInformation) {
      this.props.getLogsCreditFacility(lead.creditFacilityInformation.number);
      this.props.getInstalmentLoanByCreditFacility(
        lead.creditFacilityInformation.number
      );
    }
  }

  // eslint-disable-next-line
  shouldDisplayLoan(user, invoice) {
    if (isDentist(user)) {
      if (invoice.companyReferenceNumber !== user.activeCompany) {
        if (['PAYED', 'SETTLED', 'NOT_ACCEPTED'].indexOf(invoice.status) >= 0) {
          return false;
        }
        return true;
      }
      return true;
    }
    return true;
  }
  // eslint-disable-next-line
  notAccepted(user, invoice) {
    if (invoice?.companyReferenceNumber !== user?.activeCompany) {
      if (invoice.status === 'NOT_ACCEPTED') {
        return false;
      }
      return true;
    }
    return true;
  }

  notRestructured(user, invoice) {
    if (invoice.companyReferenceNumber === user?.activeCompany) {
      if (invoice?.invoiceNumber === 'Omlægning') {
        return false;
      }
      return true;
    }
    return true;
  }

  // eslint-disable-next-line
  showCreatedBy(user, invoice) {
    if (isDentist(user)) {
      if (invoice.companyReferenceNumber !== user?.activeCompany) {
        return false;
      }
      return true;
    }
    return true;
  }

  render() {
    const { lead, user } = this.props;
    let invoiceDetails;
    if (lead?.creditFacilityInformation) {
      invoiceDetails =
        lead.creditFacilityInformation.installmentLoanInformations;
    }

    // filter the invoices that are not related to company chain
    if (
      isManager(user) &&
      this.state.clinicsListForCompanyChain.length > 0 &&
      invoiceDetails
    ) {
      invoiceDetails = invoiceDetails.filter((invoice) =>
        this.state.clinicsListForCompanyChain.includes(
          invoice.companyReferenceNumber
        )
      );
    }

    if (isDentist(user) && invoiceDetails) {
      invoiceDetails = invoiceDetails.filter(
        (invoice) => invoice.companyReferenceNumber === user.activeCompany
      );
    }
    const reducer = (accumulator, invoice) => accumulator + invoice.amount;
    let totalInvoices = 0;
    if (invoiceDetails) {
      totalInvoices = invoiceDetails
        .filter(
          (invoice) =>
            !isDeletedOrNotAcceptedOrWaiting(invoice.status) &&
            this.shouldDisplayLoan(user, invoice)
        )
        .filter((invoice) => this.showCreatedBy(user, invoice))
        .filter((invoice) => this.notAccepted(user, invoice))
        .filter((invoice) => this.notRestructured(user, invoice))
        .reduce(reducer, 0);
    }

    return (
      <ListGroupItem
        active={this.props.active}
        onClick={() => {
          // first check that the leas has a credit facility
          if (lead.creditFacilityInformation) {
            const creditId = lead.creditFacilityInformation.id;
            const found = this.props.wsPatientsToUpdate
              ? this.props.wsPatientsToUpdate.find(
                  (value) => value === creditId
                )
              : null;
            if (found) {
              this.props.getUpdatedPatientCreditFacility(creditId).then(() => {
                this.props.removeWsPatientToUpdate(creditId);
              });
            }
          }
          this.getExtraDataForLead();
        }}
      >
        <div
          className={`${this.props.active ? 'active' : ''} single-list-item`}
        >
          <IconStatus lead={lead} />
          <div className='client-data'>
            <ListGroupItemHeading>
              {`${lead.leadClientInformation.firstName} ${lead.leadClientInformation.lastName}`}
            </ListGroupItemHeading>
            <ListGroupItemText tag='div'>
              <CreditStatus lead={Object.assign({}, lead)} />
            </ListGroupItemText>
          </div>
          {isAdmin(this.props.user) ? (
            <Badge>
              <Coin className='coin-icon' />
              <span className='ml-2'>
                {this.props.lead.creditFacilityInformation
                  ? currencyFormatDA(
                      this.props.lead.creditFacilityInformation
                        ?.remainingBalanceForAllLoans,
                      true,
                      true
                    )
                  : currencyFormatDA(0, true, true)}
              </span>
            </Badge>
          ) : (
            <Badge>
              <i className='di di-loan-count' />
              <span className='ml-2'>
                {currencyFormatDA(totalInvoices, true, true)}
              </span>
            </Badge>
          )}
        </div>
      </ListGroupItem>
    );
  }
}

LeadListItem.propTypes = {
  lead: PropTypes.object,
  setOpenLead: PropTypes.func,
  active: PropTypes.bool,
  setCurrentLead: PropTypes.func,
  getLogsCreditFacility: PropTypes.func,
  getInstalmentLoanByCreditFacility: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
    managersCompaniesList: state.company.managersCompaniesList,
    wsPatientsToUpdate: state.lead.wsPatientsToUpdate,
  };
}

export default connect(mapStateToProps, {
  setCurrentLead,
  setOpenLead,
  getLogsCreditFacility,
  getInstalmentLoanByCreditFacility,
  getUpdatedPatientCreditFacility,
  removeWsPatientToUpdate,
})(LeadListItem);
