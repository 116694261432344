import LoadingMessage from '../statusMessages/LoadingMessage';
import React, { useEffect, useState } from 'react';
import { ReactComponent as InformationIcon } from '../../resources/icons/info-circle.svg';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import { formatCurrencyDaStandard } from '../../constants/utils';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line
import { partialRedemptionPayment } from '../../actions/memberPortalActions';

interface PaymentProcessingProps {
  additionalPayment?: number | string;
  closeModal?: () => void;
  isPaymentConfirmed: boolean;
  leadUuid: string;
  creditFacilityNumber: string;
  newPaymentDetails: {
    monthlyPayment: number;
    apr: number;
    firstPaymentDate: string;
    lastPaymentDate: string;
    duration: number;
    extraPayment: number;
    newRemainingBalance: number;
    // reepayInvoiceHandleText: string;
    // setupFee: number;
  };
  hasCancelButton?: boolean;
  hasRedirect?: boolean;
}

const PaymentProcessing: React.FC<PaymentProcessingProps> = ({
  additionalPayment,
  newPaymentDetails,
  isPaymentConfirmed,
  leadUuid,
  creditFacilityNumber,
  hasCancelButton,
  hasRedirect,
  closeModal,
}) => {
  const [status, setStatus] = useState<'loading' | 'failed' | 'success'>(
    'loading'
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPaymentConfirmed) {
      setStatus('loading');
      const paymentDetails = {
        duration: newPaymentDetails.duration,
        extraPayment: newPaymentDetails.extraPayment,
        reepayInvoiceHandleText: '' as const,
        setupFee: 0 as const,
      };
      dispatch(
        partialRedemptionPayment(leadUuid, creditFacilityNumber, paymentDetails)
      )
        .then((response) => {
          if (response.status === 200) {
            setStatus('success');
          } else {
            setStatus('failed');
          }
        })
        .catch((e) => {
          console.log('Error', e);
          setStatus('failed');
        });
    }
  }, [isPaymentConfirmed]);

  const handleOkButton = () => {
    if (hasRedirect) {
      window.close();
    } else {
      closeModal && closeModal();
      window.location.reload();
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return (
          <div className='step-content payment-processing'>
            <h1 className='step-title center'>
              {strings.partialRedemptionPaymentProcessingLoadingTitle}
            </h1>
            <p className='subtitle'>
              {strings.partialRedemptionPaymentProcessingLoadingSubtitle}
            </p>
            <div className='loading-container'>
              <LoadingMessage />
            </div>
          </div>
        );

      case 'failed':
        return (
          <div className='step-content payment-processing'>
            <div className='content-information'>
              <div className='icon-container'>
                <InformationIcon className='failed-icon' />
                <h1 className='step-title center'>
                  {strings.partialRedemptionPaymentProcessingFailedTitle}
                </h1>
              </div>
              <p className='subtitle'>
                {strings.formatString(
                  strings.partialRedemptionPaymentProcessingFailedSubtitle,
                  formatCurrencyDaStandard(newPaymentDetails.extraPayment)
                )}
              </p>
              <p className='subtitle'>
                {strings.partialRedemptionPaymentProcessingFailedSubtitle2}
              </p>
            </div>
            {hasCancelButton && (
              <div className='button-container'>
                <Button color='blue' onClick={handleOkButton}>
                  {strings.partialRedemptionPaymentProcessingFailedButton}
                </Button>
              </div>
            )}
          </div>
        );

      case 'success':
        return (
          <div className='step-content payment-processing'>
            <div className='content-information'>
              <div className='icon-container'>
                <i className='lnil lnil-checkmark-circle icon' />
              </div>
              <h1 className='step-title center'>
                {strings.partialRedemptionPaymentProcessingSuccessTitle}
              </h1>
              <p className='subtitle sucess-message'>
                {strings.formatString(
                  strings.partialRedemptionPaymentProcessingSuccessSubtitle,
                  formatCurrencyDaStandard(
                    additionalPayment
                  )
                )}
              </p>
              <p className='subtitle sucess-message'>
                {strings.formatString(
                  strings.partialRedemptionPaymentProcessingSuccessSubtitle2,
                  formatCurrencyDaStandard(newPaymentDetails.monthlyPayment)
                )}
              </p>
            </div>
            {hasCancelButton && (
              <div className='button-container'>
                <Button color='blue' onClick={handleOkButton}>
                  {strings.partialRedemptionPaymentProcessingSuccessButton}
                </Button>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default PaymentProcessing;
