import React, { useEffect, useRef, useState } from 'react';
import { strings } from '../../constants/localization';

import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody } from 'reactstrap';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import {
  addErrorLog,
  eSkatGetConsentRequest,
  eSkatV3GetConsentRequest,
  sendDisposableAmount,
  storeOnboardingData,
} from '../../actions/onboardingActions';

const PowerOfAttorney = ({ step, version, setShowPowerOfAttorney }) => {
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const lastVisitedStep = useSelector(
    (state) => state.onboarding?.previousVisitedStep
  );
  const hasGotBackFromEskat = savedData?.hasGotBackFromEskat;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const formRef = useRef(null);

  const dispatch = useDispatch();

  const monthlyDisposable = savedData?.monthlyDisposable;
  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingPowerOfAttorneyTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/forbrug',
        stepTitle: 'onboarding - forbrug',
        event: 'onboarding',
      });
    }
  }, []);

  useEffect(() => {
    if (lastVisitedStep === step) {
      dispatch(
        storeOnboardingData({
          ...savedData,
          hasGotBackFromEskat: true,
        })
      );
    }
  }, [lastVisitedStep]);

  const handleNextStep = () => {
    setButtonLoading(true);
    dispatch(sendDisposableAmount(monthlyDisposable))
      .then((response) => {
        if (response?.payload?.status === 200) {
          return dispatch(
            version === 'Monthio'
              ? eSkatV3GetConsentRequest()
              : eSkatGetConsentRequest()
          ).then((response) => {
            const data = response?.payload?.data;
            if (data) {
              const refToForm = formRef.current;

              setFormData({
                ...formData,
                SAMTYKKEPARM: data,
                TESTMODE:
                  process.env.REACT_APP_STAGE === 'staging' ? 'ja' : 'nej',
              });
              setButtonLoading(true);

              // beforing submitting the form, we need to push to dataLayer
              window.dataLayer = window?.dataLayer || [];
              window.dataLayer.push({
                customPageUrl: '/viderestilling-til-eskat',
                stepTitle: 'onboarding - viderestilling til eskat',
                event: 'onboarding',
              });
              refToForm.submit();
            } else {
              dispatch(
                addErrorLog(
                  'Could not redirect to eSkat, no data received from encrypt endpoint'
                )
              );
            }
          });
        }
      })
      .catch((error) => {
        dispatch(
          addErrorLog({
            status: error?.response?.status,
            message: error?.response?.data,
          })
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <CardBody className='step-content'>
        <div className='step-title mt-1'>
          {strings.onboardingPowerOfAttorneyTitle}
        </div>

        {hasGotBackFromEskat ? (
          <>
            <div className='step-subtitle mt-4 mb-2 font-16'>
              <p>{strings.onboardingPowerOfAttorneySubtitleHasReturned}</p>
            </div>
            <div className='rejected-note'>
              <div className='head'>
                {strings.onboardingPowerOfAttorneyContainerHasReturnedTitle}
              </div>
              <div className='body'>
                <p>
                  {strings.formatString(
                    strings.onboardingPowerOfAttorneyContainerHasReturnedText1,
                    <a
                      href='https://nemlog-in.mitid.dk/ShowCookies'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {
                        strings.onboardingPowerOfAttorneyContainerHasReturnedTextPage
                      }
                    </a>
                  )}
                </p>
                <p>
                  {strings.onboardingPowerOfAttorneyContainerHasReturnedText2}
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className='step-subtitle font-16'>
            <p>{strings.onboardingPowerOfAttorneytext1}</p>
            <p>{strings.onboardingPowerOfAttorneytext2}</p>
            <p>{strings.onboardingPowerOfAttorneytext3}</p>
            <p>{strings.onboardingPowerOfAttorneytext4}</p>
          </div>
        )}

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-cancel'
            onClick={() => {
              setButtonLoading(true);
              dispatch(
                storeOnboardingData({
                  ...savedData,
                  monthlyDisposable: undefined,
                })
              );
              setShowPowerOfAttorney(false);
            }}
            disabled={buttonLoading}
          >
            {strings.onboardingGoBack}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => handleNextStep()}
            disabled={buttonLoading}
          >
            {buttonLoading ? <ButtonLoader /> : strings.onboardingContinue}
          </Button>
        </div>
      </CardBody>
      <form
        onSubmit={handleSubmit}
        ref={formRef}
        action='https://samtykke.skat.dk/samtykke/anmodadgang.do'
        id='eskatform'
        method='POST'
        target='_top'
      >
        <input
          name='SAMTYKKEPARM'
          type='hidden'
          value={formData.SAMTYKKEPARM || ''}
        />
        <input name='TESTMODE' type='hidden' value={formData.TESTMODE || ''} />
      </form>
    </>
  );
};

export default PowerOfAttorney;
