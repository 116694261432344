import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { strings } from '../../../constants/localization';

const ActiveFiltersDisplay = (props) => {
  const scrollFilterRef = useRef();
  let isDown = false;
  let startX = 0;
  let scrollLeft = 0;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(props.filters).length > 0) {
      setLoaded(true);
    }
  }, [props]);

  // handle horizontal slide on
  const handleMouseDown = (event) => {
    const slider = scrollFilterRef.current;
    isDown = true;
    // slider.classList.add('active');
    startX = event.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
  };

  const handleMouseUp = () => {
    isDown = false;
  };

  const handleMouseMove = (event) => {
    const slider = scrollFilterRef.current;
    if (!isDown) return;
    event.preventDefault();
    const offsetX = event.pageX - slider.offsetLeft;
    const walk = (offsetX - startX) * 2; // scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className='active-filters-container'>
      {loaded && (
        <div
          className='active-filters'
          ref={scrollFilterRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          {props.filters.smartLinkStatus !== undefined && (
            <div className='badge badge-filter'>
              <span
                className='close-action'
                onClick={() => props.removeFilterItem('smartLinkStatus')}
              >
                <i className='di di-close' />
              </span>
              <span>{strings.companySmartLinkOption}</span>
            </div>
          )}

          {props.filters.status !== undefined && (
            <div className='badge badge-filter'>
              <span
                className='close-action'
                onClick={() => props.removeFilterItem('status')}
              >
                <i className='di di-close' />
              </span>
              <span>{strings.companyStatusOption}</span>
            </div>
          )}

          {props.filters.brand !== undefined && (
            <div className='badge badge-filter'>
              <span
                className='close-action'
                onClick={() =>
                  props.filters.brand?.toUpperCase() === 'DENTI'
                    ? props.removeFilterItem('brandDENTI')
                    : props.removeFilterItem('brandSUNDHED')
                }
              >
                <i className='di di-close' />
              </span>
              <span>{props.filters.brand}</span>
            </div>
          )}

          {props.filters.publicCompanyCategory !== undefined && (
            <div className='badge badge-filter'>
              <span
                className='close-action'
                onClick={() => props.removeFilterItem('publicCompanyCategory')}
              >
                <i className='di di-close' />
              </span>
              <span>{props.filters.publicCompanyCategory}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedCompany: state.company.selectedCompanyRef,
    filters: state.company.filters,
  };
}

export default connect(mapStateToProps)(ActiveFiltersDisplay);
