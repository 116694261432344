import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_ONBOARDING } from '../../constants/routes';
import LoadingMessage from '../statusMessages/LoadingMessage';
import { strings } from '../../constants/localization';
import { clearOnboardingData } from '../../actions/onboardingActions';
import { config } from '../../config';

const ClearCookies = (props) => {
  const currentDomain = window.location.hostname;
  const [state, setState] = useState({});
  let timeoutUntilCleaning = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  // To delete all cookies, you need to loop through all the cookies and set their expiration dates to a time in the past
  const clearCookies = useCallback(() => {
    timeoutUntilCleaning.current = setTimeout(() => {
      let cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let singleCookie = cookies[i];
        let cookieIndex = singleCookie.indexOf('=');
        let cookieName =
          cookieIndex > -1
            ? singleCookie.substring(0, cookieIndex)
            : singleCookie;
        document.cookie =
          cookieName + '=;expires=' + new Date(0).toUTCString() + '; path=/';
      }
      // just to be sure that some of the cookies are not stored in localStorage
      window.localStorage.clear();
      setState({
        cookiesCleared: true,
      });
      if (
        props?.forClients &&
        currentDomain === process.env.REACT_APP_ONBOARDING_DOMAIN
      ) {
        // also make sure to clear the persistent onboarding data
        dispatch(clearOnboardingData());
        history.push(ROUTE_ONBOARDING);
      } else if (
        currentDomain !== process.env.REACT_APP_DAM_URL ||
        currentDomain !== process.env.REACT_APP_ONBOARDING_DOMAIN
      ) {
        window.location.href = config.damAppMainPageUrl;
      } else {
        history.push(ROUTE_LOGIN);
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    clearCookies();
    return () => {
      clearTimeout(timeoutUntilCleaning.current);
      setState({});
    };
  }, [clearCookies]);

  if (!state.cookiesCleared) {
    return <LoadingMessage message={strings.cookiesClearLoading} />;
  } else if (state.cookiesCleared) {
    return <p>{strings.cookiesClearDone}</p>;
  } else {
    return null;
  }
};

export default ClearCookies;
