import React from 'react';
import './style.scss';
import InputSearch from './InputSearch';
import FiltersDropdown from './FiltersDropdown';
import ActiveFilters from './ActiveFilters';

const SearchAndFilter = ({
  searchBarPlaceholder = '',
  storedSearchKeyword,
  filterMenuOpen,
  toggleFilterMenu,
  searchSubmit,
  removeFilter,
  children,
  filterTagsArray,
}) => {
  return (
    <div className='search-filter-section'>
      <div className='group'>
        {/* SEARCH INPUT */}
        <InputSearch
          searchBarPlaceholder={searchBarPlaceholder}
          storedSearchKeyword={storedSearchKeyword}
          toggleFilterMenu={toggleFilterMenu}
          searchSubmit={searchSubmit}
        />
        {/* FILTERS */}
        <FiltersDropdown
          filterOpen={filterMenuOpen}
          toggleFilterMenu={toggleFilterMenu}
        >
          {children}
        </FiltersDropdown>
      </div>

      {/* ACTIVE FILTERS */}
      <ActiveFilters filterTags={filterTagsArray} removeFilter={removeFilter} />
    </div>
  );
};

export default SearchAndFilter;
