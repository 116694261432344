import { requestAxios as axios } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import {
  SERVER_URL,
  CREATE_CREDIT_EMAIL,
  CREATE_CREDIT_EMAIL_MODAL,
  CREATE_CREDIT_SMS,
  CREATE_CREDIT_SMS_MODAL,
  ADD_PATIENT,
} from '../constants';

export function createCreditModalByEmail(leadId) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v2/lead/createCredit/email/${leadId}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    headers: { 'Content-Type': 'application/json' },
    data: {},
  });

  return {
    type: CREATE_CREDIT_EMAIL_MODAL,
    payload: request,
  };
}

export function createCreditPageByEmail(email, referenceNumber) {
  const request = axios({
    method: 'post',

    url: `${SERVER_URL}/api/v2/lead/createCreditForNonLeads/email/${email}/${referenceNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    headers: { 'Content-Type': 'application/json' },
    data: {},
  });

  return {
    type: CREATE_CREDIT_EMAIL,
    payload: request,
  };
}

export function createCreditModalBySms(leadId) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v2/lead/createCredit/sms/${leadId}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    headers: { 'Content-Type': 'application/json' },
    data: {},
  });

  return {
    type: CREATE_CREDIT_SMS_MODAL,
    payload: request,
  };
}

export function createCreditPageBySms(phone, referenceNumber) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v2/lead/createCreditForNonLeads/sms/${phone}/${referenceNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    headers: { 'Content-Type': 'application/json' },
    data: {},
  });

  return {
    type: CREATE_CREDIT_SMS,
    payload: request,
  };
}

export function addPatientCredit(data) {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${SERVER_URL}/api/v1/creditFacility/assignCompanyToCreditFacility`,
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
      data,
    })
      .then((response) => {
        dispatch({
          type: ADD_PATIENT,
          payload: response,
        });
        return response; // Return the response to handle it in the component
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          return error.response; // Return the error response to handle it in the component
        }
        throw error; // Throw error if it's not a response error
      });
  };
}
