import { requestAxios as axios } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import {
  SERVER_URL,
  GET_LEAD_STATUSES,
  SET_LEAD_ACTIVE_STATUS,
  GET_COMPANY_STATUSES,
  SET_COMPANY_ACTIVE_STATUS,
  COMPANY_STATUS_ORDER,
  LEAD_STATUS_ORDER,
} from '../constants';


function updateStatusData(categories, statusOrder) {
  let totalStatuses = 0;
  let i = 0;

  const sourceCategories = { ...categories };
  const keyCategories = Object.keys(categories);
  if (keyCategories.length < statusOrder.length) {
    for (let j = 0; j < statusOrder.length; j += 1) {
      const status = statusOrder[j];

      if (keyCategories.indexOf(status) < 0) {
        sourceCategories[status] = 0;
      }
    }
  }

  const orderedCategories = {};
  for (const status in sourceCategories) {
    if (Object.prototype.hasOwnProperty.call(sourceCategories, status)) {
      totalStatuses += sourceCategories[status];
      orderedCategories[statusOrder[i]] = sourceCategories[statusOrder[i]];
      i += 1;
    }
  }

  return { VIEW_ALL: totalStatuses, ...orderedCategories };
}

export function getLeadStatuses(newStatus) {
  return (dispatch, getState) => {
    const { selectedCompanyRef } = getState().company;
    const refNumber = selectedCompanyRef === undefined
      ? ''
      : selectedCompanyRef.referenceNumber;
    const api = refNumber === '' ? 'v1' : 'v2';

    return axios
      .get(`${SERVER_URL}/api/${api}/lead/getStatusesCount/${refNumber}`, {
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
      .then((response) => {
        const orderedCategories = updateStatusData(
          response.data,
          LEAD_STATUS_ORDER,
        );

        dispatch({
          type: GET_LEAD_STATUSES,
          payload: orderedCategories,
        });

        if (newStatus !== undefined) {
          dispatch(leadSidebarActiveStatus(newStatus));
        }
      });
  };
}

export function leadSidebarActiveStatus(statusActive = null) {
  return {
    type: SET_LEAD_ACTIVE_STATUS,
    payload: statusActive,
  };
}

export function getCompanyStatuses(newStatus) {
  return (dispatch) => axios
    .get(`${SERVER_URL}/api/v2/companies/getStatusesCount/`, {
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
    })
    .then((response) => {
      const orderedCategories = updateStatusData(
        response.data,
        COMPANY_STATUS_ORDER,
      );
      dispatch({
        type: GET_COMPANY_STATUSES,
        payload: orderedCategories,
      });

      if (newStatus !== undefined) {
        dispatch(companySidebarActiveStatus(newStatus));
      }
    });
}

export function companySidebarActiveStatus(statusActive = null) {
  return {
    type: SET_COMPANY_ACTIVE_STATUS,
    payload: statusActive,
  };
}