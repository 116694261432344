import { Button } from 'reactstrap';
import React from 'react';
import { strings } from '../../../constants/localization';

const DocumentClientNote = ({ close, clientNote }) => {
  return (
    <div className='client-note-modal'>
      <div className='client-note-wrapper'>{clientNote}</div>

      <div className='Button-modal-wrapper'>
        <Button color='blue' onClick={close}>
          {strings.documentationNoteCloseButton}
        </Button>
      </div>
    </div>
  );
};

export default DocumentClientNote;
