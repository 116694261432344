const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      padding: '12px 40px 12px 50px',
      height: 'auto',
      boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      color: '#000000',
    },

    success: {
      backgroundColor: '#f5fafa',
      border: '2px solid #cee7e9',
    },

    error: {
      backgroundColor: '#fbf6f6',
      border: '2px solid #edd3d3',
    },
  },

  Title: {
    DefaultStyle: {
      color: '#000000',
    },
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: 'Maven Pro',
      fontSize: '25px',
      lineHeight: '23px',
      top: 'calc(30% - 12px)',
      width: '25px',
      height: '25px',
      right: '10px',
    },

    success: {
      backgroundColor: '#8ec8cd',
    },

    error: {
      backgroundColor: '#d79a9a',
    },
  },

  Action: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      padding: '0',
      textDecoration: 'underline',
      fontWeight: 'normal',
    },

    success: {
      color: '#339ca6',
    },

    error: {
      color: '#b84949',
    },
  },
};

export default styleNotification;
