import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card } from 'reactstrap';
import { currencyFormatDA } from '../../../constants/utils';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import { strings } from '../../../constants/localization';
import { moment } from '../../../constants/';
import '../styles.scss';
import { setCurrentManagerCompany } from '../../../actions/companyActions';

const ManagerCompaniesList = ({ companies }) => {
  const dispatch = useDispatch();
  return (
    <Row>
      <Col xs='12' md='12' lg='12' className='main-content'>
        <h1 className='headline'>{strings.settingsHeadline2Link2}</h1>
        <Card id='ManagersListComponent' className='custom-card'>
          <div className='companies-table'>
            <div className='header-wrapper'>
              <Row>
                <Col xs='6' md='4' xl='4' className='name-field'>
                  {strings.name}
                </Col>
                <Col
                  xs='2'
                  md='2'
                  xl='2'
                  className='revenue-field d-none d-sm-block'
                >
                  {strings.chainRevenue}
                </Col>
                <Col
                  xs='3'
                  md='3'
                  xl='3'
                  className='create-date-field d-none d-sm-block'
                >
                  {strings.companyCreateDate}
                </Col>
                <Col xs='2' md='2' xl='2' className='status-field'>
                  {strings.invoiceStatus}
                </Col>
                <Col className='seeDetailsButton' />
              </Row>
            </div>
            <div className='content-wrapper'>
              {companies?.length === 0 ? (
                <LoadingMessage />
              ) : (
                companies?.map((company, i) => (
                  <Row
                    key={`company-${i}`}
                    onClick={() => {
                      dispatch(setCurrentManagerCompany(company));
                    }}
                  >
                    <Col xs='6' md='4' xl='4' className='name-field'>
                      {company.companyName}
                    </Col>
                    <Col
                      xs='2'
                      md='2'
                      xl='2'
                      className='revenue-field d-none d-sm-block'
                    >
                      {currencyFormatDA(
                        company.totalInvoiceAmount,
                        true,
                        true,
                        company.totalInvoiceAmount % 1 !== 0
                      )}
                    </Col>
                    <Col
                      xs='3'
                      md='3'
                      xl='3'
                      className='create-date-field lowercase d-none d-sm-block'
                    >
                      {company.createdDate
                        ? moment(company.createdDate, 'YYYY-MM-DD').format(
                            'DD-MM-YYYY'
                          )
                        : ''}
                    </Col>

                    <Col xs='2' md='2' xl='2' className='status-field'>
                      <span className={`status-${company.status} dot`}></span>
                      {company.status === 'PASSIVE'
                        ? strings.PASSIVE_OUT_OF_COMPONENT_STATUS
                        : strings[company.status]}
                    </Col>
                    <Col className='seeDetailsButton'>
                      <i className='lnil lnil-chevron-right'></i>
                    </Col>
                  </Row>
                ))
              )}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ManagerCompaniesList;
