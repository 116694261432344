import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { strings } from '../../../constants/localization';
import {
  ROUTE_LEADS,
  ROUTE_CREDIT_APPLICATIONS,
} from '../../../constants/routes';
import {
  getAllDocuments,
  getDocumentDetails,
  setCurrentDocumentationItem,
  setDocumentationItemLoading,
} from '../../../actions/onboardingDocumentationActions';
import KebabMenu from '../../common/KebabMenu/KebabMenu';
import { ReactComponent as UserIcon } from '../../../resources/icons/user-alt-5.svg';

const DocumentClientSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );

  const documentData = useSelector(
    (state) => state.onboardingDocumentation?.data
  );

  const {
    cpr,
    firstName,
    lastName,
    phone,
    email,
    address,
    city,
    zipCode,
    creditApplicationUuid,
    documents,
  } = activeDocListItem;

  const refreshDocumentItem = () => {
    dispatch(setDocumentationItemLoading(true));
    dispatch(getAllDocuments()).then(() => {
      const findCurrentDocItem = documentData?.content.find(
        (item) =>
          item?.creditApplicationUuid ===
            activeDocListItem?.creditApplicationUuid &&
          item?.type === activeDocListItem?.type
      );

      if (findCurrentDocItem) {
        documents.forEach((doc) => {
          dispatch(getDocumentDetails(cpr, doc.id))
            .then(() => {
              if (
                findCurrentDocItem?.documents?.length !==
                activeDocListItem?.documents?.length
              ) {
                dispatch(setCurrentDocumentationItem(findCurrentDocItem));
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              dispatch(setDocumentationItemLoading(false));
            });
        });
      }
    });
  };

  return (
    <section
      id='document-display-section'
      className='entity-information-card custom-card card'
    >
      <div className='card-body' id='client-details-section'>
        <div className='d-flex justify-content-between'>
          <div className='headline-section'>
            {strings.applicationClientDetails}
          </div>
          <div className='d-flex actions-wrapper header-actions'>
            <div className='refreshButton' onClick={refreshDocumentItem}>
              <i className='lnir lnir-reload icon' />
            </div>
            <KebabMenu>
              {cpr && (
                <div
                  type='button'
                  color='light'
                  className='KebabMenu-item'
                  onClick={() => {
                    history.push({
                      pathname: ROUTE_LEADS,
                      search: `?q=${cpr}`,
                    });
                  }}
                >
                  <UserIcon className='icon' />
                  {strings.documentationMemberProfileButton}
                </div>
              )}

              {creditApplicationUuid && (
                <div
                  type='button'
                  color='light'
                  className='KebabMenu-item'
                  onClick={() => {
                    history.push({
                      pathname: ROUTE_CREDIT_APPLICATIONS,
                      search: `?q=${creditApplicationUuid}`,
                    });
                  }}
                >
                  <i className='lnir lnir-files icon'></i>
                  {strings.documentationSeeApplicationButton}
                </div>
              )}
            </KebabMenu>
          </div>
        </div>

        <Row className='lead-personal-data'>
          <Col className='col-12 col-md-4'>
            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-house'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.nameAndPhone}</span>
                </div>
                <div className='media-body-content'>
                  {firstName || ''} {lastName || ''}
                </div>
                <div className='media-body-content'>{address || ''}</div>
                <div className='media-body-content'>
                  {zipCode || ''} {city || ''}
                </div>
              </div>
            </div>
          </Col>

          <Col className='col-12 col-md-4'>
            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-mobile-alt-1'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.applicationClientPhone}</span>
                </div>
                <div className='media-body-content'>{phone}</div>
              </div>
            </div>

            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-envelope'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.emailLabel}</span>
                </div>
                <div className='media-body-content'>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </div>
            </div>
          </Col>

          <Col className='col-12 col-md-4'>
            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-shield'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.applicationClientCPR}</span>
                </div>
                {cpr && (
                  <div className='media-body-content'>
                    <Link
                      to={{
                        pathname: ROUTE_LEADS,
                        search: `?q=${cpr}`,
                      }}
                    >
                      {cpr || ''}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default DocumentClientSection;
