import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';
import { moment } from '../../../constants';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../../constants/styleScrollbar';
import LoadingMessage from '../../statusMessages/LoadingMessage';

class LogDetails extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
  }

  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.resetScrollPosition();
    }
  }

  getUsernameByUserId(referenceId) {
    if (this.props?.usersAssociated) {
      const user = this.props.usersAssociated.find(
        (user) => user.id === referenceId
      );
      if (user) {
        return user?.username;
      }
    }
    return '';
  }

  render() {
    const { chainLogs } = this.props;

    return (
      <Card className='lead-information logs custom-card table-responsive-sm'>
        <CardBody>
          <div className='header-information'>
            <CardTitle>{strings.logTab}</CardTitle>
          </div>
          <div className='log-header'>
            <div className='log-cell log-date'>{strings.logTabDate}</div>
            <div className='log-cell log-event'>{strings.logTabEvent}</div>
            <div className='log-cell log-user'>{strings.logTabUser}</div>
          </div>
          <div className='log-information'>
            <Scrollbars
              ref={this.scrollRef}
              renderTrackVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    ...trackVerticalStyle,
                  }}
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    ...thumbVerticalStyle,
                  }}
                />
              )}
            >
              <div className='scrollbar-wrapper'>
                {!chainLogs ? (
                  <LoadingMessage />
                ) : (
                  <div className='log-body'>
                    {chainLogs.map((log) => (
                      <div className='log-entry' key={log.id}>
                        <div className='log-cell log-date'>
                          <div>
                            {moment
                              .utc(log.createdDate)
                              .local()
                              .format('DD. MMM. YYYY')
                              .toLowerCase()}
                          </div>
                          <div>
                            {moment
                              .utc(log.createdDate)
                              .local()
                              .format('HH:mm')}
                          </div>
                        </div>
                        <div className='log-cell log-event'>
                          <div className='event-details'>
                            {log.event.replace(/\\n/g, '\n\r')}
                          </div>
                          {log.installmentLoan !== null && (
                            <div>{`Loan id: ${log.installmentLoan}`}</div>
                          )}
                          {log.type.startsWith('USER_') && log.reference && (
                            <div>
                              {`Affected user: ${this.getUsernameByUserId(
                                log.reference
                              )} `}
                            </div>
                          )}
                        </div>
                        <div className='log-cell log-user'>{log.username}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </CardBody>
      </Card>
    );
  }
}

LogDetails.propTypes = {
  activeTab: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    chainLogs: state.log?.chainLogs,
    usersAssociated: state.companyChain?.usersAssociated,
  };
}
export default connect(mapStateToProps)(LogDetails);
