import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { success, error as notifError } from 'react-notification-system-redux';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { strings } from '../../../constants/localization';
import { notificationDismissDuration, moment } from '../../../constants';
import {
  addNotification,
  phoneNoFormat,
  isManager,
} from '../../../constants/utils';
import {
  companyUpdateInformation,
  getCompaniesListForManagers,
} from '../../../actions/companyActions';

const CompanyMasterData = (props) => {
  const company = props.companyDetails;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.user);

  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [cvr, setCvr] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [cardNumber, setCardNumber] = useState('');

  const [companyNameError, setCompanyNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [cityError, setCityError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formIsTouched, setFormIsTouched] = useState(false);

  useEffect(() => {
    setCompanyName(company.companyName || '');
    setAddress(company.address || '');
    setZipCode(company.zipCode || '');
    setCity(company.city || '');
    setCvr(company.cvr || '');
    setRegistrationNumber(company.registrationNumber || '');
    setCardNumber(company.cardNumber || '');
  }, [company]);

  const updateField = (value, field) => {
    switch (field) {
      case 'companyName':
        setCompanyName(value);
        setCompanyNameError('');
        value !== companyName
          ? setFormIsTouched(true)
          : setFormIsTouched(false);
        break;
      case 'address':
        setAddress(value);
        setAddressError('');
        value !== address ? setFormIsTouched(true) : setFormIsTouched(false);
        break;
      case 'zipCode':
        setZipCode(value);
        setZipCodeError('');
        value !== zipCode ? setFormIsTouched(true) : setFormIsTouched(false);
        break;
      case 'city':
        setCity(value);
        setCityError('');
        value !== city ? setFormIsTouched(true) : setFormIsTouched(false);
        break;
      case 'cvr':
        setCvr(value);
        setCityError(value);
        value !== cvr ? setFormIsTouched(true) : setFormIsTouched(false);
        break;
      default:
    }
  };

  const updateCompanyMasterData = (data) => {
    dispatch(
      companyUpdateInformation(props.companyDetails.referenceNumber, data)
    )
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          if (isManager(currentUser)) {
            dispatch(getCompaniesListForManagers());
          }

          dispatch(
            success(
              addNotification(
                strings.formatString(strings.editCompanySuccess, companyName),
                strings.editCompanySuccessTitle,
                notificationDismissDuration
              )
            )
          );
        }
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          notifError(
            addNotification(
              strings.errorEditCompany,
              strings.errorEditCompanyTitle,
              notificationDismissDuration
            )
          )
        );
      });
  };

  const saveFormData = () => {
    const errors = {};
    if (!companyName) {
      errors.companyName = strings.required;
      setCompanyNameError(errors.companyName);
    }
    if (!address) {
      errors.address = strings.required;
      setAddressError(errors.address);
    }
    if (!zipCode) {
      errors.zipCode = strings.required;
      setZipCodeError(errors.zipCode);
    } else if (zipCode.replace(/\s+/g, '').length !== 4) {
      errors.zipCode = strings.locationFlow_step3_regno_error;
      setZipCodeError(errors.zipCode);
    }
    if (!city) {
      errors.city = strings.required;
      setCityError(errors.city);
    }

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        companyName: companyName,
        address: address,
        zipCode: zipCode,
        city: city,
      };
      updateCompanyMasterData(data);
    } else {
      return;
    }
  };

  return (
    <div id='CompanyMasterDataComponent'>
      <Row>
        <Col xs='12' md='6' lg='6'>
          <h1 className='headline'>{companyName || ''}</h1>
        </Col>
        <Col xs='12' md='6' lg='6' className='company-extra-data'>
          <div>
            <span>{strings.companyMasterDataExtra}</span>
            <span>{company?.referenceNumber}</span>
            <span>|</span>
          </div>
          <div>
            <span>{strings.companyCreateDate}</span>
            <span>{moment(company?.createdDate).format('DD-MM-yyyy')}</span>
          </div>
        </Col>
      </Row>
      <div className='profile-component master-data custom-card'>
        <div className='wrapper'>
          <div className='descriptionWraper d-flex justify-content-between'>
            <div className='d-flex '>
              <div className='iconArea'>
                <i className='lnir lnir-house-heart' />
              </div>
              <div className='titleArea'>
                {strings.companyMasterDataHeading}
              </div>
            </div>
            <div xs='12' className='action-buttons'>
              {!loading && (
                <Button
                  color='blue'
                  className='btn-blue create-button'
                  disabled={!formIsTouched}
                  onClick={() => saveFormData()}
                >
                  <i className='di di-save' />
                  {strings.companyProfileSaveBtn}
                </Button>
              )}
              {loading && (
                <Button color='blue'>
                  <ButtonLoader />
                </Button>
              )}
            </div>
          </div>
          <div className='mt-4 row'>
            <div className='form-wrapper col-12 col-md-8'>
              <Row className='mb-15'>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.locationFlow_step1_name}</label>
                    {companyNameError && (
                      <span className='error'>{companyNameError}</span>
                    )}
                  </div>
                  <input
                    type='text'
                    value={companyName || ''}
                    onChange={(e) => {
                      updateField(e.target.value, 'companyName');
                    }}
                  />
                </Col>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.cvrLabel}</label>
                  </div>
                  <input
                    type='text'
                    value={phoneNoFormat(cvr) || ''}
                    maxLength={11}
                    disabled={true}
                    onChange={(e) => {
                      updateField(e.target.value, 'cvr');
                    }}
                  />
                </Col>
              </Row>
              <Row className='mb-15'>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.locationFlow_step1_adress}</label>
                    {addressError && (
                      <span className='error'>{addressError}</span>
                    )}
                  </div>
                  <input
                    type='text'
                    value={address || ''}
                    onChange={(e) => {
                      updateField(e.target.value, 'address');
                    }}
                  />
                </Col>
                <Col xs='12' md='6' className='col'>
                  <Row className='no-gutters'>
                    <Col xs='12' md='4' className='col pr-10'>
                      <div className='label-wrapper'>
                        <label>{strings.locationFlow_step3_regno}</label>
                      </div>
                      <input
                        type='text'
                        value={registrationNumber || ''}
                        disabled={true}
                      />
                    </Col>
                    <Col xs='12' md='8' className='col'>
                      <div className='label-wrapper'>
                        <label>{strings.locationFlow_step3_acountNo}</label>
                      </div>
                      <input
                        type='text'
                        value={cardNumber || ''}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='mb-15'>
                <Col xs='12' md='6' className='col'>
                  <Row className='no-gutters'>
                    <Col xs='12' md='4' className='col pr-10'>
                      <div className='label-wrapper'>
                        <label>{strings.zipLabel}</label>
                      </div>
                      <input
                        type='text'
                        value={zipCode || ''}
                        onChange={(e) => {
                          updateField(e.target.value, 'zipCode');
                        }}
                      />
                    </Col>
                    <Col xs='12' md='8' className='col'>
                      <div className='label-wrapper'>
                        <label>{strings.city}</label>
                        {cityError && (
                          <span className='error'>{cityError}</span>
                        )}
                        {zipCodeError && (
                          <span className='error'>{zipCodeError}</span>
                        )}
                      </div>
                      <input
                        type='text'
                        value={city || ''}
                        onChange={(e) => {
                          updateField(e.target.value, 'city');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs='12' md='6' className='col'>
                  <div className='d-flex master-data-info'>
                    <div className='iconArea'>
                      <i className='lnir lnir-shield' />
                    </div>
                    <span>{strings.companyMasterDataInfoText}</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='col-12 col-md-4 mt-25 descriptionWraper'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyMasterData;
