import React, { useState } from 'react';
import { Button } from 'reactstrap';
import './CreateNewCompanyChain.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SuccessfullCreation } from './SuccessfullCreation';
import { strings } from '../../../constants/localization';
import { createNewCompanyChain } from '../../../actions/companyChainActions';
import { toggleServerModalError } from '../../../actions/uiElementsActions';
import { formatDecimalsSpace } from '../../../constants/utils';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';

const CreateNewCompanyChain = ({ updateCallback }) => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [cvr, setCvr] = useState('');

  const [cvrError, setCvrError] = useState('');
  const [nameError, setNameError] = useState('');
  const [apiError, setApiError] = useState('');
  const [cvrExist, setCvrExist] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const changeValue = (e, field) => {
    const initialValue = e.target.value || e?.clipboardData?.getData('text');
    let possibleValue = initialValue;

    if (field === 'cvr' && possibleValue) {
      possibleValue = possibleValue.replace(/[^0-9\s]/g, '');
      possibleValue = possibleValue.substring(0, 11);

      if (possibleValue.length === 11) {
        setCvrError('');
      }

      const numericValue = possibleValue.replace(/\s/g, '');
      if (!/^\d+$/.test(numericValue)) {
        setCvrError(strings.errorOnlyNumbersAllowed);
      } else {
        setCvrError('');
      }
    } else {
      possibleValue = initialValue;
    }

    if (field === 'cvr') {
      setCvr(possibleValue);
    } else if (field === 'name') {
      setName(possibleValue);
    }
  };

  const CreateCompany = () => {
    setCvrError('');
    setNameError('');
    setApiError('');
    setCvrExist('');
    const regexedCvr = cvr.replace(/\s/g, '');

    if (name.length < 3) {
      setNameError(strings.companyNameShort);
    }

    setLoading(true);
    if (nameError === '' && cvrError === '') {
      dispatch(
        createNewCompanyChain({
          chainCompanyName: name,
          cvr: regexedCvr,
          brand: 'Sundhed+',
        })
      )
        .then((response) => {
          if (response.payload.status === 200) {
            setStep(1);
            setLoading(false);
            updateCallback(response.payload.data.id);
          }
        })
        .catch((e) => {
          const errorResponse = e.response.data.cause;
          if (errorResponse.includes('Bad Request: Company chain with cvr')) {
            setCvrExist(strings.formatString(strings.cvrExists, regexedCvr));
            setLoading(false);
            dispatch(toggleServerModalError(false));
          } else {
            setApiError(e.response.payload.cause);
            setLoading(false);
          }
        });
    } else {
      setCvrError(cvrError);
      setNameError(nameError);
      setLoading(false);
    }
  };

  return (
    <div className='createNewCompanyChain'>
      {step === 0 && (
        <>
          <div className='createNewCompanyChain-header'>
            <i className='lnil lnil-apartment' />
            <div className='title'>{strings.newChainTitle}</div>
          </div>
          <div className='content'>
            <label className='form_control_group'>
              <div className='form_text'>
                <div>{strings.name}</div>
              </div>
              <input
                className={`input form_input ${
                  nameError ? 'input_danger' : ''
                }`}
                onChange={(e) => changeValue(e, 'name')}
                placeholder=''
                type='text'
                value={name !== undefined ? name : ''}
              />
            </label>
            <label className='form_control_group'>
              <div className='form_text'>
                <div>{strings.cvrNumber}</div>
              </div>
              <input
                className={`input form_input ${cvrError ? 'input_danger' : ''}`}
                onChange={(e) => changeValue(e, 'cvr')}
                onPaste={(e) => changeValue(e, 'cvr')}
                placeholder=''
                // maxLength={11}
                type='text'
                value={formatDecimalsSpace(cvr) || ''}
                onBlur={(e) => {
                  if (e.target.value.length < 11) {
                    setCvrError(strings.errorCvcNew);
                  } else {
                    setCvrError('');
                  }
                }}
              />
            </label>

            <div className='actions_container'>
              <Button
                type='submit'
                className={`create-button ${
                  name !== '' && cvr !== '' ? 'btn-blue' : 'btn-light disabled'
                }`}
                onClick={CreateCompany}
                disabled={
                  name === '' ||
                  cvr === '' ||
                  loading ||
                  cvrError !== '' ||
                  nameError !== ''
                }
              >
                {loading ? (
                  <ButtonLoader />
                ) : (
                  <>
                    <i className='lnir lnir-circle-plus' />
                    <span>{strings.createActionButton}</span>
                  </>
                )}
              </Button>
              <div className='error'>
                {cvrError}
                {nameError}
                {apiError}
                {cvrExist}
              </div>
            </div>
          </div>
        </>
      )}
      {step === 1 && <SuccessfullCreation />}
    </div>
  );
};

CreateNewCompanyChain.propTypes = {
  updateCallback: PropTypes.func,
};

export default CreateNewCompanyChain;
