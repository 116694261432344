import React from 'react';

import './style.scss';

type SimpleCheckboxProps = {
  content?: string | string[];
  checked?: boolean;
  handleClick: () => void;
  customStyles?: string;
  textColor?: string;
};

const SimpleCheckbox: React.FC<SimpleCheckboxProps> = ({
  content = '',
  checked = false,
  handleClick,
  customStyles = '',
  textColor = '',
}) => (
  <div
    className={`SimpleCheckbox_component ${checked && 'checked'} ${
      customStyles && customStyles
    }`}
    onClick={handleClick}
  >
    <div className='SimpleCheckbox_component-icon'>
      {checked ? <i className='lnir lnir-checkmark' /> : ''}
    </div>
    <div
      className='SimpleCheckbox_component-content'
      style={textColor === '' ? undefined : (textColor as React.CSSProperties)}
    >
      {Array.isArray(content) ? content.join(' ') : content}
    </div>
  </div>
);

export default SimpleCheckbox;
