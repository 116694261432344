import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody } from 'reactstrap';
import './style.scss';
import { strings } from '../../../constants/localization';
import { onboardingTestData, Sundhedplus } from '../../../config';
import UploadDocumentationModal from './UploadDocumentationModal';
import UploadDocumentationSection from './UploadDocumentationSection';
import {
  updateDocumentationFromWs,
  clearNotifications,
} from '../../../actions/onboardingActions';
import {
  connectWebsocket,
  clearPendingDocumentationWebsocketMessage,
} from '../../../actions/websocketActions';
import { ReactComponent as InfoAltCircle } from '../../../resources/icons/information-alt.svg';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import ApprovedDocumentation from './ApprovedDocumentation';
import { error as notifError, success } from 'react-notification-system-redux';
import { notificationDismissDuration } from '../../../constants';

const UploadDocumentation = ({ step, setNextStep, toggleGuide }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding);
  const uploadedFiles = savedData?.uploadedDocumentation || [];
  const testMode = savedData?.data?.testMode || false;
  const [budgetFinalResponse, setMissingDocumentation] = useState([]);
  const [openTypeModal, setOpenTypeModal] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [showRedirectSpinner, setShowRedirectSpinner] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [websocketClient, setWebsocketClient] = useState(null);
  const wsDocumentationMessages = useSelector(
    (state) => state.websocketMessages?.documentation
  );

  // when the component is unmounted, clear the notifications so doesn't show up the rejected ones that weren't dismissed on the last step
  useEffect(() => {
    const usedUuid = testMode ? onboardingTestData?.leadUuid : savedData?.uuid;
    // connect websocket and save the ws client, in order to be able to disconnet on unmount
    if (usedUuid && !websocketClient) {
      const wsConnection = dispatch(connectWebsocket(usedUuid));
      setWebsocketClient(wsConnection.payload);
    }
    return () => {
      dispatch(clearNotifications());
      // disconnect websocket connection after unmount
      if (websocketClient) {
        websocketClient.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (
      Object.prototype.hasOwnProperty.call(savedData, 'budgetFinalResponse')
    ) {
      setMissingDocumentation(savedData?.budgetFinalResponse?.documentation);
    } else if (testMode) {
      setMissingDocumentation(onboardingTestData?.missingDocumentation);
    }
  }, [savedData]);

  // check for messages from websocket that require documentation status update
  useEffect(() => {
    // react to ws messages new entries
    if (wsDocumentationMessages?.length > 0) {
      const matchingObject = wsDocumentationMessages?.find((obj) =>
        uploadedFiles.find(
          (element) => element?.documentationType === obj.documentationType
        )
      );
      if (matchingObject?.websocketMessageType === 'DOCUMENTATION_UPDATED') {
        dispatch(updateDocumentationFromWs(matchingObject));
        //if the doucmentation is APPROVED, show success toast
        if (matchingObject?.documentationStatus === 'APPROVED') {
          dispatch(
            success({
              title: '',
              message: strings.formatString(
                strings.uploadStepToastSuccess,
                <b>{strings[matchingObject?.documentationType || '-']}</b>
              ),
              position: 'tr',
              autoDismiss: notificationDismissDuration,
            })
          );
        } else if (matchingObject?.documentationStatus === 'REJECTED') {
          dispatch(
            notifError({
              title: '',
              message: strings.formatString(
                strings.uploadStepToastError,
                <b>{strings[matchingObject?.documentationType || '-']}</b>
              ),
              position: 'tr',
              autoDismiss: 0,
            })
          );
        }

        if (matchingObject?.creditApplicationStatus === 'DONE') {
          setShowApproved(true);
        } else if (
          matchingObject?.creditApplicationStatus !== 'PENDING_DOCUMENTATION' &&
          matchingObject?.creditApplicationStatus !== 'PENDING_ACTIVATION'
        ) {
          setNextStep(0);
        }
      }
      // clear the ws message afetr the list update
      dispatch(clearPendingDocumentationWebsocketMessage());
    }
  }, [wsDocumentationMessages]);

  const renderBasedOnContinuousFlow = () => {
    return budgetFinalResponse?.map((section, index) => (
      <UploadDocumentationSection
        key={index}
        type={section?.type}
        amount={section?.amount || 0}
        openUploadModal={setOpenTypeModal}
        setCurrentSection={setCurrentSection}
        uploadedDocumentation={uploadedFiles?.find(
          (element) => element?.documentationType === section?.type
        )}
      />
    ));
  };

  const renderBasedOnInterruptedFlow = () => {
    return uploadedFiles?.map((section, index) => (
      <UploadDocumentationSection
        key={index}
        type={section?.documentationType}
        amount={section?.amount || 0}
        openUploadModal={setOpenTypeModal}
        setCurrentSection={setCurrentSection}
        uploadedDocumentation={section}
      />
    ));
  };

  const goToLastStep = () => {
    setShowRedirectSpinner(true);
    setTimeout(() => {
      setShowRedirectSpinner(false);
      setNextStep(step + 1);
    }, 2000);
  };

  return (
    <>
      {showRedirectSpinner ? (
        <LoadingSpinner
          title={strings.onboardingSpinnerBeforeApproveTitle}
          subtitle={strings.onboardingSpinnerBeforeApproveSubtitle}
        />
      ) : (
        <CardBody className='step-content'>
          <div className='step-title'>{strings.onboardingUploadDocTitle}</div>
          <div>
            <p>{strings.onboardingUploadDocP1}</p>
          </div>
          <div className='learn-more' onClick={() => toggleGuide()}>
            <InfoAltCircle className='information-alt' />
            <span>{strings.onboardingUploadDocLearnMore}</span>
          </div>

          {budgetFinalResponse.length > 0
            ? renderBasedOnContinuousFlow()
            : renderBasedOnInterruptedFlow()}

          <footer>
            <p>
              {strings.formatString(
                strings.onboardingUploadDocBottomText,
                <br />,
                <a href={`tel:${Sundhedplus.contactPhone}`}>
                  {Sundhedplus.contactPhoneHtmlFormated}
                </a>
              )}
            </p>
          </footer>

          {openTypeModal && (
            <UploadDocumentationModal
              type={currentSection?.type}
              amount={currentSection?.amount || 0}
              uploadedDocumentation={uploadedFiles?.find(
                (element) => element?.documentationType === currentSection?.type
              )}
              toggleModal={() => setOpenTypeModal(false)}
            />
          )}

          {/* documentation approved ws notification */}
          {showApproved && (
            <ApprovedDocumentation
              closeModal={() => setShowApproved(false)}
              goToLastStep={() => goToLastStep()}
            />
          )}
        </CardBody>
      )}
    </>
  );
};

export default UploadDocumentation;
