import {
  GET_PATIENT_INFORMATION,
  LOADING_PATIENT_INFORMATION,
  PATIENT_FOUNDED,
  PATIENT_NOT_FOUND,
  SERVER_URL,
  SET_SHOW_SMS_MODAL_FOR_ACCEPTANCE_INVOICE_FLOW,
  SET_SHOW_SMS_MODAL_FOR_REACTIVATION_CREDIT,
} from '../constants';
import {
  requestAxios as axios,
  isDentist,
  isManager,
} from '../constants/utils';
import { addPatientCredit } from './createCreditActions';
import { toggleServerModalError } from './uiElementsActions';
import { getLoggedInUserCredentials } from './userActions';

export function patientNotFounded(data = false) {
  return {
    type: PATIENT_NOT_FOUND,
    payload: data,
  };
}

export function showSmsAcceptanceFlowModal(data = false) {
  return {
    type: SET_SHOW_SMS_MODAL_FOR_ACCEPTANCE_INVOICE_FLOW,
    payload: data,
  };
}

export function showSmsReactivationCreditModal(data = false) {
  return {
    type: SET_SHOW_SMS_MODAL_FOR_REACTIVATION_CREDIT,
    payload: data,
  };
}

export function loadingPatientInformation(data = false) {
  return {
    type: LOADING_PATIENT_INFORMATION,
    payload: data,
  };
}

export function patientFounded(data = false) {
  return {
    type: PATIENT_FOUNDED,
    payload: data,
  };
}

export function addPatientCreditForNotAssociatedPatient(userInfo) {
  return async (dispatch) => {
    dispatch(loadingPatientInformation(true));

    try {
      const response = await dispatch(addPatientCredit(userInfo));

      if (response.status === 200) {
        const data = response.data;

        if (data.firstName === null && data.lastName === null) {
          dispatch(patientNotFounded(true));
          dispatch(loadingPatientInformation(false));
          return false; // Indicates no patient found
        } else {
          dispatch(patientFounded(true));
          dispatch(loadingPatientInformation(false));
          return true; // Indicates successful dispatch
        }
      }
      dispatch(toggleServerModalError(false));
      dispatch(patientNotFounded(true));
      dispatch(loadingPatientInformation(false));
      return false; // Indicates successful dispatch
    } catch (error) {
      console.log(error);
      dispatch(patientNotFounded(true));
      dispatch(loadingPatientInformation(false));
      dispatch(toggleServerModalError(false));
      return false; // Indicates an error occurred
    }
  };
}

export function getPatientInformationByCprAndLetters(userData, userInfo) {
  return async (dispatch, getState) => {
    dispatch(loadingPatientInformation(true));
    const user = getState().user.user;

    if (isDentist(user) || isManager(user)) {
      const success = await dispatch(
        addPatientCreditForNotAssociatedPatient(userInfo)
      );

      if (!success) {
        return; // Exit if the credit dispatch was not successful
      }
    }

    try {
      const response = await axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/leads/GET`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: {
          page: 0,
          searchedInput: userData.cpr,
        },
      });

      if (response.status === 200) {
        const firstTwoLetters =
          userData.letters[0].toUpperCase() + userData.letters[1].toLowerCase();

        const patient = response.data.content.find((patient) => {
          return (
            patient &&
            patient.leadClientInformation?.firstName?.startsWith(
              firstTwoLetters
            ) &&
            patient.leadClientInformation?.cpr === userData.cpr
          );
        });

        if (patient) {
          dispatch({
            type: GET_PATIENT_INFORMATION,
            payload: patient,
          });
          dispatch(patientFounded(true));
        } else {
          dispatch(patientNotFounded(true));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(toggleServerModalError(false));
    } finally {
      dispatch(loadingPatientInformation(false));
    }
  };
}
