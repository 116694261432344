import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import WarningCollapsible from './WarningCollapsible';
import { strings } from '../../../constants/localization';

const ApplicantWarnings = () => {
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const { warnings } = activeApplicationListItem || {};
  const warningsCounter = warnings ? warnings?.length : '0';
  // Divide the warnings array into two equal parts
  let leftColumnArray, rightColumnArray;
  if (warnings && warnings?.length > 0) {
    const midArray = Math.ceil(warnings.length / 2);
    leftColumnArray = warnings.slice(0, midArray);
    rightColumnArray = warnings.slice(midArray);
  }

  const renderWarningBasedOnText = (warningText, index) => {
    switch (warningText?.trim()) {
      case 'Ansøgeren er kontanthjælpsmodtager':
        return (
          <WarningCollapsible
            headline={strings.applicationWarningOption1Headline}
            iconType='info'
            key={index}
          >
            <p>{strings.applicationWarningOption1Description}</p>
          </WarningCollapsible>
        );
      case 'Manglende lønseddel inden for sidste halve år':
        return (
          <WarningCollapsible
            headline={strings.applicationWarningOption2Headline}
            iconType='info'
            key={index}
          >
            <p>{strings.applicationWarningOption2Description}</p>
          </WarningCollapsible>
        );
      case 'Ansøgeren har restance til det offentlige':
        return (
          <WarningCollapsible
            headline={strings.applicationWarningOption3Headline}
            iconType='info'
            key={index}
          >
            <p>{strings.applicationWarningOption3Description}</p>
          </WarningCollapsible>
        );
      default:
        return (
          <WarningCollapsible
            headline={warningText}
            iconType='info'
            key={index}
          >
            <p></p>
          </WarningCollapsible>
        );
    }
  };

  return (
    <section className='entity-status-card custom-card card'>
      <div className='card-body' id='warnings-section'>
        <div className='headline-section warnings'>
          {strings.applicationWarnings}
          <span className='warnings-counter'>{warningsCounter}</span>
        </div>

        <Row className='warning-grid'>
          <div className='left-column'>
            {leftColumnArray &&
              leftColumnArray?.length > 0 &&
              leftColumnArray.map((warning, index) =>
                renderWarningBasedOnText(warning?.warningText, index)
              )}
          </div>
          <div className='right-column'>
            {rightColumnArray &&
              rightColumnArray?.length > 0 &&
              rightColumnArray.map((warning, index) =>
                renderWarningBasedOnText(warning?.warningText, index)
              )}
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ApplicantWarnings;
