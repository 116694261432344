import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import {
  formatCurrencyDaStandard,
  checkUploadedAllRequiredDocumentation,
} from '../../../constants/utils';
import DentiModal from '../../../components/common/DentiModal';
import { onboardingTestData, uploadFileSizeLimit } from '../../../config';
import {
  uploadDocumentationFile,
  uploadNoDocuments,
  storeUploadedDocumentation,
  updateUploadedNote,
  updateStoredClientNote,
  updateStoredDocuments,
  addErrorLog,
  deleteUploadedDocument,
  uploadedRequiredFilesFinished,
} from '../../../actions/onboardingActions';
import { ReactComponent as FileNote } from '../../../resources/icons/file.svg';
import { ReactComponent as InfoCircle } from '../../../resources/icons/info-circle.svg';
import DeleteConfirm from './DeleteConfirm';
import DocumentationPreview from './DocumentationPreview';
import UploadLoading from './UploadLoading';
import UploadSuccess from './UploadSuccess';
import UploadError from './UploadError';
import CancelUpload from './CancelUpload';
import CancelEditNote from './CancelEditNote';
import FileValidationError from './FileValidationError';
import FileValidationLoading from './FileValidationLoading';
import FileValidationDuplicateError from './FileValidationDuplicateError';
import DeleteFileConfirmation from './DeleteFileConfirmation';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';

const UploadDocumentationModal = ({
  type,
  amount,
  uploadedDocumentation,
  toggleModal,
}) => {
  const noteEl = useRef();
  const textareaEl = useRef();
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const existingDocuments =
    useSelector((state) => state.onboarding?.uploadedDocumentation) || [];
  const requiredDocumentation =
    useSelector(
      (state) => state.onboarding?.budgetFinalResponse?.documentation
    ) || [];
  const uploadedAllRequiredDocuments = useSelector(
    (state) => state.onboarding?.uploadedAllRequiredDocuments
  );
  const { cpr, uuid, testMode } = savedData || {};
  const [budgetItmeValue, setBudgetItemValue] = useState(amount);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [filesToUploadArray, setFilesToUploadArray] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteUploadedConfirmation, setShowDeleteUploadedConfirmation] =
    useState(false);
  const [lastNoteSaved, setLastSavedNote] = useState('');
  const [note, setNote] = useState('');
  const [intialUploadedNote, setInitialUploadedNote] = useState('');
  const reader = new FileReader();
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [uploadImagePreview, setUploadImagePreview] = useState(false);
  const [showNoteEditor, setShowNoteEditor] = useState(false);
  const [closeNoteEditor, setCloseNoteEditor] = useState(true);
  // eslint-disable-next-line
  const [editorActive, setEditorActive] = useState(false);
  // const [editNoteMode, setEditNoteMode] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [showCancelEditNote, setShowCancelEditNote] = useState(false);
  const [showCancelUpload, setShowCancelUpload] = useState(false);
  const [exceededFileSizeLimit, setExceededFileSizeLimit] = useState(false);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [fileValidationLoading, setFileValidationLoading] = useState(false);
  const [duplicateFilenameError, setDuplicateFilenameError] = useState(false);
  const [duplicateFile, setDuplicateFile] = useState(null);
  const [hasFileDeleteError, setHasFileDeleteError] = useState(false);
  const [hasNoteUpdateError, setHasNoteUpdateError] = useState(false);
  const [loadingDeleteFile, setLoadingDeleteFile] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    if (uploadedDocumentation) {
      const newAmount = uploadedDocumentation?.newBudgetItemValue;
      if (
        Object.prototype.hasOwnProperty.call(uploadedDocumentation, 'documents')
      ) {
        setUploadedDocuments(
          uploadedDocumentation?.documents.length > 0
            ? uploadedDocumentation?.documents
            : []
        );
      }
      if (
        Object.prototype.hasOwnProperty.call(
          uploadedDocumentation,
          'clientNote'
        )
      ) {
        setNote(uploadedDocumentation?.clientNote || '');
        setInitialUploadedNote(uploadedDocumentation?.clientNote || '');
      }
      setBudgetItemValue(
        newAmount !== null && newAmount !== undefined ? newAmount : amount
      );
    }
  }, [
    uploadedDocumentation,
    uploadedDocumentation?.clientNote,
    uploadedDocumentation?.documents,
  ]);

  useEffect(() => {
    const triggerModalDisplay = () => {
      setTimeout(() => {
        setUploadSuccess(false);
        setUploadLoading(false);
        dispatch(uploadedRequiredFilesFinished(true));
        toggleModal();
      }, 2000);
    };

    if (
      !uploadedAllRequiredDocuments &&
      checkUploadedAllRequiredDocumentation(
        requiredDocumentation,
        existingDocuments
      )
    ) {
      triggerModalDisplay();
    }
  }, [existingDocuments]);

  const validateFilesBeforeAccept = (chosenFiles) => {
    setFileValidationLoading(true);
    // files is not an array, spread to get an array of files
    // actually is only one, sicne we have multiple=false
    const fillesArray = chosenFiles ? [...chosenFiles] : [];
    let fileSizeOk, fileTypeOk, fileNameOk;
    //add a timeout to show the loading first
    setTimeout(() => {
      setFileValidationLoading(false);
    }, 1000);

    if (fillesArray.length > 0) {
      const fileToValidate = fillesArray[0];

      // 1. check the size
      const fileSizeInKB = fileToValidate.size / 1024;
      if (fileSizeInKB < uploadFileSizeLimit) {
        fileSizeOk = true;
      } else {
        fileSizeOk = false;
      }
      setExceededFileSizeLimit(!fileSizeOk);

      // 2. check the type
      const fileType = fileToValidate?.type;
      if (
        fileType.split('/')[0] === 'image' ||
        fileType === 'application/pdf'
      ) {
        fileTypeOk = true;
      } else {
        fileTypeOk = false;
      }
      setInvalidFileType(!fileTypeOk);

      // 3. check the filename to be unique
      if (fileToValidate) {
        // check is there's already a file with the same name
        let foundDuplicateInUploaded, foundDuplicateInChosen;

        // check in the uploaded files
        if (uploadedDocumentation?.documents?.length > 0) {
          foundDuplicateInUploaded = uploadedDocumentation?.documents?.find(
            (doc) => doc?.fileName === fileToValidate.name
          );
        }

        // check in the chosen files, but not yet uploaded
        if (filesToUploadArray.length > 0) {
          foundDuplicateInChosen = filesToUploadArray?.find(
            (file) => file?.name === fileToValidate.name
          );
        }

        if (foundDuplicateInUploaded || foundDuplicateInChosen) {
          setDuplicateFile(fileToValidate);
          fileNameOk = false;
        } else {
          fileNameOk = true;
        }
      }
      setDuplicateFilenameError(!fileNameOk);
    }

    if (fileSizeOk && fileTypeOk && fileNameOk) {
      setFilesToUploadArray((prev) => [...prev, ...fillesArray]);
    }
    setFileValidationLoading(false);
  };

  const handleDuplicateFilenames = (filenames, newFilename) => {
    // Check if the newFilename already exists in the list
    if (filenames?.includes(newFilename)) {
      let suffix = 1;
      let modifiedFilename = newFilename;

      // Keep adding a numeric suffix until a unique filename is found
      while (filenames.includes(modifiedFilename)) {
        const filenameParts = newFilename.split('.');
        const extension = filenameParts.pop();
        const basename = filenameParts.join('.');
        modifiedFilename = `${basename}_${suffix}.${extension}`;
        suffix++;
      }

      return modifiedFilename;
    }

    return newFilename; // If it's not a duplicate, return the original filename
  };

  const acceptFileRenaming = () => {
    setDuplicateFilenameError(false);
    setFileValidationLoading(true);
    let filenamesOfUploaded = [],
      filenamesOfAdded = [];

    // if there are already files uploaded, get the filenames
    if (uploadedDocumentation?.documents?.length > 0) {
      filenamesOfUploaded = uploadedDocumentation?.documents?.map(
        (doc) => doc?.fileName
      );
    }
    if (filesToUploadArray.length > 0) {
      filenamesOfAdded = filesToUploadArray.map((file) => file?.name);
    }

    const modifiedFilename = handleDuplicateFilenames(
      filenamesOfUploaded.concat(filenamesOfAdded),
      duplicateFile?.name
    );

    const modifiedFile = new File([duplicateFile], modifiedFilename, {
      type: duplicateFile.type,
    });
    setFilesToUploadArray((prev) => [...prev, modifiedFile]);
    setFileValidationLoading(false);
  };

  const previewFile = (file) => {
    setOpenImagePreview(true);
    setUploadImagePreview(file);
    reader.onloadend = () => {
      if (file?.type === 'application/pdf') {
        setUploadImagePreview(file);
      } else {
        setUploadImagePreview(reader?.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setUploadImagePreview('');
    }
  };

  const uploadDocumentation = () => {
    const usedCpr = testMode ? onboardingTestData.cpr : cpr;
    const usedLeadUuid = testMode ? onboardingTestData.leadUuid : uuid;

    if (usedCpr && usedLeadUuid) {
      // if there are files to upload, upload them one by one
      if (filesToUploadArray.length > 0) {
        filesToUploadArray.forEach((file) => {
          setUploadLoading(true);

          dispatch(
            uploadDocumentationFile(
              usedCpr,
              usedLeadUuid,
              file,
              type,
              note || '',
              false
            )
          )
            .then((res) => {
              const {
                creditApplicationUuid,
                documentationId,
                documentationUuid,
                fileName,
                publicLink,
                clientNote,
                status,
              } = res?.payload?.data || {};
              setUploadLoading(false);
              setUploadSuccess(true);
              setUploadError(false);
              dispatch(
                storeUploadedDocumentation(
                  creditApplicationUuid,
                  type,
                  documentationId,
                  documentationUuid,
                  fileName,
                  publicLink,
                  clientNote,
                  '',
                  status
                )
              );
            })
            .catch((error) => {
              console.log(error);
              setUploadLoading(false);
              setUploadSuccess(false);
              setUploadError(true);
              // if (error?.status === 400) {
              //   setNextStep(0);
              // } else {
              //   setNextStep();
              // }

              dispatch(
                addErrorLog({
                  status: error?.response?.status,
                  message: error?.response?.data,
                })
              );
            });
        });
      } else if (note !== '') {
        // if there is a note, upload it without the file
        dispatch(uploadNoDocuments(usedCpr, type, note, usedLeadUuid))
          .then((res) => {
            const {
              creditApplicationUuid,
              documentationId,
              documentationUuid,
              fileName,
              publicLink,
              clientNote,
              status,
            } = res?.payload?.data || {};
            dispatch(
              storeUploadedDocumentation(
                creditApplicationUuid,
                type,
                documentationId,
                documentationUuid,
                fileName,
                publicLink,
                clientNote || note,
                '',
                status
              )
            );
            setUploadLoading(false);
            setUploadSuccess(true);
            setUploadError(false);
          })
          .catch((error) => {
            console.log(error);
            setUploadLoading(false);
            setUploadSuccess(false);
            setUploadError(true);

            dispatch(
              addErrorLog({
                status: error?.response?.status,
                message: error?.response?.data,
              })
            );
          });
      }
    } else {
      // Denied credit offer
      setUploadLoading(false);
      setUploadError(true);
      dispatch(
        addErrorLog('Could not upload documentation, missing cpr or uuid')
      );
    }
  };

  const removeFileToUpload = (file) => {
    const newFilesArray = filesToUploadArray.filter((item) => item !== file);
    setFilesToUploadArray(newFilesArray);
  };

  const updateNote = () => {
    const { creditApplicationUuid, documents, documentationType } =
      uploadedDocumentation || {};
    const docUuid = documents[0]?.documentationUuid;
    dispatch(updateUploadedNote(creditApplicationUuid, docUuid, note))
      .then(() => {
        dispatch(
          updateStoredClientNote(creditApplicationUuid, documentationType, note)
        );
        setHasNoteUpdateError(false);
        setLoadingUpdate(false);
      })
      .catch((error) => {
        console.log(error);
        setHasNoteUpdateError(true);
        setLoadingUpdate(false);
      });
  };

  const deleteDocument = () => {
    const { documentationUuid } = fileToDelete || {};
    setLoadingDeleteFile(true);
    dispatch(
      deleteUploadedDocument(
        uploadedDocumentation?.creditApplicationUuid,
        documentationUuid
      )
    )
      .then(() => {
        dispatch(
          updateStoredDocuments(
            uploadedDocumentation?.creditApplicationUuid,
            documentationUuid
          )
        );
        setLoadingDeleteFile(false);
        setShowDeleteUploadedConfirmation(false);
        setHasFileDeleteError(false);
      })
      .catch((error) => {
        setLoadingDeleteFile(false);
        setShowDeleteUploadedConfirmation(false);
        setHasFileDeleteError(true);
        console.log(error);
      });
  };

  const undoEditNote = () => {
    setCloseNoteEditor(true);
    if (!intialUploadedNote) {
      setShowNoteEditor(false);
    }
    setEditorActive(false);
    if (intialUploadedNote) {
      setNote(intialUploadedNote);
    } else {
      setNote(lastNoteSaved);
    }
  };

  const renderUploadContent = () => {
    const stringAmountOrPercentage =
      type !== 'SPLIT_EXPENSES'
        ? `${formatCurrencyDaStandard(budgetItmeValue)}/md`
        : `${budgetItmeValue !== 0 ? budgetItmeValue * 100 : 0} %`;
    return (
      <DentiModal
        key={1}
        close={() => toggleModal()}
        modalClass='onboarding upload-doc-modal'
        modalClassName='upload-modal'
        closeBtn={false}
      >
        <div>
          <h1 className='headline text-center mt-2 mb-20'>{strings[type]}</h1>
        </div>
        <section className='upload-section'>
          {/* TOP HEADING */}
          <div className='top'>
            <span className='type'>
              {strings.onboardingUploadDocModalSectionText}
            </span>
            <span className='amount'>{stringAmountOrPercentage}</span>
          </div>
          {/* FILES CONTAINER */}
          <div className='files-container'>
            {/* files already uploaded */}
            {uploadedDocuments.length > 0 && (
              <ul>
                {uploadedDocuments.map((doc, i) => {
                  if (doc?.fileName && doc?.fileName !== 'no-file') {
                    return (
                      <li key={i}>
                        <span className='grouped'>
                          <i className='lnir lnir-empty-file icon' />
                          <div
                            className='filename'
                            onClick={() => previewFile(doc?.publicLink)}
                          >
                            {doc?.fileName}
                          </div>
                          <i
                            className='lnir lnir-close icon close-icon'
                            onClick={() => {
                              setFileToDelete(doc);
                              setShowDeleteUploadedConfirmation(true);
                            }}
                          />
                        </span>

                        {hasFileDeleteError && (
                          <span className='delete-warning'>
                            <InfoCircle className='info-circle' />
                            {strings.onboardingWarningDeleteDocFailed}
                          </span>
                        )}
                      </li>
                    );
                  } else return null;
                })}
              </ul>
            )}

            {/* files to upload */}
            {filesToUploadArray.length > 0 && (
              <ul>
                {filesToUploadArray.map((file, i) => {
                  return (
                    <li key={i}>
                      <span className='grouped'>
                        <i className='lnir lnir-empty-file icon' />
                        <div
                          className='filename'
                          onClick={() => previewFile(file)}
                        >
                          {file.name}
                        </div>
                        <i
                          className='lnir lnir-close icon close-icon'
                          onClick={() => {
                            setFileToDelete(file);
                            setShowDeleteConfirmation(true);
                          }}
                        />
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}

            {/* editor */}
            {(showNoteEditor || note !== '') && (
              <div className='note-wrapper'>
                <div className='header' ref={noteEl}>
                  <FileNote className='file-note' />
                  <span>{strings.onboardingUploadNoteHeadline}</span>
                  {/* edit existing note or add a new note */}
                  {(intialUploadedNote || note !== intialUploadedNote) &&
                    !editorActive && (
                      <div
                        onClick={() => {
                          setShowNoteEditor(true);
                          setCloseNoteEditor(false);
                          setEditorActive(true);
                        }}
                      >
                        <i className='lnir lnir-pencil-alt'></i>
                      </div>
                    )}
                </div>

                {/* EDIT NOTE AREA */}
                <div
                  className={`panel-editor ${
                    closeNoteEditor ? 'invisible' : ''
                  }`}
                >
                  <textarea
                    ref={textareaEl}
                    className='client-note'
                    value={note || ''}
                    placeholder='Tekstbesked...'
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />

                  {hasNoteUpdateError && (
                    <span className='delete-warning'>
                      <InfoCircle className='info-circle' />
                      {strings.onboardingWarningUpdateNoteFailed}
                    </span>
                  )}
                  <div className='actions-buttons note'>
                    <Button
                      className='btn btn-blue btn-cancel'
                      onClick={() => {
                        if (
                          // Case 1: Note has been changed from the initial uploaded note
                          ((intialUploadedNote &&
                            note &&
                            note !== intialUploadedNote) ||
                            // Case 2: Note is different from the last note saved
                            (note &&
                              lastNoteSaved &&
                              note !== lastNoteSaved)) &&
                          // Ensure note is not the same as lastNoteSaved
                          !(note && lastNoteSaved && note === lastNoteSaved)
                        ) {
                          setShowCancelEditNote(true);
                        } else {
                          undoEditNote();
                          if (lastNoteSaved) {
                            setNote(lastNoteSaved);
                          }
                        }
                      }}
                    >
                      <span>{strings.onboardingEditNoteCancel}</span>
                    </Button>

                    <Button
                      className='btn btn-blue btn-success'
                      disabled={
                        note === intialUploadedNote || note === lastNoteSaved
                      }
                      onClick={() => {
                        setLastSavedNote(note);
                        setEditorActive(false);
                        setCloseNoteEditor(true);
                      }}
                    >
                      <span>{strings.onboardingEditNoteSave}</span>
                    </Button>
                  </div>
                </div>

                {/* END EDIT NOTE AREA */}
              </div>
            )}
          </div>

          {/* ADD FILES BUTTON */}
          {!editorActive && (
            <div className='bottom-area'>
              <input
                id={`files-${type}`}
                className='hidden'
                type='file'
                accept='image/*,application/pdf'
                onChange={(event) => {
                  const chosenFiles = event?.target?.files;
                  validateFilesBeforeAccept(chosenFiles);
                }}
                //multiple
              />
              <label htmlFor={`files-${type}`} className='addItemBtn'>
                <i className='lnir lnir-add-files icon' />
                <span>
                  {/* {filesToUploadArray.length === 0 &&
                    uploadedDocuments.length === 0
                      ? strings.onboardingUploadDocBtnSection
                      : (uploadedDocuments.length > 0 &&
                          uploadedDocuments[0]?.fileName) ||
                        filesToUploadArray.length > 0
                      ? strings.onboardingUploadMoreDocBtnSection
                      : ''} */}
                  {strings.onboardingUploadMoreDocBtnSection}
                </span>
              </label>

              {note === '' && (
                <button
                  type='button'
                  className='addItemBtn'
                  onClick={() => {
                    setShowNoteEditor(true);
                    setEditorActive(true);
                    // wait for the textarea to show, then focus
                    setTimeout(() => {
                      textareaEl.current.focus();
                    }, 500);
                    setCloseNoteEditor(false);
                  }}
                >
                  <FileNote className='file-note' />
                  <span>{strings.onboardingUploadNoteBtnSection}</span>
                </button>
              )}
            </div>
          )}
        </section>

        {!editorActive && (
          <>
            <p className='upload-tip'>{strings.onboardingUploadTip}</p>
            {/* CANCEL/SAVE BUTTONS */}
            <div className='actions-buttons step-buttons'>
              <Button
                className='btn btn-blue btn-cancel'
                onClick={() => {
                  if (
                    filesToUploadArray.length > 0 ||
                    (uploadedDocumentation?.clientNote &&
                      note !== uploadedDocumentation?.clientNote)
                  ) {
                    setShowCancelUpload(true);
                  } else {
                    toggleModal();
                  }
                }}
              >
                {strings.onboardingUploadDocNoBtn}
              </Button>
              {(filesToUploadArray.length > 0 || note !== '') && (
                <Button
                  className='btn btn-blue btn-success'
                  onClick={() => {
                    setLoadingUpdate(true);
                    // if there are files to upload, upload them
                    // otherwise update note
                    if (
                      intialUploadedNote !== '' &&
                      note !== intialUploadedNote &&
                      filesToUploadArray.length === 0
                    ) {
                      updateNote();
                    } else {
                      uploadDocumentation();
                    }
                  }}
                >
                  {loadingUpdate ? (
                    <ButtonLoader />
                  ) : (
                    strings.onboardingUploadDocYesBtn
                  )}
                </Button>
              )}
            </div>
          </>
        )}
      </DentiModal>
    );
  };

  const renderImagePreview = () => {
    return (
      <DocumentationPreview
        file={uploadImagePreview}
        closeModal={() => setOpenImagePreview(false)}
      />
    );
  };

  const renderDeleteFileConfirmation = () => {
    return (
      <DeleteConfirm
        file={fileToDelete}
        closeModal={() => setShowDeleteConfirmation(false)}
        deleteFile={removeFileToUpload}
      />
    );
  };

  const renderModal = () => {
    return (
      <>
        {/* main modal */}
        {!openImagePreview &&
          !showDeleteConfirmation &&
          !showCancelUpload &&
          !uploadLoading &&
          !uploadSuccess &&
          !uploadError &&
          !showDeleteUploadedConfirmation &&
          renderUploadContent()}

        {/* image preview modal */}
        {openImagePreview && renderImagePreview()}

        {/* delete added file confirmation modal */}
        {showDeleteConfirmation && renderDeleteFileConfirmation()}

        {/* delete uploaded file confirmation modal */}
        {showDeleteUploadedConfirmation && (
          <DeleteFileConfirmation
            closeModal={() => setShowDeleteUploadedConfirmation(false)}
            confirmAction={() => deleteDocument()}
            loadingDelete={loadingDeleteFile}
          />
        )}

        {/* upload loading modal */}
        {uploadLoading && <UploadLoading closeModal={toggleModal} />}

        {/* upload success modal */}
        {uploadSuccess && <UploadSuccess closeModal={toggleModal} />}

        {/* upload error modal */}
        {uploadError && <UploadError closeModal={toggleModal} />}

        {/* cancel upload modal */}
        {showCancelUpload && (
          <CancelUpload
            closeModal={toggleModal}
            cancelAction={() => setShowCancelUpload(false)}
          />
        )}

        {/* cancel edit note modal */}
        {showCancelEditNote && (
          <CancelEditNote
            noAction={() => setShowCancelEditNote(false)}
            yesAction={() => {
              undoEditNote();
              setShowCancelEditNote(false);
            }}
          />
        )}

        {/* start the file validation */}
        {fileValidationLoading && (
          <FileValidationLoading
            closeModal={() => {
              setFileValidationLoading(false);
            }}
          />
        )}

        {/* exceeded file size or wrong format modal */}
        {(exceededFileSizeLimit || invalidFileType) && (
          <FileValidationError
            closeModal={() => {
              setExceededFileSizeLimit(false);
              setInvalidFileType(false);
            }}
          />
        )}

        {/* duplicate filename error */}
        {duplicateFilenameError && (
          <FileValidationDuplicateError
            closeModal={() => {
              setDuplicateFilenameError(false);
            }}
            acceptAction={acceptFileRenaming}
          />
        )}
      </>
    );
  };

  return renderModal();
};

export default UploadDocumentationModal;
