import {
  GET_LOGS_BY_CREDIT_FACILITY,
  GET_COMPANY_CHAIN_LOGS,
  GET_LOGS_BY_COMPANIES,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_LOGS_BY_CREDIT_FACILITY:
      return {
        ...state,
        [action.payload.creditFacilityNumber]: action.payload.data,
      };
    case GET_COMPANY_CHAIN_LOGS:
      return { chainLogs: action.payload.data };
    case GET_LOGS_BY_COMPANIES:
      return { companyLogs: action.payload.data };
    default:
      return state;
  }
}
