import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import sundhedLogo from '../../../resources/images/sundhedplus-logo-invert.svg';
import { brandConfig } from '../../../config';
import { ROUTE_HOME } from '../../../constants/routes';
import './style.scss';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import { strings } from '../../../constants/localization';

import {
  getUnsubscribeBookingRequest,
  stopBookingRequest,
} from '../../../actions/bookingRequestActions';
import ErrorPage from '../../common/ErrorPage';

export default function UnsubscribeBookingRequest() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successfullyUnsubscribed, setSuccessfullyUnsubscribed] =
    useState(false);
  const [bookingRequestToken, setBookingRequestToken] = useState('');
  const [companyReferenceNumber, setCompanyReferenceNumber] = useState('');
  const [bookingRequestCompanyEmail, setBookingRequestCompanyEmail] =
    useState('');
  const [companySlugUrl, setCompanySlugUrl] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const dispatch = useDispatch();

  const getToken = useCallback(() => {
    const link = window.location.href;
    const url = new URL(link);
    const token = url.searchParams.get('token');
    const referenceNumber = url.searchParams.get('referenceNumber');
    setBookingRequestToken(token);
    setCompanyReferenceNumber(referenceNumber);
    setLoading(true);
    dispatch(getUnsubscribeBookingRequest(token, referenceNumber)).then(
      (response) => {
        const { data } = response.payload;
        if (response.payload.status === 200) {
          setLoading(false);
          setBookingRequestCompanyEmail(data.bookingRequestCompanyEmail);
          setCompanySlugUrl(data.companySlugUrl);
        } else {
          setLoading(false);
          setError(true);
        }
      }
    );
  }, [dispatch]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const unsubscribeBookingRequest = () => {
    setDisableButton(true);
    const data = {
      bookingRequestCompanyEmail,
      bookingRequestToken,
      companyReferenceNumber,
    };
    dispatch(stopBookingRequest(data)).then((response) => {
      if (response.payload.status === 200) {
        setLoading(false);
        setSuccessfullyUnsubscribed(true);
      } else {
        setLoading(false);
        setError(false);
        setSuccessfullyUnsubscribed(false);
      }
    });
  };

  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated
      : brandConfig.contactPhoneHtmlFormated;

  const brandEnvVariable = process.env.REACT_APP_BRAND;

  return (
    <div id={`${brandEnvVariable}`}>
      <div className='unsubscribeBookingRequestPage'>
        {loading && <LoadingMessage />}
        {!loading && !error && !successfullyUnsubscribed && (
          <div className='unsubscribeBookingRequest-wrapper'>
            {brandEnvVariable === 'Sundhedplus' ? (
              <div className='sundhed-plus-logo'>
                <NavLink to={ROUTE_HOME}>
                  <img
                    className='sundhed-logo'
                    src={sundhedLogo}
                    alt={`${brandConfig.logoAltText}`}
                  />
                </NavLink>
              </div>
            ) : (
              <div className='denti-logo'>
                <div className='d-logo logo-green'>
                  <NavLink to={ROUTE_HOME}>
                    <i className='di di-denti' />
                  </NavLink>
                </div>
              </div>
            )}

            <div className='unsubscribeBooking-content'>
              <div className='unsubscribeBooking-body'>
                <div className='unsubscribeBooking-header'>
                  {strings.unsubscribeBookingRequest_title}
                </div>
                <div className='unsubscribeBooking-text'>
                  {strings.formatString(
                    strings.unsubscribeBookingRequest_text1,
                    <a
                      className='color'
                      href={brandConfig.mainPageUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {brandConfig.site}
                    </a>
                  )}
                </div>
                <div className='unsubscribeBooking-text'>
                  {strings.formatString(
                    strings.unsubscribeBookingRequest_text2,
                    <a
                      className='color'
                      href={companySlugUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {strings.unsubscribeProfile}
                    </a>
                  )}
                </div>
                <div className='unsubscribeBooking-text'>
                  {strings.formatString(
                    strings.unsubscribeBookingRequest_text3,
                    <a
                      className='color'
                      href={`mailto:${brandConfig.generalEmail}`}
                    >
                      {brandConfig.generalEmail}
                    </a>,
                    <a
                      className='color'
                      href={brandConfig.SundhedplusForBehandlere}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {strings.unsubscribeBookingRequest_here}
                    </a>
                  )}
                </div>
                <div className='unsubscribeBooking-button d-flex justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-blue btn-size'
                    onClick={() => {
                      unsubscribeBookingRequest();
                    }}
                    disabled={disableButton}
                  >
                    {strings.unsubscribeBookingRequest_button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {error && !successfullyUnsubscribed && (
          <ErrorPage>
            <h1>{strings.unsubscribeBookingRequest_error_title}</h1>
            <p>{strings.unsubscribeBookingRequest_error_text}</p>
            <p>
              {strings.formatString(
                strings.errorLinkExpiredP2,

                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>
                  {phoneNumber}
                </a>
              )}
            </p>
          </ErrorPage>
        )}
        {successfullyUnsubscribed && !error && (
          <ErrorPage>
            <h1>{strings.unsubscribeBookingRequest_success_title}</h1>
            <p>{strings.unsubscribeBookingRequest_success_text}</p>
          </ErrorPage>
        )}
      </div>
    </div>
  );
}
