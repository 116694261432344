import React, { useEffect, useRef, useState } from 'react';
import { strings } from '../../constants/localization';
import MaskedInput from 'react-text-mask';
import { Button } from 'reactstrap';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import { patientNotFounded } from '../../actions/registerInvoiceActions';
import { getPatientInformationByCprAndLetters } from '../../actions/registerInvoiceActions';
import RegisterInvoiceIcon from '../../resources/icons/add_credit.svg';

interface LookupPatientProps {
  companyUserAssociated: string;
  companyReferenceNumber: string;
  setCurrentCase: React.Dispatch<React.SetStateAction<number>>;
  setPatientData: React.Dispatch<
    React.SetStateAction<{ cpr: string; letters: string }>
  >;
}

const LookupPatient: React.FC<LookupPatientProps> = ({
  setCurrentCase,
  setPatientData,
  companyUserAssociated,
  companyReferenceNumber,
}) => {
  const [patientCpr, setPatientCpr] = useState<string>('');
  const [patientLetters, setPatientLetters] = useState<string>('');
  const [patientInfo, setPatientInfo] = useState<{
    cpr: string;
    letters: string;
  }>({
    cpr: '',
    letters: '',
  });

  const dispatch = useDispatch();

  const patientLoading = useSelector(
    (state: { registerInvoiceFlow?: { loadingPatientInformation: boolean } }) =>
      state.registerInvoiceFlow?.loadingPatientInformation || false
  );

  const patientNotFound = useSelector(
    (state: { registerInvoiceFlow?: { patientNotFound: boolean } }) =>
      state.registerInvoiceFlow?.patientNotFound || false
  );

  const patientFounded = useSelector(
    (state: { registerInvoiceFlow?: { patientFounded: boolean } }) =>
      state.registerInvoiceFlow?.patientFounded || false
  );

  const maskCharRegex = /_/g;
  const cprInputRef = useRef(null);
  const letterInputRef = useRef(null);

  const resetPosition = (inputField: HTMLInputElement) => {
    // Ensure inputField is an HTMLInputElement before proceeding
    if (!(inputField instanceof HTMLInputElement)) {
      throw new Error('resetPosition: inputField must be an HTMLInputElement');
    }

    const desiredCaretPos = 0; // Assuming you want to reset to the beginning

    if (typeof inputField.setSelectionRange === 'function') {
      inputField.focus();
      inputField.setSelectionRange(desiredCaretPos, desiredCaretPos);
    } else if (document.getSelection) {
      const selection = document.getSelection();

      if (selection) {
        let oSel: Range | null = selection.getRangeAt(0); // Get existing range or create a new one

        if (!oSel) {
          oSel = document.createRange(); // Create a new range if none exists
        }

        oSel.setStart(inputField, desiredCaretPos);
        oSel.setEnd(inputField, desiredCaretPos);
        selection.removeAllRanges();
        selection.addRange(oSel);
      } else {
        // Consider a fallback for browsers that don't support getSelection
        console.warn(
          'resetPosition: Could not reset caret position due to browser incompatibility.'
        );
      }
    } else {
      // Consider a fallback for unsupported browsers that don't support either approach
      console.warn(
        'resetPosition: Could not reset caret position due to browser incompatibility.'
      );
    }
  };

  useEffect(() => {
    resetPosition(cprInputRef.current.inputElement);
    if (patientInfo.cpr !== '' && patientInfo.letters !== '') {
      const userInfo = {
        clientCpr: patientInfo.cpr,
        clientName: patientInfo.letters,
        companyReferenceNumber: companyReferenceNumber,
      };

      dispatch(
        // eslint-disable-next-line
        getPatientInformationByCprAndLetters(patientInfo, userInfo) as any
      );
      setPatientData({ cpr: patientInfo.cpr, letters: patientInfo.letters });
    }
  }, [patientInfo]);

  useEffect(() => {
    if (patientNotFound) {
      setCurrentCase(null);
    }
  }, [patientNotFound]);

  const buttonDisabled = () => {
    const cprValue = patientCpr.replace(maskCharRegex, '');
    const letterValue = patientLetters.replace(maskCharRegex, '');

    if (cprValue !== '' && letterValue !== '' && letterValue.length >= 2) {
      return false;
    }
    return true;
  };

  const setCprNumber = (value: string) => {
    if (patientNotFound) {
      dispatch(patientNotFounded(false));
    }
    const newValue = value.replace(maskCharRegex, '');
    // remove formatting from cpr
    const valueWIthoutFormatting = newValue.replace(/-/g, '');
    setPatientCpr(valueWIthoutFormatting);

    if (newValue.length > 10) {
      resetPosition(letterInputRef.current.inputElement);
    }
  };

  const setNameLetters = (value: string) => {
    const newValue = value.replace(maskCharRegex, '').toUpperCase();
    setPatientLetters(newValue);
  };

  useEffect(() => {
    if (patientFounded && !patientLoading) {
      setCurrentCase(2);
    }
  }, [patientFounded, patientLoading]);

  return (
    <div className='step-content'>
      <div className='step-header'>
        <div className='logo'>
          <img src={RegisterInvoiceIcon} alt='Register Invoice' />
        </div>
        <div className='title-text'>
          <h2>{strings.registerInvoiceFlowLookupPatientTitle}</h2>
        </div>
        <div className='step-subtitle'>
          <p>{strings.registerInvoiceFlowLookupPatientSubTitle}</p>
        </div>
      </div>
      <div className='step-body'>
        <div className='input-container'>
          <div className='form-group'>
            <label className='patientCpr'>
              {strings.registerInvoiceFlowMembersCPR}
            </label>
            <MaskedInput
              ref={cprInputRef}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              type='text'
              className='form-control'
              id='patientCpr'
              value={patientCpr}
              guide
              showMask
              onChange={(event) => setCprNumber(event.target.value)}
            />
          </div>
          <div className='form-group'>
            <label className='patientLetters'>{strings.apLettersName}</label>
            <MaskedInput
              ref={letterInputRef}
              mask={[/[a-zA-ZåæéøÅÆÉØ]/, /[a-zA-ZåæéøÅÆÉØ]/]}
              value={patientLetters}
              type='text'
              className='form-control'
              id='patientLetters'
              guide
              showMask
              onChange={(event) => setNameLetters(event.target.value)}
            />
          </div>
        </div>

        <div className='text-center'>
          <p>
            {strings.formatString(
              strings.registerInvoiceFlowLookupPatientParagraph,
              companyUserAssociated
            )}
          </p>
        </div>
        <div className='button-actions'>
          <Button
            color='blue'
            className='btn-add-patient'
            onClick={() => {
              if (!buttonDisabled()) {
                setPatientInfo({ cpr: patientCpr, letters: patientLetters });
              }
            }}
            disabled={buttonDisabled()}
          >
            {!patientLoading ? (
              strings.registerInvoiceFlowButton
            ) : (
              <ButtonLoader />
            )}
          </Button>
        </div>
      </div>
    </div>
    
  );
};

export default LookupPatient;
