import React, { useState, useEffect } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../constants/localization';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { storeOnboardingData } from '../../../actions/onboardingActions';

const CivilStatus = ({ setNextStep, step }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingCivilStatusTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/sambo',
        stepTitle: 'onboarding - sambo',
        event: 'onboarding',
      });
    }
  }, []);

  const handleNoButtonClick = () => {
    setButtonLoading(true);
    dispatch(storeOnboardingData({ ...savedData, civilStatus: 'alone' }));
    setNextStep(step + 1);
  };

  const handleYesButtonClick = () => {
    setButtonLoading(true);
    dispatch(storeOnboardingData({ ...savedData, civilStatus: 'married' }));
    setNextStep(step + 1);
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title mt-4'>
        {strings.onboardingCivilStatusTitle}
      </div>
      <div className='step-subtitle center bold mt-4 mb-4 p-4'>
        <p>{strings.onboardingCivilStatusSubtitle}</p>
      </div>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue expensesChoiceBtn'
          value={false}
          disabled={buttonLoading}
          onClick={handleNoButtonClick}
        >
          {buttonLoading ? (
            <ButtonLoader />
          ) : (
            strings.onboardingCivilStatusOptionNo
          )}
        </Button>
        <Button
          className='btn btn-blue expensesChoiceBtn'
          value
          disabled={buttonLoading}
          onClick={handleYesButtonClick}
        >
          {buttonLoading ? (
            <ButtonLoader />
          ) : (
            strings.onboardingCivilStatusOptionYes
          )}
        </Button>
      </div>
    </CardBody>
  );
};

export default CivilStatus;
