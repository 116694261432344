import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';
import { getURLFileExtension } from '../../../constants/utils';

const DocumentationPreview = ({ file, closeModal }) => {
  const [fileType, setFileType] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  useEffect(() => {
    if (typeof file === 'string') {
      setFileType('url');
      setFileExtension(getURLFileExtension(file));
    } else {
      setFileType('object');
    }
  }, [file]);

  return (
    <DentiModal
      key={2}
      close={() => closeModal()}
      modalClass='onboarding upload-doc-modal'
      modalClassName='upload-modal'
      closeBtn={true}
    >
      <div className='preview-modal'>
        {/* FILE IS AN URL         */}
        {fileType === 'url' && fileExtension === 'pdf' && (
          <div className='pdf-viewer preview-image'>
            <object data={file} width='100%'>
              <p>{file}</p>
            </object>
          </div>
        )}

        {/* FILE IS AN OBJECT         */}
        {fileType === 'object' && file?.type === 'application/pdf' && (
          <iframe
            src={URL.createObjectURL(file)}
            id='embededPDF'
            width='100%'
            height='500'
            title='PDF preview'
          ></iframe>
        )}

        {fileType !== 'object' && fileExtension !== 'pdf' && (
          <div className='img-viewer preview-image'>
            <img src={file} />
          </div>
        )}

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => closeModal()}
          >
            {strings.close}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default DocumentationPreview;
