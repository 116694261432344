import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { strings } from '../../../constants/localization';

import EntityInformationTabs from '../../common/EntityInformationTabs';
import LogDetails from './lead-options/LogDetails';
import LoanRestructure from './lead-options/LoanRestructure';
import ListOfAssociatedClinics from './ListOfAssociatedClinics/ListOfAssociatedClinics';

const LeadOptions = ({ lead, instalmentLoans }) => {
  let hasAccepted = false;

  if (lead && lead.creditFacilityInformation.installmentLoanInformations.length > 0) {
    lead.creditFacilityInformation.installmentLoanInformations.forEach((data) => {
      if (data.status === 'ACCEPTED') {
        hasAccepted = true;
      }
    })
  }

  return (
    <EntityInformationTabs
      id={lead.id}
      tabsContent={[
        {
          name: strings.clinicAccess,
          getContent: () => <ListOfAssociatedClinics
            creditFacilityNumber={lead.creditFacilityInformation.number}
            baseClinic={lead.companyReferenceNumber}
            clientCpr={lead.leadClientInformation.cpr}
            clientName={`${lead.leadClientInformation.firstName} ${lead.leadClientInformation.lastName}`}
                            />,
          show: true
        },
        lead && lead.creditFacilityInformation.installmentLoanInformations.length && hasAccepted
          ? {
            name: strings.restTitle,
            getContent: (activeTab, setActiveTab) => <LoanRestructure setActiveTab={setActiveTab} />,
            show: instalmentLoans !== undefined && instalmentLoans.length > 0
          } : undefined,
        {
          name: strings.logTab,
          getContent: (activeTab) => <LogDetails activeTab={activeTab} />,
          show: !!(lead && lead.creditFacilityInformation)
        },
      ]}
    />
  )
}

LeadOptions.propTypes = {
  lead: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    instalmentLoans: state.lead.leadLoans,
  };
}

export default connect(mapStateToProps)(LeadOptions);
