import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isAdmin, isManager, isDentist } from '../../constants/utils';
import { ReactComponent as AddPatient } from '../../resources/icons/add-user-alt.svg';
import { ReactComponent as SavingPig } from '../../resources/icons/saving-pig.svg';
import { ReactComponent as UserIcon } from '../../resources/icons/user-alt-5.svg';
import sundhedLogo from '../../resources/images/sundhedplus-logo-without-heart.svg';
import { brandConfig, config } from '../../config';

import {
  ROUTE_LEADS,
  ROUTE_COMPANIES,
  ROUTE_PATIENTS_DUNNING,
  ROUTE_COMPANY_CHAINS,
  ROUTE_SETTINGS,
  ROUTE_DOCUMENTATION,
  ROUTE_CREDIT_APPLICATIONS,
  ROUTE_HOME,
  ROUTE_MIT_SUNDHEDPLUS,
  ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
  ROUTE_MIT_SUNDHEDPLUS_HELP,
  ROUTE_MAIN_LOGIN,
} from '../../constants/routes';
import { strings } from '../../constants/localization';
import { clearPendingDocumentationWebsocketMessage } from '../../actions/websocketActions';
import { userLogout } from '../../actions/userActions';
import { clearMemberPortalData } from '../../actions/memberPortalActions.ts';

const Sidebar = ({ toggleModal, modalIsOpened, toggleMenu, isDashboard }) => {
  const location = useLocation();
  const pathname = location?.pathname;
  const currentDomain = window.location.hostname;
  const isLocalhost = currentDomain === 'localhost';
  const [shouldShowAddPatientButton, setShouldShowAddPatientButton] =
    useState(true);
  const [smallScreenSize, setSmallScreenSize] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const isAuth = useSelector((state) => state.user.isAuth);
  const companyChainStatus = useSelector(
    (state) => state.companyChain?.companyChainStatus
  );

  const counterPendingDocuments = useSelector(
    (state) => state.onboardingDocumentation?.counterPendingDocuments
  );
  const [pendingDocumentationCount, setPendingDocumentationCount] = useState(
    counterPendingDocuments
  );
  const [openSections, setOpenSections] = useState({
    leads: pathname === ROUTE_LEADS,
    companies: pathname === ROUTE_COMPANIES,
    companyChains: pathname === ROUTE_COMPANY_CHAINS,
    patientsDunning: pathname === ROUTE_PATIENTS_DUNNING,
    settings: pathname === ROUTE_SETTINGS,
    documentation: pathname === ROUTE_DOCUMENTATION,
    creditApplications: pathname === ROUTE_CREDIT_APPLICATIONS,
  });

  let hiddenMenuItem = true;

  //get the ws messages for documentation updates
  const wsDocumentationMessages = useSelector(
    (state) => state.websocketMessages?.documentation
  );
  useEffect(() => {
    // Calculate initial button visibility based on screen size and user role
    const calculateVisibility = () => {
      const windowSize = window.innerWidth;
      if (windowSize <= 991) {
        setSmallScreenSize(true);
        if (isManager(user)) {
          return companyChainStatus === 'ACTIVE';
        } else if (isDentist(user)) {
          return user?.activeCompanyStatus === 'ACTIVE';
        }
        return true;
      }
      setSmallScreenSize(false);
      // Button is not relevant for larger screens, so hide it
      return false;
    };

    // Directly update the state with the calculated value
    setShouldShowAddPatientButton(calculateVisibility());

    // Event listener for resize events
    const handleResize = () => {
      setShouldShowAddPatientButton(calculateVisibility());
    };

    // Add and remove the resize event listener
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [user, companyChainStatus]);

  const toggle = () => {
    const openSections = {
      leads: pathname === ROUTE_LEADS,
      companies: pathname === ROUTE_COMPANIES,
      companyChains: pathname === ROUTE_COMPANY_CHAINS,
      patientsDunning: pathname === ROUTE_PATIENTS_DUNNING,
      settings: pathname === ROUTE_SETTINGS,
      documentation: pathname === ROUTE_DOCUMENTATION,
      creditApplications: pathname === ROUTE_CREDIT_APPLICATIONS,
      sundhedCredit: pathname === ROUTE_MIT_SUNDHEDPLUS,
      memberProfile: pathname === ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
      help: pathname === ROUTE_MIT_SUNDHEDPLUS_HELP,
    };
    setOpenSections(openSections);
  };

  useEffect(() => {
    toggle();
  }, [location.pathname]);

  // set the counter for pending documents from redux
  useEffect(() => {
    setPendingDocumentationCount(counterPendingDocuments);
  }, [counterPendingDocuments]);

  // set the counter for pending documents from ws messages
  useEffect(() => {
    // react to ws messages new entries
    if (wsDocumentationMessages?.length > 0) {
      wsDocumentationMessages.forEach((message) => {
        const {
          websocketMessageType,
          numberOfPendingDocumentationGroupByCreditApplicationIdAndType,
        } = message || {};
        // CHECK WS NOTIFICATION FOR ADMINS
        if (
          websocketMessageType === 'NEW_DOCUMENTATION_UPLOADED' ||
          websocketMessageType === 'DOCUMENTATION_UPDATED'
        ) {
          setPendingDocumentationCount(
            numberOfPendingDocumentationGroupByCreditApplicationIdAndType
          );
        }
        if (pathname !== ROUTE_DOCUMENTATION) {
          // clear the ws message
          dispatch(clearPendingDocumentationWebsocketMessage());
        }
      });
    }
  }, [wsDocumentationMessages]);

  // create a useEffect to check if the closeSidebar is true and the user click outisde the sidebar-wrapper class it will close the sidebar
  useEffect(() => {
    if (modalIsOpened) {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.sidebar-wrapper')) {
          toggleMenu();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [modalIsOpened]);

  useEffect(() => {
    // Function to set the vh unit exactly to the viewport height, accounting for the mobile browser bar
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set vh unit on load
    setVh();

    // Set vh unit on resize
    window.addEventListener('resize', setVh);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', setVh);
  }, []);

  // if the user gets logged out, redirect to login page
  if (isAuth === false && !isDashboard) {
    return <Redirect to={ROUTE_MAIN_LOGIN} />;
  }
  const logoutUserFromClientPortal = () => {
    if (isDashboard) {
      if (isLocalhost) {
        window.location.pathname = ROUTE_HOME;
        dispatch(clearMemberPortalData());
      } else {
        dispatch(clearMemberPortalData());
        setTimeout(() => {
          window.location.href = config.damAppMainPageUrl;
        }, 200);
      }
    }
  };

  return (
    <div className='sidebar-section'>
      {smallScreenSize && (
        <div className='header'>
          <NavLink to={ROUTE_HOME}>
            <img
              className='sundhedLogo'
              src={sundhedLogo}
              alt={`${brandConfig.logoAltText}`}
            />
          </NavLink>
        </div>
      )}
      {!isDashboard ? (
        <div className='content'>
          <div className='menu'>
            <div className='top-menu-item'>
              {shouldShowAddPatientButton && (
                <div className='menu-item'>
                  <a onClick={toggleModal}>
                    <AddPatient
                      className='lnil'
                      style={{
                        width: '50px',
                        height: '50px',
                        marginBottom: '10px',
                        marginRight: '10px',
                      }}
                    />

                    {strings.sidebarMenuAddPatient}
                  </a>
                </div>
              )}
              {smallScreenSize && <hr />}
            </div>
            <div className={`menu-item ${openSections.leads ? 'active' : ''}`}>
              <NavLink to={ROUTE_LEADS}>
                <i className='lnil lnil-users' />
                {strings.clients}
              </NavLink>
            </div>
            {user && isAdmin(user) && (
              <div
                className={`menu-item ${
                  openSections.companies ? 'active' : ''
                }`}
              >
                <NavLink to={ROUTE_COMPANIES}>
                  <i className='lnil lnil-house-heart' />
                  {strings.companies}
                </NavLink>
              </div>
            )}
            {user && isAdmin(user) && (
              <div
                className={`menu-item ${
                  openSections.companyChains ? 'active' : ''
                }`}
              >
                <NavLink to={ROUTE_COMPANY_CHAINS}>
                  <i className='lnil lnil-apartment' />
                  {strings.companyChainsMenuTitle}
                </NavLink>
              </div>
            )}
            {user && isAdmin(user) && (
              <>
                <div
                  className={`menu-item dunning-link ${
                    openSections.patientsDunning ? 'active' : ''
                  }`}
                >
                  <NavLink to={ROUTE_PATIENTS_DUNNING}>
                    <i className='lnil lnil-achievement' />
                    {strings.loansInDunning}
                  </NavLink>
                </div>
              </>
            )}
            {user &&
              isAdmin(user) &&
              process.env.REACT_APP_DISABLE_DOCUMENTATION !== 'true' && (
                <div
                  className={`menu-item ${
                    openSections.creditApplications ? 'active' : ''
                  }`}
                >
                  <NavLink to={ROUTE_CREDIT_APPLICATIONS}>
                    <i className='lnil lnil-files' />
                    {strings.applicationsSideBarMenuItem}
                  </NavLink>
                </div>
              )}
            {user &&
              isAdmin(user) &&
              process.env.REACT_APP_STAGE === 'staging' &&
              process.env.REACT_APP_DISABLE_DOCUMENTATION !== 'true' && (
                <div
                  className={`menu-item ${
                    openSections.documentation ? 'active' : ''
                  }`}
                >
                  <NavLink to={ROUTE_DOCUMENTATION}>
                    <div className='icon-wrapper'>
                      <i className='lnil lnil-file-upload' />
                      {pendingDocumentationCount > 0 && (
                        <div className='notification-count'>
                          {pendingDocumentationCount}
                        </div>
                      )}
                    </div>

                    {strings.documentationSideBarMenuItem}
                  </NavLink>
                </div>
              )}
          </div>
          {smallScreenSize && (
            <div className='footer menu'>
              <hr />
              {user && (isManager(user) || isDentist(user)) && (
                <div
                  className={`menu-item ${
                    openSections.settings ? 'active' : ''
                  }`}
                >
                  <NavLink to={ROUTE_SETTINGS}>
                    <i className='lnil lnil-cog' />
                    {strings.settings}
                  </NavLink>
                </div>
              )}

              <div className='menu-item'>
                <button
                  type='button'
                  className='common-btn'
                  onClick={(e) => {
                    e.preventDefault();
                    sessionStorage.removeItem('lastVisitedPath');
                    dispatch(userLogout());
                  }}
                >
                  <i className='lnil lnil-exit' />
                  {strings.logout}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='content'>
          <div className='menu'>
            <div className='top-menu-item'>
              <div
                className={`menu-item ${
                  openSections.sundhedCredit ? 'active' : ''
                }`}
              >
                <NavLink to={ROUTE_MIT_SUNDHEDPLUS}>
                  <SavingPig
                    className='lnil'
                    style={{
                      width: '50px',
                      height: '50px',
                      marginBottom: '10px',
                      marginRight: '10px',
                    }}
                  />

                  {strings.mitSundhedMenuItemKredit}
                </NavLink>
              </div>
              <div
                className={`menu-item ${
                  openSections.memberProfile ? 'active' : ''
                }`}
              >
                <NavLink to={ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE}>
                  <UserIcon
                    className='lnil'
                    style={{
                      width: '50px',
                      height: '50px',
                      marginBottom: '10px',
                      marginRight: '10px',
                    }}
                  />

                  {strings.mitSundhedMenuItemProfile}
                </NavLink>
              </div>

              {!hiddenMenuItem && (
                <div
                  className={`menu-item ${openSections.help ? 'active' : ''}`}
                >
                  <NavLink to={ROUTE_MIT_SUNDHEDPLUS_HELP}>
                    <i className='di di-help' />
                    {strings.mitSundhedMenuItemHelp}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
          {smallScreenSize && (
            <div className='footer menu'>
              <hr />

              <div className='menu-item'>
                <button
                  type='button'
                  className='common-btn'
                  onClick={logoutUserFromClientPortal}
                >
                  <i className='lnil lnil-exit' />
                  {strings.logout}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Sidebar.propTypes = {
  selectedCompany: PropTypes.object,
};

export default Sidebar;
