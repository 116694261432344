import React from 'react'
import './style.scss';
import PropTypes from 'prop-types';

function StepBullets({ maxSteps, step }) {
  return (
    <div className='StepBullets_Component'>
      <div className='steps_step-bullets'>
        {[...Array(maxSteps)].map((StepComponent, i) => <span key={`stepBullet-${i}`} className={`step-bullet ${i <= step - 1 ? 'checked' : ''}`} />)}
      </div>
    </div>
  );
}

StepBullets.propTypes = {
  maxSteps: PropTypes.number,
  step: PropTypes.number,
}

StepBullets.defaultProps = {
}

export default StepBullets;
