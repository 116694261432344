import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import DentiModal from '../../../../common/DentiModal';
import ButtonLoader from '../../../../common/ButtonLoader/ButtonLoader';
import DropDown from '../../../../common/DropDown/DropDown';
import {
  ROLE_ADMIN,
  ROLE_DENTIST,
  ROLE_MANAGER,
} from '../../../../../constants';
import { strings } from '../../../../../constants/localization';
import './style.scss';
import {
  sendUserInvitation,
  updateUserInfo,
} from '../../../../../actions/userActions';
import { getCompanyChainLogs } from '../../../../../actions/logActions';
import { toggleServerModalError } from '../../../../../actions/uiElementsActions';
import { emailValidation } from '../../../../../constants/utils';

const AddUserModal = (props) => {
  const {
    companiList,
    closeModal,
    companyChainId,
    update,
    selectedUser,
    editMode,
  } = props;

  const userTypes = [
    {
      top: strings.chainManager,
      bottom: strings.chainFullAccess,
      value: ROLE_MANAGER,
    },
    {
      top: strings.chainEmployee,
      bottom: strings.chainAccessSettings,
      value: ROLE_DENTIST,
    },
    {
      top: strings.chainAdministrator,
      bottom: strings.chainAccessAdmin,
      value: ROLE_ADMIN,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [usertype, setUsertype] = useState({});
  const [company, setCompany] = useState({});
  const [mailerror, setmailerror] = useState('');
  const [userTypeError, setuserTypeError] = useState('');
  const [userCompanyError, setUserCompanyError] = useState('');
  const [validForm, setValidForm] = useState(false);

  const isEmptyObject = (obj) =>
    obj && Object.keys(obj).length === 0 && obj.constructor === Object;

  useEffect(() => {
    const comp = companiList
      .filter((item) => {
        //show in the list only active and passive companies
        return item?.status !== 'DEACTIVE';
      })
      .map((item) => ({
        front: item.referenceNumber,
        top: item.companyName,
        bottom: `${item.address}, ${item.zipCode} ${item.city}`,
        right: item.status,
        value: item.referenceNumber,
      }));

    //
    if (selectedUser.username) {
      setFirstName(selectedUser.firstname);
      setLastName(selectedUser.lastname);
      setEmail(selectedUser.username);
      const role =
        !isEmptyObject(selectedUser.user_role) &&
        Object.keys(selectedUser.user_role)[0]
          ? selectedUser.user_role[Object.keys(selectedUser.user_role)[0]][0]
          : '';
      userTypes.forEach((item) => {
        if (role && item.value === role) {
          setUsertype(item);
        }
      });
      const com =
        !isEmptyObject(selectedUser.user_role) &&
        Object.keys(selectedUser.user_role)[0]
          ? Object.keys(selectedUser.user_role)[0]
          : '';
      comp.forEach((item) => {
        if (com && item.value === com) {
          setCompany(item);
        }
      });
    }
    setCompanies(comp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companiList,
    selectedUser.email,
    selectedUser.lastname,
    selectedUser.firstname,
    selectedUser.username,
    selectedUser.user_role,
  ]);

  useEffect(() => {
    // if the role is dentist and no set location, then show an error message
    if (usertype.value === 'ROLE_DENTIST' && isEmptyObject(company)) {
      setUserCompanyError(strings.userRegistrationPleaseEnterUserCompany);
      setLoading(false);
    }

    // validate form
    if (mailerror === '' && userTypeError === '' && userCompanyError === '') {
      setValidForm(true);
    } else {
      setValidForm(false);
      setLoading(false);
    }
  }, [usertype, company, mailerror, userTypeError, userCompanyError, loading]);

  const updateField = (value, field) => {
    switch (field) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        setmailerror('');
        break;
      case 'usertype':
        setUsertype(value);
        setuserTypeError('');
        setUserCompanyError('');
        break;
      case 'company':
        setCompany(value);
        setUserCompanyError('');
        break;
      default:
    }
  };

  const save = () => {
    if (!editMode) {
      if (
        email &&
        !emailValidation(email) &&
        usertype.value &&
        (usertype.value === 'ROLE_MANAGER' ||
          usertype.value === 'ROLE_ADMIN' ||
          company.value)
      ) {
        setLoading(true);
        props
          .sendUserInvitation({
            email,
            userRole: usertype.value,
            companyChainId,
            companyReferenceNumber: company.value || companies[0].value,
            firstName: firstName || '',
            lastName: lastName || '',
          })
          .then((response) => {
            if (response?.payload.status === 200) {
              closeModal();
              update();
              setLoading(false);
            }
          })
          .catch(() => {
            props.toggleServerModalError(false);

            // User already exists
            setmailerror(strings.userRegistrationExistingUser);
            setLoading(false);
          });
      } else {
        if (!email || emailValidation(email)) {
          setmailerror(strings.userRegistrationPleaseEnterMail);
        }
        if (!usertype.value) {
          setuserTypeError(strings.userRegistrationPleaseEnterUserType);
        }
        if (!company.value) {
          setUserCompanyError(strings.userRegistrationPleaseEnterUserCompany);
        }
      }
    } else if (
      !isEmptyObject(usertype) &&
      usertype.value &&
      email &&
      !emailValidation(email)
    ) {
      let newUserRole = null;
      const companyOldVal =
        !isEmptyObject(selectedUser.user_role) &&
        Object.keys(selectedUser.user_role)[0]
          ? Object.keys(selectedUser.user_role)[0]
          : '';
      let userTypeOldVal = !isEmptyObject(selectedUser.user_role)
        ? selectedUser.user_role[Object.keys(selectedUser.user_role)[0]]
        : '';
      userTypeOldVal =
        userTypeOldVal && userTypeOldVal.length > 0 ? userTypeOldVal[0] : null;

      // if both dropdowns are not empty, then send the new values
      if (!isEmptyObject(usertype) && !isEmptyObject(company)) {
        newUserRole = {
          [company?.value || companyOldVal]: [
            usertype?.value || userTypeOldVal,
          ],
        };
      }
      // if the user selects manager, then update the role using the previous companyRefNumber or the first item from the dropdown with companies
      else if (!isEmptyObject(usertype) && isEmptyObject(company)) {
        newUserRole = {
          [companyOldVal || companies[0].value]: [
            usertype.value || userTypeOldVal,
          ],
        };
      }

      if (validForm) {
        setLoading(true);
        let payloadToUpdate = {
          firstName: firstName || '',
          id: selectedUser.id,
          jobtitle: usertype ? usertype.top : '',
          lastName: lastName || '',
          username: email,
        };

        // if there is no user role previously set and now is updated to manager
        if (isEmptyObject(selectedUser?.user_role) && isEmptyObject(company)) {
          payloadToUpdate.activeCompany = companies[0].value;
        }
        // if there is a user role previously set, then send the activeCompany only if the company has changed
        else if (!isEmptyObject(company) && company.value !== companyOldVal) {
          payloadToUpdate.activeCompany = company.value;
        }
        // otherwise send  null
        else {
          payloadToUpdate.activeCompany = null;
        }

        // add the companyRoles to the payload only if the user role has changed
        if (
          (!isEmptyObject(selectedUser?.user_role) &&
            company?.value !== companyOldVal) ||
          usertype?.value !== userTypeOldVal
        ) {
          payloadToUpdate.companyRoles = newUserRole;
        }

        props
          .updateUserInfo(payloadToUpdate)
          .then((response) => {
            if (response?.payload.status === 200) {
              update();
              closeModal();
              props.getCompanyChainLogs(companyChainId);
            }
          })
          .catch((e) => {
            setmailerror(e.response.data.cause);
            closeModal();
          });
      }
    } else {
      if (!email || emailValidation(email)) {
        setmailerror(strings.userRegistrationPleaseEnterMail);
      }
      if (!usertype.value) {
        setuserTypeError(strings.userRegistrationPleaseEnterUserType);
      }
      if (!company.value) {
        setUserCompanyError(strings.userRegistrationPleaseEnterUserCompany);
      }
    }
  };

  return (
    <DentiModal
      key='export-modal'
      close={closeModal}
      modalClass='grant-access-Modal'
      modalClassName='center_modal'
      closeBtn={true}
    >
      <div className='add-user-modal-component'>
        <div className='header'>
          <i className='lnir  lnir-user popupiconspacing' />
          <div className='title'>
            {editMode ? strings.chainEditUser : strings.chainCreateUser}
          </div>
        </div>
        <div className='content'>
          <div className='form'>
            <Row>
              <Col xs={6}>
                <div className='label'>{strings.firstName}</div>
                <input
                  type='text'
                  value={firstName || ''}
                  onChange={(e) => {
                    updateField(e.target.value, 'firstName');
                  }}
                />
              </Col>
              <Col xs={6}>
                <div className='label'>{strings.lastName}</div>
                <input
                  type='text'
                  value={lastName || ''}
                  onChange={(e) => {
                    updateField(e.target.value, 'lastName');
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='label'>
                  {strings.emailLabel}
                  {mailerror && <div className='ErrorZone'>{mailerror}</div>}
                </div>
                <input
                  type='email'
                  value={email || ''}
                  onChange={(e) => {
                    updateField(e.target.value, 'email');
                  }}
                  className={`${mailerror ? 'inputError' : ''}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='label'>
                  {strings.chainUserType}
                  {userTypeError && (
                    <div className='ErrorZone'>{userTypeError}</div>
                  )}
                </div>
                <DropDown
                  options={userTypes}
                  currentValue={usertype}
                  placeholder={strings.selectUserTypePlaceholder}
                  setValue={(value) => {
                    updateField(value, 'usertype');
                  }}
                  hasError={!!userTypeError}
                />
              </Col>
            </Row>
            {usertype.value === ROLE_DENTIST && (
              <Row>
                <Col xs={12}>
                  <div className='label'>
                    {strings.chainAssociatedLocation}
                    {userCompanyError && (
                      <div className='ErrorZone'>{userCompanyError}</div>
                    )}
                  </div>
                  <DropDown
                    options={companies}
                    currentValue={company}
                    placeholder={strings.selectUserLocationPlaceholder}
                    setValue={(value) => {
                      updateField(value, 'company');
                    }}
                    showStatus={true}
                    hasError={!!userCompanyError}
                  />
                </Col>
              </Row>
            )}
            {!editMode && (
              <Row>
                <div className='informationZone'>
                  <div className='content'>{strings.chainSendInformation}</div>
                </div>
              </Row>
            )}
            <Row>
              <div className='button_area'>
                {!editMode && (
                  <Button color='blue' onClick={save}>
                    {!loading && (
                      <>
                        <i className='lnir lnir-circle-plus' />
                        {strings.create}
                      </>
                    )}
                    {loading && <ButtonLoader />}
                  </Button>
                )}
                {editMode && (
                  <>
                    <Button
                      color='blue'
                      onClick={save}
                      className='Accept_button'
                    >
                      {!loading && (
                        <>
                          <i className='di di-accept' />
                          {strings.userSaveBtn}
                        </>
                      )}
                      {loading && <ButtonLoader />}
                    </Button>
                  </>
                )}
              </div>
            </Row>
          </div>
        </div>
      </div>
    </DentiModal>
  );
};

AddUserModal.propTypes = {
  closeModal: PropTypes.func,
  companyList: PropTypes.array,
  companyChainId: PropTypes.number,
  update: PropTypes.func,
  selectedUser: PropTypes.object,
  editMode: PropTypes.bool,
};

export default connect(null, {
  toggleServerModalError,
  sendUserInvitation,
  updateUserInfo,
  getCompanyChainLogs,
})(AddUserModal);
