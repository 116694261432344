import React from 'react';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setCurrentDocumentationItem } from '../../../actions/onboardingDocumentationActions';
import { documentationTypeMap } from '../../../constants/mappings';
import { currencyFormatDA } from '../../../constants/utils';
import { strings } from '../../../constants/localization';

const DocumentationListItem = (props) => {
  const { document, active } = props;
  const dispatch = useDispatch();
  let logoStatus = 'yellow';
  if (document.status === 'PENDING') {
    logoStatus = 'yellow';
  } else if (document.status === 'APPROVED') {
    logoStatus = 'green';
  } else if (document.status === 'REJECTED') {
    logoStatus = 'blue';
  } else if (document.status === 'REQUIRED') {
    logoStatus = 'grey';
  } else if (document.status === 'CANCELLED') {
    logoStatus = 'red';
  }
  return (
    <ListGroupItem
      active={active}
      onClick={() => dispatch(setCurrentDocumentationItem(document))}
    >
      <div className={`${active ? 'active' : ''} single-list-item`}>
        <div className='logo '>
          <div className={`d-logo logo-${logoStatus}`}>
            {React.isValidElement(
              documentationTypeMap[document.type]?.typeIcon
            ) ? (
              documentationTypeMap[document.type]?.typeIcon
            ) : (
              <i className={documentationTypeMap[document.type]?.typeIcon} />
            )}
          </div>
        </div>
        <div>
          <ListGroupItemHeading>{strings[document.type]}</ListGroupItemHeading>
          <ListGroupItemText tag='div'>{`${document.firstName} ${document.lastName} `}</ListGroupItemText>
        </div>
        <Badge>
          <i className='di di-loan-count' />
          <span className='ml-2'>
            {currencyFormatDA(document.budgetConclusionDTO.final, true, true)}
          </span>
        </Badge>
      </div>
    </ListGroupItem>
  );
};

export default DocumentationListItem;
