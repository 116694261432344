import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { ROUTE_LEADS } from '../../../constants/routes';
import { cprNoFormat, phoneNoFormat } from '../../../constants/utils';

const ApplicantDetails = () => {
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const { cpr, firstName, lastName, phone, email, address, zipCode, city } =
    activeApplicationListItem?.clientData || {};

  return (
    <section className='entity-information-card custom-card card'>
      <div className='card-body' id='client-details-section'>
        <div className='headline-section'>
          {strings.applicationClientDetails}
        </div>
        <Row className='lead-personal-data'>
          <Col className='col-12 col-md-4'>
            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-house'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.nameAndPhone}</span>
                </div>
                <div className='media-body-content'>
                  {firstName || ''} {lastName || ''}
                </div>
                <div className='media-body-content'>{address || ''}</div>
                <div className='media-body-content'>
                  {zipCode || ''} {city || ''}
                </div>
              </div>
            </div>
          </Col>

          <Col className='col-12 col-md-4'>
            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-mobile-alt-1'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.applicationClientPhone}</span>
                </div>
                <div className='media-body-content'>{phoneNoFormat(phone)}</div>
              </div>
            </div>

            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-envelope'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.emailLabel}</span>
                </div>
                <div className='media-body-content'>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </div>
            </div>
          </Col>

          <Col className='col-12 col-md-4'>
            <div className='media'>
              <div className='logo media-left'>
                <div className='d-logo'>
                  <i className='lnir lnir-shield'></i>
                </div>
              </div>
              <div className='media-body'>
                <div className='media-body-title'>
                  <span>{strings.applicationClientCPR}</span>
                </div>
                {cpr && (
                  <div className='media-body-content'>
                    <Link
                      to={{
                        pathname: ROUTE_LEADS,
                        search: `?q=${cpr}`,
                      }}
                    >
                      {cprNoFormat(cpr) || ''}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ApplicantDetails;
