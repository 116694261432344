import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import { getCompaniesForCompanyChain } from '../../actions/companyActions';
import SearchClinic from '../../components/common/SearchClinic/SearchClinic';
import Checkbox from '../common/Checkbox/Checkbox';
import DropDown from '../common/DropDown/DropDown';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import {
  isAdmin,
  isManager,
  isDentist,
  validateEmail,
  normalizePhone,
  formatDecimalsSpace,
} from '../../constants/utils';

class StepSendAppLink extends Component {
  state = {
    clinic: {},
    clinicsListForCompanyChain: [],
    clinicReferenceNumber: isDentist(this.props.user)
      ? this.props.activeCompany
      : '',
    email: this.props.email || '',
    phone: this.props.phone || '',
    consent: false,
    submitDisabled: true,
    loading: false,
    isRegisterInvoiceFlow: this.props.isRegisterInvoiceFlow,
  };

  setEmail = (value, callback = undefined) => {
    this.setState({ email: value }, callback !== undefined ? callback : '');
  };
  setPhone = (value, callback = undefined) => {
    this.setState({ phone: value }, callback !== undefined ? callback : '');
  };

  selectClinic = (newClinic) => {
    this.setState({ clinic: newClinic });
    // set the clinic reference number based on selection (SerachClinic or Dropdown)
    if (newClinic) {
      if (isAdmin(this.props.user)) {
        this.setState(
          { clinicReferenceNumber: newClinic.referenceNumber },
          this.isSubmitDisabled
        );
      }
      if (isManager(this.props.user)) {
        if (this.state.clinicsListForCompanyChain.length > 1) {
          //get the value from dropdown with companies
          this.setState(
            { clinicReferenceNumber: newClinic.value },
            this.isSubmitDisabled
          );
        }
      }
    }
  };

  handleClick = () => {
    this.setState({ consent: !this.state.consent }, this.isSubmitDisabled);
  };

  componentDidMount() {
    if (isManager(this.props.user) && this.props.user.companyChainId) {
      this.props
        .getCompaniesForCompanyChain(this.props.user.companyChainId)
        .then((result) => {
          if (result.payload.status === 200) {
            if (result.payload.data.companyChains[0].companies) {
              const companies = result.payload.data.companyChains[0].companies
                .filter((company) => company.status !== 'DEACTIVE')
                .map((item) => ({
                  front: item.referenceNumber,
                  top: item.companyName,
                  bottom: `${item.address}, ${item.zipCode} ${item.city}`,
                  right: item.status,
                  value: item.referenceNumber,
                }));
              if (companies.length > 1) {
                this.setState({ clinicsListForCompanyChain: companies });
              } else if (companies.length === 1)
                this.setState(
                  { clinicReferenceNumber: companies[0].value },
                  this.isSubmitDisabled
                );
            }
          }
        });
    }
    this.isSubmitDisabled();
  }

  isValidEmail(value) {
    return validateEmail(value) ? true : false;
  }

  isDanishNumber(value) {
    return /^[0-9]{8}$/.test(normalizePhone(value)) ? true : false;
  }

  isSubmitDisabled() {
    const email = this.state.email;
    const phone = this.state.phone;

    // if both email and phone are empty disable button
    if (email === '' && phone === '') {
      this.setState({
        submitDisabled: true,
      });
    }
    // if email or phone is entered, check that they are valid
    if (email !== '' || phone !== '') {
      this.setState({
        submitDisabled: !(
          this.state.consent &&
          this.state.clinicReferenceNumber !== '' &&
          (this.isValidEmail(email) || this.isDanishNumber(phone))
        ),
      });
    }
    // if both email and phone are entered, check that they both are valid
    if (email !== '' && phone !== '') {
      this.setState({
        submitDisabled: !(
          this.state.consent &&
          this.state.clinicReferenceNumber !== '' &&
          this.isValidEmail(email) &&
          this.isDanishNumber(phone)
        ),
      });
    }
  }

  render() {
    const { user, nextStep } = this.props;

    return (
      <div className='step-content step-sendapp-link'>
        <div className='step-header'>
          <i className='di di-add-user' />
          <div className='title-text'>{strings.apSendLinkTitle}</div>
        </div>
        <div className='step-body'>
          <div className='text-information'>
            <p>{strings.apSendLinkText1}</p>
            <p>{strings.apSendLinkText2}</p>
          </div>
          <div className='input-send-container'>
            <div className='form-group'>
              {isAdmin(user) && (
                <div>
                  <SearchClinic
                    classAtribute
                    selectClinic={this.selectClinic}
                    selected={this.state.clinic}
                    fieldClass={
                      !this.state.clinic.referenceNumber ? 'input_danger' : ''
                    }
                    format
                    showAdress
                  />
                </div>
              )}

              {isManager(this.props.user) &&
                this.state.clinicsListForCompanyChain.length > 1 && (
                  <div className='form-group'>
                    <label>{strings.clinic}</label>
                    <DropDown
                      options={this.state.clinicsListForCompanyChain}
                      currentValue={this.state.clinic}
                      placeholder={strings.chainSelectLocation}
                      setValue={(value) => {
                        this.selectClinic(value);
                      }}
                    />
                  </div>
                )}
            </div>

            <div className='form-group'>
              <label>{strings.emailLabel}</label>
              <input
                type='email'
                placeholder={strings.apSendLinkEmail}
                className='form-control'
                value={this.state.email}
                onChange={(event) => {
                  this.setEmail(event.target.value, this.isSubmitDisabled);
                }}
              />
            </div>

            <div className='form-group'>
              <label>{strings.apPhoneLabel}</label>
              <input
                type='tel'
                placeholder={strings.apSendLinkNumber}
                className='form-control'
                maxLength='11'
                value={formatDecimalsSpace(this.state.phone)}
                onChange={(event) => {
                  this.setPhone(event.target.value, this.isSubmitDisabled);
                }}
              />
            </div>
          </div>

          <Checkbox
            content={strings.apConfirmAgreement}
            checked={this.state.consent}
            handleClick={this.handleClick}
          />

          <div className='button-actions'>
            <Button
              color='blue'
              onClick={() => {
                this.setState({ loading: true });
                if (this.state.isRegisterInvoiceFlow) {
                  this.props.setRegisterInvoiceEmail(this.state.email);
                  this.props.setRegisterInvoicePhone(
                    normalizePhone(this.state.phone)
                  );
                  this.props.setInvoiceClinic(this.state.clinicReferenceNumber);
                  this.props.setCurrentCase(5);
                } else {
                  nextStep({
                    email: this.state.email,
                    phone: normalizePhone(this.state.phone),
                    clinic: this.state.clinicReferenceNumber,
                  });
                }
              }}
              disabled={this.state.submitDisabled || this.state.loading}
            >
              {!this.state.loading && (
                <>
                  <i className='lnir lnir-telegram-original icon'></i>
                  <span> {strings.buttonTextSendLink}</span>
                </>
              )}
              {this.state.loading && <ButtonLoader />}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}
export default connect(mapStateToProps, { getCompaniesForCompanyChain })(
  StepSendAppLink
);
