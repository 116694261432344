import React from 'react';
import { Button } from 'reactstrap';
import { ROUTE_CLINIC_CLEAR_COOKIES } from '../constants/routes';
import { strings } from '../constants/localization';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary-wrapper'>
          <h1>{strings.errorBoundaryTitle}</h1>
          <Button tag='a' href={ROUTE_CLINIC_CLEAR_COOKIES}>
            {strings.errorBoundaryText}
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
