import React, { useEffect, useState } from 'react';
import { Button, CardBody } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { brandConfig } from '../../../config';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import { sendManualInterventionEmail } from '../../../actions/onboardingActions';

const ManualIntervention = ({ step }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const manualInterventionSentEmail = useSelector(
    (state) => state.onboarding?.manualInterventionSentEmail
  );
  const { creditApplicationUuid } = data || {};

  const phoneNumber =
  strings.getLanguage() === 'en'
    ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
    : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');


  const handleSubmit = () => {
    setButtonLoading(true);

    window.location.href = 'https://www.sundhedplus.dk/';
  };

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.manualInterventionTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/kreditvurdering-godkendt',
        stepTitle: 'kreditvurdering - godkendt',
        event: 'onboarding',
      });
    }
    // when we first load the we want to dispatch the action to sendManualInterventionEmail
    // if the manualInterventionSentEmail is false and we have a creditApplicationUuid
    if (!manualInterventionSentEmail && creditApplicationUuid) {
      setButtonLoading(true);
      dispatch(sendManualInterventionEmail(creditApplicationUuid)).finally(
        () => {
          setButtonLoading(false);
        }
      );
    }
  }, []);

  return (
    <CardBody className='step-content'>
      <div className='step-title'>{strings.manualInterventionTitle}</div>
      <div className='step-subtitle'>
        <h2 className='step-subtitle center bold mb-4 p-4 pt-0 '>
          {strings.manualInterventionSubheadline}
        </h2>
      </div>
      <div className='step-subtitle '>
        <p className='contactForQuestions'>{strings.manualInterventionP1}</p>
      </div>
      <div className='step-subtitle'>
        <p className='contactForQuestions '>
          {strings.formatString(
            strings.manualInterventionP2,
            <a className='color' href={`tel:${phoneNumber.replace(/\s/g, '')}`}>
              {phoneNumber}
            </a>,
            <a className='color' href={`mailto:${brandConfig.contactEmail}`}>
              {brandConfig.contactEmail}
            </a>
          )}
        </p>
      </div>

      <div className='actions-buttons mt-auto mb-3'>
        <Button
          className='btn btn-blue btn-success full-width'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? <ButtonLoader /> : strings.manualInterventionButton}
        </Button>
      </div>
    </CardBody>
  );
};

export default ManualIntervention;
