import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, CardBody } from 'reactstrap';
import { strings } from '../../../constants/localization';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { config } from '../../../config';

const RedirectToMonthio = ({ setNextStep, step }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const monthioApplicationUuid = savedData?.monthioApplicationUuid;

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingRedirectToMonthioTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/viderestilling-til-monthio',
        stepTitle: 'onboarding - viderestilling til monthio',
        event: 'onboarding',
      });
    }
  }, []);

  const handleSubmit = () => {
    setButtonLoading(true);
    if (monthioApplicationUuid) {
      const monthioRedirectUrl = config.monthioUrl.replace(
        '$0',
        monthioApplicationUuid
      );
      window.location.href = monthioRedirectUrl;
    } else {
      setButtonLoading(false);
      setNextStep();
    }
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {strings.onboardingRedirectToMonthioTitle}
      </div>
      <div className='mb-4'>
        <p>{strings.onboardingRedirectToMonthioP1}</p>
        <p>{strings.onboardingRedirectToMonthioP2}</p>
        <p>{strings.onboardingRedirectToMonthioP3}</p>
        {/* <p>{strings.onboardingRedirectToMonthioP4}</p> */}
      </div>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-success full-width'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <ButtonLoader />
          ) : (
            strings.onboardingRedirectToMonthioButton
          )}
        </Button>
      </div>
    </CardBody>
  );
};
export default RedirectToMonthio;
