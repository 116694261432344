import React from 'react';

import { strings } from '../../../constants/localization';
import addPatientError from '../../../resources/images/add-patient_error.png';
import './InvoiceError.scss';
import { brandConfig } from '../../../config';

export const InvoiceError = () => (
  <div className='invoice_Error_Component'>
    <img src={addPatientError} alt='add patient error' className='error_icon' />

    <h2 className='invoice_title'>
      {strings.newInvoiceErrorTitle}
      ...
    </h2>
    <div>{strings.invoiceNotRegistered}</div>
    <div>{strings.formatString(strings.newInvoiceErrorContactDetails, <a className='contact_email' href={`mailto:${brandConfig.contactEmail}`}>{brandConfig.contactEmail}</a>)}</div>
  </div>
)
