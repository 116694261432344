import React from 'react';
import './style.scss';
import { strings } from '../../../constants/localization';
import {brandConfig} from '../../../config';

function Step5() {
  return (
    <div className='Step5 section'>
      <div className='title'>{strings.userRegistrationAborted}</div>
      <div className='content'>
        <p>{strings.userRegistrationTermsNedsAccepted}</p>
        <p>
          {strings.userRegistrationForMoreQuestions}
          <a href={brandConfig.contactEmail}>{brandConfig.contactEmail}</a>
        </p>
      </div>
    </div>
  );
}

export default Step5;
