import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { strings } from '../../constants/localization';
import * as animationData from '../../resources/animations/add-patient_accept_lottie.json';
import LottieControl from './LottieControl';
import { ROUTE_LEADS } from '../../constants/routes';

const StepSuccess = (props) => {
  const history = useHistory();
  const ref = useRef(null);

  const { closeModal, patientCpr } = props;
  const [searchParams, setSearchParams] = useState(null);

  //when clicking outside the modal, send true to closeModal parent callback in order to update the clients list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal && closeModal(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [closeModal]);

  //triggr re-render of the new client added
  useEffect(() => {
    setSearchParams(patientCpr);
  }, [patientCpr]);

  return (
    <div ref={ref} className='step-content step-final'>
      <div className='step-header'>
        <LottieControl animationData={animationData} height={312} width={340} />
        <div className='title-text'>{strings.apPatientAddedTitle}</div>
      </div>
      <div className='step-body'>
        <div className='text-information'>
          <p>
            {strings.formatString(
              strings.apPatientAddedText1,
              `${props.stateInfo.firstName} ${props.stateInfo.lastName}`
            )}
          </p>
          <p>
            {strings.formatString(
              strings.apPatientAddedText2,
              props.stateInfo.firstName,
              props.user.companyName
            )}
          </p>
        </div>
        <div className='button-actions'>
          <Button
            color='blue'
            onClick={() => {
              history.push({
                pathname: ROUTE_LEADS,
                search:
                  '?' + new URLSearchParams({ q: searchParams }).toString(),
              });

              props.closeModal(true);
            }}
          >
            {strings.buttonTextShowClient}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(StepSuccess);
