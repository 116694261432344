import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { success, error as notifError } from 'react-notification-system-redux';
import { config } from '../../../../config';
import KebabMenu from '../../../common/KebabMenu/KebabMenu';
import DentiModal from '../../../common/DentiModal';
import ConfirmationModal from '../../../common/ConfirmationModal';
import DeleteLeadFail from './DeleteLeadFail/DeleteLeadFail';
import { strings } from '../../../../constants/localization';
import {
  isAdmin,
  cprNoFormat,
  phoneNoFormat,
  normalizeCpr,
  normalizePhone,
  addNotification,
  required,
  letters,
  digits,
  emailValidation,
  fixedLength4,
  getCustomerIOLink,
  decomposeFullAddress,
} from '../../../../constants/utils';
import { notificationDismissDuration, moment } from '../../../../constants';
// import Time from '../../../common/Time';
import {
  suspendLeadCreditFacility,
  getLeads,
  getUpdatedPatientCreditFacility,
  leadUpdateInformation,
  setCurrentLead,
  updateMembershipsStatus,
  getLeadClinics,
  deleteLead,
  deleteOnboardingMember,
  updateActivePatientInfo,
  setClientLoading,
} from '../../../../actions/leadActions';
import { getLogsCreditFacility } from '../../../../actions/logActions';
import { store } from '../../../../store';
import { ROUTE_CREDIT_APPLICATIONS } from '../../../../constants/routes';

class LeadInformation extends Component {
  constructor(props) {
    super(props);
    const leadDetails = props.activeLead.leadClientInformation;
    const { note } = props.activeLead;
    this.state = {
      addCompany: false,
      editMode: false,
      deleteConfirmation: false,
      firstName: leadDetails.firstName,
      lastName: leadDetails.lastName,
      cpr: cprNoFormat(leadDetails.cpr),
      address: leadDetails.address,
      email: leadDetails.email,
      phone: phoneNoFormat(leadDetails.phone),
      zip: leadDetails.zipCode,
      district: leadDetails.district,
      city: leadDetails.city,
      note: note || '',
      isAdmin: isAdmin(this.props.user),
      nameAndAddressError: undefined,
      cprError: undefined,
      phoneError: undefined,
      emailError: undefined,
      noteError: undefined,
      deleteError: '',
      deleteLoading: false,
    };
    this.onDelete = this.onDelete.bind(this);
    this.getCell = this.getCell.bind(this);
    this.save = this.save.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.fieldHasError = this.fieldHasError.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.cancel = this.cancel.bind(this);
    this.toggleCreditFacilityStatus =
      this.toggleCreditFacilityStatus.bind(this);
    this.updateLeadMembershipStatus =
      this.updateLeadMembershipStatus.bind(this);
    this.refreshLead = this.refreshLead.bind(this);
    this.isValueValid = this.isValueValid.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (
      JSON.stringify(oldProps.activeLead) !==
      JSON.stringify(this.props.activeLead)
    ) {
      const leadDetails = this.props.activeLead.leadClientInformation;
      const { note } = this.props.activeLead;

      // eslint-disable-next-line
      this.setState({
        editMode: false,
        firstName: leadDetails.firstName,
        lastName: leadDetails.lastName,
        cpr: cprNoFormat(leadDetails.cpr),
        address: leadDetails.address,
        email: leadDetails.email,
        phone: phoneNoFormat(leadDetails.phone),
        zip: leadDetails.zipCode,
        district: leadDetails.district,
        city: leadDetails.city,
        note: note || null,
        nameAndAddressError: undefined,
        cprError: undefined,
        phoneError: undefined,
        emailError: undefined,
        noteError: undefined,
        deleteError: '',
      });
    }
  }

  clickDelete() {
    this.setState({
      deleteConfirmation: true,
    });
  }

  onDelete() {
    const { activeLead } = this.props;

    this.setState({ deleteLoading: true }, () => {
      let deleteActionPromise;
      if (activeLead.leadStatus === 'MEMBER') {
        deleteActionPromise = this.props.deleteOnboardingMember(
          activeLead?.leadUuid
        );
      } else {
        deleteActionPromise = this.props.deleteLead(
          activeLead.leadClientInformation.cpr
        );
      }

      deleteActionPromise
        .then((response) => {
          if (response.payload.status === 200) {
            this.setState(
              {
                deleteConfirmation: false,
                deleteLoading: false,
              },
              () => {
                this.props.setCurrentLead();
                this.props.getLeads(this.props.currentPage);
              }
            );
          }
        })
        .catch((e) => {
          this.setState({
            deleteError: e.response.payload.data.cause,
            deleteLoading: false,
          });
        });
    });
  }

  validateForm() {
    let nameAndAddressError = '';
    let cprError = '';
    let phoneError = '';
    let emailError = '';
    const noteError = '';

    nameAndAddressError =
      required(this.state.firstName) ||
      required(this.state.lastName) ||
      required(this.state.address) ||
      required(this.state.zip) ||
      required(this.state.city);
    cprError = required(this.state.cpr);
    emailError =
      required(this.state.email) || emailValidation(this.state.email);
    phoneError = required(this.state.phone) || digits(this.state.phone);

    if (this.state.phone) {
      const normalizedPhone = normalizePhone(this.state.phone);

      // eslint-disable-next-line
      if (isNaN(Number(normalizedPhone))) {
        phoneError = strings.onlyDigits;
      } else if (!/^[1-9]\d{7}$/.test(normalizedPhone)) {
        phoneError = strings.danishNumber;
      }
    }

    if (this.state.cpr && this.state.isAdmin && !cprError) {
      const normalizedCpr = normalizeCpr(this.state.cpr);
      // eslint-disable-next-line
      if (isNaN(Number(normalizedCpr))) {
        cprError = strings.onlyDigits;
      } else if (normalizedCpr.length < 10) {
        cprError = strings.formatString(strings.numberOfCharacters, 10);
      }
    }
    this.setState(
      {
        nameAndAddressError,
        cprError,
        phoneError,
        emailError,
        noteError,
      },
      this.saveForm
    );
  }

  /* eslint-disable class-methods-use-this */
  isValueValid(value, fieldName, edit) {
    if (fieldName === 'firstName') {
      if (letters(value)) return false;
    }

    if (fieldName === 'lastName') {
      if (letters(value)) return false;
    }

    if (fieldName === 'zip') {
      if (
        (!edit && (digits(value) || fixedLength4(value.zip))) ||
        (edit && (value.length === 5 || digits(value)))
      ) {
        return false;
      }
    }

    if (fieldName === 'city') {
      if (letters(value)) return false;
    }

    if (fieldName === 'phone') {
      if (normalizePhone(value).length > 8) return false;
    }

    if (fieldName === 'note') {
      if (value.length > 1000) return false;
    }

    if (fieldName === 'address') {
      if (value.length > 100) return false;
    }

    if (fieldName === 'cpr') {
      if (value && normalizeCpr(value).length > 10) return false;
    }

    return true;
  }
  /* eslint-enable class-methods-use-this */

  isFormValid() {
    return (
      !this.state.nameAndAddressError &&
      !this.state.cprError &&
      !this.state.phoneError &&
      !this.state.emailError &&
      !this.state.noteError
    );
  }

  fieldHasError(field) {
    return !!this.state[`${field}Error`];
  }

  // eslint-disable-next-line consistent-return
  saveForm() {
    const leadDetails = this.props.activeLead.leadClientInformation;
    const formData = {
      firstName: leadDetails.firstName,
      lastName: leadDetails.lastName,
      cpr: leadDetails.cpr,
      address: this.state.address,
      email: this.state.email,
      phone: normalizePhone(this.state.phone),
      zip: this.state.zip,
      city: this.state.city,
      note: this.state.note,
    };

    if (this.state.isAdmin) {
      formData.firstName = this.state.firstName;
      formData.lastName = this.state.lastName;
      formData.cpr = normalizeCpr(this.state.cpr);
    }

    if (this.isFormValid()) {
      this.props.setClientLoading(true);
      return this.props
        .leadUpdateInformation(this.props.activeLead.id, formData)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              editMode: false,
            });
            this.props.updateActivePatientInfo(formData);
            this.props.getLogsCreditFacility(
              this.props.activeLead.creditFacilityInformation.number
            );
            this.props.success(
              addNotification(
                `${strings.successPatientChange1} ${leadDetails.firstName} ${leadDetails.lastName} ${strings.successPatientChange2}`,
                strings.patientSuccessTitle,
                notificationDismissDuration
              )
            );
            this.props.setClientLoading(false);
          }
        })
        .catch(() => {
          store.dispatch(
            notifError(
              addNotification(
                strings.errorPatientChange,
                strings.patientErrorTitle,
                notificationDismissDuration
              )
            )
          );
          this.props.setClientLoading(false);
        });
    }
  }

  save(ev) {
    ev.preventDefault();
    this.validateForm();
  }

  cancel(ev) {
    ev.preventDefault();
    const leadDetails = this.props.activeLead.leadClientInformation;
    const { note } = this.props.activeLead;

    this.setState({
      editMode: false,
      firstName: leadDetails.firstName,
      lastName: leadDetails.lastName,
      cpr: cprNoFormat(leadDetails.cpr),
      address: leadDetails.address,
      email: leadDetails.email,
      phone: phoneNoFormat(leadDetails.phone),
      zip: leadDetails.zipCode,
      district: leadDetails.district,
      city: leadDetails.city,
      note: note || null,
      cprError: '',
      companyChainNameError: '',
      kotnirError: '',
      phoneError: '',
      nameAndAddressError: '',
      emailError: '',
    });
  }

  changeValue(value, fieldName) {
    if (this.isValueValid(value, fieldName, true)) {
      if (fieldName === 'address') {
        const result = decomposeFullAddress(value);
        if (result) {
          const { address, zipCode, city } = result;
          this.setState({ address: address, zip: zipCode, city: city });
        } else {
          this.setState({ address: value });
        }
      } else if (fieldName === 'phone') {
        this.setState({ [fieldName]: phoneNoFormat(value) });
      } else if (fieldName === 'cpr') {
        this.setState({ [fieldName]: cprNoFormat(value) });
      } else {
        this.setState({ [fieldName]: value });
      }
    }
  }

  getCell(logoClassName, title, value, fieldName, type) {
    const inputElement = (
      <input
        className='form-control custom-form-control'
        type='text'
        value={this.state[fieldName] || ''}
        onChange={(e) => this.changeValue(e.target.value, fieldName)}
      />
    );

    const inputNote = type === 'note' && (
      <textarea
        className='form-control custom-form-control'
        value={this.state[fieldName] || ''}
        onChange={(e) => this.changeValue(e.target.value, fieldName)}
      />
    );

    const inputnameAndAddress = type === 'nameAndAddress' && (
      <>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name1'
            type='text'
            value={this.state.firstName || ''}
            onChange={(e) => this.changeValue(e.target.value, 'firstName')}
          />
          <input
            className='form-control custom-form-control name2'
            type='text'
            value={this.state.lastName || ''}
            onChange={(e) => this.changeValue(e.target.value, 'lastName')}
          />
        </div>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name3'
            type='text'
            placeholder={strings.placeholderAddress}
            value={this.state.address || ''}
            onChange={(e) => this.changeValue(e.target.value, 'address')}
          />
        </div>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name5'
            type='text'
            placeholder={strings.placeholderZipCode}
            value={this.state.zip || ''}
            onChange={(e) => this.changeValue(e.target.value, 'zip')}
          />
          <input
            className='form-control custom-form-control name6'
            type='text'
            placeholder={strings.placeholderCity}
            value={this.state.city || ''}
            onChange={(e) => this.changeValue(e.target.value, 'city')}
          />
        </div>
      </>
    );

    return (
      <div className='col-12'>
        <div className='media'>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className={`lnir ${logoClassName}`} />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{title}</div>

              {this.fieldHasError(type || fieldName) && (
                <div className='error'>
                  {this.state[`${type || fieldName}Error`]}
                </div>
              )}
            </div>
            <div
              className={`media-body-content ${
                type === 'nameAndAddress' ? 'nameAndAddress' : ''
              }`}
            >
              {!type && (
                <>{this.state.editMode ? inputElement : value || '-'}</>
              )}
              {type === 'nameAndAddress' && (
                <>
                  {this.state.editMode ? (
                    inputnameAndAddress
                  ) : (
                    <>
                      <div>{`${this.state.firstName} ${this.state.lastName}`}</div>
                      <div>
                        {`${this.state.address || '-'} ${
                          this.state.district || ''
                        }`}
                      </div>
                      <div>{`${this.state.zip || '-'} ${
                        this.state.city || '-'
                      }`}</div>
                    </>
                  )}
                </>
              )}

              {type === 'note' && (
                <>{this.state.editMode ? inputNote : value}</>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggleCreditFacilityStatus() {
    const lead = this.props.activeLead;
    const creditFacilitySuspended =
      lead.creditFacilityInformation.status === 'SUSPENDED';
    let status = 'SUSPENDED';
    if (creditFacilitySuspended) {
      status = 'ACTIVE';
      const hasLatePayment =
        lead.creditFacilityInformation.installmentLoanInformations.some(
          (invoice) => invoice.status === 'LATE_PAYMENT'
        );
      if (hasLatePayment) {
        status = 'LATE_PAYMENT';
      }
    }
    this.props
      .suspendLeadCreditFacility(lead.creditFacilityInformation.number, status)
      .then((result) => {
        if (result.payload.status === 200) {
          this.props.getUpdatedPatientCreditFacility(
            lead.creditFacilityInformation.id
          );
        }
      })
      .catch((error) => new Error(error));
  }

  updateLeadMembershipStatus() {
    // event.preventDefault();
    let value = 'MEMBER';
    if (
      this.props.activeLead.leadClientInformation.clientStatus !== 'CANCELLED'
    ) {
      value = 'CANCELLED';
    }
    this.props
      .updateMembershipsStatus(
        value,
        this.props.activeLead.leadClientInformation.cpr
      )
      .then((response) => {
        if (response.payload.status === 200) {
          this.setState({ cancelConfirmation: false });
          this.props.updateActivePatientInfo({ clientStatus: value });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshLead() {
    const lead = this.props.activeLead;
    {
      const { user } = this.props;
      // eslint-disable-next-line no-unused-expressions
      isAdmin(user) &&
        this.setState(
          {
            addCompany: true,
          },
          () => this.props.getLeadClinics(lead.creditFacilityInformation.number)
        );
    }
    this.props.setClientLoading(true);

    this.props
      .getUpdatedPatientCreditFacility(lead.creditFacilityInformation.id)
      .then(() => {
        this.props.setClientLoading(false);
      });
  }

  render() {
    const { activeLead, user } = this.props;
    const { editMode, companyChainName, note, cpr, phone, email } = this.state;
    const leadEmail = activeLead?.leadClientInformation?.email;
    let brand, hasAcceptedLoans;
    if (activeLead.creditFacilityInformation) {
      brand = activeLead.creditFacilityInformation.brand;
    } else {
      brand = 'Sundhed+';
    }

    //Cancelling a membership results in the client being removed from Customer IO, ie we can't/won't contact them anymore. that's why we don't show the cancel button if they have accepted loans
    if (activeLead.creditFacilityInformation !== null) {
      if (
        activeLead.creditFacilityInformation?.installmentLoanInformations
          .length === 0
      ) {
        hasAcceptedLoans = false;
      } else {
        hasAcceptedLoans =
          activeLead.creditFacilityInformation?.installmentLoanInformations.some(
            (loan) => loan.status === 'ACCEPTED'
          );
      }
    }

    return (
      <div
        id='LeadInformationComponent'
        className={`entity-information-card custom-card card ${
          this.state.editMode ? 'edit-mode' : ''
        }`}
      >
        <div className='card-body'>
          <div className='header-information'>
            <div className='header-text leadHeader-text'>
              <div className='card-title'>{`${activeLead.leadClientInformation.firstName} ${activeLead.leadClientInformation.lastName}`}</div>
              {isAdmin(user) && (
                <div className='card-loked-data leadCardLokedData'>
                  <div className='iconzone'>
                    {brand === 'Denti' && <i className='di di-denti' />}
                    {brand === 'Sundhed+' && (
                      <i className='di di-sundhedplus' />
                    )}
                    {brand === 'Denti' &&
                      this.props.activeLead.leadClientInformation
                        .clientStatus !== 'ONBOARDING' && (
                        <>
                          {this.props.activeLead.leadClientInformation
                            .clientStatus === 'CANCELLED'
                            ? strings.membershipCanceled
                            : strings.createdOn}
                        </>
                      )}
                    {brand === 'Sundhed+' &&
                      this.props.activeLead.leadClientInformation
                        .clientStatus !== 'ONBOARDING' && (
                        <>
                          {this.props.activeLead.leadClientInformation
                            .clientStatus === 'CANCELLED'
                            ? strings.membershipCanceled
                            : strings.memberSince}
                        </>
                      )}
                    <span className='time'>
                      {this.props.activeLead.leadClientInformation
                        .clientStatus !== 'CANCELLED' &&
                        this.props.activeLead.leadClientInformation
                          .clientStatus !== 'ONBOARDING' &&
                        moment(activeLead.createdTime).format('DD-MM-yyyy')}
                      {this.props.activeLead.leadClientInformation
                        .clientStatus === 'ONBOARDING' && (
                        <>{strings.onboardingLidProp}</>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='header-actions leadHeader-actions'>
              {editMode ? (
                <div className='editFormButton'>
                  <Button
                    className='btn-light create-button'
                    onClick={this.cancel}
                  >
                    {strings.undoActionButton}
                  </Button>
                  <Button
                    className='btn-blue create-button'
                    onClick={(ev) => {
                      this.save(ev);
                    }}
                  >
                    <i className='lnil lnil-save icon' />
                    <span className='center_Text'>
                      {strings.saveActionButton}
                    </span>
                  </Button>
                </div>
              ) : (
                <>
                  {activeLead.leadStatus !== 'MEMBER' && (
                    <div className='refreshButton ' onClick={this.refreshLead}>
                      <i className='lnir lnir-reload icon' />
                    </div>
                  )}

                  {isAdmin(user) && (
                    <KebabMenu>
                      {activeLead.leadStatus !== 'MEMBER' && (
                        <div
                          className='KebabMenu-item'
                          onClick={() => {
                            this.setState({
                              editMode: true,
                              cprError: '',
                              companyChainNameError: '',
                              kotnirError: '',
                            });
                          }}
                        >
                          <i className='lnir lnir-pencil icon' />
                          <span>{strings.edit}</span>
                        </div>
                      )}

                      <div
                        className='KebabMenu-item'
                        onClick={() => {
                          const linkFilters = getCustomerIOLink(new Date());
                          const customerIoURL = config.customerIO.replace(
                            '$0',
                            this.props.activeLead.leadUuid
                          );
                          window.open(
                            `${customerIoURL}/${linkFilters}`,
                            '_blank'
                          );
                        }}
                      >
                        <i className='lnir lnir-reply icon' />
                        <span>{strings.seeCustomerIO}</span>
                      </div>

                      <div
                        className='KebabMenu-item'
                        onClick={() => {
                          const newUrl = `${ROUTE_CREDIT_APPLICATIONS}?q=${leadEmail}`;
                          window.location.href = newUrl;
                        }}
                      >
                        <i className='lnir lnir-empty-file icon' />
                        <span>{strings.navigateToApplications}</span>
                      </div>

                      {this.props.activeLead.leadClientInformation
                        .clientStatus === 'MEMBER' &&
                        hasAcceptedLoans === false && (
                          <div
                            className='KebabMenu-item'
                            onClick={() => {
                              this.setState({
                                cancelConfirmation: true,
                              });
                            }}
                          >
                            <i className='lnir lnir-cross-circle icon' />
                            <span>{strings.cancelMembership}</span>
                          </div>
                        )}
                      {this.props.activeLead.leadClientInformation
                        .clientStatus === 'CANCELLED' && (
                        <div
                          className='KebabMenu-item'
                          onClick={this.updateLeadMembershipStatus}
                        >
                          <i className='lnir lnir-checkmark-circle icon' />
                          <span>{strings.reactivate}</span>
                        </div>
                      )}
                      {/* if there's no credit or credit exists but has no invoices */}
                      {(!this.props.activeLead.creditFacilityInformation ||
                        (this.props.activeLead.creditFacilityInformation &&
                          this.props.activeLead.creditFacilityInformation
                            .installmentLoanInformations.length === 0)) && (
                        <div
                          className='KebabMenu-item'
                          onClick={this.clickDelete}
                        >
                          <i className='lnir lnir-trash-can icon' />
                          <span>{strings.delete}</span>
                        </div>
                      )}
                    </KebabMenu>
                  )}
                </>
              )}
            </div>
          </div>

          <div className='mt-4 lead-personal-data row'>
            <div className='col-12 col-md-5'>
              <div className='row'>
                {this.getCell(
                  'lnir lnir-house',
                  strings.nameAndPhone,
                  null,
                  'nameAndAddress',
                  'nameAndAddress'
                )}
                {this.getCell(
                  'lnir-bubble',
                  strings.clinicNote,
                  note || '-',
                  'note',
                  'note'
                )}
              </div>
            </div>
            <div className='col-12 col-md-7'>
              {this.getCell('lnir-shield', strings.cprLabel, cpr, 'cpr')}
              {this.getCell(
                'lnir-mobile-alt-1',
                strings.phoneLabel,
                phone,
                'phone'
              )}
              {this.getCell(
                'lnir-envelope',
                strings.emailLabelEdit,
                <a href={`mailto:${email}`}>{email}</a>,
                'email'
              )}
            </div>
          </div>
          {this.state.deleteConfirmation && this.state.deleteError === '' && (
            <DentiModal
              key={1}
              close={() => this.setState({ deleteConfirmation: false })}
              modalClass='confirm-modal'
              modalClassName='confirm-action'
            >
              <ConfirmationModal
                loading={this.state.deleteLoading}
                confirmAction={() => {
                  this.onDelete();
                }}
                cancelAction={() => {
                  this.setState({
                    deleteConfirmation: false,
                  });
                }}
              >
                <div>
                  <p className='title'>{strings.deleteLeadTitle}</p>
                  <p className='confirm-message'>
                    {strings.formatString(
                      strings.deleteLeadContent,
                      `${activeLead.leadClientInformation.firstName} ${activeLead.leadClientInformation.lastName}`
                    )}
                  </p>
                </div>
              </ConfirmationModal>
            </DentiModal>
          )}
          {this.state.cancelConfirmation && (
            <DentiModal
              key={1}
              close={() => this.setState({ deleteConfirmation: false })}
              modalClass='confirm-modal'
              modalClassName='confirm-action'
            >
              <ConfirmationModal
                confirmAction={() => {
                  this.updateLeadMembershipStatus();
                }}
                cancelAction={() => {
                  this.setState({
                    cancelConfirmation: false,
                  });
                }}
              >
                <div>
                  <p className='title'>{strings.cancelLeadConfirmTitle}</p>
                  <p className='confirm-message'>
                    {strings.formatString(
                      strings.canceldLeadConfirmContent,
                      `${activeLead.leadClientInformation.firstName} ${activeLead.leadClientInformation.lastName}`
                    )}
                  </p>
                </div>
              </ConfirmationModal>
            </DentiModal>
          )}
          {this.state.deleteError !== '' && (
            <DentiModal
              key={2}
              close={() => this.setState({ deleteError: '' })}
              modalClass='confirm-modal'
              modalClassName='confirm-action'
            >
              <DeleteLeadFail
                chainName={companyChainName}
                close={() => {
                  this.setState({
                    deleteError: '',
                    deleteConfirmation: false,
                  });
                }}
                error={this.state.deleteError}
              />
            </DentiModal>
          )}
        </div>
      </div>
    );
  }
}

LeadInformation.propTypes = {
  activeLead: PropTypes.object,
  user: PropTypes.object,
  leadUpdateInformation: PropTypes.func,
};
// { this.state.loading && <ButtonLoader />}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    getLeadClinics: state.lead.getLeadClinics,
    activePage: state.lead ? state.lead.activePage : null,
    searchActive: state.lead ? state.lead.searchActive : null,
    leadClinics: state.lead ? state.lead.leadClinics : null,
    currentPage:
      state.lead && state.lead.leadsList && state.lead.leadsList.pageable
        ? state.lead.leadsList.pageable.page
        : null,
  };
}

export default connect(mapStateToProps, {
  suspendLeadCreditFacility,
  getLeadClinics,
  getLeads,
  getUpdatedPatientCreditFacility,
  getLogsCreditFacility,
  leadUpdateInformation,
  success,
  notifError,
  setCurrentLead,
  updateMembershipsStatus,
  deleteLead,
  deleteOnboardingMember,
  updateActivePatientInfo,
  setClientLoading,
})(LeadInformation);
