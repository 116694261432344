import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { strings } from '../../constants/localization';

function Step4({ clientEmail, isCreditExpired }) {
  const clientDetails = useSelector(
    (state) => state.migrateCredit?.clientDetails
  );
  const { creditAmount, creditAmountLeft, creditBrand, latestLoanBrand } =
    clientDetails || {};
  const amountFormatted = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
  }).format(creditAmount);
  const amountLeftFormatted = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
  }).format(creditAmountLeft);

  // send page event to customer.io tracking system, after component is mounted
  useEffect(() => {
    const pageName =
      latestLoanBrand === 'Sundhed+' || latestLoanBrand === null
        ? strings.migrateCredit_text3_step4_headline
        : strings.migrateCredit_title_step4;
    _cio.page('migration - ' + pageName);
  }, []);

  return (
    <div className='Step4 section'>
      {/* latest loan brand is null */}
      {latestLoanBrand === null && creditBrand === 'Denti' && (
        <div className='lastest-loan-brand-null'>
          <div className='title'>{strings.migrateCredit_title_step4}</div>
          <h5>{strings.migrateCredit_subtitle_step4}</h5>
          <div className='content'>
            {isCreditExpired || creditAmount === 0 ? (
              <article>
                <p>
                  {strings.migrateCredit_first_paragraph_text_for_expired_step4}
                </p>
                <p>
                  {strings.formatString(
                    strings.migrateCredit_second_paragraph_text_for_expired_step4,
                    <br />
                  )}
                </p>
              </article>
            ) : (
              <article>
                <p>
                  {strings.formatString(
                    strings.migrateCredit_first_paragraph_text2_step4,
                    <br />,
                    amountFormatted
                  )}
                </p>
                <p>
                  {strings.formatString(
                    strings.migrateCredit_second_paragraph_step4,
                    <strong>{amountLeftFormatted}</strong>
                  )}
                </p>
              </article>
            )}

            <div className='subsection'>
              <div className='title'>
                {strings.migrateCredit_subsection_title_step4}
              </div>
              <p>
                {strings.formatString(
                  strings.migrateCredit_subsection_paragraph_step4,
                  <strong>{clientEmail}</strong>
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* latest loan brand is Denti */}
      {latestLoanBrand === 'Denti' && (
        <div className='lastest-loan-brand-denti'>
          <div className='title'>{strings.migrateCredit_title_step4}</div>
          <div className='content'>
            {isCreditExpired || creditAmount === 0 ? (
              <article>
                <p>
                  {strings.migrateCredit_first_paragraph_text_for_expired_step4}
                </p>
                <p>
                  {strings.formatString(
                    strings.migrateCredit_second_paragraph_text_for_expired_step4,
                    <br />
                  )}
                </p>
              </article>
            ) : (
              <article>
                <p>
                  {strings.formatString(
                    strings.migrateCredit_first_paragraph_text1_step4,
                    <br />,
                    amountFormatted
                  )}
                </p>
                <p>
                  {strings.formatString(
                    strings.migrateCredit_second_paragraph_step4,
                    <strong>{amountLeftFormatted}</strong>
                  )}
                </p>
              </article>
            )}

            <div className='subsection'>
              <div className='title'>
                {strings.migrateCredit_subsection_title_step4}
              </div>
              <p>
                {strings.formatString(
                  strings.migrateCredit_subsection_paragraph_step4,
                  <strong>{clientEmail}</strong>
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* latest loan brand is Sundhed+ */}
      {(latestLoanBrand === 'Sundhed+' || latestLoanBrand === null) &&
        creditBrand === 'Sundhed+' && (
          <div className='lastest-loan-brand-sundhed'>
            <div className='title'>
              {strings.migrateCredit_text3_step4_headline}
            </div>
            <div className='content'>
              {isCreditExpired || creditAmount === 0 ? (
                <article>
                  <p>{strings.migrateCredit_first_paragraph_text3_expired}</p>
                </article>
              ) : (
                <article>
                  <p>
                    {strings.formatString(
                      strings.migrateCredit_first_paragraph_text3_step4,
                      amountFormatted
                    )}
                  </p>
                  <p>
                    {strings.formatString(
                      strings.migrateCredit_second_paragraph_step4,
                      <strong>{amountLeftFormatted}</strong>
                    )}
                  </p>
                </article>
              )}
            </div>
          </div>
        )}
    </div>
  );
}

export default Step4;
