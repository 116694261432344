import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { validateEmail, normalizePhone } from "../../../constants/utils";
import { strings } from "../../../constants/localization";

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clean: this.props.cleanStep,
      cleanStep: this.props.cleanStep,
      valid:
        this.props.validation && this.props.value
          ? this.props.validation(this.props.value)
          : !this.props.validation,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (props.cleanStep !== state.cleanStep) {
      newState.cleanStep = props.cleanStep;
      newState.clean = props.cleanStep;
    }
    // if (props.value != state.value) {
    //   newState.value = props.value;
    // }
    if (props.valid !== undefined && props.valid !== state.valid) {
      newState.valid = props.valid;
    }
    return newState;
  }

  onChangeHandler = (event) => {
    this.setState({
      clean: false,
    });
    if (this.props.validation) {
      this.setState({ valid: this.props.validation(event.target.value) });
    }
    this.props.onChange(event);
  };

  isValid = () => {
    return this.state.clean || this.state.valid === true;
  };

  render() {
    return (
      <div className="inputComponent">
        <label htmlFor={this.props.name}>
          <span>{this.props.label}</span>
          {!this.props.stepIsValid && !this.isValid() && this.props.errorMsg && this.props.value !== "" && (
            <span className="inlineInputError">{this.props.errorMsg}</span>
          )}
        </label>

        <input
          id={this.props.name}
          name={this.props.name}
          type={this.props.type}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          onChange={this.onChangeHandler}
          onBlur={this.onChangeHandler}
          onKeyDown={this.props.onKeyDown}
          maxLength={this.props.maxLength}
          //if there's a format function, format the input, otherwise let it be
          value={
            this.props.format
              ? this.props.format(this.props.value, this.props.trimmedLength)
              : this.props.value
          }
          className={!this.props.stepIsValid && !this.isValid() ? "invalidInput" : ""}
        />
      </div>
    );
  }
}

Input.DefaultValidators = {
  isNotEmpty: (value) => {
    return value !== "";
  },
  isNumber: (value) => {
    let regex = /^[0-9]+$/;
    return value.match(regex);
  },
  isExactly4Digits: (value) => {
    return Input.DefaultValidators.isNumber &&
      value?.replaceAll(" ", "").length === 4
      ? true
      : false;
  },
  isExactly8Digits: (value) => {
    return Input.DefaultValidators.isNumber &&
    value?.replaceAll(" ", "").length === 8
    ? true
    : false;
  },
  isExactly10Digits: (value) => {
    return Input.DefaultValidators.isNumber &&
      value?.replaceAll(" ", "").length === 10
      ? true
      : false;
  },
  isValidEmail: (value) => {
    return validateEmail(value) ? true : false;
  },
  isOnlyDigits: (value) => {
    return isNaN(Number(normalizePhone(value)))
    ? true
    : false;
  },
  isDanishNumber: (value) => {
    return (!/^[1-9]\d{8}$/.test(normalizePhone(value)))
    ? true
    : false;
  },

  isErrorForPhone: (value) => {
    //let regex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    let phoneError = false;
    if (value) {
      // eslint-disable-next-line
      if (isNaN(Number(normalizePhone(value)))) {
        phoneError = strings.onlyDigits;
      } else if (!/^[1-9]\d{7}$/.test(normalizePhone(value))) {
        phoneError = strings.danishNumber;
      } else if (normalizePhone(value).length > 8) {
        phoneError = `${strings.mustBeSmaller} ${9}`;
      }
    }
    return phoneError;
  },
};

Input.defaultProps = {
  type: "text",
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "tel", "email"]),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default Input;
