import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { ReactComponent as InfoCircle } from '../../../resources/icons/info-circle.svg';
import DentiModal from '../../common/DentiModal';

const FileValidationError = ({ closeModal }) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-status-modal'
      modalClassName='upload-modal'
      close={() => closeModal()}
      closeBtn={false}
    >
      <div className='content-wrapper error d-flex align-items-center justify-content-center'>
        <h3 className='headline'>
          {strings.onboardingUploadValidationErrorHeadline}
        </h3>
        <span className='subheadline'>
          {strings.onboardingUploadValidationErrorP1}
        </span>
        <div className='status-icon'>
          <InfoCircle className='info-circle' />
        </div>
        <div className='error-text'>
          <p>{strings.onboardingUploadValidationErrorP2}</p>
        </div>

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => closeModal()}
          >
            {strings.onboardingUploadValidationErrorOk}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default FileValidationError;
