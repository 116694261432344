/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { strings } from '../../constants/localization';

class Step4 extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    if (this.props.currentStep !== 4) {
      return null;
    }

    return (
      <div className='step step4'>
        <div className='icon'>
          <i className='lnil lnil-checkmark-circle' />
        </div>
        <div className='title'>{strings.locationFlow_step4_title}</div>
        <div className='description'>
          {strings.formatString(
            strings.locationFlow_step4_description,
            this.props.location,
          )}
        </div>

        <div className='formWrapper'>
          <div className='locationData'>
            <div className='name'>{this.props.location}</div>

            <div className='line'>
              {/* Address */}
              <div className='column'>
                <div className='icon'>
                  <i className='lnil lnil-house-alt' />
                </div>
                <div className='input_details'>
                  <div className='label'>
                    {strings.locationFlow_step4_adress}
                  </div>
                  <div
                    className={`value ${this.props.address ? 'hasValue' : 'empty'}`}
                  >
                    {!this.props.address
                      ? strings.locationFlow_step4_notSet
                      : `${this.props.address}\n${
                        this.props.zipCode} ${this.props.city}`}
                  </div>
                </div>
              </div>
              {/* Telephone */}
              <div className='column'>
                <div className='icon'>
                  <i className='di di-phone' />
                </div>
                <div className='input_details'>
                  <div className='label'>
                    {strings.locationFlow_step4_phone}
                  </div>
                  <div
                    className={`value ${this.props.phone ? 'hasValue' : 'empty'}`}
                  >
                    {!this.props.phone ? strings.locationFlow_step4_notSet : this.props.phone}
                  </div>
                </div>
              </div>
            </div>

            <div className='line'>
              {/* CVR */}
              <div className='column'>
                <div className='icon'>
                  <i className='lnil lnil-shield' />
                </div>
                <div className='input_details'>
                  <div className='label'>{strings.locationFlow_step4_cvr}</div>
                  <div className={`value ${this.props.cvr ? 'hasValue' : 'empty'}`}>
                    {!this.props.cvr ? strings.locationFlow_step4_notSet : this.props.cvr}
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className='column'>
                <div className='icon'>
                  <i className='lnil lnil-envelope' />
                </div>
                <div className='input_details'>
                  <div className='label'>
                    {strings.locationFlow_step4_email}
                  </div>
                  <div
                    className={`value ${this.props.email ? 'hasValue' : 'empty'}`}
                  >
                    {!this.props.email ? strings.locationFlow_step4_notSet : this.props.email}
                  </div>
                </div>
              </div>
            </div>

            <div className='line'>
              {/* Registration no */}
              <div className='column'>
                <div className='icon'>
                  <i className='lnil lnil-bank' />
                </div>
                <div className='input_details'>
                  <div className='label'>
                    {strings.locationFlow_step4_accountNo}
                  </div>
                  <div
                    className={`value ${
                      this.props.registrationNo && this.props.accountNo ? 'hasValue' : 'empty'
                    }`}
                  >
                    {!this.props.registrationNo && !this.props.accountNo
                      ? strings.locationFlow_step4_notSet
                      : `${this.props.registrationNo} ${this.props.accountNo}`}
                  </div>
                </div>
              </div>
              {/* Webiste */}
              <div className='column'>
                <div className='icon'>
                  <i className='lnil lnil-link' />
                </div>
                <div className='input_details'>
                  <div className='label'>{strings.locationFlow_step4_web}</div>
                  <div
                    className={`value ${this.props.website ? 'hasValue' : 'empty'}`}
                  >
                    {!this.props.website
                      ? strings.locationFlow_step4_notSet
                      : this.props.website}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* This line should only be shown if showInIndex is "true". */}
          {this.props.hideLocation && (
            <div className='more_info'>
              <i className='lnir lnir-alarm-clock' />
              {strings.locationFlow_step4_visible}
            </div>
          )}

          <div className='link_zone'>
            {strings.locationFlow_step4_Manager}
            <span className='step_link'
              onClick={() => {
                this.props.createNewUserHandler();
              }}
            >
              {strings.locationFlow_step4_createUser}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Step4;
