import React, { useState } from 'react';
import './style.scss';

const RadioButtonList = ({
  options,
  selectedOption,
  customName,
  handleClick,
}) => {
  const [checkedOption, setCheckedOption] = useState(selectedOption);

  const handleOptionClick = (option) => {
    if (option.value === checkedOption) {
      setCheckedOption(null);
      handleClick(null);
    } else {
      setCheckedOption(option.value);
      handleClick(option.value);
    }
  };

  return (
    <div className='RadioButton_component'>
      {options.map((option, index) => (
        <div
          className='radio-button-container'
          key={index}
          onClick={() => handleOptionClick(option)}
        >
          <div
            type='radio'
            className={`radio-button ${
              option.value === selectedOption ? 'checked' : ''
            }`}
            name={customName || 'radio-button'}
            onClick={() => {}}
          />
          <span className='label'>{option.label}</span>
        </div>
      ))}
    </div>
  );
};

export default RadioButtonList;
