import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';
import { debounce } from 'lodash';
import { strings } from '../../../constants/localization';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import { getCompanyChains } from '../../../actions/companyChainActions';
import './style.scss';

const SearchCompanyChain = ({ fieldClass, handleSelectedCompanyChain }) => {
  const dispatch = useDispatch();
  const currentChain =
    useSelector((state) => state.company?.activeCompany?.companyChain) || {};
  const chainName =
    currentChain?.chainCompanyName || currentChain?.companyChainName || '';
  const [filteredCompanyChain, setFilteredCompanyChain] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasSelectedItem, setHasSelectedItem] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const debouncedSearchRef = useRef(null);

  useEffect(() => {
    // Initialize the debounced function
    // prevResults is used because the filteredCompanyChain looses the reference when used inside the debounced function
    debouncedSearchRef.current = debounce(
      ({ updatedPageNumber, updatedSearchTerm, prevResults }) => {
        fetchCompanyChains(updatedPageNumber, updatedSearchTerm, prevResults);
      },
      1000
    );
  }, []);

  useEffect(() => {
    if (pageNumber > 0) {
      debouncedSearchRef.current({
        updatedPageNumber: pageNumber,
        updatedSearchTerm: searchTerm,
        prevResults: filteredCompanyChain,
      });
    }
  }, [pageNumber]);

  // function to fetch the company chains based on the search term when the user has stopped typing
  const fetchCompanyChains = (
    activePageNumber,
    activeSearchTerm,
    prevResults
  ) => {
    try {
      if (activePageNumber === 0) {
        // abort any pending requests
        if (abortController) {
          abortController.abort();
        }
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      dispatch(
        getCompanyChains(
          activePageNumber,
          activeSearchTerm,
          null,
          null,
          null,
          newAbortController.signal
        )
      ).then((response) => {
        const companyChainSuggestions =
          response.data?.content || response.data?.content[0]?.companies;
        let newFilteredChainsList = [];
        if (activePageNumber === 0) {
          newFilteredChainsList = companyChainSuggestions;
        } else {
          newFilteredChainsList = prevResults.concat(companyChainSuggestions);
        }

        setFilteredCompanyChain(newFilteredChainsList);

        if (companyChainSuggestions.length < 20) {
          setHasMore(false);
          setLoading(false);
        } else {
          setPageNumber(activePageNumber + 1);
          setHasMore(true);
          setLoading(true);
        }
      });
    } catch (error) {
      console.log('Error on fetchCompanyChains', error);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setPageNumber(0);
    setHasSelectedItem(false);

    // Cancel the previous debounced function
    if (debouncedSearchRef.current) {
      debouncedSearchRef.current.cancel();
    }

    // Call the debounced function
    if (value) {
      setLoading(true);
      setFilteredCompanyChain([]);
      debouncedSearchRef.current({
        updatedPageNumber: 0,
        updatedSearchTerm: value,
        prevResults: [],
      });
    } else {
      setLoading(false);
    }
  };

  const handleDropdownSelection = (selection) => {
    handleSelectedCompanyChain(selection);
    setSearchTerm(selection?.companyChainName);
    setHasSelectedItem(true);
  };

  return (
    <div id='searchCompanyChain'>
      <Input
        type='text'
        name='CompanyChainSearch'
        className={` ${fieldClass || ''}`}
        onChange={(e) => handleInputChange(e)}
        value={searchTerm || ''}
        placeholder={chainName}
        autoFocus={true}
      />
      {!loading && <i className='lnir lnir-search-alt' />}
      {loading && (
        <div className='filter-loader'>
          <ButtonLoader />
        </div>
      )}

      {!hasSelectedItem && searchTerm !== '' && (
        <ListGroup className='searchCompanyChain__list'>
          {filteredCompanyChain?.length > 0 &&
            filteredCompanyChain?.map((suggestion, i) => {
              if (suggestion.status === 'DEACTIVE') {
                return null;
              }
              const className = '';
              return (
                <ListGroupItem
                  className={className}
                  key={i}
                  onClick={() => handleDropdownSelection(suggestion)}
                >
                  <div className='searchCompanyChain__list-item'>
                    {suggestion.companyChainName}
                  </div>
                </ListGroupItem>
              );
            })}
          {filteredCompanyChain.length > 0 && hasMore && (
            <ListGroupItem className='suggestions-loader'>
              <ButtonLoader />
            </ListGroupItem>
          )}
        </ListGroup>
      )}
      {filteredCompanyChain.length === 0 &&
        searchTerm !== '' &&
        hasMore === false &&
        !loading && (
          <ListGroup className='no-suggestions'>
            <ListGroupItem>{strings.searchEmpty}</ListGroupItem>
          </ListGroup>
        )}
    </div>
  );
};

export default SearchCompanyChain;
