import {
  CREATE_INSTALLMENT_LOAN_SUCCESS,
  DELETE_INSTALLMENT_LOAN,
  RESEND_SMS,
  RESEND_EMAIL,
  CLONE_INVOICE,
  GET_INSTALLMENT,
  GET_DEBT_COLLECTION_LOANS,
  REGISTER_DEBT_COLLECTION_LOANS,
  SET_LOAN_ACTIVE_PAGE,
  REQUEST_ACCEPT_LOAN_DETAILS,
  GET_LOAN_SIGNATURE_DATA,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_INSTALLMENT_LOAN_SUCCESS:
      return { ...state, createdInvoice: action.payload };
    case DELETE_INSTALLMENT_LOAN:
      return { ...state, deletedInvoice: action.payload };
    case GET_INSTALLMENT:
      return { ...state, invoice: action.payload.data };
    case RESEND_SMS:
      return action.payload;
    case CLONE_INVOICE:
      return { ...state, invoiceCloned: action.payload };
    case RESEND_EMAIL:
      return action.payload;
    case GET_DEBT_COLLECTION_LOANS:
      return { ...state, debtLoans: action.payload.data };
    case REQUEST_ACCEPT_LOAN_DETAILS:
      return { ...state, acceptLoan: action.payload };
    case SET_LOAN_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case REGISTER_DEBT_COLLECTION_LOANS:
      return { ...state, createdDebtLoan: action.payload.data };
    case GET_LOAN_SIGNATURE_DATA:
      return { ...state, signatureData: action.payload?.data };
    default:
      return state;
  }
}
