import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';
import './activateCreditModal.scss';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { ReactComponent as IconAddPatientSuccess } from '../../../resources/images/checkmark_circle.svg';
import {
  creditMinAmount,
  creditMaxAmountApproveCredit,
  creditMinInterestRate,
  creditMaxInterestRate,
  creditMinDurationMin,
  creditMinDurationMax,
} from '../../../config';
import {
  formatCurrency,
  removeCurrencyFormating,
  formatInterestRate,
  removePercentageOnFocus,
  numberWithDotAsThousandsSeparator,
  paymentListMoment,
  formatReasonWithTimestampAndUser,
} from '../../../constants/utils';
import { approveCreditApplication } from '../../../actions/onboardingApplicationsActions';

const ActivateCreditModal = ({ closeModal, reloadApplication }) => {
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const loggedUser = useSelector((state) => state.user?.user);
  const { firstName, lastName } = loggedUser || {};
  const displayUserName = `${firstName} ${lastName?.charAt(0)}.`;
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const { creditApplication } = activeApplicationListItem || {};
  const {
    amount,
    interestRate,
    minimumDuration,
    estimatedDisposableAmount,
    creditApplicationUuid,
  } = creditApplication || {};
  const [minDuration, setMinDuration] = useState(
    minimumDuration || creditMinDurationMin
  );
  const [maxMonthlyPayment, setMaxMonthlyPayment] = useState(
    estimatedDisposableAmount || ''
  );
  const [monthlyPaymentError, setMonthlyPaymentError] = useState(false);
  const [minAmountRequired, setMinAmountRequired] = useState('');

  const removeCurrencySignAndFormat = (value) => {
    if (value && typeof value === 'string') {
      return parseFloat(value.replaceAll('.', '').replaceAll(',', '.'), 10);
    }
    return value;
  };

  const removeInterestRateAndFormat = (value) => {
    if (value && typeof value === 'string') {
      return parseFloat(value.replace('%', '').replace(',', '.'));
    }
    return value;
  };

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      creditAmount: amount
        ? numberWithDotAsThousandsSeparator(
            amount?.toString().replace('.', ',')
          ) + ' kr.'
        : '',
      interestRate: formatInterestRate(interestRate) || '',
      estimatedDisposableAmount: estimatedDisposableAmount
        ? numberWithDotAsThousandsSeparator(
            estimatedDisposableAmount?.toString().replace('.', ',')
          ) + ' kr.'
        : '',
      reason: '',
    },
    validateOnMount: true,
    validate: (values) => {
      const errors = {};
      const { creditAmount, interestRate, estimatedDisposableAmount, reason } =
        values;
      const amountNo = removeCurrencySignAndFormat(creditAmount);
      const interestRateValue = removeInterestRateAndFormat(interestRate);
      const estimatedDisposableAmountNo = removeCurrencySignAndFormat(
        estimatedDisposableAmount
      );
      if (!amountNo) {
        errors.creditAmount = strings.required;
      } else if (
        amountNo < creditMinAmount ||
        amountNo > creditMaxAmountApproveCredit
      ) {
        errors.creditAmount = 'Amount not in range';
      }
      if (!interestRateValue) {
        errors.interestRate = strings.required;
      } else if (
        interestRateValue < creditMinInterestRate ||
        interestRateValue > creditMaxInterestRate
      ) {
        errors.interestRate = 'Interest rate not in range';
      }

      if (!estimatedDisposableAmountNo) {
        errors.estimatedDisposableAmount = strings.required;
      }
      if (!reason) {
        errors.reason = strings.required;
      }

      return errors;
    },
    onSubmit: (values) => {
      const { creditAmount, interestRate, estimatedDisposableAmount, reason } =
        values;
      const amountNo = removeCurrencySignAndFormat(creditAmount);
      const interestRateValue = removeInterestRateAndFormat(interestRate);
      const estimatedDisposableAmountNo = removeCurrencySignAndFormat(
        estimatedDisposableAmount
      );
      const formatedReason = formatReasonWithTimestampAndUser(
        reason,
        firstName,
        lastName
      );
      setLoadingSubmit(true);
      dispatch(
        approveCreditApplication({
          creditAmount: amountNo,
          estimatedDisposableIncome: estimatedDisposableAmountNo,
          interestRate: interestRateValue,
          reason: formatedReason,
          uuid: creditApplicationUuid,
        })
      ).finally(() => {
        setLoadingSubmit(false);
        reloadApplication();
        closeModal();
      });
    },
  });

  useEffect(() => {
    if (minDuration) {
      recalculateValues(minDuration);
    }
  }, [minDuration, maxMonthlyPayment]);

  const recalculateValues = (currentMinDuration) => {
    // if those values are set, then do the calculation for the maxMonthlyPayment
    const amount = formik.values.creditAmount;
    const interestRate = formik.values.interestRate;
    const estimatedDisposableAmount = formik.values.estimatedDisposableAmount;

    const amountNo = removeCurrencySignAndFormat(amount);
    // Remove '%' symbol and replace comma with dot
    const interestRateValue = removeInterestRateAndFormat(interestRate);
    if (amountNo && interestRateValue) {
      const minDurationNo = Number(currentMinDuration) || creditMinDurationMin;
      const startOfNextMonth = moment()
        .add(1, 'M')
        .startOf('month')
        .format('YYYY-MM-DD');
      // establismentFee is always 5 % of the loan amount
      const establishmentFee = 0.05 * amountNo;

      const paymentResults = paymentListMoment(
        startOfNextMonth,
        interestRateValue,
        amountNo,
        minDurationNo,
        parseFloat(establishmentFee)
      );
      const monthlyAmount = Number(paymentResults?.monthlyPayment?.toFixed(2));
      const numberEstimatedDisposableAmount = removeCurrencyFormating(
        estimatedDisposableAmount?.toString().replace('.', '')
      );

      if (
        numberEstimatedDisposableAmount &&
        monthlyAmount &&
        monthlyAmount > numberEstimatedDisposableAmount
      ) {
        //stop whenever Monthly Payment from the loan calculation logic is lower than maxMonthlyPayment and minDuration < 48
        if (minDurationNo < creditMinDurationMax) {
          setMinDuration(minDurationNo + 2);
        }
        if (minDuration >= creditMinDurationMax) {
          const estimatedMinRequired = monthlyAmount.toFixed(2);
          setMinAmountRequired(estimatedMinRequired);
          setMonthlyPaymentError(true);
        }
      } else {
        setMonthlyPaymentError(false);
      }
    }
  };

  const removeCurrencySign = (value) => {
    const valueUpdated = value
      ?.toString()
      ?.replace(',00 kr.', '')
      ?.replace(' kr.', '');
    return valueUpdated;
  };

  const handleFocus = (fieldName, value) => {
    setFocusedField(fieldName);
    if (fieldName === 'interestRate') {
      formik.setFieldValue('interestRate', removePercentageOnFocus(value));
    } else {
      formik.setFieldValue(fieldName, removeCurrencySign(value));
    }
  };

  const handleChange = (fieldName, value) => {
    if (
      fieldName === 'creditAmount' ||
      fieldName === 'estimatedDisposableAmount'
    ) {
      let result;
      const valueWithoutPoints = value?.replace(/[^\d,]/g, '');
      const nextRenderedValue =
        numberWithDotAsThousandsSeparator(valueWithoutPoints);
      const decimalIndex = nextRenderedValue.indexOf(',');
      if (decimalIndex !== -1 && nextRenderedValue.length - decimalIndex > 3) {
        // Truncate decimal places to 2
        const truncatedValue = nextRenderedValue.slice(0, decimalIndex + 3);
        result = truncatedValue;
      } else {
        result = nextRenderedValue;
      }
      formik.setFieldValue(fieldName, result);
    } else {
      formik.setFieldValue(fieldName, value);
    }
  };

  const handleBlur = (fieldName, value) => {
    setFocusedField(fieldName);
    // make sure to set the field as touched
    formik.setFieldTouched(fieldName, true);

    const clearStringNumber = value
      .replace('.', '')
      .replace(',', '.')
      .replace('%', '');
    const numberValue = parseFloat(clearStringNumber, 10);

    // make sure the validation is done on blur
    if (fieldName === 'creditAmount') {
      const valueUpdated = removeCurrencySignAndFormat(value);
      if (
        numberValue < creditMinAmount ||
        numberValue > creditMaxAmountApproveCredit
      ) {
        formik.errors.creditAmount = 'Amount not in range';
      }
      formik.setFieldValue('creditAmount', formatCurrency(value));
      if (valueUpdated !== amount) {
        setMinDuration(creditMinDurationMin);
        recalculateValues(creditMinDurationMin);
      }
    }

    // make sure the validation is done on blur
    if (fieldName === 'interestRate') {
      const valueUpdated = removeInterestRateAndFormat(value);
      if (
        numberValue < creditMinInterestRate ||
        numberValue > creditMaxInterestRate
      ) {
        formik.errors.interestRate = 'Interest rate not in range';
      }
      formik.setFieldValue('interestRate', formatInterestRate(value));
      if (valueUpdated !== interestRate) {
        setMinDuration(creditMinDurationMin);
        recalculateValues(creditMinDurationMin);
      }
    }

    // update the minDuration and maxMonthlyPayment when the estimatedDisposableAmount is changed
    if (fieldName === 'estimatedDisposableAmount') {
      const valueUpdated = removeCurrencySignAndFormat(value);
      formik.setFieldValue('estimatedDisposableAmount', formatCurrency(value));
      if (valueUpdated !== estimatedDisposableAmount) {
        setMinDuration(creditMinDurationMin);
        setMaxMonthlyPayment(
          value ? value.replace('.', '').replace(',', '.') : ''
        );
        setMinDuration(creditMinDurationMin);
        recalculateValues(creditMinDurationMin);
      }
    }
  };

  return (
    <DentiModal
      key={1}
      close={() => closeModal()}
      modalClass='activatingCreditApplicationModal'
      modalClassName='paid-out-details-action center_modal'
    >
      <div className='activating-credit-modal'>
        <div>
          <div className='form_icon'>
            <IconAddPatientSuccess className='form_icon_ico' />
          </div>

          <div className='form_title'>{strings.applicationApproveHeadline}</div>

          <div className='form_subtitle'>{strings.applicationApproveText}</div>

          <form
            onSubmit={formik.handleSubmit}
            id='activateCreditApplication'
            className='form_control'
          >
            <div>
              {/* CREDIT AMOUNT */}
              <label
                className={`form_control_group creditAmount ${
                  focusedField === 'creditAmount' ? 'active' : ''
                } ${
                  formik.touched.creditAmount && formik.errors.creditAmount
                    ? 'has-error'
                    : ''
                }`}
              >
                <div className='form_text'>
                  <div>{strings.creditDetailsAmount}</div>
                  <div
                    className={`${
                      formik.touched.creditAmount && formik.errors.creditAmount
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    {strings.formatString(
                      strings.newInvoiceAmountNote,
                      formatCurrency(
                        numberWithDotAsThousandsSeparator(
                          creditMaxAmountApproveCredit
                        )
                      ),
                      formatCurrency(
                        numberWithDotAsThousandsSeparator(creditMinAmount)
                      )
                    )}
                  </div>
                </div>
                <input
                  name='creditAmount'
                  className={`input form_input ${
                    formik.touched.creditAmount && formik.errors.creditAmount
                      ? 'input_danger'
                      : ''
                  }`}
                  placeholder='0,00 kr.'
                  onChange={(e) => handleChange('creditAmount', e.target.value)}
                  onBlur={(e) => handleBlur('creditAmount', e.target.value)}
                  onFocus={(e) => handleFocus('creditAmount', e.target.value)}
                  value={formik.values.creditAmount}
                />
              </label>

              {/* INTEREST RATE */}
              <label
                className={`form_control_group interestRate ${
                  focusedField === 'interestRate' ? 'active' : ''
                } ${
                  formik.touched.interestRate && formik.errors.interestRate
                    ? 'has-error'
                    : ''
                }`}
              >
                <div className='form_text'>
                  <div>{strings.creditDetailsInterestRate}</div>
                  <div
                    className={`${
                      formik.touched.interestRate && formik.errors.interestRate
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    {strings.formatString(
                      strings.newInvoiceAmountNote,
                      formatInterestRate(creditMaxInterestRate),
                      formatInterestRate(creditMinInterestRate)
                    )}
                  </div>
                </div>
                <input
                  name='formatInterestRate'
                  className={`input form_input ${
                    formik.touched.interestRate && formik.errors.interestRate
                      ? 'input_danger'
                      : ''
                  }`}
                  placeholder='0,00 kr.'
                  onChange={(e) => handleChange('interestRate', e.target.value)}
                  onBlur={(e) => handleBlur('interestRate', e.target.value)}
                  onFocus={(e) => handleFocus('interestRate', e.target.value)}
                  value={formik.values.interestRate}
                />
              </label>

              {/* ESTIMATED DISPOASABLE AMOUNT */}
              <label
                className={`form_control_group estimatedDisposableAmount ${
                  focusedField === 'estimatedDisposableAmount' ? 'active' : ''
                } ${
                  formik.errors.estimatedDisposableAmount ? 'has-error' : ''
                }`}
              >
                <div className='form_text'>
                  <div>{strings.creditDetailsEstimatedDisposableAmount}</div>
                  {formik.errors.estimatedDisposableAmount && (
                    <div className='text-danger'>
                      {formik.errors.estimatedDisposableAmount}
                    </div>
                  )}
                </div>
                <input
                  name='estimatedDisposableAmount'
                  className={`input form_input ${
                    formik.errors.estimatedDisposableAmount
                      ? 'input_danger'
                      : ''
                  }`}
                  placeholder='0,00 kr.'
                  onChange={(e) =>
                    handleChange('estimatedDisposableAmount', e.target.value)
                  }
                  onBlur={(e) =>
                    handleBlur('estimatedDisposableAmount', e.target.value)
                  }
                  onFocus={(e) =>
                    handleFocus('estimatedDisposableAmount', e.target.value)
                  }
                  value={formik.values.estimatedDisposableAmount}
                />
              </label>

              {/* REASON */}
              <label
                className={`form_control_group reason ${
                  focusedField === 'reason' ? 'active' : ''
                } ${
                  formik.touched.reason && formik.errors.reason
                    ? 'has-error'
                    : ''
                }`}
              >
                <div className='form_text'>
                  <div>{strings.applicationApproveComment}</div>
                  {formik.touched.reason && formik.errors.reason && (
                    <div className='text-danger'>{formik.errors.reason}</div>
                  )}
                </div>
                <textarea
                  name='reason'
                  className={`input form_input ${
                    formik.touched.reason && formik.errors.reason
                      ? 'input_danger'
                      : ''
                  }`}
                  maxLength={200}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={formik.handleFocus}
                  value={formik.values.reason}
                  autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                />
                <div className='note-card mb-2'>
                  <div className='support-info'>
                    {moment().format('DD.MM.YYYY')}
                    <span className='support-user'>{displayUserName}</span>
                  </div>
                </div>
              </label>
            </div>

            {/* MIN DURATION & MAX MONTHLY PAYMENT */}
            <div className='conclusions-section'>
              <div className='side-by-side-infobox'>
                <span>
                  {strings.applicationApprovedModalConclusionSectionMonthly}
                  <b className='calculated-amount'>
                    {minDuration ? `${minDuration} mdr.` : '-'}
                  </b>
                </span>
                <span>
                  {
                    strings.applicationApprovedModalConclusionSectionMonthlyPayment
                  }
                  <b className='calculated-amount'>
                    {maxMonthlyPayment
                      ? numberWithDotAsThousandsSeparator(
                          maxMonthlyPayment?.toString().replace('.', ',')
                        ) + ' kr.'
                      : '-'}
                  </b>
                </span>
              </div>
            </div>
            {maxMonthlyPayment && monthlyPaymentError && (
              <div className='calculation-error text-danger'>
                {strings.formatString(
                  strings.creditDetailsValidateValuesError,
                  minAmountRequired.toString().includes('kr')
                    ? minAmountRequired
                    : formatCurrency(
                        numberWithDotAsThousandsSeparator(
                          minAmountRequired?.replace('.', ',')
                        )
                      ) || '',
                  formik.values.creditAmount || ''
                )}
              </div>
            )}

            {/* BUTTONS */}
            <div className='buttonArea action-btn cf'>
              <button
                className='submit-btn btn btn-blue center-block'
                type='submit'
                disabled={
                  loadingSubmit || !formik.isValid || monthlyPaymentError
                }
              >
                {!loadingSubmit ? (
                  <>
                    <i className='di di-accept' />
                    <span>{strings.applicationApproveBtn}</span>
                  </>
                ) : (
                  <ButtonLoader />
                )}
              </button>
              <button
                className='cancel-btn btn btn-light center-block'
                type='button'
                onClick={closeModal}
                disabled={loadingSubmit}
              >
                <i className='di di-cancel' />
                <span>{strings.applicationCancelActionBtn}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </DentiModal>
  );
};

export default ActivateCreditModal;
