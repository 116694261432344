import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import { brandConfig } from '../../config';

const TermsConditions = (props) => (
  <div className='terms-conditions'>
    <IframeResizer
      title='denti-terms'
      checkOrigin={false}
      src={!props.bnpl ? brandConfig.termsAndConditionsWithoutBnpl : brandConfig.termsAndConditionsWithBnpl }
      heightCalculationMethod='lowestElement'
      style={{
        width: '1px', minWidth: '100%', border: 0, minHeight: '4200px',
      }}
    />
  </div>
);

export default TermsConditions;
