import React, { useState, useEffect, useRef } from 'react';

import { Button, CardBody, Form, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import { onboardingTestData, Sundhedplus } from '../../config';
import {
  emailValidation,
  letters,
  phoneNoFormatWithPrefix,
  normalizePhone,
} from '../../constants/utils';
import { storeOnboardingData } from '../../actions/onboardingActions';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';

const JoinSundhed = ({ setNextStep, step }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const testMode = savedData?.testMode;
  const [firstName, setFirstName] = useState(savedData?.firstName || '');
  const [lastName, setLastName] = useState(savedData?.lastName || '');
  const [email, setEmail] = useState(savedData?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(savedData?.phoneNumber || '');
  const [buttonLoading, setButtonLoading] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingFlowTitle}`;
  }, []);

  useEffect(() => {
    if (testMode) {
      setFirstName(onboardingTestData?.firstName);
      setLastName(onboardingTestData?.lastName);
      setEmail(onboardingTestData?.email);
      setPhoneNumber(onboardingTestData?.phone);
    } else {
      setFirstName(savedData?.firstName || '');
      setLastName(savedData?.lastName || '');
      setEmail(savedData?.email || '');
      setPhoneNumber(savedData?.phoneNumber || '');
    }
  }, [testMode, savedData]);

  const handleValidation = () => {
    const errors = {};
    if (!email) {
      errors.email = strings.required;
    } else if (emailValidation(email)) {
      errors.email = strings.invalidEmail;
    }

    if (!phoneNumber) {
      errors.phoneNumber = strings.required;
    } else {
      const normalizedPhone = normalizePhone(phoneNumber);
      // validate is a danish number
      if (!/^(\+45)?\d{8}$/.test(normalizedPhone)) {
        errors.phoneNumber = strings.danishNumber;
      }
    }

    if (!firstName) {
      errors.firstName = strings.required;
    } else if (firstName.length < 2) {
      errors.firstName = strings.onboardingFirstNameTooShort;
    } else if (letters(firstName)) {
      errors.firstName = strings.onlyLetters;
    }

    if (!lastName) {
      errors.lastName = strings.required;
    } else if (lastName.length < 2) {
      errors.lastName = strings.onboardingLastNameTooShort;
    } else if (letters(lastName)) {
      errors.lastName = strings.onlyLetters;
    }
    setEmailError(errors.email);
    setPhoneError(errors.phoneNumber);
    setFirstNameError(errors.firstName);
    setLastNameError(errors.lastName);

    return errors;
  };

  const updateField = (value, field) => {
    switch (field) {
      case 'email':
        setEmail(value);
        setEmailError('');
        break;
      case 'phoneNumber':
        setPhoneNumber(value.replace(/[^\d+]/g, ''));
        setPhoneError('');
        break;
      case 'firstName':
        setFirstName(value);
        setFirstNameError('');
        break;
      case 'lastName':
        setLastName(value);
        setLastNameError('');
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    setButtonLoading(true);

    if (Object.keys(handleValidation()).length === 0) {
      setButtonLoading(true);
      const phoneWithoutSpaces = phoneNumber.startsWith('+45')
        ? phoneNumber.substring(3)
        : phoneNumber.replace(/\s+/g, '');

      dispatch(
        storeOnboardingData({
          ...savedData,
          firstName,
          lastName,
          email,
          phoneNumber: phoneWithoutSpaces,
        })
      );
      setNextStep(step + 1);

      setButtonLoading(false);
    } else {
      setButtonLoading(false);
    }
  };

  const handleKeyDown = (event, ref) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior (form submission)
      if (ref && ref.current) {
        ref.current.focus(); // Focus on the next input field
      } else {
        handleSubmit(); // if there is no next input field as ref, submit the form
      }
    }
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>{strings.onboardingFlowTitle}</div>
      <div className='step-subtitle center'>
        <p>{strings.onboardingFlowSubtitle}</p>
        <p>{strings.onboardingFlowFirstInformation}</p>
      </div>
      <Form className='form-details mb-0'>
        <div className='form-group'>
          <div className='input-details'>
            <div className='label-wrapper'>
              <Label>{strings.firstName}</Label>
              {firstNameError && (
                <span className='error'>{firstNameError}</span>
              )}
            </div>
            <input
              type='text'
              value={firstName}
              className={firstNameError ? 'input_danger' : ''}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={(e) => {
                updateField(e.target.value, 'firstName');
              }}
              onKeyDown={(e) => handleKeyDown(e, input2Ref)}
              ref={input1Ref}
            />
          </div>
          <div className='input-details'>
            <div className='label-wrapper'>
              <Label>{strings.lastName}</Label>
              {lastNameError && <span className='error'>{lastNameError}</span>}
            </div>
            <input
              type='text'
              value={lastName}
              className={lastNameError ? 'input_danger' : ''}
              // eslint-disable-next-line jsx-a11y/no-autofocus

              onChange={(e) => {
                updateField(e.target.value, 'lastName');
              }}
              onKeyDown={(e) => handleKeyDown(e, input3Ref)}
              ref={input2Ref}
            />
          </div>

          <div className='input-details'>
            <div className='label-wrapper'>
              <Label>{strings.telLabel}</Label>
              {phoneError && <span className='error'>{phoneError}</span>}
            </div>
            <input
              type='phoneNumber'
              className={phoneError ? 'input_danger' : ''}
              value={phoneNoFormatWithPrefix(phoneNumber)}
              minLength={phoneNumber.startsWith('+') ? 11 : 8}
              maxLength={phoneNumber.startsWith('+') ? 15 : 11}
              onChange={(e) => {
                updateField(e.target.value, 'phoneNumber');
              }}
              onKeyDown={(e) => handleKeyDown(e, input4Ref)}
              ref={input3Ref}
            />
          </div>
          <div className='input-details'>
            <div className='label-wrapper'>
              <Label>{strings.email}</Label>
              {emailError && <span className='error'>{emailError}</span>}
            </div>
            <input
              type='email'
              className={emailError ? 'input_danger' : ''}
              value={email}
              // eslint-disable-next-line jsx-a11y/no-autofocus

              onChange={(e) => {
                updateField(e.target.value, 'email');
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              ref={input4Ref}
            />
          </div>
        </div>
      </Form>
      <p className='onboardingPolicyPrivacy center'>
        {strings.formatString(
          strings.onboardingJoinSundhedPlus,
          <a
            className='color'
            href={`${Sundhedplus.privacyPolicy}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {strings.onboardingConsentHandlingDataLink}
          </a>
        )}
      </p>
      <div className='actions-buttons step-buttons'>
        <Button
          type='submit'
          className='btn btn-blue btn-success'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? <ButtonLoader /> : strings.onboardingJoinBtn}
        </Button>
      </div>
    </CardBody>
  );
};

export default JoinSundhed;
