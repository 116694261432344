import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { ReactComponent as InfoCircle } from '../../../resources/icons/info-circle.svg';
import DentiModal from '../../common/DentiModal';
import { brandConfig } from '../../../config';

const UploadError = ({ closeModal }) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-status-modal error'
      modalClassName='upload-modal'
      close={() => closeModal()}
      closeBtn={true}
    >
      <div className='content-wrapper error d-flex align-items-center justify-content-center'>
        <h3 className='headline'>{strings.onboardingUploadErrorHeadline}</h3>
        <div className='status-icon'>
          <InfoCircle className='info-circle' />
        </div>
        <div className='error-text'>
          <p>{strings.onboardingUploadErrorP}</p>
        </div>

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => closeModal()}
          >
            {strings.onboardingUploadErrorBtn}
          </Button>
        </div>
        <footer>
          <p>
            {strings.formatString(
              strings.onboardingUploadDocBottomText,
              <br />,
              <a href={`tel:${brandConfig.contactPhone}`}>
                {brandConfig.contactPhoneHtmlFormated}
              </a>
            )}
          </p>
        </footer>
      </div>
    </DentiModal>
  );
};

export default UploadError;
