import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';

const ApprovedDocumentation = ({ closeModal, goToLastStep }) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding approved-documentation upload-status-modal'
      modalClassName='upload-modal'
      close={() => closeModal()}
      closeBtn={false}
    >
      <div className='content-wrapper success d-flex align-items-center justify-content-center'>
        <h3 className='headline'>
          <span>{strings.onboadringUploadApprovedHedline}</span>
          <span className='subheadline'>
            {strings.onboadringUploadApprovedSubtitle}
          </span>
        </h3>
        {/* <h4></h4> */}
        <div className='status-icon'>
          <i className='di di-circle-check' />
        </div>
        <div className='subtitle'>{strings.onboadringUploadApprovedP1}</div>

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => goToLastStep()}
          >
            {strings.onboadringUploadApprovedOk}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default ApprovedDocumentation;
