import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Label } from 'reactstrap';
import { strings } from '../../../constants/localization';
import {
  currencyFormatDA,
  formatCreditCardWithMaskNumberAlreadyMasked,
} from '../../../constants/utils';
import { SERVER_URL, momentFormatLong } from '../../../constants';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { cardStatusMap } from '../../../constants/mappings';

interface paymentDetailsProps {
  creditFacilityData: {
    status: string;
    paymentData: {
      latestPaidPaymentDate: string;
      latestPaymentAmount: number;
    };
    creditCardData: {
      status: string;
      maskedCardNumber: string;
    };
    installmentLoanDataList: [
      {
        number: string;
      }
    ];
  };
}

const PaymentDetails: React.FC = () => {
  // const [paymentDetails, setPaymentDetails] = useState<PaymentDetail[]>([]);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);

  const creditClientInformation = useSelector(
    (state: { memberPortal: { clientDataInformation: paymentDetailsProps } }) =>
      state.memberPortal?.clientDataInformation
  );

  const {
    creditFacilityData: {
      status = '',
      creditCardData: {
        status: creditCardDataStatus = null,
        maskedCardNumber: clientMaskedCardNumber = '',
      } = {},
      paymentData: {
        latestPaidPaymentDate: latestPaymentDate = '',
        latestPaymentAmount: lastPaymentAmount = 0,
      } = {},
      installmentLoanDataList: latestInstallmentLoanData = [],
    } = {},
  } = creditClientInformation || {};

  const lastInvoiceDetails = latestInstallmentLoanData.length
    ? latestInstallmentLoanData[latestInstallmentLoanData.length - 1].number
    : undefined;

  const isDisabled = status === 'DISABLED';

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const creitCardStatus = creditCardDataStatus
    ? creditCardDataStatus
    : 'NO_CARD';

  return (
    <Card className='custom-card mitPaymentDetails'>
      <CardBody className='credit-info-card'>
        <div className='title'>{strings.mitPaymentDetailsTitle}</div>
        <div className='section-item'>
          <div className='budget-entry'>
            <Label>{strings.mitPaymentDetailsMonthlyPayment}</Label>
            <span className='right'>
              {!isDisabled && lastPaymentAmount > 0
                ? currencyFormatDA(lastPaymentAmount || 0, true, true)
                : '-'}
            </span>
          </div>
          <div className='budget-entry'>
            <Label>{strings.mitPaymentDetailsLastPayment}</Label>
            <span className='right'>
              {!isDisabled && latestPaymentDate
                ? moment(latestPaymentDate).format(momentFormatLong)
                : '-'}
            </span>
          </div>
          <div className='budget-entry'>
            <Label>{strings.mitPaymentDetailsLinkedPaymentCard}</Label>
            {!smallScreen && !isDisabled && (
              <span className='right creditCard'>
                <a
                  href={`${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${lastInvoiceDetails}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Button className='card-info'>
                    {strings.mitUpdateCreditCard}
                  </Button>
                </a>

                {clientMaskedCardNumber && !isDisabled
                  ? formatCreditCardWithMaskNumberAlreadyMasked(
                      clientMaskedCardNumber
                    )
                  : '-'}
              </span>
            )}
            {smallScreen &&
              !isDisabled &&
              formatCreditCardWithMaskNumberAlreadyMasked(
                clientMaskedCardNumber
              )}
          </div>
          <div className='budget-entry'>
            <Label>{strings.mitPaymentDetailsCardStatus}</Label>
            <span
              className={`right ${cardStatusMap[creitCardStatus].className}`}
            >
              {!isDisabled &&
                strings[cardStatusMap[creitCardStatus].statusText]}
            </span>
          </div>
          {smallScreen && (
            <div className='renew-creditCard'>
              <a
                href={`${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${lastInvoiceDetails}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button className='btn btn-blue btn-renew-card-button'>
                  {strings.mitUpdateCreditCard}
                </Button>
              </a>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default PaymentDetails;
