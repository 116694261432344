import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import Fade from '../../common/Fade';

const DocumentPreview = ({ docURL, documentFileName, thumbnailLink }) => {
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );
  const { firstName, lastName } = activeDocListItem || {};

  const renderFilePreview = () => {
    return (
      <div className='img-viewer'>
        <img
          src={thumbnailLink}
          alt={`documentation for ${firstName} ${lastName}`}
          onError={(event) => {
            event.target.src =
              'https://liftlearning.com/wp-content/uploads/2020/09/default-image.png';
            event.onerror = null;
          }}
        />
      </div>
    );
  };

  return (
    <section className='small-card wrapper card-body has-tooltip bottom'>
      <a href={docURL} target='_blank' rel='noopener noreferrer'>
        <div className='doc-preview'>
          <div className='wrapper card-text document-area'>
            <Row>
              <Fade show='true'>
                <div className=''>{renderFilePreview()}</div>
              </Fade>
            </Row>
          </div>

          <div className='cardDescription'>{documentFileName}</div>
          <span className='tooltip'>{documentFileName}</span>
        </div>
      </a>
    </section>
  );
};

export default DocumentPreview;
