import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import SearchClinic from '../../../../common/SearchClinic/SearchClinic';
import { strings } from '../../../../../constants/localization';
import { isAdmin } from '../../../../../constants/utils';
import GrandAccessSuccess from './GrandAccessSuccess/GrandAccessSuccess';
import GrandAccessFail from './GrandAccessFail/GrandAccessFail';
import ReasignClinic from './GrandAccessFail/ReasignClinic';
import './style.scss';
import ButtonLoader from '../../../../common/ButtonLoader/ButtonLoader';
import { toggleServerModalError } from '../../../../../actions/uiElementsActions';
import { grantClinicAccess } from '../../../../../actions/leadActions';

class GrantAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clinic: !isAdmin(props.user)
        ? { referenceNumber: props.activeCompany }
        : {},
      step: 0,
      error: null,
      loading: false,
    };
    this.grantAccess = this.grantAccess.bind(this);
    this.selectClinic = this.selectClinic.bind(this);
  }

  selectClinic(newClinic) {
    this.setState({
      clinic: newClinic,
    });
  }

  grantAccess() {
    if (this.state.loading) {
      return;
    }
    if (this.state.clinic.referenceNumber) {
      this.setState({ loading: true }, () => {
        this.props
          .grantClinicAccess(
            this.props.creditFacilityNumber,
            this.state.clinic.referenceNumber
          )
          .then((response) => {
            if (response.payload.status === 200) {
              this.setState({
                step: 1,
                loading: false,
              });
            }
          })
          .catch((error) => {
            if (error.response.data && error.response.status === 400) {
              this.props.toggleServerModalError(false);
              this.setState({
                step: 2,
                loading: false,
              });
            } else {
              this.props.toggleServerModalError(false);
              this.setState({
                step: 3,
                loading: false,
                error:
                  error.response.data?.message || error.response.data?.cause,
              });
            }
          });
      });
    }
  }

  render() {
    const { clientName } = this.props;
    const { step, loading } = this.state;
    return (
      <div className='grant_access_Component'>
        {step === 0 && (
          <>
            <div className='grant_access_Component-header'>
              <i className='lnir  lnir-house-heart' />
              <div className='title'>{strings.assignClinic}</div>
            </div>
            <div className='grant_access_Component-section'>
              <div className='select-patient-note'>
                {strings.formatString(strings.selectPatient, clientName)}
              </div>
              <div className='search-wrapper grant-access'>
                <SearchClinic
                  selectClinic={this.selectClinic}
                  selected={this.state.clinic}
                  format
                  showAdress
                  hideLabel
                  showStatus
                  inputHasFocus
                />
              </div>
            </div>
            <div className='grant_access_Component-footer'>
              <div>{strings.selectPatientNotePart1}</div>
              <div>{strings.selectPatientNotePart2}</div>
            </div>

            <div className='grant_access_Component-button_area'>
              <Button
                color={`btn ${
                  this.state.clinic.referenceNumber
                    ? 'btn-blue'
                    : 'btn-light disabled'
                }`}
                onClick={this.grantAccess}
                disabled={
                  this.state.clinic.referenceNumber && this.state.loading
                }
              >
                {!loading && (
                  <>
                    <i className='lnir lnir-circle-plus' />
                    {strings.assignGrantedClinic}
                  </>
                )}
                {loading && <ButtonLoader />}
              </Button>
            </div>
          </>
        )}
        {step === 1 && (
          <GrandAccessSuccess
            close={this.props.close}
            patientName={this.props.clientName}
            clinicName={this.state.clinic.companyName}
          />
        )}
        {step === 2 && (
          <ReasignClinic
            close={this.props.close}
            patientName={this.props.clientName}
            clinicName={this.state.clinic.companyName}
          />
        )}
        {step === 3 && (
          <GrandAccessFail
            close={this.props.close}
            patientName={this.props.clientName}
            clinicName={this.state.clinic.companyName}
            error={this.state.error}
          />
        )}
      </div>
    );
  }
}

GrantAccess.propTypes = {
  clientName: PropTypes.string,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedCompany: state.company.selectedCompanyRef,
    activeCompany: state.user.user.activeCompany,
    user: state.user.user,
    creditFacilityNumber:
      state.lead.activeLead.creditFacilityInformation.number,
  };
}

export default connect(mapStateToProps, {
  toggleServerModalError,
  grantClinicAccess,
})(GrantAccess);
