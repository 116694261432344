import {
  GET_USER,
  LOG_OUT,
  SET_USER,
  SET_USER_CLEAN,
  AUTH_USER,
  ERROR_AUTH_USER,
  ERROR_USER_AUTHORITY,
  ERROR_DETAILS_AUTH_USER,
  LOG_IN,
  USER_LOCKDOWN,
  UPDATE_CARD_DETAILS,
  GET_ALL_ADMINS,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_USER:
      return { ...state, user: action.payload.data };
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_USER_CLEAN:
      return { ...state, userClean: action.payload };
    case AUTH_USER:
      return { ...state, isAuth: action.payload };
    case ERROR_AUTH_USER:
      return { ...state, hasError: action.payload, hasAuthority: true };
    case ERROR_USER_AUTHORITY:
      return { ...state, hasAuthority: action.payload, hasError: false };
    case ERROR_DETAILS_AUTH_USER:
      return { ...state, errorDetails: action.payload };
    case USER_LOCKDOWN:
      return { ...state, userLockdown: action.payload };
    case UPDATE_CARD_DETAILS:
      return { ...state, clientDetails: action.payload.data };
    case GET_ALL_ADMINS:
      return { ...state, admins: action.payload.data };
    case LOG_IN:
      return { ...state };
    case LOG_OUT:
      return {};
    default:
      return state;
  }
}
