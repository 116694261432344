import React from 'react';

import './ButtonLoader.scss'

const ButtonLoader = () => (
  <div className='ButtonLoader_component'>
    ..loading
  </div>
)
export default ButtonLoader;
