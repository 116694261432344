import React from 'react';
import Input from '../common/AppInput/Input';
import { strings } from '../../constants/localization';
import { formatDecimalsSpace } from '../../constants/utils';
import NemKonto from '../../resources/images/NemKonto-logo.png';

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.props.validator.initialize('step3');
    this.state = {
      validator: this.props.validator,
      Option1Selected: false,
      Option2Selected: false,
    };
  }

  buildValidationHandler(key, validadFunc) {
    return (value) => {
      const valid = validadFunc(value);
      this.state.validator.setValid(key, valid);
      return valid;
    };
  }

  render() {
    if (this.props.currentStep !== 3) {
      return null;
    }
    return (
      <div className='step step3'>
        <div className='icon'>
          <i className='lnil lnil-house-heart' />
        </div>
        <div className='title'>{strings.locationFlow_step3_title}</div>
        <div className='description'>
          {strings.formatString(
            strings.locationFlow_step3_description1,
            this.props.chainBrand || ''
          )}
        </div>
        <div className='description'>
          {strings.locationFlow_step3_description2}
        </div>

        <div className='formWrapper'>
          {/* First option */}
          <div
            className={`selector first 
             ${this.state.Option1Selected ? 'selected' : ''}
             ${
               !this.props.cleanStep &&
               !this.props.validator.isValid() &&
               this.props.validator.hasKey('step3')
                 ? 'invalid'
                 : ''
             }
             `}
            onClick={() => {
              this.props.validator.initialize();
              this.setState({
                Option1Selected: true,
                Option2Selected: false,
              });
              this.props.resetStep3Values();
              this.props.handleChange({
                target: { name: 'useNemKonto', value: true },
              });
            }}
          >
            <div className='roundOption' />
            <div className='icon'>
              <img src={NemKonto} alt='konto' />
            </div>
            <div className='textzone'>
              {`(${strings.formatString(
                strings.locationFlow_step3_cvrTarget,
                formatDecimalsSpace(this.props.cvr)
              )})`}
            </div>
          </div>

          {/* Second option */}
          <div
            className={`selector second 
            ${this.state.Option2Selected ? 'selected' : ''}
            ${
              !this.props.cleanStep &&
              !this.props.validator.isValid() &&
              this.props.validator.hasKey('step3')
                ? 'invalid'
                : ''
            }
            `}
            onClick={() => {
              this.props.validator.initialize('registrationNo', 'accountNo');
              this.setState({
                Option1Selected: false,
                Option2Selected: true,
              });
              this.props.handleChange({
                target: { name: 'step3AnotherBank', value: true },
              });
            }}
          >
            <div className='roundOption' />
            <div className='icon'>
              <i className='lnir lnir-bank' />
            </div>
            <div className='textzone'>
              {strings.locationFlow_step3_anotherBank}
            </div>
          </div>

          {/* Content based on the option */}
          <div className='selectionContainer'>
            {this.state.Option1Selected && (
              <div className='warning_zone first'>
                {strings.formatString(
                  strings.locationFlow_step3_selectionMeaning,
                  this.props.cvr
                )}
              </div>
            )}

            {this.state.Option2Selected && (
              <div className='warning_zone second'>
                <div className='warning_row'>
                  <div className='regno_area'>
                    <Input
                      autoFocus
                      type='text'
                      name='registrationNo'
                      label={strings.locationFlow_step3_regno}
                      placeholder={strings.locationFlow_step3_regno}
                      required={!!this.state.Option2Selected}
                      value={this.props.registrationNo}
                      onChange={this.props.handleChange}
                      maxLength='4'
                      validation={this.buildValidationHandler(
                        'registrationNo',
                        (value) =>
                          Input.DefaultValidators.isNotEmpty(value) &&
                          Input.DefaultValidators.isExactly4Digits(value)
                      )}
                      errorMsg={strings.locationFlow_step3_regno_error}
                      cleanStep={this.props.cleanStep}
                      stepIsValid={this.props.stepIsValid}
                    />
                  </div>
                  <div className='acountNo_area'>
                    <Input
                      id='accountNo'
                      type='text'
                      name='accountNo'
                      label={strings.locationFlow_step3_acountNo}
                      placeholder={strings.locationFlow_step3_acountNo}
                      required={!!this.state.Option2Selected}
                      value={this.props.accountNo}
                      onChange={this.props.handleChange}
                      maxLength='10'
                      validation={this.buildValidationHandler(
                        'accountNo',
                        (value) =>
                          Input.DefaultValidators.isNotEmpty(value) &&
                          Input.DefaultValidators.isExactly10Digits(value)
                      )}
                      errorMsg={strings.locationFlow_step3_acountNo_error}
                      cleanStep={this.props.cleanStep}
                      stepIsValid={this.props.stepIsValid}
                    />
                  </div>
                </div>
                <div className='warning_row'>
                  {strings.formatString(
                    strings.locationFlow_step3_noResp,
                    this.props.chainBrand || ''
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Step3;
