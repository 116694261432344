import React, { useEffect } from 'react';
import { Button, Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import brokenRobot from '../../resources/images/broken-robot.svg';
import { moment } from '../../constants';

const SomethingWentWrongError = (props) => {
  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
      : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');

  // for migrate credit flow, send page event to customer.io tracking system, after component is mounted
  useEffect(() => {
    if (window.location.pathname === '/migrate-to-sundhedplus') {
      _cio.page('migration - ' + strings.errorServerTitle);
    }
  }, []);

  return (
    <div className='something-went-wrong'>
      <h1 className='title'>{strings.errorServerTitle}</h1>
      <div className='error-info'>
        <p>{strings.errorServerText1}</p>
        <p>{strings.errorServerText2}</p>
      </div>
      <div className='error-image-container'>
        <img
          src={brokenRobot}
          alt='broken-robot'
          className='image-broken-robot'
        />
      </div>
      <div className='visit-action-buttons'>
        <Button color='blue' tag='a' href={`tel:${phoneNumber}`}>
          {strings.formatString(strings.declineCallText, '', phoneNumber)}
        </Button>
        <Button
          color='blue'
          tag='a'
          href={`mailto:${brandConfig.contactEmail}`}
        >
          {strings.declineEmailText}
        </Button>

        {/* More tehnical information section */}
        {props.serverErrorDetails !== undefined && (
          <>
            <Button className='more-technical-info' outline id='toggler'>
              {strings.showMoreTehnicalInformation}
              <i className='lnil lnil-chevron-down'></i>
            </Button>
            <UncontrolledCollapse
              toggler='#toggler'
              className='collapsible-section'
            >
              <Card>
                <CardBody>
                  {typeof props.serverErrorDetails === 'object' ? (
                    <>
                      <div>
                        <b>{strings.httpText} </b>
                        {props.serverErrorDetails.status}
                      </div>
                      <div>
                        <b>{strings.httpMessage} </b>
                        {props.serverErrorDetails.message
                          ? props.serverErrorDetails.message
                          : '-'}
                      </div>
                    </>
                  ) : (
                    <div>
                      <b>{strings.httpMessage} </b>
                      {props.serverErrorDetails}
                    </div>
                  )}
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </>
        )}
      </div>
      <div className='timestamp'>{moment().format('DD-MM-YYYY  HH:mm:ss')}</div>
    </div>
  );
};

export default SomethingWentWrongError;
