import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { strings } from '../../../../../constants/localization';
import { currencyFormatDA, isAdmin } from '../../../../../constants/utils';
import { moment } from '../../../../../constants';

import './style.scss';

function CreditAmount(props) {
  const { creditDetails, user } = props;
  let leftAmount = creditDetails.amountLeftWithoutBonus;
  let amount = creditDetails.amount;
  const isUserAdmin = isAdmin(user);
  const timeDiference = moment().diff(
    moment(creditDetails.expirationDate).add(1, 'days')
  );
  const isSuspended = creditDetails.status === 'SUSPENDED' && !isAdmin(user);
  const isRejected = creditDetails.status === 'REJECTED';
  const isExpired = timeDiference >= 0;
  if ((isSuspended || isRejected || isExpired) && !isUserAdmin) {
    amount = 0;
    leftAmount = 0;
  }

  return (
    <div id='CreditInfoComponent'>
      <div className='icon_area'>
        <i className='lnil lnil-mobile-coin green' />
      </div>
      <div className='content_area'>
        <div className='top_area'>
          {`${currencyFormatDA(leftAmount, true, true, false)} ${
            (isUserAdmin || leftAmount > 0) && isUserAdmin
              ? ` / ${currencyFormatDA(amount, true, true, false)}`
              : ''
          }`}
        </div>
        <div className='bottom_area'>{strings.availableCreditAmunt}</div>
      </div>
    </div>
  );
}

CreditAmount.propTypes = {
  creditDetails: PropTypes.object,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

export default connect(mapStateToProps)(CreditAmount);
