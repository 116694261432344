import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { debounce } from 'lodash';
import { strings } from '../../constants/localization';
import Checkbox from '../common/Checkbox/Checkbox';
import TermsConditions from '../common/TermsAndConditions/TermsConditions';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import {
  createMember,
  storeOnboardingData,
} from '../../actions/onboardingActions';
import { Sundhedplus } from '../../config';

const MembershipConditions = ({ setNextStep, step, previousStep }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [consent, setConsent] = useState(false);
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [goBackButtonLoading, setGoBackButtonLoading] = useState(false);

  const { firstName, lastName, email, phoneNumber, linkTag, companyId } =
    savedData || {};

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingMembershipConditionsTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/medlemsbetingelser',
        stepTitle: 'onboarding - medlemsbetingelser',
        event: 'onboarding',
      });
    }
  }, []);

  useEffect(() => {
    if (consent) {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSubmit();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [consent]);

  const handleSubmit = () => {
    setButtonLoading(true);
    if (consent) {
      // Debouncing ensures that the event handler is called only once within a specified time period
      const debouncedAction = debounce(() => {
        dispatch(
          createMember(
            firstName,
            lastName,
            email,
            phoneNumber,
            linkTag,
            companyId
          )
        )
          .then((response) => {
            const respData = response?.data;
            if (respData) {
              const { memberStatus, cpr, leadUuid, uuid, activated } = respData;
              dispatch(
                storeOnboardingData({
                  ...savedData,
                  consent,
                  memberStatus,
                  emailIsConfirmed: activated,
                  ...(cpr && { cpr }),
                  ...(leadUuid && { leadUuid }),
                  ...(uuid && { uuid }),
                })
              );
              setNextStep(step + 1);
            }
          })
          .finally(() => {
            setButtonLoading(false);
          });
      }, 300);

      debouncedAction();
    } else {
      setButtonLoading(false);
    }
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {strings.onboardingMembershipConditionsTitle}
      </div>
      <div className='content'>
        {readMoreOpen && (
          <div className='terms-wrapper'>
            <Scrollbars style={{ height: 340 }}>
              <TermsConditions
                link={Sundhedplus.termsAndConditionsOnboarding}
              />
            </Scrollbars>
          </div>
        )}

        {!readMoreOpen && (
          <div className='terms-conditions-summary'>
            <article>
              <p>{strings.onboardingMembershipConditionsP1}</p>
            </article>
            <article>
              <p>{strings.onboardingMembershipConditionsP2}</p>
            </article>
            <article>
              <p className='read-more' onClick={() => setReadMoreOpen(true)}>
                {strings.onboardingMembershipConditionsReadMore}
              </p>
            </article>
          </div>
        )}

        <article>
          <Checkbox
            content={strings.onboardingMembershipConditionsCheckbox}
            checked={consent}
            handleClick={() => setConsent(!consent)}
          />
        </article>
      </div>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-cancel goBackButton'
          disabled={goBackButtonLoading}
          onClick={() => {
            setGoBackButtonLoading(true);
            previousStep();
          }}
        >
          {goBackButtonLoading ? <ButtonLoader /> : strings.onboardingGoBack}
        </Button>
        <Button
          className='btn btn-blue btn-success'
          disabled={!consent || buttonLoading}
          onClick={() => handleSubmit()}
        >
          {buttonLoading ? <ButtonLoader /> : strings.onboardingContinue}
        </Button>
      </div>
    </CardBody>
  );
};

export default MembershipConditions;
