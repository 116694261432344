import React from 'react';
import PropTypes from 'prop-types';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { strings } from '../../constants/localization';

const ModalBookingOptions = ({ firstAction, secondAction, loadingWithSuccess, loadingWithoutSuccess, children }) => {
  return (
    <div className='status-modal'>
      {children}
      <div className='modalOptionsContent'>
        <button
          className='btn btn-blue opions-btn'
          type='button'
          onClick={firstAction}
          disabled={loadingWithSuccess || loadingWithoutSuccess}
        >
          {!loadingWithSuccess ? (
            <>
              <span>{strings.bookingModalSolved_first_option}</span>
            </>
          ) : (
            <ButtonLoader />
          )}
        </button>
        <button
          className='btn btn-blue opions-btn'
          type='button'
          onClick={secondAction}
          disabled={loadingWithSuccess || loadingWithoutSuccess}
        >
          {!loadingWithoutSuccess ? (
            <>
              <span>{strings.bookingModalSolved_second_option}</span>
            </>
          ) : (
            <ButtonLoader />
          )}
        </button>
      </div>
    </div>
  );
};

ModalBookingOptions.propTypes = {
  firstAction: PropTypes.func,
  secondAction: PropTypes.func,
  loadingWithSuccess: PropTypes.bool,
  loadingWithoutSuccess: PropTypes.bool,
  children: PropTypes.node,
};

export default ModalBookingOptions;
