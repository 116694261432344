import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';
import Step5 from './Step5/Step5';
import ErrorPage from '../common/ErrorPage';
import SomethingWentWrongError from '../common/SomethingWentWrongError';
import './style.scss';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { ROUTE_LOGIN } from '../../constants/routes';
import LoadingMessage from '../statusMessages/LoadingMessage';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { brandConfig } from '../../config';
import {
  changePassword,
  getPendingUser,
  activateUser,
} from '../../actions/userActions';

function UserRegistrationFlow() {
  const [step, setStep] = useState(1);
  // eslint-disable-next-line
  const [maxSteps, setMaxSteps] = useState(4);
  // eslint-disable-next-line
  const [email, setEmail] = useState('test@gmail.com');
  const [firstName, setFirstName] = useState('John');
  const [lastName, setLastName] = useState('Jonhson');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDanishNumer, setIsDanishNumer] = useState(true);
  // const [phoneNumberError, setPhoneNumberError] = useState('');
  const [code, setCode] = useState('');
  const [next, setNext] = useState(false);
  const [uuidValue, setUuidvalue] = useState('');
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [userRegistrationError, setUserRegistrationError] = useState('');

  const brandEnvVariable = process.env.REACT_APP_BRAND;
  const dispatch = useDispatch();
  const serverErrorDetails = useSelector(
    (state) => state.ui?.serverErrorDetails
  );

  useEffect(() => {
    document.body.classList.add('public-content');
    const link = window.location.href?.replace(/&amp;/g, '&');
    const url = new URL(link);
    const args = new URLSearchParams(url.search);
    const uuid = args.get('u');
    const username = args.get('e');
    setUserName(username);
    setUuidvalue(uuid);
    dispatch(getPendingUser(uuid, username))
      .then((response) => {
        if (response.payload.status === 200) {
          setEmail(response.payload.data.username);
          setFirstName(response.payload.data.firstName);
          setLastName(response.payload.data.lastName);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.cause === 'Bad Request: the user is not found') {
          setUserRegistrationError('USERNOTFOUND');
        } else if (
          e.response.data.cause ===
          'Bad Request: the user has already been activated'
        ) {
          setUserRegistrationError('USEREXISTS');
        } else {
          setUserRegistrationError('USERINVITEEXPIRED');
        }
      });
    return () => {
      document.body.classList.remove('public-content');
    };
    // eslint-disable-next-line
  }, []);

  const resendInvitation = () => {
    setLoading(true);
    dispatch(changePassword(userName))
      .then((response) => {
        if (response.payload.status === 200) {
          setLoading(false);
        }
      })
      .catch(() => {
        setStep(6);
      });
  };

  const save = () => {
    dispatch(
      activateUser({
        uuid: uuidValue,
        password: code,
        username: userName,
        firstName,
        lastName,
        phoneNumber: phoneNumber || undefined,
      })
    )
      .then((response) => {
        if (response.payload.status === 200) {
          setStep(step + 1);
        }
      })
      .catch(() => {
        setStep(6);
      });
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            email={email}
            code={code}
            setCode={setCode}
            next={next}
            disableButton={setDisabledButton}
          />
        );
      case 2:
        return (
          <Step2
            firstName={firstName}
            lastName={lastName}
            phoneNumber={phoneNumber}
            isDanishNumer={isDanishNumer}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPhoneNumber={setPhoneNumber}
            setIsDanishNumber={setIsDanishNumer}
          />
        );
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      case 6:
        return (
          <SomethingWentWrongError serverErrorDetails={serverErrorDetails} />
        );
      default:
        return null;
    }
  };

  const nextStep = () => {
    if (step === 1) {
      if (
        code.replace(/[^A-Z]/g, '').length > 0 &&
        code.length >= 8 &&
        code.replace(/[^a-z]/g, '').length > 0 &&
        code.replace(/[^0-9]/g, '').length > 0
      ) {
        setStep(step + 1);
        setNext(false);
      } else {
        setNext(true);
      }
    } else if (step === 2) {
      if (isDanishNumer) {
        setStep(step + 1);
      }
    } else if (step === 3) {
      save();
    } else if (step === 5) {
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };

  const cancel = () => {
    setStep(5);
  };

  const back = () => {
    setStep(step - 1);
  };

  const renderErrorContent = () => {
    switch (userRegistrationError) {
      case 'USEREXISTS':
        return (
          <ErrorPage>
            <h1>{strings.userAlredyActivated1}</h1>
            <p>{strings.userAlredyActivated2}</p>
            <div className='button_area'>
              <a href={ROUTE_LOGIN}>
                <Button color='blue' onClick={() => {}}>
                  {strings.userAlredyActivatedButton}
                </Button>
              </a>
            </div>
          </ErrorPage>
        );
      case 'USERNOTFOUND':
        return (
          <ErrorPage>
            <h1>{strings.userNotFound1}</h1>
            <p>{strings.userNotFound2}</p>
          </ErrorPage>
        );
      case 'USERINVITEEXPIRED':
        return (
          <ErrorPage>
            <h1>{strings.userLinkExpired1}</h1>
            <p>{strings.userLinkExpired2}</p>
            <p>{strings.userLinkExpired3}</p>
            <div className='button_area'>
              <Button color='blue' onClick={resendInvitation}>
                {loading && <ButtonLoader />}
                {!loading && strings.userLinkExpiredButton}
              </Button>
            </div>
          </ErrorPage>
        );
      default:
        return <div />;
    }
  };

  const getStepControls = () => {
    switch (step) {
      case 1:
        return (
          <div className='actions-section single'>
            <Button color='blue' onClick={nextStep} disabled={disabledButton}>
              {strings.userRegistrationContinue}
            </Button>
          </div>
        );
      case 2:
        return (
          <div className='actions-section'>
            <Button color='light' onClick={back}>
              {strings.userRegistrationBack}
            </Button>
            <Button
              color='blue'
              onClick={nextStep}
              disabled={phoneNumber !== '' && !isDanishNumer}
            >
              {strings.continueStep}
            </Button>
          </div>
        );
      case 3:
        return (
          <div className='actions-section'>
            <Button color='light' onClick={cancel}>
              {strings.declineLoan}
            </Button>
            <Button color='blue' onClick={nextStep}>
              {strings.acceptStep}
            </Button>
          </div>
        );
      case 4:
        return (
          <div className='longButtonzone'>
            <a href={ROUTE_LOGIN}>
              <Button color='blue' onClick={() => {}}>
                {strings.userRegistrationLogIn}
              </Button>
            </a>
          </div>
        );
      case 5:
        return (
          <div className='longButtonzone'>
            <Button color='blue' onClick={nextStep}>
              {strings.userRegistrationBack}
            </Button>
          </div>
        );
      case 6:
        return;
      default:
        return (
          <div className='actions-section'>
            <Button color='blue' onClick={nextStep}>
              {strings.userRegistrationContinue}
            </Button>
          </div>
        );
    }
  };

  return (
    <div className='UserRegistrationFlow'>
      {loading && <LoadingMessage />}
      {!loading && userRegistrationError === '' && (
        <>
          <div className='top-header'>
            {brandEnvVariable === 'Denti' && (
              <div className='d-logo logo-green'>
                <i className='di di-denti' />
              </div>
            )}
            {brandEnvVariable === 'Sundhedplus' && (
              <img src={sundhedLogo} alt={`${brandConfig.logoAltText}`} />
            )}
          </div>
          <div className='wrapper'>
            <div className='registration-steps'>
              <div className='registration-steps_content'>
                {getStepContent()}
              </div>
              <div className='finalState'>
                {step === 5 && (
                  <div className='iconZone'>
                    <i className='lnil lnil-cross-circle red' />
                  </div>
                )}
                {step === 4 && (
                  <div className='iconZone'>
                    <i className='lnil lnil-checkmark-circle green' />
                  </div>
                )}
              </div>
              <div className='controls'>
                {getStepControls()}
                <div className='registration-steps_step-bullets'>
                  {[...Array(maxSteps)].map((StepComponent, i) => (
                    <span
                      key={`stepBullet-${i}`}
                      className={`step-bullet ${
                        i <= step - 1 ? 'checked' : ''
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {userRegistrationError !== '' && renderErrorContent()}
    </div>
  );
}
export default UserRegistrationFlow;
