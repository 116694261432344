import React from 'react';
import { connect } from 'react-redux';
import { isAdmin } from '../../../../constants/utils';

const IconStatus = ({ lead, user }) => {
  const {
    brand,
    status: creditFacilityStatus,
    remainingTime,
  } = lead.creditFacilityInformation || {};
  const dentiUser = brand === 'Denti';

  let logoColor = 'grey';

  const expired =
    (remainingTime && remainingTime?.totalDays < 0) ||
    lead.creditFacilityInformation == null;
  if (isAdmin(user)) {
    switch (creditFacilityStatus) {
      case 'ACTIVE':
      case 'OFFER':
        if (!expired) logoColor = 'green';
        break;
      case 'SUSPENDED':
        logoColor = 'yellow';
        break;
      case 'LATE_PAYMENT':
        logoColor = 'red';
        break;
      default:
        break;
    }
  } else if (
    ['ACTIVE', 'OFFER'].indexOf(creditFacilityStatus) > -1 &&
    !expired
  ) {
    logoColor = 'green';
  }

  return (
    <div className='logo'>
      <div className={`d-logo logo-${logoColor}`}>
        {dentiUser ? (
          <i className='di di-denti' />
        ) : (
          <i className='di di-sundhedplus' />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

export default connect(mapStateToProps)(IconStatus);
