import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { strings } from '../../../constants/localization';
import { useSelector } from 'react-redux';

export default function AmountSlider({
  setAmount,
  minAmount,
  maxAmount,
  defaultAmount,
  type,
  suggestedAmount,
}) {
  const [value, setValue] = useState(
    defaultAmount || defaultAmount === 0 ? defaultAmount : maxAmount / 2
  );
  const savedData = useSelector((state) => state.onboarding?.data);
  const minAmountCurrency = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    maximumSignificantDigits: 5,
  }).format(minAmount);
  const maxAmountCurrency = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    maximumSignificantDigits: 5,
  }).format(maxAmount);

  useEffect(() => {
    setAmount(value);
  }, [value, setAmount]);

  const handleSliderChange = (newValue) => {
    setValue(newValue);
  };

  const styleLbelHolder = {
    textAlign: 'center',
    fontSize: '36px',
    fontWeight: '300',
    color: '#be323c',
    marginBottom: '30px',
  };

  useEffect(() => {
    if (type === 'householdExpenses' && savedData?.householdExpenses) {
      setValue(savedData?.householdExpenses);
    } else if (
      type === 'householdExpenses' &&
      suggestedAmount > 0 &&
      !savedData?.householdExpenses
    ) {
      setValue(suggestedAmount);
    }

    if (type === 'monthlyDisposable') {
      // if the user has already saved the monthlyDisposable value, then set it as default
      // otherwise set the default value to 2000
      if (savedData?.monthlyDisposable) {
        setValue(savedData.monthlyDisposable);
      } else {
        setValue(2000);
      }
    }
  }, [savedData, type, suggestedAmount]);

  return (
    <div>
      <p style={styleLbelHolder}>
        {strings.formatString(
          strings.onboardingSliderAmount,
          new Intl.NumberFormat('da-DK', {
            style: 'currency',
            currency: 'DKK',
            maximumSignificantDigits: 5,
          }).format(value)
        )}
      </p>
      <Slider
        min={minAmount}
        max={maxAmount}
        step={100}
        value={value}
        onChange={handleSliderChange}
        trackStyle={{ backgroundColor: '#998064', height: 13, borderRadius: 0 }}
        handleStyle={{
          background: 'rgb(255, 255, 255) none repeat scroll 0% 0% !important',
          border: '1px solid #fff',
          height: 27,
          width: 27,
          fontSize: 24,
          boxShadow: '0px 0px 5px #888',
          outline: '0px none !important',
          marginTop: -9,
          borderWidth: 2,
        }}
        railStyle={{ backgroundColor: '#f8f8f8', height: 13 }}
      />

      <div className='d-flex justify-content-between mt-3'>
        <span>{minAmountCurrency}</span>
        <span>{maxAmountCurrency}</span>
      </div>
    </div>
  );
}
