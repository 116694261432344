import React from 'react';
import { Button } from 'reactstrap';
import './style.scss';
import { strings } from '../../../../../../constants/localization';
import * as animationData from '../../../../../../resources/animations/add-patient_accept_lottie.json';
import LottieControl from '../../../../../add-patient/LottieControl';

const ReasignClinic = ({ clinicName = '', patientName = '', close }) => (
  <div className='grant_access_fail_Component reasign'>
    <div className='grant_access_fail_Component-header'>
      <LottieControl animationData={animationData} height={312} width={340} />
      <div className='title'>{strings.accessReasignClinicHeadline}</div>
    </div>
    <div className='grant_access_fail_Component-content'>
      <div className='firstText'>
        {strings.formatString(
          strings.accessReasignClinicText,
          patientName,
          clinicName
        )}
      </div>
      <div className='secondText'>
        {strings.formatString(
          strings.accessReasignClinicNote,
          <b>{strings.accessReasignClinicNoteBold}</b>
        )}
      </div>
    </div>
    <div className='grant_access_Component-button_area'>
      <Button color='blue' onClick={close}>
        {strings.accessReasignClinicButton}
      </Button>
    </div>
  </div>
);

export default ReasignClinic;
