import React, { useState, useEffect } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import { Sundhedplus } from '../../config';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { ReactComponent as CelebrateImage } from '../../resources/images/celebrate_membership.svg';

const MembershipCreated = ({ setNextStep, step }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);

  // add handler to go to next step on Enter key pressed
  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingMembershipCreatedTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/medlemskab-oprettet',
        stepTitle: 'onboarding - medlemskab oprettet',
        event: 'onboarding',
      });
    }
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handleSubmit = () => {
    setButtonLoading(true);
    setNextStep(step + 1);
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {strings.onboardingMembershipCreatedTitle}
      </div>
      <section>
        <CelebrateImage className='celebrate-image' />
      </section>
      <section className='content mt-4'>
        <p>{strings.onboardingMembershipCreatedP1}</p>
        <p>{strings.onboardingMembershipCreatedP2}</p>
      </section>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-success full-width'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <ButtonLoader />
          ) : (
            strings.onboardingMembershipCreatedBtn
          )}
        </Button>
      </div>
      <a
        href={Sundhedplus.presentationSite}
        target='_blank'
        rel='noopener noreferrer'
        className='text-center'
      >
        {strings.onboardingMembershipCreatedLink}
      </a>
    </CardBody>
  );
};

export default MembershipCreated;
