import React, { useRef, useState } from 'react';
import BudgetListItem from './BudgetListItem';
import { formatDanishWithoutFractionDigits } from '../../../constants/utils';

const BudgetCategory = ({
  headline,
  totalAmount,
  budgetItems,
  budgetMap,
  setBudgetMap,
  setEnableButton,
}) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='budget-category'>
      <div
        className='budget-category-headline'
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className={`arrow ${isOpen ? 'active' : ''}`}></span>
        <span className='name'>{headline}</span>
        <span className='total'>
          {formatDanishWithoutFractionDigits(totalAmount)}
        </span>
      </div>
      <div
        className='budget-category-list'
        ref={contentRef}
        style={
          isOpen
            ? {
                maxHeight: (contentRef?.current?.scrollHeight || 0) + 20,
              }
            : { maxHeight: '0px' }
        }
      >
        {budgetItems.map((item, index) => (
          <BudgetListItem
            key={index}
            budgetItemData={item}
            budgetMap={budgetMap}
            setBudgetMap={setBudgetMap}
            setEnableButton={setEnableButton}
          />
        ))}
      </div>
    </div>
  );
};

export default BudgetCategory;
