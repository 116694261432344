import React, { Component } from 'react';
// import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Pagination from 'react-js-pagination';
import { Field, reduxForm } from 'redux-form';
import { ListGroup, Form, FormGroup } from 'reactstrap';
import {
  COMPANY_CHAINS_LOADING,
  COMPANY_CHAINS_EMPTY,
  COMPANY_CHAINS_SUCCESS,
  COMPANY_CHAINS_FAILURE,
} from '../../../constants';
import ChainListItem from './ChainListItem';
import CreateNewCompanyChain from './CreateNewCompanyChain';
import './ChainList.scss';
import {
  getCompanyChains,
  searchEmitted,
  filterEmitted,
  setActivePage,
  setCurrentChain,
} from '../../../actions/companyChainActions';

import DentiModal from '../../common/DentiModal';
import { strings } from '../../../constants/localization';
import { isAuthenticated, required } from '../../../constants/utils';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../../constants/styleScrollbar';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import EmptyMessage from '../../statusMessages/EmptyMessage';
import ServerModalError from '../../common/ServerModalError';
import ChainFilter from './ChainFilter';
import { ROUTE_COMPANY_CHAINS } from '../../../constants/routes';
// testing

class ChainList extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();

    this.state = {
      activePage: 1,
      newChain: false,
      searchedInput:
        this.props.search?.flag === true ? this.props.search.searchInput : '',
      filterPayload: null,
      scrollTimeout: null,
      hasClearedSearchInput: false,
    };

    this.chainListRender = this.chainListRender.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.updateCallback = this.updateCallback.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
  }

  static renderField(field) {
    const { input, refName, placeholder } = field;
    return (
      <input
        className='search-query form-control'
        id='searchQuery'
        ref={refName}
        type={field.type}
        {...input}
        placeholder={placeholder}
      />
    );
  }

  componentDidMount() {
    if (isAuthenticated()) {
      //if there is a query in the location url, display company based on that
      if (this.props.location.search !== '') {
        const searchParams = new URLSearchParams(location.search);
        const searchedKeyword = searchParams.get('q');
        const values = {
          flag: true,
          searchedInput: searchedKeyword,
        };
        this.handleSearchSubmit(values);
        this.props.change('searchedInput', values.searchedInput);
      } else if (!this.props.displayChain) {
        this.props.getCompanyChains(this.props.activePage - 1);
      } else if (!this.state.hasClearedSearchInput) {
        const conditionalSearchTerm =
          this.props.displayChain.ref.cvr ||
          this.props.displayChain.ref.cvrNumber
            ? this.props.displayChain.ref.cvr ||
              this.props.displayChain.ref.cvrNumber
            : this.props.displayChain.ref.chainCompanyName ||
              this.props.displayChain.ref.companyChainName;
        this.props.setCurrentChain(null);
        this.props
          .getCompanyChains(this.props.activePage - 1, conditionalSearchTerm)
          .then(() => {
            this.props.searchEmitted({
              flag: true,
              searchInput: conditionalSearchTerm,
            });
            this.props.initialize({ searchedInput: conditionalSearchTerm });
          });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search?.searchInput !== this.props.search?.searchInput) {
      //search term has updated
      if (this.props.search?.redirectSearch) {
        this.props.initialize({
          searchedInput: this.props.search?.searchInput,
        });
      }
    }
  }

  handlePageChange(pageNumber) {
    const { search, activePage } = this.props;
    if (pageNumber !== activePage) {
      this.props.setActivePage(pageNumber);
      this.setState({ activePage: pageNumber }, () => {
        this.props.getCompanyChains(
          this.state.activePage - 1,
          search && search.flag === true ? this.state.searchedInput : '',
          null,
          () => {
            this.resetScrollPosition();
          },
          this.state.filterPayload
        );
      });
    }
  }

  filterSubmit(filterPayload, deleteFlag) {
    this.props.setActivePage(1);
    this.props.filterEmitted(filterPayload, deleteFlag);
    this.props.getCompanyChains(
      0,
      this.props.search?.flag === true ? this.props.search.searchInput : '',
      null,
      () => {
        this.resetScrollPosition();
      },
      filterPayload
    );
    this.setState({ filterPayload });
  }

  resetView() {
    this.props.activePage(1);
    this.props.getCompaniChains();
  }

  handleSearchSubmit(values) {
    if (values.searchedInput !== undefined) {
      const keyword = values.searchedInput.trim();
      if (keyword !== '') {
        this.props.searchEmitted({ flag: true, searchInput: keyword });
        this.input.blur();
        this.props.setActivePage(1);
        this.props.getCompanyChains(
          0,
          keyword,
          null,
          () => {
            this.resetScrollPosition();
          },
          Object.keys(this.props.filters).length > 0 ? this.props.filters : null
        );

        this.setState({ searchedInput: keyword });
      }
    }
  }
  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }
  componentWillUnmount() {
    if (isAuthenticated()) {
      this.props.searchEmitted({ flag: false, searchInput: '' }); // clear search
      this.props.filterEmitted({}); // clear filter
      //this.props.setActivePage(1); // clear search page
    }
    clearTimeout(this.state.scrollTimeout);
  }

  clearSearch() {
    this.resetScrollPosition();

    this.props.reset();
    this.props.searchEmitted({ flag: false, searchInput: '' });
    this.setState({
      searchedInput: '',
      activePage: 1,
      hasClearedSearchInput: true,
    });
    this.props.initialize({ searchedInput: '' });
    this.props.getCompanyChains(
      this.props.statusActive,
      null,
      '',
      () => {
        this.props.setCurrentChain();
        this.resetScrollPosition();
      },
      Object.keys(this.props.filters).length > 0 ? this.props.filters : null
    );
    if (this.props.location.search !== '') {
      this.props.history.replace(ROUTE_COMPANY_CHAINS);
    }
  }

  updateCallback(id) {
    this.props.getCompanyChains(
      this.state.activePage - 1,
      this.props.searchValue,
      id
    );
  }

  onChangeState(state) {
    this.setState({ newChain: state });
  }

  chainListRender() {
    return (
      <div className='entities-list-group'>
        <Scrollbars
          ref={this.scrollRef}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...thumbVerticalStyle,
              }}
            />
          )}
        >
          <div className='scrollbar-wrapper'>
            <ListGroup className='company-list-group'>
              {this.props.chainList.map((chain) => (
                <ChainListItem
                  key={chain.id}
                  chain={chain}
                  active={
                    this.props.activeChain
                      ? chain.id === this.props.activeChain.id
                      : false
                  }
                />
              ))}
            </ListGroup>
            {this.props.pageOptions.totalPages > 1 && (
              <Pagination
                activePage={this.props.pageOptions.pageable.page + 1}
                itemClass='page-item'
                linkClass='page-link'
                itemsCountPerPage={20}
                totalItemsCount={this.props.pageOptions.total}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            )}
          </div>
        </Scrollbars>
      </div>
    );
  }

  render() {
    let renderSituation;
    const { search, loadingStatus, handleSubmit } = this.props;

    switch (loadingStatus) {
      case COMPANY_CHAINS_LOADING:
        renderSituation = <LoadingMessage />;
        break;
      case COMPANY_CHAINS_SUCCESS:
        renderSituation = this.chainListRender();
        break;
      case COMPANY_CHAINS_EMPTY:
        renderSituation = <EmptyMessage message={strings.searchEmpty} />;
        break;
      case COMPANY_CHAINS_FAILURE:
        renderSituation = <ServerModalError />;
        break;
      default:
        renderSituation = <EmptyMessage message={strings.searchEmpty} />;
    }

    return (
      <div className='chain-list'>
        <div className='header'>
          <button
            type='button'
            className='btn btn-white menu-toggler'
            onClick={this.props.toggleMenu}
          >
            <div className='menu-toggler-icon'>
              <span />
              <span />
              <span />
            </div>
          </button>
          <h3 className='title'>{strings.companyChainsMenuTitle}</h3>
          <Form
            className='search-form'
            onSubmit={handleSubmit(this.handleSearchSubmit)}
            autoComplete='off'
          >
            {search !== undefined && search.flag === true ? (
              <button
                type='button'
                className='btn-clear-search'
                onClick={this.clearSearch}
              >
                <span className='clear-search di di-close' />
              </button>
            ) : (
              <button type='submit' className='btn-search'>
                <span className='lnir lnir-search-alt' />
              </button>
            )}
            <FormGroup className='mb-0'>
              <Field
                // eslint-disable-next-line
                refName={(ref) => (this.input = ref)}
                name='searchedInput'
                onChange={this.handleSearchChange}
                component={ChainList.renderField}
                validate={[required]}
                placeholder={strings.searchForchain}
              />
            </FormGroup>
          </Form>
        </div>

        <ChainFilter
          filterSubmit={this.filterSubmit}
          searchStatus={loadingStatus}
          search={search}
          resetView={this.resetView}
          onChangeState={this.onChangeState}
          sendProps={this.props}
        />

        {/* <div className='filterArea'>
          <Button color='blue' onClick={() => { this.setState({ newChain: true }); }}>
            <i className='lnir lnir-plus' />
            {strings.new}
          </Button>
        </div> */}
        {renderSituation}
        {this.state.newChain && (
          <DentiModal
            key='export-modal'
            close={() => {
              this.setState({ newChain: false });
            }}
            modalClass='grant-access-Modal'
            modalClassName='center_modal'
          >
            <CreateNewCompanyChain updateCallback={this.updateCallback} />
          </DentiModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chainList:
      state.companyChain && state.companyChain.companyChainList
        ? state.companyChain.companyChainList.content
        : [],
    activeChain: state.companyChain.activeChain,
    pageOptions: state.companyChain.companyChainList,
    statuses: state.status.companyChainStatuses,
    statusActive: state.status.companyChainStatusActive,
    search: state.companyChain.searchActive,
    searchValue:
      state.form && state.form.searchForm && state.form.searchForm.values
        ? state.form.searchForm.values.searchedInput
        : '',
    loadingStatus: state.companyChain.loading,
    chainsFilteredBySearch: state.companyChain.chainsFilteredBySearch,
    activePage: state.companyChain.activePage,
    filters: state.companyChain.filters,
  };
}

ChainList.propTypes = {
  chainList: PropTypes.array,
  activeChain: PropTypes.object,
  pageOptions: PropTypes.object,
  getCompanyChains: PropTypes.func,
  searchEmitted: PropTypes.func,
  statuses: PropTypes.object,
  search: PropTypes.object,
  statusActive: PropTypes.string,
  searchValue: PropTypes.string,
  displayChain: PropTypes.object,
  loadingStatus: PropTypes.string,
  filterEmitted: PropTypes.func,
  setActivePage: PropTypes.func,
  activePage: PropTypes.number,
  setCurrentChain: PropTypes.func,
};

export default reduxForm({
  form: 'searchForm',
})(
  connect(mapStateToProps, {
    getCompanyChains,
    searchEmitted,
    filterEmitted,
    setActivePage,
    setCurrentChain,
  })(ChainList)
);
