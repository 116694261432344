import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../constants/localization';
import ButtonLoader from './ButtonLoader/ButtonLoader';

class ConfirmActionModal extends Component {
  constructor(props) {
    super(props);

    this.confirmAction = this.props.confirmAction;
    this.cancelAction = this.props.cancelAction;
  }

  render() {
    return (
      <div className='status-modal'>
        {this.props.children}
        <div className='bottom-section action-btn cf'>
          <button
            className='submit-btn btn btn-blue center-block'
            type='button'
            onClick={this.confirmAction}
            disabled={this.props.loading || this.props?.disabledConfirmButton}
          >
            {!this.props.loading && (
              <>
                <i className='di di-accept' />
                <span>
                  {this.props.confirmBtnText
                    ? this.props.confirmBtnText
                    : strings.confirm}
                </span>
              </>
            )}
            {this.props.loading && <ButtonLoader />}
          </button>
          <button
            className='cancel-btn btn btn-light center-block'
            type='button'
            onClick={this.cancelAction}
            disabled={this.props.loading}
          >
            <i className='di di-cancel' />
            <span>
              {this.props.cancelBtnText
                ? this.props.cancelBtnText
                : strings.cancel}
            </span>
          </button>
        </div>
      </div>
    );
  }
}

ConfirmActionModal.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConfirmActionModal;
