import React, { useState, useEffect } from 'react';
import { Button, CardBody, List } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { strings } from '../../constants/localization';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import {
  startOnboarding,
  storeOnboardingData,
  storeBudgetTransactionId,
} from '../../actions/onboardingActions';
import { generateRandomUuid } from '../../constants/utils';

const ConditionsForCredit = ({ setNextStep, step }) => {
  const dispatch = useDispatch();
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const savedData = useSelector((state) => state.onboarding?.data);
  const { companyId, linkTag, memberStatus } = savedData || {};
  const existingClient = memberStatus === 'EXISTING_CLIENT';

  const [buttonLoading, setButtonLoading] = useState(false);

  // add handler to go to next step on Enter key pressed
  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingConditionsCreditTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/minimumskrav-for-kredit',
        stepTitle: 'onboarding - betingelser',
        event: 'onboarding',
      });
    }
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const startOnboardingForNonExistingClient = () => {
    let hadError = false;
    dispatch(startOnboarding(null, companyId, linkTag))
      .then((result) => {
        if (result.payload?.status === 200) {
          const creditApplicationUuid = result.payload?.data || '';
          dispatch(
            storeOnboardingData({
              ...savedData,
              creditApplicationUuid: creditApplicationUuid,
            })
          );
        } else {
          hadError = true;
        }
      })
      .then(() => {
        if (!hadError) {
          // once started the onboarding, we need to generate and store a new budget transaction id
          try {
            dispatch(storeBudgetTransactionId(generateRandomUuid()));
          } catch (error) {
            console.error('Error generating the transaction uuid', error);
            hadError = true;
          }
        }
      })
      .catch((error) => {
        console.error('Error starting onboarding:', error);
        if (error.response?.status === 400) {
          hadError = true;
        }
      })
      .finally(() => {
        console.log('finally');
        setButtonLoading(false);
        if (!hadError) {
          setNextStep(step + 1);
        } else {
          setNextStep(0);
        }
      });
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    // only for existing clients do this call here
    if (existingClient) {
      startOnboardingForNonExistingClient();
    } else {
      setButtonLoading(false);
      setNextStep(step + 1);
    }
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {strings.onboardingConditionsCreditTitle}
      </div>
      <div className='step-subtitle bold mt-4 mb-4'>
        {strings.onboardingConditionsCreditText}
      </div>
      <List className='mt-4'>
        <li>{strings.onboardingConditionsCreditItem1}</li>
        <li>{strings.onboardingConditionsCreditItem2}</li>
        <li>{strings.onboardingConditionsCreditItem3}</li>
        <li>{strings.onboardingConditionsCreditItem4}</li>
        <li>{strings.onboardingConditionsCreditItem5}</li>
      </List>

      <div>
        <p>{strings.onboardingConditionsCreditNote}</p>
      </div>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-success full-width'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <ButtonLoader />
          ) : (
            strings.onboardingEditEstimatedBudgetBtn
          )}
        </Button>
      </div>
    </CardBody>
  );
};
export default ConditionsForCredit;
