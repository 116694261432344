import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import { currencyFormatDA } from '../../constants/utils';
import creditCardBg from '../../resources/images/creditcard_back.svg';
import visaCard from '../../resources/images/visa.svg';
import dankortCard from '../../resources/images/dankort.svg';
import masterCard from '../../resources/images/mastercard.svg';

const StepFive = (props) => {
  const cardType = () => {
    switch (props.acceptLoan.creditCardType) {
      case 'visa':
        return visaCard;
      case 'visa_dk':
        return dankortCard;
      case 'mastercard':
        return masterCard;
      default:
        return null;
    }
  };
  const cardTypeLogo = cardType();

  const acceptCurrentCard = (flag) => {
    props.nextStep({
      acceptCurrentCard: flag,
    });
  };
  return (
    <div className='step-content step-five'>
      <h1 className='step-title'>
        {strings.step5Title}
      </h1>
      <div className='creditcard-information'>
        {strings.formatString(strings.paymentDetails, <b>{currencyFormatDA(props.processData.monthlyPayment, true, true)}</b>)}
      </div>
      <div className='creditcard-details'>
        <p>{strings.creditCardDetails}</p>
        <div className='credit-card-bg'>
          <img src={creditCardBg} alt='credit card' className='credit-card-bg-image' />
          <div className='credit-card'>
            {
              cardTypeLogo !== null && <img src={cardTypeLogo} alt='credit card' className='credit-card-logo' />
            }
            <div className='credit-card-number'>{props.acceptLoan.creditCardNumber.replace(/(.{4})/g, '$1  ')}</div>
            <div className='credit-card-expires'>
              <span className='text'>{strings.creditCardExpires}</span>
              <span className='date'>{props.acceptLoan.creditCardExpiry}</span>
            </div>
            <div className='credit-card-name'>
              {`${props.acceptLoan.clientFirstName} ${props.acceptLoan.clientLastName}`}
            </div>
          </div>
        </div>
      </div>
      <div className='creditcard-action-buttons'>
        <Button color='blue' onClick={() => acceptCurrentCard(true)}>
          {strings.useCurrentCard}
        </Button>
        <Button color='blue' onClick={() => acceptCurrentCard(false)}>
          {strings.useDifferentCard}
        </Button>
      </div>
    </div>
  );
};
export default StepFive;
