import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { success, error as notifError } from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import './NewInvoice.scss';

import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import DropDown from '../../common/DropDown/DropDown';
import { getUpdatedPatientCreditFacility } from '../../../actions/leadActions';
import { getLogsCreditFacility } from '../../../actions/logActions';
import SwitchWithText from '../../common/Switch/SwitchWithText';
import {
  createInstallmentLoans,
  removeResponseInstallmentLoans,
} from '../../../actions/installmentLoanActions';
import {
  getCompaniesForCompanyChain,
  getSimpleSearchClinicPaginated,
} from '../../../actions/companyActions';
import { toggleServerModalError } from '../../../actions/uiElementsActions';
import { InvoiceSuccess } from './InvoiceSuccess';
import { InvoiceError } from './InvoiceError';
import addPatientForm from '../../../resources/images/add_credit.svg';
import { strings } from '../../../constants/localization';

import {
  isAdmin,
  isManager,
  isDentist,
  currencyFormatDA,
  numberWithDotAsThousandsSeparator,
} from '../../../constants/utils';
import SearchClinic from '../../common/SearchClinic/SearchClinic';

class NewInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'form', // error , success, form
      invoiceDescription: '',
      error: '',
      selectedOption: '', // full, partial
      invoiceAmount: '',
      invoicePayOutToClient: false,
      invoicePaymentReason: '',
      touched: false,
      clinicsListForCompanyChain: [],
      adminFilteredCompanies: [],
      clinicReferenceNumber: isDentist(this.props.user)
        ? this.props.activeCompany
        : '',
      clinic: {},
      autoCompleteClinicNameAndId: {},
      creditMessage: !props.invoiceFromFlow
        ? props?.lead?.creditFacilityInformation?.creditMessage
        : props?.leadsInformation?.creditFacilityInformation?.creditMessage,
      generalError: '',
      valueError: false,
      mitsMinimum: true,
      loading: false, // used for creating the invoice
    };
    this.changeValue = this.changeValue.bind(this);
    this.selectClinic = this.selectClinic.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.submit = this.submit.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.blurAmount = this.blurAmount.bind(this);
    this.handlePayoutToggle = this.handlePayoutToggle.bind(this);
    this.cloneInvoiceTimeout = null;
  }

  componentDidMount() {
    if (isManager(this.props.user) && this.props.user.companyChainId) {
      this.props
        .getCompaniesForCompanyChain(this.props.user.companyChainId)
        .then((result) => {
          if (result.payload.status === 200) {
            if (result.payload.data.companyChains[0].companies) {
              const companies = result.payload.data.companyChains[0].companies
                .filter((company) => company.status !== 'DEACTIVE')
                .map((item) => ({
                  front: item.referenceNumber,
                  top: item.companyName,
                  bottom: `${item.address}, ${item.zipCode} ${item.city}`,
                  right: item.status,
                  value: item.referenceNumber,
                }));
              if (companies.length > 1) {
                this.setState({ clinicsListForCompanyChain: companies });
              } else if (companies.length === 1) {
                this.setState({ clinicReferenceNumber: companies[0].value });
              }
            }
          }
        });
    }

    if (this.props.invoiceCloned) {
      const { amount, companyName, companyReferenceNumber, invoiceNumber } =
        this.props.invoiceCloned;
      const amountNotInInterval =
        (this.state.creditMessage !== 'acceptedLoan' && amount < 500) ||
        amount > this.props.maxLoan;
      this.cloneInvoiceTimeout = setTimeout(() => {
        this.setState({
          invoicePayOutToClient: invoiceNumber === 'Udbetaling til patient',
          invoiceDescription:
            invoiceNumber !== 'Udbetaling til patient' ? invoiceNumber : '',
          invoiceAmount: numberWithDotAsThousandsSeparator(amount) || '',
          mitsMinimum: !amountNotInInterval,
          clinic:
            companyReferenceNumber && companyName
              ? {
                  referenceNumber: companyReferenceNumber,
                  companyName,
                }
              : {},
          clinicReferenceNumber: companyReferenceNumber
            ? companyReferenceNumber
            : '',
        });
      }, 500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.cloneInvoiceTimeout);
  }

  changeValue(e, field) {
    const {
      selectedOption,
      clinic,
      invoicePayOutToClient,
      invoiceDescription,
      invoiceAmount,
      generalError,
    } = this.state;

    if (field === 'invoiceAmount') {
      let valueError = false;
      const value = e.target.value.replace(/[^\d.,-]/g, '');
      const valueWithoutPoints = value.replaceAll('.', '');
      if (parseInt(valueWithoutPoints, 10) > this.props.maxLoan) {
        valueError = true;
      }

      const nextRenderedValue =
        numberWithDotAsThousandsSeparator(valueWithoutPoints);
      const decimalIndex = nextRenderedValue.indexOf(',');
      let result;
      if (decimalIndex !== -1 && nextRenderedValue.length - decimalIndex > 3) {
        // Truncate decimal places to 2
        const truncatedValue = nextRenderedValue.slice(0, decimalIndex + 3);
        result = truncatedValue;
      } else {
        result = nextRenderedValue;
      }
      // check if the switch is not payout to client, then description is not needed, since we have the reason
      let missingDescription = !invoicePayOutToClient
        ? invoiceDescription
        : false;
      this.setState({
        invoiceAmount: result,
        valueError,
        generalError:
          selectedOption &&
          Object.keys(clinic).length > 0 &&
          missingDescription &&
          nextRenderedValue &&
          !valueError
            ? ''
            : generalError,
      });
    } else {
      this.setState({
        [field]: e.target.value,
        generalError:
          selectedOption &&
          Object.keys(clinic).length > 0 &&
          e.target.value &&
          invoiceAmount
            ? ''
            : generalError,
      });
    }
  }

  selectClinic(newClinic, isSelected = false) {
    const { selectedOption, invoiceDescription, invoiceAmount, generalError } =
      this.state;

    if (isSelected) {
      this.setState(
        {
          clinic: newClinic,
          generalError:
            selectedOption && newClinic && invoiceDescription && invoiceAmount
              ? ''
              : generalError,
        },
        () => {
          this.props.resetView();
        }
      );
    } else {
      this.setState(
        {
          clinic: newClinic,
          generalError:
            selectedOption && newClinic && invoiceDescription && invoiceAmount
              ? ''
              : generalError,
        },
        () => {
          if (Object.keys(newClinic).length === 0) {
            // this.sendData();
          }
        }
      );
    }

    // set the clinic reference number based on selection (SerachClinic or Dropdown)
    if (newClinic) {
      if (isAdmin(this.props.user)) {
        this.setState({ clinicReferenceNumber: newClinic.referenceNumber });
      }
      if (isManager(this.props.user)) {
        if (this.state.clinicsListForCompanyChain.length > 1) {
          //get the value from dropdown with companies
          this.setState({ clinicReferenceNumber: newClinic.value });
        }
      }
    }
  }

  selectOption(value) {
    const { clinic, invoiceDescription, invoiceAmount, generalError } =
      this.state;
    this.setState({
      selectedOption: value,
      generalError:
        value &&
        Object.keys(clinic).length > 0 &&
        invoiceDescription &&
        invoiceAmount
          ? ''
          : generalError,
    });
  }

  submit() {
    const value = parseFloat(
      this.state.invoiceAmount.replaceAll('.', '').replaceAll(',', '.'),
      10
    );
    const hasInvoicePayoutToClient =
      this.state.invoicePayOutToClient === true &&
      this.state.invoicePaymentReason !== '' &&
      this.state.invoiceDescription === '';
    const hasInvoicePayoutToClinic =
      this.state.invoicePayOutToClient === false &&
      this.state.invoicePaymentReason === '' &&
      this.state.invoiceDescription !== '';
    let mitsMinimum = true;
    if (this.state.creditMessage !== 'acceptedLoan' && value < 500) {
      mitsMinimum = false;
    }
    if (value === 0.5) {
      this.updateEror();
    } else if (
      value > 0 &&
      value <= this.props.maxLoan &&
      (hasInvoicePayoutToClient || hasInvoicePayoutToClinic) &&
      this.state.clinicReferenceNumber &&
      this.state.selectedOption &&
      mitsMinimum
    ) {
      this.setState({ loading: true }, () => {
        this.props.createInstallmentLoans({
          number: !this.state.invoicePayOutToClient
            ? this.state.invoiceDescription
            : 'Udbetaling til patient',
          id: this.props.creditFacilityInformation.id,
          amount: value,
          activeCompany: this.state.clinicReferenceNumber,
          invoiceType: this.state.selectedOption,
          payoutToClient: this.state.invoicePayOutToClient,
          payoutToClientReason: this.state.invoicePayOutToClient
            ? this.state.invoicePaymentReason
            : '',
        });
      });
    } else {
      this.setState({
        generalError: strings.newInvoiceGeneralError,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { loanResponse } = this.props;

    if (
      loanResponse &&
      loanResponse.createdInvoice &&
      loanResponse.createdInvoice.payload.errorText &&
      (!oldProps.loanResponse ||
        !oldProps.loanResponse.createdInvoice ||
        oldProps.loanResponse.createdInvoice.payload.errorText !==
          loanResponse.createdInvoice.payload.errorText) &&
      this.state.step !== 'error'
    ) {
      this.updateEror();
    } else if (
      loanResponse &&
      loanResponse.createdInvoice &&
      loanResponse.createdInvoice.payload.status === 200 &&
      (!oldProps.loanResponse ||
        !oldProps.loanResponse.createdInvoice ||
        oldProps.loanResponse.createdInvoice.payload.status !==
          loanResponse.createdInvoice.payload.status) &&
      this.state.step !== 'success'
    ) {
      this.updateSuccess();
    }
  }

  updateSuccess() {
    this.setState({ step: 'success', generalError: '', loading: false });
  }

  updateEror() {
    this.props.toggleServerModalError(false);
    this.setState({ step: 'error', loading: false });
  }

  handleClose() {
    this.props.getUpdatedPatientCreditFacility(
      this.props.lead?.creditFacilityInformation?.id
        ? this.props.lead?.creditFacilityInformation?.id
        : this.props?.creditFacilityInformation?.id
    );
    this.props.getLogsCreditFacility(
      this.props?.lead?.creditFacilityInformation?.number
        ? this.props?.lead?.creditFacilityInformation?.number
        : this.props?.creditFacilityInformation?.number
    );
    this.props.removeResponseInstallmentLoans();
    if (this.props.publicRegistrationInvoice) {
      this.props.toggleCreateNewInvoice();
    } else {
      this.props.handleClose();
    }
  }

  blurAmount() {
    const { invoiceAmount, creditMessage } = this.state;

    if (!invoiceAmount) {
      return;
    }
    const value = parseFloat(
      invoiceAmount.replaceAll('.', '').replaceAll(',', '.'),
      10
    );
    // if loan has credit message different than accpted, then the minimum is 500
    const mitsMinimum = creditMessage === 'acceptedLoan' ? true : value >= 500;

    let invoiceAmountResult;
    if (invoiceAmount) {
      const [integerPart, decimalPart] = invoiceAmount.split(',');
      const decimalLength = decimalPart ? decimalPart.length : 0;

      invoiceAmountResult = `${integerPart},${decimalPart || ''}`;
      invoiceAmountResult +=
        decimalLength === 0 ? '00 kr.' : decimalLength === 1 ? '0 kr.' : ' kr.';
    }

    this.setState({
      invoiceAmount: invoiceAmountResult,
      mitsMinimum,
    });
  }

  handlePayoutToggle() {
    this.setState({
      invoicePayOutToClient: !this.state.invoicePayOutToClient,
    });
  }

  render() {
    const {
      step,
      invoiceDescription,
      error,
      touched,
      invoiceAmount,
      invoicePayOutToClient,
      invoicePaymentReason,
      valueError,
      generalError,
      selectedOption,
    } = this.state;
    const { maxLoan } = this.props;

    let maxloanText = currencyFormatDA(parseFloat(maxLoan), true, true);
    return (
      <div className='new_Invoice_Component'>
        {step === 'form' && (
          <div>
            <div className='form_icon'>
              <img
                src={addPatientForm}
                alt='add patient error'
                className='form_icon_ico'
              />
            </div>

            <div className='form_title'>{strings.newInvoiceTitle}</div>

            <div className='form_control'>
              {/* Show this option only for admins */}
              {isAdmin(this.props.user) && (
                <label className='form_control_group'>
                  <div className='form_text toggle'>
                    <div>{strings.newInvoicePaymentOptionHeadline}</div>
                    <div>
                      <SwitchWithText
                        isOn={!this.state.invoicePayOutToClient}
                        handleToggle={this.handlePayoutToggle}
                        onText={strings.newInvoicePaymentOption1}
                        offText={strings.newInvoicePaymentOption2}
                      />
                    </div>
                  </div>
                </label>
              )}
              <label className='form_control_group'>
                <div className='form_text'>
                  <div>{strings.newInvoiceAmount}</div>
                  <div
                    className={`${
                      valueError ||
                      (generalError && !invoiceAmount) ||
                      !this.state.mitsMinimum
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    {/* show min amount only if there is no invoice or no accepted invoice */}
                    {this.state.creditMessage === 'acceptedLoan'
                      ? strings.formatString(
                          strings.newInvoiceAmountNoteWithoutMin,
                          maxloanText
                        )
                      : strings.formatString(
                          strings.newInvoiceAmountNote,
                          maxloanText,
                          '500,00 kr.'
                        )}
                  </div>
                </div>
                <input
                  className={`input form_input ${
                    valueError ||
                    (generalError && !invoiceAmount) ||
                    !this.state.mitsMinimum
                      ? 'input_danger'
                      : ''
                  }`}
                  onChange={(e) => this.changeValue(e, 'invoiceAmount')}
                  onFocus={() =>
                    this.setState({
                      invoiceAmount: invoiceAmount
                        .toString()
                        .replace(',00 kr.', '')
                        .replace(' kr.', ''), // remove kr.
                    })
                  }
                  onBlur={this.blurAmount}
                  placeholder='0,00 kr.'
                  maxLength={70}
                  type='text'
                  value={invoiceAmount !== undefined ? invoiceAmount : ''}
                />
              </label>
              {touched && error && <div className='text-danger'>{error}</div>}

              <CSSTransition
                in={invoicePayOutToClient === false}
                timeout={300}
                classNames='fade'
                unmountOnExit
              >
                <>
                  <label className='form_control_group'>
                    <div className='form_text'>
                      <div>{strings.newInvoiceReference}</div>
                      <div>{strings.newInvoiceReferenceNote}</div>
                    </div>

                    <input
                      className={`input form_input ${
                        generalError && !invoiceDescription
                          ? 'input_danger'
                          : ''
                      }`}
                      onChange={(e) =>
                        this.changeValue(e, 'invoiceDescription')
                      }
                      placeholder=''
                      maxLength={10}
                      type='text'
                      value={
                        invoiceDescription !== undefined
                          ? invoiceDescription
                          : ''
                      }
                    />
                  </label>
                  {touched && error && (
                    <div className='text-danger'>{error}</div>
                  )}
                </>
              </CSSTransition>

              <CSSTransition
                in={isAdmin(this.props.user) && invoicePayOutToClient === true}
                timeout={300}
                classNames='fade'
                unmountOnExit
              >
                <>
                  <label className='form_control_group'>
                    <div className='form_text'>
                      <div>{strings.newInvoicePaymentReasonHeadline}</div>
                      <div></div>
                    </div>
                    <textarea
                      className={`input form_input ${
                        generalError && !invoicePaymentReason
                          ? 'input_danger'
                          : ''
                      }`}
                      onChange={(e) =>
                        this.changeValue(e, 'invoicePaymentReason')
                      }
                      placeholder={strings.newInvoicePaymentReasonPlaceholder}
                      maxLength={200}
                      value={
                        invoicePaymentReason !== undefined
                          ? invoicePaymentReason
                          : ''
                      }
                    />
                  </label>
                  {touched && error && (
                    <div className='text-danger'>{error}</div>
                  )}
                </>
              </CSSTransition>

              {isAdmin(this.props.user) && (
                <label className='form_control_group'>
                  <div className='search-wrapper'>
                    <SearchClinic
                      selectClinic={this.selectClinic}
                      selected={Object.assign({}, this.state.clinic)}
                      autocomplete={this.state.autoCompleteClinicNameAndId}
                      fieldClass={`${
                        generalError &&
                        Object.keys(this.state.clinic).length === 0
                          ? 'input_danger'
                          : ''
                      }`}
                      format
                      showStatus
                    />
                  </div>
                </label>
              )}

              {isManager(this.props.user) &&
                this.state.clinicsListForCompanyChain.length > 1 && (
                  <label className='form_control_group'>
                    <div className='form_text'>
                      <div>{strings.clinic}</div>
                    </div>
                    <DropDown
                      options={this.state.clinicsListForCompanyChain}
                      currentValue={this.state.clinic}
                      placeholder={strings.chainSelectLocation}
                      setValue={(value) => {
                        this.selectClinic(value);
                      }}
                      hasError={
                        generalError &&
                        Object.keys(this.state.clinic).length === 0
                          ? true
                          : false
                      }
                      showStatus={true}
                    />
                  </label>
                )}

              <div className='radio'>
                <label>
                  <input
                    type='radio'
                    value='ADVANCE_PAYMENT'
                    className={`${
                      generalError && selectedOption === ''
                        ? 'radio_danger'
                        : ''
                    }`}
                    checked={selectedOption === 'ADVANCE_PAYMENT'}
                    onChange={() => this.selectOption('ADVANCE_PAYMENT')}
                  />
                  {strings.newInvoicePartialAmount}
                </label>
              </div>
              <div className='radio'>
                <label>
                  <input
                    type='radio'
                    className={`${
                      generalError && selectedOption === ''
                        ? 'radio_danger'
                        : ''
                    }`}
                    value='FINAL_INVOICE'
                    checked={selectedOption === 'FINAL_INVOICE'}
                    onChange={() => this.selectOption('FINAL_INVOICE')}
                  />
                  {strings.newInvoiceFullAmount}
                </label>
              </div>
            </div>
          </div>
        )}
        {step === 'success' && (
          <InvoiceSuccess
            invoiceAmount={this.state.invoiceAmount}
            brand={this.props.brand}
          />
        )}
        {step === 'error' && <InvoiceError />}
        <div className='buttonArea'>
          <Button
            color='blue'
            size='lg'
            onClick={
              step === 'form'
                ? !this.state.loading
                  ? this.submit
                  : () => {}
                : this.handleClose
            }
          >
            {step === 'form' && (
              <>
                {!this.state.loading && (
                  <>
                    <i className='di di-circle-plus' />
                    <span>{strings.newInvoiceRegister}</span>
                  </>
                )}
                {this.state.loading && <ButtonLoader />}
              </>
            )}
            {step !== 'form' && <span>{strings.close}</span>}
          </Button>
        </div>
        {generalError && (
          <div className='text-danger center eror_notification'>
            {strings.newInvoiceGeneralError}
          </div>
        )}
      </div>
    );
  }
}

NewInvoice.propTypes = {
  createInstallmentLoans: PropTypes.func,
  removeResponseInstallmentLoans: PropTypes.func,
  loanResponse: PropTypes.object,
  creditFacilityInformation: PropTypes.object,
  handleClose: PropTypes.func,
  activeCompany: PropTypes.string,
  company: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const { leadsInformation, invoiceFromFlow } = ownProps;

  const activeLead = !invoiceFromFlow
    ? state.lead.activeLead
    : leadsInformation.leadClientInformation;
  const creditFacilityInformation = !invoiceFromFlow
    ? state?.lead?.activeLead?.creditFacilityInformation
    : leadsInformation.creditFacilityInformation;

  return {
    statusActive: state.status.leadStatusActive,
    activePage: state.lead.activePage,
    activeLead: activeLead,
    search: state.lead.searchActive,
    user: state.user.user,
    company: state.company.companiesList
      ? state.company.companiesList.content
      : [],
    selectedCompany: state.company.selectedCompanyRef,
    chainCompaniesList: state.company.chainCompaniesList,
    creditFacilityInformation: creditFacilityInformation,
    loanResponse: state?.installmentLoan,
    maxLoan: creditFacilityInformation?.amountLeftWithoutBonus,
    activeCompany: state.user.user.activeCompany,
    invoiceCloned: state.installmentLoan?.invoiceCloned,
  };
}
export default connect(mapStateToProps, {
  createInstallmentLoans,
  removeResponseInstallmentLoans,
  getUpdatedPatientCreditFacility,
  getLogsCreditFacility,
  getSimpleSearchClinicPaginated,
  getCompaniesForCompanyChain,
  success,
  notifError,
  toggleServerModalError,
})(NewInvoice);
