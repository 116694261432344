import {
  ACCEPTANCE_FLOW_MODAL_STATE,
  CLEAR_MEMBER_PORTAL_DATA,
  CLIENT_IS_AUTHENTICATED,
  CREATE_MIT_ID_STATE,
  GET_CLIENT_CREDIT_DATA_FOR_MEMBER_PORTAL,
  IS_CLIENT_LOGOUT,
  IS_NOT_CLIENT,
  LOADING_CLIENT_PORTAL_DATA,
} from '../constants';

const defaultState = {
  mitIdState: '',
  clientDataInformation: null,
  loadingClientPortalData: null,
  clientIsAuthenticated: null,
  isClientLogout: null,
  isNotClient: null,
  acceptanceFlowModal: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CREATE_MIT_ID_STATE:
      return {
        ...state,
        mitIdState: action.payload?.data,
      };

    case GET_CLIENT_CREDIT_DATA_FOR_MEMBER_PORTAL:
      return {
        ...state,
        clientDataInformation: action.payload?.data,
      };

    case LOADING_CLIENT_PORTAL_DATA:
      return {
        ...state,
        loadingClientPortalData: action.payload,
      };
    case CLIENT_IS_AUTHENTICATED:
      return {
        ...state,
        clientIsAuthenticated: action.payload,
      };
    case ACCEPTANCE_FLOW_MODAL_STATE:
      return {
        ...state,
        acceptanceFlowModal: action.payload,
      };

    case IS_CLIENT_LOGOUT:
      return {
        ...state,
        isClientLogout: action.payload,
      };
    case IS_NOT_CLIENT:
      return {
        ...state,
        isNotClient: action.payload,
      };
    case CLEAR_MEMBER_PORTAL_DATA:
      return {
        ...state,
        clientDataInformation: null,
        loadingClientPortalData: null,
        clientIsAuthenticated: null,
        mitIdState: '',
      };

    default:
      return state;
  }
}
