import { requestAxios as axios } from '../constants/utils';
import {
  SERVER_URL,
  GET_BOOKING_REQUEST_DATA,
  UPDATE_SUPPORT_DECIDED_STATUS,
  UPDATE_CLINIC_DECIDED_STATUS,
  UPDATE_ALLOW_BOOKING_REQUESTS_STATUS,
  UPDATE_LEAD_INTERNAL_NOTE_SUPPORT,
  GET_PATIENT_DATA_SUPPORT,
  ADD_EMAIL_SEGMENT_USER_ASSOCIATION,
  REMOVE_EMAIL_SEGMENT_USER_ASSOCIATION,
  RESEND_BOOKING_REQUEST,
} from '../constants';
import { isAuthenticated } from '../constants/utils';

export function retrieveBookingRequestData(
  auth,
  bookingRequestId,
  clinicName,
  email,
  firstName
) {
  let axiosConfig = {
    method: 'POST',
    url: `${SERVER_URL}/api/v1/liveAgent/retrieveBookingRequestData`,
    withCredentials: true,
    data: {
      bookingRequestId,
      clinicName,
      email,
      firstName,
    },
  };
  if (!isAuthenticated()) {
    axiosConfig.auth = auth;
  }
  const request = axios(axiosConfig);

  return {
    type: GET_BOOKING_REQUEST_DATA,
    payload: request,
  };
}

export function resendBookingRequest(auth, token) {
  const request = axios({
    method: 'POST',
    url: `${SERVER_URL}/api/v1/company/resendBookingRequest`,
    withCredentials: true,
    auth: auth,
    data: {
      token,
    },
  });

  return {
    type: RESEND_BOOKING_REQUEST,
    payload: request,
  };
}

export function updateSupportDecidedStatus(auth, token, decidedStatus) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/company/updateSupportDecidedStatus`,
    withCredentials: true,
    auth: auth,
    data: {
      bookingRequestUuid: token,
      supportDecidedStatus: decidedStatus,
    },
  });

  return {
    type: UPDATE_SUPPORT_DECIDED_STATUS,
    payload: request,
  };
}

export function updateClinicDecidedStatus(token, decidedStatus) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/company/updateClinicDecidedStatus`,
    data: {
      bookingRequestUuid: token,
      clinicDecidedStatus: decidedStatus,
    },
  });

  return {
    type: UPDATE_CLINIC_DECIDED_STATUS,
    payload: request,
  };
}

export function updateAllowBookingRequestStatus(
  auth,
  companyRefNumber,
  allowBookingRequests
) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/company/updateCompanyInfo/`,
    withCredentials: true,
    auth: auth,
    data: {
      referenceNumber: companyRefNumber,
      allowBookingRequests,
    },
  });

  return {
    type: UPDATE_ALLOW_BOOKING_REQUESTS_STATUS,
    payload: request,
  };
}

export function updateLeadInternalNoteSupport(auth, id, internalNote) {
  const request = axios({
    method: 'patch',
    url: `${SERVER_URL}/api/v1/leads/internalNote`,
    withCredentials: true,
    auth: auth,
    data: {
      id,
      internalNote,
    },
  });

  return {
    type: UPDATE_LEAD_INTERNAL_NOTE_SUPPORT,
    payload: request,
  };
}

export function getPatientDataSupport(auth, data) {
  let axiosConfig = {
    method: 'post',
    url: `${SERVER_URL}/api/v1/liveAgent/retrievePatientData`,
    withCredentials: true,
    data,
  };
  if (!isAuthenticated()) {
    axiosConfig.auth = auth;
  }
  const request = axios(axiosConfig);

  return {
    type: GET_PATIENT_DATA_SUPPORT,
    payload: request,
  };
}

export function addEmailSegmentUserAssociation(auth, data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/emailSegment/addEmailSegmentUserAssociation`,
    withCredentials: true,
    auth,
    data,
  });

  return {
    type: ADD_EMAIL_SEGMENT_USER_ASSOCIATION,
    payload: request,
  };
}

export function removeEmailSegmentUserAssociation(auth, data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/emailSegment/removeEmailSegmentUserAssociation`,
    withCredentials: true,
    auth,
    data,
  });

  return {
    type: REMOVE_EMAIL_SEGMENT_USER_ASSOCIATION,
    payload: request,
  };
}
