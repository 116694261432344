const initialState = {
  user: {},
  form: {},
  lead: {
    searchActive: {
      flag: false,
      searchInput: '',
    },
    wsPatientsToUpdate: [],
  },
  status: {},
  createCredit: {},
  installmentLoan: {
    acceptLoan: {},
    signatureData: {},
  },
  company: {
    searchActive: {
      flag: false,
      searchInput: '',
    },
    filters: {},
  },
  companyChain: {
    searchActive: {
      flag: false,
      searchInput: '',
    },
    filters: {},
  },
  patientsDunning: {
    searchActive: {
      flag: false,
      searchInput: '',
    },
  },
  ui: {
    ongoingRequestsCounter: 0,
  },
  notifications: [],
  log: {},
  onboarding: {},
  onboardingDocumentation: {},
  websocketMessages: {
    clientPortalWs: [],
    client: [],
    clinic: [],
    documentation: [],
  },
};

export default initialState;
