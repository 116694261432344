import { momentFormatLong } from '../../../constants';
import { currencyFormatDA, formatInterestRate } from '../../../constants/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DentiModal from '../../common/DentiModal';
import { useDispatch } from 'react-redux';
// import { ReactComponent as ArrowRightIcon } from '../../../resources/icons/arrow-right.svg';
// eslint-disable-next-line
import { getClientPaymentPlan } from '../../../actions/memberPortalActions';
// eslint-disable-next-line
import PartialRedemptionMainComponent from '../../partialRedemption/PartialRedemptionMainComponent';
import LoanDetails from '../../invoices/LoanDetails';
import { strings } from '../../../constants/localization';
import KebabMenu from '../../../components/common/KebabMenu/KebabMenu';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../../constants';

interface PaymentAgreementsListItemProps {
  setPaymentPlanModal: (value: boolean) => void;
  setPartialRedemptionModal: (value: boolean) => void;
  createdDate: string;
  montlhyPayment: number;
  interestRate: number;
  remainingBalance: number;
  loanAmount: number;
  loanNumber: string;
}

interface clientDataProps {
  clientData: {
    leadUuid: string;
  };
  creditFacilityData: {
    number: string;
  };
}

interface SundhedCreditProps {
  creditFacilityData: {
    status: string;
    amountAvailable: number;
    expirationDate: string;
    interestRate: number;
    amount: number;
    dunning: boolean;
    creditCardData: {
      status: string;
      expirationDate: string;
    };
    numberOfReepayInvoicesToday: number;
    installmentLoanDataList: [
      {
        number: string;
        status: string;
      }
    ];
  };
  clientData: {
    uuid: string;
  };
}

enum WarningCase {
  LIMIT_REACHED = "paymentReachedLimit",
  INVALID_CARD = "invalidCard"
};

const PaymentAgreementsListItem: React.FC<PaymentAgreementsListItemProps> = ({
  createdDate,
  montlhyPayment,
  interestRate,
  remainingBalance,
  loanAmount,
  loanNumber,
  setPaymentPlanModal,
  setPartialRedemptionModal,
}) => {
  const [loanPlanModal, setLoanPlanModal] = useState<boolean>(false);
  const [loanPlan, setLoanPlan] = useState<object>();
  const [partialPaymentModal, setPartialPaymentModal] =
    useState<boolean>(false);
  const memberData = useSelector(
    (state: { memberPortal: { clientDataInformation: clientDataProps } }) =>
      state.memberPortal?.clientDataInformation
  );
  const dispatch = useDispatch();

  const creditInformation = useSelector(
    (state: { memberPortal: { clientDataInformation: SundhedCreditProps } }) =>
      state.memberPortal?.clientDataInformation
  );

  const clientCreditInformation = creditInformation.creditFacilityData;

  const { dunning, numberOfReepayInvoicesToday, installmentLoanDataList
  } = clientCreditInformation;
  const { expirationDate, status: creditCardStatus } = clientCreditInformation.creditCardData;
  const isCardInactive = creditCardStatus !== 'ACTIVE';
  const [showWarningNotAllowed, setShowWarningNotAllowed] =
    useState<boolean>(false);
  const [warningCase, setWarningCase] = useState<WarningCase>(WarningCase.LIMIT_REACHED);
  // get the last accepted invoice
  const lastAcceptedInvoice = installmentLoanDataList.filter(invoice => invoice.status === 'ACCEPTED' || invoice.status === 'ACTIVE').pop();
  const lastAcceptedInvoiceNumber = lastAcceptedInvoice?.number;

  const { userAgent } = window.navigator;

  // Set device to "mobile" if "Mobi" exists in userAgent else set device to "desktop"
  const device = userAgent.includes('Mobi') ? 'mobile' : 'desktop';

  const isCreditCardExpired = () => {
    const [month, year] = expirationDate.split('-');
    // Prepend '20' to the year to interpret it as 2099 instead of 1999
    const fullYear = `20${year}`;
    const expDate = moment(`${month}-${fullYear}`, 'MM-YYYY').endOf('month');
    return expDate.isBefore(moment());
  };

  const currentUrl =
    window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : `https://${process.env.REACT_APP_DAM_URL}`;

  const openLoanDetailsModal = () => {
    setLoanPlanModal(!loanPlanModal);

    dispatch(getClientPaymentPlan(loanNumber)).then((response) => {
      setLoanPlan(response.payload.data);
    });
  };

  useEffect(() => {
    setPaymentPlanModal(loanPlanModal);
    setPartialRedemptionModal(partialPaymentModal);
  }, [loanPlanModal, partialPaymentModal]);

  const openPartialPaymentModal = () => {
    if (numberOfReepayInvoicesToday > 0) {
      setWarningCase(WarningCase.LIMIT_REACHED);
      setShowWarningNotAllowed(true);
    } else if (isCardInactive || isCreditCardExpired() || dunning) {
      setWarningCase(WarningCase.INVALID_CARD);
      setShowWarningNotAllowed(true);
    }
    else {
      const url = `${currentUrl}/partialRedemption?leadUuid=${memberData?.clientData.leadUuid}&creditFacilityNumber=${memberData?.creditFacilityData?.number}&source=portal&device=${device}`;
      if (device === 'mobile') {
        window.open(url, '_blank');
      } else {
        setPartialPaymentModal(!partialPaymentModal);
      }
    }
  };

  const renderWarning = () => {
    return (
      <DentiModal
        key={1}
        close={() => setShowWarningNotAllowed(false)}
        modalClass='notification-modal warning payment-flow'
        modalClassName='confirm-action'
      >
        <div>
          <div className='icon'>
            <i className=' lnil lnil-warning' />
          </div>
          <div>
            <h3 className='headline'>
              {strings.partialRedemptionTitleWarningMessage}
            </h3>
            <p className='notification-message'>
              {warningCase === WarningCase.LIMIT_REACHED ? strings.partialRedemptionTextWarningMessage1 :
                strings.formatString(
                  strings.partialRedemptionTextWarningMessage2,
                  <a
                    href={`${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${lastAcceptedInvoiceNumber}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {strings.partialRedemptionTextWarningMessage2Link}
                  </a>
                )}
            </p>
          </div>
          <div className='bottom-section action-btn mt-3'>
            <button
              className='submit-btn btn btn-blue center-block'
              type='button'
              onClick={() => setShowWarningNotAllowed(false)}
            >
              <span>{strings.loanDetailsClose}</span>
            </button>
          </div>
        </div>
      </DentiModal>
    );
  };

  return (
    <>
      <div
        className='table-row-div'
        // removed loan-list-item className while we can´t click at the item for the first release version
        id='list-group-item'
        onClick={() => openLoanDetailsModal()}
      >
        <div className='table-cell-div'>
          {moment(createdDate).format(momentFormatLong).toLowerCase()}
        </div>

        <div className='table-cell-div '>
          {montlhyPayment && currencyFormatDA(montlhyPayment, true, true)}
        </div>
        <div className='table-cell-div'>{formatInterestRate(interestRate)}</div>
        <div className='table-cell-div'>
          {remainingBalance && currencyFormatDA(remainingBalance, true, true)}
        </div>
        <div className='table-cell-div'>
          {loanAmount && currencyFormatDA(loanAmount, true, true)}
        </div>

        <div
          className='table-cell-div'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='actions-menu'>
            <KebabMenu menuPlacement={undefined} scrollToBottom={undefined}>
              <div
                color='light'
                className='KebabMenu-item'
                onClick={openLoanDetailsModal}
              >
                <i className='lnir lnir-search-alt icon' />
                {strings.mitCreditAgreementKebabMenuOptionShowDetails}
              </div>

              <div
                color='light'
                className='KebabMenu-item'
                onClick={openPartialPaymentModal}
              >
                <i className='lnir lnir-circle-plus icon' />
                {strings.mitCreditAgreementKebabMenuOptionPartialPayment}
              </div>

            </KebabMenu>
          </div>
        </div>
      </div>

      {/* Loan details modal */}
      {loanPlanModal && (
        <DentiModal
          key={2}
          close={() => setLoanPlanModal(false)}
          modalClass='loan-details-modal modal-dialog-scrollable modal-dialog-centered'
          modalClassName='loan-details-action'
          // closeBtn
          footerCloseBtn
          footerCloseBtnText={strings.loanDetailsClose}
        >
          <LoanDetails
            loanNumber={loanNumber}
            loanDetails={loanPlan}
            isClientPortal={true}
          />
        </DentiModal>
      )}

      {/* warning partial payment not allowed */}
      {showWarningNotAllowed && renderWarning()}

      {/* Partial payment modal */}
      {!showWarningNotAllowed && partialPaymentModal && (
        <DentiModal
          key={1}
          close={() => {
            setPartialPaymentModal(false);
            window.location.reload();
          }}
          modalClass='loan-details-modal modal-dialog-centered'
          modalClassName='partial-redemption-modal'
          closeBtn={true}
        >
          <div className='partial-redemption-styles'>
            <PartialRedemptionMainComponent
              hasLeadUuid={memberData?.clientData.leadUuid}
              hasCreditFacilityNumber={memberData?.creditFacilityData?.number}
              isClientPortal={true}
              deviceType={device}
              closeModal={() => setPartialPaymentModal(false)}
            />
          </div>
        </DentiModal>
      )}
    </>
  );
};

export default PaymentAgreementsListItem;
