import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import './style.scss';
import { strings } from '../../constants/localization';
import BookingRequestTab from './BookingRequestTab';
import LiveAgent from './LiveAgent';

class SupportPage extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(document.location.search.substring(1));
    const subject = params.get('subject') || null;
    const emails = params.get('e-mail').split(',') || null;
    const phone = params.get('phone').split(',') || null;
    const firstname = params.get('firstname').split(' ') || null;
    const ak = atob(params.get('ak'));
    let stringA = '';
    let stringB = '';
    for (let i = 0; i < ak.length; i++) {
      if (i % 2 === 0) {
        stringA = `${stringA}${ak.charAt(i)}`;
      } else {
        stringB = `${stringB}${ak.charAt(i)}`;
      }
    }
    let urlHasBooking =
      subject &&
      (subject?.includes('Bookingforespørgsel') ||
        subject?.includes('Anmodning om at få behandler oprettet'));
    this.state = {
      emails: emails.length > 0 && emails[0] !== '' ? emails : null,
      phone: phone.length > 0 && phone[0] !== '' ? phone : null,
      firstname:
        firstname.length > 0 && firstname[0] !== '' ? firstname[0] : null,
      stringA,
      stringB,
      activeTab: '1',
      bookingRequestStatus: {},
      subject,
    };
    if (urlHasBooking) {
      this.state = {
        ...this.state,
        hasBooking: true,
        clinicName: this.processSubject(subject)?.clinicName,
        bookingId: this.processSubject(subject)?.bookingId,
      };
    }
  }

  componentDidMount() {
    document.body.classList.add('public-content');
  }
  componentWillUnmount() {
    document.body.classList.remove('public-content');
  }

  extractClinicNameAndBookingId(string) {
    let regexForBookingId = /-\s*(\d+)/g;
    let regexForClinicName = /^[a-zA-Z\s-]*/g;
    let matchId = string.match(regexForBookingId);
    let matchClinicName = string.match(regexForClinicName);

    return {
      clinicName: matchClinicName
        ? matchClinicName[0].replace(/\s*-\s*$/, '')
        : null,
      bookingId: matchId ? matchId[0].replace(/\s*-\s*/g, '') : null,
    };
  }

  processSubject(subject) {
    let clinicResult = '';
    let regexForRefNumber = /\s*-*\s*\(([^)]+)\)/g;
    let match = subject.match(regexForRefNumber);

    if (subject) {
      if (subject.includes('Bookingforespørgsel')) {
        clinicResult = subject.replace('Bookingforespørgsel - ', '').trim();
      }
      if (subject.includes('Anmodning om at få behandler oprettet')) {
        clinicResult = subject
          .replace('Anmodning om at få behandler oprettet - ', '')
          .trim();
      }
      if (clinicResult.includes('Partner')) {
        clinicResult = clinicResult.replace('Partner - ', '').trim();
      }
    }

    // if there is a match for refNumber, remove it from subject
    if (match) {
      clinicResult = clinicResult.replace(regexForRefNumber, '');
    }
    return this.extractClinicNameAndBookingId(clinicResult);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className='LiveAgent_page'>
        {this.state.subject && this.state.hasBooking ? (
          <Row className='tab-row'>
            <Col xs='12'>
              <div className='live-agent-container'>
                <div className='nav-tabs-section'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => this.toggleTab('1')}
                      >
                        {strings.liveAgentTabBookingHeadline}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => this.toggleTab('2')}
                      >
                        {strings.liveAgentTabPatientHeadline}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                      <BookingRequestTab
                        user={{ language: 'da' }}
                        subject={this.state.subject}
                        clinicName={this.state.clinicName}
                        bookingId={this.state.bookingId}
                        hasBooking={this.state.hasBooking}
                        email={this.state.emails[0]}
                        firstName={this.state.firstname}
                        auth={{
                          password: this.state.stringA,
                          username: this.state.stringB,
                        }}
                      />
                    </TabPane>
                    <TabPane tabId='2'>
                      <Row>
                        <Col sm='12'>
                          <LiveAgent
                            emails={this.state.emails}
                            firstname={this.state.firstname}
                            phone={this.state.phone}
                            auth={{
                              password: this.state.stringA,
                              username: this.state.stringB,
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <LiveAgent
            emails={this.state.emails}
            firstname={this.state.firstname}
            phone={this.state.phone}
            auth={{
              password: this.state.stringA,
              username: this.state.stringB,
            }}
          />
        )}
      </div>
    );
  }
}
export default SupportPage;
