import React from 'react';
import { ReactComponent as MoneyBag } from '../resources/icons/money-bag-alt.svg';
import { ReactComponent as TruckRoadAssistance } from '../resources/icons/truck-alt-1.svg';
import { ReactComponent as HouseDiscount } from '../resources/icons/house-discount.svg';
import { ReactComponent as BabyKid } from '../resources/icons/baby-kid.svg';
import { ReactComponent as Condominium } from '../resources/icons/condominium.svg';
import { ReactComponent as Team } from '../resources/icons/team.svg';
import { ReactComponent as RepairTools } from '../resources/icons/repair-tools.svg';
import { ReactComponent as Fuel } from '../resources/icons/fuel.svg';
import { ReactComponent as BankAlt } from '../resources/icons/bank-alt.svg';
import { ReactComponent as LifeGuard } from '../resources/icons/life-guard.svg';
import { ReactComponent as InternetAlt } from '../resources/icons/internet-alt.svg';
import { ReactComponent as MobileAlt } from '../resources/icons/mobile-alt.svg';
import { ReactComponent as Tv } from '../resources/icons/tv-1.svg';
import { ReactComponent as CoinsAlt } from '../resources/icons/coins-alt.svg';

// INVOICE STATUSES
export const invoicesStatusMap = {
  WAITING_FOR_SIGNATURE: {
    className: 'status-yellow pending',
    statusText: 'waitingForSignature',
  },
  ACCEPTED: {
    className: 'status-green',
    statusText: 'accepted',
  },
  NOT_ACCEPTED: {
    className: 'status-red',
    statusText: 'notAccepted',
  },
  ACTIVE: {
    className: 'status-green',
    statusText: 'active',
  },
  DEPRECATED: {
    className: 'status-yellow',
    statusText: 'deprecated',
  },
  PAYED_IN_FULL: {
    className: 'status-green',
    statusText: 'payedInFull',
  },
  LATE_PAYMENT: {
    className: 'status-red',
    statusText: 'latePayment',
  },
  DELETED: {
    className: 'status-red',
    statusText: 'deleted',
  },
  SETTLED: {
    className: 'status-green',
    statusText: 'settled',
  },
  PAYED: {
    className: 'status-green',
    statusText: 'payed',
  },
  DUNNING: {
    className: 'status-red',
    statusText: 'payed',
  },
  FAILED: {
    className: 'status-red',
    statusText: 'payed',
  },
  REEPAY: {
    className: 'status-red',
    statusText: 'overdue',
  },
  WAITING: {
    className: 'status-red',
    statusText: 'overdue',
  },
  INACTIVE: {
    className: 'status-inactive',
    statusText: 'inactive',
  },
  DEBT_COLLECTION: {
    className: 'status-yellow',
    statusText: 'debtCollection',
  },
  RESTRUCTURED: {
    className: 'status-inactive',
    statusText: 'restructured',
  },
  CONSOLIDATED: {
    className:
      process.env.REACT_APP_BRAND === 'Denti'
        ? 'status-green'
        : 'status-stable',
    statusText: 'consolidated',
  },
  ONBOARDING: {
    className: 'status-green',
    statusText: 'onBoarding',
  },
  MEMBER: {
    className: 'status-green',
    statusText: 'member',
  },
  CANCELLED: {
    className: 'status-green',
    statusText: 'cancelled',
  },
  REJECTED: {
    className: 'status-red',
    statusText: 'rejectedCredit',
  },
  SUSPENDED: {
    className: 'status-yellow',
    statusText: 'suspended',
  },
  DISABLED: {
    className: 'status-inactive',
    statusText: 'noCredit',
  },
  ACTIVE_CREDIT_EXPIRED: {
    className: 'status-green',
    statusText: 'expiredCredit',
  },
  ACTIVE_CREDIT_UNEXPIRED: {
    className: 'status-green',
    statusText: 'activeCredit',
  },
  LATE_PAYMENT_CREDIT: {
    className: 'status-green',
    statusText: 'latePaymentCredit',
  },
  DENTI: {
    className: 'status-green',
    statusText: 'denti',
  },
  SUNDHED: {
    className: 'status-green',
    statusText: 'sundhed',
  },
};

// NEW INVOICE HISTORY STATUSES
export const newInvoicesStatusMap = {
  PENDING: {
    className: 'status-pending',
    statusIcon: 'lnir-spinner-5',
    statusText: 'pendingStatus',
  },
  APPROVED: {
    className: 'status-approved',
    statusIcon: 'lnir-thumbs-up',
    statusText: 'approvedStatus',
  },
  APPROVED_ZERO_INTEREST: {
    className: 'status-approved',
    statusIcon: 'lnir-thumbs-up',
    statusText: 'approvedStatusZeroInterest',
  },
  PAYED_OUT: {
    className: 'status-payed',
    statusIcon: 'lnir-checkmark',
    statusText: 'paidOutStatus',
  },
  PAYED_OUT_ZERO_INTEREST: {
    className: 'status-payed',
    statusIcon: 'lnir-pie-chart-alt',
    statusText: 'paidOutStatusZeroInterest',
  },
  DECLINED: {
    className: 'status-declined',
    statusIcon: 'lnir-cross-circle',
    statusText: 'declinedStatus',
  },
  UNDEFINED: {
    className: 'status-undefined',
    statusIcon: 'lnir-help',
    statusText: 'undefinedStatus',
  },
};

//PAYMENT AGREEMENT STATUS MAP
export const paymentAgreementStatus = {
  ACCEPTED: {
    className: 'status-active',
    statusText: 'loanActiveStatus',
  },
  CONSOLIDATED: {
    className: 'status-disabled',
    statusText: 'loanConsolidatedStatus',
  },
  RESTRUCTURED: {
    className: 'status-disabled',
    statusText: 'loanRestructuredStatus',
  },
  LATE_PAYMENT: {
    className: 'status-red',
    statusText: 'loanLatePaymentStatus',
  },
  PAYED: {
    className: 'status-inactive',
    statusText: 'loanPayedStatus',
  },
  SETTLED: {
    className: 'status-active',
    statusText: 'loanSettledStatus',
  },
};

// INVOICE STATUSES
export const companiesStatusMap = {
  DENTI: {
    className: 'status-green',
    statusText: 'denti',
  },
  SUNDHED: {
    className: 'status-green',
    statusText: 'sundhed',
  },
};

// CARD STATUSES
export const cardStatusMap = {
  NO_CARD: {
    className: 'status-yellow',
    statusText: 'noCard',
  },
  ACTIVE: {
    className: 'status-green',
    statusText: 'cardActive',
  },
  FAILED: {
    className: 'status-red',
    statusText: 'cardDeclined',
  },
  INACTIVATED: {
    className: 'status-red',
    statusText: 'cardDeclined',
  },
  DELETED: {
    className: 'status-red',
    statusText: 'cardDeclined',
  },
};

// APPLICATION STATUSES
export const applicationStatusMap = {
  IN_PROGRESS: {
    className: 'in-progress',
    statusText: 'applicationFilterInProgress',
  },
  FAILED: {
    className: 'failed',
    statusText: 'applicationFilterFailed',
  },
  REJECTED: {
    className: 'rejected',
    statusText: 'applicationFilterRejected',
  },
  DEPRECATED: {
    className: 'deprecated',
    statusText: 'applicationFilterDeprecated',
  },
  PENDING_MANUAL: {
    className: 'pending-manual',
    statusText: 'applicationFilterPendingManual',
  },
  PENDING_DOCUMENTATION: {
    className: 'pending',
    statusText: 'applicationFilterPendingDocumentation',
  },
  PENDING_ACTIVATION: {
    className: 'pending',
    statusText: 'applicationFilterPendingActivation',
  },
  DONE: {
    className: 'done',
    statusText: 'applicationFilterDone',
  },
};

export const applicationPayslipsMap = {
  '00': {
    payslipsSalaryTypeText: 'payslipsType00',
  },
  '01': {
    payslipsSalaryTypeText: 'payslipsType01',
  },
  '03': {
    payslipsSalaryTypeText: 'payslipsType03',
  },
  '04': {
    payslipsSalaryTypeText: 'payslipsType04',
  },
  '05': {
    payslipsSalaryTypeText: 'payslipsType05',
  },
  '06': {
    payslipsSalaryTypeText: 'payslipsType06',
  },
  '07': {
    payslipsSalaryTypeText: 'payslipsType07',
  },
  '08': {
    payslipsSalaryTypeText: 'payslipsType08',
  },
  '09': {
    payslipsSalaryTypeText: 'payslipsType09',
  },
  20: {
    payslipsSalaryTypeText: 'payslipsType20',
  },
  24: {
    payslipsSalaryTypeText: 'payslipsType24',
  },
  26: {
    payslipsSalaryTypeText: 'payslipsType26',
  },
};

// DOCUMENTATION STATUSES
export const documentationStatusMap = {
  APPROVED: {
    className: 'status-green',
    statusText: 'documentationFilterApproved',
  },
  PENDING: {
    className: 'status-yellow',
    statusText: 'documentationFilterPending',
  },
  REJECTED: {
    className: 'status-red',
    statusText: 'documentationFilterRejected',
  },
};

// DOCUMENTATION TYPES
export const documentationTypeMap = {
  NET_INCOME_TOTAL: {
    typeIcon: <MoneyBag className='documentation-svg-icon' />,
  },
  HOUSING_BENEFIT: {
    typeIcon: <HouseDiscount className='documentation-svg-icon' />,
  },
  CHILD_BENEFIT: {
    typeIcon: <BabyKid className='documentation-svg-icon' />,
  },
  CHILDREN_CHECK_AMOUNT: {
    typeIcon: <BabyKid className='documentation-svg-icon' />,
  },
  ADDITIONAL_INCOME: {
    typeIcon: <MoneyBag className='documentation-svg-icon' />,
  },
  HOUSE_LOAN_EXPENSES: {
    typeIcon: 'lnil lnil-house-alt-1',
  },
  MORTGAGE_EXPENSES: {
    typeIcon: 'lnil lnil-house-alt-1',
  },
  RENTAL_EXPENSES: {
    typeIcon: <Condominium className='documentation-svg-icon' />,
  },
  COMMON_FIXED_EXPENSES: {
    typeIcon: <Team className='documentation-svg-icon' />,
  },
  ELECTRICITY: {
    typeIcon: 'lnil lnil-bolt-alt',
  },
  HEATING_EXPENSES: {
    typeIcon: 'lnil lnil-sun',
  },
  WATER: {
    typeIcon: 'lnil lnil-water-drops',
  },
  MAINTENANCE: {
    typeIcon: <RepairTools className='documentation-svg-icon' />,
  },
  REAL_ESTATE_INSURANCE: {
    typeIcon: 'lnil lnil-money-protection',
  },
  TRAIN_BUS_CARD: {
    typeIcon: 'lnil lnil-train',
  },
  TRANSPORT_FUEL_EXPENSES: {
    typeIcon: <Fuel className='documentation-svg-icon' />,
  },
  CAR_LEASING_COST: {
    typeIcon: 'lnil lnil-car-alt',
  },
  CAR_LOAN: {
    typeIcon: <BankAlt className='documentation-svg-icon' />,
  },
  CAR_INSURANCE: {
    typeIcon: 'lnil lnil-money-protection',
  },
  CAR_MAINTENENCE: {
    typeIcon: 'lnil lnil-cog',
  },
  CAR_OWNERSHIP_TAXES: {
    typeIcon: 'lnil lnil-coins',
  },
  FDMAAA: {
    typeIcon: <TruckRoadAssistance className='documentation-svg-icon' />,
  },
  EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN: {
    typeIcon: <BankAlt className='documentation-svg-icon' />,
  },
  STUDENT_LOAN_EXPENSES: {
    typeIcon: <BankAlt className='documentation-svg-icon' />,
  },
  DebtSinceLastTaxReport: {
    typeIcon: <MoneyBag className='documentation-svg-icon' />,
  },
  SECURITY_SUBSCRIPTION: {
    typeIcon: 'lnil lnil-alarm-4',
  },
  CHILDREN_SUPPORT: {
    typeIcon: <LifeGuard className='documentation-svg-icon' />,
  },
  CHILDREN_COST: {
    typeIcon: 'lnil lnil-letterblocks',
  },
  HEALTH_INSURANCE: {
    typeIcon: 'lnil lnil-medical-protection',
  },
  INSURANCE: {
    typeIcon: 'lnil lnil-money-protection',
  },
  INTERNET_SUBSCRIPTION: {
    typeIcon: <InternetAlt className='documentation-svg-icon' />,
  },
  PHONE_SUBSCRIPTION: {
    typeIcon: <MobileAlt className='documentation-svg-icon' />,
  },
  TV_SUBSCRIPTION: {
    typeIcon: <Tv className='documentation-svg-icon' />,
  },
  FAGLIGT_KONTIGENT: {
    typeIcon: 'lnil lnil-handshake',
  },
  OTHER_EXPENSES_TO_PUBLIC_AUTHORITIES: {
    typeIcon: <CoinsAlt className='documentation-svg-icon' />,
  },
  ADDITIONAL_EXPENSES: {
    typeIcon: <CoinsAlt className='documentation-svg-icon' />,
  },
  HOUSEHOLD: {
    typeIcon: 'lnil lnil-cart-full',
  },

  SPLIT_EXPENSES: {
    typeIcon: 'lnil lnil-arrows-horizontal ',
  },
};

export const onboardingDenialReasons = {
  'The client has payment remarks': {
    statusText: [
      'onboardingDeniedForPaymentRemarks',
      'onboardingDeniedForPaymentRemarksP2',
      'onboardingDeniedForPaymentRemarksP3',
    ],
    link: 'https://www.mindbroplysning.dk/',
  },
  'Estimated disposable amount <= 0': {
    statusText: [
      'onboardingDeniedForDisposableAmountBelow0',
      'onboardingDeniedForDisposableAmountBelow0P2',
    ],
  },
  'Cpr missmatch': {
    statusText: [
      'onboardingDeniedForCprMismatch',
      'onboardingDeniedForCprMismatchP2',
    ],
  },
  'Client is suspended': {
    statusText: [
      'onboardingDeniedForSuspendedP1',
      'onboardingDeniedForSuspendedP2',
      'onboardingDeniedForSuspendedP3',
    ],
  },
  'Client is in debt collection': {
    statusText: ['onboardingDeniedForClientIndebCollection'],
  },
  'Client has name and address protection': {
    statusText: ['onboardingDeniedForClientHasNameAndAddressProtection'],
  },
  'Next allowed application date is in the future': {
    statusText: ['onboardingDeniedForNextAllowedApplicationDate'],
  },
  Generic: {
    statusText: ['onboardingDeniedGenericMessage'],
  },
};

// DUNNING STATUSES
export const dunningStatusMap = {
  NOT_CONTACTED: {
    className: 'status-notContacted',
    statusText: 'dunningNotContacted',
    color: 'grey',
  },
  NO_CONTACT: {
    className: 'status-noContact',
    statusText: 'dunningNoContact',
    color: 'yellow',
  },
  DIALOGUE: {
    className: 'status-dialogue',
    statusText: 'dunningDialogue',
    color: 'blue',
  },
  AGREEMENT: {
    className: 'status-agreement',
    statusText: 'dunningAgreement',
    color: 'green',
  },
  DEBT_COLLECTION: {
    className: 'status-debtCollection',
    statusText: 'dunningDebtCollection',
    color: 'red',
  },
};

// DUNNING CAUSE
export const dunningCauseMap = [
  { value: 'DUNNING', label: 'dunningCauseDunning' },
  { value: 'DECEASED', label: 'dunningCauseDeceased' },
  { value: 'DEBT_COUNSELLING', label: 'dunningCauseCounseling' },
  { value: 'MANUAL_PAYMENT', label: 'dunningCauseManual' },
];

// DUNNING CARD STATE
export const dunningCardStateMap = [
  { value: 'ACTIVE', label: 'dunningCardStateActive' },
  { value: 'INACTIVATED', label: 'dunningCardStateInactive' },
  { value: 'FAILED', label: 'dunningCardStateFailed' },
  { value: 'DELETED', label: 'dunningCardStateDeleted' },
  { value: 'MISSING', label: 'dunningCardStateMissing' },
];

// CREDIT FACILITY
export const creditFaciltyStatusesMapEN = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'SUSPENDED', label: 'Suspended' },
  { value: 'LATE_PAYMENT', label: 'Debt collection' },
  { value: 'DISABLED', label: 'Inactive' },
];
export const creditFaciltyStatusesMapDA = [
  { value: 'ACTIVE', label: 'Aktiv' },
  { value: 'REJECTED', label: 'Afvist' },
  { value: 'SUSPENDED', label: 'Suspenderet' },
  { value: 'LATE_PAYMENT', label: 'Inkasso' },
  { value: 'DISABLED', label: 'Inaktiv' },
];

// BOOKING STATUSES
export const bookingStatusesDropdownEN = [
  { value: 'OPEN', top: 'NEW' },
  { value: 'WATCHED', top: 'WATCHED' },
  { value: 'CLINIC_DONE', top: 'SOLVED BY CLINIC' },
  { value: 'DONE', top: 'DONE' },
];
export const bookingStatusesDropdownDA = [
  { value: 'OPEN', top: 'UÅBNET' },
  { value: 'WATCHED', top: 'ÅBNET' },
  { value: 'CLINIC_DONE', top: 'LØST AF KLINIKKEN' },
  { value: 'DONE', top: 'AFSLUTTET' },
];
