import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { strings } from '../../../constants/localization';
import { Button } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import BudgetCategory from './BudgetCategory';
import { ReactComponent as DisposableAmount } from '../../../resources/icons/disposable_amount.svg';
import { formatCurrencyDaStandard } from '../../../constants/utils';
import { updateApplicationBudgetItem } from '../../../actions/onboardingApplicationsActions';
import UpdateBudgetLoading from './UpdateBudgetLoading';

const BudgetDisplay = () => {
  const dispatch = useDispatch();
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const [categories, setCategories] = useState([]);
  const { creditApplication, budgetInformation, clientData } =
    activeApplicationListItem || {};
  const {
    statedDisposableAmount,

    creditApplicationUuid,
  } = creditApplication || {};
  const { referenceBudget, revisedBudget, clientFinalBudget, finalBudget } =
    budgetInformation || {};
  const leadUuid = clientData?.leadUuid;
  const [combinedBudgetsArray, setCombinedBudgetsArray] = useState([]);
  const [enableButton, setEnableButton] = useState(false);
  const [showUpdateBudgetLoading, setShowUpdateBudgetLoading] = useState(false);
  const [
    currentEstimatedDisposableAmount,
    setCurrentEstimatedDisposableAmount,
  ] = useState(null);

  useEffect(() => {
    if (activeApplicationListItem) {
      const { creditApplication } = activeApplicationListItem || {};
      const { estimatedDisposableAmount } = creditApplication || {};
      setCurrentEstimatedDisposableAmount(estimatedDisposableAmount);
    }
  }, [activeApplicationListItem]);

  const getBudgetType = (index) => {
    switch (index) {
      case 0:
        return 'reference';
      case 1:
        return 'revised';
      case 2:
        return 'clientFinal';
      case 3:
        return 'final';
      default:
        return '';
    }
  };

  // reset budgets when new application is selected
  useEffect(() => {
    setCombinedBudgetsArray([]);
    setCategories([]);

    const combinedBudgets = {};
    // Iterate through each array
    [referenceBudget, revisedBudget, clientFinalBudget, finalBudget].forEach(
      (arr, budgetIndex) => {
        arr?.forEach((item) => {
          // Check if the type exists in the combined array
          if (!combinedBudgets[item.type]) {
            combinedBudgets[item.type] = {
              type: item.type,
              values: [],
            };
          }
          // Push the value to the corresponding type in the combined array
          combinedBudgets[item.type].values.push({
            amount: item?.value || 0,
            from: getBudgetType(budgetIndex),
          });
          combinedBudgets[item.type].current = {
            final: item?.value || 0,
            datasource: 'FINAL',
          };
        });
      }
    );
    const resultArray = Object.values(combinedBudgets);
    setCombinedBudgetsArray(resultArray);
  }, [creditApplicationUuid]);

  useEffect(() => {
    let netIncomeInputs = [],
      livingInputs = [],
      transportInputs = [],
      debtInputs = [],
      householdInputs = [],
      othersInputs = [];
    let mergedCategories = [];
    let netIncomeInputsTotal = 0,
      livingInputsTotal = 0,
      transportInputsTotal = 0,
      debtInputsTotal = 0,
      householdInputsTotal = 0,
      othersInputsTotal = 0;
    if (combinedBudgetsArray?.length > 0) {
      combinedBudgetsArray.forEach((item) => {
        const finalAmount = item?.current?.final || 0;
        switch (item?.type) {
          case 'NET_INCOME_TOTAL':
          case 'HOUSING_BENEFIT':
          case 'CHILD_BENEFIT':
          case 'CHILDREN_CHECK_AMOUNT':
          case 'ADDITIONAL_INCOME':
            netIncomeInputs.push(item);
            netIncomeInputsTotal += finalAmount;
            break;
          case 'HOUSE_LOAN_EXPENSES':
          case 'MORTGAGE_EXPENSES':
          case 'COMMON_FIXED_EXPENSES':
          case 'MAINTENANCE':
          case 'REAL_ESTATE_INSURANCE':
          case 'RENTAL_EXPENSES':
          case 'ELECTRICITY':
          case 'HEATING_EXPENSES':
          case 'WATER':
            livingInputs.push(item);
            livingInputsTotal += finalAmount;
            break;
          case 'TRAIN_BUS_CARD':
          case 'TRANSPORT_FUEL_EXPENSES':
          case 'CAR_LEASING_COST':
          case 'CAR_LOAN':
          case 'CAR_INSURANCE':
          case 'CAR_MAINTENENCE':
          case 'CAR_OWNERSHIP_TAXES':
          case 'FDMAAA':
            transportInputs.push(item);
            transportInputsTotal += finalAmount;
            break;
          case 'EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN':
          case 'STUDENT_LOAN_EXPENSES':
          case 'DebtSinceLastTaxReport':
            debtInputs.push(item);
            debtInputsTotal += finalAmount;
            break;
          case 'HOUSEHOLD':
            householdInputs.push(item);
            householdInputsTotal += finalAmount;
            break;
          case 'SPLIT_EXPENSES':
            return;
          case 'PHONE_SUBSCRIPTION':
            othersInputs.push(item);
            othersInputsTotal += finalAmount;
            break;
          default:
            othersInputs.push(item);
            othersInputsTotal += finalAmount;
        }
      });

      const addCategoryToBudgetDisplay = (headline, totalAmount, inputs) => {
        mergedCategories.push({
          headline,
          totalAmount: totalAmount || 0,
          inputs,
        });
      };

      addCategoryToBudgetDisplay(
        strings.CATEGORY_NET_INCOME,
        netIncomeInputsTotal,
        netIncomeInputs
      );
      addCategoryToBudgetDisplay(
        strings.CATEGORY_LIVING,
        livingInputsTotal,
        livingInputs
      );
      addCategoryToBudgetDisplay(
        strings.CATEGORY_TRANSPORT,
        transportInputsTotal,
        transportInputs
      );
      addCategoryToBudgetDisplay(
        strings.CATEGORY_DEBT,
        debtInputsTotal,
        debtInputs
      );
      addCategoryToBudgetDisplay(
        strings.CATEGORY_OTHERS,
        othersInputsTotal,
        othersInputs
      );
      addCategoryToBudgetDisplay(
        strings.CATEGORY_HOUSEHOLD,
        householdInputsTotal,
        householdInputs
      );
      setCategories(mergedCategories);
    }
  }, [combinedBudgetsArray]);

  const saveUpdatedBudget = () => {
    setShowUpdateBudgetLoading(true);
    if (combinedBudgetsArray.length > 0) {
      const budgetMap = combinedBudgetsArray.reduce((acc, item) => {
        let referenceAmount = null,
          revisedAmount = null,
          clientFinalAmount = null;
        item.values?.forEach((val) => {
          switch (val?.from) {
            case 'reference':
              referenceAmount = val.amount;
              break;
            case 'revised':
              revisedAmount = val.amount;
              break;
            case 'clientFinal':
              clientFinalAmount = val.amount;
              break;
            default:
              break;
          }
        });
        if (item.current.datasource === 'CUSTOMER_SERVICE') {
          acc[item.type] = {
            reference: referenceAmount,
            psd2: revisedAmount,
            clientFinal: clientFinalAmount,
            _final: item.current.final,
            datasource: item.current.datasource,
          };
        }
        return acc;
      }, {});
      const newBudgetConclusionDTO = {
        budgetMap: budgetMap,
        budgetType: 'FINAL',
        leadUuid,
      };

      dispatch(updateApplicationBudgetItem(newBudgetConclusionDTO))
        .then((res) => {
          setTimeout(() => {
            setShowUpdateBudgetLoading(false);
            const updatedEstimatedDisposableAmount =
              res?.payload?.data?.estimatedDisposableAmount;
            if (updatedEstimatedDisposableAmount) {
              setCurrentEstimatedDisposableAmount(
                updatedEstimatedDisposableAmount
              );
            }
          }, 500);
        })
        .catch(() => {
          setShowUpdateBudgetLoading(false);
        });
    }
  };

  return (
    <section className='budget-card custom-card card'>
      <div className='card-body' id='budget-section'>
        <div className='headline-section'>
          {strings.documentationBudgetHeader}
        </div>

        <div className='detailsArea'>
          <CSSTransition
            in={true}
            timeout={3000}
            classNames='fade'
            unmountOnExit
          >
            <div className='table-wrapper'>
              <div className='table-header'>
                <span className='item'></span>
                <span className='status'>
                  {strings.applicationBudgetStatus}
                </span>
                <span className='reference'>
                  {strings.applicationBudgetReference}
                </span>
                <span className='bank-data'>
                  {strings.applicationBudgetBankData}
                </span>
                <span className='client'>
                  {strings.applicationBudgetClientEntered}
                </span>
                <span className='deviation'>%</span>
                <span className='split-expenses'>
                  {strings.applicationBudgetSplit}
                </span>
                <span className='final'>{strings.applicationBudgetFinal}</span>
              </div>
              <div className='table-content'>
                {categories.length > 0 ? (
                  categories.map((section, index) => (
                    <BudgetCategory
                      key={index}
                      headline={section?.headline}
                      totalAmount={section?.totalAmount}
                      budgetItems={section?.inputs}
                      budgetMap={combinedBudgetsArray}
                      setBudgetMap={setCombinedBudgetsArray}
                      setEnableButton={setEnableButton}
                    />
                  ))
                ) : (
                  <div className='no-data'>
                    {strings.applicationBudgetNoData}
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>

          {/* SAVE BUDGET FINAL BTN */}
          <div className='budget-save-btn text-center'>
            <Button
              color='blue'
              disabled={!enableButton}
              onClick={() => {
                saveUpdatedBudget();
              }}
            >
              {strings.applicationBudgetSaveBtn}
            </Button>
          </div>

          <div className='conclusions-section'>
            <div className='left-side'>
              <DisposableAmount />
              <span>{strings.applicationBudgetDisposableAmount}</span>
            </div>
            <div className='right-side'>
              <div>
                {strings.applicationBudgetDisposableCalculated}{' '}
                <b className='calculated-amount'>
                  {currentEstimatedDisposableAmount
                    ? formatCurrencyDaStandard(currentEstimatedDisposableAmount)
                    : '-'}
                </b>
              </div>
              <div className='small'>
                {strings.applicationBudgetInformedByApplicant}{' '}
                <span>
                  {statedDisposableAmount
                    ? formatCurrencyDaStandard(statedDisposableAmount)
                    : '-'}
                </span>
              </div>
            </div>
          </div>

          {showUpdateBudgetLoading && (
            <UpdateBudgetLoading
              closeModal={() => setShowUpdateBudgetLoading(false)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default BudgetDisplay;
