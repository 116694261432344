import {
  GET_LEAD_STATUSES,
  SET_LEAD_ACTIVE_STATUS,
  GET_COMPANY_STATUSES,
  SET_COMPANY_ACTIVE_STATUS,
  GET_COMPANY_CHAIN_STATUSES,
  SET_COMPANY_CHAIN_ACTIVE_STATUS,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_LEAD_STATUSES:
      return { ...state, leadStatuses: action.payload };
    case SET_LEAD_ACTIVE_STATUS:
      return { ...state, leadStatusActive: action.payload };
    case GET_COMPANY_STATUSES:
      return { ...state, companyStatuses: action.payload };
    case SET_COMPANY_ACTIVE_STATUS:
      return { ...state, companyStatusActive: action.payload };
    case GET_COMPANY_CHAIN_STATUSES:
      return { ...state, companyChainStatuses: action.payload };
    case SET_COMPANY_CHAIN_ACTIVE_STATUS:
      return { ...state, companyChainStatusActive: action.payload };
    default:
      return state;
  }
}
