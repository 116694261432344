import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { success, error as notifError } from 'react-notification-system-redux';
import ButtonLoader from '../../../common/ButtonLoader/ButtonLoader';
import { strings } from '../../../../constants/localization';
import { notificationDismissDuration } from '../../../../constants';
import { addNotification, isValidURL } from '../../../../constants/utils';
import { updateCompanyProfile } from '../../../../actions/companyActions';
import { getLogsforCompanies } from '../../../../actions/logActions';

const BookingLink = (props) => {
  const { referenceNumber, companyName } = props.companyDetails;

  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const [bookingLink, setBookingLink] = useState('');
  const [bookingLinkError, setBookingLinkError] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputIsTouched, setInputIsTouched] = useState(false);
  const [formIsTouched, setFormIsTouched] = useState(false);

  useEffect(() => {
    const bookingLink = props.companyDetails?.bookingLink;

    if (bookingLink) {
      if (bookingLink.trim() === 'disabled') {
        setSelectedOption('3');
        setBookingLink('');
      } else {
        setSelectedOption('2');
        setBookingLink(bookingLink);
      }
    } else {
      setSelectedOption('1');
      setBookingLink('');
    }
    setFormIsTouched(false);
  }, [props.companyDetails]);

  const updateCompanyProfileData = () => {
    const errors = {};
    let bookingValueToSend;
    switch (selectedOption) {
      case '1':
        bookingValueToSend = '';
        break;
      case '2':
        bookingValueToSend = bookingLink;
        if (bookingLink && !isValidURL(bookingLink)) {
          errors.bookingLink = strings.invalidURL;
          setBookingLinkError(errors.bookingLink);
        }
        break;
      case '3':
        bookingValueToSend = 'disabled';
        break;
      default:
        break;
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        bookingLink: bookingValueToSend,
        referenceNumber: referenceNumber,
      };
      dispatch(updateCompanyProfile(data))
        .then((result) => {
          if (result?.status === 200) {
            setLoading(false);
            dispatch(
              success(
                addNotification(
                  strings.formatString(strings.editCompanySuccess, companyName),
                  strings.editCompanySuccessTitle,
                  notificationDismissDuration
                )
              )
            );
            dispatch(getLogsforCompanies(data.referenceNumber));
          }
        })
        .catch(() => {
          dispatch(
            notifError(
              addNotification(
                strings.errorEditCompany,
                strings.errorEditCompanyTitle,
                notificationDismissDuration
              )
            )
          );
        });
    } else {
      return;
    }
  };

  const onRadioChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === '2') {
      if (inputIsTouched) {
        setFormIsTouched(true);
      } else {
        setFormIsTouched(false);
      }
    } else {
      setFormIsTouched(true);
    }
  };

  return (
    <div id='BookingLink' className='profile-component custom-card'>
      <div className='wrapper'>
        <div className='descriptionWraper d-flex justify-content-between'>
          <div className='d-flex '>
            <div className='titleArea'>{strings.companyProfileBookingLink}</div>
          </div>
        </div>
        <div className='mt-4 row'>
          <div className='form-wrapper col-12 col-md-12'>
            <Row className='mb-15'>
              <Col xs='12' md='12' className='col'>
                <p className='usage-description'>
                  {strings.companyBookingLinkUsage}
                </p>
              </Col>
            </Row>
            <div className='booking-options'>
              <div
                className={`booking-option ${
                  selectedOption === '1' ? 'selected' : ''
                } ${props.companyDetails?.email === '' ? 'disabled' : ''}`}
              >
                <label>
                  <input
                    type='radio'
                    name='radio-button'
                    value='1'
                    checked={selectedOption === '1'}
                    onChange={onRadioChange}
                    disabled={props.companyDetails?.email === ''}
                  />
                  <span>{strings.companyBookingLinkOption1Label}</span>
                </label>
                <p className='option-description'>
                  {strings.companyBookingLinkOption1Text}
                </p>
              </div>
              <div
                className={`booking-option ${
                  selectedOption === '2' ? 'selected' : ''
                }`}
              >
                <label>
                  <input
                    type='radio'
                    name='radio-button'
                    value='2'
                    checked={selectedOption === '2'}
                    onChange={onRadioChange}
                  />
                  <span>{strings.companyBookingLinkOption2Label}</span>
                </label>
                <p className='option-description'>
                  {strings.companyBookingLinkOption2Text}
                  <input
                    type='text'
                    name='url'
                    id='bookingURL'
                    placeholder='https://'
                    value={bookingLink}
                    onChange={(evt) => {
                      setBookingLink(evt.target.value);
                      setInputIsTouched(true);
                      setFormIsTouched(true);
                      setBookingLinkError('');
                    }}
                  />
                  {bookingLinkError && (
                    <span className='error'>{bookingLinkError}</span>
                  )}
                </p>
              </div>
              <div
                className={`booking-option ${
                  selectedOption === '3' ? 'selected' : ''
                }`}
              >
                <label>
                  <input
                    type='radio'
                    name='radio-button'
                    value='3'
                    checked={selectedOption === '3'}
                    onChange={onRadioChange}
                  />
                  <span>{strings.companyBookingLinkOption3Label}</span>
                </label>
                <p className='option-description'>
                  {strings.companyBookingLinkOption3Text}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='text-center'>
          {!loading && (
            <Button
              color='blue'
              disabled={!formIsTouched}
              onClick={() => updateCompanyProfileData()}
            >
              <i className='di di-save' />
              {strings.companyProfileSaveBtn}
            </Button>
          )}
          {loading && (
            <Button color='blue'>
              <ButtonLoader />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingLink;
