import React from 'react';
import { strings } from '../../constants/localization';

const EmptyMessage = (props) => (
  <div className='emptyMessage'>
    <div>{props.message}</div>
    <br />
    {props.subtitle ? (
      <div className='subtitle'>
        <span className='first-word'>{strings.tipCategoryTitle}</span>
        {props.subtitle}
      </div>
    ) : (
      ''
    )}
  </div>
);

export default EmptyMessage;
