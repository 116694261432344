import React from 'react';
import { CardBody } from 'reactstrap';
import { moment } from '../../constants';
import { strings } from '../../constants/localization';
import DigitallyApprovedBadge from '../../resources/images/digitally-approved.svg';
import WhiteShieldIcon from '../../resources/images/shield-alt-2-check.svg';
import uuidByString from 'uuid-by-string';

const agreementSignatureStyles = {
  width: '350px',
  display: 'block',
  margin: '20px auto',
  flex: 'none',
};

const agreementSignatureTopStyles = {
  background:
    'linear-gradient(180deg, rgba(254,250,245,1) 0%, rgba(254,245,236,1) 100%)',
  border: '1px solid #ececec',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  padding: '10px',
  color: '#6A6A6A',
};

const lineStyles = {
  marginTop: '10px',
  marginBottom: '15px',
};

const lineLastStyles = {
  marginTop: '10px',
  display: 'flex',
};

const contentStyles = {
  fontWeight: 'bold',
  fontSize: '14px',
};

const contentLastStyles = {
  fontWeight: 'bold',
  fontSize: '10px',
  marginLeft: '8px',
};

const labelStyles = {
  fontSize: '12px',
  marginBottom: '8px',
};

const labelLastStyles = {
  fontSize: '10px',
};

const badgeVerifiedStyles = {
  backgroundColor: '#375CDB',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '20px',
  margin: '8px 0',
  padding: '3px 12px',
  color: '#D9E1F8',
  fontSize: '10px',
};

const badgeVerifiedIconStyles = {
  width: '12px',
  marginRight: '4px',
  // icon color had to be changed inside the svg file
};

const bottomStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  background:
    'linear-gradient(90deg, rgba(209,241,228,1) 0%, rgba(225,248,238,1) 100%)',
  border: '1px solid #B1E6CF',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  padding: '10px',
  color: '#63B58E',
};

const badgeImgStyles = {
  display: 'block',
  width: '80px',
  height: '80px',
};

const bottomTextStyles = {
  marginLeft: '10px',
  fontSize: '13px',
  lineHeight: '18px',
};

const bottomTextPStyles = {
  marginTop: '2px',
  marginBottom: '0px',
};

const PaymentAgreementSignature = ({ invoiceDetails, signatureData }) => {
  const { firstName, lastName, email } = invoiceDetails?.client || {};
  const fullName = `${firstName} ${lastName}`;
  const { deviceInfo } = signatureData?.invoiceSignatureData || {};
  const { ip, createdDate, countryName, city, zip, latitude, longitude } =
    signatureData?.invoiceIpData || {};

  // Generate UUID from the user agent string
  const locationGroup = `(${latitude ? Number(latitude).toFixed(4) : ''} ${
    longitude ? Number(longitude).toFixed(4) : ''
  })`;

  const location = `${locationGroup}, ${zip || ''}, ${city || ''}, ${
    countryName || ''
  }`;
  const userAgent = deviceInfo + ' ' + location || '';
  const deviceSignature = uuidByString(userAgent, 5);

  // check if the invoiceDetails startDate is after 31st October 2023 and the creditFacility startedDate is after 31st October 2023
  const isMitIdVerificationOnly =
    moment(invoiceDetails.startDate).isAfter('2023-10-31') &&
    moment(invoiceDetails.creditFacility?.startedDate).isAfter('2023-10-31');

  return (
    <CardBody className='agreement-signature' style={agreementSignatureStyles}>
      <div className='top' style={agreementSignatureTopStyles}>
        <div className='line' style={lineStyles}>
          <div className='label' style={labelStyles}>
            {strings.paymentAgreementSignatureLabel1}
          </div>
          <div className='content' style={contentStyles}>
            {fullName}
          </div>
          <div className='badge-verified' style={badgeVerifiedStyles}>
            <img
              src={WhiteShieldIcon}
              style={badgeVerifiedIconStyles}
              alt='shield'
            />

            {isMitIdVerificationOnly
              ? strings.paymentAgreementSignatureTopBtnTextMitID
              : strings.paymentAgreementSignatureTopBtnTextNemIdAndMitId}
          </div>
        </div>
        <div className='line' style={lineStyles}>
          <div className='label' style={labelStyles}>
            {strings.paymentAgreementSignatureLabel2}
          </div>
          <div className='content' style={contentStyles}>
            {email || ''}
          </div>
        </div>
        <div className='line' style={lineStyles}>
          <div className='label' style={labelStyles}>
            {strings.paymentAgreementSignatureLabel3}
          </div>
          <div className='content' style={contentStyles}>
            {ip || ''}
          </div>
        </div>

        <div className='line' style={lineLastStyles}>
          <div className='label' style={labelLastStyles}>
            {strings.paymentAgreementSignatureLabel4}
          </div>
          <div className='content' style={contentLastStyles}>
            {deviceSignature}
          </div>
        </div>
      </div>
      <div className='bottom' style={bottomStyles}>
        <div className='svg-conatiner'>
          <img
            src={DigitallyApprovedBadge}
            style={badgeImgStyles}
            alt='Digital Approved Badge'
            className='approved-badge'
          />
        </div>
        <div className='text' style={bottomTextStyles}>
          <p style={bottomTextPStyles}>
            <strong>
              {strings.formatString(
                strings.paymentAgreementSignatureBottomText1,
                fullName
              )}
            </strong>
          </p>
          <p style={bottomTextPStyles}>
            {strings.formatString(
              strings.paymentAgreementSignatureBottomText2,
              createdDate
                ? moment(createdDate, 'YYYY-MM-DD').format('D. MMMM YYYY')
                : '',
              createdDate ? moment(createdDate).format('HH:mm:ss') : ''
            )}
          </p>
        </div>
      </div>
    </CardBody>
  );
};

export default PaymentAgreementSignature;
