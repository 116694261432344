import React from 'react';
import './style.scss';

const SwitchWithText = ({ isOn, handleToggle, onColor, onText, offText }) => {
  return (
    <label style={{ background: isOn && onColor }} className='react-switch'>
      <input
        checked={isOn}
        onChange={handleToggle}
        className='react-switch-checkbox'
        type='checkbox'
      />
      <div className='react-switch-button' />
      <div className='react-switch-labels'>
        <span className={isOn ? 'on' : ''}>{onText}</span>
        <span className={!isOn ? 'off' : ''}>{offText}</span>
      </div>
    </label>
  );
};

export default SwitchWithText;
