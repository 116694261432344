import React from 'react';
import { ReactComponent as LoadingGif } from '../../resources/images/loading.svg';

const LoadingMessage = ({ message = '' }) => (
  <div className='h-100 d-flex align-items-center justify-content-center loading-message '>
    {' '}
    <LoadingGif className='loadingGif' />
    {message ? <div className='loadingMessage'>{message}</div> : null}
  </div>
);

export default LoadingMessage;
