import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { setCurrentCompanyRefFromRouter } from '../../../../actions/companyActions';
import { moment } from '../../../../constants';
import { strings } from '../../../../constants/localization';
import { currencyFormatDA } from '../../../../constants/utils';
import { ROUTE_COMPANIES } from '../../../../constants/routes';
import DentiModal from '../../../common/DentiModal';
import AddCompanyLocation from '../../../add-company-location/AddCompanyLocation';
import AddUserModal from '../ListOfAssociatedUsers/AddUser/AddUserModal';
import './style.scss';
import LoadingMessage from '../../../statusMessages/LoadingMessage';

function ListOfCompanyChains({ activeChain, updateLocations }) {
  const [addLocation, setAddLocation] = useState(false);
  const [createNewUser, setCreateNewUser] = useState(false);
  const dispatch = useDispatch();
  const chainCompanies =
    useSelector((state) => state.companyChain?.chainLocations) || [];
  const chainLocationsLoading =
    useSelector((state) => state.companyChain?.chainLocationsLoading) || false;

  return (
    <Card className='custom-card lead-information list-of-company-chains table-responsive-md'>
      <div className='chains-table table-size'>
        <div className='header_wrapper'>
          <div className='lead_wrapper'>
            <div className='name-field'>{strings.name}</div>
            <div className='create-date-field'>{strings.companyCreateDate}</div>
            <div className='reference-field'>{strings.invoiceLead}</div>
            <div className='status-field'>{strings.invoiceStatus}</div>
            <div className='revenue-field'>{strings.chainRevenue}</div>
            <div className='seeDetailsButton' />
          </div>
        </div>
        <div className='table-separator ' />
        <div className='contentWrapper '>
          {chainCompanies && chainLocationsLoading ? (
            <LoadingMessage />
          ) : (
            chainCompanies.length > 0 &&
            chainCompanies?.map((company, i) => (
              <div className='lead_row' key={`chain-${i}`}>
                <div className='name-field'>{company.companyName}</div>
                <div className='create-date-field lowercase'>
                  {company.createdDate
                    ? moment(company.createdDate, 'YYYY-MM-DD').format(
                        'D. MMM YYYY'
                      )
                    : ''}
                </div>
                <div className='reference-field'>{company.referenceNumber}</div>
                <div className='status-field'>
                  {company.status === 'PASSIVE'
                    ? strings.PASSIVE_OUT_OF_COMPONENT_STATUS
                    : strings[company.status]}
                </div>
                <div className='revenue-field'>
                  {currencyFormatDA(
                    company.totalInvoiceAmount,
                    true,
                    true,
                    company.totalInvoiceAmount % 1 !== 0
                  )}
                </div>
                <div className='seeDetailsButton'>
                  <Link
                    to={{
                      pathname: ROUTE_COMPANIES,
                      search: company?.referenceNumber
                        ? `?q=${company.referenceNumber}`
                        : '',
                    }}
                    onClick={() =>
                      dispatch(
                        setCurrentCompanyRefFromRouter(company.referenceNumber)
                      )
                    }
                  >
                    <Button color='light' className='btn-small'>
                      <i className='lnir lnir-house-heart' />
                      {strings.seeDetails}
                    </Button>
                  </Link>
                </div>
              </div>
            ))
          )}

          <div className='button-area'>
            {!chainLocationsLoading && (
              <Button
                color='blue'
                onClick={() => {
                  setAddLocation(true);
                }}
              >
                <i className='lnir lnir-circle-plus' />
                {strings.addLoaction}
              </Button>
            )}
            {addLocation && (
              <DentiModal
                key={1}
                close={() => {
                  setAddLocation(false);
                }}
                modalClass='new-location-modal'
                modalClassName='new-location-action'
              >
                <AddCompanyLocation
                  close={() => {
                    setAddLocation(false);
                  }}
                  createNewUserHandler={() => {
                    setAddLocation(false);
                    setCreateNewUser(true);
                  }}
                  parentCvr={activeChain.cvrNumber}
                  chainData={activeChain}
                  update={updateLocations}
                />
              </DentiModal>
            )}
            {createNewUser && (
              <AddUserModal
                closeModal={() => {
                  setCreateNewUser(false);
                }}
                selectedUser={{}}
                companiList={chainCompanies}
                companyChainId={activeChain.id}
                editMode={false}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

ListOfCompanyChains.propTypes = {
  chains: PropTypes.array,
  activeChain: PropTypes.object,
};

export default ListOfCompanyChains;
