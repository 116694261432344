// src/actions/memberPortalActions.ts
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { requestAxios as axios } from '../constants/utils';
import {
  SERVER_URL,
  CREATE_MIT_ID_STATE,
  GET_CLIENT_CREDIT_DATA_FOR_MEMBER_PORTAL,
  LOADING_CLIENT_PORTAL_DATA,
  CLIENT_IS_AUTHENTICATED,
  CLEAR_MEMBER_PORTAL_DATA,
  IS_CLIENT_LOGOUT,
  IS_NOT_CLIENT,
  GET_CLIENT_PAYMENT_PLAN,
  ACCEPTANCE_FLOW_MODAL_STATE,
  PARTIAL_REDEMPTION_RESPONSE,
} from '../constants';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export function createMitIdState(): AnyAction {
  const request = axios({
    method: 'GET',
    url: `${SERVER_URL}/public/v1/mitId/getState`,
    withCredentials: true,
  });

  return {
    type: CREATE_MIT_ID_STATE,
    payload: request,
  };
}

export function loadingClientPortalData(state = true): AnyAction {
  return {
    type: LOADING_CLIENT_PORTAL_DATA,
    payload: state,
  };
}

export function clientIsAuthenticated(state = false): AnyAction {
  return {
    type: CLIENT_IS_AUTHENTICATED,
    payload: state,
  };
}

export function isClientLogout(state = false): AnyAction {
  return {
    type: IS_CLIENT_LOGOUT,
    payload: state,
  };
}

export function openAcceptanceFlowModalFunction(state = false): AnyAction {
  return {
    type: ACCEPTANCE_FLOW_MODAL_STATE,
    payload: state,
  };
}

export const createMitIdSession =
  (result: {
    id_token: string;
    error: string;
  }): ThunkAction<Promise<AxiosResponse<unknown>>, object, object, AnyAction> =>
  async (dispatch) => {
    const { mitIdState } = store.getState().memberPortal || {};
    // eslint-disable-next-line camelcase
    const { id_token } = result;

    try {
      const response = await axios({
        method: 'GET',
        url: `${SERVER_URL}/public/v1/mitId/createSession`,
        withCredentials: true,
        params: {
          // eslint-disable-next-line camelcase
          id_token,
          state: mitIdState,
        },
      });

      return response;
    } catch (error) {
      console.error('Error in createMitIdSession:', error);
      dispatch(isNotClient(true)); // Handle as CLIENT_NOT_FOUND on error
      throw error;
    }
  };

export function getClientPaymentPlan(loanNumber: string): AnyAction {
  const request = axios({
    method: 'GET',
    url: `${SERVER_URL}/public/v1/mitId/paymentPlan/${loanNumber}`,
    withCredentials: true,
  });

  return {
    type: GET_CLIENT_PAYMENT_PLAN,
    payload: request,
  };
}

export const downloadCreditTermsPDF =
  (): ThunkAction<Promise<AxiosResponse<unknown>>, object, object, AnyAction> =>
  async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${SERVER_URL}/public/v1/mitId/downloadCreditTermsPDF`,
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export function isNotClient(state = false): AnyAction {
  return {
    type: IS_NOT_CLIENT,
    payload: state,
  };
}

export const getClientCreditData =
  (): ThunkAction<Promise<AxiosResponse<unknown>>, object, object, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${SERVER_URL}/public/v1/mitId/getClientCreditData`,
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_CLIENT_CREDIT_DATA_FOR_MEMBER_PORTAL,
          payload: response,
        });
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export function clearMemberPortalData(): AnyAction {
  return {
    type: CLEAR_MEMBER_PORTAL_DATA,
    payload: null,
  };
}

export function partialRedemptionPayment(
  leadUuid: string,
  creditFacilityNumber: string,
  partialRedemptionDTO: {
    duration: number;
    extraPayment: number;
    reepayInvoiceHandleText: '';
    setupFee: 0;
  }
): ThunkAction<Promise<AxiosResponse<unknown>>, object, object, AnyAction> {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${SERVER_URL}/public/v1/mitId/partialRedemption`,
        params: {
          leadUuid,
          creditFacilityNumber,
        },
        data: partialRedemptionDTO,
      });
      if (response.status === 200) {
        dispatch({
          type: PARTIAL_REDEMPTION_RESPONSE,
          payload: response,
        });
        return response;
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
