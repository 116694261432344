import React from 'react';
import { moment } from '../../constants';

import { strings } from '../../constants/localization';
import './style.scss';

export const TimeSlot = ({ timeSlot, requestedDates }) => {
  return (
    <>
      {timeSlot ? (
        <>
          <div className='info-header upper-case'>
            {strings.noTimeSlotHeadLine}
          </div>
          <div className='booking-comment'>
            <div className='capitalize-me'>{strings.noTimeSlot}</div>
          </div>
        </>
      ) : (
        <>
          <div className='info-header timeRange-title'>
            {strings.time_ranges}
          </div>
          {requestedDates.map((datesRange, index) => (
            <div className='timeRange-list' key={index}>
              <div className='time-list capitalize-me'>
                {moment(datesRange.date, 'YYYY-MM-DD').format('dddd')}
                {strings.date_abbreviation}
                {moment(datesRange.date, 'YYYY-MM-DD').format('DD/MM')}
                {', '}
                {strings.time_abbreviation}
                {datesRange.from}
                {' - '}
                {datesRange.to}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
