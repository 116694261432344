import React, { useRef, ReactNode } from 'react';
import { Card } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  trackThinVerticalStyle,
  thumbVerticalStyle,
} from '../../../constants/styleScrollbar';
import { currencyFormatDA } from '../../../constants/utils';
import { strings } from '../../../constants/localization';

interface PaymentAgreement {
  createdDate?: string;
  montlhyPayment?: number;
  interestRate?: number;
  remainingBalance?: number;
  loanAmount?: number;
  companyChain?: string;
  status?: string;
}

interface EntityTableComponentProps {
  tableList?: PaymentAgreement[];
  tableHeaders?: string[];
  title?: string;
  totalDebtLabel?: string;
  totalDebtAmount?: number;
  children?: ReactNode;
  activePaymentAgreement?: boolean;
  tableRowClass?: string;
  cardClass?: string;
  practitionerUsage?: boolean;
  tableEmptyList?: string;
}

const EntityTableComponent: React.FC<EntityTableComponentProps> = ({
  tableList,
  tableHeaders,
  title,
  totalDebtLabel,
  totalDebtAmount,
  children,
  activePaymentAgreement,
  tableRowClass,
  cardClass,
  practitionerUsage,
  tableEmptyList,
}) => {
  const scrollRef = useRef<Scrollbars>(null);

  return (
    <Card
      className={`tableInformation custom-card mb-3 ${
        cardClass ? cardClass : ''
      }`}
    >
      {title && (
        <div className='header-information agreement my-3'>
          <div className='title'>{title}</div>
        </div>
      )}
      <div
        className={`custom-table agreement table-wrapper ${
          tableList.length === 0 ? 'empty' : ''
        }`}
      >
        <iframe id='iframeContentToPrint' title='iframeContentToPrint' />
        <div className='table-header'>
          <div
            className={`table-row-div ${tableRowClass ? tableRowClass : ''}`}
          >
            {tableHeaders.map((item, index) => (
              <div key={index} className='table-cell-div'>
                {item}
              </div>
            ))}
            {!tableRowClass && <div className='table-cell-div'></div>}
          </div>
        </div>
        {tableList && tableList.length > 0 && (
          <div
            className={`table-body invoices-list invoices-list-${
              tableList.length > 5 ? 'scroll' : tableList.length
            }`}
          >
            {tableList.length > 5 ? (
              <Scrollbars
                ref={scrollRef}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{ ...style, ...trackThinVerticalStyle }}
                  />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
                )}
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    style={{ display: 'none' }}
                    className='track-horizontal'
                  />
                )}
              >
                <div className='scrollbar-wrapper'>{children}</div>
              </Scrollbars>
            ) : (
              <div className='scrollbar-wrapper'>{children}</div>
            )}
          </div>
        )}
        {tableList.length === 0 && (
          <>
            <div
              className={`table-body invoices-list invoices-list-${
                tableList.length > 5 ? 'scroll' : tableList.length
              }`}
            >
              <div className='empty-table center'>
                <span>
                  {tableEmptyList ? tableEmptyList : strings.tableEmptyList}
                </span>
              </div>
            </div>
            <div
              className={`card-table-footer agreement ${
                tableList.length > 5 ? 'has-scroll' : ''
              } `}
            ></div>
          </>
        )}

        {!practitionerUsage && (
          <div
            className={`card-table-footer agreement ${
              tableList.length > 5 ? 'has-scroll' : ''
            } `}
          ></div>
        )}
      </div>
      {activePaymentAgreement && (
        <div className='total-outstanding-debt container'>
          <span className='label'>{totalDebtLabel}</span>
          <span className='remaining-amount'>
            <i className='lnir lnir-coin' />
            {currencyFormatDA(totalDebtAmount, true, true)}
          </span>
        </div>
      )}
    </Card>
  );
};

export default EntityTableComponent;
