import { success, error as notifError } from 'react-notification-system-redux';
import FileSaver from 'file-saver';
import { store } from '../store';
import {
  requestAxios as axios,
  addNotification,
  isManager,
  isAdmin,
  isDentist,
} from '../constants/utils';
import { strings } from '../constants/localization';
import { getLoggedInUserCredentials } from './userActions';
import {
  leadSidebarActiveStatus,
  companySidebarActiveStatus,
} from './statusActions';

import {
  SERVER_URL,
  GET_COMPANIES,
  GET_COMPANIES_FOR_CHAIN,
  SET_CURRENT_COMPANY,
  SET_CURRENT_MANAGER_COMPANY,
  GET_CURRENT_DENTIST_COMPANY,
  UPDATE_COMPANY_INFORMATION,
  SET_COMPANY_INTERNAL_NOTE,
  UPDATE_COMPANY_STATUS,
  COMPANY_SEARCH_EMITTED,
  COMPANY_UPDATE_FILTER,
  SET_COMPANY_ACTIVE_PAGE,
  VIEW_ALL,
  DISPLAY_LEADS_FOR_COMPANY,
  EDIT_COMPANY_LOADING,
  COMPANIES_EMPTY,
  COMPANIES_LOADING,
  COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  notificationDismissDuration,
  GET_ALL_FILTERED_COMPANIES,
  GET_COMPANIES_LIST_FOR_MANAGERS,
  GET_ALL_COMPANIES_LIST_FOR_MANAGERS,
  GET_ALL_PUBLIC_COMPANY_CATEGORIES,
  GET_COMPANY_CHAIN_STATUS,
  GET_SIMPLE_SEARCH_CLINIC_PAGINATED,
  DELETE_COMPANY,
  UPDATE_COMPANY_PROFILE,
  ADD_TO_BLACKLIST,
  SET_CURRENT_COMPANY_REF_FROM_ROUTER,
  UPDATE_ACTIVE_COMPANY_ITEM_LIST,
  SET_COMPANY_LOADING,
  UPDATE_COMPANY_MANAGER_STATUS,
  UPDATE_COMPANY_PROFILE_FOR_MANAGER,
  UPDATE_COMPANY_MANAGER_LIST,
  UPDATE_COMPANY_CHAIN_OF_COMPANY,
  UPDATE_DENTIST_COMPANY_STATUS,
} from '../constants';

export function setCurrentCompany(company = undefined) {
  return {
    type: SET_CURRENT_COMPANY,
    payload: company,
  };
}

export function setCurrentManagerCompany(company = undefined) {
  return {
    type: SET_CURRENT_MANAGER_COMPANY,
    payload: company,
  };
}

export function setActivePage(activePage) {
  return {
    type: SET_COMPANY_ACTIVE_PAGE,
    payload: activePage,
  };
}

export function setCurrentCompanyRefFromRouter(refNumber) {
  return {
    type: SET_CURRENT_COMPANY_REF_FROM_ROUTER,
    payload: refNumber,
  };
}

export function updateCompanyChainOfCompany(
  companyReferenceNumber,
  newCompanyChainId,
  companyChain
) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/company/updateCompanyChainOfCompany`,
        withCredentials: true,
        data: {
          companyReferenceNumber,
          newCompanyChainId,
        },
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          if (response.status === 200) {
            const { activeCompany } = getState().company;
            let newUpdatedChain = { ...activeCompany, companyChain };
            dispatch({
              type: UPDATE_COMPANY_CHAIN_OF_COMPANY,
              payload: newUpdatedChain,
            });
            dispatch(updateCompanyItemList(Object.assign({}, newUpdatedChain)));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_COMPANY_CHAIN_OF_COMPANY,
            payload: error,
          });
          reject(error);
        });
    });
}

export function companyUpdateInformation(
  companyReferenceNumber,
  companyDetails
) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const user = getState().user.user;
      dispatch(setCompanyLoading(true));
      const data = companyDetails;
      data.referenceNumber = companyReferenceNumber;
      axios({
        method: 'POST',
        url: `${SERVER_URL}/api/v1/company/updateCompanyInfo/`,
        data,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          if (isManager(user)) {
            const { activeManagerCompany } = getState().company;
            let newObjManager = { ...activeManagerCompany, ...data };
            dispatch({
              type: UPDATE_COMPANY_PROFILE_FOR_MANAGER,
              payload: data,
            });
            dispatch(updateCompanyManagerList(newObjManager));
            dispatch(setCompanyLoading(false));
          } else if (isAdmin(user)) {
            const { activeCompany } = getState().company;
            let newObjAdmin = { ...activeCompany, ...data };
            dispatch({
              type: UPDATE_COMPANY_INFORMATION,
              payload: data,
            });

            dispatch(updateCompanyItemList(Object.assign({}, newObjAdmin)));
            dispatch(setCompanyLoading(false));
          } else {
            dispatch({
              type: UPDATE_COMPANY_INFORMATION,
              payload: data,
            });

            dispatch(setCompanyLoading(false));
          }

          resolve(response);
        })
        .catch((error) => {
          if (isAdmin(user)) {
            dispatch({
              type: UPDATE_COMPANY_INFORMATION,
              payload: error,
            });
            console.error(error);
            dispatch(setCompanyLoading(false));
          }
          if (isManager(user)) {
            dispatch({
              type: UPDATE_COMPANY_PROFILE_FOR_MANAGER,
              payload: error,
            });
            console.error(error);
            dispatch(setCompanyLoading(false));
          }

          reject(error);
        });
    });
}

export function updateCompanyStatus(id, status, referenceNumber) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(setCompanyLoading(true));
      const user = getState().user.user;
      const data = {
        id,
        status,
        referenceNumber,
      };

      axios({
        method: 'put',
        url: `${SERVER_URL}/api/v2/company/changeCompanyStatus/id=${id}&status=${status}`,
        data,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          if (response.status === 200) {
            if (isManager(user)) {
              const { activeManagerCompany } = getState().company;
              let newActiveManagerCompany = activeManagerCompany;
              newActiveManagerCompany.status = status;
              dispatch({
                type: UPDATE_COMPANY_MANAGER_STATUS,
                payload: activeManagerCompany,
              });
              dispatch(setCompanyLoading(false));
              dispatch(updateCompanyManagerList(newActiveManagerCompany));
            }
            if (isAdmin(user)) {
              const { activeCompany } = getState().company;
              let newObjn = activeCompany;
              newObjn.status = status;
              dispatch({
                type: UPDATE_COMPANY_STATUS,
                payload: newObjn,
              });
              dispatch(updateCompanyItemList(Object.assign({}, activeCompany)));
              dispatch(setCompanyLoading(false));
            }
            if (isDentist(user)) {
              const { currentDentistCompany } = getState().company;
              let newObjn = currentDentistCompany;
              newObjn.data.status = status;
              dispatch({
                type: UPDATE_DENTIST_COMPANY_STATUS,
                payload: newObjn,
              });

              dispatch(setCompanyLoading(false));
            }
          }
          resolve(response);
        })

        .catch((error) => {
          dispatch({
            type: UPDATE_COMPANY_STATUS,
            payload: error,
          });
          dispatch(setCompanyLoading(false));
          reject(error);
        });
    });
}

export function updateCompanyItemList(newCompanyInfo) {
  return {
    type: UPDATE_ACTIVE_COMPANY_ITEM_LIST,
    payload: newCompanyInfo,
  };
}

export function updateCompanyManagerList(newManagerlist) {
  return {
    type: UPDATE_COMPANY_MANAGER_LIST,
    payload: newManagerlist,
  };
}

export function searchEmitted(search = false) {
  return {
    type: COMPANY_SEARCH_EMITTED,
    payload: { search },
  };
}

export function filterEmitted(filter, deleteFilter = false) {
  let newFilter = {};

  if (!deleteFilter) {
    newFilter = filter;
  }

  return {
    type: COMPANY_UPDATE_FILTER,
    payload: newFilter,
  };
}

export function getCompanies(
  status = null,
  page = 0,
  searchInput = null,
  callback = undefined,
  filter = null
) {
  const paramsRequest = {};

  // status
  if (status !== null && status !== VIEW_ALL) {
    paramsRequest.status = status;
  }

  // pagination
  if (page > 0) {
    paramsRequest.page = page;
  } else {
    paramsRequest.page = 0;
  }

  // search
  if (searchInput !== null && searchInput !== '') {
    if (searchInput !== null && searchInput !== '' && searchInput.length < 12) {
      paramsRequest.searchedInput = searchInput
        .trim()
        .replace(/\s/g, '')
        .split('-')
        .join('');
    } else {
      paramsRequest.searchedInput = searchInput;
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(paramsRequest.searchedInput)) {
      paramsRequest.searchedInput = searchInput;
    }
  }

  // add filter params
  if (filter !== null && Object.keys(filter).length > 0) {
    Object.assign(paramsRequest, { ...filter });
  }

  return async (dispatch, getState) => {
    const { globalLoading } = getState().company;

    dispatch({
      type: COMPANIES_LOADING,
      payload: COMPANIES_LOADING,
    });

    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v5/companies`,
        params: paramsRequest,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      });

      const companiesList = response?.data;

      dispatch({
        type: GET_COMPANIES,
        payload: companiesList,
      });
      if (companiesList?.content.length > 0) {
        dispatch({
          type: COMPANIES_SUCCESS,
          payload: COMPANIES_SUCCESS,
        });
      } else {
        dispatch({
          type: COMPANIES_EMPTY,
          payload: COMPANIES_EMPTY,
        });
      }
      if (globalLoading) {
        // dispatch global loading
        dispatch({
          type: EDIT_COMPANY_LOADING,
          payload: !globalLoading,
        });
      }
      if (callback !== undefined) {
        callback();
      }
    } catch (e) {
      dispatch({
        type: COMPANIES_FAILURE,
        payload: COMPANIES_FAILURE,
      });
      if (globalLoading) {
        // dispatch global loading
        dispatch({
          type: EDIT_COMPANY_LOADING,
          payload: !globalLoading,
        });
      }
    }
  };
}

export function setCompanyLoading(loadingStatus) {
  return {
    type: SET_COMPANY_LOADING,
    payload: loadingStatus,
  };
}

export function getDentistCompany(referenceNumber) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/api/v1/companies/${referenceNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });

  return {
    type: GET_CURRENT_DENTIST_COMPANY,
    payload: request,
  };
}

export function getSimpleSearchClinicPaginated(
  pageNumber,
  searchTerm,
  signal = null
) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/company/companyPaginatedSearch`,
    data: {
      page: pageNumber,
      searchInput: searchTerm,
    },
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    ...(signal && { signal }), // add the signal to the request if it exists; used to abort the request
  });

  return {
    type: GET_SIMPLE_SEARCH_CLINIC_PAGINATED,
    payload: request,
  };
}

export function getCompaniesForCompanyChain(companyChainId) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/api/v1/companyChain/${companyChainId}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });

  return {
    type: GET_COMPANIES_FOR_CHAIN,
    payload: request,
  };
}

export function getCompaniesListForManagers() {
  const user = store.getState().user.user;
  let companiesListActive = [];
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/companyChain/${user.companyChainId}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      });
      if (isManager(user)) {
        if (response.status === 200) {
          if (response?.data?.companyChains[0].companies) {
            companiesListActive =
              response.data.companyChains[0].companies.filter(
                (company) => company.status !== 'DEACTIVE'
              );

            // get the company chain status
            dispatch({
              type: GET_COMPANY_CHAIN_STATUS,
              payload: response?.data?.companyChains[0].status,
            });

            dispatch({
              type: GET_COMPANIES_LIST_FOR_MANAGERS,
              payload: companiesListActive,
            });
            // get all companies for manager, even the inactive ones
            dispatch({
              type: GET_ALL_COMPANIES_LIST_FOR_MANAGERS,
              payload: response?.data?.companyChains[0].companies,
            });
          }
        }
      }
    } catch (e) {
      dispatch({
        type: GET_COMPANIES_LIST_FOR_MANAGERS,
        payload: [],
      });

      throw new Error(e);
    }
  };
}

export function deleteCompany(referenceNumber) {
  const request = axios({
    method: 'delete',
    url: `${SERVER_URL}/api/v1/company/deleteCompany/${referenceNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: DELETE_COMPANY,
    payload: request,
  };
}

export function addToBlacklist(cvrNumber) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/company/addToBlacklist`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    headers: {
      'Content-Type': 'text/plain',
    },
    data: cvrNumber,
  });
  return {
    type: ADD_TO_BLACKLIST,
    payload: request,
  };
}

export function getFilteredCompaniesFile(searchKeyword) {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${SERVER_URL}/api/v1/company/getAllCompaniesFile`,
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
      responseType: 'blob',
      data: {
        searchedInput: searchKeyword,
      },
    })
      .then((response) => {
        dispatch({
          type: GET_ALL_FILTERED_COMPANIES,
          payload: response.data,
        });
        if (response.data) {
          const blob = new Blob([response.data], { type: 'text/plain' });
          if (blob) {
            FileSaver(blob, 'Companies.txt');
          }
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: GET_ALL_FILTERED_COMPANIES,
          payload: [],
        });
      });
  };
}

export function displayLeadsForCompany(refNumber = undefined) {
  return (dispatch) => {
    dispatch(leadSidebarActiveStatus(VIEW_ALL));
    dispatch({
      type: DISPLAY_LEADS_FOR_COMPANY,
      payload: refNumber,
    });
    dispatch(companySidebarActiveStatus(VIEW_ALL));
  };
}

export function updateCompanyInternalNote(id, internalNote) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const payloadData = { id, internalNote };
      axios({
        method: 'patch',
        url: `${SERVER_URL}/api/v2/company/internalNote`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: payloadData,
      })
        .then((response) => {
          const { activeCompany } = getState().company;
          let newObj = activeCompany;
          newObj.internalNote = payloadData.internalNote;
          dispatch({
            type: SET_COMPANY_INTERNAL_NOTE,
            payload: newObj,
          });
          dispatch(
            success(
              addNotification(
                strings.clinicInternalNoteSuccessMessage,
                strings.editCompanySuccessTitle,
                notificationDismissDuration
              )
            )
          );

          dispatch(updateCompanyItemList(Object.assign({}, activeCompany)));
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            notifError(
              addNotification(
                strings.clinicInternalNoteErrorMessage,
                strings.errorEditCompanyTitle,
                notificationDismissDuration
              )
            )
          );
          reject(error);
        });
    });
}

export function getPublicCompanyCategories() {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/company/getAllPublicCompanyCategories/`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      });
      const categList = [];
      if (response.status === 200) {
        response.data.forEach((val) => {
          categList.push({
            value: val.id,
            label: val.categoryName,
          });
        });
      }
      dispatch({
        type: GET_ALL_PUBLIC_COMPANY_CATEGORIES,
        payload: categList,
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_PUBLIC_COMPANY_CATEGORIES,
        payload: [],
      });

      // throw new Error(e);
    }
  };
}

export function updateCompanyProfile(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(setCompanyLoading(true));
      const user = getState().user.user;
      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/company/updateCompanyProfileData`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setCompanyLoading(false));

            if (isManager(user)) {
              const { activeManagerCompany } = getState().company;
              let newObjManager = { ...activeManagerCompany, ...data };
              dispatch({
                type: UPDATE_COMPANY_PROFILE_FOR_MANAGER,
                payload: data,
              });
              dispatch(updateCompanyManagerList(newObjManager));
            }
            if (isAdmin(user)) {
              const { activeCompany } = getState().company;
              let newObjAdmin = { ...activeCompany, ...data };
              dispatch({
                type: UPDATE_COMPANY_PROFILE,
                payload: data,
              });

              dispatch(updateCompanyItemList(Object.assign({}, newObjAdmin)));
            }
          }
          resolve(response);
        })
        .catch((error) => {
          if (isAdmin(user)) {
            dispatch({
              type: UPDATE_COMPANY_PROFILE,
              payload: error,
            });
            console.error(error);
            dispatch(setCompanyLoading(false));
          }
          if (isManager(user)) {
            dispatch({
              type: UPDATE_COMPANY_PROFILE_FOR_MANAGER,
              payload: error,
            });
            console.error(error);
            dispatch(setCompanyLoading(false));
          }
          reject(error);
        });
    });
}
