import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import { connect } from 'react-redux';
import { setCurrentCompany } from '../../../actions/companyActions';

const CompanyListItem = (props) => {
  const { company, active, setCurrentCompany } = props;
  let logoStatus = 'grey';
  if (company.status === 'ACTIVE' || company.status === 'SMARTLINK') {
    logoStatus = 'green';
  }
  useEffect(() => {
    if (active) {
      setCurrentCompany(company);
    }
  }, [active, company, setCurrentCompany]);

  const chainCompanyName = company.companyChain.chainCompanyName
    ? company.companyChain.chainCompanyName
    : company.companyChain.companyChainName;

  const brand = company.brand;

  return (
    <ListGroupItem
      active={active}
      onClick={() => {
        props.setCurrentCompany(company);
      }}
    >
      <div
        className={`${active ? 'active' : ''} single-list-item company-details`}
      >
        <div className='logo'>
          <div className={`d-logo logo-${logoStatus}`}>
            {brand === 'Denti' && <i className='di di-denti' />}
            {brand === 'Sundhed+' && <i className='di di-sundhedplus' />}
          </div>
        </div>
        <div className='clinic-data'>
          <ListGroupItemHeading>
            {`${company.companyName} `}
            <span className='refNumber'>{`(${company.referenceNumber})`}</span>
          </ListGroupItemHeading>
          <ListGroupItemText tag='div'>{chainCompanyName}</ListGroupItemText>
        </div>
        <div className='info-location'>
          {company.zipCode ? company.zipCode : ''}{' '}
          {company.city ? company.city : ''}
        </div>
      </div>
    </ListGroupItem>
  );
};
// <ListGroupItemText tag='div'>{`- ${strings[company.status]} -`}</ListGroupItemText>

CompanyListItem.propTypes = {
  company: PropTypes.object,
  active: PropTypes.bool,
  setCurrentCompany: PropTypes.func,
};

export default connect(null, { setCurrentCompany })(CompanyListItem);
