import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import statusCheck from '../../../resources/icons/status_check.png';
import statusInactive from '../../../resources/icons/status_inactive.png';
import statusCurrent from '../../../resources/icons/status_current.png';
import statusCross from '../../../resources/icons/status_cross.png';
import { strings } from '../../../constants/localization';

const ApplicationTimeline = () => {
  const [statusesTimelineMap, setStatusesTimelineMap] = useState({});
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const {
    budgetInformation,
    creditApplication,
    paymentRemarks,
    payslips,
    warnings,
  } = activeApplicationListItem || {};
  const { creditApplicationType, creditApplicationStatus, reason } =
    creditApplication || {};

  useEffect(() => {
    generateOnboardingTimelineStatus();
  }, [activeApplicationListItem]);

  const generateOnboardingTimelineStatus = () => {
    // 1: inactive, 2: current, 3: checked, 4: crossed
    let statusesMap = {
      eskatStatus: 1,
      budgetStatus: 1,
      paymentRemarksStatus: 1,
      documentationStatus: 1,
      activationStatus: 1,
    };

    if (creditApplicationType === null) {
      statusesMap.eskatStatus = 2;
      statusesMap.budgetStatus = 1;
      statusesMap.paymentRemarksStatus = 1;
      statusesMap.documentationStatus = 1;
      statusesMap.activationStatus = 1;
    } else {
      // eSkat status
      if (budgetInformation?.caseId !== null) {
        statusesMap.eskatStatus = 3;
      } else if (
        warnings.length > 0 &&
        warnings.some((obj) => obj?.severity === 'WARNING')
      ) {
        statusesMap.eskatStatus = 4;
      } else if (!payslips || payslips?.length === 0) {
        if (
          creditApplicationStatus === 'FAILED' ||
          creditApplicationStatus === 'REJECTED'
        ) {
          statusesMap.eskatStatus = 4;
        } else {
          statusesMap.eskatStatus = 2;
        }
      } else {
        statusesMap.eskatStatus = 3;
      }

      // Monthio status
      if (budgetInformation?.caseId === null) {
        statusesMap.budgetStatus = 1;
      } else if (budgetInformation?.revisedBudget?.length === 0) {
        statusesMap.budgetStatus = 2;
      } else if (
        warnings.length > 0 &&
        warnings.some((obj) => obj?.severity === 'WARNING')
      ) {
        statusesMap.budgetStatus = 4;
      } else if (reason && reason?.includes('Estimated disposable amount')) {
        statusesMap.budgetStatus = 4;
      } else if (statusesMap.eskatStatus === 3) {
        statusesMap.budgetStatus = 3;
      } else {
        statusesMap.budgetStatus = 1;
      }

      // Payment remarks status
      if (paymentRemarks === null) {
        statusesMap.paymentRemarksStatus = 1;
      } else if (paymentRemarks === 0) {
        statusesMap.paymentRemarksStatus = 3;
      } else if (paymentRemarks > 0) {
        statusesMap.paymentRemarksStatus = 4;
      }

      // Documentation status
      if (creditApplicationStatus === 'PENDING_DOCUMENTATION') {
        statusesMap.documentationStatus = 2;
      } else if (creditApplicationStatus === 'DONE') {
        statusesMap.documentationStatus = 3;
      } else {
        statusesMap.documentationStatus = 1;
      }

      // Activation status
      if (creditApplicationStatus === 'DONE') {
        statusesMap.activationStatus = 3;
      } else {
        statusesMap.activationStatus = 1;
      }
    }

    setStatusesTimelineMap(statusesMap);
  };

  const getIconBasedOnStatus = (status) => {
    switch (status) {
      case 1:
        return <img src={statusInactive} />;
      case 2:
        return <img src={statusCurrent} />;
      case 3:
        return <img src={statusCheck} />;
      case 4:
        return <img src={statusCross} />;
      default:
        return <img src={statusInactive} />;
    }
  };

  return (
    <div className='application-timeline'>
      <div className='frame'>
        {/* eSkat section */}
        <div className='frame-name'>
          <div className='group'>
            <span>{strings.applicationTimelineeSkat}</span>
            {getIconBasedOnStatus(statusesTimelineMap.eskatStatus)}
          </div>
          <div className='timeline-arrow'>
            <span></span>
            <i className='arrow-right'></i>
          </div>
        </div>

        {/* Budget section */}
        <div className='frame-name'>
          <div className='group'>
            <span>{strings.applicationTimeLineMonthio}</span>
            {getIconBasedOnStatus(statusesTimelineMap.budgetStatus)}
          </div>
          <div className='timeline-arrow'>
            <span></span>
            <i className='arrow-right'></i>
          </div>
        </div>

        {/* Payment remarks section */}
        <div className='frame-name'>
          <div className='group'>
            <span>{strings.applicationTimeLineDebitorCheck}</span>
            {getIconBasedOnStatus(statusesTimelineMap.paymentRemarksStatus)}
          </div>
          <div className='timeline-arrow'>
            <span></span>
            <i className='arrow-right'></i>
          </div>
        </div>

        {/* Documentation section */}
        <div className='frame-name'>
          <div className='group'>
            <span>{strings.applicationTimelineDocumentation}</span>
            {getIconBasedOnStatus(statusesTimelineMap.documentationStatus)}
          </div>
          <div className='timeline-arrow'>
            <span></span>
            <i className='arrow-right'></i>
          </div>
        </div>

        {/* Gennemført section */}
        <div className='frame-name'>
          <div className='group'>
            <span>{strings.applicationTimelineCompleted}</span>
            {getIconBasedOnStatus(statusesTimelineMap.activationStatus)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationTimeline;
