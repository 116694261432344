import React from 'react';
// import PropTypes from 'prop-types';
import TermsConditions from '../../common/TermsAndConditions/TermsConditions';
import { strings } from '../../../constants/localization';
import { brandConfig } from '../../../config';

import './style.scss';

function Step3() {
  return (
    <div className='Step3 section'>
      <div className='title'>{strings.userRegistrationTermsAndConditions}</div>
      <div className='content'>
        <div className='tos'>
          <TermsConditions
            link={brandConfig.termsAndConditionsUserRegistration}
          />
        </div>
      </div>
    </div>
  );
}

// Step3.propTypes = {
// };

export default Step3;
