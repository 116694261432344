import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';

class Observation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: false,
    };
  }

  componentDidMount() {
    this.setState({
      wait: true,
    });
  }

  render() {
    const marginTop = `text-center observation ${
      this.props.ableToAddInvoice || this.props.marginTop ? 'mt-2' : ''
    }`;
    if (this.props.observation && this.props.observation?.message !== null) {
      return (
        <CSSTransition
          in={this.state.wait}
          timeout={3000}
          classNames='fade'
          unmountOnExit
        >
          <Col className={marginTop}>
            <div>{this.props.observation}</div>
          </Col>
        </CSSTransition>
      );
    } else {
      return null;
    }
  }
}

export default Observation;
