import React from 'react';
import { Label } from 'reactstrap';
import SectionExpandable from './SectionExpandable';

const ApplicationDataCardRow = ({
  type,
  label,
  value,
  counter,
  childrenArray,
  carsArray,
  properties,
}) => {
  const options = ['children', 'cars', 'properties'];
  if (options.includes(type)) {
    return (
      <SectionExpandable
        counter={counter}
        label={label || '-'}
        value={value || '-'}
        childrenArray={childrenArray}
        carsArray={carsArray}
        properties={properties}
      />
    );
  } else if (type === 'income') {
    return (
      <div className='budget-entry section-item'>
        {label && <Label>{label}</Label>}
        {value || '-'}
      </div>
    );
  } else {
    return (
      <div className='budget-entry'>
        <Label>{label || '-'}</Label>
        <span>{value || '-'}</span>
      </div>
    );
  }
};

export default ApplicationDataCardRow;
