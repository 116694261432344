import React, { useRef, useState, useEffect } from 'react';

import { strings } from '../../../constants/localization';
import { documentationStatusMap } from '../../../constants/mappings';

const ActiveFilterDisplayDocumentation = (props) => {
  const { status, type } = props.filterParams;
  const scrollFilterRef = useRef();
  let isDown = false;
  let startX = 0;
  let scrollLeft = 0;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(props.filterParams).length > 0) {
      setLoaded(true);
    }
  }, [props]);

  // handle horizontal slide on
  const handleMouseDown = (event) => {
    const slider = scrollFilterRef.current;
    isDown = true;
    // slider.classList.add('active');
    startX = event.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
  };

  const handleMouseUp = () => {
    isDown = false;
  };

  const handleMouseMove = (event) => {
    const slider = scrollFilterRef.current;
    if (!isDown) return;
    event.preventDefault();
    const offsetX = event.pageX - slider.offsetLeft;
    const walk = (offsetX - startX) * 2; // scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className='active-filters-container'>
      {loaded && (
        <div
          className='active-filters'
          ref={scrollFilterRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          {status && (
            <div className='badge badge-filter' key={status}>
              <span
                className='close-action'
                onClick={() => props.removeDocStatus(status)}
              >
                <i className='di di-close' />
              </span>
              <span>{strings[documentationStatusMap[status].statusText]}</span>
            </div>
          )}
          {type && (
            <div className='badge badge-filter' key={type}>
              <span
                className='close-action'
                onClick={() => props.removeDocType(type)}
              >
                <i className='di di-close' />
              </span>
              <span>{strings[type]}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActiveFilterDisplayDocumentation;
