import React from 'react';
import { strings } from '../../../constants/localization';
import ErrorPage from '../../common/ErrorPage';
import { brandConfig } from '../.././../config';

function ConfirmEmailInvalidLink() {
  document.title = strings.confirmEmailLinkError;
  return (
    <ErrorPage>
      <h1>{strings.confirmEmailLinkError}</h1>
      <p>{strings.confirmEmailAlreadyConfirmedP1}</p>
      <p>
        {strings.formatString(
          strings.confirmEmailAlreadyConfirmedP2,
          <a href={`mailto:${brandConfig.contactEmail}`}>
            {brandConfig.contactEmail}
          </a>,
          <a href={`tel:${brandConfig.contactPhone}`}>
            {brandConfig.contactPhoneHtmlFormated}
          </a>
        )}
      </p>
    </ErrorPage>
  );
}

export default ConfirmEmailInvalidLink;
