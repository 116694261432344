import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../constants/utils';
import { ROUTE_MAIN_LOGIN } from '../constants/routes';


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect replace to={ROUTE_MAIN_LOGIN} />
        )
      }
    />
 
);

export default PrivateRoute;
