import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../constants/localization';
import {
  documentationStatusFilter,
  documentationTypeFilter,
} from '../../../constants';
import { documentationStatusMap } from '../../../constants/mappings';
import ActiveFilterDisplayDocumentation from './ActiveFilterDisplayDocumentation';
import {
  getAllDocuments,
  updateDocumentationFilterParams,
} from '../../../actions/onboardingDocumentationActions';
import RadioButtonList from '../../common/RadioButtonList/RadioButtonList';

const DocumentationFilter = ({ filterSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const filters = useSelector(
    (state) => state.onboardingDocumentation?.filterParams
  );
  const [filterParams, setFilterParams] = useState({
    status: filters?.status || '',
    type: filters?.type || '',
  });
  const [activeFilters, setActiveFilters] = useState(filters || {});
  const filterRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  useEffect(() => {
    if (filterParams !== filters) {
      dispatch(updateDocumentationFilterParams(filterParams));
    }
  }, [filterParams]);

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleRadioChange = (value) => {
    setFilterParams((prevState) => ({
      ...prevState,
      status: value || '',
    }));
  };

  const handleDocumentationTypeChange = (selectedOption) => {
    setFilterParams((prevState) => ({
      ...prevState,
      type: selectedOption?.value || '',
    }));
  };

  const clearFilters = () => {
    const clearedFilters = {
      status: '',
      type: '',
    };
    setFilterParams(clearedFilters);
    setIsOpen(false);
    dispatch(getAllDocuments());
    // if filters stored gets updated, update the active filters locally
    setActiveFilters(clearedFilters);
  };

  const applyFilters = (event) => {
    event.preventDefault();
    const { status, type } = filterParams;

    if (status || type) {
      filterSubmit(filterParams);
    } else {
      dispatch(getAllDocuments());
    }

    toggle();
    setActiveFilters(filterParams);
  };

  const removeDocStatus = () => {
    setFilterParams((prevState) => {
      const updatedFilters = {
        ...prevState,
        status: '',
      };
      filterSubmit(updatedFilters);
      return updatedFilters;
    });

    setActiveFilters((prevState) => ({
      ...prevState,
      status: '',
    }));
  };

  const removeDocType = () => {
    setFilterParams((prevState) => {
      const updatedFilters = {
        ...prevState,
        type: '',
      };
      filterSubmit(updatedFilters);
      return updatedFilters;
    });

    setActiveFilters((prevState) => ({
      ...prevState,
      type: '',
    }));
  };

  return (
    <div className='filter-container' ref={filterRef}>
      <Button color='light' className='btn-filter' onClick={toggle}>
        <i className='di di-controls' />
        <span>{strings.filterTitle}</span>
      </Button>
      <ActiveFilterDisplayDocumentation
        filterParams={activeFilters}
        removeDocType={removeDocType}
        removeDocStatus={removeDocStatus}
      />
      <div className={`filter-content ${isOpen ? 'opened' : ''}`}>
        <button
          type='button'
          className='btn btn-white menu-toggler close-action'
          onClick={toggle}
        >
          <div className='close-toggler-icon'>
            <span />
            <span />
          </div>
        </button>
        <Form onReset={clearFilters} onSubmit={applyFilters}>
          <Row>
            <Col xs='12' className='form-group'>
              <div className='filter-title'>
                {strings.documentationFilterStatus}
              </div>
              <RadioButtonList
                options={documentationStatusFilter.map((item) => ({
                  value: item,
                  label: strings[documentationStatusMap[item].statusText],
                }))}
                selectedOption={filterParams.status}
                handleClick={handleRadioChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs='12' className='CustomSelectComponent filter form-group'>
              <div className='filter-title'>
                {strings.documentationFilterType}
              </div>
              <Select
                className='custom_select'
                classNamePrefix='custom_select'
                options={documentationTypeFilter.map((item) => ({
                  value: item,
                  label: strings[item],
                }))}
                placeholder={strings.documentationFilterPlaceholder}
                value={
                  filterParams?.type !== ''
                    ? {
                        value: filterParams.type,
                        label: strings[filterParams.type],
                      }
                    : {}
                }
                onChange={handleDocumentationTypeChange}
                menuPlacement='auto'
                maxMenuHeight={180}
              />
            </Col>
          </Row>
          {/* ACTION BUTTONS */}
          <Row>
            <Col xs='6' md='6' className='mt-3'>
              <Button type='reset' color='light' block>
                <i className='di di-close' />
                &ensp;
                {strings.resetFilter}
              </Button>
            </Col>
            <Col xs='6' md='6' className='mt-3'>
              <Button type='submit' color='light' className='btn-blue' block>
                <i className='di di-check' />
                &ensp;
                {strings.submitFilter}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default DocumentationFilter;
