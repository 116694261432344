import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import './ChainItem.scss';
import { updateCompanyChainInternalNote } from '../../../actions/companyChainActions';
import ChainInformation from './ChainInformation/ChainInformation';
import ChainOptions from './ChainOptions';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import { strings } from '../../../constants/localization';
import EntityStatus from '../../common/EntityStatus/EntityStatus ';
import InternalNote from '../../common/InternalNote';

const ChainItem = (props) => {
  const chainLoading = useSelector((state) => state.companyChain.chainLoading);
  const search = useSelector((state) => state.companyChain.searchActive);
  const activePage = useSelector((state) => state.companyChain.activePage);

  return (
    <div className='ChainItemContent main-content'>
      <button
        type='button'
        className='btn btn-white go-back-to-list'
        onClick={() => props.setCurrentChain(undefined)}
      >
        <i className='lnil lnil-chevron-left' />
        <span>{strings.goToChainsList}</span>
      </button>

      {chainLoading && <LoadingMessage />}
      <Row>
        <Col xs='12' lg='12'>
          <ChainInformation
            activeChain={props.activeChain}
            searchValue={search}
            activePage={activePage}
            filterPayload={props.filterPayload}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='12'>
          <EntityStatus
            entityDetails={props.activeChain}
            fieldClass='CompanyChainStatusComponent'
            companyChainPage
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='12'>
          <InternalNote
            id={props.activeChain.id}
            activeObject={props.activeChain}
            saveInternalNote={updateCompanyChainInternalNote}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='12'>
          <ChainOptions
            chains={props.activeChain.companies}
            id={props.activeChain.id}
            activeChain={props.activeChain}
          />
        </Col>
      </Row>
    </div>
  );
};

ChainItem.propTypes = {
  activeChain: PropTypes.object,
};

export default ChainItem;
