import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { success, error as notifError } from 'react-notification-system-redux';
import { Card, Button } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
  minLoanAllowed,
  SERVER_URL,
  notificationDismissDuration,
} from '../../constants';
import {
  isAdmin,
  isManager,
  isDentist,
  isDeletedOrDeprecated,
  addNotification,
} from '../../constants/utils';

import {
  trackThinVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import { strings } from '../../constants/localization';
import { resendSMS, cloneInvoice } from '../../actions/installmentLoanActions';
// eslint-disable-next-line import/no-named-as-default
import InvoiceHistoryListItem from './InvoiceHistoryListItem';
import DentiModal from '../common/DentiModal';
import ConfirmationModal from '../common/ConfirmationModal';
import NewInvoice from './NewInvoice/NewInvoice';
import Observation from './Observation';
import AddPatient from '../add-patient/AddPatient';
import SendLinkToRenew from './RenewLinkModal/SendLinkToRenew.tsx';

const InvoiceHistoryList = (props) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [lead, setLead] = useState(props.lead);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [pendingInvoicesList, setPendingInvoicesList] = useState([]);
  const [observationMessages, setObservationMessages] = useState([]);
  const [showSendAppLinkModal, setShowSendAppLinkModal] = useState(false);
  const [modalSendRenewLink, setModalSendRenewLink] = useState(false);
  const user = useSelector((state) => state.user?.user);
  const isUserAdmin = isAdmin(user);
  const isUserManager = isManager(user);
  const isUserDentist = isDentist(user);

  // should not be able to register invoice if company chain is non-active
  const managersCompaniesList = useSelector(
    (state) => state.company?.managersCompaniesList
  );
  const invoiceCloned = useSelector(
    (state) => state.installmentLoan?.invoiceCloned
  );

  const [showCloneBtn, setShowCloneBtn] = useState(false);
  const [isRegisterBtnDisabled, setIsRegisterBtnDisabled] = useState(false);
  const [registerBtnTooltip, setRegisterBtnTooltip] = useState(null);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [onlyOneInvoice, setOnlyOneInvoice] = useState(false);
  const [showResendSmsObsModal, setShowResendSmsObsModal] = useState(false);

  // when lead gets changed, reset scroll position and filtered invoices
  useEffect(() => {
    resetScrollPosition();
    setLead(props.lead);
    setFilteredInvoices(getInvoicesBasedOnUserRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lead]);

  // if the lead changes, update the view
  useEffect(() => {
    getUpdatedView(lead);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollRef.current !== null) {
        scrollRef.current.scrollToBottom({ behavior: 'smooth' });
      }
    }, 10);
  };

  const getInvoicesBasedOnUserRoles = () => {
    const currentPatient = props.lead;
    const unfiltered =
      currentPatient.creditFacilityInformation.installmentLoanInformations;
    let filtered;
    const managerClinics =
      isUserManager && managersCompaniesList?.length > 0
        ? managersCompaniesList.map((item) => item.referenceNumber)
        : [];

    if (isUserAdmin) {
      filtered = unfiltered;
    }

    // filter the invoices to show only the ones from the company chain
    else if (isUserManager && managerClinics?.length > 0) {
      filtered = unfiltered.filter((invoice) =>
        managerClinics.includes(invoice.companyReferenceNumber)
      );
    }
    // filter the invoices to show only the ones from the active company
    else if (isUserDentist) {
      filtered = unfiltered.filter(
        (invoice) => invoice.companyReferenceNumber === user.activeCompany
      );
    } else {
      // Set filtered to an empty array if none of the conditions match
      filtered = [];
    }

    const reversedArray = [...filtered].reverse();
    return reversedArray;
  };

  const getUpdatedView = () => {
    let observationMessage;
    let messageQueue = [];
    let disableRegisterBtn = false;
    const {
      creditFacilityInformation: {
        creditMessage,
        remainingTime: { totalDays },
        interestRate,
        amountLeftWithoutBonus,
        status,
        installmentLoanInformations,
      },
      creditFacilityInformation: creditDetails,
      leadClientInformation: { clientDunningStatus },
    } = lead || {};
    const expired = totalDays < 0;
    const zeroInterestRate = interestRate === 0;
    const zeroCredit = !expired && amountLeftWithoutBonus <= 0;
    const creditFacilitySuspended = status === 'SUSPENDED';
    const latePayment = status === 'LATE_PAYMENT';
    const pendingInvoicesList = installmentLoanInformations.filter(
      (invoice) => invoice.status === 'WAITING_FOR_SIGNATURE'
    );
    const hasPendingLoans =
      pendingInvoicesList.length > 0 || creditMessage === 'pendingLoan';
    //const hasZeroInterest =
    zeroInterestRate || zeroCredit || creditMessage === 'zeroInterestLoan';
    const companyIsCooperating = isUserManager
      ? user?.companyChainStatus === 'ACTIVE'
      : user?.activeCompanyStatus === 'ACTIVE';
    let acceptedOrActiveInvoiceNumber;
    const availableInvoices = filteredInvoices
      .filter(
        (invoice) =>
          !isDeletedOrDeprecated(invoice.status) && shouldDisplayLoan(invoice)
      )
      .filter((invoice) => showCreatedBy(invoice));
    availableInvoices.forEach((invoice) => {
      if (invoice.status === 'ACCEPTED' || invoice.status === 'ACTIVE') {
        acceptedOrActiveInvoiceNumber = invoice.number;
      }
    });

    // if the user is admin and has only one invoice accepted and paidout null allowed the user delete
    const counterOfDeletableInvoices = availableInvoices.filter((invoice) => {
      const { status } = invoice;
      return (
        status === 'ACCEPTED' ||
        status === 'CONSOLIDATED' ||
        status === 'LATE_PAYMENT' ||
        status === 'RESTRUCTURED'
      );
    }).length;
    const onlyOneInvoice = counterOfDeletableInvoices <= 1;

    // for all user roles, disable btn if the credit is NOT Active, credit is expired, client in dunning or credit amount left is zero
    const disableButtonForAllRoles =
      status !== 'ACTIVE' || expired || clientDunningStatus || zeroCredit;
    if (disableButtonForAllRoles) {
      disableRegisterBtn = true;
    }

    // for non-admins, disable if the credit has pendingLoans or the user being logged in on a NON-active company/not cooperating
    if (!isUserAdmin) {
      // for manager users if the creditMessage is zeroInterestLoan, check the visible loans and if an invoice with zeroInterestLoan exists, then enable the "Create invoice" button, otherwise disabled it.
      if (isUserManager) {
        const visibleInvoices = getInvoicesBasedOnUserRoles();
        const zeroInterestLoanExists = visibleInvoices?.some(
          (invoice) => invoice.installmentInterestRate === 0
        );
        if (creditMessage === 'zeroInterestLoan' && !disableButtonForAllRoles) {
          if (zeroInterestLoanExists) {
            disableRegisterBtn = false;
          } else {
            disableRegisterBtn = true;
          }
        }
      }

      // for manager and dentist users
      if (hasPendingLoans || !companyIsCooperating) {
        disableRegisterBtn = true;
      }
    }

    // set the tooltip message for Register button
    if (expired) {
      setRegisterBtnTooltip(
        strings.formatString(strings.leadExpiredTooltipMessage, minLoanAllowed)
      );
    } else if (latePayment) {
      setRegisterBtnTooltip(strings.latePaymentTooltip);
    } else if (!isUserAdmin && clientDunningStatus) {
      setRegisterBtnTooltip(strings.suspensionTooltip);
    } else {
      setRegisterBtnTooltip('');
    }

    // 1: the credit has expired
    if (expired) {
      if (
        zeroInterestRate &&
        creditDetails.installmentLoanInformations.length <= 1
      ) {
        messageQueue.push(
          <>
            {strings.observationExp}
            <Button
              color='link'
              className='no-break-line obs-link btn-link reapply-btn'
              onClick={() => {
                setShowSendAppLinkModal(true);
              }}
            >
              {strings.reapply}
            </Button>
          </>
        );
      } else {
        messageQueue.push(
          <div className='renew-credit-link'>
            <span className=''>{strings.obs4}</span>

            <Button
              color='link'
              className='text-decoration-none no-break-line obs-link btn-link reapply-btn'
              onClick={() => {
                setModalSendRenewLink(true);
              }}
            >
              {strings.obs4Link}
            </Button>
          </div>
        );
      }
    }
    // 2: the credit is closed/suspended
    if (creditFacilitySuspended) {
      messageQueue.push(strings.suspendMessage);
    }
    if (clientDunningStatus) {
      if (acceptedOrActiveInvoiceNumber) {
        messageQueue.push(
          strings.formatString(
            strings.suspensionText,
            <a
              href={`${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${acceptedOrActiveInvoiceNumber}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {strings.suspensionLinkText}
            </a>
          )
        );
      } else {
        messageQueue.push(strings.suspensionTextNoInvoice);
      }
    }
    // 3: the credit is fully utilized
    if (zeroCredit && pendingInvoicesList.length === 0) {
      messageQueue.push(strings.obsZeroCredit);
    } else if (pendingInvoicesList.length > 0) {
      messageQueue.push(strings.obsPendingInvoices);
    } else {
    }

    // 4: CreditMessage
    // 4.1: pendingLoan
    if (creditMessage === 'pendingLoan') {
      // 4.1.1: loan is from the current company/copanyChain
      if (pendingInvoicesList.length > 0) {
        observationMessage = strings.resendText;
      } else {
        // 4.1.2: loan is from outside company/companyChain
        observationMessage = strings.registerInvoiceNoteMessage;
      }
      messageQueue.push(
        <>
          {observationMessage}
          <br />
          {(isAdmin(user) ||
            (!isAdmin(user) && pendingInvoicesList.length > 0)) && (
            <Button
              color='link'
              className='no-break-line obs-link'
              onClick={() => setShowResendSmsObsModal(true)}
            >
              {strings.resendSMSLinkText}
            </Button>
          )}
        </>
      );
    }

    // 4.2: zeroInterestLoan
    if (zeroInterestRate || creditMessage === 'zeroInterestLoan') {
      if (creditMessage !== 'pendingLoan') {
        messageQueue.push(strings.observation);
      }
    }

    // 4.3: acceptedLoan => no observation msg
    if (creditMessage === 'acceptedLoan') {
      //return null;
    }

    setOnlyOneInvoice(onlyOneInvoice);
    setPendingInvoicesList(pendingInvoicesList);
    setObservationMessages(messageQueue);
    setIsRegisterBtnDisabled(disableRegisterBtn);
    setShowCloneBtn(companyIsCooperating && !disableRegisterBtn);
  };

  const shouldDisplayLoan = (invoice) => {
    if (isDentist(user)) {
      if (invoice.companyReferenceNumber !== user.activeCompany) {
        if (['PAYED', 'SETTLED'].indexOf(invoice.status) >= 0) {
          return false;
        }
      }
    }
    return true;
  };

  const showCreatedBy = (invoice) => {
    if (
      isUserDentist &&
      invoice.companyReferenceNumber !== user.activeCompany
    ) {
      return false;
    }
    return true;
  };

  const resetScrollPosition = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.view.scrollTop = 0;
    }
  };

  const getCreateButton = () => {
    return (
      <>
        <Button
          color={isRegisterBtnDisabled ? 'light' : 'blue'}
          disabled={isRegisterBtnDisabled}
          onClick={() => setShowRegisterModal(true)}
          className={`register-invoice-btn center-block ${
            !isRegisterBtnDisabled ? '' : ' disabled'
          }`}
        >
          <i className='di di-circle-plus' />
          {strings.registerInvoice}
        </Button>
        {registerBtnTooltip ? (
          <span className='tooltip large'>{registerBtnTooltip}</span>
        ) : (
          ''
        )}
      </>
    );
  };

  const clearInvoiceClonedIfAny = () => {
    if (invoiceCloned) {
      dispatch(cloneInvoice(null));
    }
  };

  const renderInvoicesList = () => {
    return (
      filteredInvoices &&
      filteredInvoices.map((invoice, index) => (
        <InvoiceHistoryListItem
          key={invoice.id}
          invoice={invoice}
          lead={lead}
          onlyOneInvoice={onlyOneInvoice}
          showCloneBtn={showCloneBtn}
          openRegisterInvoice={() => {
            setShowRegisterModal(true);
          }}
          {...(filteredInvoices.length > 5 &&
          index >= filteredInvoices.length - 4
            ? { scrollToBottom: scrollToBottom }
            : {})}
        />
      ))
    );
  };

  return (
    <Card className='invoice-history custom-card'>
      {/* INVOICES TABLE  */}
      <div className='header-information my-3'>
        <h4>{strings.invoiceHistoryTitle}</h4>
      </div>
      <div
        className={`custom-table table-responsive-sm table-wrapper ${
          isUserManager ? 'manager' : isUserDentist ? 'dentist' : 'admin'
        }`}
      >
        <iframe
          id='iframeContentToPrintDraft'
          title='iframeContentToPrintDraft'
        />
        <div className='table-header'>
          <div className='table-row-div'>
            <div className='table-cell-div'>{strings.invoiceDate}</div>
            <div className='table-cell-div'>{strings.invoiceLead}</div>
            {(isUserAdmin || isUserManager) && (
              <div className='table-cell-div'>{strings.invoiceClinic}</div>
            )}
            <div className='table-cell-div'>{strings.invoiceStatus}</div>
            <div className='table-cell-div'>{strings.newInvoiceAmount}</div>
            <div className='table-cell-div'></div>
          </div>
        </div>
        <div className='table-separator' />
        {filteredInvoices && filteredInvoices.length > 0 && (
          <div
            className={`table-body invoices-list invoices-list-${
              filteredInvoices.length > 5 ? 'scroll' : filteredInvoices.length
            }`}
          >
            {filteredInvoices.length > 5 ? (
              <Scrollbars
                ref={scrollRef}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{ ...style, ...trackThinVerticalStyle }}
                  />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
                )}
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    style={{ display: 'none' }}
                    className='track-horizontal'
                  />
                )}
              >
                <div className='scrollbar-wrapper'>{renderInvoicesList()}</div>
              </Scrollbars>
            ) : (
              <div className='scrollbar-wrapper'>{renderInvoicesList()}</div>
            )}
          </div>
        )}
      </div>
      {/* END INVOICES TABLE  */}

      <div
        className={`card-table-footer ${
          filteredInvoices.length > 5 ? 'has-scroll' : ''
        } `}
      >
        {/* Warning messages     */}
        {observationMessages &&
          observationMessages.length > 0 &&
          observationMessages.map((observationMsg, index) => {
            return (
              <Observation
                key={index}
                observation={observationMsg}
                ableToAddInvoice={showRegisterModal}
                marginTop={index > 0}
              />
            );
          })}

        {/* Create Invoice button   */}
        <div className='has-tooltip bottom'>{getCreateButton()}</div>
      </div>

      {/* Resend sms observation confirmation modal that is opened from the observation notice and not from invoice list item */}
      {showResendSmsObsModal && (
        <DentiModal
          key={1}
          close={() => setShowResendSmsObsModal(false)}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              dispatch(resendSMS(pendingInvoicesList[0]?.number))
                .then((result) => {
                  dispatch(
                    success(
                      addNotification(
                        `${strings.successTextMessageSent} ${result.payload.data.phone}`,
                        strings.resendSMSTitleSuccess,
                        notificationDismissDuration
                      )
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    notifError(
                      addNotification(
                        strings.errorTextMessageSent,
                        strings.resendSMSTitleError,
                        notificationDismissDuration
                      )
                    )
                  );
                });
            }}
            cancelAction={() => setShowResendSmsObsModal(false)}
          >
            <div>
              <p className='title'>{strings.invoiceTitle}</p>
              <p className='confirm-message'>
                {`${strings.confirmActionSMS} ${lead.leadClientInformation?.phone}?`}
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}

      {/* New Invoice modal */}
      {showRegisterModal && (
        <DentiModal
          key={1}
          close={() => {
            setShowRegisterModal(false);
            clearInvoiceClonedIfAny();
          }}
          modalClass='new-invoice-Modal'
          modalClassName='paid-out-details-action center_modal'
          closeBtn={true}
        >
          <NewInvoice
            handleClose={() => {
              setShowRegisterModal(false);
              clearInvoiceClonedIfAny();
            }}
            lead={lead}
            brand={lead.creditFacilityInformation.brand}
          />
        </DentiModal>
      )}

      {showSendAppLinkModal && (
        <DentiModal
          key='add-patient-modal'
          close={() => setShowSendAppLinkModal(false)}
          modalClass='add-patient-modal modal-dialog-centered modal-dialog-scrollable'
        >
          <AddPatient
            activeStep={7}
            email={props.lead.leadClientInformation.email}
            phone={props.lead.leadClientInformation.phone}
            toggleModal={() => setShowSendAppLinkModal(false)}
          />
        </DentiModal>
      )}
      {/* modal to show sms or email with renew link  */}
      {modalSendRenewLink && (
        <DentiModal
          key='renew-credit-link'
          close={() => setModalSendRenewLink(false)}
          modalClass='renrew-modal-container modal-dialog-centered modal-dialog-scrollable'
        >
          <SendLinkToRenew
            email={props.lead.leadClientInformation.email}
            phone={props.lead.leadClientInformation.phone}
            creditFacilityInformation={props.lead.creditFacilityInformation}
            closeModal={() => setModalSendRenewLink(false)}
          />
        </DentiModal>
      )}
    </Card>
  );
};
export default InvoiceHistoryList;
