import React, { Component } from 'react';
import { Button, Form, Row, Col, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { strings } from '../../../constants/localization';
import { companiesStatusMap } from '../../../constants/mappings';
import { brandFilterStatuses, momentFormat } from '../../../constants';
import ActiveFiltersDisplayChain from './ActiveFiltersDisplayChain';

class ChainFilter extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.scrollFilterRef = React.createRef();

    this.handleDataSubmit = this.handleDataSubmit.bind(this);
    this.handleDataReset = this.handleDataReset.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.removeFilterStatus = this.removeFilterStatus.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.unmountClearFilter = this.unmountClearFilter.bind(this);

    this.filterRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      filterOpen: false,
      statuses: [],
      trialEndDate: '',
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.filterRef.current &&
      !this.filterRef.current.contains(event.target)
    ) {
      this.setState({ filterOpen: false });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      filterOpen: !prevState.filterOpen,
    }));
  }

  handleStatusChange(event) {
    const selectedStatuses = [...this.state.statuses];
    const inputEl = event.target;
    const { value } = inputEl;
    const indexCurrentStatus = selectedStatuses
      ? selectedStatuses.indexOf(value)
      : null;

    if (!inputEl.checked && indexCurrentStatus) {
      // if the input is not checked, removed it from the array
      selectedStatuses.splice(indexCurrentStatus, 1);
    } else {
      selectedStatuses.push(value);
    }

    // update the state with checked filters
    this.setState({
      statuses: [...selectedStatuses],
    });
  }

  removeFilterStatus(status) {
    const selectedStatuses = [...this.state.statuses];
    const indexCurrentStatus = selectedStatuses
      ? selectedStatuses.indexOf(status)
      : null;
    if (selectedStatuses) {
      selectedStatuses.splice(indexCurrentStatus, 1);
    }
    this.setState(
      {
        statuses: [...selectedStatuses],
      },
      () => {
        this.sendData();
      }
    );
  }

  handleDateChange(event) {
    const inputEl = event.target;
    const { name, value } = inputEl;

    // inputEl.setAttribute("data-date", moment(value, "YYYY-MM-DD").format(inputEl.getAttribute("data-date-format")));

    this.setState({
      [name]: value,
    });
  }

  clearDate() {
    this.setState(
      {
        trialEndDate: '',
      },
      () => {
        this.sendData();
      }
    );
  }

  unmountClearFilter() {
    this.props.filterSubmit({}, true, []);
    this.toggle();
  }

  sendData() {
    const payload = {};
    const { statuses, trialEndDate } = this.state;
    if (statuses.length > 0) {
      let companyStatus = null;
      let isBrandDenti = false;
      let isBrandSundhed = false;

      statuses.forEach((value) => {
        switch (value) {
          case 'ACTIVE':
            companyStatus = value;
            break;
          case 'DENTI':
            isBrandDenti = true;
            break;
          case 'SUNDHED':
            isBrandSundhed = true;
            break;
          default:
        }
      });

      if (companyStatus) {
        payload.companyStatus = companyStatus;
      }
      if (isBrandDenti && !isBrandSundhed) {
        payload.brand = 'Denti';
      }
      if (!isBrandDenti && isBrandSundhed) {
        payload.brand = 'Sundhed+';
      }
    }
    if (trialEndDate !== '') {
      payload.trialEndDate = moment(trialEndDate).format('YYYY-MM-DD');
    }
    if (Object.keys(payload).length > 0) {
      this.props.filterSubmit(payload, false, {
        trialEndDate: payload.trialEndDate,
      });
    } else {
      this.props.filterSubmit(payload, true, {
        trialEndDate: payload.trialEndDate,
      });
    }
  }

  handleDataReset() {
    const dataReset = {
      statuses: [],
      trialEndDate: '',
    };
    this.setState(dataReset);
    this.props.filterSubmit(dataReset, true);
    this.toggle();
  }

  handleDataSubmit(event) {
    event.preventDefault();
    this.sendData();
    this.toggle();
  }

  render() {
    return (
      <div ref={this.filterRef} className='filter-container companies'>
        <Button color='light' className='btn-filter' onClick={this.toggle}>
          <i className='di di-controls' />
          <span>{strings.filterTitle}</span>
        </Button>

        {/* SHOW THE ACTIVE FILTERS in the filter bar */}
        <ActiveFiltersDisplayChain
          activeFilters={this.props.activeFilters}
          companyChain={this.props.companyChain}
          removeFilterStatus={this.removeFilterStatus}
          clearDate={this.clearDate}
          unmountClearFilter={this.unmountClearFilter}
        />

        {/* SHOW THE DOWNLOAD COMPANIES BUTTON IF SEARCH GIVES RESULTS */}

        <Button
          color='blue'
          className='btn-download'
          onClick={() => {
            this.props.onChangeState(true);
          }}
        >
          <i className='lnir lnir-plus' />
          <span> {strings.new}</span>
        </Button>

        <div
          className={`filter-content ${this.state.filterOpen ? 'opened' : ''}`}
        >
          {/* CLOSE BUTTON FOR RESPONSIVE  */}
          <button
            type='button'
            className='btn btn-white menu-toggler close-action'
            onClick={this.toggle}
          >
            <div className='close-toggler-icon'>
              <span />
              <span />
            </div>
          </button>

          {/* FILTER FORM */}
          <Form onReset={this.handleDataReset} onSubmit={this.handleDataSubmit}>
            <Row>
              <Col xs='12' className='form-group'>
                <div className='filter-title'>{strings.companyChainStatus}</div>

                <div className='company-status-filter custom-control custom-checkbox'>
                  <Input
                    className='custom-control-input topic-control'
                    id='company-status-1'
                    type='checkbox'
                    name='companyChainStatuses'
                    value='ACTIVE'
                    checked={
                      this.state.statuses
                        ? this.state.statuses.indexOf('ACTIVE') > -1
                        : false
                    }
                    onChange={this.handleStatusChange}
                  />
                  <Label
                    for='company-status-1'
                    className='text-capitalize custom-control-label'
                  >
                    {strings.companyStatusOption}
                  </Label>
                </div>
              </Col>
            </Row>

            {/* BRAND STATUS */}
            <Row>
              <Col xs='12' className='form-group'>
                <div className='filter-title'>{strings.companyBrandStatus}</div>
                {brandFilterStatuses.map((brandStatus, index) => (
                  <div
                    className='brand-status-filter custom-control custom-checkbox'
                    key={brandStatus}
                  >
                    <Input
                      className='custom-control-input topic-control'
                      id={`brand-status-${index}`}
                      type='checkbox'
                      name='brandChainStatuses'
                      value={brandStatus}
                      checked={
                        this.state.statuses
                          ? this.state.statuses.indexOf(brandStatus) > -1
                          : false
                      }
                      onChange={this.handleStatusChange}
                    />
                    <Label
                      for={`brand-status-${index}`}
                      className='text-capitalize custom-control-label'
                    >
                      {strings[companiesStatusMap[brandStatus].statusText]}
                    </Label>
                  </div>
                ))}
              </Col>
            </Row>

            {/* DATE INPUTS */}
            <Row className='form-row'>
              <Col xs='12'>
                <Label for='date-interval'>
                  {strings.companyChainTrialPeriod}
                </Label>
              </Col>
              <Col xs='6' md='6' className='form-group date-input'>
                <Input
                  type='date'
                  name='trialEndDate'
                  data-date={momentFormat}
                  data-date-format={momentFormat}
                  value={this.state.trialEndDate}
                  onChange={this.handleDateChange}
                />
              </Col>
            </Row>

            {/* ACTION BUTTONS */}
            <Row>
              <Col xs='6' md='6' className='mt-3'>
                <Button type='reset' color='light' block>
                  <i className='di di-close' />
                  &ensp;
                  {strings.resetFilter}
                </Button>
              </Col>
              <Col xs='6' md='6' className='mt-3'>
                <Button type='submit' color='light' className='btn-blue' block>
                  <i className='di di-check' />
                  &ensp;
                  {strings.submitFilter}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chainsFilteredBySearch: state.companyChain.chainsFilteredBySearch,
    user: state.user.user,
  };
}
export default connect(mapStateToProps)(ChainFilter);
