import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setCurrentPatient } from '../../actions/patientDunningActions';
import {
  getInstalmentLoanByCreditFacility,
  setCurrentLead,
} from '../../actions/leadActions';
import { getLogsCreditFacility } from '../../actions/logActions';
import LeadDetails from '../leads-page/lead-details/LeadDetails';
import LoadingMessage from '../statusMessages/LoadingMessage';

const PatientDetails = (props) => {
  const patientDetailsRef = useRef();
  // patientDetailsRef = useClickOutside(() => {
  //   props.setCurrentPatient();
  // });
  const [localDataPatient, setLocalDataPatient] = useState(undefined);

  useEffect(() => {
    if (props.activePatient !== undefined) {
      patientDetailsRef.current.classList.add('open');
      setLocalDataPatient(props.activePatient);
      props.getInstalmentLoanByCreditFacility(
        props.activePatient.creditFacilityInformation.number
      );
      props.getLogsCreditFacility(
        props.activePatient.creditFacilityInformation.number
      );
    } else {
      patientDetailsRef.current.classList.remove('open');
      patientDetailsRef.current.addEventListener('animationend', () => {
        setLocalDataPatient(props.activePatient);
      });
    }
  }, [props]);

  return (
    <div className='patient-dunning-details' ref={patientDetailsRef}>
      {localDataPatient !== undefined && props.activePatient !== undefined ? (
        <div className='entity-details-section active'>
          <button
            type='button'
            className='btn details-toggler'
            onClick={() => {
              props.setCurrentLead(undefined);
              setTimeout(() => {
                props.removeFilter('reload');
              }, 500);
            }}
          >
            <i className='di di-chevron-right' />
          </button>
          <LeadDetails activeLead={localDataPatient} />
        </div>
      ) : (
        <LoadingMessage />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    activePatient: state.lead.activeLead,
  };
}

export default connect(mapStateToProps, {
  setCurrentLead,
  setCurrentPatient,
  getInstalmentLoanByCreditFacility,
  getLogsCreditFacility,
})(PatientDetails);
