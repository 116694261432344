import React from 'react';
import { Button, CardBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { strings } from '../../constants/localization';
import { Sundhedplus, brandConfig } from '../../config';
import { clearOnboardingData } from '../../actions/onboardingActions';

const InvalidLinkForOnboarding = ({ setNextStep, clearInvalidLink }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const phoneNumber =
  strings.getLanguage() === 'en'
    ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
    : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');

  return (
    <CardBody className='step-content'>
      <div className='step-title'>{strings.onboardingInvalidLinkTitle}</div>

      <section>
        <p>
          {strings.formatString(
            strings.onboardingInvalidLinkP,
            <a className='color' href={`mailto:${Sundhedplus.contactEmail}`}>
              {Sundhedplus.contactEmail}
            </a>,
            <a className='color' href={`tel:${Sundhedplus.contactPhone}`}>
              {phoneNumber}
            </a>
          )}
        </p>
      </section>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-success full-width'
          onClick={() => {
            dispatch(clearOnboardingData());
            clearInvalidLink();
            setNextStep(1);
            history.replace('/onboarding?step=1');
          }}
        >
          {strings.onboardingInvalidLinkButton}
        </Button>
      </div>
    </CardBody>
  );
};

export default InvalidLinkForOnboarding;
