import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import { strings } from '../../../../constants/localization';
import { moment } from '../../../../constants';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../../../constants/styleScrollbar';
import LoadingMessage from '../../../statusMessages/LoadingMessage';

class LogDetails extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
  }

  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.resetScrollPosition();
    }
  }

  render() {
    if (this.props.activeLead !== undefined) {
      const { activeLead, logs } = this.props;
      const creditFacilityNumber = activeLead.creditFacilityInformation.number;
      const leadLog = logs[creditFacilityNumber];

      return (
        <Card className='custom-card logs table-responsive-sm'>
          <CardBody>
            <div className='header-information'>
              <CardTitle>{strings.logTab}</CardTitle>
            </div>
            <div className='log-header'>
              <div className='log-cell log-date'>{strings.logTabDate}</div>
              <div className='log-cell log-event'>{strings.logTabEvent}</div>
              <div className='log-cell log-user'>{strings.logTabUser}</div>
            </div>
            <div className='log-information'>
              <Scrollbars
                ref={this.scrollRef}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...trackVerticalStyle,
                    }}
                  />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...thumbVerticalStyle,
                    }}
                  />
                )}
              >
                <div className='scrollbar-wrapper'>
                  {leadLog === undefined ? (
                    <LoadingMessage />
                  ) : (
                    <div className='log-body'>
                      {leadLog.map((log) => (
                        <div className='log-entry' key={log.id}>
                          <div className='log-cell log-date'>
                            <div>
                              {moment
                                .utc(log.createdDate)
                                .local()
                                .format('DD. MMM. YYYY')
                                .toLowerCase()}
                            </div>
                            <div>
                              {moment
                                .utc(log.createdDate)
                                .local()
                                .format('HH:mm')}
                            </div>
                          </div>
                          <div className='log-cell log-event'>
                            <div className='event-details'>
                              {log.event.replace(/\\n/g, '\n\r')}
                            </div>
                            {log.installmentLoan !== null && (
                              <div>{`Loan id: ${log.installmentLoan}`}</div>
                            )}
                          </div>
                          <div className='log-cell log-user'>
                            {log.username}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Scrollbars>
            </div>
          </CardBody>
        </Card>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    activeLead: state.lead.activeLead,
    logs: state.log,
  };
}

LogDetails.propTypes = {
  activeTab: PropTypes.string,
};

export default connect(mapStateToProps)(LogDetails);
