import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { Formik, Form, FieldArray } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
  moment,
  momentFormat,
  patientFilterStatuses,
  loanFilterStatuses,
  loanFilterStatusesNonAdmins,
  creditFacilityFilterStatuses,
  creditFacilityFilterStatusesNonAdmins,
  brandFilterStatuses,
} from '../../../constants';
import { filterEmitted } from '../../../actions/leadActions';
import { invoicesStatusMap } from '../../../constants/mappings';
import { isAdmin, isManager } from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import SearchClinic from '../../common/SearchClinic/SearchClinic';
import DropDown from '../../common/DropDown/DropDown';

const LeadsFilterMenu = ({
  closeFilterMenu,
  clearFilterValue,
  triggerClearFilterEffect,
}) => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const currentUser = useSelector((state) => state.user?.user);
  const managerCompanies = useSelector(
    (state) => state.company.managersCompaniesList
  );
  const selectedCompany = useSelector(
    (state) => state.company.selectedCompanyRef
  );
  const [roleLoanFilter, setRoleLoanFilter] = useState([]);
  const [roleCreditFilter, setRoleCreditFilter] = useState([]);
  const [clinicsListForCompanyChain, setClinicsListForCompanyChain] = useState(
    []
  );

  const formikRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    resetScrollPosition();
  });

  useEffect(() => {
    if (managerCompanies && managerCompanies.length > 0) {
      const companies = managerCompanies
        .filter((company) => company.status !== 'DEACTIVE')
        .map((item) => ({
          front: item.referenceNumber,
          top: item.companyName,
          bottom: `${item.address}, ${item.zipCode} ${item.city}`,
          value: item.referenceNumber,
        }));
      if (companies.length > 0) {
        setClinicsListForCompanyChain(companies);
      }
    }
  }, [managerCompanies]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !event.target.closest('#filterResultsIcon')
      ) {
        closeFilterMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  useEffect(() => {
    if (isAdmin(currentUser)) {
      setRoleLoanFilter(loanFilterStatuses);
      setRoleCreditFilter(creditFacilityFilterStatuses);
    } else {
      setRoleLoanFilter(loanFilterStatusesNonAdmins);
      setRoleCreditFilter(creditFacilityFilterStatusesNonAdmins);
    }
  }, [currentUser]);

  useEffect(() => {
    if (clearFilterValue) {
      if (clearFilterValue === 'all') {
        formikRef.current.resetForm();
        formikRef.current.setFieldValue('clinic', {});
        dispatch(filterEmitted({}));
      } else if (formikRef.current) {
        const { key, value } = clearFilterValue || {};
        const findOptionAndRemove = (fieldArrayName, value) => {
          // if option to remove is part of the array, filter the array and set the new array
          // otherwise set the value to empty string
          if (Array.isArray(formikRef.current?.values[fieldArrayName])) {
            const updatedOptions = formikRef.current?.values[
              fieldArrayName
            ].filter((option) => option !== value);
            formikRef.current.setFieldValue(fieldArrayName, updatedOptions);
          } else {
            formikRef.current.setFieldValue(
              fieldArrayName,
              fieldArrayName === 'clinic' ? {} : ''
            );
          }
        };
        switch (key) {
          case 'brand':
            findOptionAndRemove('brandStatusOptions', value);
            break;
          case 'clientStatus':
            findOptionAndRemove('patientStatusOptions', value);
            break;
          case 'creditFacilityStatus':
            findOptionAndRemove('creditStatusOptions', value);
            break;
          case 'installmentLoanStatus':
            findOptionAndRemove('loanStatusOptions', value);
            break;
          case 'companyReferenceNumber':
            findOptionAndRemove('clinic', value);
            break;
          case 'dateLowerInterval':
            findOptionAndRemove('dateMin', value);
            break;
          case 'dateUpperInterval':
            findOptionAndRemove('dateMax', value);
            break;
          default:
            break;
        }
      }
    }
  }, [clearFilterValue, triggerClearFilterEffect]);

  const resetScrollPosition = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.view.scrollTop = 0;
    }
  };

  const handleFilterSubmit = (values) => {
    let filters = {},
      creditStatusArray = [];
    const {
      patientStatusOptions,
      creditStatusOptions,
      loanStatusOptions,
      brandStatusOptions,
      clinic,
      dateMin,
      dateMax,
    } = values;
    filters.brand = brandStatusOptions;
    filters.clientStatus = patientStatusOptions;
    if (Object.keys(clinic).length > 0) {
      // for admin sent the reference number from search clinic and for manager sent from dropdown
      filters.companyReferenceNumber =
        clinic?.referenceNumber || clinic?.value || null;
      filters.companyName = clinic?.companyName;
    }
    creditStatusOptions.forEach((value) => {
      if (value === 'ACTIVE_CREDIT_EXPIRED') {
        filters.creditExpired = true;
        creditStatusArray.push('ACTIVE');
      } else if (value === 'ACTIVE_CREDIT_UNEXPIRED') {
        filters.creditExpired = false;
        creditStatusArray.push('ACTIVE');
      } else if (value === 'LATE_PAYMENT_CREDIT') {
        creditStatusArray.push('LATE_PAYMENT');
      } else {
        creditStatusArray.push(value);
      }
    });
    filters.creditFacilityStatus = creditStatusArray;
    if (dateMin !== '' && dateMax !== '') {
      filters.dateLowerInterval = moment(dateMin).format('YYYY-MM-DD 00:00:00');
      filters.dateUpperInterval = moment(dateMax).format('YYYY-MM-DD 00:00:00');
    }
    filters.installmentLoanStatus = loanStatusOptions;

    dispatch(filterEmitted(filters));
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        patientStatusOptions: [],
        creditStatusOptions: [],
        loanStatusOptions: [],
        brandStatusOptions: [],
        clinic: selectedCompany || {},
        dateMin: '',
        dateMax: '',
      }}
      onSubmit={(values) => {
        handleFilterSubmit(values);
        closeFilterMenu();
      }}
      onReset={() => {
        dispatch(filterEmitted({}));
        closeFilterMenu();
      }}
    >
      {({ values, setFieldValue }) => (
        <Scrollbars
          ref={scrollRef}
          style={
            isAdmin(currentUser) ? { height: '70vh' } : { height: '450px' }
          }
        >
          <Form
            ref={filterRef}
            className={`${!isAdmin(currentUser) ? 'not-admin' : ''} `}
          >
            {/* PATIENTS STATUS */}
            {isAdmin(currentUser) && (
              <Row>
                <Col xs='12' className='form-group'>
                  <div className='filter-title'>{strings.patientStatus}</div>
                  <FieldArray name='patientStatusOptions'>
                    {({ push, remove }) =>
                      patientFilterStatuses.map((status, index) => (
                        <div
                          className='patient-status-filter custom-control custom-checkbox'
                          key={status}
                        >
                          <Input
                            className='custom-control-input topic-control'
                            id={`patient-status-${index}`}
                            type='checkbox'
                            name={`patientStatusOptions[${index}]`}
                            value={status}
                            checked={
                              values.patientStatusOptions
                                ? values.patientStatusOptions.indexOf(status) >
                                  -1
                                : false
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              if (isChecked) {
                                push(status);
                              } else {
                                const optionIndex =
                                  values.patientStatusOptions.indexOf(status);
                                if (optionIndex !== -1) {
                                  remove(optionIndex);
                                }
                              }
                            }}
                          />
                          <Label
                            for={`patient-status-${index}`}
                            className='text-capitalize custom-control-label'
                          >
                            {strings[invoicesStatusMap[status].statusText]}
                          </Label>
                        </div>
                      ))
                    }
                  </FieldArray>
                </Col>
              </Row>
            )}

            {/* CREDIT STATUS */}
            <Row>
              <Col xs='12' className='form-group'>
                <div className='filter-title'>{strings.creditStatus}</div>
                <FieldArray name='creditStatusOptions'>
                  {({ push, remove }) =>
                    roleCreditFilter.map((status, index) => (
                      <div
                        className='credit-status-filter custom-control custom-checkbox'
                        key={status}
                      >
                        <Input
                          className='custom-control-input topic-control'
                          id={`credit-status-${index}`}
                          type='checkbox'
                          name={`creditStatusOptions[${index}]`}
                          value={status}
                          checked={
                            values.creditStatusOptions
                              ? values.creditStatusOptions.indexOf(status) > -1
                              : false
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              push(status);
                            } else {
                              const optionIndex =
                                values.creditStatusOptions.indexOf(status);
                              if (optionIndex !== -1) {
                                remove(optionIndex);
                              }
                            }
                          }}
                        />
                        <Label
                          for={`credit-status-${index}`}
                          className='text-capitalize custom-control-label'
                        >
                          {strings[invoicesStatusMap[status].statusText]}
                        </Label>
                      </div>
                    ))
                  }
                </FieldArray>
              </Col>
            </Row>

            {/* LOAN STATUS */}
            <Row>
              <Col xs='12' className='form-group'>
                <div className='filter-title'>{strings.loanStatus}</div>
                {isAdmin(currentUser) && (
                  <FieldArray name='loanStatusOptions'>
                    {({ push, remove }) =>
                      roleLoanFilter.map((status, index) => (
                        <div
                          className='loan-status-filter custom-control custom-checkbox'
                          key={status}
                        >
                          <Input
                            className='custom-control-input topic-control'
                            id={`loan-status-${index}`}
                            type='checkbox'
                            name={`loanStatusOptions[${index}]`}
                            value={status}
                            checked={
                              values.loanStatusOptions
                                ? values.loanStatusOptions.indexOf(status) > -1
                                : false
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              if (isChecked) {
                                push(status);
                              } else {
                                const optionIndex =
                                  values.loanStatusOptions.indexOf(status);
                                if (optionIndex !== -1) {
                                  remove(optionIndex);
                                }
                              }
                            }}
                          />
                          <Label
                            for={`loan-status-${index}`}
                            className='text-capitalize custom-control-label'
                          >
                            {strings[invoicesStatusMap[status].statusText]}
                          </Label>
                        </div>
                      ))
                    }
                  </FieldArray>
                )}

                {!isAdmin(currentUser) && (
                  <FieldArray name='loanStatusOptions'>
                    <div className='RadioButton_component'>
                      {roleLoanFilter.map((status, index) => (
                        <div
                          className='radio-button-container'
                          key={index}
                          checked={
                            values.loanStatusOptions
                              ? values.loanStatusOptions.indexOf(status) > -1
                              : false
                          }
                          onClick={() => {
                            setFieldValue('loanStatusOptions', [status]);
                          }}
                        >
                          <div
                            type='radio'
                            className={`radio-button ${
                              values.loanStatusOptions.indexOf(status) > -1
                                ? 'checked'
                                : ''
                            }`}
                          />
                          <span className='label'>
                            {strings[invoicesStatusMap[status].statusText]}
                          </span>
                        </div>
                      ))}
                    </div>
                  </FieldArray>
                )}
              </Col>
            </Row>

            {/* BRAND */}

            {isAdmin(currentUser) && (
              <Row>
                <Col xs='12' className='form-group'>
                  <div className='filter-title'>{strings.brandStatus}</div>
                  <FieldArray name='brandStatusOptions'>
                    {({ push, remove }) =>
                      brandFilterStatuses.map((status, index) => (
                        <div
                          className='brand-status-filter custom-control custom-checkbox'
                          key={index}
                        >
                          <Input
                            className='custom-control-input topic-control'
                            id={`brand-status-${index}`}
                            type='checkbox'
                            name={`brandStatusOptions[${index}]`}
                            value={status}
                            checked={
                              values.brandStatusOptions
                                ? values.brandStatusOptions.indexOf(status) > -1
                                : false
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              if (isChecked) {
                                push(status);
                              } else {
                                const optionIndex =
                                  values.brandStatusOptions.indexOf(status);
                                if (optionIndex !== -1) {
                                  remove(optionIndex);
                                }
                              }
                            }}
                          />
                          <Label
                            for={`brand-status-${index}`}
                            className='text-capitalize custom-control-label'
                          >
                            {strings[invoicesStatusMap[status].statusText]}
                          </Label>
                        </div>
                      ))
                    }
                  </FieldArray>
                </Col>
              </Row>
            )}

            {isAdmin(currentUser) && (
              <>
                {/* CLINIC SEARCH */}
                <Row>
                  <Col xs='12'>
                    <SearchClinic
                      selectClinic={(clinic) => {
                        setFieldValue('clinic', clinic);
                      }}
                      selected={values?.clinic}
                    />
                  </Col>
                </Row>

                {/* DATE INPUTS */}
                <Row className='form-row'>
                  <Col xs='12'>
                    <Label for='date-interval'>{strings.dateInterval}</Label>
                  </Col>
                  <Col xs='6' md='6' className='form-group date-input'>
                    <Input
                      type='date'
                      name='dateMin'
                      data-date={momentFormat}
                      data-date-format={momentFormat}
                      value={values?.dateMin || ''}
                      onChange={(event) => {
                        setFieldValue('dateMin', event.target.value);
                      }}
                    />
                  </Col>
                  <Col xs='6' md='6' className='form-group date-input'>
                    <Input
                      type='date'
                      name='dateMax'
                      className='dash-line'
                      data-date={momentFormat}
                      data-date-format={momentFormat}
                      value={values?.dateMax || ''}
                      onChange={(event) => {
                        setFieldValue('dateMax', event.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

            {/* Show clinics dropdown list for manager */}
            {isManager(currentUser) &&
              clinicsListForCompanyChain?.length > 0 && (
                <Row>
                  <Col id='filter-dropdown' xs='12' className='form-group'>
                    <div className='filter-title'>{strings.clinic}</div>
                    <DropDown
                      options={clinicsListForCompanyChain}
                      currentValue={values?.clinic}
                      placeholder={strings.chainSelectLocation}
                      setValue={(value) => {
                        setFieldValue('clinic', value);
                      }}
                    />
                  </Col>
                </Row>
              )}

            <Row>
              <Col xs='6' md='6' className='mt-3'>
                <Button type='reset' color='light' block>
                  <i className='di di-close' />
                  &ensp;
                  {strings.resetFilter}
                </Button>
              </Col>
              <Col xs='6' md='6' className='mt-3'>
                <Button type='submit' color='light' className='btn-blue' block>
                  <i className='di di-check' />
                  &ensp;
                  {strings.submitFilter}
                </Button>
              </Col>
            </Row>
          </Form>
        </Scrollbars>
      )}
    </Formik>
  );
};

export default LeadsFilterMenu;
