import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { success, error as notifError } from 'react-notification-system-redux';
import { strings } from '../../constants/localization';
import {
  moment,
  momentFormatLong,
  notificationDismissDuration,
} from '../../constants';
import {
  currencyFormatDA,
  isAdmin,
  isManager,
  addNotification,
  showDelete,
} from '../../constants/utils';
import { ROUTE_COMPANIES } from '../../constants/routes';
import KebabMenu from '../common/KebabMenu/KebabMenu';
import { newInvoicesStatusMap } from '../../constants/mappings';
import {
  resendEmail,
  resendSMS,
  deleteInstallmentLoan,
  cloneInvoice,
  getLoanDetails,
} from '../../actions/installmentLoanActions';
import { getUpdatedPatientCreditFacility } from '../../actions/leadActions';
import { getLogsCreditFacility } from '../../actions/logActions';
import PaidOutDetails from './PaidOutDetails';
import DentiModal from '../common/DentiModal';
import ConfirmationModal from '../common/ConfirmationModal';
import LoanDetails from './LoanDetails';

const InvoiceHistoryListItem = (props) => {
  const { invoice, lead, onlyOneInvoice, firstScrollItem } = props || {};
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const isUserAdmin = isAdmin(user);
  const isUserManager = isManager(user);

  const [paidOutModal, setPaidOutModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loadingConfirmBtn, setLoadingConfirmBtn] = useState(false);
  const [modalType, setModalType] = useState('');
  const [draftAgreement, setDraftAgreement] = useState({});
  const [showDraftAgreemntPlan, setShowDraftAgreemntPlan] = useState(false);

  const shownStatus = (() => {
    switch (invoice.status) {
      case 'WAITING_FOR_SIGNATURE':
        return 'PENDING';
      case 'ACCEPTED':
        if (!invoice.paidOutDate) {
          if (invoice?.installmentInterestRate === 0) {
            return 'APPROVED_ZERO_INTEREST';
          }
          return 'APPROVED';
        }
        if (invoice.paidOutDate) {
          if (invoice?.installmentInterestRate === 0) {
            return 'PAYED_OUT_ZERO_INTEREST';
          }
          return 'PAYED_OUT';
        }
        break;
      case 'PAYED':
        if (invoice.paidOutDate) {
          if (invoice?.installmentInterestRate === 0) {
            return 'PAYED_OUT_ZERO_INTEREST';
          }
          return 'PAYED_OUT';
        }
        break;
      case 'CONSOLIDATED':
      case 'RESTRUCTURED':
      case 'LATE_PAYMENT':
      case 'SETTLED':
        if (!invoice.paidOutDate) {
          return 'APPROVED';
        }
        if (invoice.paidOutDate) {
          return 'PAYED_OUT';
        }
        break;
      case 'NOT_ACCEPTED':
        return 'DECLINED';
      default:
        return 'UNDEFINED';
    }
    return 'UNDEFINED';
  })();

  const closeConfirmModalAndStopLoading = () => {
    setShowConfirmModal(false);
    setLoadingConfirmBtn(false);
  };

  const confirmAction = () => {
    const activePatientCredit = lead.creditFacilityInformation;
    setLoadingConfirmBtn(true);
    if (modalType === 'sms') {
      dispatch(resendSMS(invoice?.number))
        .then((result) => {
          dispatch(
            success(
              addNotification(
                `${strings.successTextMessageSent} ${result.payload.data.phone}`,
                strings.resendSMSTitleSuccess,
                notificationDismissDuration
              )
            )
          );
          closeConfirmModalAndStopLoading();
        })
        .catch(() => {
          dispatch(
            notifError(
              addNotification(
                strings.errorTextMessageSent,
                strings.resendSMSTitleError,
                notificationDismissDuration
              )
            )
          );
          closeConfirmModalAndStopLoading();
        });
    } else if (modalType === 'email') {
      dispatch(resendEmail(invoice?.number))
        .then((result) => {
          dispatch(
            success(
              addNotification(
                `${strings.successEmailSent} ${result.payload.data.email}`,
                strings.resendEmailTitleSuccess,
                notificationDismissDuration
              )
            )
          );
          closeConfirmModalAndStopLoading();
        })
        .catch(() => {
          dispatch(
            notifError(
              addNotification(
                strings.errorEmailSent,
                strings.resendEmailTitleError,
                notificationDismissDuration
              )
            )
          );
          closeConfirmModalAndStopLoading();
        });
    } else if (modalType === 'delete') {
      dispatch(deleteInstallmentLoan(invoice?.number))
        .then(() => {
          dispatch(getUpdatedPatientCreditFacility(activePatientCredit.id));
          dispatch(getLogsCreditFacility(activePatientCredit.number));
          dispatch(
            success(
              addNotification(
                `${strings.formatString(
                  strings.successTextDelete,
                  invoice?.number
                )}`,
                strings.deleteTitleSuccess,
                notificationDismissDuration
              )
            )
          );
          closeConfirmModalAndStopLoading();
        })
        .catch(() => {
          dispatch(
            notifError(
              addNotification(
                `${strings.formatString(
                  strings.errorTextDelete,
                  invoice?.number
                )}`,
                strings.deleteTitleError,
                notificationDismissDuration
              )
            )
          );
          closeConfirmModalAndStopLoading();
        });
    }
  };

  const renderConfirmationModal = () => {
    let modalElement = null;
    const { firstName, lastName, phone, email } =
      lead?.leadClientInformation || {};
    let modalTitle = '',
      modalText = '';
    if (showConfirmModal === true) {
      switch (modalType) {
        case 'delete':
          modalTitle = strings.deleteInvoiceTitle;
          modalText = `${strings.formatString(
            strings.confirmActionDelete,
            invoice.amount,
            `${firstName} ${lastName}`
          )}`;
          break;
        default:
          modalTitle = strings.invoiceTitle;
          modalText =
            modalType === 'sms'
              ? `${strings.confirmActionSMS} ${phone}?`
              : `${strings.confirmActionEmail} ${email}?`;
      }

      modalElement = (
        <DentiModal
          key={1}
          close={() => setShowConfirmModal(false)}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              confirmAction();
            }}
            cancelAction={() => setShowConfirmModal(false)}
            loading={loadingConfirmBtn}
          >
            <div>
              <p className='title'>{modalTitle}</p>
              <p className='confirm-message'>{modalText}</p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      );
    }
    return modalElement;
  };

  const openLoanDetailsModal = () => {
    dispatch(getLoanDetails(invoice.number))
      .then((result) => {
        if (result.payload.data) {
          setDraftAgreement(result.payload.data);
          setShowDraftAgreemntPlan(true);
        }
      })
      .catch((error) => new Error(error));
  };

  const generateFlagMap = (invoice) => {
    const flagMap = {
      isPaidOut: false,
      isLoanDraft: false,
      isPending: false,
      canBeCloned: false,
      canBeDeleted: false,

      hasAnyFlag: false,
    };
    // is paid out
    if (invoice.paidOutDate !== null) {
      flagMap.isPaidOut = true;
    }
    // is loan draft
    if (
      invoice.status === 'NOT_ACCEPTED' ||
      invoice.status === 'WAITING_FOR_SIGNATURE'
    ) {
      flagMap.isLoanDraft = true;
    }
    // is pending singature
    if (invoice.status === 'WAITING_FOR_SIGNATURE') {
      flagMap.isPending = true;
    }

    // can be clonned
    if (
      invoice.status !== 'WAITING_FOR_SIGNATURE' &&
      invoice.invoiceNumber !== 'Omlægning' &&
      props.showCloneBtn
    ) {
      flagMap.canBeCloned = true;
    }

    //can be deleted
    if (
      invoice.status === 'WAITING_FOR_SIGNATURE' ||
      invoice.status === 'NOT_ACCEPTED' ||
      (invoice.status === 'ACCEPTED' &&
        invoice.paidOutDate === null &&
        onlyOneInvoice)
    ) {
      flagMap.canBeDeleted = true;
    }

    // if at least one of the flags is true, then hasAnyFlag is true
    if (
      flagMap.isPaidOut ||
      flagMap.isLoanDraft ||
      flagMap.isPending ||
      flagMap.canBeCloned ||
      flagMap.canBeDeleted
    ) {
      flagMap.hasAnyFlag = true;
    }
    return flagMap;
  };

  const flagMap = generateFlagMap(invoice);

  return (
    <>
      <div
        className={`invoice-list-item table-row-div
      ${
        newInvoicesStatusMap[shownStatus] !== undefined
          ? newInvoicesStatusMap[shownStatus].className
          : ``
      }
      `}
      >
        <div className='table-cell-div'>
          {moment(invoice.createdDate).format(momentFormatLong).toLowerCase()}
        </div>
        <div className='table-cell-div'>{invoice.invoiceNumber}</div>
        {(isUserAdmin || isUserManager) && (
          <div className='table-cell-div clinic'>
            {isUserAdmin && (
              <div
                className={`${isUserAdmin ? 'has-tooltip' : ''} ${
                  firstScrollItem ? 'bottom' : 'top'
                }`}
              >
                <Link
                  to={{
                    pathname: ROUTE_COMPANIES,
                    state: {
                      ref: invoice.companyReferenceNumber,
                    },
                  }}
                >
                  {invoice.companyReferenceNumber}
                </Link>
                <span className='tooltip'>{invoice.companyName}</span>
              </div>
            )}
            {isUserManager && invoice.companyName}
          </div>
        )}
        <div className='table-cell-div status'>
          <div className='icon'>
            <i
              className={`lnir ${newInvoicesStatusMap[shownStatus].statusIcon}`}
            />
          </div>
          {strings[newInvoicesStatusMap[shownStatus].statusText]}
        </div>
        <div className='table-cell-div amount'>
          <span>
            {currencyFormatDA(parseFloat(invoice.amount), true, true)}
          </span>
        </div>
        <div className='table-cell-div'>
          {flagMap.hasAnyFlag && (
            <div className='actions-menu'>
              <KebabMenu scrollToBottom={props.scrollToBottom}>
                {/* settle invoice action */}
                {flagMap.isPaidOut && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={() => setPaidOutModal(true)}
                  >
                    <i className='lnir lnir-bookmark icon' />
                    {strings.seeReceiptKebabMenu}
                  </div>
                )}

                {/* open modal draft of agreement*/}
                {flagMap.isLoanDraft && isUserAdmin && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={() => openLoanDetailsModal()}
                  >
                    <i className='lnil lnil-list icon' />
                    {strings.loanDetailsDraftTooltip}
                  </div>
                )}

                {/* send sms action */}
                {flagMap.isPending && (
                  <>
                    <div
                      type='button'
                      color='light'
                      className='KebabMenu-item'
                      onClick={() => {
                        setModalType('sms');
                        setShowConfirmModal(true);
                      }}
                    >
                      <i className='di di-sms icon' />
                      {strings.smsResendTooltip}
                    </div>

                    {/* send email action */}
                    <div
                      type='button'
                      color='light'
                      className='KebabMenu-item'
                      onClick={() => {
                        setModalType('email');
                        setShowConfirmModal(true);
                      }}
                    >
                      <i className='lnir lnir-message-forward icon' />
                      {strings.emailResendTooltip}
                    </div>
                  </>
                )}

                {/* clone invoice action */}
                {flagMap.canBeCloned && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={() => {
                      dispatch(cloneInvoice(invoice));
                      props.openRegisterInvoice();
                    }}
                  >
                    <i className='lnir lnir-files icon' />
                    {strings.cloneTooltip}
                  </div>
                )}

                {/* delete invoice action */}

                {flagMap.canBeDeleted && showDelete(user, invoice.status) && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item last'
                    onClick={() => {
                      setModalType('delete');
                      setShowConfirmModal(true);
                    }}
                  >
                    <i className='lnir lnir-trash-can icon' />
                    {invoice.status === 'WAITING_FOR_SIGNATURE'
                      ? strings.deletePendingTooltip
                      : strings.deleteTooltip}
                  </div>
                )}
              </KebabMenu>
            </div>
          )}
        </div>
      </div>

      {/* Paid out modal */}
      {paidOutModal && (
        <DentiModal
          key={3}
          close={() => setPaidOutModal(false)}
          modalClass='paid-out-details-modal modal-dialog-centered modal-dialog-scrollable'
          modalClassName='paid-out-details-action'
          closeBtn
        >
          <PaidOutDetails invoice={invoice} />
        </DentiModal>
      )}

      {/* See agreement draft modal */}
      {showDraftAgreemntPlan && (
        <DentiModal
          key={2}
          close={() => setShowDraftAgreemntPlan(false)}
          modalClass='loan-details-modal modal-dialog-scrollable'
          modalClassName='loan-details-action'
          closeBtn
        >
          <LoanDetails
            loanNumber={invoice.number}
            loanDetails={draftAgreement}
          />
        </DentiModal>
      )}

      {/* Confirmation modal */}
      {renderConfirmationModal()}
    </>
  );
};
export default InvoiceHistoryListItem;
