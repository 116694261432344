import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

/* eslint-disable import/no-unresolved */
import { strings } from '../../constants/localization';
import { brandConfig, config } from '../../config';
import { ReactComponent as LogoIcon } from '../../resources/images/logo-black.svg';
import { ReactComponent as UserIcon } from '../../resources/icons/user-alt-5.svg';
import { ReactComponent as HouseIcon } from '../../resources/icons/house.svg';
import { ReactComponent as RedHeart } from '../../resources/images/red-heart.svg';
import DentiModal from '../common/DentiModal';
// eslint-disable-next-line
import LoginModal from './LoginModal';
import DAM from '../DAM';
import { isAuthenticated } from '../../constants/utils';

import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { ROUTE_START_CLIENT_PORTAL } from 'constants/routes';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux-custom';
import {
  createMitIdState,
  loadingClientPortalData,
} from 'actions/memberPortalActions';
import { useCriiptoVerify } from '@criipto/verify-react';

const MainLogin: React.FC = (props) => {
  const [showPartnerLogin, setShowPartnerLogin] = useState(false);
  const { loginWithRedirect } = useCriiptoVerify();
  const currentDomain = window.location.hostname;
  const isLocalhost = currentDomain === 'localhost';

  const [loadingMitIdRedirect, setLoadingMitIdRedirect] = useState(false);

  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const handleMemberLogin = () => {
    if (!isLocalhost) {
      setLoadingMitIdRedirect(true);
      const redirectToMitSundhedPlus = `${config.clientPortalUrl}${ROUTE_START_CLIENT_PORTAL}`;
      window.location.href = redirectToMitSundhedPlus;
    } else {
      dispatch(loadingClientPortalData(true));
      setLoadingMitIdRedirect(true);
      dispatch(createMitIdState())
        .then(() => {
          loginWithRedirect().then(() => {});
        })
        .catch(() => {
          setLoadingMitIdRedirect(false);
        });
    }
  };

  const renderPartnerLogin = () => {
    return (
      <DentiModal
        key={1}
        close={() => {
          setShowPartnerLogin(false);
        }}
        modalClass='modal-dialog-centered login-modal'
        closeBtn={false}
      >
        <LoginModal {...props} />
      </DentiModal>
    );
  };

  useEffect(() => {
    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');

    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  // detect if the user are using a IOS device and prevent to zoon in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        true
      );
    }

    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  return (
    <div id='main-login'>
      <aside>
        {/* <div className='login-container'> */}

        <a href='https://www.sundhedplus.dk'>
          <div className='sundhed-plus-logo'>
            <LogoIcon />
          </div>
        </a>

        {/* MEMBER LOGIN */}
        <div className='login-box'>
          <h2>{strings.loginForMembersTitle}</h2>
          <p>{strings.loginForMembersText}</p>
          <div className='button-wrapper'>
            <Button
              color='blue'
              disabled={loadingMitIdRedirect}
              onClick={() => handleMemberLogin()}
            >
              {loadingMitIdRedirect ? (
                <ButtonLoader />
              ) : (
                <>
                  {' '}
                  <UserIcon />
                  <span>{strings.loginForMembersButton}</span>
                </>
              )}
            </Button>
          </div>
        </div>

        {/* PARTNER LOGIN */}
        <div className='login-box'>
          <h2>{strings.loginForPartnersTitle}</h2>
          <p>{strings.loginForPartnersText}</p>
          <div className='button-wrapper'>
            <Button
              color='blue'
              onClick={() => {
                if (isAuthenticated()) {
                  return <DAM {...props} />;
                } else {
                  setShowPartnerLogin(true);
                }
              }}
            >
              <HouseIcon />
              <span>{strings.loginForPartnerButton}</span>
            </Button>
          </div>
        </div>
        <div className='need-help'>
          <h6>{strings.noLeadsQuestion}</h6>
          <p>
            <a href={`mailto:${brandConfig.contactEmail}`}>
              {brandConfig.contactEmail}
            </a>
            <span> | </span>
            <a href={`tel:${brandConfig.contactPhone}`}>
              {brandConfig.contactPhoneHtmlFormated}
            </a>
          </p>
        </div>
      </aside>
      <div className='image-container'>
        <RedHeart />
      </div>

      {showPartnerLogin && renderPartnerLogin()}
    </div>
  );
};

export default MainLogin;
