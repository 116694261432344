import {
  GET_COMPANY_CHAINS,
  SET_CURRENT_CHAIN,
  SET_CHAIN_ACTIVE_PAGE,
  SET_CHAIN_LOADING,
  CHAIN_LOCATIONS_LOADING,
  CHAIN_USERS_LOADING,
  GET_CHAIN_LOCATIONS,
  GET_USERS_ASSOCIATED,
  COMPANY_CHAIN_SEARCH_EMITTED,
  COMPANY_CHAINS_LOADING,
  COMPANY_CHAINS_EMPTY,
  COMPANY_CHAINS_SUCCESS,
  COMPANY_CHAINS_FAILURE,
  EDIT_COMPANY_CHAINS_LOADING,
  GET_MARKETING_FEE_DENTI,
  GET_MARKETING_FEE_SUNDHED,
  GET_COMPANY_CHAIN_STATUS,
  COMPANY_CHAIN_UPDATE_FILTER,
  GET_ALL_FILTERED_CHAINS,
  UPDATE_COMPANY_CHAIN_INFO,
  UPDATE_COMPANY_CHAIN_STATUS,
  UPDATE_COMPANY_STATUS_IN_ACTIVE_CHAIN,
  UPDATE_CHAIN_INTERNAL_NOTE,
  UPDATE_ACTIVE_CHAIN_IN_LIST,
} from '../constants';

const defaultState = {
  usersAssociated: [],
  loading: '',
};
export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_COMPANY_CHAINS:
      return {
        ...state,
        companyChainList: action.payload,
      };
    case SET_CURRENT_CHAIN:
      return {
        ...state,
        activeChain: action.payload,
      };
    case SET_CHAIN_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_CHAIN_LOADING:
      return {
        ...state,
        chainLoading: action.payload,
      };
    case GET_CHAIN_LOCATIONS: {
      return {
        ...state,
        chainLocations: action.payload,
      };
    }
    case CHAIN_LOCATIONS_LOADING: {
      return {
        ...state,
        chainLocationsLoading: action.payload,
      };
    }
    case GET_USERS_ASSOCIATED:
      return {
        ...state,
        usersAssociated: action.payload,
      };
    case CHAIN_USERS_LOADING: {
      return {
        ...state,
        chainUsersLoading: action.payload,
      };
    }

    case COMPANY_CHAINS_LOADING:
    case COMPANY_CHAINS_EMPTY:
    case COMPANY_CHAINS_SUCCESS:
    case COMPANY_CHAINS_FAILURE:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_MARKETING_FEE_DENTI:
      return {
        ...state,
        marketingFeeDenti: action.payload,
      };
    case GET_MARKETING_FEE_SUNDHED:
      return {
        ...state,
        marketingFeeSundhed: action.payload,
      };
    case GET_COMPANY_CHAIN_STATUS:
      return {
        ...state,
        companyChainStatus: action.payload,
      };
    case EDIT_COMPANY_CHAINS_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    case COMPANY_CHAIN_SEARCH_EMITTED:
      return {
        ...state,
        searchActive: action.payload.search,
      };
    case GET_ALL_FILTERED_CHAINS:
      return {
        ...state,
        chainsFilteredBySearch: action.payload,
      };

    case COMPANY_CHAIN_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case UPDATE_COMPANY_CHAIN_INFO:
      return {
        ...state,
        activeChain: Object.assign({}, state.activeChain, action.payload),
      };
    case UPDATE_COMPANY_CHAIN_STATUS:
      return {
        ...state,
        activeChain: Object.assign({}, action.payload),
      };
    case UPDATE_CHAIN_INTERNAL_NOTE:
      return {
        ...state,
        activeChain: Object.assign({}, action.payload),
      };
    case UPDATE_ACTIVE_CHAIN_IN_LIST:
      const foundIndex = state.companyChainList.content.findIndex(
        (obj) => obj.id === state.activeChain.id
      );
      const newContentArray = [...state.companyChainList.content]; //make a new array
      newContentArray[foundIndex] = action.payload;
      return {
        ...state,
        companyChainList: {
          ...state.companyChainList,
          content: newContentArray,
        },
      };
    case UPDATE_COMPANY_STATUS_IN_ACTIVE_CHAIN:
      if (state.activeChain) {
        const foundCompanyIndex = state.activeChain.companies.findIndex(
          (obj) => obj.id === action.payload.id
        );
        const newCompaniesArray = [...state.activeChain.companies];
        newCompaniesArray[foundCompanyIndex].status = action.payload.status;
        return {
          ...state,
          activeChain: {
            ...state.activeChain,
            companies: newCompaniesArray,
          },
        };
      }
      break;
    default:
      return state;
  }
}
