import {
  GET_COMPANIES,
  GET_COMPANIES_BY_CATEGORY_COUNT,
  SET_CURRENT_COMPANY,
  SET_CURRENT_MANAGER_COMPANY,
  COMPANY_SEARCH_EMITTED,
  SET_COMPANY_ACTIVE_PAGE,
  COMPANIES_EMPTY,
  COMPANIES_FAILURE,
  COMPANIES_LOADING,
  COMPANIES_SUCCESS,
  DISPLAY_LEADS_FOR_COMPANY,
  EDIT_COMPANY_LOADING,
  SET_COMPANY_INTERNAL_NOTE,
  COMPANY_UPDATE_FILTER,
  GET_ALL_FILTERED_COMPANIES,
  DELETE_COMPANY,
  GET_COMPANIES_FOR_CHAIN,
  GET_COMPANIES_LIST_FOR_MANAGERS,
  GET_SIMPLE_SEARCH_CLINIC_PAGINATED,
  GET_ALL_PUBLIC_COMPANY_CATEGORIES,
  SET_CURRENT_COMPANY_REF_FROM_ROUTER,
  UPDATE_COMPANY_STATUS,
  UPDATE_ACTIVE_COMPANY_ITEM_LIST,
  SET_COMPANY_LOADING,
  UPDATE_COMPANY_INFORMATION,
  GET_ALL_COMPANIES_LIST_FOR_MANAGERS,
  UPDATE_COMPANY_MANAGER_STATUS,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE_FOR_MANAGER,
  UPDATE_COMPANY_MANAGER_LIST,
  UPDATE_COMPANY_INFORMATION_FOR_MANAGER,
  UPDATE_COMPANY_CHAIN_OF_COMPANY,
  GET_CURRENT_DENTIST_COMPANY,
  UPDATE_DENTIST_COMPANY_STATUS,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companiesList: action.payload,
      };
    case UPDATE_COMPANY_STATUS:
      return {
        ...state,
        activeCompany: Object.assign({}, action.payload),
      };
    case UPDATE_COMPANY_MANAGER_STATUS:
      return {
        ...state,
        activeManagerCompany: Object.assign({}, action.payload),
      };
    case GET_CURRENT_DENTIST_COMPANY:
      return {
        ...state,
        currentDentistCompany: action.payload,
      };
    case UPDATE_DENTIST_COMPANY_STATUS:
      return {
        ...state,
        currentDentistCompany: Object.assign({}, action.payload),
      };

    case UPDATE_COMPANY_CHAIN_OF_COMPANY:
      return {
        ...state,
        activeCompany: Object.assign({}, action.payload),
      };

    case UPDATE_ACTIVE_COMPANY_ITEM_LIST:
      const foundIndex = state.companiesList.content.findIndex(
        (obj) => obj.id === state.activeCompany.id
      );
      const newContentArray = [...state.companiesList.content];
      newContentArray[foundIndex] = action.payload;
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          content: newContentArray,
        },
      };
    case SET_COMPANY_LOADING:
      return {
        ...state,
        companyLoading: action.payload,
      };
    case UPDATE_COMPANY_INFORMATION:
      return {
        ...state,
        activeCompany: Object.assign({}, state.activeCompany, action.payload),
      };
    case UPDATE_COMPANY_INFORMATION_FOR_MANAGER:
      return {
        ...state,
        activeManagerCompany: Object.assign(
          {},
          state.activeManagerCompany,
          action.payload
        ),
      };

    case UPDATE_COMPANY_PROFILE:
      let newCompany = Object.assign({}, state.activeCompany, action.payload);
      return {
        ...state,
        activeCompany: newCompany,
      };
    case UPDATE_COMPANY_PROFILE_FOR_MANAGER:
      return {
        ...state,
        activeManagerCompany: Object.assign(
          {},
          state.activeManagerCompany,
          action.payload
        ),
      };
    case GET_COMPANIES_FOR_CHAIN:
      return {
        ...state,
        chainCompaniesList: action.payload,
      };
    case GET_COMPANIES_LIST_FOR_MANAGERS:
      return {
        ...state,
        managersCompaniesList: action.payload,
      };
    case GET_ALL_COMPANIES_LIST_FOR_MANAGERS:
      return {
        ...state,
        managersCompaniesListAll: action.payload,
      };
    case UPDATE_COMPANY_MANAGER_LIST:
      const filtetrCompanyList = state.managersCompaniesList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      const newCompanyList = [...state.managersCompaniesList];
      newCompanyList[filtetrCompanyList] = action.payload;

      return {
        ...state,
        managersCompaniesListAll: newCompanyList,
      };
    case GET_COMPANIES_BY_CATEGORY_COUNT:
      return {
        ...state,
        companiesByCategory: action.payload,
      };
    case GET_ALL_FILTERED_COMPANIES:
      return {
        ...state,
        companiesFilteredBySearch: action.payload,
      };
    case GET_SIMPLE_SEARCH_CLINIC_PAGINATED:
      return {
        ...state,
        paginatedFilteredClinics: action.payload,
      };
    case GET_ALL_PUBLIC_COMPANY_CATEGORIES:
      return {
        ...state,
        publicCompanyCategories: action.payload,
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        activeCompany: action.payload,
      };
    case SET_CURRENT_MANAGER_COMPANY:
      return {
        ...state,
        activeManagerCompany: action.payload,
      };
    case SET_COMPANY_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_CURRENT_COMPANY_REF_FROM_ROUTER:
      return {
        ...state,
        currentRefFromRouter: action.payload,
      };
    case COMPANY_SEARCH_EMITTED:
    case SET_COMPANY_INTERNAL_NOTE:
      return {
        ...state,
        searchActive: action.payload.search,
      };
    case COMPANY_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case DISPLAY_LEADS_FOR_COMPANY:
      return {
        ...state,
        selectedCompanyRef: action.payload,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        deleteCompany: action.payload,
      };
    case COMPANIES_EMPTY:
    case COMPANIES_FAILURE:
    case COMPANIES_SUCCESS:
    case COMPANIES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EDIT_COMPANY_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    default:
      return state;
  }
}
