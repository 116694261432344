import {
  STORE_PSD2_LOGIN_DATA,
  STORE_BUDGET_TRANSACTION_ID,
  ADDED_ONE_BANK_SUCESFULLY,
  ESKAT_ENCRYPT_REQUEST,
  SET_ONBOARDING_DATA,
  SET_ONBOARDING_STEP,
  SET_PREVIOUS_VISITED_STEP,
  TRIGGERED_POP_ALLOWED,
  ADD_ERROR_LOG,
  STORE_ONBOARDING_VISITED_STEPS,
  GET_BUDGET_REFERENCE,
  GET_BUDGET_REVISED,
  SAVE_CLIENT_FINAL_BUDGET,
  GET_BUDGET_FINAL,
  STORE_UPLOADED_DOCUMENTATION,
  UPDATE_STORED_CLIENT_NOTE,
  UPDATE_DOCUMENTATION_ITEM_FROM_WS,
  UPDATE_STORED_DOCUMENTS,
  STORE_IS_GUIDE_VISITED,
  GET_COMPANIES_FOR_COMPANY_CHAIN_SMARTLINK,
  STORE_MISSING_BUDGET_ITEMS,
  CLEAR_ONBOADING_DATA,
  CLEAR_STORED_DOCUMENTS,
  SET_ONBOARDING_DATA_BASED_CREDIT_APPLICATION,
  SET_UPLOAD_REQUIRED_FILES_FINISHED,
  SEND_MANUAL_INTERVENTION_EMAIL,
  CREDIT_APPLICATION_DENIED_STEP,
  CREDIT_APPLICATION_ABORTED_STEP,
} from '../constants';

const defaultState = {
  data: {},
  currentStep: 1,
  missingBudgetItemsValues: [],
  uploadedDocumentation: [],
  errorLogs: [],
  visitedSteps: [],
  isGuideVisited: false,
  eSkatData: {},
  budgetReference: {},
  budgetRevised: {},
  clientFinalBudget: {},
  budgetFinalResponse: {},
  companiesForCompanyChain: [],
  uploadedAllRequiredDocuments: false,
  manualInterventionSentEmail: false,
  creditApplicationDeniedApplication: false,
  creditApplicationAbortedApplication: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload, // This ensures the data is merged correctly
        },
      };
    case ADD_ERROR_LOG:
      const prevErrorLog = state?.errorLogs || [];
      return {
        ...state,
        errorLogs: prevErrorLog.concat(action.payload),
      };
    case SET_PREVIOUS_VISITED_STEP:
      return {
        ...state,
        previousVisitedStep: action.payload,
      };
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case TRIGGERED_POP_ALLOWED:
      return {
        ...state,
        triggeredPopAllowed: action.payload,
      };
    case STORE_ONBOARDING_VISITED_STEPS:
      return {
        ...state,
        visitedSteps: [...action.payload],
      };
    case STORE_IS_GUIDE_VISITED:
      return {
        ...state,
        isGuideVisited: action.payload,
      };
    case STORE_PSD2_LOGIN_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          psd2LoginData: action.payload,
        },
      };
    case STORE_BUDGET_TRANSACTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          budgetTransactionId: action.payload,
        },
      };
    case ADDED_ONE_BANK_SUCESFULLY:
      return {
        ...state,
        data: {
          ...state.data,
          addedOneBankSucessfully: action.payload,
        },
      };

    case ESKAT_ENCRYPT_REQUEST:
      return {
        ...state,
        eSkatData: action.payload.data,
      };
    case GET_BUDGET_REFERENCE:
      return {
        ...state,
        budgetReference: action.payload,
      };
    case GET_BUDGET_REVISED:
      return {
        ...state,
        budgetRevised: action.payload,
      };
    case SAVE_CLIENT_FINAL_BUDGET:
      return {
        ...state,
        clientFinalBudget: action.payload,
      };
    case GET_BUDGET_FINAL:
      return {
        ...state,
        budgetFinalResponse: action.payload,
      };

    case SET_UPLOAD_REQUIRED_FILES_FINISHED:
      return {
        ...state,
        uploadedAllRequiredDocuments: action.payload,
      };
    case STORE_UPLOADED_DOCUMENTATION:
      const {
        creditApplicationUuid,
        documentationType,
        documentationId,
        documentationUuid,
        fileName,
        publicLink,
        clientNote,
        supportNote,
        status,
        _final,
      } = action.payload;
      const doc = {
        documentationId,
        documentationUuid,
        fileName,
        publicLink,
      };
      const existingDocumentation = state?.uploadedDocumentation || [];
      const matchingSection = existingDocumentation?.find(
        (obj) => obj.documentationType === documentationType
      );

      let newSection = {
        documentationType: documentationType,
        creditApplicationUuid: creditApplicationUuid,
        documents: [],
      };

      if (matchingSection) {
        newSection.documents = matchingSection?.documents;
        // if there's a new document, add it to the list otherwise keep the old one updated
        const docIndex = matchingSection?.documents?.findIndex(
          (obj) => obj?.documentationId === doc?.documentationId
        );
        if (docIndex === -1) {
          newSection.documents?.push(doc);
        } else {
          newSection.documents[docIndex] = doc;
        }

        // if there's a new client note, keep update it
        if (clientNote && clientNote !== matchingSection.clientNote) {
          newSection.clientNote = clientNote;
        } else {
          newSection.clientNote = matchingSection?.clientNote;
        }

        // if there's already support note from credit application endpoint, keep it
        if (supportNote) {
          newSection.customerNote = supportNote;
        } else if (matchingSection?.customerNote) {
          newSection.customerNote = matchingSection?.customerNote;
        }
      } else {
        newSection.documents.push(doc);
        if (clientNote) {
          newSection.clientNote = clientNote;
        }
        if (supportNote) {
          newSection.customerNote = supportNote;
        }
      }

      // also keep the status
      if (status) {
        newSection.status = status;
      } else {
        newSection.status = 'PENDING';
      }

      if (_final) {
        newSection.amount = _final;
      }

      // filter the array to remove the old section
      let newUploadedDocumentation = [];
      const filteredArray = state?.uploadedDocumentation?.filter(
        (obj) => obj.documentationType !== documentationType
      );

      if (filteredArray && filteredArray?.length > 0) {
        // Find the index of the removed item (documentationType)
        const index = state.uploadedDocumentation.findIndex(
          (obj) => obj.documentationType === documentationType
        );

        // Create a copy of the filtered array to modify
        newUploadedDocumentation = [...filteredArray];

        // Insert newSection at the same index if found, else push it to the end
        if (index !== -1) {
          newUploadedDocumentation.splice(index, 0, newSection);
        } else {
          newUploadedDocumentation.push(newSection);
        }
      } else {
        newUploadedDocumentation.push(newSection);
      }
      return {
        ...state,
        uploadedDocumentation: newUploadedDocumentation,
      };
    case UPDATE_STORED_CLIENT_NOTE: {
      const { creditApplicationUuid, documentationType, clientNote } =
        action.payload || {};

      const matchingSection = state?.uploadedDocumentation?.find(
        (obj) =>
          obj.documentationType === documentationType &&
          obj.creditApplicationUuid === creditApplicationUuid
      );

      if (matchingSection) {
        matchingSection.clientNote = clientNote;
      }

      const filteredArray =
        state?.uploadedDocumentation.filter(
          (obj) => obj.documentationType !== documentationType
        ) || [];

      // replace it with the updated one
      let updatedList = [...filteredArray, matchingSection];

      return {
        ...state,
        uploadedDocumentation: updatedList,
      };
    }

    case UPDATE_STORED_DOCUMENTS: {
      const { creditApplicationUuid, documentationUuid } = action.payload;
      const matchingSection = state?.uploadedDocumentation?.find(
        (obj) => obj.creditApplicationUuid === creditApplicationUuid
      );
      const updatedDocumentsList = matchingSection?.documents?.filter(
        (doc) => doc?.documentationUuid !== documentationUuid
      );

      const updatedList = state?.uploadedDocumentation?.map((obj) => {
        if (obj?.creditApplicationUuid === creditApplicationUuid) {
          obj.documents = updatedDocumentsList;
        }
        return obj;
      });

      return {
        ...state,
        uploadedDocumentation: updatedList,
      };
    }

    case UPDATE_DOCUMENTATION_ITEM_FROM_WS: {
      const {
        documentationType,
        documentationStatus,
        documentationNote,
        newBudgetItemValue,
      } = action.payload || {};
      const newDocumentsList = state?.uploadedDocumentation.map((oldDoc) => {
        if (oldDoc.documentationType === documentationType) {
          return {
            ...oldDoc,
            status: documentationStatus,
            customerNote: documentationNote,
            newBudgetItemValue: newBudgetItemValue,
          };
        } else {
          return oldDoc;
        }
      });
      return {
        ...state,
        uploadedDocumentation: newDocumentsList,
      };
    }

    case GET_COMPANIES_FOR_COMPANY_CHAIN_SMARTLINK:
      return {
        ...state,
        companiesForCompanyChain: action.payload,
      };

    case STORE_MISSING_BUDGET_ITEMS:
      return {
        ...state,
        missingBudgetItemsValues: action.payload,
      };

    case CLEAR_ONBOADING_DATA:
      return {};
    case CLEAR_STORED_DOCUMENTS:
      return {
        ...state,
        uploadedDocumentation: [],
      };

    case SET_ONBOARDING_DATA_BASED_CREDIT_APPLICATION: {
      const { clientEnteredData, creditApplicationData, budgetDataDto } =
        action.payload || {};
      const {
        bisnodeCpr,
        cpr,
        email,
        firstName,
        lastName,
        leadUuid,
        memberUuid,
        phone,
      } = clientEnteredData || {};
      const {
        disposableAmount,
        tag,
        companyReferenceNumber,
        cars,
        childrenAges,
        debtSinceLastTaxReport,
        shareExpensesPercentage,
        accomodationType,
        creditApplicationUuid,
        bisnodeId,
      } = creditApplicationData || {};
      return {
        ...state,
        data: {
          ...state.data,
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          uuid: leadUuid || memberUuid,
          cpr: cpr || bisnodeCpr,
          monthlyDisposable: disposableAmount,
          linkTag: tag,
          companyId: companyReferenceNumber,
          cars,
          children: childrenAges,
          debtSinceLastTaxReport,
          shareExpensesPercentage,
          houseType: accomodationType,
          creditApplicationUuid,
          budgetTransactionId: budgetDataDto?.budgetTransactionId,
          isGuideVisited: false,
          bisnodeId,
        },
        creditApplicationData: action.payload,
      };
    }

    case SEND_MANUAL_INTERVENTION_EMAIL:
      return {
        ...state,
        manualInterventionSentEmail: action.payload || false,
      };
    case CREDIT_APPLICATION_DENIED_STEP:
      return {
        ...state,
        creditApplicationDeniedApplication: action.payload,
      };
    case CREDIT_APPLICATION_ABORTED_STEP:
      return {
        ...state,
        creditApplicationAbortedApplication: action.payload,
      };
    default:
      return state;
  }
}
