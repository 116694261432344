import React from "react";
import Select from "react-select";
import { strings } from "../../../constants/localization";

import "./style.scss";

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clean: this.props.cleanStep,
      cleanStep: this.props.cleanStep,
      valid:
        this.props.validation && this.props.value
          ? this.props.validation(this.props.value)
          : !this.props.validation
    };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (props.cleanStep !== state.cleanStep) {
      newState.cleanStep = props.cleanStep;
      newState.clean = props.cleanStep;
    }
    if (props.valid !== undefined && props.valid !== state.valid) {
      newState.valid = props.valid;
    }
    return newState;
  }

  onChangeHandler = (selectionArray) => {
    // the input had focus, so now it's not clean anymore
    this.setState({
      clean: false
    });

    if (this.props.validation) {
      this.setState({ valid: this.props.validation(selectionArray) });
    }
    let eventLike = {
      target: { name: "selectedCategories", value: selectionArray }
    };
    this.props.onChange(eventLike);
  }

  isValid = () => {
    return this.state.clean || this.state.valid === true;
  };

  render() {
    return (
      <div className="CustomSelectComponent">
        <label htmlFor={this.props.name}>
          <span>{this.props.label}</span>
          {!this.props.stepIsValid && !this.isValid() && this.props.errorMsg && this.props.value !== "" && (
            <span className="inlineInputError">{this.props.errorMsg}</span>
          )}
        </label>

        <Select
          options={this.props.options}
          isMulti={this.props.isMulti}
          value={this.props.value}
          onChange={this.onChangeHandler}
          //onBlur={this.onChangeHandler}
          placeholder={strings.locationFlow_step2_placeholder_categories}
          className={`custom_select ${!this.props.stepIsValid && !this.isValid() ? "invalidInput" : ""}`}
          classNamePrefix="custom_select"
          //menuIsOpen={true}
        />
      </div>
    );
  }
}


export default CustomSelect;
