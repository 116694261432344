import {
  BOOKING_REQUEST_ERROR,
  BOOKING_REQUEST_LINK_EXPIRED,
  DELETE_BOOKING_REQUEST,
  GET_BOOKING_REQUEST,
  GET_BOOKING_STATUS,
  GET_UNSUBSCRIBE_BOOKING_REQUEST,
  SERVER_URL,
  STOP_BOOKING_REQUEST,
} from '../constants';
import { getLoggedInUserCredentials } from './userActions';
import { isAdmin, requestAxios as axios } from '../constants/utils';

export function getBookingRequest(requestData) {
  const userDetails = getLoggedInUserCredentials();
  let request;
  // if the user is logged in, then send credentials alongside the private endpoint
  const userObj = JSON.parse(localStorage.getItem('user'));
  if (userDetails && isAdmin(userObj)) {
    request = {
      method: 'GET',
      url: `${SERVER_URL}/api/v1/company/contact?token=${requestData.uuid}`,
      auth: userDetails,
      withCredentials: true,
    };
  }
  // oterwise, use the public endpoint
  else {
    request = {
      method: 'GET',
      url: `${SERVER_URL}/public/company/contact?token=${requestData.uuid}`,
    };
  }
  return () => axios(request)
    .then((response) => {
      if (response.status === 200) {
        return {
          type: GET_BOOKING_REQUEST,
          payload: response.data,
        };
      }
    })
    .catch((error) => {
      if (
        error.response.data.cause
          === 'Bad Request: Contact Company Request is expired.'
      ) {
        return {
          type: BOOKING_REQUEST_LINK_EXPIRED,
          payload: error,
        };
      }
      return {
        type: BOOKING_REQUEST_ERROR,
        payload: error,
      };
    });
}

export function getBookingStatus(requestData) {
  return () => axios({
    method: 'post',
    url: `${SERVER_URL}/public/company/updateClinicDecidedStatus`,
    data: requestData,
  })
    .then((response) => {
      if (response.status === 200) {
        return {
          type: GET_BOOKING_STATUS,
          payload: response,
        };
      }
    })
    .catch((error) => ({
      type: BOOKING_REQUEST_ERROR,
      payload: error,
    }));
}

export function deleteBookingRequest(requestData) {
  return () => axios({
    method: 'get',
    url: `${SERVER_URL}/public/company/deleteBookingRequest/${requestData.bookingRequestUuid}`,
    data: requestData,
  })
    .then((response) => {
      if (response.status === 200) {
        return {
          type: DELETE_BOOKING_REQUEST,
          payload: response,
        };
      }
    })
    .catch((error) => ({
      type: BOOKING_REQUEST_ERROR,
      payload: error,
    }));
}

export function getUnsubscribeBookingRequest(token, referenceNumber) {
  return () => axios({
    method: 'get',
    url: `${SERVER_URL}/public/contactCompany/publicCompanyRequestData?token=${token}&referenceNumber=${referenceNumber}`,
  })
    .then((response) => {
      if (response.status === 200) {
        return {
          type: GET_UNSUBSCRIBE_BOOKING_REQUEST,
          payload: response,
        };
      }
    })
    .catch((error) => ({
      type: BOOKING_REQUEST_ERROR,
      payload: error,
    }));
}

export function stopBookingRequest(data) {
  const request = {
    bookingRequestEmail: data.bookingRequestCompanyEmail,
    bookingRequestToken: data.bookingRequestToken,
    companyReferenceNumber: data.companyReferenceNumber,
  };
  return () => axios({
    method: 'post',
    url: `${SERVER_URL}/public/contactCompany/stopBookingRequests`,
    data: request,
  })
    .then((response) => {
      if (response.status === 200) {
        return {
          type: STOP_BOOKING_REQUEST,
          payload: response,
        };
      }
    })
    .catch((error) => ({
      type: BOOKING_REQUEST_ERROR,
      payload: error,
    }));
}
