import React from 'react';
import { strings } from '../../../constants/localization';
import '../../onboardFlow/common/LoadingSpinner/loadingSpinner.scss';
import DentiModal from '../../common/DentiModal';

const UpdateBudgetLoading = ({ closeModal }) => {
  return (
    <DentiModal
      key={1}
      close={() => closeModal()}
      modalClass='onboarding update-budget-modal'
      modalClassName='upload-modal'
      closeBtn={false}
    >
      <div className='content-wrapper'>
        <h3 className='headline'>
          {strings.applicationUpdateBudgetLoadingHeadline}
        </h3>
        <div className='subheadline'>
          {strings.applicationUpdateBudgetLoadingSubtitle}
        </div>
        <div className='loading-spinner'>
          <div>
            <div className='LoadingSpinner'>
              <div className='lds-ring'>
                <div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DentiModal>
  );
};

export default UpdateBudgetLoading;
