import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { strings } from '../../../constants/localization';
import { REQUIRES_ACTION, UNTREATED } from '../../../constants';


class DropdownList extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  componentWillUnmount() {
    this.setState({
      dropdownOpen: false,
    });
  }

  render() {
    const { options, value } = this.props;
    if (value.value === REQUIRES_ACTION) {
      value.label = strings.UNTREATED;
      value.value = UNTREATED;
    }

    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color='light' className={`btn-outline ${this.state.dropdownOpen ? 'open' : ''}`} block>
          <span>{`- ${value.label} -`}</span>
          <i className='di di-dropdown' />
        </DropdownToggle>
        <DropdownMenu right>
          {options.map((option) => (
            <DropdownItem
              className='text-center'
              active={value.value === option.value}
              key={option.value}
              onClick={() => this.props.onSelect(option.value)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

DropdownList.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.object,
};

export default DropdownList;
