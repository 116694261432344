import React, { useState, useEffect } from 'react';
import DentiModal from './DentiModal';
import { strings } from '../../constants/localization';
import { currencyFormatDA } from '../../constants/utils';
import { brandConfig } from '../../config';

const WebsocketNotificationModal = (props) => {
  const [headline, setHeadline] = useState('');
  const [text, setText] = useState('');
  const [linkText, setLinkText] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [iconClassName, setIconClassName] = useState('');

  useEffect(() => {
    const patientData = props.patientData;
    switch (props.type) {
      case 'patient-profile-update':
        setHeadline(strings.wsNotifPatientUpdateHeadline);
        setText(
          strings.formatString(
            strings.wsNotifPatientUpdateText,
            `${patientData.firstName || patientData.clientFirstName || ''} ${
              patientData.lastName || patientData.clientLastName || ''
            }`
          )
        );
        setButtonText(strings.wsNotifPatientUpdateButton);
        setIconClassName('lnil-reload');
        break;
      case 'invoice-registered':
        setHeadline(strings.wsNotifNewInvoiceHeadline);
        setText(
          strings.formatString(
            strings.wsNotifNewInvoiceText,
            currencyFormatDA(
              patientData?.loanDetails?.amount.toFixed(2),
              true,
              true
            ),
            `${patientData?.loanDetails?.creditFacilityClientFirstName || ''} ${
              patientData?.loanDetails?.creditFacilityClientLastName || ''
            }`
          )
        );
        setButtonText(strings.wsNotifNewInvoiceButton);
        setIconClassName('lnil-add-files');
        break;
      case 'invoice-accepted':
        setHeadline(strings.wsNotifInvoiceAcceptedHeadline);
        setText(
          strings.formatString(
            strings.wsNotifInvoiceAcceptedText,
            `${patientData?.loanDetails?.creditFacilityClientFirstName || ''} ${
              patientData?.loanDetails?.creditFacilityClientLastName || ''
            }`,
            currencyFormatDA(
              patientData?.loanDetails?.amount.toFixed(2),
              true,
              true
            )
          )
        );
        setButtonText(strings.wsNotifInvoiceAcceptedButton);
        setIconClassName('lnil-checkmark-circle');
        break;
      case 'invoice-declined':
        setHeadline(strings.wsNotifInvoiceDeclinedHeadline);
        setText(
          strings.formatString(
            strings.wsNotifInvoiceDeclinedText,
            currencyFormatDA(
              patientData?.loanDetails?.amount.toFixed(2),
              true,
              true
            ),
            `${patientData?.loanDetails?.creditFacilityClientFirstName || ''} ${
              patientData?.loanDetails?.creditFacilityClientLastName || ''
            }`
          )
        );
        setButtonText(strings.wsNotifInvoiceAcceptedButton);
        setIconClassName('lnil-warning');
        break;
      case 'refund':
        setHeadline(strings.wsNotifRefundHeadline);
        setText(
          strings.formatString(
            strings.wsNotifRefundText,
            patientData?.amount
              ? currencyFormatDA(patientData?.amount.toFixed(2), true, true)
              : '{0}'
          )
        );
        setLinkText(strings.wsNotifRefundLink);
        setButtonText(strings.wsNotifRefundButton);
        setIconClassName('lnil-undo');
        break;
      case 'dunning':
        if (patientData.inDunning === true) {
          setHeadline(strings.wsNotifDunningInHeadline);
          setText(
            strings.formatString(
              strings.wsNotifDunningInText,
              `${patientData.firstName || ''} ${patientData.lastName || ''}`,
              <br />,
              <a href={`mailto:${brandConfig.contactEmail}`}>
                {brandConfig.contactEmail}
              </a>,
              <a href={`tel:${brandConfig.contactPhone}`}>
                {brandConfig.contactPhoneHtmlFormated}
              </a>
            )
          );
          setButtonText(strings.wsNotifDunningButton);
          setIconClassName('lnil-warning');
        } else {
          setHeadline(strings.wsNotifDunningOutHeadline);
          setText(
            strings.formatString(
              strings.wsNotifDunningOutText,
              `${patientData.firstName || ''} ${patientData.lastName || ''}`
            )
          );
          setButtonText(strings.wsNotifDunningButton);
          setIconClassName('lnil-checkmark-circle');
        }
        break;
      // displayed when a normal loan delete is triggered
      case 'loan-deleted':
        setHeadline(strings.wsNotifLoanDeletedHeadline);
        setText(strings.formatString(strings.wsNotifLoanDeletedText, <br />));
        setButtonText(strings.wsNotifLoanDeletedBtn);
        setIconClassName('lnil-cross-circle');
        break;
      // displayed when a failing withdrawal of a partial redemption, which causes the restructuring to be deleted.
      case 'loan-deleted-withdrawal-failed':
        setHeadline(strings.wsNotifLoanDeletedWithdrawalFailedHeadline);
        setText(
          strings.formatString(
            strings.wsNotifLoanDeletedWithdrawalFailedText,
            <br />,
            <br />
          )
        );
        setButtonText(strings.wsNotifLoanDeletedWithdrawalFailedBtn);
        setIconClassName('lnil-cross-circle');
        break;
      default:
        console.log(`Sorry, you need to provide a notification type property`);
    }
  }, [props.patientData, props.type]);

  return (
    <DentiModal
      key={1}
      close={() => props.close()}
      modalClass='notification-modal'
      modalClassName='confirm-action'
      closeBtn={false}
    >
      <div>
        <div className='icon'>
          <i className={`lnil ${iconClassName}`} />
        </div>
        <div>
          <h3 className='headline'>{headline}</h3>
          <p className='notification-message'>{text}</p>
          {linkText !== '' &&
            props.type === 'refund' &&
            props.patientData?.installmentLoanNumber !== null && (
              <p className='receipt'>
                <i className='lnil lnil-bookmark' />
                <span onClick={() => props.openLoanModal()}>{linkText}</span>
              </p>
            )}
        </div>
        <div className='bottom-section action-btn cf'>
          <button
            className='submit-btn btn btn-blue center-block'
            type='button'
            onClick={() => props.close()}
          >
            <span>{buttonText}</span>
          </button>
        </div>
      </div>
    </DentiModal>
  );
};

export default WebsocketNotificationModal;
