import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';
import { moment } from '../../../constants';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../../constants/styleScrollbar';
import { getLogsforCompanies } from '../../../actions/logActions';
import LoadingMessage from '../../statusMessages/LoadingMessage';

class CompanyLogDetails extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
    props.getLogsforCompanies(props.activeChain.referenceNumber);
  }

  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.resetScrollPosition();
    }
    if (prevProps.activeChain.id !== this.props.activeChain.id) {
      this.props.getLogsforCompanies(this.props.activeChain.referenceNumber);
    }
  }

  render() {
    const { companyLogs } = this.props;

    return (
      <Card className='lead-information custom-card logs table-responsive-sm'>
        <CardBody>
          <div className='header-information'>
            <CardTitle>{strings.logTab}</CardTitle>
          </div>
          <div className='log-header'>
            <div className='log-cell log-date'>{strings.logTabDate}</div>
            <div className='log-cell log-event'>{strings.logTabEvent}</div>
            <div className='log-cell log-user'>{strings.logTabUser}</div>
          </div>
          <div className='log-information'>
            <Scrollbars
              renderTrackVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    ...trackVerticalStyle,
                  }}
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    ...thumbVerticalStyle,
                  }}
                />
              )}
            >
              <div className='scrollbar-wrapper'>
                {companyLogs === undefined ? (
                  <LoadingMessage />
                ) : (
                  <div className='log-body'>
                    {companyLogs.map((log) => (
                      <div className='log-entry' key={log.id}>
                        <div className='log-cell log-date'>
                          <div>
                            {moment
                              .utc(log.createdDate)
                              .local()
                              .format('DD. MMM. YYYY')
                              .toLowerCase()}
                          </div>
                          <div>
                            {moment
                              .utc(log.createdDate)
                              .local()
                              .format('HH:mm')}
                          </div>
                        </div>
                        <div className='log-cell log-event'>
                          <div className='event-details'>
                            {log.event.replace(/\\n/g, '\n\r')}
                          </div>
                          {log.installmentLoan !== null && (
                            <div>{`Loan id: ${log.installmentLoan}`}</div>
                          )}
                        </div>
                        <div className='log-cell log-user'>{log.username}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyLogs: state.log?.companyLogs,
  };
}

CompanyLogDetails.propTypes = {
  activeTab: PropTypes.string,
  getLogsforCompanies: PropTypes.func,
};

export default connect(mapStateToProps, { getLogsforCompanies })(
  CompanyLogDetails
);
