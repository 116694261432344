import React, { useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';
import './style.scss';
import { strings } from '../../../../constants/localization';
import CompanyProfile from './CompanyProfile/CompanyProfile';
import BookingLink from './BookingLink';

const ClinicIndexSection = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setActiveTab('1');
  }, [props.companyDetails]);

  return (
    <div id='ClinicIndexSection'>
      <Row noGutters>
        <Col className='tab-nav'>
          <Nav tabs vertical pills>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => {
                  toggle('1');
                }}
              >
                <i className='lnir lnir-hospital-location' />
                <span>{strings.clinicIndexTab1}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => {
                  toggle('2');
                }}
              >
                <i className='lnir lnir-thunderbolt' />
                <span>{strings.clinicIndexTab2}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col className='tab-container'>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <CompanyProfile companyDetails={props.companyDetails} />
            </TabPane>
            <TabPane tabId='2'>
              <BookingLink companyDetails={props.companyDetails} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default ClinicIndexSection;
