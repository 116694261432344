import { saveAs } from 'file-saver';
import { Base64 } from 'js-base64';
import { requestAxios as axios } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import {
  PATIENTS_DUNNING_LOADING,
  GET_ALL_PATIENTS,
  PATIENTS_DUNNING_SUCCESS,
  PATIENTS_DUNNING_EMPTY,
  PATIENTS_DUNNING_FAILURE,
  SERVER_URL,
  GET_GLOBAL_PATIENTS_DATA,
  EXPORT_CSV_FILE_START,
  EXPORT_CSV_FILE_FINISH,
  EXPORT_CSV_FILE_FAIL,
  SET_CURRENT_LEAD,
  SET_ACTIVE_PAGE_PATIENTS,
  GET_NEW_DUNNING_PATIENTS,
  GET_PAGINATED_DUNNING_PATIENTS,
  UPDATE_PATIENTS_DUNNING_FILTER_PARAMS,
  DUNNING_PATIENTS_SEARCH_EMITTED,
} from '../constants';

export function setActivePage(activePage) {
  return {
    type: SET_ACTIVE_PAGE_PATIENTS,
    payload: activePage,
  };
}

export function updatePatientDunningFilterParams(filterParams) {
  return {
    type: UPDATE_PATIENTS_DUNNING_FILTER_PARAMS,
    payload: filterParams,
  };
}

export function searchEmitted(search = false) {
  return {
    type: DUNNING_PATIENTS_SEARCH_EMITTED,
    payload: { search },
  };
}

export function getPatients(page = 0, signal = null) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const paramsRequest = {};
      const state = getState();
      let searchString = null;
      const { activeLead } = state.lead || {};
      const { filterParams, searchActive, currentPatient } =
        state.patientsDunning || {};
      searchString = searchActive?.searchInput;

      // pagination
      if (page > 0) {
        paramsRequest.page = page;
      } else {
        paramsRequest.page = 0;
        dispatch({
          type: PATIENTS_DUNNING_LOADING,
          payload: PATIENTS_DUNNING_LOADING,
        });
      }

      // search
      if (searchString) {
        paramsRequest.searchString = searchString
          .trim()
          .replace(/\s/g, '')
          .split('-')
          .join('');
      } else if (searchString === '') {
        paramsRequest.searchString = null;
      } else {
        paramsRequest.searchString = searchString;
      }

      // filters
      if (filterParams) {
        for (const key in filterParams) {
          if (Object.prototype.hasOwnProperty.call(filterParams, key)) {
            if (filterParams[key].value !== null) {
              paramsRequest[key] = filterParams[key].value;
            }
          }
        }
      }

      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v2/creditFacility/getDunningClients`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: paramsRequest,
        ...(signal && { signal }), // add the signal to the request if it exists, used to abort the request
      })
        .then((response) => {
          const { clientInDunningList } = response.data || {};

          if (paramsRequest.page === 0) {
            dispatch({
              type: GET_ALL_PATIENTS,
              payload: response.data,
            });
          } else {
            dispatch({
              type: GET_NEW_DUNNING_PATIENTS,
              payload: response.data,
            });
          }

          if (clientInDunningList?.length === 0) {
            dispatch({
              type: PATIENTS_DUNNING_EMPTY,
              payload: PATIENTS_DUNNING_EMPTY,
            });
          }

          if (clientInDunningList?.length > 0) {
            if (activeLead !== undefined) {
              const currentLead = clientInDunningList.find(
                (lead) =>
                  activeLead.creditFacilityInformation.id ===
                  lead.creditFacilityId
              );

              if (
                currentLead &&
                currentLead.creditFacilityId !==
                  currentPatient?.creditFacilityId
              ) {
                dispatch(setCurrentPatient(currentLead?.creditFacilityId));
              }
            }
            dispatch({
              type: PATIENTS_DUNNING_SUCCESS,
              payload: PATIENTS_DUNNING_SUCCESS,
            });
            dispatch({
              type: GET_PAGINATED_DUNNING_PATIENTS,
              payload: GET_PAGINATED_DUNNING_PATIENTS,
            });
          }
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: PATIENTS_DUNNING_FAILURE,
            payload: error,
          });
          reject(error);
        });
    });
}

export function getDunningClientsTotals() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: PATIENTS_DUNNING_LOADING,
        payload: PATIENTS_DUNNING_LOADING,
      });
      axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/creditFacility/getDunningClientsTotals`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_GLOBAL_PATIENTS_DATA,
              payload: response,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: PATIENTS_DUNNING_FAILURE,
            payload: error,
          });
          reject(error);
        });
    });
}

export function setCurrentPatient(creditFacilityId = undefined) {
  return (dispatch) => {
    if (creditFacilityId === undefined) {
      dispatch({
        type: SET_CURRENT_LEAD,
        payload: creditFacilityId,
      });
    } else {
      try {
        axios({
          method: 'get',
          url: `${SERVER_URL}/api/v2/leads/creditFacility/${creditFacilityId}`,
          withCredentials: true,
          auth: getLoggedInUserCredentials(),
        }).then((result) => {
          dispatch({
            type: SET_CURRENT_LEAD,
            payload: result.data,
          });
        });
      } catch (error) {
        throw new Error(error);
      }
    }
  };
}

export function exportCsvFile(callback = undefined) {
  return async (dispatch) => {
    dispatch({
      type: EXPORT_CSV_FILE_START,
      payload: EXPORT_CSV_FILE_START,
    });

    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/creditFacility/getDebtCollectionFile`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      });

      const responseData = Base64.decode(response.data.data);
      const blob = new Blob([responseData], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, response.data.name);
      dispatch({
        type: EXPORT_CSV_FILE_FINISH,
        payload: EXPORT_CSV_FILE_FINISH,
      });
      if (callback !== undefined) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: EXPORT_CSV_FILE_FAIL,
        payload: error,
      });
    }
  };
}
