import React, { useState, useEffect } from 'react';
import { strings } from '../../constants/localization';
import DentiModal from '../common/DentiModal';

const ServerUnavailableModal = ({ closeModal }) => {
  const [countdownTime, setCountdownTime] = useState(120); // 2 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          // Timer expired, do something here if needed
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const minutes = Math.floor(countdownTime / 60);
  const seconds = countdownTime % 60;

  return (
    <DentiModal
      key={1}
      modalClass='notification-modal'
      modalClassName='confirm-action'
      close={() => closeModal()}
      closeBtn={true}
    >
      <div>
        <div className='icon'>
          <i className='lnil lnil-reload' />
        </div>
        <div>
          <h3 className='headline'>{strings.serverRebootingHeadline}</h3>
          <p className='notification-message'>{strings.serverRebootingText}</p>
        </div>
        <div>
          <p></p>
        </div>
        <div className='bottom-section action-btn cf'>
          {countdownTime > 0 && (
            <button type='button' className='countdown'>
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </button>
          )}
          <button
            className='submit-btn btn btn-blue center-block'
            type='button'
            onClick={() => closeModal()}
          >
            <span>{strings.serverRebootingOKButton}</span>
          </button>
        </div>
      </div>
    </DentiModal>
  );
};

export default ServerUnavailableModal;
