import React from 'react';
import { Alert } from 'reactstrap';
import { strings } from '../../../constants/localization';

const RequiresActionAlert = () => (
  <div className='requires-action-container'>
    <Alert color='warning' className='requires-action-alert'>
      <div className='alert-icon'>
        <i className='di di-bell' />
      </div>
      <div>
        <div className='alert-title'>{strings.requiresActionAlertTitle}</div>
        <div className='alert-text'>{strings.requiresActionAlertSubtitle}</div>
      </div>
    </Alert>
  </div>
);

export default RequiresActionAlert;
