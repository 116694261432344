import React from 'react';
import { Row, Col } from 'reactstrap';
import { currencyFormatDA } from '../../constants/utils';
import { strings } from '../../constants/localization';
import { moment, momentFormat } from '../../constants';

const PaidOutDetails = ({ invoice }) => {
  const commisionRate = invoice.commission > 0 ? invoice.commission / 100 : 0;
  const totalAmount =
    invoice.createdDuringTrial === true && invoice.commission > 0
      ? invoice.amount
      : invoice.amount - invoice.amount * commisionRate;
  const composedReferenceNumber =  (invoice.brand === "Denti" ? 'DENTI ' : 'SUND+ ') + invoice.invoiceNumber;   
      
  return (
    <>
      <Row>
        <Col xs="12" className="text-center">
          <i className="di di-circle-check" />
        </Col>
        <Col xs="12" className="text-center">
          <h3 className="paid-out-title">{strings.paidOutTitle}</h3>
        </Col>
        <Col xs="12">
          <Row className="data-row">
            <Col xs="12" md="7">
              {strings.paidOutInvoice}
            </Col>
            <Col xs="12" md="5" className="text-right">
              {currencyFormatDA(invoice.amount, true, true)}
            </Col>
          </Row>
          <Row className="data-row">
            <Col xs="12" md="7">
              {strings.formatString(
                strings.paidOutCommision,
                commisionRate * 100
              )}
            </Col>
            <Col xs="12" md="5" className="text-right">
              {invoice.commission > 0 ? "-" : ""}
              {currencyFormatDA(invoice.amount * commisionRate, true, true)}
            </Col>
          </Row>

          {/* Show this row only if the createdDuringTrial is true and comission is greater than 0 */}
          {invoice.createdDuringTrial === true && invoice.commission > 0 && (
            <Row className="data-row text-color-green">
              <Col xs="12" md="7">
                {strings.freePaidOutCommision}
              </Col>
              <Col xs="12" md="5" className="text-right text-color-green">
                {currencyFormatDA(invoice.amount * commisionRate, true, true)}
              </Col>
            </Row>
          )}

          <div className="total-paid-out">
            <Row>
              <Col xs="12" md="7">
                {strings.formatString(
                  strings.paidOutDate,
                  moment(invoice.paidOutDate).format(momentFormat)
                )}
              </Col>
              <Col xs="12" md="5" className="text-right">
                {currencyFormatDA(totalAmount,true,true)}
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12" className="extra-text">
          {(invoice.paidOutRegNo === null || invoice.paidOutRegNo === "") &&
          (invoice.paidOutAccountNo === null ||
            invoice.paidOutAccountNo === "") ? (
            <p>
              {strings.formatString(
                strings.paidOutText1,
                invoice.cvrNumber || "",
                <b>{composedReferenceNumber}</b>
              )}
            </p>
          ) : (
            <p>
              {strings.formatString(
                strings.paidOutText2,
                invoice.paidOutRegNo,
                invoice.paidOutAccountNo,
                <b>{composedReferenceNumber}</b>
              )}
            </p>
          )}
          <p>{strings.paidOutText3}</p>
        </Col>
      </Row>
    </>
  );
};

export default PaidOutDetails;
