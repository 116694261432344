import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../constants/localization';
import {
  formatCurrency,
  formatCurrencyDaStandard,
  removeCurrencyFormating,
  numberWithDotAsThousandsSeparator,
} from '../../../constants/utils';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import { updateBudgetItem } from '../../../actions/onboardingDocumentationActions';

export default function BudgetDisplay() {
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );
  const { leadUuid, budgetConclusionDTO, type, status } =
    activeDocListItem || {};
  const [referenceBudget, setReferenceBudget] = useState(
    budgetConclusionDTO?.reference || 0
  );
  const [revisedBudget, setRevisedBudget] = useState(
    budgetConclusionDTO?.psd2 || 0
  );
  const [clientFinal, setClientFinal] = useState(
    budgetConclusionDTO?.clientFinal || 0
  );
  //const [finalBudget, setFinalBudget] = useState(budgetConclusionDTO?.final || 0);
  const [budgetValue, setBudgetValue] = useState(
    budgetConclusionDTO?.final || 0
  );
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  let timer,
    timeoutVal = 500;
  const inputRef = useRef(null);

  const updateBudgetConclusionDTO = () => {
    if (budgetValue !== budgetConclusionDTO?.psd2) {
      const budgetValueAsNumber = removeCurrencyFormating(budgetValue);

      const newBudgetMap = {
        [type]: {
          ...budgetConclusionDTO,
          datasource: 'CUSTOMER_SERVICE',
          _final: budgetValueAsNumber,
        },
      };

      const newBudgetConclusionDTO = {
        budgetMap: newBudgetMap,
        budgetType: 'FINAL',
        leadUuid,
      };

      dispatch(updateBudgetItem(newBudgetConclusionDTO)).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setEditModal(false);
        }
      });
    }
  };

  useEffect(() => {
    if (activeDocListItem) {
      const { reference, clientFinal, final } =
        activeDocListItem?.budgetConclusionDTO || {};
      setReferenceBudget(reference || 0);
      setRevisedBudget(revisedBudget);
      setClientFinal(clientFinal || 0);
      setBudgetValue(formatCurrencyDaStandard(final));

      setEditModal(false);
      setLoading(false);
    }
  }, [activeDocListItem]);

  const updateBudget = () => {
    setLoading(true);

    // Update the budgetConclusionDTO before dispatching the action
    updateBudgetConclusionDTO();
  };

  useEffect(() => {
    if (editModal) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, 0);
    }
  }, [editModal]);

  const removeFormatting = (value) => {
    if (value) {
      // keep only digits, dots, and commas
      const numericValue = value.toString().replace(/[^\d.,]/g, '');
      // convert the string to a numeric value
      return parseFloat(numericValue.replace('.', '').replace(',', '.'));
    }
    return 0;
  };

  const handleBudgetChange = (event) => {
    const cleanedValue = event.target.value.replace(/[^\d.,-]/g, '');
    let trimmedValue = cleanedValue;
    //ignore more than 2 decimals after comma
    if (cleanedValue.indexOf(',') > -1) {
      trimmedValue = cleanedValue.substring(0, cleanedValue.indexOf(',') + 3);
    }
    setBudgetValue(trimmedValue);
  };

  const handleBudgetFocus = (event) => {
    let inputVal = event.target.value;
    // removed currency, but keep the comma separator
    const removedCurrency = inputVal
      ?.replace('kr.', '')
      .replace('.', '')
      .trim();
    setBudgetValue(removedCurrency);
  };

  const handleBudgetBlur = (event) => {
    let formattedValue;
    if (event.target.value) {
      formattedValue = numberWithDotAsThousandsSeparator(
        formatCurrency(event.target.value)
      );
    } else {
      formattedValue = '';
    }
    setBudgetValue(formattedValue);
  };

  return (
    <section
      id='budget-section'
      className='entity-status-card custom-card card'
    >
      <div className='wrapper'>
        <div className='descriptionWraper'>
          <div className='iconArea'>
            <i className='lnir lnir-bank'></i>
          </div>
          <div className='titleArea'>{strings.documentationBudgetHeader}</div>
        </div>
        <div
          className={`budgetAmountDisplay ${!editModal ? 'budget-hover' : ''}`}
        >
          <div className='p-1 bd-highlight  text-center mt-3'>
            <div className='mb-3'>
              <b>
                {strings.formatString(
                  strings.documentationBudgetTypeInformation,
                  strings[type]
                )}
              </b>
            </div>
          </div>

          {!loading ? (
            <Row className='input-with-icon mb-3 text-center '>
              <Col className='input-container  '>
                <input
                  ref={inputRef}
                  type='text'
                  className='text-center budgetInput mb-2'
                  value={budgetValue}
                  onChange={handleBudgetChange}
                  onFocus={handleBudgetFocus}
                  onBlur={handleBudgetBlur}
                  onKeyDown={() => window.clearTimeout(timer)}
                  onKeyUp={(evt) => {
                    window.clearTimeout(timer);
                    timer = window.setTimeout(() => {
                      setBudgetValue(
                        numberWithDotAsThousandsSeparator(evt.target.value)
                      );
                    }, timeoutVal);
                  }}
                  disabled={!editModal}
                />

                {!editModal && status === 'PENDING' && (
                  <i
                    className='lnil lnil-pencil icon'
                    onClick={() => setEditModal(!editModal)}
                  ></i>
                )}
              </Col>
            </Row>
          ) : (
            <form>
              <div className='form-row justify-content-center'>
                <div className='col-4 updatingLoading d-flex'>
                  <div className='text-right text-sm-center updateTitle'>
                    {strings.onboardingUpdatingValueLoader}

                    <p className='text-right text-sm-center'>
                      {strings.onboardingUpdatingValueLoader2}
                    </p>
                  </div>
                </div>
                <div className='text-center col-4 spinnerUpdade'>
                  <LoadingMessage />
                </div>
              </div>
            </form>
          )}

          {editModal &&
            removeFormatting(budgetValue) !==
              activeDocListItem?.budgetConclusionDTO?.final &&
            budgetValue !== '' && (
              <div className='text-center mt-1 mb-4'>
                <Button
                  color='blue'
                  disabled={
                    budgetValue === activeDocListItem.budgetConclusionDTO?.psd2
                  }
                  onClick={updateBudget}
                >
                  <i className='lnir lnir-spinner-arrow'></i>
                  {strings.documentationUpdateBtn}
                </Button>
              </div>
            )}

          {editModal &&
            (budgetValue === '' ||
              removeFormatting(budgetValue) ===
                activeDocListItem?.budgetConclusionDTO?.final) && (
              <div className='text-center mt-1 mb-4'>
                <Button
                  color='blue'
                  onClick={() => {
                    setEditModal(false);
                    setBudgetValue(
                      formatCurrencyDaStandard(
                        activeDocListItem.budgetConclusionDTO?.final
                      )
                    );
                  }}
                >
                  <i className='lnir lnir-close'></i>
                  {strings.close}
                </Button>
              </div>
            )}
        </div>
        <div className='budget-information'>
          <div className='line referenceBudget'>
            <div>{strings.documentationBudgetReferenceBudget}</div>
            <div>{formatCurrencyDaStandard(referenceBudget)}</div>
          </div>
          <div className='line'>
            <div>{strings.documentationBudgetRetrievedByNoitso}</div>
            <div>{formatCurrencyDaStandard(revisedBudget)}</div>
          </div>
          <div className='line'>
            <div>{strings.documentationBudgetInformedByCustomer}</div>
            <div>
              {clientFinal !== null
                ? formatCurrencyDaStandard(clientFinal)
                : '-'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
