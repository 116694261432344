import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import {
  dunningCauseMap,
  dunningStatusMap,
  dunningCardStateMap,
} from '../../constants/mappings';
import { momentFormat } from '../../constants';
import { strings } from '../../constants/localization';
import { updatePatientDunningFilterParams } from '../../actions/patientDunningActions';

const PatientsDunningFilterMenu = ({
  closeFilterMenu,
  clearFilterValue,
  triggerClearFilterEffect,
}) => {
  //const [filterOpen, setFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const [newDunningCauseMap, setNewDunningCauseMap] = useState([]);
  const [newDunningStatusMap, setNewDunningStatusMap] = useState([]);
  const [newDunningCardStatusMap, setNewDunningCardStatusMap] = useState([]);
  const listOfAdmins = useSelector((state) => state.user?.admins);
  const [adminsSelect, setAdminsSelect] = useState([]);
  const dunningFilters = useSelector(
    (state) => state.patientsDunning?.filterParams
  );
  const formikRef = useRef(null);
  const filterRef = useRef(null);

  // run only on first render with translated labels
  useEffect(() => {
    // dunning cause map
    setNewDunningCauseMap(
      dunningCauseMap.map((item) => {
        return {
          label: strings[item.label],
          value: item.value,
        };
      })
    );

    // dunning card state map
    setNewDunningCardStatusMap(
      dunningCardStateMap.map((item) => {
        return {
          label: strings[item.label],
          value: item.value,
        };
      })
    );

    // dunning status map
    setNewDunningStatusMap(
      Object.entries(dunningStatusMap).map((entry) => {
        const [key, value] = entry;
        return {
          label: strings[value?.statusText],
          value: key,
        };
      })
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !event.target.closest('#filterResultsIcon')
      ) {
        closeFilterMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  // make sure this list with admins is updated
  useEffect(() => {
    // admins mapping
    if (listOfAdmins && listOfAdmins?.length > 0) {
      const mappedAdmins = listOfAdmins.map((admin) => {
        return {
          label: admin?.fullName,
          value: admin?.userName,
        };
      });
      setAdminsSelect(mappedAdmins);
    }
  }, [listOfAdmins]);

  useEffect(() => {
    if (clearFilterValue) {
      if (clearFilterValue === 'all') {
        formikRef.current.resetForm();
      } else {
        formikRef.current.setFieldValue(clearFilterValue, '');
      }
    }
  }, [clearFilterValue, triggerClearFilterEffect]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        creditCardState: dunningFilters?.creditCardState || '',
        dunningStatus: dunningFilters?.dunningStatus || '',
        dunningType: dunningFilters?.dunningType || '',
        lastPaymentPayedDate: dunningFilters?.lastPaymentPayedDate || '',
        userInCharge: dunningFilters?.userInCharge || '',
      }}
      onSubmit={(values) => {
        const filterValues = {};
        const {
          creditCardState,
          dunningStatus,
          dunningType,
          lastPaymentPayedDate,
          userInCharge,
        } = values;
        const { label: creditCardStateLabel, value: creditCardStateValue } =
          creditCardState || {};
        const { label: dunningStatusLabel, value: dunningStatusValue } =
          dunningStatus || {};
        const { label: dunningTypeLabel, value: dunningTypeValue } =
          dunningType || {};
        const { label: userInChargeLabel, value: userInChargeValue } =
          userInCharge || {};

        if (creditCardStateValue) {
          filterValues.creditCardState = {
            label: creditCardStateLabel || creditCardStateValue,
            value: creditCardStateValue,
          };
        }
        if (dunningStatusValue) {
          filterValues.dunningStatus = {
            label: dunningStatusLabel || dunningStatusValue,
            value: dunningStatusValue,
          };
        }
        if (dunningTypeValue) {
          filterValues.dunningType = {
            label: dunningTypeLabel || dunningTypeValue,
            value: dunningTypeValue,
          };
        }
        if (lastPaymentPayedDate) {
          filterValues.lastPaymentPayedDate = {
            label: lastPaymentPayedDate,
            value: lastPaymentPayedDate,
          };
        }
        if (userInChargeValue) {
          filterValues.userInCharge = {
            label: userInChargeLabel || userInChargeValue,
            value: userInChargeValue,
          };
        }
        dispatch(updatePatientDunningFilterParams(filterValues));
        closeFilterMenu();
      }}
      onReset={() => {
        dispatch(updatePatientDunningFilterParams({}));
        closeFilterMenu();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form ref={filterRef}>
          <Row>
            <Col xs='12' className='form-group CustomSelectComponent'>
              <div className='filter-title'>{strings.dunningCauseTitle}</div>
              <Select
                id='dunningCauseSelect'
                options={newDunningCauseMap}
                isMulti={false}
                value={values?.dunningType || ''}
                onChange={(opt) => {
                  setFieldValue('dunningType', opt);
                }}
                placeholder={strings.dunningResponsibleDropdownPlaceholder}
                className='custom_select'
                classNamePrefix='custom_select'
              />
            </Col>
          </Row>

          <Row>
            <Col xs='12' className='form-group CustomSelectComponent'>
              <div className='filter-title'>{strings.personResponsible}</div>
              <Select
                id='dunningResponsibleSelect'
                options={adminsSelect}
                isMulti={false}
                value={values?.userInCharge}
                onChange={(opt) => {
                  setFieldValue('userInCharge', opt);
                }}
                placeholder={strings.dunningResponsibleDropdownPlaceholder}
                className='custom_select'
                classNamePrefix='custom_select'
              />
            </Col>
          </Row>

          <Row>
            <Col xs='12' className='form-group CustomSelectComponent'>
              <div className='filter-title'>{strings.caseStatus}</div>
              <Select
                id='dunningCaseStatusSelect'
                options={newDunningStatusMap}
                isMulti={false}
                value={values?.dunningStatus}
                onChange={(opt) => {
                  setFieldValue('dunningStatus', opt);
                }}
                placeholder={strings.dunningResponsibleDropdownPlaceholder}
                className='custom_select'
                classNamePrefix='custom_select'
              />
            </Col>
          </Row>

          <Row>
            <Col xs='12' className='form-group CustomSelectComponent'>
              <div className='filter-title'>
                {strings.dunningCardStatusTitle}
              </div>
              <Select
                id='dunningCardStatusSelect'
                options={newDunningCardStatusMap}
                isMulti={false}
                value={values?.creditCardState}
                onChange={(opt) => {
                  setFieldValue('creditCardState', opt);
                }}
                placeholder={strings.dunningResponsibleDropdownPlaceholder}
                className='custom_select'
                classNamePrefix='custom_select'
                menuPlacement='auto'
                maxMenuHeight={250}
              />
            </Col>
          </Row>

          {/* DATE INPUTS */}
          <Row>
            <Col xs='12' className='form-group date-input'>
              <div className='filter-title'>
                {strings.dunningLastPaymentPayedDateTitle}
              </div>
              <Col xs='12' md='6'>
                <Input
                  id='dunningLastPayment'
                  type='date'
                  name='lastPaymentPayedDate'
                  data-date={momentFormat}
                  data-date-format={momentFormat}
                  value={values?.lastPaymentPayedDate || ''}
                  onChange={(event) => {
                    setFieldValue('lastPaymentPayedDate', event.target.value);
                  }}
                />
              </Col>
            </Col>
          </Row>

          <Row>
            <Col xs='6' md='6' className='mt-3'>
              <Button type='reset' color='light' block>
                <i className='di di-close' />
                &ensp;
                {strings.resetFilter}
              </Button>
            </Col>
            <Col xs='6' md='6' className='mt-3'>
              <Button type='submit' color='light' className='btn-blue' block>
                <i className='di di-check' />
                &ensp;
                {strings.submitFilter}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PatientsDunningFilterMenu;
