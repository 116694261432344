import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody } from 'reactstrap';
import { strings } from '../../../constants/localization';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { storeOnboardingData } from '../../../actions/onboardingActions';

const Accomodation = ({ setNextStep, step }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [acommodation, setAcommodation] = useState('');
  const [buttonLoading, setButtonLoading] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingStepFiveTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/hvordan-bor-du',
        stepTitle: 'onboarding - boligform',
        event: 'onboarding',
      });
    }
  }, []);

  const handleButtonSelect = (e) => {
    const selectedButtonValue = e.target.value;
    let accomodationType = '';
    switch (selectedButtonValue) {
      case '1':
      case '2':
        accomodationType = 'ownerOccupied';
        break;
      case '3':
        accomodationType = 'rent';
        break;
      case '4':
        accomodationType = 'livingWithParents';
        break;
      case '5':
        accomodationType = 'housingCooperative';
        break;
      default:
        accomodationType = 'other';
    }
    setAcommodation(accomodationType);
    setButtonLoading({ ...buttonLoading, [selectedButtonValue]: true });
    dispatch(
      storeOnboardingData({ ...savedData, housingStatus: accomodationType })
    );
    setNextStep(step + 1);
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>{strings.onboardingStepFiveTitle}</div>
      <div className='actions-buttons display-columns'>
        {[1, 2, 3, 4, 5].map((option) => (
          <Button
            key={option}
            className='btn btn-blue btn-success full-width'
            onClick={handleButtonSelect}
            value={String(option)}
            disabled={acommodation !== ''}
          >
            {buttonLoading[option] ? (
              <ButtonLoader />
            ) : (
              strings[`onboardingAcommodationOption${option}`]
            )}
          </Button>
        ))}
      </div>
    </CardBody>
  );
};

export default Accomodation;
