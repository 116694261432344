import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { strings } from '../../constants/localization';
import Checkbox from '../common/Checkbox/Checkbox';
import TermsConditions from '../common/TermsAndConditions/TermsConditions';
import { Sundhedplus } from '../../config';

const Step2 = (props) => {
  const [consent, setConsent] = useState(false);
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const handleClick = () => {
    setConsent(!consent);
  };

  // send page event to customer.io tracking system, after component is mounted
  useEffect(() => {
    _cio.page('migration - ' + strings.migrateCredit_title_step2);
  }, []);

  useEffect(() => {
    props.updateConsent(consent);
  }, [consent, props]);

  return (
    <div className='Step2 section'>
      <div className='title'>{strings.migrateCredit_title_step2}</div>
      <div className='content'>
        {readMoreOpen && (
          <div className='terms-wrapper'>
            <Scrollbars style={{ height: 340 }}>
              <TermsConditions link={Sundhedplus.termsAndConditionsMigration} />
            </Scrollbars>
          </div>
        )}

        {!readMoreOpen && (
          <div className='terms-conditions-summary'>
            <article>
              <p>{strings.migrateCredit_first_paragraph_step2}</p>
            </article>
            <article>
              <p>{strings.migrateCredit_second_paragraph_step2}</p>
            </article>
            <article>
              <p className='read-more' onClick={() => setReadMoreOpen(true)}>
                {strings.migrateCredit_read_more_step2}
              </p>
            </article>
          </div>
        )}

        <article>
          <Checkbox
            content={strings.migrateCredit_checkbox_step2}
            checked={consent}
            handleClick={handleClick}
          />
        </article>
      </div>
    </div>
  );
};

export default Step2;
