import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { strings } from '../../constants/localization';

const StepOne = (props) => (
  <div className='step-content'>
    <div className='step-header'>
      <i className='di di-add-user' />
      <div className='title-text'>{strings.apStep1Title}</div>
    </div>
    <div className='step-body'>
      <div className='text-information'>
        <p>{strings.apStep1HelpText}</p>
      </div>
      <ListGroup className='select-options'>
        <ListGroupItem
          className='select-item'
          onClick={() => props.nextStep({ hasCredit: true })}
        >
          <i className='di di-circle-check' />
          <div>
            <h5>{strings.apStep1YesTitle}</h5>
            <p>{strings.apStep1YesText}</p>
          </div>
        </ListGroupItem>
        <ListGroupItem
          className='select-item'
          onClick={() => props.nextStep({ hasCredit: false })}
        >
          <i className='di di-circle-plus' />
          <div>
            <h5>{strings.apStep1NoTitle}</h5>
            <p>{strings.apStep1NoText}</p>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
  </div>
);

export default StepOne;
