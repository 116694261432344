import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';
import './style.scss';
import ButtonLoader from '../ButtonLoader/ButtonLoader';

const DropDown = ({
  placeholder,
  currentValue,
  options,
  setValue,
  hasError,
  showStatus,
  loading,
}) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [opend, setOpend] = useState(false);
  const ref = useRef();

  const select = (e, value) => {
    e.preventDefault();
    setSelectedItem(value);
    setOpend(false);

    if (setValue) {
      setValue(value);
    }
  };

  const hadleClickOutside = useCallback((e) => {
    e.preventDefault();
    if (ref.current && !ref.current.contains(e.target)) {
      e.stopPropagation();
      setOpend(false);
    }
  }, []);

  const toggle = (e) => {
    e.preventDefault();
    if (!opend) {
      setOpend(true);
    } else {
      setOpend(false);
    }
  };

  useEffect(() => {
    if (currentValue && currentValue?.value) {
      setSelectedItem(currentValue);
    } else {
      setSelectedItem({});
    }
  }, [currentValue]);

  /* eslint-disable */
  useEffect(() => {
    if (opend) {
      document.addEventListener('click', hadleClickOutside);
    } else {
      document.removeEventListener('click', hadleClickOutside);
    }
  }, [opend]);

  /* eslint-enable */

  useEffect(() => () => {
    document.removeEventListener('click', hadleClickOutside);
    // eslint-disable-next-line
  });

  const getValueHtml = () => {
    if (selectedItem && selectedItem.top) {
      return <>{selectedItem.top}</>;
    } else if (currentValue && currentValue.top) {
      return <>{currentValue.top}</>;
    } else {
      return <span className='placeholder'>{placeholder}</span>;
    }
  };

  return (
    <div className='DropDown_Component'>
      <div
        className={`Top_area ${hasError ? 'hasError' : ''}`}
        onClick={!loading ? toggle : () => {}}
      >
        {getValueHtml()}
        {loading ? (
          <div className='filter-loader'>
            <ButtonLoader />
          </div>
        ) : (
          <div className='triangle'>
            <svg
              viewBox='0 0 20 20'
              aria-hidden='true'
              focusable='false'
              className='css-tj5bde-Svg'
            >
              <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
            </svg>
          </div>
        )}
      </div>
      {opend && (
        <div className='drop_area' ref={ref}>
          {!loading &&
            options.map((item) => (
              <div
                className='row'
                onClick={(e) => {
                  select(e, item);
                }}
                key={item.value}
              >
                <div
                  className={`first_column ${item.front ? '' : 'displayNone'}`}
                >
                  {item.front}
                </div>
                <div
                  className={`second_column ${
                    item.front ? '' : 'hasmargin fullWidth'
                  }`}
                >
                  <div className={`first ${!item.bottom ? 'boldText' : ''}`}>
                    {item.top}
                  </div>
                  <div
                    className={`second ${!item.bottom ? 'displayNone' : ''}`}
                  >
                    {item.bottom}
                  </div>
                </div>
                {showStatus && (
                  <div
                    className={`third_column ${
                      item.right ? '' : 'displayNone'
                    }`}
                  >
                    <span
                      className={`status-${item.right} badge badge-light badge-pill`}
                    >
                      {strings[item.right]}
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
DropDown.propTypes = {
  currentValue: PropTypes.object,
  placeholder: PropTypes.string,
  setValue: PropTypes.func,
  options: PropTypes.array,
  hasError: PropTypes.bool,
};

export default DropDown;
