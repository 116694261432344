import React, { useEffect, useState } from 'react';
import { strings } from '../../constants/localization';
import { useDispatch, useSelector } from 'react-redux';
import { createSundhedplusUserAndGetSessionId } from '../../actions/creditMigrateActions';

const Step3 = (props) => {
  const dispatch = useDispatch();
  const clientDetails = useSelector(
    (state) => state.migrateCredit?.clientDetails
  );
  const [creditNumber, setCreditNumber] = useState();
  const [latestLoanBrand, setLatestLoanBrand] = useState();

  // send page event to customer.io tracking system, after component is mounted
  useEffect(() => {
    _cio.page('migration - ' + strings.migrateCredit_title_step3);
  }, []);

  useEffect(() => {
    if (clientDetails && Object.keys(clientDetails).length > 0) {
      setCreditNumber(clientDetails?.creditNumber);
      setLatestLoanBrand(clientDetails?.latestLoanBrand);
    }
  }, [clientDetails]);

  useEffect(() => {
    if (latestLoanBrand === 'Sundhed+') {
      props.setStep(4);
    } else if (creditNumber) {
      dispatch(createSundhedplusUserAndGetSessionId(creditNumber))
        .then((result) => {
          const sessionData = result.payload?.data;
          // eslint-disable-next-line
          const rp = new Reepay.EmbeddedCheckout(sessionData.id, {
            html_element: 'rp_container',
          });
          // eslint-disable-next-line
          rp.addEventHandler(Reepay.Event.Accept, (data) => {
            registerPaymentCard(data);
          });
        })
        .catch(() => {
          // set error step
          props.setStep(6);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditNumber]);

  const registerPaymentCard = (data) => {
    props.nextStep({
      paymentMethod: data?.payment_method,
    });
  };

  return (
    <div className='Step3 section'>
      <div className='title'>{strings.migrateCredit_title_step3}</div>
      <div className='content'>
        <article>
          <p>{strings.migrateCredit_first_paragraph_step3}</p>
        </article>
        <article>
          <p>{strings.migrateCredit_second_paragraph_step3}</p>
        </article>

        <div className='reepay-form-container'>
          <div id='rp_container' />
        </div>
      </div>
    </div>
  );
};

export default Step3;
