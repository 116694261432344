import React from 'react';
import { Button } from 'reactstrap';
import { brandConfig } from '../../config';
import { strings } from '../../constants/localization';
import brokenRobot from '../../resources/images/broken-robot.svg';

const StepError = ({ hasRedirect }) => {
  console.log('StepError -> hasRedirect', hasRedirect);
  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
      : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');
  return (
    <div className='step-content step-eight'>
      <h1 className='step-title'>{strings.errorServerTitle}</h1>
      <div className='error-info'>
        <div>{strings.errorServerText1}</div>
        <div>{strings.errorServerText2}</div>
      </div>
      <div className='error-image-container'>
        <img
          src={brokenRobot}
          alt='broken-robot'
          className='image-broken-robot'
        />
      </div>
      <div className='visit-action-buttons'>
        <Button color='blue' tag='a' href={`tel:${phoneNumber}`}>
          {strings.formatString(
            strings.declineCallText,
            <br className='display-iphone5' />,
            phoneNumber
          )}
        </Button>
        <Button
          color='blue'
          tag='a'
          href={`mailto:${brandConfig.contactEmail}`}
        >
          {strings.declineEmailText}
        </Button>
        {hasRedirect && (
          <Button
            type='button'
            className='btn btn-blue mt-5'
            onClick={() => window.close()}
          >
            {strings.close}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepError;
