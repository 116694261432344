import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { strings } from '../../../../../constants/localization';
import { moment, momentFormatLong } from '../../../../../constants';
import { isAdmin } from '../../../../../constants/utils';
import CreditFacilityModal from '../../CreditFacility/CreditFacilityModal';

import './style.scss';
import { useEffect } from 'react';

function CalendarCreditInfo(props) {
  const user = useSelector((state) => state.user.user);
  const [creditDetails, setCreditDetails] = useState(props.creditDetails);
  const [showCreditFacilityModal, setShowCreditFacilityModal] = useState(false);
  const timeDiference = moment().diff(
    moment(creditDetails.expirationDate).add(1, 'days')
  );

  useEffect(() => {
    setCreditDetails(props.creditDetails);
  }, [props.creditDetails]);

  const isExpired = timeDiference >= 0 ? true : false;
  const isActive = creditDetails.status === 'ACTIVE';
  const isDisabled = creditDetails.status === 'DISABLED';
  const isSuspended = creditDetails.status === 'SUSPENDED';
  const isRejected = creditDetails.status === 'REJECTED';
  const latePayment = creditDetails.status === 'LATE_PAYMENT';

  const ICONS_LOAN_STATES = Object.freeze({
    active: <i className='lnil lnil-checkmark-circle green' />,
    expired: <i className='lnil lnil-cross-circle red' />,
    suspended: <i className='lnil lnil-circle-minus gray' />,
    suspended_admin: <i className='lnil lnil-cross-circle yelow' />,
    rejected: <i className='lnil lnil-circle-minus gray' />,
    rejected_admin: <i className='lnil lnil-circle-minus gray' />,
    late_payment: <i className='lnil lnil-circle-minus gray' />,
    late_payment_admin: <i className='lnil lnil-cross-circle red' />,
    disabled: <i className='lnil lnil-circle-minus gray' />,
  });

  let generateLoanSituation = () => {
    switch (creditDetails.status) {
      case 'ACTIVE':
        if (isExpired) {
          return 'expired';
        }
        return 'active';
      case 'SUSPENDED':
        if (isAdmin(user)) {
          return 'suspended_admin';
        }
        return 'suspended';
      case 'REJECTED':
        if (isAdmin(user)) {
          return 'rejected_admin';
        }
        return 'rejected';
      case 'LATE_PAYMENT':
        if (isAdmin(user)) {
          return 'late_payment_admin';
        }
        return 'late_payment';
      case 'DISABLED':
        return 'disabled';
      default:
        return null;
    }
  };

  let headingStyleColor = () => {
    if (latePayment && isAdmin(user)) {
      return 'red';
    }
    if (isSuspended && isAdmin(user)) {
      return 'yelow';
    }
  };

  return (
    <div
      id='CalendarCreditInfoComponent'
      className='CalendarCreditInfo_component'
    >
      <div className='icon_area'>
        {ICONS_LOAN_STATES[generateLoanSituation()]}
      </div>
      <div className='content_area'>
        <div className={`top_area ${headingStyleColor()}`}>
          {isActive && !isExpired && strings.creditActiv}
          {isExpired &&
          !isSuspended &&
          !isDisabled &&
          !isRejected &&
          !latePayment
            ? strings.expired
            : ''}
          {isAdmin(user) &&
          isExpired &&
          !isSuspended &&
          !isDisabled &&
          !isRejected &&
          !latePayment ? (
            <span>
              {` (${moment(creditDetails.expirationDate)
                .format(momentFormatLong)
                .toLowerCase()})`}
            </span>
          ) : (
            ''
          )}

          {/* Patient with no active credit (not admin) */}
          {!isAdmin(user) &&
            (isDisabled || isSuspended || latePayment) &&
            strings.noActivCredit}

          {/* Patient with denied application (only admin) */}
          {isAdmin(user) && isRejected && strings.applicationDenied}

          {/* Patient with credit suspended (only admin) */}
          {isAdmin(user) && isSuspended && strings.creditSuspended}

          {/* Patient with late payment (only admin) */}
          {isAdmin(user) && latePayment && strings.latepayment}

          {/* Patient with no active credit (only admin) */}
          {isAdmin(user) && isDisabled && strings.noActivCredit}
        </div>

        <div className='bottom_area '>
          {/* Show when credit should expire */}
          {((!isExpired &&
            !isDisabled &&
            !isSuspended &&
            !isRejected &&
            !latePayment) ||
            (!isExpired && isSuspended)) && (
            <>
              {creditDetails.remainingTime.totalDays > 1 &&
                strings.formatString(
                  strings.expresMore,
                  creditDetails.remainingTime.totalDays + 1
                )}
              {creditDetails.remainingTime.totalDays === 1 &&
                strings.expiresOne}
              {creditDetails.remainingTime.totalDays === 0 &&
                strings.expiresTuday}
              <span>{` (${moment(creditDetails.expirationDate)
                .format(momentFormatLong)
                .toLowerCase()})`}</span>
            </>
          )}

          {/* Credit is expired (but not suspended) and needs renewel */}
          {isExpired &&
            !isDisabled &&
            !isSuspended &&
            !latePayment &&
            !isRejected &&
            strings.creditNeedsRenewel}

          {/* Patient with didn't applied for a credit */}
          {isDisabled &&
            !isSuspended &&
            !isRejected &&
            strings.patientWithNocreditAplication}

          {/* Patient with finished credit (not for admin) */}
          {!isAdmin(user) &&
            ((isSuspended && isExpired) || isRejected || latePayment) &&
            strings.creditTerminated}

          {/* Patient with no credit (for admin only)*/}
          {isAdmin(user) && isRejected && strings.patientWitNoCredit}

          {/* Credit is suspended and expired (for admin only)*/}
          {isAdmin(user) && isSuspended && isExpired && (
            <>
              {strings.creditHasExpired}
              <span>{` (${moment(creditDetails.expirationDate)
                .format(momentFormatLong)
                .toLowerCase()})`}</span>
            </>
          )}

          {/* Credit closed */}
          {isAdmin(user) && latePayment && strings.creditClosed}
        </div>
      </div>
      {isAdmin(user) && (
        <div
          className='editCreditFacilityButton'
          onClick={() => {
            setShowCreditFacilityModal(true);
          }}
        >
          <i className='lnir lnir-pencil icon'></i>
        </div>
      )}

      {isAdmin(user) && showCreditFacilityModal && (
        <CreditFacilityModal
          creditDetails={creditDetails}
          hideModal={() => setShowCreditFacilityModal(false)}
        />
      )}
    </div>
  );
}

CalendarCreditInfo.propTypes = {
  creditDetails: PropTypes.object,
  user: PropTypes.object,
};

export default CalendarCreditInfo;
