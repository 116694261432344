import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import { moment } from '../../constants';
import { ROUTE_UPDATE_CARD } from '../../constants/routes';
import LoadingMessage from '../statusMessages/LoadingMessage';
import icon from '../../resources/images/sundhedplus-logo-invert.svg';
import './style.scss';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import StepErrorDunning from './StepErrorDunning';
import SomethingWentWrongError from '../common/SomethingWentWrongError';
import {
  getMigratingClient,
  migrateUserFromDentiToSundhed,
} from '../../actions/creditMigrateActions';
import { Sundhedplus } from '../../config';

function MigrateCreditFlow() {
  const link = window.location.href;
  const url = new URL(link);
  const leadUuid = url.searchParams.get('leaduuid');
  const clientEmail = url.searchParams.get('clientemail');
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [maxSteps] = useState(4);
  const [next, setNext] = useState(false);
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [migratingLoading, setMigratingLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [clientId, setClientId] = useState();
  const [subscriptionHandle, setSubscriptionHandle] = useState();
  const userDataForCardUpdate = useSelector(
    (state) => state.migrateCredit?.userDataForCardUpdate
  );
  const [isCreditExpired, setIsCreditExpired] = useState(false);
  const [isCreditInactive, setIsCreditInactive] = useState(false);

  useEffect(() => {
    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    document.body.classList.add('loading');

    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  // detect if the user are using a IOS device and prevent to zoon in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  //get the needed params from url and use them to get the migrating client details
  useEffect(() => {
    if (leadUuid && clientEmail) {
      dispatch(getMigratingClient(leadUuid, clientEmail))
        .then((response) => {
          if (response && response.payload?.status === 200) {
            setLoading(false);
            const clientDetails = response.payload?.data;
            setClientDetails(clientDetails);
            setIsCreditExpired(
              !notExpired(clientDetails?.creditExpirationDate)
            );
            setIsCreditInactive(clientDetails?.creditAmount === 0);
            // is credit brand is Sundhed then skip some steps
            if (
              clientDetails &&
              Object.keys(clientDetails).length > 0 &&
              clientDetails?.creditBrand === 'Sundhed+' &&
              (clientDetails?.latestLoanBrand === 'Sundhed+' ||
                clientDetails?.latestLoanBrand === null)
            ) {
              setStep(4);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err &&
            err?.response?.data?.cause ===
              'Bad Request: Client is in dunning, and it is therefore not possible to migrate the client'
          ) {
            setStep(5);
          } else {
            setStep(6);
          }
        });
    } else {
      setLoading(false);
      setStep(6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadUuid, clientEmail]);

  useEffect(() => {
    if (userDataForCardUpdate) {
      setClientId(userDataForCardUpdate.clientId);
      setSubscriptionHandle(userDataForCardUpdate.subscriptionHandle);
    }
  }, [userDataForCardUpdate]);

  const updateConsent = (receivedConsent) => {
    setConsent(receivedConsent);
  };

  const notExpired = (creditExpirationDate) => {
    if (creditExpirationDate) {
      const today = moment();
      const expirationDate = moment(creditExpirationDate, [
        'MM-DD-YYYY',
        'YYYY-MM-DD',
      ]);
      const notExpired = today.isBefore(expirationDate);
      return notExpired;
    }
    return false;
  };

  const migrateLoans = (creditNumber, signupMethod, token) => {
    setMigratingLoading(true);
    dispatch(migrateUserFromDentiToSundhed(creditNumber, signupMethod, token))
      .then((response) => {
        if (response && response.payload?.status === 200) {
          setMigratingLoading(false);
          setStep(4);
        }
      })
      .catch(() => {
        setMigratingLoading(false);
        setStep(6);
      });
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return <Step1 next={next} isCreditExpired={isCreditExpired} />;
      case 2:
        return <Step2 updateConsent={updateConsent} />;
      case 3:
        return <Step3 nextStep={nextStep} setStep={setStep} />;
      case 4:
        return (
          <Step4 clientEmail={clientEmail} isCreditExpired={isCreditExpired} />
        );
      case 5:
        return (
          <StepErrorDunning
            leadUuid={leadUuid}
            clientEmail={clientEmail}
            setStep={setStep}
          />
        );
      case 6:
        return <SomethingWentWrongError />;
      default:
        return null;
    }
  };

  const nextStep = (updateData = null) => {
    let paymentMethod =
      updateData && updateData?.paymentMethod ? updateData.paymentMethod : null;

    if (step === 1) {
      if (clientDetails?.creditBrand === 'Sundhed+') {
        // jump to add credit card
        setStep(3);
      } else {
        // jump to terms and conditions
        setStep(step + 1);
      }
    } else if (step === 2) {
      // go to next step only if the terms and conditions where accepted
      if (consent) {
        setNext(true);
        // if creditBrand == Denti and latestLoanBrand = null then we also skip Step 3
        if (
          clientDetails?.creditBrand === 'Denti' &&
          clientDetails?.latestLoanBrand === null
        ) {
          migrateLoans(clientDetails?.creditNumber, 'email', null);
        } else {
          setStep(step + 1);
        }
      } else {
        setNext(false);
        // error step
        setStep(6);
      }
      setStep(step + 1);
    } else if (step === 3) {
      if (paymentMethod) {
        if (clientDetails?.latestLoanBrand === 'Denti') {
          migrateLoans(clientDetails?.creditNumber, 'source', paymentMethod);
        }
      } else {
        // error step
        setStep(6);
      }
    } else if (step === 6) {
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };

  const back = () => {
    setStep(step - 1);
  };

  const getStepControls = () => {
    switch (step) {
      case 1:
        return (
          <Button
            className='btn btn-blue btn-success full-width'
            onClick={nextStep}
          >
            {strings.migrateCredit_button_step1}
          </Button>
        );
      case 2:
        return (
          <div>
            <Button className='btn btn-blue btn-cancel' onClick={back}>
              {strings.migrateCredit_button_back}
            </Button>
            <Button
              className='btn btn-blue btn-success'
              disabled={!consent}
              onClick={nextStep}
            >
              {strings.migrateCredit_button_next}
            </Button>
          </div>
        );
      case 3:
        return null;
      case 4:
        return (
          <Button
            className='btn btn-blue btn-success full-width'
            tag='a'
            href={
              isCreditExpired || isCreditInactive
                ? Sundhedplus.presentationSite
                : Sundhedplus.SundhedplusFindClinicUrl
            }
          >
            {isCreditExpired || isCreditInactive
              ? strings.migrateCredit_button_step4_expired
              : strings.migrateCredit_button_step4}
          </Button>
        );
      case 5:
        const prefix =
          process.env.REACT_APP_STAGE === 'preprod' ? 'ppd_' : 'dev_';
        return (
          <Button
            className='btn btn-blue btn-success full-width'
            tag='a'
            href={
              ROUTE_UPDATE_CARD +
              `?c=${prefix}${clientId}&s=${subscriptionHandle}`
            }
          >
            {strings.migrateCredit_error_btn}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div id='Sundhedplus'>
      <div className='MigrateCreditFlow d-flex min-vh-100 h-100 flex-column'>
        {loading && <LoadingMessage />}
        {!loading && (
          <>
            <div className='d-logo logo-green'>
              <img src={icon} alt='' />
            </div>
            <div className='container'>
              <div className='migration-steps'>
                <div className='migration-steps_content'>
                  {!migratingLoading && getStepContent()}
                  {migratingLoading && (
                    <LoadingMessage message={strings.migrateCreditLoadingMsg} />
                  )}
                </div>
                {step !== 6 && (
                  <div className='controls'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='actions-buttons'>
                          {getStepControls()}
                        </div>
                      </div>
                    </div>
                    {step === 4 ? (
                      <div className='timestamp'>
                        {moment().format('DD-MM-YYYY  HH:mm:ss')}
                      </div>
                    ) : (
                      step !== 5 && (
                        <div className='migration-steps_step-bullets'>
                          {[...Array(maxSteps)].map((StepComponent, i) => (
                            <span
                              key={`stepBullet-${i}`}
                              className={`step-bullet ${
                                i <= step - 1 ? 'checked' : ''
                              }`}
                            />
                          ))}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default MigrateCreditFlow;
