import React, { useState } from 'react';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

function LoginModal() {
    const [currentComponent, setCurrentComponent] = useState('login'); // 'login' or 'forgotPassword'
    const [resetPassordEmail, setResetPassordEmail] = useState('');
    return (
        <div>
            {currentComponent === 'login' ? (
                <Login switchToForgotPassword={() => setCurrentComponent('forgotPassword')} resetPassordEmail={resetPassordEmail} />
            ) : (
                <ForgotPassword switchToLogin={() => setCurrentComponent('login')} setResetPassordEmail={setResetPassordEmail} />
            )}
        </div>
    );
}

export default LoginModal;
