import {
  SET_CURRENT_DOCUMENTATION_ITEM,
  GET_ALL_DOCUMENTS,
  GET_COUNTER_PENDING_DOCUMENTS,
  UPDATE_DOCUMENTATION_FILTER_PARAMS,
  DOCUMENTATION_FAILURE,
  DOCUMENTATION_SUCCESS,
  DOCUMENTATION_EMPTY,
  DOCUMENTATION_LOADING,
  GET_DOCUMENT_DETAILS,
  UPDATE_DOCUMENTATION_INTERNAL_NOTE,
  UPDATE_DOCUMENTATION_INFORMATION,
  SET_DOCUMENTATION_ITEM_LOADING,
  SET_DOCUMENTATION_ACTIVE_PAGE,
} from '../constants';

const defaultState = {
  data: [],
  activeDocListItem: {},
  filterParams: [],
  documentDetails: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_DOCUMENT_DETAILS:
      return {
        ...state,
        documentDetails: action.payload,
      };

    case GET_COUNTER_PENDING_DOCUMENTS:
      return {
        ...state,
        counterPendingDocuments: action.payload,
      };
    case SET_CURRENT_DOCUMENTATION_ITEM:
      return {
        ...state,
        activeDocListItem: action.payload,
      };
    case SET_DOCUMENTATION_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case DOCUMENTATION_EMPTY:
    case DOCUMENTATION_FAILURE:
    case DOCUMENTATION_SUCCESS:
    case DOCUMENTATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_DOCUMENTATION_ITEM_LOADING:
      return {
        ...state,
        loadingDocumentationItem: action.payload,
      };
    case UPDATE_DOCUMENTATION_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };
    case UPDATE_DOCUMENTATION_INTERNAL_NOTE:
      const { activeDocListItem } = state;

      return {
        ...state,
        activeDocListItem: {
          ...activeDocListItem,
          internalNote: action.payload,
        },
      };

    case UPDATE_DOCUMENTATION_INFORMATION:
      const foundIndex = state.data.content.findIndex(
        (item) => item.cpr === state.activeDocListItem.cpr
      );
      const newContentArray = [...state.data.content];
      newContentArray[foundIndex] = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          content: newContentArray,
        },
      };

    default:
      return state;
  }
}
