import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';
import { phoneNoFormat } from '../../../constants/utils';
import './EditCompanyForm.scss';
import { ROUTE_COMPANY_CHAINS } from '../../../constants/routes';

const CompanyData = (props) => {
  const {
    companyName,
    address,
    zipCode,
    city,
    cardNumber,
    registrationNumber,
    cvr,
  } = props.companyDetails;
  const registrationValue =
    registrationNumber === '' || registrationNumber === null
      ? '-'
      : registrationNumber;
  const cardValue = cardNumber === '' || cardNumber === null ? '-' : cardNumber;

  const chainCompanyName = props.companyDetails.companyChain.chainCompanyName
    ? props.companyDetails.companyChain.chainCompanyName
    : props.companyDetails.companyChain.companyChainName;

  return (
    <div className='mt-4 lead-personal-data row'>
      <div className='col-12 col-md-5'>
        <div className='media'>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className='lnir lnir lnir-house' />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{strings.nameAndPhone}</div>
            </div>
            <div className='media-body-content'>
              <div className='input-wrapper'>
                <div className='nameAndAddress'>
                  <div className='name3'>{companyName}</div>
                  <div className='name3'>{address}</div>
                  <div className='name3'>
                    {zipCode || ''} {city || ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='media mt-3 '>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className='lnir lnir-shield' />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{strings.cvrLabel}</div>
            </div>
            <div className='media-body-content'>
              <div className='input-wrapper'>
                <div className='fullWidth'>
                  <div>{phoneNoFormat(cvr)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-7'>
        <div className='media mail col-12'>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className='lnir lnir-apartment' />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{strings.companyAffiliation}</div>
            </div>
            <div className='media-body-content'>
              <div>
                <Link
                  to={{
                    pathname: ROUTE_COMPANY_CHAINS,
                    state: { ref: props.companyDetails.companyChain },
                  }}
                >
                  {chainCompanyName}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='media phone col-12 '>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className='lnir lnir-bank' />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{strings.registrationNumber}</div>
            </div>
            <div className='media-body-content'>
              <div>
                {registrationValue === '-' && cardValue === '-' ? (
                  <div>-</div>
                ) : (
                  <div>
                    {registrationValue} {cardValue}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyData.propTypes = {
  companyDetails: PropTypes.object,
};

export default CompanyData;
