/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Table, Badge } from 'reactstrap';

import { strings } from '../../constants/localization';
import { currencyFormatDA } from '../../constants/utils';
import { moment } from '../../constants';
import {
  cardStatusMap,
  dunningStatusMap,
  dunningCauseMap,
} from '../../constants/mappings';
import { setCurrentPatient } from '../../actions/patientDunningActions';
import { getAllAdmins } from '../../actions/userActions';
import LoadingMessage from '../statusMessages/LoadingMessage';

const PatientsList = (props) => {
  const tableBodyRef = useRef(null);
  const patientsStored = useSelector(
    (state) => state.patientsDunning?.patients?.clientInDunningList
  );
  const [leadList, setLeadList] = useState([]);
  const [shouldRenderScrollbar, setShouldRenderScrollbar] = useState(false);
  const [activeColumn, setActiveColumn] = useState(
    sessionStorage.getItem('lastSort') !== null
      ? JSON.parse(sessionStorage.getItem('lastSort')).activeColumn
      : -1
  );
  const [toggle, setToggle] = useState(
    sessionStorage.getItem('lastSort') !== null
      ? JSON.parse(sessionStorage.getItem('lastSort')).reverse
      : false
  );

  const getDunningTypeText = (dunningType) => {
    let found = dunningCauseMap.find(
      (cause) => cause.value === dunningType
    )?.label;
    found = found ? strings[found] : '';
    return found;
  };

  const sortByColumn = (colIndex, reverse) => {
    let sortedList = [];

    const sortFunction = (a, b) => {
      const signReverse = reverse ? -1 : 1;

      const helpSort = (leftSide, rightSide) =>
        leftSide === rightSide
          ? 0
          : leftSide < rightSide
          ? -signReverse
          : signReverse;

      switch (colIndex) {
        case 'creditCardState': {
          const leftSide =
            a[colIndex] === null
              ? strings.noCard
              : strings[cardStatusMap[a[colIndex]].statusText];
          const rightSide =
            b[colIndex] === null
              ? strings.noCard
              : strings[cardStatusMap[b[colIndex]].statusText];

          return helpSort(leftSide, rightSide);
        }
        case 'dunningStatus': {
          const leftSide =
            a[colIndex] === null
              ? strings.dunningNoStatus
              : strings[dunningStatusMap[a[colIndex]].statusText];
          const rightSide =
            b[colIndex] === null
              ? strings.dunningNoStatus
              : strings[dunningStatusMap[b[colIndex]].statusText];

          return helpSort(leftSide, rightSide);
        }
        case 'lastPaymentPayedDate': {
          const leftSide = a[colIndex] || '1970-01-01';
          const rightSide = b[colIndex] || '1970-01-01';

          return moment(leftSide).diff(moment(rightSide)) * signReverse;
        }
        case 'userInCharge': {
          const leftSide = a[colIndex] || '';
          const rightSide = b[colIndex] || '';

          return helpSort(leftSide, rightSide);
        }
        default: {
          const leftSide = a[colIndex] || 0;
          const rightSide = b[colIndex] || 0;

          return helpSort(leftSide, rightSide);
        }
      }
    };

    sortedList = [...props.patients].sort(sortFunction);

    return sortedList;
  };

  // const initSort = () => {
  //   const lastSort = sessionStorage.getItem('lastSort');
  //   let sortedList;

  //   if (lastSort !== null) {
  //     const { colIndex, reverse } = JSON.parse(lastSort);
  //     sortedList = sortByColumn(colIndex, reverse);
  //   } else {
  //     // a bit force here
  //     const paymentSort = JSON.parse(
  //       '{"colIndex":"lastPaymentPayedDate","reverse":false,"activeColumn":2}'
  //     );
  //     sortedList = sortByColumn(paymentSort.colIndex, paymentSort.reverse);
  //     setActiveColumn(paymentSort.activeColumn);
  //   }

  //   setLeadList(sortedList);
  // };

  useEffect(() => {
    if (patientsStored?.length > 0) {
      if (patientsStored?.length > 20) {
        setShouldRenderScrollbar(true);
      } else {
        setShouldRenderScrollbar(false);
      }
      setLeadList(patientsStored);

      // initSort();
    }
    // eslint-disable-next-line
  }, [patientsStored]);

  const handleColumnClick = (title, key) => {
    let sortList = [];
    let reverse = false;

    if (activeColumn === key) {
      setToggle(!toggle);
      reverse = !toggle;
      sortList = sortByColumn(title, reverse);
    } else {
      setToggle(false);
      reverse = false;
      sortList = sortByColumn(title, reverse);
    }

    setActiveColumn(key);
    sessionStorage.setItem(
      'lastSort',
      JSON.stringify({ colIndex: title, reverse, activeColumn: key })
    );
    setLeadList([...sortList]);
  };

  const hideHeaders = [
    'subscriptionId',
    'clientIdentifier',
    'numberOfActiveLoans',
    'creditFacilityNumber',
    'creditFacilityId',
  ];

  const getSelected = (id) => {
    if (props.activePatient) {
      if (id === props.activePatient.creditFacilityInformation.id) {
        return 'selected';
      }
    }
    return '';
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {patientsStored[0] &&
            Object.keys(patientsStored[0])
              // remove some object properties
              .filter((header) => !(hideHeaders.indexOf(header) >= 0))
              .map((header, index) => (
                <th
                  className={`sortable-header ${header}`}
                  key={`header-${index}`}
                  onClick={() => handleColumnClick(header, index)}
                >
                  {strings[header]}
                  {activeColumn === index ? (
                    toggle ? (
                      <span className='sort-arrow desc' />
                    ) : (
                      <span className='sort-arrow asc' />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody ref={tableBodyRef}>
        {leadList.map((patient, id) => {
          const classNameSelected = getSelected(patient.creditFacilityId);
          return (
            <tr
              key={id}
              onClick={() => props.setCurrentPatient(patient.creditFacilityId)}
              className={classNameSelected}
            >
              <td>
                <div className='d-flex justify-content-between align-items-center'>
                  {patient.clientName}
                  <Badge color='light' pill>
                    {patient.numberOfActiveLoans}
                  </Badge>
                </div>
              </td>
              <td>{currencyFormatDA(patient.totalLoanAmount, true, true)}</td>
              <td>
                {patient.lastPaymentPayedDate === null ? (
                  <>{strings.never}</>
                ) : (
                  <>
                    {moment(patient.lastPaymentPayedDate).format('DD.MM.YYYY')}
                  </>
                )}
              </td>
              <td>{currencyFormatDA(patient.remainingBalance, true, true)}</td>
              <td>{currencyFormatDA(patient.monthlyPayment, true, true)}</td>
              <td>
                {patient.creditCardState === null ? (
                  <span className='status-yellow'>{strings.noCard}</span>
                ) : (
                  <span
                    className={cardStatusMap[patient.creditCardState].className}
                  >
                    {strings[cardStatusMap[patient.creditCardState].statusText]}
                  </span>
                )}
              </td>
              <td>
                {patient.dunningStatus === null ? (
                  <Badge color='light' pill>
                    {strings.dunningNoStatus}
                  </Badge>
                ) : (
                  <Badge
                    color='light'
                    pill
                    className={
                      dunningStatusMap[patient.dunningStatus].className
                    }
                  >
                    {
                      strings[
                        dunningStatusMap[patient.dunningStatus].statusText
                      ]
                    }
                  </Badge>
                )}
              </td>
              <td className='text-left'>
                {patient.userInCharge === null ? '' : patient.userInCharge}
              </td>
              <td className='text-left'>
                {getDunningTypeText(patient.dunningType)}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className={`table-custom ${props.loadingPatients ? 'loading' : ''}`}>
      {(props.empty || props.loadingPatients) && (
        <>
          <div className='table-overlay'></div>
          <Table hover className='patient-table sticky-header'>
            <thead>
              <tr>
                <th>{strings.clientName}</th>
                <th>{strings.totalLoanAmount}</th>
                <th>{strings.lastPaymentPayedDate}</th>
                <th>{strings.remainingBalance}</th>
                <th>{strings.monthlyPayment}</th>
                <th>{strings.creditCardState}</th>
                <th>{strings.dunningStatus}</th>
                <th>{strings.userInCharge}</th>
                <th>{strings.dunningType}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='empty-results' colSpan='100%'>
                  {props.empty && <span>{strings.searchEmpty}</span>}
                  {props.loadingPatients && <LoadingMessage />}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      {!props.empty && !props.loadingPatients ? (
        shouldRenderScrollbar ? (
          <>
            <div className='table-overlay'></div>
            <Table hover className='patient-table sticky-header'>
              {renderTableHeader()}
              {renderTableBody()}
            </Table>
          </>
        ) : (
          <>
            <div className='table-overlay'></div>
            <Table hover className='patient-table sticky-header'>
              {renderTableHeader()}
              {renderTableBody()}
            </Table>
          </>
        )
      ) : null}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    activePatient: state.lead.activeLead,
  };
}
export default connect(mapStateToProps, { setCurrentPatient, getAllAdmins })(
  PatientsList
);
