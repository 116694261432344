import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';

const CancelUpload = ({ closeModal, cancelAction }) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-doc-modal'
      modalClassName='upload-modal'
      close={() => cancelAction()}
      closeBtn={true}
    >
      <div className='cancel-upload-modal d-flex align-items-center justify-content-center'>
        <div className='px-2'>
          <h3 className='headline'>{strings.onboardingUploadCancelHeadline}</h3>
          <div className='subtitle mt-5 mb-5'>
            {strings.onboardingUploadCancelText}
          </div>
        </div>

        <div className='actions-buttons'>
          <Button
            className='btn btn-blue btn-cancel'
            onClick={() => cancelAction()}
          >
            {strings.onboardingUploadCancelNo}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => closeModal()}
          >
            {strings.onboardingUploadCancelYes}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default CancelUpload;
