import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';
import { strings } from '../../../constants/localization';
import { updateDocumentationInternalNote } from '../../../actions/onboardingDocumentationActions';

const InternalNote = () => {
  const dispatch = useDispatch();
  const reactQuillRef = React.useRef();
  const [internalNote, setInternalNote] = useState('');
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );

  const { documents, cpr } = activeDocListItem;

  const documentId = documents?.[0];

  useEffect(() => {
    setInternalNote(activeDocListItem.internalNote || '');
    setFormIsTouched(false);
  }, [activeDocListItem]);

  const updateNote = (text) => {
    setInternalNote(text);
    text !== internalNote ? setFormIsTouched(true) : setFormIsTouched(false);
  };

  const updateInternalNote = () => {
    setLoading(true);
    //update internal internalNote dispatch here

    dispatch(
      updateDocumentationInternalNote(documentId.id, cpr, internalNote)
    ).then(() => {
      setLoading(false);
      setFormIsTouched(false);
    });
  };

  const checkEditorChanged = (value, hasDeleteEvent = false) => {
    const initialValue = decodeURIComponent(internalNote);
    let hasFocus = editorHasFocus;
    if (hasDeleteEvent) {
      hasFocus = true;
    }
    if (hasFocus && value !== initialValue) {
      setFormIsTouched(true);
    } else if (value === initialValue) {
      setFormIsTouched(false);
    }
  };

  return (
    <section className='document-note-section entity-status-card custom-card card'>
      <div className='wrapper'>
        <div className='descriptionWraper'>
          <div className='iconArea'>
            <i className='lnir lnir-pencil-alt'></i>
          </div>
          <div className='titleArea'>{strings.internalNote}</div>
        </div>
        <Row className='detailsArea'>
          <Col xs='12' lg='12'>
            <div className='textarea-wrapper'>
              <div className='quill-editor'>
                <ReactQuill
                  theme='bubble'
                  ref={reactQuillRef}
                  placeholder={strings.notePlaceholder}
                  value={internalNote || ''}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['clean'],
                    ],
                  }}
                  bounds='.quill-editor'
                  onChange={(htmlText) => {
                    updateNote(htmlText);
                  }}
                  onKeyDown={(e) => {
                    const keyCode = e.keyCode;
                    //backspace and delete
                    if (keyCode === 8 || keyCode === 46) {
                      checkEditorChanged(e.target?.innerHTML, true);
                    } else {
                      setEditorHasFocus(true);
                    }
                  }}
                  onPaste={(e) => {
                    updateNote(e.clipboardData.getData('Text'), 'internalNote');
                    setEditorHasFocus(true);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className='text-center'>
          {!loading && (
            <Button
              color='blue'
              disabled={!formIsTouched}
              onClick={() => updateInternalNote()}
            >
              <i className='di di-save' />
              {strings.saveInternalNote}
            </Button>
          )}
          {loading && (
            <Button color='blue' disabled>
              <ButtonLoader />
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default InternalNote;
