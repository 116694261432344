import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';
import dentiLogo from '../../resources/images/email-template/denti-logo-green.png';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';

import {
  getSessionReepay,
  sendSubscriptionData,
} from '../../actions/userActions';
import { brandConfig } from '../../config';
import { strings } from '../../constants/localization';
import ErrorPage from '../common/ErrorPage';

class UpdateCardDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ie: /msie|Trident.*rv[ :]*11\./gi.test(window.navigator.userAgent),
      modal: false,
      creditNumber: '',
    };
  }

  componentDidMount() {
    // get query s from url and set it to creditNumber
    const urlParams = new URLSearchParams(window.location.search);
    const creditNumberFromUrl = urlParams.get('s');

    if (creditNumberFromUrl) {
      this.setState({
        creditNumber: creditNumberFromUrl,
      });
    }
    // dected if IOS device and add a margin bottom to the body to avoid the footer to be hidden by the keyboard and do not allow to zoon in / out
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );

      const updateCardDetails = document.getElementsByClassName(
        'update-card-details'
      );
      //padding bottom 100 px to avoid the footer to be hidden by the keyboard
      updateCardDetails[0].style.paddingBottom = '100px';
    }

    document.body.classList.add('iframe');
    document.body.classList.add('public-content');

    const query = queryString.parse(this.props.location.search);

    const { c, s } = query;
    // (customerHandle, subscriptionHandle)
    this.props
      .getSessionReepay(c, s)
      .then((result) => {
        const { sessionId } = result.payload.data;
        // eslint-disable-next-line
        const rp = new Reepay.EmbeddedCheckout(sessionId, {
          html_element: 'rp_container',
        });
        // eslint-disable-next-line
        rp.addEventHandler(Reepay.Event.Accept, (data) => {
          this.props
            .sendSubscriptionData({ handle: s, source: data.payment_method })
            .then(() => {});
        });
      })
      .catch(() => {
        this.setState({
          modal: true,
        });
      });
  }

  componentWillUnmount() {
    document.body.classList.remove('iframe');
    document.body.classList.remove('public-content');
  }

  render() {
    const brandEnvVariable = process.env.REACT_APP_BRAND;

    const rowClasses = `details ${this.state.ie ? 'ie' : ''}`;
    const phoneNumber =
      strings.getLanguage() === 'en'
        ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
        : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');
    let element;
    if (this.state.modal === false) {
      element = (
        <div id={`${brandEnvVariable}`}>
          <div className='update-card-details'>
            <div className='top-header'>
              {brandEnvVariable === 'Denti' && (
                <img src={dentiLogo} alt='denti-logo' />
              )}
              {brandEnvVariable === 'Sundhedplus' && (
                <img src={sundhedLogo} alt='Sundhed+ logo' />
              )}
            </div>
            <div className='container'>
              <Row className={rowClasses}>
                <Col xs='12' md='12' lg='12'>
                  <div className='header-wrapper'>
                    <h1>{strings.heading}</h1>
                    <p>
                      {strings.formatString(
                        strings.subheading,
                        this.state.creditNumber
                      )}
                    </p>
                  </div>
                </Col>
                <Col xs='12' md='12' lg='12' className='mb-3'>
                  <p>{strings.firstParagraph}</p>
                  <p>{strings.secondParagraph}</p>
                </Col>
                <Col xs='12' md='12' lg='12'>
                  <div id='rp_container' />
                </Col>
              </Row>
              <div className='footer'>
                <p>
                  <span className='question mb-5'>
                    {strings.loginAssistance}
                  </span>
                  <br />
                  {strings.formatString(
                    strings.contactHeading,
                    <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>
                      {phoneNumber}
                    </a>,
                    <a href={`mailto:${brandConfig.contactEmail}`}>
                      {brandConfig.contactEmail}
                    </a>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      element = (
        <ErrorPage>
          <h1>{strings.errorHeading}</h1>
          <p>{strings.errorP1}</p>
          <p>
            {strings.formatString(
              strings.errorP2,

              <a href={`mailto:${brandConfig.contactEmail}`}>
                {brandConfig.contactEmail}
              </a>,
              <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>
                {phoneNumber}
              </a>
            )}
          </p>
        </ErrorPage>
      );
    }
    return element;
  }
}

function mapStateToProps(state) {
  return {
    clientDetails: state.user.clientDetails,
  };
}

export default connect(mapStateToProps, {
  getSessionReepay,
  sendSubscriptionData,
})(UpdateCardDetails);
