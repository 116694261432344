import {
  GET_ALL_PATIENTS,
  GET_GLOBAL_PATIENTS_DATA,
  PATIENTS_DUNNING_EMPTY,
  PATIENTS_DUNNING_FAILURE,
  PATIENTS_DUNNING_SUCCESS,
  PATIENTS_DUNNING_LOADING,
  SET_CURRENT_PATIENT,
  GET_NEW_DUNNING_PATIENTS,
  UPDATE_PATIENTS_DUNNING_FILTER_PARAMS,
  DUNNING_PATIENTS_SEARCH_EMITTED,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_PATIENTS:
      return {
        ...state,
        patients: action.payload,
      };

    case UPDATE_PATIENTS_DUNNING_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };

    case DUNNING_PATIENTS_SEARCH_EMITTED:
      return {
        ...state,
        searchActive: action.payload.search,
      };

    case GET_NEW_DUNNING_PATIENTS:
      const updatedObject = {
        ...state.patients,
        ...action.payload,
        clientInDunningList: state.patients.clientInDunningList.concat(
          action.payload.clientInDunningList
        ),
      };

      return {
        ...state,
        patients: updatedObject,
      };

    case GET_GLOBAL_PATIENTS_DATA: {
      return { ...state, globalData: action.payload };
    }
    case SET_CURRENT_PATIENT:
      return {
        ...state,
        activePatient: action.payload,
      };
    case PATIENTS_DUNNING_EMPTY:
    case PATIENTS_DUNNING_FAILURE:
    case PATIENTS_DUNNING_SUCCESS:
    case PATIENTS_DUNNING_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
