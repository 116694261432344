import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Button, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import {
  startOnboarding,
  getBisnodeIdLookupClientByCpr,
  addErrorLog,
  storeOnboardingData,
  storeBudgetTransactionId,
} from '../../actions/onboardingActions';
import { strings } from '../../constants/localization';
import { normalizeCpr, generateRandomUuid } from '../../constants/utils';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import LoadingSpinner from './common/LoadingSpinner/LoadingSpinner';
import { Sundhedplus } from '../../config';
import HiddenAddress from './HiddenAddress';

const CprNumber = ({ setNextStep, step, previousStep }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const { companyId, linkTag, uuid } = savedData || {};
  const [patientCpr, setPatientCpr] = useState(savedData?.normalizedCpr || '');
  const [secretAddress, setSecretAddress] = useState(false);
  const [cprError, setCprError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingMessages] = useState([
    strings.onboardingSpinnerMessageCprGetData,
    strings.onboardingSpinnerMessageCheckCPR,
  ]);

  const [registeringCpr, setRegisteringCpr] = useState(false);
  const [goBackButtonLoading, setGoBackButtonLoading] = useState(false);

  const text = strings.onboardingStepTwoOBS;
  const firstThree = text.substring(0, 3);
  const rest = text.substring(3);

  const boldedText = (
    <>
      <span style={{ fontWeight: 'bold' }}>{firstThree}</span>
      {rest}
    </>
  );
  const maskCharRegex = /_/g;
  const cprInputRef = useRef();

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingCPRNumber}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/indtast-cpr-nummer',
        stepTitle: 'onboarding - indtast cpr',
        event: 'onboarding',
      });
    }
  }, []);

  useEffect(() => {
    // on mount focus on the input
    if (cprInputRef.current) {
      cprInputRef.current.inputElement.focus();
    }

    // add handler to focus if on enter key pressed
    if (patientCpr) {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleNextStep();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [patientCpr]);

  const setCprNumber = (value) => {
    const newValue = value.replace(maskCharRegex, '');
    setPatientCpr(newValue);
  };

  const validateCpr = (patientCpr) => {
    const error = strings.required;
    const normalizedCpr = normalizeCpr(patientCpr);

    if (patientCpr === '') {
      return error;
    }

    if (isNaN(Number(normalizedCpr))) {
      return strings.onlyDigits;
    }

    if (normalizedCpr.length < 10) {
      return strings.formatString(strings.numberOfCharacters, 10);
    }

    return null;
  };

  const handleNextStep = () => {
    const validationError = validateCpr(patientCpr);

    if (validationError) {
      setCprError(validationError);
      return;
    }

    const normalizedCpr = normalizeCpr(patientCpr);
    setRegisteringCpr(true);

    dispatch(getBisnodeIdLookupClientByCpr(normalizedCpr, uuid))
      .then((response) => {
        if (response.payload.status === 200) {
          const bisnodeId = response.payload.data?.id || '';

          dispatch(startOnboarding(bisnodeId, companyId, linkTag))
            .then((result) => {
              if (result.payload.status === 200) {
                const creditApplicationUuid = result.payload?.data || '';
                dispatch(
                  storeOnboardingData({
                    ...savedData,
                    creditApplicationUuid: creditApplicationUuid,
                    bisnodeId: bisnodeId,
                    cpr: normalizedCpr,
                  })
                );
              }
            })

            .then(() => {
              // once started the onboarding, we need to generate and store a new budget transaction id
              try {
                dispatch(storeBudgetTransactionId(generateRandomUuid()));
              } catch (error) {
                console.error('Error generationg the transaction uuid', error);
              }
              setSecretAddress(false);
              setNextStep(step + 1);
            })
            .catch((error) => {
              if (error.response?.status === 400) {
                setButtonLoading(false);
                setNextStep(0);
              } else {
                dispatch(
                  addErrorLog({
                    status: error?.response?.status,
                    message: error?.response?.data,
                  })
                );
                setRegisteringCpr(false);
              }
            });
        }
      })
      .catch((error) => {
        dispatch(
          addErrorLog({
            status: error?.response?.status,
            message: error?.response?.data,
          })
        );
        setRegisteringCpr(false);
        if (error.response?.status === 400) {
          setButtonLoading(false);
          setSecretAddress(true);
        } else {
          setNextStep();
        }
      });
  };

  const renderSecretAddress = () => {
    return <HiddenAddress showCprStep={() => setSecretAddress(false)} />;
  };

  const renderLoadingRegisterCpr = () => {
    return (
      <LoadingSpinner
        loadingMessage={loadingMessages}
        title={strings.loadingMessage}
        subtitle={strings.onboardingSpinnerSubtitleCprRegistration}
      />
    );
  };

  const renderCprNumberInput = () => {
    return (
      <CardBody className='step-content step-two-content'>
        <div className='step-title'>{strings.onboardingCPRNumber}</div>
        <div className='step-subtitle mb-5'>
          {strings.onboardingStepTwoSubtitle}
        </div>

        <div className='form-details'>
          <div className='label-wrapper'>
            <Label>{strings.onboardingCPRNumber}</Label>
            {cprError && <span className='error'>{cprError}</span>}
          </div>
          <MaskedInput
            ref={cprInputRef}
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            type='text'
            className={cprError ? 'input_danger' : ''}
            showMask={false}
            guide={false}
            id='patientCpr'
            value={patientCpr}
            onChange={(event) => {
              setCprNumber(event.target.value);
              setCprError('');
            }}
            onPaste={(event) => {
              setCprNumber(event.target.value);
              setCprError('');
            }}
          />
          <div className='see-more'>
            {strings.formatString(
              strings.onboardingStepTwoSeeMore,
              <a
                href={Sundhedplus.seeOurGuide}
                target='_blank'
                rel='noopener noreferrer'
              >
                {strings.onboardingStepTwoSeeMoreLink}
              </a>
            )}
          </div>
        </div>

        <div className='mt-5'>{boldedText}</div>
        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-cancel goBackButton'
            disabled={goBackButtonLoading}
            onClick={() => {
              setGoBackButtonLoading(true);
              previousStep();
            }}
          >
            {goBackButtonLoading ? <ButtonLoader /> : strings.onboardingGoBack}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => handleNextStep()}
            disabled={buttonLoading}
          >
            {buttonLoading ? <ButtonLoader /> : strings.onboardingContinue}
          </Button>
        </div>
      </CardBody>
    );
  };
  return secretAddress
    ? renderSecretAddress()
    : registeringCpr
    ? renderLoadingRegisterCpr()
    : renderCprNumberInput();
};

export default CprNumber;
