import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { strings } from '../../../constants/localization';
import { dunningStatusMap } from '../../../constants/mappings';

const DunningStatusDropdown = (props) => {
  const [toggle, setToggle] = useState(false);
  const [activeStatus, setActiveStatus] = useState(props.value);

  const updateStatus = (status) => {
    setActiveStatus(status);
    props.onSelect(status);
  };

  // This will launch only if props.value has chaged
  useEffect(() => {
    setActiveStatus(props.value);
  }, [props.value]);

  return (
    <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)} className={`dunning-status ${activeStatus !== null ? dunningStatusMap[activeStatus].className : ''}`}>
      <DropdownToggle color={dunningStatusMap[activeStatus].color} className={`btn-round btn-outline ${toggle ? 'open' : ''}`} block>
        {
          activeStatus !== null
            ? <span>{strings[dunningStatusMap[activeStatus].statusText]}</span>
            : <span>{strings.dunningNoStatus}</span>
        }
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(dunningStatusMap).map((status) => (
          <DropdownItem
            className='text-center'
            active={activeStatus === status}
            key={status}
            onClick={() => updateStatus(status)}
          >
            <span className={`dot ${dunningStatusMap[status].color}`}></span>
            {strings[dunningStatusMap[status].statusText]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

DunningStatusDropdown.propTypes = {
  onSelect: PropTypes.func,
  value: PropTypes.string,
};

export default DunningStatusDropdown;
