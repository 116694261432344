import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonLoader from '../common/ButtonLoader/ButtonLoader';

const AgreementModalOptions = (props) => {
  const [firstButtonClicked, setFirstButtonClicked] = useState(false);
  const [secondButtonClicked, setSecondButtonClicked] = useState(false);

  const handleFirstActionClick = async () => {
    setFirstButtonClicked(true);
    await props.firstAction();
    setFirstButtonClicked(false);
  };

  const handleSecondActionClick = async () => {
    setSecondButtonClicked(true);
    await props.secondAction();
    setSecondButtonClicked(false);
  };

  return (
    <div className='status-modal'>
      {props.children}
      <div className='bottom-section action-btn cf'>
        <button
          className='submit-btn btn btn-blue '
          type='button'
          onClick={handleFirstActionClick}
          disabled={props.loading || firstButtonClicked}
        >
          {!props.loading && !firstButtonClicked && (
            <>
              <i className='lnil lnil-certificate-alt' />
              <span>{props.firstActionText}</span>
            </>
          )}
          {(props.loading || firstButtonClicked) && <ButtonLoader />}
        </button>
        <button
          className='submit-btn btn btn-blue'
          type='button'
          onClick={handleSecondActionClick}
          disabled={props.loading || secondButtonClicked}
        >
          {!props.loading && !secondButtonClicked && (
            <>
              <i className='lnil lnil-empty-file' />
              <span>{props.secondActionText}</span>
            </>
          )}
          {(props.loading || secondButtonClicked) && <ButtonLoader />}
        </button>
      </div>
    </div>
  );
};

AgreementModalOptions.propTypes = {
  firstAction: PropTypes.func,
  secondAction: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
  firstActionText: PropTypes.string,
  secondActionText: PropTypes.string,
};

export default AgreementModalOptions;
