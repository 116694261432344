import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../constants/localization';

const DeleteWarningModal = (props) => {
  return (
    <div>
      <div className='icon'>
        <i className='lnil lnil-warning' />
      </div>
      <div>
        <h3 className='headline'>{props.headlineText}</h3>
        <p className='warning-message'>{props.bodyText}</p>
        {props?.contactText && (
          <p className='contact-text'>{props?.contactText}</p>
        )}
      </div>
      <div className='bottom-section action-btn cf'>
        <button
          className='submit-btn btn btn-blue center-block'
          type='button'
          onClick={() => props.close()}
        >
          <span>{strings.close}</span>
        </button>
      </div>
    </div>
  );
};

DeleteWarningModal.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  loading: PropTypes.bool,
};

export default DeleteWarningModal;
