import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { strings } from '../../constants/localization';
import { getUserDataForCreditCardUpdate } from '../../actions/creditMigrateActions';
import LoadingMessage from '../statusMessages/LoadingMessage';

const StepErrorDunning = (props) => {
  const dispatch = useDispatch();
  const { leadUuid, clientEmail } = props;
  const [loading, setLoading] = useState(true);

  // send page event to customer.io tracking system, after component is mounted
  useEffect(() => {
    _cio.page('migration - ' + strings.migrateCredit_error_headline);
  }, []);

  useEffect(() => {
    if (leadUuid && clientEmail) {
      dispatch(getUserDataForCreditCardUpdate(leadUuid, clientEmail))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          props.setStep(6);
        });
    } else {
      setLoading(false);
      props.setStep(6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingMessage />}
      {!loading && (
        <div className='StepErrorDunning section'>
          <div className='title'>{strings.migrateCredit_error_headline}</div>
          <div className='content'>
            <article>
              <p>{strings.migrateCredit_error_p1}</p>
              <p>{strings.migrateCredit_error_p2}</p>
            </article>
          </div>
        </div>
      )}
    </>
  );
};

export default StepErrorDunning;
