import React, { useState, useEffect } from 'react';
import { Button, CardBody } from 'reactstrap';
import { brandConfig } from '../../config';

import { strings } from '../../constants/localization';

const HiddenAddress = ({ showCprStep }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingCprFailedTitle}`;
  }, []);

  return (
    <CardBody className='step-content'>
      <div className='step-title'>{strings.onboardingCprFailedTitle}</div>
      <div className='step-subtitle '>
        <p className='existingMemberSubtitle'>
          {strings.onboardingCprFailedSubtitle}
        </p>
      </div>
      <div className='step-subtitle text-left'>
        <h2 className=' existingMemberTitle bold '>
          {strings.onboardingCprFailedSecondTitle}
        </h2>
        <p className='contactForQuestions'>
          {strings.formatString(
            strings.onboardingCprFailedSecondSubtitle,

            <a
              className='color'
              href={`${brandConfig.borderDk}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {strings.onboardingCprFailedBorgerDkLink}
            </a>,
            <a
              className='color'
              href={`${brandConfig.seeOurGuide}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {strings.onboardingCprFailedSecondLink}
            </a>
          )}
        </p>
        <p className='existingMemberSubtitle'>
          {strings.onboardingCprFailedParagraph}
        </p>
      </div>
      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-cancel'
          onClick={() => {
            setButtonLoading(true);
            showCprStep();
          }}
          disabled={buttonLoading}
        >
          {strings.onboardingGoBack}
        </Button>
      </div>
    </CardBody>
  );
};

export default HiddenAddress;
