import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import { retrieveMonthioBudget } from '../../../actions/onboardingActions';
import { strings } from '../../../constants/localization';

const EvaluateBudget = ({ setNextStep, step }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const creditApplicationUuid = savedData?.creditApplicationUuid;
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [changeSpinnerTitle, setChangeSpinnerTitle] = useState(false);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingEvaluatingBudgetMessage}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/validering-af-monthio',
        stepTitle: 'onboarding - validering af monthio',
        event: 'onboarding',
      });
    }
  }, []);

  useEffect(() => {
    const fetchBudgetWithRetry = async () => {
      let attempts = 0;
      const maxAttempts = 4;
      while (attempts < maxAttempts) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await dispatch(
            retrieveMonthioBudget(creditApplicationUuid)
          );
          if (response?.status === 200) {
            setNextStep(step + 1);
            return;
          }
        } catch (error) {
          const { status, data } = error?.response || {};
          // Check if the error is "Budget response is still null."
          if (
            status === 400 &&
            !data?.cause?.includes('Budget response is still null.')
          ) {
            setNextStep(0); // Deny the credit application
            return;
          }
          // Log and continue retrying if it's not the specific error
          console.error(`Attempt ${attempts + 1} failed with error:`, error);
        }
        attempts++;
        // Change the spinner title after the second attempt
        if (attempts === 2) {
          setChangeSpinnerTitle(true);
        }
        // Wait for 2 seconds before retrying
        if (attempts < maxAttempts) {
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
        }
      }
      // If all attempts fail, proceed to the next step
      console.error('All retry attempts failed');
      setNextStep(step + 1);
    };
    fetchBudgetWithRetry();
  }, [dispatch, setNextStep, step, creditApplicationUuid]);

  return (
    <LoadingSpinner
      title={
        changeSpinnerTitle
          ? strings.onboardingAnomalousWaitingTimeMessage
          : strings.onboardingEvaluatingBudgetMessage
      }
    />
  );
};

export default EvaluateBudget;
