import React, { useEffect, useState } from 'react';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import ButtonLoader from './ButtonLoader/ButtonLoader';
import { useDispatch } from 'react-redux';

import { openSendLinkApplicationFromWelcomePage } from '../../actions/uiElementsActions';

interface WelcomePageProps {
  toggleModal: () => void; // Function to toggle send link state in DAM component
  modalShow: boolean;
  toggleCreateNewInvoice: () => void; // Function to toggle create Invoice state in DAM component
  createNewInvoice: boolean;
}

const WelcomePage: React.FC<WelcomePageProps> = ({
  toggleModal,
  modalShow,
  toggleCreateNewInvoice,
  createNewInvoice,
}) => {
  const [sendApplicationButtonLoading, setSendApplicationButtonLoading] =
    useState<boolean>(false);
  const [createInvoiceButtonLoading, setCreateInvoiceButtonLoading] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated
      : brandConfig.contactPhoneHtmlFormated;

  const contactEmail = brandConfig.generalEmail;

  const sendApplicationLink = () => {
    setSendApplicationButtonLoading(true);

    if (!modalShow) {
      // send to step 7 wich is the direct step where we send the application link so we jump all the previusly steps
      dispatch(openSendLinkApplicationFromWelcomePage(7));
      toggleModal();
    }
  };

  // make sure to reset the state if the modal is closed
  useEffect(() => {
    if (!modalShow) {
      dispatch(openSendLinkApplicationFromWelcomePage(null));
    }
  }, [modalShow, dispatch, sendApplicationButtonLoading]);

  useEffect(() => {
    if (createNewInvoice) {
      setCreateInvoiceButtonLoading(false);
    }
    if (modalShow) {
      setSendApplicationButtonLoading(false);
    }
  }, [createNewInvoice, modalShow]);

  return (
    <>
      <div className='welcome-page-wrapper'>
        <div className='welcome-page-header'>
          <h1>{strings.welcomePageTitle}</h1>
          <h2>{strings.welcomePageSubTitle}</h2>
        </div>
        <Row>
          <Col xs='6' md='6' lg='6'>
            <Card className='card-wrapper'>
              <CardBody className='content'>
                <h3>{strings.welcomePageCardCreateInvoiceTitle}</h3>
                <p>{strings.welcomePageCardCreateInvoiceP1}</p>
                <div className='actions-buttons mt-auto'>
                  <Button
                    className='btn btn-blue btn-success full-width'
                    onClick={() => {
                      setCreateInvoiceButtonLoading(true);
                      toggleCreateNewInvoice();
                    }}
                    disabled={createInvoiceButtonLoading}
                  >
                    {createInvoiceButtonLoading ? (
                      <ButtonLoader />
                    ) : (
                      <span className='button-label'>
                        <i className='lnil lnil-circle-plus' />
                        {strings.welcomePageCardCreateInvoiceButton}
                      </span>
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs='6' md='6' lg='6'>
            <Card className='card-wrapper'>
              <CardBody className='content'>
                <h3>{strings.welcomePageCardSendApplicationLinkTitle}</h3>
                <p>{strings.welcomePageCardSendApplicationLinkP1}</p>

                <div className='actions-buttons mt-auto'>
                  <Button
                    className='btn btn-blue full-width'
                    onClick={sendApplicationLink}
                    disabled={sendApplicationButtonLoading}
                  >
                    {sendApplicationButtonLoading ? (
                      <ButtonLoader />
                    ) : (
                      <span className='button-label'>
                        <i className='lnil lnil-message-forward' />
                        {strings.welcomePageCardSendApplicationLinkButton}
                      </span>
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className='info-container'>
          {strings.welcomePageContactInfoP1}
          <br />
          {strings.formatString(
            strings.welcomePageContactInfoP2,
            <a
              className='link-color'
              href={`tel:${phoneNumber.replace(/\s/g, '')}`}
            >
              {phoneNumber}
            </a>,
            <a className='link-color' href={`mailto:${contactEmail}`}>
              {contactEmail}
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
