import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  formatDanishWithoutFractionDigits,
  removeDanishCurrencyFormating,
  numberWithDotAsThousandsSeparator,
} from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import { ReactComponent as ShareIcon } from '../../../resources/icons/share_icon.svg';
import { splitExpensesBudgetItems } from '../../../constants/index';
import { ROUTE_DOCUMENTATION } from '../../../constants/routes';

const BudgetListItem = ({
  budgetItemData,
  budgetMap,
  setBudgetMap,
  setEnableButton,
}) => {
  const [bugetItemAmount, setBudgetItemAmount] = useState(0);
  const [deviationPercentage, setDeviationPercentage] = useState('');
  const [budgetDocumentationStatus, setBudgetDocumentationStatus] =
    useState('');
  const [budgetDocumentationUuid, setBudgetDocumentationUuid] = useState('');
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const { creditApplication, documentation } = activeApplicationListItem || {};
  const { shareExpensesPercentage } = creditApplication || {};
  const data = budgetItemData?.values || [];
  const amounts = {};
  data.forEach((item) => {
    amounts[item.from] = item.amount;
  });
  let timer,
    timeoutVal = 1000;

  useEffect(() => {
    if (budgetItemData) {
      setBudgetItemAmount(
        formatDanishWithoutFractionDigits(budgetItemData?.current?.final || 0)
      );
      // get the deviatio  based on the initial value for final of the updated one
      setDeviationPercentage(
        getDeviation(
          amounts['reference'],
          budgetItemData?.current?.final || amounts['final']
        )
      );

      if (documentation && Object.keys(documentation).length > 0) {
        if (budgetItemData?.type && documentation[budgetItemData?.type]) {
          const status =
            documentation && Object.keys(documentation).length > 0
              ? documentation[budgetItemData?.type][0]?.status
              : '';
          setBudgetDocumentationStatus(status);
          setBudgetDocumentationUuid(
            documentation[budgetItemData?.type][0]?.documentationUuid
          );
        }
      }
    }
  }, [budgetItemData]);

  // const recalculateDeviation = (value) => {
  //   const amountWithoutCurrency = removeDanishCurrencyFormating(value);
  //   setDeviationPercentage(
  //     getDeviation(amounts['reference'], amountWithoutCurrency)
  //   );
  // };

  const getDeviation = (initialValue, finalValue) => {
    let deviationRed = false;
    if (!initialValue) return null;
    const deviation = ((finalValue - initialValue) / initialValue) * 100;
    switch (budgetItemData?.type) {
      case 'NET_INCOME_TOTAL':
      case 'HOUSING_BENEFIT':
      case 'CHILD_BENEFIT':
      case 'CHILDREN_CHECK_AMOUNT':
      case 'ADDITIONAL_INCOME':
        if (deviation <= 0) {
          deviationRed = true;
        }
        break;
      case 'MORTGAGE_EXPENSES':
        if (deviation >= 0) {
          deviationRed = true;
        }
        break;
      case 'HOUSE_LOAN_EXPENSES':
      case 'COMMON_FIXED_EXPENSES':
      case 'MAINTENANCE':
      case 'RENTAL_EXPENSES':
      case 'ELECTRICITY':
      case 'HEATING_EXPENSES':
      case 'WATER':
      case 'CAR_LEASING_COST':
      case 'CAR_LOAN':
      case 'CAR_INSURANCE':
      case 'CAR_MAINTENENCE':
      case 'CAR_OWNERSHIP_TAXES':
      case 'INSURANCE':
      case 'INTERNET_SUBSCRIPTION':
      case 'FAGLIGT_KONTIGENT':
      case 'OTHER_EXPENSES_TO_PUBLIC_AUTHORITIES':
      case 'ADDITIONAL_EXPENSES':
      case 'EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN':
      case 'STUDENT_LOAN_EXPENSES':
        if (deviation < -10) {
          deviationRed = true;
        }
        break;
      case 'REAL_ESTATE_INSURANCE':
      case 'TRANSPORT_FUEL_EXPENSES':
      case 'TRAIN_BUS_CARD':
      case 'CHILDREN_SUPPORT':
      case 'CHILDREN_COST':
      case 'PHONE_SUBSCRIPTION':
      case 'TV_SUBSCRIPTION':
        if (deviation < -20) {
          deviationRed = true;
        }
        break;
      default:
        break;
    }

    if (deviation) {
      return (
        <span className={`${deviationRed ? 'deviation-red' : ''}`}>
          {Math.floor(deviation) + ' %'}
        </span>
      );
    }
    return null;
  };

  const getBudgetStatus = () => {
    if (documentation && Object.keys(documentation).length > 0) {
      if (!budgetItemData?.type || !documentation[budgetItemData?.type]) {
        return null;
      } else {
        const status =
          documentation && Object.keys(documentation).length > 0
            ? documentation[budgetItemData?.type][0]?.status
            : '';
        switch (status) {
          case 'REQUIRED':
            return (
              <span className='required'>
                {strings.applicationDocumentationStatusRequired}
              </span>
            );
          case 'PENDING':
            return (
              <span className='pending'>
                {strings.applicationDocumentationStatusPending}
              </span>
            );
          case 'APPROVED':
            return (
              <span className='approved'>
                {strings.applicationDocumentationStatusApproved}
              </span>
            );
          case 'REJECTED':
            return (
              <span className='rejected'>
                {strings.applicationDocumentationStatusRejected}
              </span>
            );
          default:
            return null;
        }
      }
    }
    return null;
  };

  const updateBudgetObject = (newValue) => {
    const updatedList = budgetMap.map((obj) =>
      obj?.type === budgetItemData?.type
        ? {
            ...obj,
            current: {
              final: newValue,
              datasource: 'CUSTOMER_SERVICE',
            },
          }
        : obj
    );

    setBudgetMap(updatedList);
    setEnableButton(true);
  };

  const handleChange = (evt) => {
    const value = evt.target.value.replace(/[^\d.,-]/g, '');
    let trimmedValue = value;
    //ignore decimals after comma
    if (value.indexOf(',') > -1) {
      trimmedValue = value.substring(0, value.indexOf(',') + 3);
    }
    const valueWithoutPoints = trimmedValue.replaceAll('.', '');
    setBudgetItemAmount(valueWithoutPoints);
  };

  return (
    <div className='budget-item'>
      <div className='item'>
        {budgetDocumentationStatus !== '' &&
        budgetDocumentationStatus !== 'CANCELLED' &&
        budgetDocumentationStatus !== 'REQUIRED' &&
        budgetDocumentationUuid ? (
          <Link
            to={{
              pathname: ROUTE_DOCUMENTATION,
              search: `?q=${budgetDocumentationUuid}`,
            }}
          >
            {strings[budgetItemData?.type] || '-'}
          </Link>
        ) : (
          strings[budgetItemData?.type] || '-'
        )}
      </div>
      <div className='status'>{getBudgetStatus()}</div>
      <div className='reference'>
        {amounts['reference'] !== null && amounts['reference'] !== undefined
          ? formatDanishWithoutFractionDigits(amounts['reference'])
          : '-'}
      </div>
      <div className='bank-data'>
        {amounts['revised'] !== null && amounts['revised'] !== undefined
          ? formatDanishWithoutFractionDigits(amounts['revised'])
          : '-'}
      </div>
      <div className='client'>
        {amounts['clientFinal'] !== null && amounts['clientFinal'] !== undefined
          ? formatDanishWithoutFractionDigits(amounts['clientFinal'])
          : '-'}
      </div>
      <div className='deviation'>
        {deviationPercentage ? deviationPercentage : '-'}
      </div>
      <div className='split-expenses'>
        {shareExpensesPercentage &&
          shareExpensesPercentage !== 1 &&
          splitExpensesBudgetItems.includes(budgetItemData?.type) && (
            <ShareIcon />
          )}
      </div>
      <div className='final'>
        <input
          type='text'
          className='final-input'
          name={`input-${budgetItemData?.type}`}
          value={bugetItemAmount}
          onChange={handleChange}
          onFocus={(event) => {
            let inputVal = event.target.value;
            let clearedValue = removeDanishCurrencyFormating(inputVal);
            if (clearedValue === 0) {
              clearedValue = '';
            }
            setBudgetItemAmount(clearedValue);
          }}
          onBlur={(event) => {
            let formattedValue;
            const withoutFormatting = removeDanishCurrencyFormating(
              event.target.value
            );
            if (withoutFormatting) {
              formattedValue =
                formatDanishWithoutFractionDigits(withoutFormatting);
            } else {
              formattedValue = '0,00 kr.';
            }
            setBudgetItemAmount(formattedValue);
          }}
          onKeyDown={() => window.clearTimeout(timer)}
          onKeyUp={(evt) => {
            window.clearTimeout(timer);
            timer = window.setTimeout(() => {
              updateBudgetObject(
                removeDanishCurrencyFormating(evt.target.value)
              );
              setBudgetItemAmount(
                numberWithDotAsThousandsSeparator(evt.target.value)
              );
            }, timeoutVal);
          }}
        />
      </div>
    </div>
  );
};

export default BudgetListItem;
