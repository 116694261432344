import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  moment,
  GET_BOOKING_REQUEST,
  BOOKING_REQUEST_LINK_EXPIRED,
  BOOKING_REQUEST_ERROR,
} from '../../constants';
import LoadingMessage from '../statusMessages/LoadingMessage';
import PatientInformation from './PatientInformation';
import dentiLogo from '../../resources/images/email-template/denti-logo-green.png';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import './style.scss';
import { ROUTE_HOME } from '../../constants/routes';
import ErrorPage from '../common/ErrorPage';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import { getBookingRequest } from '../../actions/bookingRequestActions';
import BookingStatus from './BookingStatus';

export default function BookingRequestPage() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState([0]);
  const [seconds, setSeconds] = useState([0]);
  const [expirationDate, setExpirationDate] = useState('');
  const [createdDate, setCreatedDate] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [requestText, setRequestText] = useState('');
  const [name, setName] = useState('');
  const [referral, setReferral] = useState('');
  const [uuidValue, setUuidvalue] = useState('');
  const [requestedDates, setRequestedDates] = useState([]);
  const [timeSlot, setTimeSlot] = useState(false);
  const [companyUrl, setCompanyUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const [timeExpiring, setTimeExpiring] = useState(false);
  const dispatch = useDispatch();
  const [clinicDecidedStatus, setClinicDecidedStatus] = useState('');

  const getUuid = useCallback(() => {
    const link = window.location.href;
    const url = new URL(link);
    const uuid = url.searchParams.get('uuid');
    setUuidvalue(uuid);
    dispatch(getBookingRequest({ uuid })).then((response) => {
      const requestData = response.payload;
      if (response.type === GET_BOOKING_REQUEST) {
        setClinicDecidedStatus(requestData.status);
        setEmail(requestData.email);
        setPhone(requestData.phone);
        setRequestText(requestData.requestText);
        setName(requestData.name);
        setRequestedDates(requestData.requestedDates);
        setCompanyUrl(requestData.companyUrl);
        setLoading(false);
        setError(false);
        setLinkExpired(false);
        setCreatedDate(requestData.createdDate);
        setExpirationDate(requestData.expirationDate);
        setReferral(requestData.referral);
      }

      if (response.type === BOOKING_REQUEST_ERROR) {
        setLoading(false);
        setError(true);
        setLinkExpired(false);
      }
      if (response.type === BOOKING_REQUEST_LINK_EXPIRED) {
        setLoading(false);
        setError(false);
        setLinkExpired(true);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add('public-content');

    getUuid();
    return () => {
      document.body.classList.remove('public-content');
    };
    // eslint-disable-next-line
  }, []);

  // detect if the user are using a IOS device then prevent the zoom in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  useEffect(() => {
    if (requestedDates === null || requestedDates.length === 0) {
      setTimeSlot(true);
    } else {
      setTimeSlot(false);
    }
  }, [requestedDates]);

  // eslint-disable-next-line
  const countDown = () => {
    const now = moment.utc(new Date().toUTCString());
    const expiry = moment.utc(expirationDate, 'YYYY-MM-DD HH:mm:ss');
    const duration = moment.duration(expiry.diff(now));
    const hour = duration.asHours();
    const min = duration.asMinutes();
    const sec = duration.asSeconds();

    const days = parseInt(duration / (24 * 60 * 60 * 1000), 10);
    setHours(
      days * 24 +
        Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    setMinutes(Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((duration % (1000 * 60)) / 1000));

    if (hour === 0 && min === 0 && sec === 0) {
      setTimeExpiring(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setSeconds(59);
        if (minutes > 0) {
          setMinutes(minutes - 1);
        } else {
          setMinutes(59);
          if (hours > 0) {
            setHours(hours - 1);
          } else {
            setTimeExpiring(true);
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes, hours]);

  useEffect(() => {
    if (timeExpiring) {
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      countDown();
    }
  }, [countDown, timeExpiring]);

  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
      : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');

  const data = {
    email,
    phone,
    requestText,
    name,
    requestedDates,
    uuidValue,
    companyUrl,
    createdDate,
    expirationDate,
    clinicDecidedStatus,
    referral,
    timeSlot,
  };

  const brandEnvVariable = process.env.REACT_APP_BRAND;

  return (
    <div>
      {!error && !linkExpired && (
        <div className='page-content'>
          <div className='top-header'>
            <NavLink to={ROUTE_HOME}>
              {brandEnvVariable === 'Denti' && (
                <img src={dentiLogo} alt={`${brandConfig.logoAltText}`} />
              )}
              {brandEnvVariable === 'Sundhedplus' && (
                <img src={sundhedLogo} alt={`${brandConfig.logoAltText}`} />
              )}
            </NavLink>
          </div>
          {clinicDecidedStatus === 'DONE_WITH_SUCCESS' ||
          clinicDecidedStatus === 'DONE_WITHOUT_SUCCESS' ? (
            <div className='bookingRequestSolvedBar'>
              <p>{strings.bookingSolvedNotification}</p>
            </div>
          ) : null}

          {loading && <LoadingMessage />}
          {!loading && (
            <>
              <div className='content-wrapper '>
                <div className='second-section'>
                  <PatientInformation patientInformation={data} />
                </div>

                <div className='first-section'>
                  <div className='header-title d-flex justify-content-center'>
                    {strings.header_title}
                  </div>

                  <div className='therapist-container'>
                    {strings.therapist_profile}

                    <a href={companyUrl} className='color'>
                      {strings.therapist_profile1}
                    </a>
                    <span>{strings.therapist_profile2}</span>
                  </div>
                </div>
                <div className='third-section '>
                  <h3 className='contact-patient-title'>
                    {strings.contact_patient_title}
                  </h3>

                  <p>{strings.time_intervals}</p>
                  <p>{strings.resolved_query}</p>

                  <div className='btnBox'>
                    <BookingStatus
                      status={data}
                      setClinicDecidedStatus={setClinicDecidedStatus}
                      setError={setError}
                    />
                  </div>

                  <div className='count-down-timer '>
                    <div className='count-down-text'>
                      {strings.automatic_delete_msg}
                    </div>
                    <div className='count-down-timer-content'>
                      <div
                        className={
                          timeExpiring
                            ? 'count-box red-color timer-text'
                            : 'count-box timer-text'
                        }
                      >
                        {hours < 10 ? `0${hours}` : hours}

                        <div className='box-text'>{strings.timer_hour}</div>
                      </div>

                      <div
                        className={
                          timeExpiring
                            ? 'count-box red-color timer-text'
                            : 'count-box timer-text'
                        }
                      >
                        {minutes < 10 ? `0${minutes}` : minutes}

                        <div className='box-text'>{strings.timer_min}</div>
                      </div>
                      <div
                        className={
                          timeExpiring
                            ? 'count-box red-color timer-text'
                            : 'count-box timer-text'
                        }
                      >
                        {seconds < 10 ? `0${seconds}` : seconds}

                        <div className='box-text'>{strings.timer_seconds}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footer '>
                  <p>
                    <span className='question'>
                      {strings.booking_help_msg}{' '}
                    </span>
                    {strings.formatString(
                      strings.contactHeading,
                      <a
                        className='color'
                        href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                      >
                        {phoneNumber}
                      </a>,
                      <a
                        className='color'
                        href={`mailto:${brandConfig.contactEmail}`}
                      >
                        {brandConfig.contactEmail}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {error && (
        <ErrorPage>
          <h1>{strings.errorRequestBookingHeader}</h1>
          <p>{strings.errorRequestBookingP1}</p>
          <p>
            {strings.formatString(
              strings.errorRequestBookingP2,
              <a href={`mailto:${brandConfig.contactEmail}`}>
                {brandConfig.contactEmail}
              </a>,
              <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>
                {phoneNumber}
              </a>
            )}
          </p>
        </ErrorPage>
      )}
      {linkExpired && (
        <ErrorPage>
          <h1>{strings.errorLinkExpiredHeader}</h1>
          <p>{strings.errorLinkExpiredP1}</p>
          <p>
            {strings.formatString(
              strings.errorLinkExpiredP2,
              <a href={`mailto:${brandConfig.contactEmail}`}>
                {brandConfig.contactEmail}
              </a>,
              <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>
                {phoneNumber}
              </a>
            )}
          </p>
        </ErrorPage>
      )}
    </div>
  );
}
