import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DentiModal from '../common/DentiModal';
import AddPatient from './AddPatient';
import { getLeads } from '../../actions/leadActions';
import { ROUTE_LEADS } from '../../constants/routes';
import { useSelector } from 'react-redux';
const AddPatientModal = (props) => {
  const location = useLocation();
  const stepFromWelcomePage = useSelector(
    (state) => state.ui?.sendLinkApplicationFromWelcomePage
  );

  const closeAction = (updateData = false) => {
    props.toggleModal(false);
    if (updateData) {
      if (location.pathname === ROUTE_LEADS && !location.search) {
        //props.getLeads(VIEW_ALL, 0);
      }
    }
  };

  return (
    <DentiModal
      key='add-patient-modal'
      close={() => closeAction()}
      modalClass='add-patient-modal modal-dialog-centered modal-dialog-scrollable'
    >
      <AddPatient
        toggleModal={closeAction}
        activeStep={stepFromWelcomePage ? stepFromWelcomePage : null}
      />
    </DentiModal>
  );
};

export default connect(null, { getLeads })(AddPatientModal);
