import React, { useState } from 'react';
// eslint-disable-next-line
import { strings } from '../../constants/localization';
import {
  cprNoFormat,
  formatCurrencyDaStandard,
  isAdmin,
  isManager,
  isDentist,
} from '../../constants/utils';
import { moment } from '../../constants';
import { Button } from 'reactstrap';
import {
  showSmsAcceptanceFlowModal,
  showSmsReactivationCreditModal,
} from '../../actions/registerInvoiceActions';

import { ROUTE_LEADS } from '../../constants/routes';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { shallowEqual, useSelector } from 'react-redux';
import RegisterInvoiceIcon from '../../resources/icons/add_credit.svg';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

interface LeadClientInformation {
  cpr: number;
  firstName: string;
  lastName: string;
  phone: string;
  clientDunningStatus: string;
}

interface CreditFacilityInformation {
  status: string;
  expirationDate: string;
  amountLeftWithoutBonus: number;
  installmentLoanInformations: [];
  remainingTime: {
    totalDays: number;
  };
  creditMessage: string;
}

interface LeadsClientInformation {
  leadClientInformation: LeadClientInformation;
  creditFacilityInformation: CreditFacilityInformation;
}

interface LeadsInformationProps {
  patientInformation: LeadsClientInformation[];
}

interface CloseRegisterInvoiceModalProps {
  toggleCreateNewInvoice: () => void;
  setCurrentCase: (value: number) => void;
}

interface useSelectorProps {
  user: {
    user: object;
  };
}

const PatientInfoAndCreditStatus: React.FC<CloseRegisterInvoiceModalProps> = ({
  toggleCreateNewInvoice,
  setCurrentCase,
}) => {
  const leadsInformation = useSelector(
    (state: { registerInvoiceFlow?: LeadsInformationProps }) =>
      state.registerInvoiceFlow?.patientInformation || [],
    shallowEqual
  );

  const user = useSelector(
    (state: useSelectorProps) => state.user.user,
    shallowEqual
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const { leadClientInformation = {}, creditFacilityInformation = {} } =
    leadsInformation;
  const {
    firstName = '',
    lastName = '',
    cpr = '',
    phone = '',
    clientDunningStatus = '',
  } = leadClientInformation;

  const {
    status = '',
    expirationDate = '',
    amountLeftWithoutBonus = 0,
    installmentLoanInformations = [],
    remainingTime = { totalDays: 0 },
    creditMessage = '',
  } = creditFacilityInformation;
  const timeDiference = moment().diff(moment(expirationDate).add(1, 'days'));

  const isExpired = timeDiference >= 0 ? true : false;
  const isActive = status === 'ACTIVE';
  const isDisabled = status === 'DISABLED';
  const isSuspended = status === 'SUSPENDED';
  const isRejected = status === 'REJECTED';
  const latePayment = status === 'LATE_PAYMENT';
  const zeroCredit = !isExpired && amountLeftWithoutBonus <= 0;

  let formattedStatus = '';

  if (isActive && isExpired) {
    formattedStatus = strings.registerInvoiceFlowStatusExpired;
  }

  if (isActive && !isExpired) {
    formattedStatus = strings.registerInvoiceFlowStatusActive;
  }

  if (isAdmin(user)) {
    if (isRejected) {
      formattedStatus = strings.registerInvoiceFlowStatusRejected;
    }
    if (isDisabled) {
      formattedStatus = strings.registerInvoiceFlowStatusInactive;
    }
    if (isSuspended) {
      formattedStatus = strings.registerInvoiceFlowStatusSuspended;
    }
    if (latePayment) {
      formattedStatus = strings.registerInvoiceFlowStatusDebtCollection;
    }
    if (clientDunningStatus) {
      formattedStatus = strings.registerInvoiceFlowStatusClosed;
    }
  }
  if (isManager(user) || isDentist(user)) {
    if (
      isRejected ||
      isDisabled ||
      latePayment ||
      isSuspended ||
      clientDunningStatus
    ) {
      formattedStatus = strings.registerInvoiceFlowStatusClosed;
    }
  }

  const pendingInvoicesList = installmentLoanInformations.filter(
    (invoice) => invoice.status === 'WAITING_FOR_SIGNATURE'
  );

  const handleNavigation = (
    path: string,
    stateSetter: () => void,
    functionName: string,
    state: boolean = false
  ) => {
    stateSetter();
    if (functionName === 'showSmsAcceptanceFlowModal') {
      if (history.location.pathname === ROUTE_LEADS) {
        sessionStorage.setItem('showSmsAcceptanceFlowModal', 'true');
        sessionStorage.setItem(
          'pendingInvoiceListNumber',
          pendingInvoicesList[0]?.number
        );
        sessionStorage.setItem('clientphone', phone);
        window.location.href = path;
        return;
      } else {
        dispatch(showSmsAcceptanceFlowModal(state));
        history.push(path);
      }
    } else if (functionName === 'showSmsReactivationCreditModal') {
      if (history.location.pathname === ROUTE_LEADS) {
        sessionStorage.setItem('showSmsReactivationCreditModal', 'true');
        sessionStorage.setItem('clientphone', phone);
        sessionStorage.setItem(
          'companyReferenceNumber',
          installmentLoanInformations[0]?.companyReferenceNumber
        );
        window.location.href = path;
        return;
      } else {
        dispatch(showSmsReactivationCreditModal(state));
        history.push(path);
      }
    } else if (history.location.pathname === ROUTE_LEADS) {
      window.location.href = path;
      return;
    } else {
      history.push(path);
    }
  };

  return (
    <div className='step-content'>
      <div className='step-header'>
        <div className='logo'>
          <img src={RegisterInvoiceIcon} alt='Register Invoice' />
        </div>
        <div className='title-text'>
          <h2>{strings.registerInvoiceFlowPatientInfoTitle}</h2>
        </div>
      </div>
      <div className='step-body'>
        <div className='input-container'>
          <div className='container-wrapper'>
            <div className='patient-info-name'>
              <h3>
                {firstName} {lastName}
              </h3>
              <div className='patient-info-label'>
                {strings.registerInvoiceFlowCprLabel}{' '}
                <span className='patient-info-value'>{cprNoFormat(cpr)}</span>
              </div>
            </div>
            <div className='credit-info-container'>
              {isActive && !isExpired && !clientDunningStatus && (
                <div className='patient-info'>
                  <div className='patient-info-label'>
                    {strings.registerInvoiceFlowCreditAmount}
                  </div>
                  <div className='patient-info-value'>
                    {isAdmin(user)
                      ? formatCurrencyDaStandard(amountLeftWithoutBonus)
                      : amountLeftWithoutBonus < 0
                      ? formatCurrencyDaStandard(0)
                      : formatCurrencyDaStandard(amountLeftWithoutBonus)}
                  </div>
                </div>
              )}
              <div className='patient-info'>
                <div className='patient-info-label'>
                  {strings.registerInvoiceFlowCreditStatus}
                </div>
                <div
                  className={`patient-info-value ${
                    isActive && !isExpired && !clientDunningStatus
                      ? 'active'
                      : 'inactive'
                  }`}
                >
                  {formattedStatus}
                </div>
              </div>
              <div className='patient-info'>
                {isActive && isExpired && !clientDunningStatus && (
                  <div className='patient-info-label'>
                    {strings.registerInvoiceFlowCreditExpired}
                  </div>
                )}
                {isActive && !isExpired && !clientDunningStatus && (
                  <div className='patient-info-label'>
                    {strings.registerInvoiceFlowCreditExpired}
                  </div>
                )}
                <div className='patient-info-value expiration'>
                  {isActive && !isExpired && !clientDunningStatus && (
                    <>
                      <span>
                        {moment(expirationDate, 'YYYY-MM-DD').format(
                          'DD-MM-YYYY'
                        )}
                      </span>
                      <span>
                        {strings.formatString(
                          strings.registerInvoiceFlowExpirationDate,
                          remainingTime.totalDays
                        )}
                      </span>
                    </>
                  )}
                  {isActive &&
                    isExpired &&
                    !clientDunningStatus &&
                    strings.formatString(
                      strings.registerInvoiceFlowExpirationDateforNonActiveCredit,
                      moment(expirationDate, 'YYYY-MM-DD').format('DD-MMM-YYYY')
                    )}
                </div>
              </div>

              <div className='button-actions btn-show-profile'>
                <Button
                  color='blue'
                  className='btn-add-patient'
                  onClick={() => {
                    handleNavigation(
                      `${ROUTE_LEADS}?q=${cpr}`,
                      toggleCreateNewInvoice,
                      'ShowProfileActions',
                      false
                    );
                    toggleCreateNewInvoice();
                  }}
                >
                  {strings.registerSeeProfileButton}
                </Button>
              </div>
            </div>

            {/* note for pending loan and disallow the button to send sms in case the user is not admin and the pending invoice does not belong to the clinic*/}
            {isActive && !isExpired && creditMessage === 'pendingLoan' && (
              <div className='note-container'>
                <div className='note-label'>
                  {strings.registerInvoiceNoteTitleForPendings}
                </div>
                <div className='note-value'>
                  {strings.registerInvoiceNoteMessage}
                </div>
                {(isAdmin(user) ||
                  (!isAdmin(user) && pendingInvoicesList.length > 0)) && (
                  <Button
                    color='link'
                    className='no-break-line obs-link'
                    onClick={() =>
                      handleNavigation(
                        `${ROUTE_LEADS}?q=${cpr}`,
                        toggleCreateNewInvoice,
                        'showSmsAcceptanceFlowModal',
                        true
                      )
                    }
                  >
                    {strings.resendSMSLinkText}
                  </Button>
                )}
              </div>
            )}

            {/* note for closed credit */}
            {(isRejected ||
              isDisabled ||
              latePayment ||
              isSuspended ||
              clientDunningStatus) && (
              <div className='note-container'>
                <div className='note-label'>
                  {strings.registerInvoiceNoteTitleForStatusClosed}
                </div>
                <div className='note-value'>
                  {strings.registerInvoiceNoteMessageForStatusClosed}
                </div>
              </div>
            )}

            {/* note for zero credit */}
            {zeroCredit &&
              pendingInvoicesList.length === 0 &&
              !isDisabled &&
              !isRejected && (
                <div className='note-container'>
                  <div className='note-value'>{strings.obsZeroCredit}</div>
                </div>
              )}

            {/* note with a button to reactivate credit in case the credit is expired */}
            {isActive && isExpired && !clientDunningStatus && (
              <div className='note-container'>
                <div className='note-label'>
                  {strings.registerInvoiceNoteTitleForExpiredCredit}
                </div>
                <div className='note-value'>
                  {strings.registerInvoiceNoteMessageForExpiredCredit}
                </div>

                {/* Button will be disabled untill Backend finish a new endpoint for that  */}
                {/* <Button
                  color='link'
                  className='no-break-line obs-link'
                  onClick={() =>
                    handleNavigation(
                      `${ROUTE_LEADS}?q=${cpr}`,
                      toggleCreateNewInvoice,
                      'showSmsReactivationCreditModal',
                      true
                    )
                  }
                >
                  {strings.registerInvoiceSmsButtonToNewAssesmentLink}
                </Button> */}
              </div>
            )}
          </div>
        </div>

        {/* Button to register invoice condition */}
        {isActive &&
          !isExpired &&
          creditMessage !== 'pendingLoan' &&
          !clientDunningStatus &&
          amountLeftWithoutBonus > 0 && (
            <div className='button-actions'>
              <Button
                color='blue'
                className='btn-add-patient'
                onClick={() => {
                  setLoading(true);
                  setCurrentCase(3);
                }}
              >
                {!loading ? strings.registerInvoiceButton : <ButtonLoader />}
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default PatientInfoAndCreditStatus;
