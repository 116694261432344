import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import addPatientError from '../../resources/images/add-patient_error.png';

const StepClosedCredit = (props) => (
  <div className='step-content step-final'>
    <div className='step-header'>
      <img src={addPatientError} alt='add patient error' className='image-status' />
      <div className='title-text'>
        {strings.apCreditClosedTitle}
      </div>
    </div>
    <div className='step-body'>
      <div className='text-information'>
        <p>{strings.formatString(strings.apCreditClosedText1, `${props.stateInfo.firstName} ${props.stateInfo.lastName}`)}</p>
        <p>{strings.apCreditClosedText2}</p>
      </div>
      <div className='button-actions'>
        <Button color='blue' onClick={() => props.closeModal()}>
          {strings.buttonTextClose}
        </Button>
      </div>
    </div>
  </div>
);

export default StepClosedCredit;
