import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { strings } from '../../../../../constants/localization';
import './style.scss';
import DentiModal from '../../../../common/DentiModal';
import AddPatient from '../../../../add-patient/AddPatient';

function NoPatientCredit() {
  const [showSendAppLinkModal, setShowSendAppLinkModal] = useState(false);
  const currentPatient = useSelector(
    (state) => state.lead?.activeLead.leadClientInformation
  );
  const { email, phone } = currentPatient || {};

  return (
    <div className='NoPatientCredit_component'>
      <div className='icon_area'>
        <i className='lnil lnil-circle-minus gray' />
      </div>
      <div className='content_area'>
        <div className='top_area'>{strings.patientWithNocreditFacility}</div>
        <div className='bottom_area'>
          <p>{strings.noDentiCredit}</p>
          <p>{strings.helpPatient}</p>
        </div>
        <div className='button_area'>
          <Button
            color='blue'
            onClick={() => {
              setShowSendAppLinkModal(true);
            }}
          >
            <i className='lnir lnir-circle-plus' />
            {strings.sendAppLink}
          </Button>
        </div>
      </div>

      {showSendAppLinkModal && (
        <DentiModal
          key='add-patient-modal'
          close={() => setShowSendAppLinkModal(false)}
          modalClass='add-patient-modal modal-dialog-centered modal-dialog-scrollable'
        >
          <AddPatient
            activeStep={7}
            email={email}
            phone={phone}
            toggleModal={() => setShowSendAppLinkModal(false)}
          />
        </DentiModal>
      )}
    </div>
  );
}

export default NoPatientCredit;
