import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';
import { normalizePhone, formatDecimalsSpace, isDanishNumber } from '../../../constants/utils';

import './style.scss';

function Step2({
  firstName, lastName, phoneNumber, isDanishNumer, setFirstName, setLastName, setPhoneNumber, setIsDanishNumber
}) {

  const [phoneFormatted, setPhoneFormatted] = useState(formatDecimalsSpace(phoneNumber));
  
  const updatePhoneNumber = (value) => {
    const phoneUnformatted = normalizePhone(value);
    setPhoneFormatted(formatDecimalsSpace(value));
    // eslint-disable-next-line
    if (!isNaN(Number(phoneUnformatted))) {
      setPhoneNumber(phoneUnformatted);
      setIsDanishNumber(phoneUnformatted === '' ? true : isDanishNumber(phoneUnformatted));
    }
  }

  return (
    <div className='Step2 section'>
      <div className='title'>{strings.userRegistrationUserInformation}</div>
      <div className='note'>{strings.userRegistrationStep2Note}</div>
      <div className='content'>
        <div className='inputContainer'>
          <label>{strings.firstName}</label>
          <input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>

        <div className='inputContainer '>
          <label>{strings.lastName}</label>
          <input value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>

        <div className='inputContainer'>
          <label>
            {strings.telLabel}
            {!isDanishNumer && <span className='error'>{strings.danishNumber}</span>}
          </label>
          <input 
            value={phoneFormatted}
            maxLength={11} 
            onChange={(e) => updatePhoneNumber(e.target.value)} />
        </div>
      </div>
    </div>
  );
}

Step2.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
};

export default Step2;
