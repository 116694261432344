import {
  GET_MIGRATING_CLIENT,
  GET_USER_DATA_FOR_CARD_UPDATE,
  CREATE_SUNHEDPLUS_USER_AND_GET_SESSION_ID,
} from '../constants';

const defaultState = {
  clientDetails: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_MIGRATING_CLIENT:
      return {
        ...state,
        clientDetails: action.payload?.data,
      };
    case CREATE_SUNHEDPLUS_USER_AND_GET_SESSION_ID:
      return {
        ...state,
        reepayUserSession: action.payload?.data,
      };
    case GET_USER_DATA_FOR_CARD_UPDATE:
      return {
        ...state,
        userDataForCardUpdate: action.payload?.data,
      };
    default:
      return state;
  }
}
