import React from 'react';
import Input from '../common/AppInput/Input';
// eslint-disable-next-line
import Checkbox from '../common/SimpleCheckbox/SimpleCheckbox';
import { strings } from '../../constants/localization';
import {
  formatDecimalsSpace,
  formatZipWithoutLetters,
} from '../../constants/utils';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.props.validator.initialize(
      'location',
      'address',
      'zipCode',
      'city',
      'cvr'
    );

    this.state = {
      validator: this.props.validator,
      showParentCvr: false,
    };
  }

  buildValidationHandler(key, validadFunc) {
    return (value) => {
      let valid = validadFunc(value);
      this.state.validator.setValid(key, valid);
      return valid;
    };
  }

  render() {
    if (this.props.currentStep !== 1) {
      return null;
    }

    return (
      <div className='step'>
        <div className='icon'>
          <i className='lnil lnil-house-heart' />
        </div>
        <div className='title'>{strings.locationFlow_step1_title}</div>

        <div className='formWrapper'>
          {/* Location input  */}
          <div className='step_row first_row'>
            <Input
              type='text'
              name='location'
              label={strings.locationFlow_step1_name}
              required={true}
              value={this.props.location}
              onChange={this.props.handleChange}
              validation={this.buildValidationHandler(
                'location',
                Input.DefaultValidators.isNotEmpty
              )}
              cleanStep={this.props.cleanStep}
              stepIsValid={this.props.stepIsValid}
            />
          </div>

          {/* Address input  */}
          <div className='step_row'>
            <Input
              type='text'
              name='address'
              label={strings.locationFlow_step1_adress}
              required={true}
              value={this.props.address}
              onChange={this.props.handleChange}
              validation={this.buildValidationHandler(
                'address',
                Input.DefaultValidators.isNotEmpty
              )}
              cleanStep={this.props.cleanStep}
              stepIsValid={this.props.stepIsValid}
            />
          </div>

          <div className='step_row'>
            {/* Zip input  */}
            <div className='zip_area'>
              <Input
                type='text'
                name='zipCode'
                label={strings.locationFlow_step1_zipCode}
                required={true}
                value={this.props.zipCode}
                onChange={this.props.handleChange}
                trimmedLength='4'
                format={formatZipWithoutLetters}
                validation={this.buildValidationHandler('zipCode', (value) => {
                  return (
                    Input.DefaultValidators.isNotEmpty(value) &&
                    Input.DefaultValidators.isExactly4Digits(value)
                  );
                })}
                // errorMsg={strings.locationFlow_step3_regno_error}
                cleanStep={this.props.cleanStep}
                stepIsValid={this.props.stepIsValid}
              />
            </div>
            <div className='city_area'>
              {/* City input  */}
              <Input
                type='text'
                name='city'
                label={strings.locationFlow_step1_city}
                required={true}
                value={this.props.city}
                onChange={this.props.handleChange}
                validation={this.buildValidationHandler(
                  'city',
                  Input.DefaultValidators.isNotEmpty
                )}
                cleanStep={this.props.cleanStep}
                stepIsValid={this.props.stepIsValid}
              />
            </div>
          </div>

          {/* CVR input  */}
          <div className='step_row last_row'>
            <Input
              type='text'
              name='cvr'
              label={strings.locationFlow_step1_cvr}
              required={true}
              value={
                this.state.showParentCvr ? this.props.parentCvr : this.props.cvr
              }
              valid={this.state.showParentCvr ? true : undefined}
              format={formatDecimalsSpace}
              maxLength='11'
              disabled={this.state.showParentCvr}
              onChange={this.props.handleChange}
              validation={this.buildValidationHandler('cvr', (value) => {
                return (
                  Input.DefaultValidators.isNotEmpty(value) &&
                  Input.DefaultValidators.isExactly8Digits(value)
                );
              })}
              errorMsg={strings.cvrError}
              cleanStep={this.props.cleanStep}
              stepIsValid={this.props.stepIsValid}
            />
          </div>

          {/* show the parent cvr onlt if has value         */}
          {this.props.parentCvr && (
            <div className='step_row checkbox_zone'>
              <Checkbox
                content={strings.locationFlow_step1_cvrSwitch}
                checked={this.state.showParentCvr}
                handleClick={() => {
                  if (this.state.showParentCvr) {
                    let valid =
                      Input.DefaultValidators.isNotEmpty(this.props.cvr) &&
                      Input.DefaultValidators.isExactly8Digits(this.props.cvr);
                    this.state.validator.setValid('cvr', valid);
                  } else {
                    this.state.validator.setValid('cvr', true);
                  }
                  this.setState({
                    showParentCvr: !this.state.showParentCvr,
                  });
                  this.props.handleChange({
                    target: { name: 'cvr', value: this.props.parentCvr },
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Step1;
