import React from 'react';

const LoadingGeneratingFile = ({ message = '' }) => (
  <div className='align-items-center justify-content-center'>
    <div className='loadingGif' />
    {message ? <div className='loadingMessage'>{message}</div> : null}
  </div>

);

export default LoadingGeneratingFile;
