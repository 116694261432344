import React, { Component } from 'react';
import { Button, Form, Row, Col, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';
import { getFilteredCompaniesFile } from '../../../actions/companyActions';
import { companiesStatusMap } from '../../../constants/mappings';
import ActiveFiltersDisplay from './ActiveFiltersDisplay';
import Select from 'react-select';

class CompaniesFilter extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.scrollFilterRef = React.createRef();
    this.handleDataSubmit = this.handleDataSubmit.bind(this);
    this.handleDataReset = this.handleDataReset.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleCompaniesDownloadFile =
      this.handleCompaniesDownloadFile.bind(this);
    this.removeFilterItem = this.removeFilterItem.bind(this);
    this.filterRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      filterOpen: false,
      filterParams: {
        status: '',
        smartLinkStatus: '',
        brandDENTI: '',
        brandSUNDHED: '',
        publicCompanyCategory: '',
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.filterRef.current &&
      !this.filterRef.current.contains(event.target)
    ) {
      this.setState({ filterOpen: false });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      filterOpen: !prevState.filterOpen,
    }));
  }

  handleCompaniesDownloadFile() {
    this.props.getFilteredCompaniesFile(this.props.searchKeyword);
  }

  handleFilterChange(event, filterParam) {
    const currentFilterParams = Object.assign({}, this.state.filterParams);
    const inputEl = event.target;
    let isChecked = '';
    let value = '';
    if (filterParam !== 'publicCompanyCategory') {
      isChecked = inputEl.checked;
      value = inputEl.value;
    }
    switch (filterParam) {
      case 'status':
        currentFilterParams.status = isChecked ? value : '';
        break;
      case 'smartLinkStatus':
        currentFilterParams.smartLinkStatus = isChecked ? value : '';
        break;
      case 'brandDENTI':
        currentFilterParams.brandDENTI = isChecked ? value : '';
        break;
      case 'brandSUNDHED':
        currentFilterParams.brandSUNDHED = isChecked ? value : '';
        break;
      case 'publicCompanyCategory':
        currentFilterParams.publicCompanyCategory = event;
        break;
      default:
        break;
    }
    // update the state with checked filters
    this.setState({
      filterParams: currentFilterParams,
    });
  }

  removeFilterItem(filterItem) {
    const currentFilterParams = Object.assign({}, this.state.filterParams);
    if (filterItem) {
      currentFilterParams[filterItem] = '';
    }
    this.setState(
      {
        filterParams: currentFilterParams,
      },
      () => {
        this.sendData();
      }
    );
  }

  sendData() {
    const payload = {};
    const filters = this.state.filterParams;

    if (filters.status) {
      payload.status = filters.status;
    }
    if (filters.smartLinkStatus) {
      payload.smartLinkStatus = true;
    }
    if (filters.brandDENTI && !filters.brandSUNDHED) {
      payload.brand = 'Denti';
    }
    if (!filters.brandDENTI && filters.brandSUNDHED) {
      payload.brand = 'Sundhed+';
    }
    if (Object.keys(filters.publicCompanyCategory).length !== 0) {
      payload.publicCompanyCategory = filters.publicCompanyCategory.label;
    }
    if (Object.keys(payload).length > 0) {
      this.props.filterSubmit(payload, false);
    } else {
      this.props.filterSubmit(payload, true);
    }
  }

  handleDataReset() {
    this.setState({
      filterParams: {
        status: '',
        smartLinkStatus: '',
        brandDENTI: '',
        brandSUNDHED: '',
        publicCompanyCategory: '',
      },
    });
    this.props.filterSubmit({}, true);
    this.toggle();
  }

  handleDataSubmit(event) {
    event.preventDefault();
    this.sendData();
    this.toggle();
  }

  render() {
    const { searchStatus, search } = this.props;
    return (
      <div ref={this.filterRef} className='filter-container companies'>
        <Button color='light' className='btn-filter' onClick={this.toggle}>
          <i className='di di-controls' />
          <span>{strings.filterTitle}</span>
        </Button>

        {/* SHOW THE ACTIVE FILTERS in the filter bar */}
        <ActiveFiltersDisplay removeFilterItem={this.removeFilterItem} />

        {/* SHOW THE DOWNLOAD COMPANIES BUTTON IF SEARCH GIVES RESULTS */}
        {searchStatus === 'COMPANIES_SUCCESS' && search && search.flag && (
          <Button
            color='light'
            className='btn-download'
            onClick={this.handleCompaniesDownloadFile}
          >
            <i className='lnil lnil-download' />
            <span>{strings.companiesDownload}</span>
          </Button>
        )}
        <div
          className={`filter-content ${this.state.filterOpen ? 'opened' : ''}`}
        >
          {/* CLOSE BUTTON FOR RESPONSIVE  */}
          <button
            type='button'
            className='btn btn-white menu-toggler close-action'
            onClick={this.toggle}
          >
            <div className='close-toggler-icon'>
              <span />
              <span />
            </div>
          </button>

          {/* FILTER FORM */}
          <Form onReset={this.handleDataReset} onSubmit={this.handleDataSubmit}>
            <Row>
              <Col xs='12' className='form-group'>
                <div className='filter-title'>{strings.companyStatus}</div>
                <div className='company-status-filter custom-control custom-checkbox'>
                  <Input
                    className='custom-control-input topic-control'
                    id='smartLink-status-0'
                    type='checkbox'
                    name='companyStatuses'
                    value='SMARTLINK'
                    checked={this.state.filterParams.smartLinkStatus !== ''}
                    onChange={(evt) =>
                      this.handleFilterChange(evt, 'smartLinkStatus')
                    }
                  />
                  <Label
                    for='smartLink-status-0'
                    className='text-capitalize custom-control-label'
                  >
                    {strings.companySmartLinkOption}
                  </Label>
                </div>
                <div className='company-status-filter custom-control custom-checkbox'>
                  <Input
                    className='custom-control-input topic-control'
                    id='company-status-1'
                    type='checkbox'
                    name='companyStatuses'
                    value='ACTIVE'
                    checked={this.state.filterParams.status !== ''}
                    onChange={(evt) => this.handleFilterChange(evt, 'status')}
                  />
                  <Label
                    for='company-status-1'
                    className='text-capitalize custom-control-label'
                  >
                    {strings.companyStatusOption}
                  </Label>
                </div>
              </Col>
            </Row>

            {/* BRAND STATUS */}
            <Row>
              <Col xs='12' className='form-group'>
                <div className='filter-title'>{strings.companyBrandStatus}</div>
                <div
                  className='brand-status-filter custom-control custom-checkbox'
                  key={'DENTI'}
                >
                  <Input
                    className='custom-control-input topic-control'
                    id='brand-status-1'
                    type='checkbox'
                    name='brand-status-1'
                    value={'DENTI'}
                    checked={this.state.filterParams.brandDENTI !== ''}
                    onChange={(evt) =>
                      this.handleFilterChange(evt, 'brandDENTI')
                    }
                  />
                  <Label
                    for='brand-status-1'
                    className='text-capitalize custom-control-label'
                  >
                    {strings[companiesStatusMap['DENTI'].statusText]}
                  </Label>
                </div>
                <div
                  className='brand-status-filter custom-control custom-checkbox'
                  key={'SUNDHED'}
                >
                  <Input
                    className='custom-control-input topic-control'
                    id='brand-status-2'
                    type='checkbox'
                    name='brand-status-2'
                    value={'SUNDHED'}
                    checked={this.state.filterParams.brandSUNDHED !== ''}
                    onChange={(evt) =>
                      this.handleFilterChange(evt, 'brandSUNDHED')
                    }
                  />
                  <Label
                    for='brand-status-2'
                    className='text-capitalize custom-control-label'
                  >
                    {strings[companiesStatusMap['SUNDHED'].statusText]}
                  </Label>
                </div>
              </Col>
            </Row>

            {/* COMPANY CATEGORY SELECT */}
            <Row>
              <Col xs='12' className='CustomSelectComponent filter form-group'>
                <div className='filter-title'>
                  {strings.companyCategoryStatus}
                </div>
                <Select
                  className='custom_select'
                  classNamePrefix='custom_select'
                  options={this.props.publicCompanyCategories}
                  placeholder={strings.companyCategoryPlaceholder}
                  value={this.state.filterParams.publicCompanyCategory}
                  onChange={(evt) =>
                    this.handleFilterChange(evt, 'publicCompanyCategory')
                  }
                  menuPlacement='auto'
                  maxMenuHeight={250}
                />
              </Col>
            </Row>

            {/* ACTION BUTTONS */}
            <Row>
              <Col xs='6' md='6' className='mt-3'>
                <Button type='reset' color='light' block>
                  <i className='di di-close' />
                  &ensp;
                  {strings.resetFilter}
                </Button>
              </Col>
              <Col xs='6' md='6' className='mt-3'>
                <Button type='submit' color='light' className='btn-blue' block>
                  <i className='di di-check' />
                  &ensp;
                  {strings.submitFilter}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

CompaniesFilter.propTypes = {
  getFilteredCompaniesFile: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    companiesFilteredBySearch: state.company.companiesFilteredBySearch,
    publicCompanyCategories: state.company.publicCompanyCategories,
    user: state.user.user,
  };
}
export default connect(mapStateToProps, {
  getFilteredCompaniesFile,
})(CompaniesFilter);
