/* eslint-disable no-const-assign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { success, error as notifError } from 'react-notification-system-redux';
import {
  moment,
  momentFormat,
  notificationDismissDuration,
} from '../../../constants';
import CompanyData from './CompanyData';
import { strings } from '../../../constants/localization';
import KebabMenu from '../../common/KebabMenu/KebabMenu';
import DeleteCompanyFail from './DeleteCompanyFail/DeleteCompanyFail';
import DeleteWarningModal from '../../common/DeleteWarningModal';
import DentiModal from '../../common/DentiModal';
import ConfirmActionModal from '../../common/ConfirmationModal';
import { toggleServerModalError } from '../../../actions/uiElementsActions';
// eslint-disable-next-line
import SimpleCheckbox from '../../common/SimpleCheckbox/SimpleCheckbox';
import './EditCompanyForm.scss';
import {
  getCompanyChains,
  deleteCompanyChainAndAssociatedCompanies,
  updateCompanyChainStatusFromCompany,
  setCurrentChain,
} from '../../../actions/companyChainActions';

import {
  companyUpdateInformation,
  deleteCompany,
  getCompanies,
  getCompaniesForCompanyChain,
  addToBlacklist,
  setCurrentCompany,
  updateCompanyChainOfCompany,
} from '../../../actions/companyActions';
import { getLogsforCompanies } from '../../../actions/logActions';
import {
  phoneNoFormat,
  addNotification,
  digits,
  fixedLength4,
  letters,
  decomposeFullAddress,
} from '../../../constants/utils';
import SeachCompanyChain from '../../common/SearchCompanyChain/SeachCompanyChain';

class CompanyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editMode: false,
      companyName: props.companyDetails.companyName,
      address: props.companyDetails.address,
      zipCode: props.companyDetails.zipCode,
      city: props.companyDetails.city,
      note: props.companyDetails.notes || '',
      registrationNumber: props.companyDetails.registrationNumber || '',
      cardNumber: props.companyDetails.cardNumber || '',
      cvr: phoneNoFormat(props.companyDetails.cvr),
      companyChain: props.companyDetails.companyChain,
      newAssociatedCompanyChain: {},
      selectedNewCompanyChain: false,
      blacklistCVR: false,
      editCvrBlacklist: false,
      deleteEmptyCompanyChain: false,
      previousCompanyChain: {},
      editCvr: false,
      cvrError: '',
      nameAndAddressError: '',
      kotnirError: '',
      deleteError: '',
    };

    this.getCell = this.getCell.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.save = this.save.bind(this);
    this.fieldHasError = this.fieldHasError.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.isValueValid = this.isValueValid.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.closeEdit = this.closeEdit.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.blackListOldCvr = this.blackListOldCvr.bind(this);
    this.handleSelectedCompanyChain =
      this.handleSelectedCompanyChain.bind(this);
    this.isBlackListChecked = this.isBlackListChecked.bind(this);
  }

  toggleEdit() {
    const editMode = this.state.editMode;
    this.setState({
      editMode: !editMode,
      companyName: this.props.companyDetails.companyName,
      address: this.props.companyDetails.address,
      zipCode: this.props.companyDetails.zipCode,
      city: this.props.companyDetails.city,
      registrationNumber: this.props.companyDetails.registrationNumber || '',
      cardNumber: this.props.companyDetails.cardNumber || '',
      cvr: phoneNoFormat(this.props.companyDetails.cvr),
      companyChain: this.props.companyDetails.companyChain.chainCompanyName,
      blacklistCVR: false,
      selectedNewCompanyChain: false,
      cvrError: '',
      nameAndAddressError: '',
      kotnirError: '',
      deleteError: '',
    });
  }

  closeEdit() {
    this.setState({
      editMode: false,
    });
  }

  clickDelete() {
    this.setState({
      deleteConfirmation: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.companyDetails) !==
      JSON.stringify(this.props.companyDetails)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
        editMode: false,
        companyName: this.props.companyDetails.companyName,
        address: this.props.companyDetails.address,
        zipCode: this.props.companyDetails.zipCode,
        city: this.props.companyDetails.city,
        registrationNumber: this.props.companyDetails.registrationNumber || '',
        cardNumber: this.props.companyDetails.cardNumber || '',
        cvr: phoneNoFormat(this.props.companyDetails.cvr),
        companyChain: this.props.companyDetails.companyChain,
        blacklistCVR: false,
        deleteConfirmation: false,
        editCvrBlacklist: false,
        deleteEmptyCompanyChain: false,
        previousCompanyChain: {},
        editCvr: false,
      });
    }
  }

  onDelete() {
    this.setState({
      loading: true,
    });
    this.props
      .deleteCompany(this.props.companyDetails.referenceNumber)
      .then((response) => {
        if (response.payload.status === 200) {
          // if the blacklist is checked, the blacklist company after delete
          if (this.state.blacklistCVR === true) {
            this.props
              .addToBlacklist(this.props.companyDetails.cvr)
              .catch((error) => {
                console.log(error);
                this.props.toggleServerModalError(false);
                this.setState({
                  deleteConfirmation: false,
                  deleteError: error.response.data.cause,
                  loading: false,
                });
              });
          }

          this.setState({
            deleteConfirmation: false,
            loading: false,
          });

          this.props.getCompanies(
            null,
            this.props.activePage - 1,
            this.props.searchActive.searchInput,
            undefined,
            this.props.filters
          );

          this.props.setCurrentCompany(null);
        }
      })
      .catch((error) => {
        console.log(error, 'fromSecondCatch');
        this.props.toggleServerModalError(false);
        this.setState({
          deleteConfirmation: false,
          deleteError: error.response?.data.cause,
          loading: false,
        });
      });
  }

  // eslint-disable-next-line consistent-return
  saveForm() {
    const formData = {
      companyName: this.state.companyName,
      address: this.state.address,
      zipCode: this.state.zipCode,
      city: this.state.city,
      cvr: this.state.cvr
        ? parseInt(this.state.cvr.replaceAll(' ', ''), 10)
        : null,
      registrationNumber: this.state.registrationNumber || '',
      cardNumber: this.state.cardNumber || '',
      companyChain: this.props.companyDetails.companyChain,
      newAssociatedCompanyChain: this.state.newAssociatedCompanyChain,
    };

    // return the promise, redux-form will switch the submitting flag on complete
    if (this.isFormValid()) {
      this.props
        .companyUpdateInformation(
          this.props.companyDetails.referenceNumber,
          formData
        )
        .then((response) => {
          if (response?.status === 200) {
            if (this.state.selectedNewCompanyChain === true) {
              //get the previous company chain
              let previousCompanyChain;
              this.props
                .getCompaniesForCompanyChain(
                  this.props.companyDetails.companyChain.id
                )
                .then((resp) => {
                  if (resp.payload?.status === 200) {
                    previousCompanyChain = resp.payload?.data.companyChains[0];
                  }
                })
                .then(() => {
                  this.props
                    .updateCompanyChainOfCompany(
                      this.props.companyDetails.referenceNumber,
                      this.state.newAssociatedCompanyChain.id,
                      this.state.newAssociatedCompanyChain
                    )
                    .then((response) => {
                      // check if the companyChain was updated succesfully and then display confirm modal if the previous company chain should be deleted
                      if (
                        response.status === 200 &&
                        previousCompanyChain?.companies?.length <= 1
                      ) {
                        this.setState({
                          deleteEmptyCompanyChain: true,
                          previousCompanyChain: previousCompanyChain,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
            }
            this.setState({
              editMode: false,
              cvrError: '',
              nameAndAddressError: '',
              kotnirError: '',
              deleteError: '',
            });
            this.props.success(
              addNotification(
                strings.formatString(
                  strings.editCompanySuccess,
                  `${this.state.companyName}`
                ),
                strings.editCompanySuccessTitle,
                notificationDismissDuration
              )
            );
            this.props.getLogsforCompanies(
              this.props.companyDetails.referenceNumber
            );
          }
        })
        .catch(() => {
          this.props.notifError(
            addNotification(
              strings.errorEditCompany,
              strings.errorEditCompanyTitle,
              notificationDismissDuration
            )
          );
        });
    }
  }

  fieldHasError(fieldName) {
    return !!this.state[`${fieldName}Error`];
  }

  save(ev) {
    ev.preventDefault();
    this.validateForm();
  }

  blackListOldCvr() {
    if (this.state.blacklistCVR === true) {
      this.props
        .addToBlacklist(this.props.companyDetails.cvr)
        .then((response) => {
          if (response?.payload.status === 200) {
            this.saveForm();
            this.setState({
              editCvrBlacklist: false,
              editCvr: false,
              loading: false,
            });
          } else {
            this.setState({
              editCvrBlacklist: false,
              editCvr: false,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.props.toggleServerModalError(false);
          this.setState({
            editCvrBlacklist: false,
            editCvr: false,
            loading: false,
          });
        });
    } else {
      this.saveForm();
    }
  }

  validateForm() {
    let cvrError = '';
    let kotnirError = '';
    let nameAndAddressError = '';

    nameAndAddressError =
      this.state.companyName &&
      this.state.address &&
      this.state.zipCode &&
      this.state.city
        ? ''
        : strings.required;
    cvrError = digits(this.state.cvr);

    if (this.state.cvr?.replaceAll(' ', '').length !== 8) {
      cvrError = strings.cvrError;
      this.setState({
        editCvrBlacklist: false,
      });
    } else {
      cvrError = '';
    }

    const oldCvr = phoneNoFormat(this.props.companyDetails.cvr);
    if (cvrError === '' && this.state.editCvr) {
      if (this.state.cvr === oldCvr || oldCvr === null || oldCvr === '') {
        this.setState({
          editCvrBlacklist: false,
        });
      } else {
        this.setState({
          editCvrBlacklist: true,
        });
      }
    }

    if (
      (this.state.registrationNumber !== '' &&
        this.state.registrationNumber.length < 4) ||
      (this.state.registrationNumber.length === 4 &&
        this.state.cardNumber === '') ||
      (this.state.cardNumber.length !== 0 &&
        (this.state.cardNumber.length < 7 || this.state.cardNumber.length > 10))
    ) {
      kotnirError = strings.kotnirError;
    } else {
      kotnirError = '';
    }

    this.setState(
      {
        nameAndAddressError,
        cvrError,
        kotnirError,
      },
      this.isBlackListChecked
    );
  }

  isBlackListChecked() {
    if (this.state.editCvrBlacklist === false) {
      this.saveForm();
    }
  }
  // eslint-disable-next-line class-methods-use-this
  isValueValid(value, fieldName, edit) {
    if (fieldName === 'nameAndAddress') {
      if (letters(value)) return false;
    }

    if (fieldName === 'zipCode') {
      if (
        (!edit && (digits(value) || fixedLength4(value.zipCode))) ||
        (edit && (value.length === 5 || digits(value)))
      ) {
        return false;
      }
    }

    if (fieldName === 'city') {
      if (letters(value)) return false;
    }
    if (fieldName === 'cvr') {
      this.setState({
        editCvr: true,
      });
      if (value?.replaceAll(' ', '').length > 8) return false;
    }

    if (fieldName === 'address') {
      if (value.length > 100) return false;
    }
    if (fieldName === 'registrationNumber') {
      if (value.length > 4 && !isNaN(value)) {
        return false;
      }
    }
    if (fieldName === 'cardNumber') {
      // eslint-disable-next-line no-restricted-globals
      if (value.length > 10 && !isNaN(value)) return false;
    }

    if (fieldName === 'companyChainList') {
      if (value.length > 100) return false;
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  getDigitsFromInput(text) {
    const number = text.match(/\d+((.|,)\d+)?/);
    if (number) {
      return number[0];
    }
    return '';
  }

  isFormValid() {
    return (
      !this.state.nameAndAddressError &&
      !this.state.cvrError &&
      !this.state.kotnirError
    );
  }
  handleSelectedCompanyChain = (selectedCompanyChain) => {
    if (selectedCompanyChain) {
      this.changeValue(selectedCompanyChain, 'companyChainList');
      this.setState({
        companyChain: selectedCompanyChain,
        newAssociatedCompanyChain: selectedCompanyChain,
        selectedNewCompanyChain: true,
      });
    }
  };

  changeValue(value, fieldName) {
    if (this.isValueValid(value, fieldName, true)) {
      if (fieldName === 'cvr') {
        this.setState({ [fieldName]: phoneNoFormat(value) });
      } else if (fieldName === 'registrationNumber') {
        this.setState({ [fieldName]: this.getDigitsFromInput(value) });
        // set focus to input field cardNumber
      } else if (fieldName === 'cardNumber') {
        this.setState({ [fieldName]: this.getDigitsFromInput(value) });
      } else if (fieldName === 'address') {
        const result = decomposeFullAddress(value);
        if (result) {
          const { address, zipCode, city } = result;
          this.setState({ address: address, zipCode: zipCode, city: city });
        } else {
          this.setState({ address: value });
        }
      } else {
        this.setState({ [fieldName]: value });
      }
    }
  }

  onPasteAccountNumber(e, fieldName) {
    e.preventDefault();
    let pastedValue = e.clipboardData.getData('text');
    pastedValue = pastedValue.replace(/[^0-9]/g, ''); // remove non-numeric characters

    if (fieldName === 'registrationNumber') {
      const registrationNumber = pastedValue.substring(0, 4);
      const cardNumber = pastedValue.substring(4, 14); // Limit the cardNumber to 10 digits
      this.setState({ registrationNumber, cardNumber });
      this.cardNumberInput.focus(); // Move focus to cardNumber input
    } else if (fieldName === 'cardNumber') {
      const cardNumber = pastedValue.substring(0, 10); // Limit the cardNumber to 10 digits
      this.setState({ cardNumber });
    }
  }

  onPasteCvr(e) {
    e.preventDefault();
    let pastedValue = e.clipboardData.getData('text');
    pastedValue = pastedValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
    // max 8 digits
    pastedValue = pastedValue.substring(0, 8);
    const cvr = phoneNoFormat(pastedValue);
    this.setState({ cvr });
  }

  getCell(logoClassName, title, value, fieldName, type) {
    const inputElement = (
      <input
        className='form-control custom-form-control'
        type='text'
        value={this.state[fieldName] || ''}
        onChange={(e) => this.changeValue(e.target.value, fieldName)}
        onPaste={(e) => {
          if (fieldName === 'cvr') {
            this.onPasteCvr(e);
          }
        }}
      />
    );

    const inputnameAndAddress = type === 'nameAndAddress' && (
      <>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name3'
            type='text'
            value={this.state.companyName || ''}
            onChange={(e) => this.changeValue(e.target.value, 'companyName')}
          />
        </div>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name3'
            type='text'
            placeholder={strings.placeholderAddress}
            value={this.state.address || ''}
            onChange={(e) => this.changeValue(e.target.value, 'address')}
          />
        </div>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name5'
            type='text'
            placeholder={strings.placeholderZipCode}
            value={this.state.zipCode || ''}
            onChange={(e) => this.changeValue(e.target.value, 'zipCode')}
          />
          <input
            className='form-control custom-form-control name6'
            type='text'
            placeholder={strings.placeholderCity}
            value={this.state.city || ''}
            onChange={(e) => this.changeValue(e.target.value, 'city')}
          />
        </div>
      </>
    );

    const inputKotnir = (
      <>
        <div className='fullWidth'>
          <input
            className='form-control custom-form-control name5'
            type='text'
            value={this.state.registrationNumber}
            onChange={(e) => {
              this.changeValue(e.target.value, 'registrationNumber');
              if (e.target.value.length === 4) {
                this.cardNumberInput.focus();
              }
            }}
            onPaste={(e) => this.onPasteAccountNumber(e, 'registrationNumber')}
          />

          <input
            className='form-control custom-form-control name6'
            type='text'
            value={this.state.cardNumber}
            onChange={(e) => this.changeValue(e.target.value, 'cardNumber')}
            ref={(input) => {
              this.cardNumberInput = input;
            }}
            onPaste={(e) => this.onPasteAccountNumber(e, 'cardNumber')}
          />
        </div>
      </>
    );

    const companyChainList = (
      <div className='fullWidth'>
        <SeachCompanyChain
          handleSelectedCompanyChain={this.handleSelectedCompanyChain}
          fieldClass={'form-control custom-form-control fullWidth'}
        />
      </div>
    );

    return (
      <div className='col-12'>
        <div className='media'>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className={`lnir ${logoClassName}`} />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{title}</div>

              {this.fieldHasError(type || fieldName) && (
                <div className='error'>
                  {this.state[`${type || fieldName}Error`]}
                </div>
              )}
            </div>
            <div
              className={`media-body-content ${
                type === 'nameAndAddress' ? 'nameAndAddress' : ''
              }`}
            >
              {!type && (
                <>{this.state.editMode ? inputElement : value || '-'}</>
              )}
              {type === 'nameAndAddress' && (
                <>
                  {this.state.editMode ? (
                    inputnameAndAddress
                  ) : (
                    <>
                      <div>{this.state.companyName}</div>
                      <div>{`${this.state.address || '-'}`}</div>
                      <div>
                        {this.state.zipCode || '-'} {this.state.city || '-'}
                      </div>
                    </>
                  )}
                </>
              )}
              {type === 'cvr' && (
                <>{this.state.editMode ? inputElement : value || '-'}</>
              )}
              {type === 'kotnir' && (
                <>
                  {this.state.editMode ? (
                    inputKotnir
                  ) : (
                    <>
                      {this.state.registrationNumber && this.state.cardNumber
                        ? `${this.state.registrationNumber} ${this.state.cardNumber} `
                        : '-'}
                    </>
                  )}
                </>
              )}
              {type === 'companyChainList' && (
                <>
                  {this.state.editMode
                    ? companyChainList
                    : value || this.state.companyChain}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderBlacklistCVRModal() {
    if (this.state.editCvrBlacklist) {
      return (
        <DentiModal
          key={3}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmActionModal
            confirmAction={() => {
              this.blackListOldCvr();
              this.setState({
                loading: true,
              });
            }}
            disabled={this.state.loading}
            loading={this.state.loading}
            cancelAction={() => {
              this.setState({
                editCvrBlacklist: false,
                editCvr: true,
              });
            }}
          >
            <div>
              <p className='title'>{strings.chainEditCVRHeadlineBlackList}</p>
              <p className='confirm-message'>
                {strings.chainEditCVRBodyBlackList}
              </p>
              <p>{strings.chainEditCVRBodyBlackListSecond}</p>

              <SimpleCheckbox
                customStyles='d-flex mb-20'
                content={strings.formatString(
                  strings.blacklistCVR,
                  phoneNoFormat(this.props.companyDetails.cvr)
                )}
                checked={this.state.blacklistCVR}
                handleClick={() => {
                  this.setState({
                    blacklistCVR: !this.state.blacklistCVR,
                  });
                }}
              />
            </div>
          </ConfirmActionModal>
        </DentiModal>
      );
    }
    return null;
  }

  renderConfirmDeletingEmptyCompanyChainModal() {
    const deleteCompanyChainAction = () => {
      this.props
        .deleteCompanyChainAndAssociatedCompanies({
          cvrNumber: this.state.previousCompanyChain?.cvrNumber,
          chainCompanyId: this.state.previousCompanyChain?.id,
        })
        .then(() => {
          this.setState({
            loading: false,
            deleteEmptyCompanyChain: false,
          });
          if (
            this.props?.activeChain?.id === this.state.previousCompanyChain?.id
          ) {
            this.props.setCurrentChain(null);
          }
        });
    };
    if (this.state.deleteEmptyCompanyChain) {
      return (
        <DentiModal
          key={3}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmActionModal
            confirmBtnText={strings.noCompanyForChainBtn1}
            confirmAction={() => {
              this.setState({
                loading: true,
              });
              // if previous empty company chain is active, make it inactive before delete
              if (this.state.previousCompanyChain?.status === 'ACTIVE') {
                this.props
                  .updateCompanyChainStatusFromCompany(
                    this.state.previousCompanyChain?.id,
                    'CLOSED'
                  )
                  .then((resp) => {
                    if (resp?.status === 200) {
                      deleteCompanyChainAction();
                    }
                  });
              } else {
                deleteCompanyChainAction();
              }
            }}
            disabled={this.state.loading}
            loading={this.state.loading}
            cancelBtnText={strings.noCompanyForChainBtn2}
            cancelAction={() => {
              this.setState({
                deleteEmptyCompanyChain: false,
              });
            }}
          >
            <div>
              <p className='title'>
                {strings.formatString(
                  strings.noCompanyForChainHeadline,
                  this.state.previousCompanyChain?.companyChainName
                )}
              </p>
              <p className='confirm-message'>
                {strings.formatString(
                  strings.noCompanyForChainText,
                  this.state.previousCompanyChain?.companyChainName
                )}
              </p>
              <p></p>
            </div>
          </ConfirmActionModal>
        </DentiModal>
      );
    }
    return null;
  }

  renderConfirmDeletingCompanyModal(company, trimedCVR) {
    if (
      this.state.deleteConfirmation &&
      this.state.deleteError === '' &&
      this.props.companyDetails.status !== 'ACTIVE'
    ) {
      return (
        <DentiModal
          key={1}
          close={() => this.setState({ deleteConfirmation: false })}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
          closeBtn={false}
        >
          <ConfirmActionModal
            confirmAction={() => {
              this.onDelete();
            }}
            loading={this.state.loading}
            cancelAction={() => {
              this.setState({
                deleteConfirmation: false,
              });
            }}
          >
            <div>
              <p className='title'>{strings.deleteInvoiceTitle}</p>
              <p className='confirm-message'>
                {strings.formatString(
                  strings.deleteCompanyConfirmMessage,
                  company.companyName
                )}
              </p>
              {trimedCVR && trimedCVR !== '-' && (
                <SimpleCheckbox
                  customStyles='d-flex mb-20'
                  content={strings.formatString(
                    strings.blacklistCVR,
                    phoneNoFormat(this.props.companyDetails.cvr)
                  )}
                  checked={this.state.blacklistCVR}
                  handleClick={() => {
                    this.setState({
                      blacklistCVR: !this.state.blacklistCVR,
                    });
                  }}
                />
              )}
            </div>
          </ConfirmActionModal>
        </DentiModal>
      );
    }
    return null;
  }

  renderWarningDeletingCompanyModal() {
    if (
      this.state.deleteConfirmation &&
      this.props.companyDetails.status === 'ACTIVE'
    ) {
      return (
        <DentiModal
          key={1}
          close={() => this.setState({ deleteConfirmation: false })}
          modalClass='warning-modal'
          modalClassName='confirm-action'
          closeBtn={false}
        >
          <DeleteWarningModal
            headlineText={strings.deleteCompanyWarningHeadline}
            bodyText={strings.formatString(
              strings.deleteCompanyWarningBody,
              <br />
            )}
            close={() => this.setState({ deleteConfirmation: false })}
          />
        </DentiModal>
      );
    }
    return null;
  }

  renderFailedDeletingCompanyModal(company) {
    if (this.state.deleteError !== '') {
      return (
        <DentiModal
          key={2}
          close={() => this.setState({ deleteError: '' })}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <DeleteCompanyFail
            companyName={company.companyName}
            error={this.state.deleteError}
            close={() => {
              this.setState({ deleteError: '', deleteConfirmation: false });
            }}
          />
        </DentiModal>
      );
    }
    return null;
  }

  render() {
    const company = this.props.companyDetails;
    const brand = this.props.companyDetails.brand;
    const localTime = moment.utc(company.createdDate).local();
    let latestLoginUserDate = '';
    const companyCreateDate = localTime.format(momentFormat);

    if (company.latestLogin && company.latestLogin !== undefined) {
      const time = moment.utc(company.latestLogin).local();
      latestLoginUserDate = time.format(momentFormat);
    } else {
      latestLoginUserDate = null;
    }

    const { cvr, registrationNumber, companyChain } = this.state;
    const trimedCVR = cvr ? cvr.replaceAll(' ', '') : '';

    return (
      <div
        id='CompanyInformationComponent'
        className={`entity-information-card custom-card card ${
          this.state.editMode ? 'edit-mode' : ''
        }`}
      >
        <div className='card-body'>
          <div className='header-information'>
            <div className='header-text'>
              <div className='card-title'>{`${company.companyName} `}</div>
              <span className='card-loked-data'>
                <span className='iconzone'>
                  {brand === 'Denti' && <i className='di di-denti' />}
                  {brand === 'Sundhed+' && <i className='di di-sundhedplus' />}
                  {company.referenceNumber}
                </span>
              </span>
            </div>
            {!this.state.editMode && (
              <div className='header-actions'>
                <KebabMenu>
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={this.toggleEdit}
                  >
                    <i className='lnir lnir-pencil icon' />
                    {strings.edit}
                  </div>
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={() => {
                      this.props.markCompanyAsSelectedFunc();
                    }}
                  >
                    <i
                      style={{ fontWeight: 'bold' }}
                      className='lnil lnil-users icon'
                    />
                    {strings.seeLeadsFor}
                  </div>
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={this.clickDelete}
                  >
                    <i className='lnir lnir-trash-can icon' />
                    {strings.delete}
                  </div>
                </KebabMenu>
              </div>
            )}
            {this.state.editMode && (
              <div
                xs='12'
                md={{ size: 6, offset: 6 }}
                className='action-buttons text-right'
              >
                <div className='action-btn cf'>
                  <Button
                    className='cancel-btn'
                    color='light'
                    onClick={this.toggleEdit}
                    disabled={this.submitting}
                  >
                    {strings.undoActionButton}
                  </Button>
                  <Button
                    color='blue'
                    className='btn-blue create-button'
                    onClick={(ev) => {
                      this.save(ev);
                    }}
                  >
                    <i className='di di-save' />
                    {strings.saveActionButton}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className='header header-text company header-information p-1 bd-highlight '>
            <div className='time-created'>
              <span className='text create-date'>{`${strings.createDate} ${companyCreateDate}`}</span>
              {latestLoginUserDate ? (
                <span className='text last-login-date'>{`${strings.lastLoginDate} ${latestLoginUserDate}`}</span>
              ) : (
                ''
              )}
            </div>
          </div>

          {this.state.editMode ? (
            <div className='mt-4 lead-personal-data row'>
              <div className='col-12 col-md-5'>
                <div className='row'>
                  {this.getCell(
                    'lnir-house',
                    strings.nameAndPhone,
                    '',
                    'nameAndAddress',
                    'nameAndAddress'
                  )}
                  {this.getCell('lnir-shield', strings.cvrLabel, cvr, 'cvr')}
                </div>
              </div>
              <div className='col-12 col-md-7'>
                {this.getCell(
                  'lnir lnir-apartment',
                  strings.companyAffiliation,
                  companyChain,
                  'companyChainList',
                  'companyChainList'
                )}

                {this.getCell(
                  'lnir-bank',
                  strings.registrationNumber,
                  registrationNumber,
                  'registrationNumber',
                  'kotnir'
                )}
              </div>
            </div>
          ) : (
            <CompanyData companyDetails={this.props.companyDetails} />
          )}

          {/* Confirm modal when updating a cvr to blacklist the old one    */}
          {this.renderBlacklistCVRModal()}

          {/* Confirm modal when deleting the previous company chain that is now empty after update         */}
          {this.renderConfirmDeletingEmptyCompanyChainModal()}

          {/* Confirm modal when deleting a company         */}
          {this.renderConfirmDeletingCompanyModal(company, trimedCVR)}

          {/* Warning when trying to delete an active company         */}
          {this.renderWarningDeletingCompanyModal()}

          {/* Delete company failed     */}
          {this.renderFailedDeletingCompanyModal(company)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    statusActive: state.status.companyStatusActive,
    activePage: state.company.activePage,
    searchActive: state.company.searchActive,
    filters: state.company.filters,
    activeCompany: state.company.activeCompany,
    activeChain: state.companyChain?.activeChain,
    currentCompanyRefFromRouter: state.company.currentRefFromRouter,
    chainList:
      state.companyChain && state.companyChain.companyChainList
        ? state.companyChain.companyChainList.content
        : [],
  };
}

CompanyInformation.propTypes = {
  companyDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  companyUpdateInformation: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  toggleEdit: PropTypes.func,
  getCompanies: PropTypes.func,
  getCompanyChains: PropTypes.func,
  updateCompanyChainOfCompany: PropTypes.func,
};

export default connect(mapStateToProps, {
  companyUpdateInformation,
  deleteCompany,
  addToBlacklist,
  getCompanies,
  getLogsforCompanies,
  setCurrentCompany,
  setCurrentChain,
  toggleServerModalError,
  success,
  notifError,
  getCompanyChains,
  updateCompanyChainOfCompany,
  getCompaniesForCompanyChain,
  updateCompanyChainStatusFromCompany,
  deleteCompanyChainAndAssociatedCompanies,
})(CompanyInformation);
