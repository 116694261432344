import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import addPatientWarning from '../../resources/images/add-patient_warning.png';

const StepExpiredCredit = (props) => (
  <div className='step-content step-final'>
    <div className='step-header'>
      <img src={addPatientWarning} alt='add patient warning' className='image-status' />
      <div className='title-text'>
        {strings.apCreditExpiredTitle}
      </div>
    </div>
    <div className='step-body'>
      <div className='text-information'>
        <p>{strings.formatString(strings.apCreditExpiredText1, `${props.stateInfo.firstName} ${props.stateInfo.lastName}`)}</p>
        <p>{strings.formatString(strings.apCreditExpiredText2, props.stateInfo.firstName, props.stateInfo.firstName)}</p>
      </div>
      <div className='button-actions'>
        <Button color='blue' onClick={() => props.closeModal()}>
          {strings.buttonTextClose}
        </Button>
      </div>
    </div>
  </div>
);

export default StepExpiredCredit;
