import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import { currencyFormatDA } from '../../constants/utils';
import DentiClinicBadgeDa from '../../resources/images/denti-approved-clinic-badge-de.svg';
import DentiClinicBadgeEn from '../../resources/images/denti-approved-clinic-badge_en.svg';
import SundhedplusClinicBadgeDa from '../../resources/images/sundhed-approved-clinic-badge-de.svg';
import SundhedplusClinicBadgeEn from '../../resources/images/sundhed-approved-clinic-badge_en.svg';

const StepOne = (props) => {
  const [loanDetails, setLoanDetails] = useState({});
  const brandEnvVariable = process.env.REACT_APP_BRAND;

  useEffect(() => {
    if (props.acceptLoan !== undefined) {
      setLoanDetails(props.acceptLoan);
    }
  }, [props.acceptLoan]);

  const continueStep = () => {
    props.nextStep();
  };

  const rejectStep = () => {
    props.rejectLoan();
  };

  return (
    <div className='step-content step-one'>
      <h1 className='step-title'>
        {strings.step1Title}
      </h1>
      <div className='clinic-information'>
        <div className='clinic-details'>
          <div className='clinic-label'>{strings.loanClinic}</div>
          <div className='clinic-data'>
            {loanDetails.companyInvoiceName}
            <br />
            {loanDetails.companyAddress}
            <br />
            {`${loanDetails.companyZip} ${loanDetails.companyCity}`}
          </div>
        </div>
        {
          strings.getLanguage() === 'da'
            ? <img src={brandEnvVariable === 'Denti' ? DentiClinicBadgeDa : SundhedplusClinicBadgeDa} alt='ClinicBadge' className='clinic-badge' />
            : <img src={brandEnvVariable === 'Denti' ? DentiClinicBadgeEn : SundhedplusClinicBadgeEn} alt='ClinicBadge' className='clinic-badge' />
        }
      </div>
      <div className='invoice-amount'>
        <div className='invoice-amount-label'>
          {strings.invoiceAmount}
        </div>
        <div className='invoice-amount-value'>
          {currencyFormatDA(loanDetails.loanAmount, true, true)}
        </div>
      </div>
      <div className='available-credit'>
        <div>{strings.availableCredit}</div>
        <div>{currencyFormatDA(loanDetails.remainingAvailableCredit, true, true)}</div>
      </div>
      <div className='question'>
        {strings.agreeBillQuestion}
      </div>
      <div className='step-actions'>
        <Button color='light' className='reject' onClick={() => rejectStep()}>{strings.declineLoan}</Button>
        <Button color='blue' className='continue' onClick={() => continueStep()}>{strings.continueStep}</Button>
      </div>
    </div>
  );
};

export default StepOne;
