import React from 'react';
import { Button } from 'reactstrap';
import './style.scss';
import { strings } from '../../../../../../constants/localization';

const GrandAccessSuccess = ({ clinicName = 'leggthhiPRoblem', patientName = 'patient Test', close}) => (
  <div className='grant_access_success_Component'>
    <div className='grant_access_success_Component-header'>
      <i className='lnir lnir-checkmark-circle' />
      <div className='title'>{strings.accesGranted}</div>
    </div>
    <div className='grant_access_success_Component-content'>
      <div className='firstText'>
        {strings.formatString(strings.accesGrantedTo, clinicName, patientName)}
      </div>
      <div className='secondText'>
        {strings.accessGrantedNotification}
      </div>
    </div>
    <div className='grant_access_Component-button_area'>
      <Button color='blue' onClick={close}>
        {strings.close}
      </Button>
    </div>
  </div>
);

export default GrandAccessSuccess;
