import React from 'react';
import DentiModal from '../../../common/DentiModal';
import CreditFacility from './CreditFacility';

const CreditFacilityModal = (props) => {
  return (
    <DentiModal
      key='credit-facility-modal'
      close={() => props.hideModal(true)}
      modalClass='credit-facility-modal modal-dialog-centered modal-dialog-scrollable'
      closeBtn={true}
    >
      <CreditFacility creditDetails={props.creditDetails} />
    </DentiModal>
  );
};

export default CreditFacilityModal;
