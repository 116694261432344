import {
  TOGGLE_MODAL_ERROR,
  SET_SERVER_MODAL_ERROR,
  INCREMENT_ONGOING_REQUESTS_COUNTER,
  DECREMENT_ONGOING_REQUESTS_COUNTER,
  OPEN_SEND_LINK_APPLICATION_FROM_WELCOME_PAGE,
} from '../constants';

export function toggleServerModalError(status = false) {
  return {
    type: TOGGLE_MODAL_ERROR,
    payload: status,
  };
}

export function setServerModalError(serverErrorDetails) {
  return {
    type: SET_SERVER_MODAL_ERROR,
    payload: serverErrorDetails,
  };
}

export function openSendLinkApplicationFromWelcomePage(step = null) {
  return {
    type: OPEN_SEND_LINK_APPLICATION_FROM_WELCOME_PAGE,
    payload: step,
  };
}

export function incrementOngoingRequestsCounter() {
  return {
    type: INCREMENT_ONGOING_REQUESTS_COUNTER,
    payload: null,
  };
}

export function decrementOngoingRequestsCounter() {
  return {
    type: DECREMENT_ONGOING_REQUESTS_COUNTER,
    payload: null,
  };
}

let lastId = 0;

export function newId(prefix = 'id') {
  lastId += 1;
  return `${prefix}${lastId}`;
}
