import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'reactstrap';
import {
  deleteBookingRequest,
  getBookingStatus,
} from '../../actions/bookingRequestActions';
import { strings } from '../../constants/localization';
import ConfirmActionModal from '../common/ConfirmationModal';
import DentiModal from '../common/DentiModal';
import ModalBookingOptions from './ModalBookingOptions';

function BookingStatus(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingWithSuccess, setLoadingWithSuccess] = useState(false);
  const [loadingWithoutSuccess, setLoadingWithoutSuccess] = useState(false);
  const [bookingWithSuccess] = useState('DONE_WITH_SUCCESS');
  const [bookingWithoutSuccess] = useState('DONE_WITHOUT_SUCCESS');
  const [deleteBooking, setDeleteBooking] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const clinicStatus = props.status.clinicDecidedStatus;

  const dispatch = useDispatch();

  const CheckClinicStatus = useCallback(() => {
    if (
      clinicStatus === bookingWithSuccess
      || clinicStatus === bookingWithoutSuccess
    ) {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line
  }, [clinicStatus]);
  useEffect(() => {
    CheckClinicStatus();
  }, [CheckClinicStatus]);

  const markAsSolved = () => {
    setIsModalOpen(true);
  };

  const solvedWithSuccess = () => {
    setLoadingWithSuccess(true);
    dispatch(
      getBookingStatus({
        bookingRequestUuid: props.status.uuidValue,
        clinicDecidedStatus: bookingWithSuccess,
      }),
    )
      .then((response) => {
        const requestData = response.payload;
        if (requestData.status === 200) {
          setIsModalOpen(false);
          setLoadingWithSuccess(false);
          setButtonDisabled(true);
          props.setClinicDecidedStatus(bookingWithSuccess);
        }
      })
      .catch((error) => {
        setLoadingWithSuccess(false);
        console.log('error', error);
      });
  };

  const solvedWithoutSuccess = () => {
    setLoadingWithoutSuccess(true);
    dispatch(
      getBookingStatus({
        bookingRequestUuid: props.status.uuidValue,
        clinicDecidedStatus: bookingWithoutSuccess,
      }),
    )
      .then((response) => {
        const requestData = response.payload;
        if (requestData.status === 200) {
          setIsModalOpen(false);
          setLoadingWithoutSuccess(false);
          setButtonDisabled(true);
          props.setClinicDecidedStatus(bookingWithoutSuccess);
        }
      })
      .catch((error) => {
        setLoadingWithoutSuccess(false);
        console.log('error', error);
      });
  };

  const deleteModal = () => {
    setDeleteBooking(true);
  };

  const bookingRequestDelete = () => {
    setLoading(true);
    dispatch(
      deleteBookingRequest({
        bookingRequestUuid: props.status.uuidValue,
      }),
    )
      .then((response) => {
        const requestData = response.payload;

        if (requestData.status === 200) {
          setLoading(false);
          setDeleteBooking(false);
          props.setError(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  return (
    <>
      {!buttonDisabled ? (
        <Button
          color='blue'
          type='button'
          className='btnSolved center-block btn btn-blue'
          onClick={() => {
            markAsSolved();
          }}
        >
          <i className='lnir lnir-checkmark-circle' />
          {strings.btn_solved}
        </Button>
      ) : (
        <Button
          type='button'
          className='btnSolved center-block btn btn-BookingDisabled'
          disabled
        >
          <i className='lnir lnir-checkmark-circle' />
          {strings.btn_markedSolved}
        </Button>
      )}

      <Button
        color='blue'
        type='button'
        className='center-block btn btn-blue btnDeleted'
        onClick={() => {
          deleteModal();
        }}
      >
        <i className='lnir lnir-trash-can ' />
        {strings.delete}
      </Button>
      {isModalOpen && (
        <DentiModal
          key={props.status.uuidValue}
          close={() => setIsModalOpen(false)}
          modalClass='modal-dialog-centered modal-content-bookingRequest '
        >
          <ModalBookingOptions
            loadingWithSuccess={loadingWithSuccess}
            loadingWithoutSuccess={loadingWithoutSuccess}
            firstAction={solvedWithSuccess}
            secondAction={solvedWithoutSuccess}
          >
            <div className='bookingRequestModalText'>
              <h1 className='title'>{strings.bookingModalSolved_tittle}</h1>
            </div>
          </ModalBookingOptions>
        </DentiModal>
      )}
      {deleteBooking && (
        <DentiModal
          key={1}
          close={() => setDeleteBooking(false)}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmActionModal
            loading={loading}
            confirmAction={bookingRequestDelete}
            cancelAction={() => {
              setDeleteBooking(false);
            }}
          >
            <div>
              <p className='title'>{strings.bookingModalDelete_tittle}</p>
              <p className='confirm-message mb-3'>
                {strings.bookingModalDelete_message}
              </p>
            </div>
          </ConfirmActionModal>
        </DentiModal>
      )}
    </>
  );
}

export default BookingStatus;
