import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import 'react-quill/dist/quill.bubble.css';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';

export default function InternalNote(props) {
  const dispatch = useDispatch();
  const decodedNote = decodeURIComponent(
    props.activeObject?.internalNote || props.activeObject?.internal_note || ''
  );
  const [editorHtml, setEditorHtml] = useState(decodedNote);
  const [editorDirty, setEditorDirty] = useState(false);
  const [loadingAfterSave, setLoadingAfterSave] = useState(false);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['image'],
      [{ list: 'ordered' }],
      [{ color: [] }],
      ['clean'],
    ],
  };

  useEffect(() => {
    const note = decodeURIComponent(
      props.activeObject.internalNote || props.activeObject?.internal_note || ''
    );
    setEditorHtml(note);
    setEditorDirty(false);
  }, [props.activeObject]);

  const handleChange = (htmlText) => {
    setEditorHtml(htmlText);
    checkEditorChanged(htmlText);
  };

  const checkEditorChanged = (htmlText) => {
    const intialNote = decodeURIComponent(
      props.activeObject.internalNote || props.activeObject?.internal_note || ''
    );
    // if the intial text was not empty and now is updated or erased
    // or it was empty and now is updated to something different than empty
    if (
      (intialNote !== '' && htmlText !== intialNote) ||
      (intialNote === '' && htmlText !== '<p><br></p>')
    ) {
      setEditorDirty(true);
    } else {
      setEditorDirty(false);
    }
  };

  // since we have no control over library react quill we have to suppressing specific warnings in our console that comes from the library
  // useEffect(() => {
  //   const originalConsoleError = console.error;
  //   console.error = (...args) => {
  //     if (args[0]?.includes('DOM Mutation Event')) {
  //       return;
  //     }
  //     originalConsoleError.call(console, ...args);
  //   };
  // }, []);

  return (
    <div id='LeadInternalNote' className='note-component custom-card card'>
      <div className='wrapper'>
        <div className='descriptionWraper'>
          <div className='iconArea'>
            <i className='lnir lnir-pencil-alt' />
          </div>
          <div className='titleArea'>{strings.internalNote}</div>
        </div>
        <div className='editorArea'>
          <div className='quill-editor'>
            <ReactQuill
              placeholder={strings.notePlaceholder}
              theme='bubble'
              onChange={handleChange}
              value={editorHtml}
              modules={modules}
              bounds='.quill-editor'
            />
          </div>
          <div className='text-center'>
            <Button
              color='blue'
              disabled={editorDirty === false || loadingAfterSave}
              onClick={() => {
                setLoadingAfterSave(true);
                dispatch(
                  props.saveInternalNote(
                    props.id,
                    encodeURIComponent(editorHtml)
                  )
                )
                  .then(() => {
                    setLoadingAfterSave(false);
                  })
                  .catch(() => {
                    setLoadingAfterSave(false);
                  });

                if (props.updateInternalNote) {
                  props.updateInternalNote(encodeURIComponent(editorHtml));
                }
                setEditorDirty(false);
              }}
            >
              {loadingAfterSave ? (
                <ButtonLoader />
              ) : (
                <>
                  <i className='di di-save' />
                  {strings.saveInternalNote}
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

InternalNote.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeObject: PropTypes.object,
  saveInternalNote: PropTypes.func,
  updateInternalNote: PropTypes.func,
};
