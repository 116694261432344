import React from 'react';
import { useSelector } from 'react-redux';
import { strings } from '../../constants/localization';

const Step1 = ({ isCreditExpired }) => {
  const clientDetails = useSelector(
    (state) => state.migrateCredit?.clientDetails
  );
  const { creditAmount, creditBrand, latestLoanBrand } = clientDetails || {};

  return (
    <div className='Step1 section'>
      {/* Text for Sundhed+ members with Denti loans */}
      {creditBrand === 'Sundhed+' && latestLoanBrand === 'Denti' && (
        <div className='sundhed-credit-denti-loans'>
          <div className='title'>
            {strings.migrateCredit_SundhedCredit_DentiLoan_title}
          </div>
          <div className='content'>
            <article>
              <p>{strings.migrateCredit_SundhedCredit_DentiLoan_p1}</p>
            </article>
            <article>
              <p>{strings.migrateCredit_SundhedCredit_DentiLoan_p2}</p>
            </article>
          </div>
        </div>
      )}

      {/* Text for not expired Denti Credit with Denti loans */}
      {creditBrand === 'Denti' && !isCreditExpired && (
        <div className='not-expired-denti-credit-denti-loans'>
          <div className='title'>{strings.migrateCredit_title_step1}</div>
          <div className='content'>
            <article>
              <p>
                {strings.formatString(
                  strings.migrateCredit_first_paragraph_step1,
                  new Intl.NumberFormat('da-DK', {
                    style: 'currency',
                    currency: 'DKK',
                    maximumSignificantDigits: 5,
                  }).format(creditAmount)
                )}
              </p>
            </article>
            <article>
              <h5>{strings.migrateCredit_second_headline_step1}</h5>
              <p>{strings.migrateCredit_second_paragraph_step1}</p>
            </article>
            <article>
              <h5>{strings.migrateCredit_third_headline_step1}</h5>
              <p>{strings.migrateCredit_third_paragraph_step1}</p>
            </article>
          </div>
        </div>
      )}

      {/* Text for expired Denti Credit with Denti loans */}
      {creditBrand === 'Denti' && isCreditExpired && (
        <div className='expired-denti-credit-denti-loans'>
          <div className='title'>
            {strings.migrateCredit_ExpiredDentiCredit_DentiLoan_title}
          </div>
          <div className='content'>
            <article>
              <p>{strings.migrateCredit_ExpiredDentiCredit_DentiLoan_p1}</p>
            </article>
            <article>
              <h5>
                {strings.migrateCredit_ExpiredDentiCredit_DentiLoan_headline2}
              </h5>
              <p>
                {strings.formatString(
                  strings.migrateCredit_ExpiredDentiCredit_DentiLoan_p2,
                  <br />
                )}
              </p>
              <p>{strings.migrateCredit_ExpiredDentiCredit_DentiLoan_p3}</p>
            </article>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1;
