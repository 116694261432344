import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { strings } from '../../constants/localization';
import { ROUTE_LEADS } from '../../constants/routes';
import * as animationData from '../../resources/animations/add-patient_accept_lottie.json';
import LottieControl from './LottieControl';

const StepClientAssigned = (props) => {
  console.log('StepClientAssigned props', props);
  const history = useHistory();

  return (
    <div className='step-content step-final'>
      <div className='step-header'>
        <LottieControl animationData={animationData} height={312} width={340} />
        <div className='title-text'>{strings.apClientAssignedTitle}</div>
      </div>
      <div className='step-body'>
        <div className='text-information'>
          <p>
            {strings.formatString(
              strings.apClientAssignedText1,
              `${props.stateInfo.firstName} ${props.stateInfo.lastName}`
            )}
          </p>
          <p>
            {strings.formatString(
              strings.apClientAssignedText2,
              <b>{strings.apClientAssignedTip}</b>
            )}
          </p>
        </div>
        <div className='button-actions'>
          <Button
            color='blue'
            onClick={() => {
              props.closeModal();
              history.push({
                pathname: ROUTE_LEADS,
                search: `?q=${props.patientCpr}`,
              });
              // history.push(`${ROUTE_LEADS}?q=${props.stateInfo.clientCpr}`);
            }}
          >
            {strings.buttonTextShowClient}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default StepClientAssigned;
