import React, { useRef, useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import { ReactComponent as InfoCircle } from '../../../resources/icons/info-circle.svg';
import { ReactComponent as Flag } from '../../../resources/icons/lni_flag.svg';

const WarningCollapsible = ({ iconType, headline, children }) => {
  const descriptionRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  return (
    <Col className='warning-item'>
      <div
        className='warning-headline'
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className='icon'>
          {iconType === 'info' ? (
            <InfoCircle className='info-circle' />
          ) : (
            <Flag />
          )}
        </span>
        <span className='warning-text'>{headline}</span>
        <span className={`arrow ${isOpen ? 'active' : ''}`}></span>
      </div>
      <div
        className='warning-description'
        ref={descriptionRef}
        style={
          isOpen
            ? {
                maxHeight: (descriptionRef?.current?.scrollHeight || 0) + 20,
              }
            : { maxHeight: '0px' }
        }
      >
        {children}
      </div>
    </Col>
  );
};

export default WarningCollapsible;
