import React, { useState, useEffect } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import moment from 'moment';
import { ReactComponent as InformationIcon } from '../../resources/icons/info-circle.svg';

const ExistingMember = ({ step, setNextStep, creditExpirationDate }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const savedData = useSelector((state) => state.onboarding?.data);
  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
      : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');

  const timeDiference = moment().diff(
    moment(creditExpirationDate).add(1, 'days')
  );

  const isExpired = timeDiference >= 0 ? true : false;

  const isCreditRenew =
    savedData?.linkTag === 'forny' ||
    savedData?.memberStatus === 'EXISTING_CLIENT'
      ? true
      : false;

  // add handler to go to next step on Enter key pressed
  useEffect(() => {
    document.title = `Sundhed+ | ${
      isCreditRenew
        ? strings.onboardingExistingClientTitle
        : strings.onboardingExistingMemberTitle
    }`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/eksisterende-medlem',
        stepTitle: 'onboarding - eksisterende medlem',
        event: 'onboarding',
      });
    }

    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handleSubmit = () => {
    setButtonLoading(true);
    setNextStep(step + 1);
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {isCreditRenew
          ? strings.onboardingExistingClientTitle
          : strings.onboardingExistingMemberTitle}
      </div>
      {isCreditRenew ? (
        <>
          <div className='step-subtitle'>
            <p className='existingMemberSubtitle'>
              {strings.onboardingExistingClientP1}
            </p>
            <p className='existingMemberSubtitle'>
              {strings.onboardingExistingClientP2}
            </p>
            <p className='existingMemberSubtitle'>
              {strings.onboardingExistingClientP3}
            </p>
          </div>

          <div className='warning-note mb-5'>
            <div className='head'>
              <div className='icon'>
                <InformationIcon className='infoIcon' />
              </div>

              {strings.onboardingExistingClientExpiredCreditTitle}
            </div>
            <div className='body'>
              <p>{strings.onboardingExistingClientExpiredP1}</p>
              <p>{strings.onboardingExistingClientExpiredCreditP2}</p>
              {!isExpired && (
                <p>{strings.onboardingExistingClientExpiredCreditP3}</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='step-subtitle'>
            <p className='existingMemberSubtitle'>
              {strings.onboardingExistingMemberSubtitle}
            </p>
          </div>
          <div className='step-subtitle '>
            <h2 className='existingMemberTitle bold '>
              {strings.onboardingExistingMemberQuestionsTitle}
            </h2>
            <p className='contactForQuestions'>
              {strings.formatString(
                strings.onboardingExistingMemberQuestions,
                <a
                  className='color'
                  href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                >
                  {phoneNumber}
                </a>,
                <a
                  className='color'
                  href={`mailto:${brandConfig.contactEmail}`}
                >
                  {brandConfig.contactEmail}
                </a>
              )}
            </p>
          </div>
          <div className='step-subtitle'>
            <h2 className='bold existingMemberTitle '>
              {strings.onboardingExistingMemberApplyForCreditTitle}
            </h2>
            <p className='existingMemberSubtitle'>
              {strings.onboardingExistingMemberApplyForCredit}
            </p>
          </div>
        </>
      )}

      <div className='actions-buttons mt-auto mb-3'>
        <Button
          className='btn btn-blue btn-success full-width'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <ButtonLoader />
          ) : isCreditRenew ? (
            isExpired ? (
              strings.onboardingRenewCreditCheck
            ) : (
              strings.onboardingRenewCredit
            )
          ) : (
            strings.onboardingCreateNewCredit
          )}
        </Button>
      </div>
    </CardBody>
  );
};

export default ExistingMember;
