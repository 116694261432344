import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardFooter, CardBody, Button } from 'reactstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './style.scss';
import Fade from '../common/Fade';
import dentiLogo from '../../resources/images/Denti_logo_400x92px.png';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { strings } from '../../constants/localization';
import { moment } from '../../constants';
import {
  loanCalculatorFormatAmounts,
  currencyFormatDA,
  paymentListMoment,
  formatInterestRate,
} from '../../constants/utils';
import { loanCalcDefaultInterestRate } from '../../config';

function LoanCalculator() {
  const params = new URLSearchParams(document.location.search.substring(1));
  const urlParams = new URLSearchParams(window.location.search);
  const interestRateParam = urlParams.get('interestRate');
  const logo = params.get('logo') || null;
  const minAmount = 1000;
  const halfAmount = 25000;
  const maxAmount = 50000;
  const stepAmount = 500;
  const interestRate =
    parseFloat(interestRateParam) || loanCalcDefaultInterestRate;
  const headline = urlParams.get('headline') ? urlParams.get('headline') : '';
  const companyReference = urlParams.get('companyReference');
  const companyChainId = urlParams.get('companyChainId');
  const [applyButton, setApplyButton] = useState(false);
  // const applyButtonParam = urlParams.get('applyButton') === 'true';
  const [applyButtonParam, setApplyButtonParam] = useState(
    urlParams.get('applyButton') === 'true'
  );
  const duration = [24, 36, 48, 60, 72];
  const [currentAmount, setCurrentAmount] = useState(halfAmount);
  const [currentDuration, setCurrentDuration] = useState(72);
  const [establishmentFee, setEstablishmentFee] = useState(
    0.05 * currentAmount
  );
  const brandEnvVariable = process.env.REACT_APP_BRAND;
  const sliderMarks = {
    [minAmount]: currencyFormatDA(minAmount, false, true, false),
    [maxAmount]: currencyFormatDA(maxAmount, false, true, false),
  };
  const [paymentDetails, setPaymentDetails] = useState({
    monthlyPayment: 0,
    apr: 0,
  });
  const [totalCreditCost, setTotalCreditCost] = useState(0);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [horizontalCard, setHorizontalCard] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const match = url.match(/backgroundColor=([^&]+)/);
    const bgColorParam = match ? match[1] : '';

    const isValidColorKeyword = (color) => {
      const elem = document.createElement('div');
      elem.style.color = color;
      return !!elem.style.color;
    };

    const colorRegex =
      /^(#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})|rgb\((\s*\d{1,3}\s*,){2}\s*\d{1,3}\s*\)|[a-zA-Z]+)$/i;

    const bgColor =
      bgColorParam.startsWith('rgb') || isValidColorKeyword(bgColorParam)
        ? bgColorParam
        : `#${bgColorParam}`;

    const setElementBackgroundColor = (element, color) => {
      if (colorRegex.test(color)) {
        // eslint-disable-next-line no-param-reassign
        element.style.backgroundColor = color;
      }
    };

    const loanCalculator = document.getElementById('loan-calculator');
    const cardWrapperClass = !horizontalCard ? 'wrapper' : 'main-wrapper';
    const cardWrapperElements =
      document.getElementsByClassName(cardWrapperClass);

    setElementBackgroundColor(loanCalculator, bgColor);
    setElementBackgroundColor(cardWrapperElements[0], bgColor);
  }, [params, horizontalCard]);

  useEffect(() => {
    document.body.classList.add('loan-calculator');
    document.body.classList.add('public-content');
    return () => {
      // cleanup
      document.body.classList.remove('loan-calculator');
      document.body.classList.remove('public-content');
    };
  });

  useEffect(() => {
    const url = window.location.href;
    const horizontal = url.includes('horizontal');

    if (horizontal) {
      setHorizontalCard(true);
    }

    const handleResize = () => {
      if (window.innerWidth < 525) {
        setHorizontalCard(false);
      } else {
        setHorizontalCard(true);
      }
    };
    if (horizontal) {
      handleResize(); // Initial check

      const resizeListener = () => {
        handleResize();
      };

      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
  }, [params]);
  // eslint-disable-next-line

  useEffect(() => {
    const totalRepay = currentDuration * paymentDetails.monthlyPayment;
    setTotalRepayment(totalRepay);
    const totalCost = totalRepay - currentAmount;
    setTotalCreditCost(totalCost);
    // eslint-disable-next-line
  }, [currentDuration, paymentDetails.monthlyPayment]);

  useEffect(() => {
    const startOfNextMonth = moment()
      .add(1, 'M')
      .startOf('month')
      .format('YYYY-MM-DD');
    // establismentFee is always 5 % of the loan amount
    setEstablishmentFee(0.05 * currentAmount);
    const paymentResults = paymentListMoment(
      startOfNextMonth,
      parseFloat(interestRate),
      currentAmount,
      currentDuration,
      parseFloat(establishmentFee)
    );
    setPaymentDetails(paymentResults);
  }, [currentAmount, currentDuration, establishmentFee, interestRate]);

  const onSliderChange = (value) => {
    setCurrentAmount(value);
  };

  const onDurationChange = (event) => {
    setCurrentDuration(+event.target.value);
  };

  const renderLoanDetails = (isHorizontal) => (
    <div className='loan-details-card'>
      <div className={`loan-details ${isHorizontal ? 'horizontally' : ''}`}>
        <div className='focus-line line'>
          <div>{strings.calculatorMonthlyBenefit}</div>
          <div>
            {loanCalculatorFormatAmounts(paymentDetails.monthlyPayment)}
          </div>
        </div>
        <div className='line'>
          <div>{strings.calculatorEstablishmentCosts}</div>
          <div>{loanCalculatorFormatAmounts(establishmentFee)}</div>
        </div>
        <div className='line'>
          <div>{strings.calculatorInterestRate}</div>
          <div>{formatInterestRate(interestRate.toFixed(2))}</div>
        </div>
        <div className='line'>
          <div>{strings.calculatorTotalCosts}</div>
          <div>{loanCalculatorFormatAmounts(totalCreditCost)}</div>
        </div>
        <div className='line'>
          <div>{strings.calculatorAnnualCosts}</div>
          <div>{formatInterestRate(paymentDetails?.apr.toFixed(2))}</div>
        </div>
        <div className='line'>
          <div>{strings.calculatorTotalRepayment}</div>
          <div>{loanCalculatorFormatAmounts(totalRepayment)}</div>
        </div>
      </div>
      <p className='note'>
        {strings.formatString(
          strings.calculatorNote,
          formatInterestRate(interestRate)
        )}
      </p>
    </div>
  );

  const renderContent = () => (
    <>
      <h1 className='title'>{headline}</h1>
      <div className='focus-line'>
        <div>{strings.calculatorAmount}</div>
        <div>{loanCalculatorFormatAmounts(currentAmount)}</div>
      </div>
      <div className='amount-slider'>
        <Slider
          value={currentAmount}
          step={stepAmount}
          min={minAmount}
          max={maxAmount}
          onChange={onSliderChange}
          marks={sliderMarks}
          trackStyle={{
            backgroundColor:
              brandEnvVariable === 'Denti' ? '#339ca6' : '#aa2d36',
            height: 8,
          }}
          dots={false}
          handleStyle={{
            backgroundColor:
              brandEnvVariable === 'Denti' ? '#d2bea3' : '#d4aa79',
            borderColor: '#ffffff',
            height: 27,
            width: 27,
            borderWidth: 2,
            marginLeft: -13,
            marginTop: -10,
          }}
          railStyle={{
            backgroundColor:
              brandEnvVariable === 'Denti' ? '#f8f8f8' : '#d1d1d1',
            height: 8,
          }}
        />
      </div>

      <div className='loan-duration'>
        <h3>{strings.calculatorQuestion1}</h3>
        <div
          className={
            horizontalCard ? 'horizontally-duration' : 'duration-buttons'
          }
        >
          {duration.map((item) => (
            <label
              className={`radio-inline ${
                currentDuration === item ? 'selected' : ''
              }`}
              key={item}
            >
              <input
                type='radio'
                value={item}
                checked={currentDuration === item}
                onChange={onDurationChange}
              />

              {strings.formatString(strings.calculatorMonth, item)}
            </label>
          ))}
        </div>
      </div>
      {!horizontalCard && renderLoanDetails(horizontalCard)}
    </>
  );

  useEffect(() => {
    if (companyChainId === '' && companyReference !== '') {
      setApplyButton(true);
    } else if (companyChainId !== '' && companyReference === '') {
      setApplyButton(true);
    } else if (companyChainId === '' && companyReference === '') {
      setApplyButton(false);
    }

    if (applyButton && (companyReference === null || companyChainId === null)) {
      setApplyButtonParam(false);
    }
  }, [companyReference, companyChainId]);

  const renderApplyButton = () => {
    if (applyButtonParam) {
      let applyButtonUrl = '';

      if (
        companyReference === 'X001' ||
        (!companyReference && !companyChainId)
      ) {
        applyButtonUrl = 'https://signup.sundhedplus.dk/';
      } else if (companyReference) {
        applyButtonUrl = `https://www.sundhedplus.dk/sundhedplus-kredit/?c=${companyReference}`;
      } else if (companyChainId) {
        applyButtonUrl = `https://www.sundhedplus.dk/del-regningen-op-med-sundhed/?cc=${companyChainId}`;
      }

      return (
        <div className='text-center applyButton'>
          <a href={applyButtonUrl} target='_blank' rel='noopener noreferrer'>
            <Button className='btn btn-blue text-center button-layout'>
              {strings.calculatorApplyButton}
            </Button>
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderWithLogo = () => {
    if (brandEnvVariable === 'Denti') {
      return (
        <div className='logo logo-green'>
          <img className='denti-logo' src={dentiLogo} alt='Denti' />
        </div>
      );
    }
    if (brandEnvVariable === 'Sundhedplus') {
      return (
        <div className='logo sundhed-plus-logo'>
          <img className='sundhed-logo' src={sundhedLogo} alt='Sundhed+' />
        </div>
      );
    }
    return null;
  };

  return (
    <div id={brandEnvVariable}>
      <div id='loan-calculator'>
        <Fade show='true'>
          {horizontalCard ? (
            <div className='main-wrapper'>
              <div className='left-column'>
                <div className='logo-card'>
                  {logo && logo === 'top' && (
                    <CardHeader>{renderWithLogo()}</CardHeader>
                  )}
                  {(logo === null || logo !== 'top' || logo !== 'bottom') && (
                    <div className='horizontalCard'>{renderContent()}</div>
                  )}
                  {logo && logo === 'bottom' && (
                    <CardFooter>{renderWithLogo()}</CardFooter>
                  )}
                </div>
              </div>
              <div className='right-column'>
                {renderLoanDetails(horizontalCard)}
                {renderApplyButton()}
              </div>
            </div>
          ) : (
            <Card className='wrapper'>
              {logo && logo === 'top' && (
                <CardHeader>{renderWithLogo()}</CardHeader>
              )}
              {(logo === null || logo !== 'top' || logo !== 'bottom') && (
                <CardBody>{renderContent()}</CardBody>
              )}

              {logo && logo === 'bottom' && (
                <CardFooter>{renderWithLogo()}</CardFooter>
              )}
              {renderApplyButton()}
            </Card>
          )}
        </Fade>
      </div>
    </div>
  );
}
export default LoanCalculator;
