import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody } from 'reactstrap';
import { strings } from '../../../constants/localization';
import {
  createMember,
  storeOnboardingData,
} from '../../../actions/onboardingActions';

function ConfirmEmailError({
  showConfirmErrorStep,
  setButtonLoading,
  setEmailConfirmationCode,
}) {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const { firstName, lastName, email, phoneNumber } = savedData || {};
  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {strings.onboardingEmailVerificationErrorTitle}
      </div>
      <div className='step-subtitle '>
        <p className='existingMemberSubtitle'>
          {strings.onboardingEmailVerificationErrorP1}
        </p>
      </div>
      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-cancel long-btn'
          onClick={() => {
            //Email code error. Retry by calling createMember, which should update and resend the email code
            dispatch(
              createMember(firstName, lastName, email, phoneNumber)
            ).then(() => {
              dispatch(
                storeOnboardingData({
                  ...savedData,
                  remainingConfirmEmailAttempts: 3,
                  emailCodeIncorrect: false,
                })
              );
              showConfirmErrorStep(false);
              setButtonLoading(false);
              setEmailConfirmationCode(['', '', '', '']);
            });
          }}
        >
          {strings.onboardingEmailVerificationErrorBtn}
        </Button>
      </div>
    </CardBody>
  );
}

export default ConfirmEmailError;
