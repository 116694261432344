import React from 'react';
import { strings } from '../../../constants/localization';
import { brandConfig } from '../.././../config';
import ErrorPage from '../../../components/common/ErrorPage';

function CreateMemberError() {
  document.title = strings.exceptionExpiredTitle;
  return (
    <ErrorPage>
      <h1>{strings.createMemberErrorTitle}</h1>

      <p>{strings.createMemberErrorP1}</p>

      <p>
        {strings.formatString(
          strings.createMemberErrorP2,
          <a href={`mailto:${brandConfig.contactEmail}`}>
            {brandConfig.contactEmail}
          </a>,
          <a href={`tel:${brandConfig.contactPhone}`}>
            {brandConfig.contactPhoneHtmlFormated}
          </a>
        )}
      </p>
    </ErrorPage>
  );
}

export default CreateMemberError;
