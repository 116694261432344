import {
  CREATE_CREDIT_EMAIL,
  CREATE_CREDIT_EMAIL_MODAL,
  CREATE_CREDIT_SMS,
  CREATE_CREDIT_SMS_MODAL,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_CREDIT_EMAIL:
    case CREATE_CREDIT_EMAIL_MODAL:
    case CREATE_CREDIT_SMS:
    case CREATE_CREDIT_SMS_MODAL:
      return state;
    default:
      return state;
  }
}
