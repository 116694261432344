import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { strings } from '../../../constants/localization';
import {
  getDocumentsSupportNotesEntries,
  formatCurrencyDaStandard,
} from '../../../constants/utils';
import { ReactComponent as FileNote } from '../../../resources/icons/file.svg';
import DocumentationPreview from './DocumentationPreview';

const UploadDocumentationSection = ({
  type,
  amount,
  openUploadModal,
  setCurrentSection,
  uploadedDocumentation,
}) => {
  const sectionContentEl = useRef();
  const clientNoteEl = useRef();
  const supportNoteEl = useRef();
  const filesContainerEl = useRef();
  const buttonEl = useRef();
  //const savedData = useSelector((state) => state.onboarding?.data);
  const [budgetItmeValue, setBudgetItemValue] = useState(amount);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [clientNote, setClientNote] = useState('');
  const [expandEditor, setExpandEditor] = useState(false);
  const [fullSectionVisible, setFullSectionVisible] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [uploadImagePreview, setUploadImagePreview] = useState({});
  // PENDING, APPROVED, REJECTED, REQUIRED
  const [documentationStatus, setDocumentationStatus] = useState(
    uploadedDocumentation?.status || 'PENDING'
  );
  const supportEntries = getDocumentsSupportNotesEntries(
    uploadedDocumentation?.customerNote
  );
  const lastSupportEntry =
    supportEntries.length > 0 ? supportEntries[supportEntries.length - 1] : '';
  const [customerNote, setCustomerNote] = useState(
    lastSupportEntry?.supportNote
  );

  const [sectionMaxHeight, setSectionMaxHeight] = useState(0);

  useEffect(() => {
    if (uploadedDocumentation) {
      const newAmount = uploadedDocumentation?.newBudgetItemValue;
      setUploadedDocuments(
        uploadedDocumentation?.documents.length > 0
          ? uploadedDocumentation?.documents
          : []
      );
      setClientNote(
        uploadedDocumentation?.clientNote !== ''
          ? uploadedDocumentation.clientNote
          : ''
      );
      const supportEntries = getDocumentsSupportNotesEntries(
        uploadedDocumentation?.customerNote
      );
      const lastSupportEntry =
        supportEntries.length > 0
          ? supportEntries[supportEntries.length - 1]
          : '';
      setCustomerNote(lastSupportEntry?.supportNote);
      setBudgetItemValue(
        newAmount !== null && newAmount !== undefined ? newAmount : amount
      );
      setDocumentationStatus(uploadedDocumentation?.status);

      if (validateDocumentsHaveFiles() || uploadedDocumentation?.clientNote) {
        setHasContent(true);
      } else {
        setHasContent(false);
      }

      if (uploadedDocumentation?.status === 'REJECTED') {
        setFullSectionVisible(true);
      }
    }
  }, [
    uploadedDocumentation,
    uploadedDocumentation?.clientNote,
    uploadedDocumentation?.documents,
  ]);

  useEffect(() => {
    let hasDocuments = uploadedDocuments.length > 0;
    let hasClientNote = clientNote !== '';
    let hasSupportNote = customerNote !== '';
    let totalHeight = 0;

    if (hasDocuments) {
      totalHeight += filesContainerEl.current?.offsetHeight || 0;
    }
    if (hasClientNote) {
      totalHeight += clientNoteEl.current?.offsetHeight || 0;
    }
    if (hasSupportNote) {
      totalHeight += supportNoteEl.current?.offsetHeight || 0;
    }

    setSectionMaxHeight(
      totalHeight + (buttonEl.current?.offsetHeight || 0) + 20
    );
  }, [documentationStatus, clientNote, customerNote]);

  useEffect(() => {
    if (expandEditor) {
      setSectionMaxHeight(
        sectionMaxHeight + (clientNoteEl.current?.offsetHeight || 0)
      );
    }
  }, [expandEditor]);

  const validateDocumentsHaveFiles = () => {
    if (uploadedDocumentation?.documents.length > 0) {
      // Checks whether a document has a file name
      const hasFileName = (element) =>
        element.fileName !== null &&
        element.fileName !== undefined &&
        element.fileName !== '' &&
        element.fileName !== 'no-file';
      return uploadedDocumentation?.documents.some(hasFileName);
    }
  };

  const renderDocumentationStatus = () => {
    switch (documentationStatus) {
      case 'PENDING':
        return strings.onboardingUploadDocStatusPending;
      case 'APPROVED':
        return strings.onboardingUploadDocStatusApproved;
      case 'REJECTED':
        return strings.onboardingUploadDocStatusRejected;
      default:
        return strings.onboardingUploadDocStatusPending;
    }
  };

  const stringAmountOrPercentage =
    type !== 'SPLIT_EXPENSES'
      ? `${formatCurrencyDaStandard(budgetItmeValue)}/md`
      : `${budgetItmeValue !== 0 ? budgetItmeValue * 100 : 0} %`;
  return (
    <section
      className={`upload-section ${
        documentationStatus === 'REJECTED' ? 'rejected' : ''
      }`}
    >
      <div className='top'>
        <span className='type'>{strings[type]}</span>
        <span className='amount'>{stringAmountOrPercentage}</span>
      </div>
      {hasContent ? (
        <>
          <div className='upload-results'>
            {/* STATUS */}
            <div className={`status ${documentationStatus}`}>
              <span className='dot-status'></span>
              <span className='text'>{renderDocumentationStatus()}</span>
            </div>

            {/* SECTION CONTENT WRAPPER */}
            <div
              ref={sectionContentEl}
              className='documentation-section'
              style={
                fullSectionVisible
                  ? { maxHeight: sectionMaxHeight }
                  : { maxHeight: '0px' }
              }
            >
              {/* Documents CONTAINER */}
              {(uploadedDocuments.length > 0 || clientNote !== '') && (
                <div className='files-container' ref={filesContainerEl}>
                  {/* files */}
                  <ul>
                    {uploadedDocuments.length > 0 &&
                      uploadedDocuments.map((doc, i) => {
                        if (doc?.fileName && doc?.fileName !== 'no-file') {
                          return (
                            <li key={i}>
                              <span className='grouped'>
                                <i className='lnir lnir-empty-file icon' />
                                <div
                                  className='filename'
                                  onClick={() => {
                                    setOpenImagePreview(true);
                                    setUploadImagePreview(doc?.publicLink);
                                  }}
                                >
                                  {doc.fileName}
                                </div>
                              </span>
                            </li>
                          );
                        } else return null;
                      })}
                  </ul>
                  {clientNote && (
                    <div ref={clientNoteEl} className='note-wrapper'>
                      <div className='header'>
                        <FileNote className='file-note' />
                        <span>{strings.onboardingUploadNoteHeadline}</span>
                        <div
                          className={`arrow ${expandEditor ? 'active' : ''}`}
                          onClick={() => {
                            setExpandEditor(!expandEditor);
                          }}
                        ></div>
                      </div>
                      <div
                        className='panel-editor'
                        style={
                          expandEditor
                            ? {
                                maxHeight:
                                  parseInt(
                                    clientNoteEl.current?.scrollHeight,
                                    10
                                  ) + 64,
                              }
                            : { maxHeight: '0px' }
                        }
                      >
                        <div className='client-note'>{clientNote || ''}</div>
                      </div>
                    </div>
                  )}

                  {documentationStatus === 'REJECTED' &&
                    customerNote !== '' && (
                      <div className='rejected-note' ref={supportNoteEl}>
                        <div className='head'>
                          {strings.onboardingUploadDocRejectedNoteHeadline}
                        </div>
                        <div className='body'>{customerNote || ''}</div>
                      </div>
                    )}
                </div>
              )}

              {/* BUTTONS AREA */}
              <div className='bottom-area' ref={buttonEl}>
                <button
                  type='button'
                  className='addItemBtn'
                  onClick={() => {
                    openUploadModal(true);
                    setCurrentSection({
                      type: type,
                      amount: budgetItmeValue,
                    });
                  }}
                >
                  <span>{strings.onboardingUploadMoreDocBtnSection}</span>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='bottom-area'>
          <button
            type='button'
            className='addItemBtn section'
            onClick={() => {
              openUploadModal(true);
              setCurrentSection({
                type: type,
                amount: budgetItmeValue,
              });
            }}
          >
            <span>{strings.onboardingUploadDocBtnSection}</span>
          </button>
        </div>
      )}

      {/* doc image preview*/}
      {openImagePreview && (
        <DocumentationPreview
          file={uploadImagePreview}
          closeModal={() => setOpenImagePreview(false)}
        />
      )}

      {/* EXPAND/COLLAPSE  */}
      {hasContent && (
        <div
          className={`toggle-documentation ${
            fullSectionVisible ? 'open' : 'closed'
          }`}
        >
          <i
            className={`lnir ${
              fullSectionVisible ? 'lnir-chevron-up' : 'lnir-chevron-down'
            } icon`}
            onClick={() => setFullSectionVisible(!fullSectionVisible)}
          />
        </div>
      )}
    </section>
  );
};

export default UploadDocumentationSection;
