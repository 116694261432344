import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LoadingGif } from '../../../resources/images/loading.svg';
import { ReactComponent as InfoCircle } from '../../../resources/icons/info-circle.svg';
import { strings } from '../../../constants/localization';
import { formatCurrencyDaStandard } from '../../../constants/utils';
import { settleInvoice } from '../../../actions/installmentLoanActions';
import { getUpdatedPatientCreditFacility, setClientLoading } from '../../../actions/leadActions';

interface RetryWithdrawalModalProps {
  onClose: () => void;
  creditFacilityInformation: {
    installmentLoanInformations: {
      status: string;
      brand: string;
      number: string;
      monthlyPaymentAmount: number;
    }[];
    cardExpiryDate: string;
    maskedCard: string;
    subscriptionId: string | null;
    creditCardState: string | null;
    lastPaidInstallmentDate: string | null;
    number: string | null;
  };
}

interface ReepaySettleResponse {
  reepayInvoiceAmount: number;
};

interface ActiveLeadState {
  creditFacilityInformation: {
    id: number;
  }
}

const RetryWithdrawalModal: React.FC<RetryWithdrawalModalProps> = ({
  onClose,
  creditFacilityInformation,
}) => {
  const dispatch = useDispatch();
  const activeLead = useSelector(
    (state: {
      lead: { activeLead: ActiveLeadState };
    }) => state.lead?.activeLead
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [successData, setSuccessData] = useState<ReepaySettleResponse | null>(null);
  const [error, setError] = useState<boolean>(false);
  const amountToWithdraw =
    creditFacilityInformation?.installmentLoanInformations?.filter(invoice => invoice?.status === 'ACCEPTED').reduce((sum, invoice) => sum + invoice.monthlyPaymentAmount || 0, 0);

  const refreshClientData = () => {
    dispatch(setClientLoading(true));
    dispatch(getUpdatedPatientCreditFacility(activeLead.creditFacilityInformation.id))
      .then(() => {
        dispatch(setClientLoading(false));
      });
  };

  useEffect(() => {
    setLoading(true);
    if (creditFacilityInformation?.number) {
      setTimeout(() => {
        dispatch(settleInvoice(creditFacilityInformation.number)).then(
          (response) => {
            if (response.status === 200) {
              setSuccessData(response.data);
              setError(false);
              refreshClientData();
            } else {
              setError(true);
            }
          }
        ).catch(() => {
          setError(true);
        }).finally(() => {
          setLoading(false);
        });
      }, 2000);
    }
  }, []);

  return (
    <div className='retry-withdrawal'>
      {loading ? (
        <div className='loading'>
          <h3 className='headline'>{strings.retryWithdrawalLoadingTitle}</h3>
          <p className='waiting-text'>{strings.retryWithdrawalLoadingText}</p>

          <LoadingGif className='loadingGif' />
        </div>
      ) : successData ? (
        <div className='success'>
          <div className='icon'>
            <i className='di di-circle-check' />
          </div>
          <div>
            <h3 className='headline'>{strings.retryWithdrawalSuccessTitle}</h3>
            <p className='subtitle'>
              {strings.formatString(
                strings.retryWithdrawalSuccessText,
                formatCurrencyDaStandard(successData?.reepayInvoiceAmount ? successData.reepayInvoiceAmount / 100 : amountToWithdraw)
              )}
            </p>
          </div>
          <div className='bottom-section action-btn cf'>
            <Button color='blue' className='btn btn-cancel' onClick={onClose}>
              {strings.retryWithdrawalButtonText}
            </Button>
          </div>
        </div>
      ) : error ? (
        <div className='error'>
          <div className='icon'>
            <InfoCircle className='info-circle' />
          </div>
          <div>
            <h3 className='headline'>{strings.retryWithdrawalErrorTitle}</h3>
            <p className='subtitle'>
              {strings.formatString(
                strings.retryWithdrawalErrorText,
                formatCurrencyDaStandard(amountToWithdraw)
              )}
            </p>
          </div>
          <div className='bottom-section action-btn cf'>
            <Button color='blue' className='btn btn-cancel' onClick={onClose}>
              {strings.retryWithdrawalButtonText}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RetryWithdrawalModal;
