import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { strings } from '../../../../constants/localization';
import {
  isAdmin,
  isManager,
  isDentist,
  currencyFormatDA,
} from '../../../../constants/utils';

const CreditStatus = (props) => {
  const creditDetails = props.lead.creditFacilityInformation;
  const creditFacilityStatus = creditDetails?.status;
  const isUserAdmin = isAdmin(props.user);
  const isUserManager = isManager(props.user);
  const isUserDentist = isDentist(props.user);
  let installmentLoans =
    props.lead.creditFacilityInformation?.installmentLoanInformations || [];
  const expired = creditDetails && creditDetails.remainingTime.totalDays < 0;
  const [clinicsListForCompanyChain, setClinicsListForCompanyChain] = useState(
    []
  );
  let creditStatusText = '';

  useEffect(() => {
    if (isUserManager) {
      let companies = props.managersCompaniesList;
      if (companies?.length > 0) {
        companies = props.managersCompaniesList.map(
          (item) => item.referenceNumber
        );
        setClinicsListForCompanyChain(companies);
      }
    }
  }, [isUserManager, props.managersCompaniesList]);

  //filter the invoices to be only the ones for current company or company chain
  if (isUserManager && clinicsListForCompanyChain.length > 0) {
    installmentLoans = installmentLoans.filter((invoice) =>
      clinicsListForCompanyChain.includes(invoice.companyReferenceNumber)
    );
  }
  if (isUserDentist) {
    installmentLoans = installmentLoans.filter(
      (invoice) => invoice.companyReferenceNumber === props.user.activeCompany
    );
  }

  const needsSignature =
    installmentLoans.filter(
      (invoice) => invoice.status === 'WAITING_FOR_SIGNATURE'
    ).length > 0;

  if (needsSignature) {
    creditStatusText = strings.paymentApproval;
  } else if (!isUserAdmin && creditDetails) {
    if (
      ['LATE_PAYMENT', 'SUSPENDED', 'DECEASED', 'REJECTED'].includes(
        creditFacilityStatus
      )
    ) {
      creditStatusText = strings.creditClosed;
    } else if (expired) {
      creditStatusText = strings.creditExpired;
    } else {
      creditStatusText = `${strings.remainingAmount} ${currencyFormatDA(
        creditDetails.amountLeftWithoutBonus,
        true,
        true
      )}`;
    }
  } else {
    switch (creditFacilityStatus) {
      case 'ACTIVE':
      case 'OFFER':
        if (expired) {
          creditStatusText = strings.creditExpired;
        } else {
          creditStatusText = `${strings.remainingAmount} ${currencyFormatDA(
            creditDetails.amountLeftWithoutBonus,
            true,
            true
          )}`;
        }
        break;
      case 'REJECTED':
        creditStatusText = strings.clientCreditRejected;
        break;
      case 'LATE_PAYMENT':
        creditStatusText = strings.clientDebtCollection;
        break;
      case 'SUSPENDED':
        creditStatusText = strings.clientSuspended;
        break;
      case 'DECEASED':
        creditStatusText = strings.clientDeceased;
        break;
      case 'DISABLED':
      case undefined:
        creditStatusText = strings.clientCreditDisabled;
        break;
      default:
        creditStatusText = `${strings.remainingAmount} ${
          creditDetails
            ? currencyFormatDA(
                creditDetails?.amountLeftWithoutBonus,
                true,
                true
              )
            : 0
        }`;
        break;
    }
  }

  return (
    <div
      className={`credit-status-text ${
        needsSignature ? 'needs-signature' : ''
      }`}
    >
      {creditStatusText}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
    managersCompaniesList: state.company.managersCompaniesList,
  };
}

export default connect(mapStateToProps)(CreditStatus);
