import React from 'react';
import { Button } from 'reactstrap';
import './style.scss';
import { strings } from '../../../../../constants/localization';

const DeleteChainFail = ({
  chainName = '',
  close,
  error = 'this is a test error',
}) => (
  <div className='delete_chain_fail_Component'>
    <div className='delete_chain_fail_Component-header'>
      <i className='lnir lnir-cross-circle' />
      <div className='title'>{strings.accesFaild}</div>
    </div>
    <div className='delete_chain_fail_Component-content'>
      <div className='firstText'>
        {strings.formatString(strings.chainDeleteError, chainName)}
      </div>
      <div className='secondText'>
        <b>{strings.accesFaildReason}</b>
        {`'${error}'`}
      </div>
    </div>
    <div className='grant_access_Component-button_area'>
      <Button color='blue' onClick={close}>
        {strings.close}

      </Button>
    </div>
  </div>
);

export default DeleteChainFail;
