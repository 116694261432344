import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, Button, CardFooter } from 'reactstrap';

import { strings } from '../../constants/localization';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import AmountSlider from './common/AmountSlider';
import { storeOnboardingData } from '../../actions/onboardingActions';

const DisposableAmount = ({ step, previousStep, setShowPowerOfAttorney }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [monthlyDisposable, setMonthlyDisposable] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [goBackButtonLoading, setGoBackButtonLoading] = useState(false);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingDisposableAmountTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/forbrug',
        stepTitle: 'onboarding - forbrug',
        event: 'onboarding',
      });
    }
  }, []);

  // add handler to go to next step on Enter key pressed
  useEffect(() => {
    if (monthlyDisposable) {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleNextStep();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [monthlyDisposable]);

  const handleNextStep = () => {
    setButtonLoading(true);
    dispatch(storeOnboardingData({ ...savedData, monthlyDisposable }));
    setShowPowerOfAttorney(true);
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title mt-1'>
        {strings.onboardingDisposableAmountTitle}
      </div>
      <div className='step-subtitle center bold mb-4 p-4'>
        <p>{strings.onboardingDisposableAmountText}</p>
      </div>
      <div className='mb-4'>
        <AmountSlider
          type='monthlyDisposable'
          setAmount={setMonthlyDisposable}
          defaultAmount={savedData?.monthlyDisposable}
          minAmount={0}
          maxAmount={10000}
        />
      </div>
      <CardFooter className='mb-4'>
        {strings.onboardingDisposableAmountGuide}
      </CardFooter>
      <div className='actions-buttons step-buttons'>
      <Button
          className='btn btn-blue btn-cancel goBackButton'
          disabled={goBackButtonLoading}
          onClick={() => {
            setGoBackButtonLoading(true);
            previousStep();
          }}
        >
          {goBackButtonLoading ? <ButtonLoader /> : strings.onboardingGoBack}
        </Button>
        <Button
          className='btn btn-blue btn-success'
          onClick={() => handleNextStep()}
          disabled={buttonLoading}
        >
          {buttonLoading ? <ButtonLoader /> : strings.onboardingContinue}
        </Button>
      </div>
    </CardBody>
  );
};

export default DisposableAmount;
