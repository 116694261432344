import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';

const EntityInformationTabs = ({ id, tabsContent, otherClass }) => {
  const [activeTab, setActiveTab] = useState();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    toggle('0');
  }, [id]);

  return (
    <div
      className={`tab-row custom-card custom-card-container custom-card-tabs card ${otherClass}`}
    >
      <div className='lead-options'>
        <div className='nav-tabs-section'>
          <Nav tabs className='justify-content-end'>
            {tabsContent.map(
              (tab, index) =>
                tab !== undefined && (
                  <NavItem key={index}>
                    <NavLink
                      className={activeTab === index.toString() ? 'active' : ''}
                      onClick={() => {
                        toggle(index.toString());
                        if (typeof tab?.clickCallback === 'function') {
                          tab.clickCallback();
                        }
                      }}
                    >
                      {tab.name}
                    </NavLink>
                  </NavItem>
                )
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabsContent.map(
              (tab, index) =>
                tab !== undefined && (
                  <TabPane key={index} tabId={index.toString()}>
                    <Row>
                      <Col>{tab.show && tab.getContent(activeTab, toggle)}</Col>
                    </Row>
                  </TabPane>
                )
            )}
          </TabContent>
        </div>
      </div>
    </div>
  );
};

EntityInformationTabs.propTypes = {
  id: PropTypes.number,
  tabsContent: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      show: PropTypes.bool,
      getContent: PropTypes.func.isRequired,
    })
  ).isRequired,
  otherClass: PropTypes.string,
};

EntityInformationTabs.defaultProps = {
  otherClass: '',
};

export default EntityInformationTabs;
