import { orderBy } from 'lodash';
import { requestAxios as axios, isAdmin } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import {
  SERVER_URL,
  GET_LOGS_BY_CREDIT_FACILITY,
  GET_COMPANY_CHAIN_LOGS,
  GET_LOGS_BY_COMPANIES,
} from '../constants';

export function getLogsCreditFacility(creditFacilityNumber) {
  return (dispatch, getState) => {
    const user = getState().user.user;
    if (isAdmin(user)) {
      axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/loanLog/creditFacility/${creditFacilityNumber}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          const responseData = orderBy(
            response.data,
            ['createdDate'],
            ['desc']
          );
          const logPayload = {
            creditFacilityNumber,
            data: responseData,
          };

          dispatch({
            type: GET_LOGS_BY_CREDIT_FACILITY,
            payload: logPayload,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({
      type: GET_LOGS_BY_CREDIT_FACILITY,
      payload: false,
    });
  };
}

export function getCompanyChainLogs(companiId) {
  return (dispatch, getState) => {
    const user = getState().user.user;
    if (isAdmin(user)) {
      axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/log/companyChain/${companiId}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          const responseData = orderBy(
            response.data,
            ['createdDate'],
            ['desc']
          );
          const logPayload = {
            companiId,
            data: responseData,
          };

          dispatch({
            type: GET_COMPANY_CHAIN_LOGS,
            payload: logPayload,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({
      type: GET_COMPANY_CHAIN_LOGS,
      payload: false,
    });
  };
}

export function getLogsforCompanies(referenceNumber) {
  return (dispatch, getState) => {
    const user = getState().user.user;
    if (isAdmin(user)) {
      axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/getLogsForCompany/${referenceNumber}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          const responseData = orderBy(
            response.data,
            ['createdDate'],
            ['desc']
          );
          const logPayload = {
            referenceNumber,
            data: responseData,
          };

          dispatch({
            type: GET_LOGS_BY_COMPANIES,
            payload: logPayload,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({
      type: GET_LOGS_BY_COMPANIES,
      payload: false,
    });
  };
}
