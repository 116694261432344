import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { strings } from '../../constants/localization';
import { emailValidation } from '../../constants/utils';
import { changePassword } from '../../actions/userActions';
import ServerModalError from '../common/ServerModalError';
import { ReactComponent as EnvelopeIcon } from '../../resources/icons/envelope-alt.svg';

const ForgotPassword = ({ switchToLogin, setResetPassordEmail }) => {
  const dispatch = useDispatch();
  const [focusedField, setFocusedField] = useState(null);
  const serverError = useSelector((state) => state.ui?.serverError);

  // detect if the user are using a IOS device then prevent the zoom in/out
  useEffect(() => {
    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  /*  const updateOnError = () => {
    setSuccessSubmit(false);
    setErrorVisible(true);

    setTimeout(() => {
      setErrorVisible(false);
      formik.setFieldTouched('username', false);
      formik.setFieldValue('username', '');
    }, 5000);
  }; */

  const submitForm = (values) => {
    const username = values.username;
    if (username) {
      dispatch(changePassword(username))
        .then((result) => {
          if (!Object.prototype.hasOwnProperty.call(result, 'error')) {
            setResetPassordEmail(formik.values.username);
            switchToLogin();
          }
        })
        .catch(() => {});
    }
  };

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validateOnMount: true,
    validate: (values) => {
      console.log('valiudate', values);
      const errors = {};
      const { username } = values;
      if (!username) {
        errors.username = strings.required;
      } else if (emailValidation(username)) {
        errors.username = strings.validUsername;
      }
      return errors;
    },
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  console.log('errors', formik.errors);

  return (
    <>
      <div
        id={`${process.env.REACT_APP_BRAND}`}
        className='user-login forgot-password'
      >
        <div className='form-wrapper'>
          <form
            onSubmit={formik.handleSubmit}
            id='forgotPasswordForm'
            className='cf'
          >
            <h2>{strings.forgotPasswordHeadline}</h2>
            <p>{strings.forgotPasswordParagraph}</p>
            {/* USERNAME */}
            <div
              className={`form-group username ${
                formik.touched.username && formik.errors.username
                  ? 'has-error'
                  : ''
              }`}
            >
              <EnvelopeIcon />
              <label htmlFor='username'>{strings.username}</label>
              <input
                type='text'
                id='username'
                className={`form-group borderRadius-30 ${
                  focusedField === 'username' ? 'active' : ''
                }`}
                name='username'
                placeholder={strings.username}
                onChange={formik.handleChange}
                onBlur={() => setFocusedField(null)}
                onFocus={() => setFocusedField('username')}
                value={formik.values.username}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='text-error'>{formik.errors.username}</div>
              )}
            </div>

            <button type='submit'>{strings.submitForm}</button>
          </form>
        </div>
      </div>
      {serverError && serverError === 400 && <ServerModalError />}
    </>
  );
};

export default ForgotPassword;
