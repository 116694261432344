import React from 'react';
import './style.scss';
import { strings } from '../../../constants/localization';
import { brandConfig } from '../../../config';

function Step4() {
  return (
    <div className='Step4 section'>
      <div className='title'>{strings.userRegistrationThatsIt}</div>
      <div className='content'>
        <p>{strings.userRegistrationReadyPaiments}</p>
        <p>
          {strings.userRegistrationSetpGuide}{' '}
          {process.env.REACT_APP_BRAND === 'Sundhedplus' && (
            <a
              href={brandConfig.userRegistrationWelcomePage}
              target='_blank'
              rel='noopener noreferrer'
            >
              {strings.userRegistrationSetpGuideLink}
            </a>
          )}
        </p>
        <p>{strings.userRegistrationReadyToHelp}</p>
      </div>
    </div>
  );
}

export default Step4;
