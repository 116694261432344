import {
  GET_PATIENT_INFORMATION,
  LOADING_PATIENT_INFORMATION,
  PATIENT_FOUNDED,
  PATIENT_NOT_FOUND,
  SET_SHOW_SMS_MODAL_FOR_ACCEPTANCE_INVOICE_FLOW,
  SET_SHOW_SMS_MODAL_FOR_REACTIVATION_CREDIT,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_PATIENT_INFORMATION:
      return {
        ...state,
        patientInformation: action.payload,
      };
    case LOADING_PATIENT_INFORMATION:
      return {
        ...state,
        loadingPatientInformation: action.payload,
      };
    case PATIENT_NOT_FOUND:
      return {
        ...state,
        patientNotFound: action.payload,
      };
    case PATIENT_FOUNDED:
      return {
        ...state,
        patientFounded: action.payload,
      };
    case SET_SHOW_SMS_MODAL_FOR_ACCEPTANCE_INVOICE_FLOW:
      return {
        ...state,
        showSmsModalForAcceptanceInvoiceFlow: action.payload,
      };
    case SET_SHOW_SMS_MODAL_FOR_REACTIVATION_CREDIT:
      return {
        ...state,
        showSmsModalForReactivationCredit: action.payload,
      };
    default:
      return state;
  }
}
