import React from 'react';
import { moment } from '../../constants';

import { strings } from '../../constants/localization';
import './style.scss';
import { TimeSlot } from './TimeSlot';

export default function PatientInformation(props) {
  const { patientInformation } = props;
  const { timeSlot, requestedDates } = patientInformation;

  return (
    <div className='container-booking'>
      <div className='container-content'>
        <div className='header-name'>
          <span className='d-flex justify-content-center col-md-12'>
            {props.patientInformation.name}
          </span>
        </div>
        {props.patientInformation.referral && (
          <div className='referralTrue'>
            <span className='referral-position'>
              <i className=' lnil lnil-star' />
              <span className='referral-text'>{strings.referralTrue}</span>
            </span>
          </div>
        )}
        {!props.patientInformation.referral && (
          <div className='referralFalse'>
            <span className='referral-position'>
              <i className=' lnil lnil-cross-circle notReferral' />
              <span className='referral-text'>{strings.referralFalse}</span>
            </span>
          </div>
        )}
        <div className='text-hr mb-4'>
          <span className='text-hr__text' />
        </div>
        <div className='contact d-flex'>
          <div className='emailAndTlf '>{strings.email}</div>
          <div className='contact-info d-inline '>
            <a
              className='color'
              href={`mailto:${props.patientInformation.email}`}
            >
              {props.patientInformation.email}
            </a>
          </div>
        </div>
        <div className='contact d-flex '>
          <div className='emailAndTlf '>{strings.labelTLF}</div>
          <div className='contact-info d-inline'>
            {' '}
            <a
              className='color'
              href={`tel:${props.patientInformation.phone.replace(/\s/g, '')}`}
            >
              {props.patientInformation.phone}
            </a>
          </div>
        </div>
        <div className='text-hr mt-4'>
          <span className='text-hr__text' />
        </div>
        <div className='info-header upper-case'>{strings.labelComment}</div>
        <div className='booking-comment'>
          {props.patientInformation.requestText}
        </div>

        <TimeSlot timeSlot={timeSlot} requestedDates={requestedDates} />
      </div>
      <div className='footer-info'>
        <span>
          {`${moment(props.patientInformation.createdDate, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )}`}
        </span>

        {strings.time_abbreviation}
        <span>
          {`${moment(props.patientInformation.createdDate).format('HH:mm')}`}
        </span>
      </div>
    </div>
  );
}
