import {
  requestAxios as axios,
  getLoggedInUserDetails,
} from '../constants/utils';
import { strings } from '../constants/localization';

import { store } from '../store';
import {
  SERVER_URL,
  CHANGE_PASSWORD,
  moment,
  LOG_OUT,
  LOG_IN,
  GET_USER,
  SET_USER,
  SET_USER_CLEAN,
  AUTH_USER,
  LANGUAGE_UPDATE,
  ERROR_AUTH_USER,
  ERROR_USER_AUTHORITY,
  RESET_PASSWORD,
  USER_LOCKDOWN,
  UPDATE_CARD_DETAILS,
  SEND_SUBSCRIPTION_DATA,
  GET_ALL_ADMINS,
  GET_PENDING_USER,
  ACTIVATE_USER,
  REINVITE_USER,
  DELETE_USER,
  UPDATE_USER_INFO,
  SEND_USER_INVITATION,
} from '../constants';
import {
  setServerModalError,
  toggleServerModalError,
} from './uiElementsActions';

function escapeNonLatin(user) {
  const encodeUser = {};
  encodeUser.username = decodeURIComponent(encodeURIComponent(user.username));
  encodeUser.password = decodeURIComponent(encodeURIComponent(user.password));

  return encodeUser;
}

export function getLoggedInUserCredentials() {
  let userObj;
  let loggedInUser;
  const userCredentials = store.getState().user.userClean;
  if (userCredentials !== undefined) {
    loggedInUser = escapeNonLatin(userCredentials);
  } else {
    userObj = JSON.parse(localStorage.getItem('user'));
    if (userObj) {
      loggedInUser = escapeNonLatin(userObj);
    }
  }
  return loggedInUser;
}

export function getUser(user) {
  const encodeUser = escapeNonLatin(user);
  return (dispatch) =>
    axios
      .get(`${SERVER_URL}/api/v3/user`, {
        withCredentials: true,
        auth: encodeUser,
      })
      .then((response) => {
        if (response.status === 200) {
          const userData = JSON.stringify(response.data);
          // if the user has authority/companyRoles proceed
          if (
            response.data?.companyRoles &&
            Object.keys(response.data?.companyRoles).length > 0
          ) {
            localStorage.setItem('isAuth', true);
            localStorage.setItem('user', userData);
            localStorage.setItem('loginTime', moment.now());
            dispatch({
              type: GET_USER,
              payload: response.data,
            });
            dispatch(setAuthUser(true));
          } else {
            localStorage.setItem('isAuth', false);
            //user has no authority/roles
            dispatch(setUserAuthority(false));
            dispatch(setAuthUser(false));
          }
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response !== null &&
          error.response?.status === 403
        ) {
          // user has no authority/roles
          dispatch(setUserAuthority(false));
          dispatch(toggleServerModalError(false));
        }
        localStorage.setItem('isAuth', false);
        dispatch(setAuthUser(false));
      });
}

export function setUserFromStorage() {
  const userCredentials = getLoggedInUserDetails();

  return {
    type: SET_USER,
    payload: userCredentials,
  };
}

export function setCleanUserData(data) {
  return {
    type: SET_USER_CLEAN,
    payload: data,
  };
}

export function setUserLanguage(language) {
  if (language !== undefined) {
    // change language here
    strings.setLanguage(language);
  }

  return {
    type: LANGUAGE_UPDATE,
  };
}

export function changePassword(username) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/changePassword/username=${username}/`,
  });

  return {
    type: CHANGE_PASSWORD,
    payload: request,
  };
}

export function resetPassword(requestData) {
  const request = axios({
    method: 'post',
    data: requestData,
    url: `${SERVER_URL}/changepassword`,
  });

  return {
    type: RESET_PASSWORD,
    payload: request,
  };
}

export function reinviteUser(username) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/user/reinvite/${username}/`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: REINVITE_USER,
    payload: request,
  };
}

export function deleteUser(username) {
  const request = axios({
    method: 'delete',
    url: `${SERVER_URL}/api/v1/delete/${username}/`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: DELETE_USER,
    payload: request,
  };
}

export function userLogout(showSessionExpiredWarning = false) {
  return (dispatch, getState) => {
    const userIsAuth = getState().user.isAuth;

    if (userIsAuth) {
      return axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/logout`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      }).then((response) => {
        if (response.status === 200) {
          localStorage.removeItem('isAuth');
          localStorage.removeItem('user');
          localStorage.removeItem('loginTime');

          if (showSessionExpiredWarning) {
            localStorage.setItem('showSessionExpiredWarning', true);
          }
          setUserLanguage('da');
          dispatch(setAuthUser(false));
          dispatch({
            type: LOG_OUT,
            payload: '',
          });
        }
      });
    } else {
      console.log('user is not authenticated');
    }

    return false;
  };
}

export function userLogin(requestData) {
  return (dispatch) =>
    axios({
      method: 'post',
      url: `${SERVER_URL}/login`,
      data: requestData,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUser(requestData));
          dispatch(setCleanUserData(requestData));
          dispatch({
            type: LOG_IN,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.response !== null && error.response?.status === 423) {
            dispatch(setUserLockdown(error.response.data));
            dispatch(errorAuthUser(false));
          }
          if (error.response !== null && error.response?.status === 403) {
            dispatch(setServerModalError(error.response));
            dispatch(errorAuthUser(false));
          }
          dispatch(setServerModalError(error.response));
        }
        if (!error.response || error.message === 'Network Error') {
          dispatch(setServerModalError(error.message));
        } else if (error.response) {
          dispatch(setServerModalError(error.response));
        }

        localStorage.setItem('isAuth', false);
        dispatch(errorAuthUser(true));
      });
}

export function setAuthUser(isAuth = true) {
  return {
    type: AUTH_USER,
    payload: isAuth,
  };
}

export function setUserAuthority(hasAuthority = false) {
  return {
    type: ERROR_USER_AUTHORITY,
    payload: hasAuthority,
  };
}

export function errorAuthUser(hasError = false) {
  return {
    type: ERROR_AUTH_USER,
    payload: hasError,
    data: {
      message: strings.loginWrongBrand,
    },
  };
}

export function setUserLockdown(data) {
  return {
    type: USER_LOCKDOWN,
    payload: data,
  };
}

export function getSessionReepay(customerHandle, subscriptionHandle) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/public/api/v1/reepay/session/getSeesionInformation/${customerHandle}/${subscriptionHandle}`,
  });
  return {
    type: UPDATE_CARD_DETAILS,
    payload: request,
  };
}

export function sendSubscriptionData(data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/api/v1/reepay/subscription/payment_method`,
    data,
  });
  return {
    type: SEND_SUBSCRIPTION_DATA,
    payload: request,
  };
}

export function getAllAdmins() {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/api/v1/getAllAdminUsers`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });

  return {
    type: GET_ALL_ADMINS,
    payload: request,
  };
}

export function updateUserInfo(data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v3/changeUserInfo`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data,
  });

  return {
    type: UPDATE_USER_INFO,
    payload: request,
  };
}

export function sendUserInvitation(data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/user/invite`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data,
  });

  return {
    type: SEND_USER_INVITATION,
    payload: request,
  };
}
export function getPendingUser(uuid, username) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/getPendingUser`,
    data: { uuid, username },
  });
  return {
    type: GET_PENDING_USER,
    payload: request,
  };
}

export function activateUser(data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/activateUser`,
    data,
  });
  return {
    type: ACTIVATE_USER,
    payload: request,
  };
}
