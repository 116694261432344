import React from 'react';
import { Button } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { strings } from '../../constants/localization';
import { currencyFormatDA, formatInterestRate } from '../../constants/utils';
import { moment } from '../../constants';
import TermsConditions from './TermsConditions';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';

const StepThree = (props) => {
  const { processData } = props;

  const continueStep = () => {
    props.nextStep();
  };

  const rejectStep = () => {
    props.rejectLoan();
  };
  return (
    <div className='step-content step-three'>
      <h1 className='step-title'>{strings.step3Title}</h1>
      <div className='agreement-information'>
        <Scrollbars
          // eslint-disable-next-line
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          // eslint-disable-next-line
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...thumbVerticalStyle,
              }}
            />
          )}
        >
          <div className='scrollbar-wrapper'>
            <div className='title-agreement'>
              <p>{strings.titleAgreement}</p>
              <p>{strings.subTitleAgreement}</p>
            </div>
            <div className='number-agreement'>
              {`${strings.numberAgreement} ${props.acceptLoan.loanNumber}`}
            </div>
            <div className='agreement-details'>
              <div>
                <div>{strings.loanAmountAgreement}</div>
                <div>
                  {currencyFormatDA(processData.loanAmount, true, true)}
                </div>
              </div>
              <div>
                <div>{strings.acceptLoanDuration}</div>
                <div>
                  {strings.formatString(
                    strings.restMonths,
                    processData.duration
                  )}
                </div>
              </div>
              <div>
                <div>{strings.acceptSetupFee}</div>
                <div>{currencyFormatDA(processData.setupFee, true, true)}</div>
              </div>
              <div>
                <div>{strings.acceptInterestRate}</div>
                <div>{formatInterestRate(processData?.interestRate)}</div>
              </div>
              <div>
                <div>{strings.acceptLoanApr}</div>
                <div>{formatInterestRate(processData?.apr.toFixed(2))}</div>
              </div>
              <div>
                <div>{strings.acceptTotalCreditCost}</div>
                <div>
                  {currencyFormatDA(processData.totalCreditCost, true, true)}
                </div>
              </div>
              <div>
                <div>{strings.totalRepayment}</div>
                <div>
                  {currencyFormatDA(processData.totalRepayment, true, true)}
                </div>
              </div>
              <div>
                <div>{strings.establishmentDate}</div>
                <div>
                  {moment(
                    processData.dateEstablishmentFee,
                    'DD-MM-YYYY'
                  ).format('DD-MM-YYYY')}
                </div>
              </div>
              <div>
                <div>{strings.acceptMonthlyPayment}</div>
                <div>
                  {currencyFormatDA(processData.monthlyPayment, true, true)}
                </div>
              </div>
              <br />
              <div>
                <div>{strings.firstInstallmentDate}</div>
                <div>
                  {moment(
                    processData.firstInstallmentDate,
                    'DD-MM-YYYY'
                  ).format('DD-MM-YYYY')}
                </div>
              </div>
              <div>
                <div>{strings.lastInstallmentDate}</div>
                <div>
                  {moment(processData.lastInstallmentDate, 'DD-MM-YYYY').format(
                    'DD-MM-YYYY'
                  )}
                </div>
              </div>
            </div>
            <TermsConditions
              bnpl={processData.interestRate === 0 ? true : false}
            />
          </div>
        </Scrollbars>
      </div>
      <div className='step-actions'>
        <Button color='light' className='reject' onClick={() => rejectStep()}>
          {strings.declineLoan}
        </Button>
        <Button
          color='blue'
          className='continue'
          onClick={() => continueStep()}
        >
          {strings.acceptStep}
        </Button>
      </div>
    </div>
  );
};

export default StepThree;
