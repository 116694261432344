import React from 'react';
import { strings } from '../../../constants/localization';

const ActiveFilters = ({ filterTags, removeFilter }) => {
  return (
    <div className='active-filters-section'>
      {/* CLEAR FILTERS BUTTON  */}
      {filterTags?.length > 0 && (
        <div
          className='badge badge-filter clear-filters'
          onClick={() => removeFilter('all')}
        >
          {strings.clearAllFilters}
        </div>
      )}

      {/* ACTIVE FILTERS */}
      {filterTags?.map((tag, index) => (
        <div className='badge badge-filter has-tooltip bottom' key={index}>
          <span className='close-action' onClick={() => removeFilter(tag)}>
            <i className='di di-close' />
          </span>
          <span>{tag?.label}</span>
          <span className='tooltip'>
            {strings.deleteFilterText} {tag?.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ActiveFilters;
