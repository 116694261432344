import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import addPatientWarning from '../../resources/images/add-patient_warning.png';

const StepNotFound = (props) => {
  return (
    <div className='step-content step-final'>
    <div className='step-header'>
      <img src={addPatientWarning} alt='add patient warning' className='image-status' />
      <div className='title-text'>
        {strings.apNotFoundTitle}
      </div>
    </div>
    <div className='step-body'>
      <div className='text-information'>
        <p>
          {strings.formatString(strings.apNotFoundText1, <b>{props.stateInfo.clientCpr}</b>, <b>{props.stateInfo.clientName}</b>)}
          <br />
          {strings.apNotFoundText2}
        </p>
        <p>
          {strings.apNotFoundText3}
          <br />
          <Button color='link' className='btn-app-link' onClick={() => props.nextStep({ appLink: true })}>
            {strings.apNotFoundLink}
          </Button>
        </p>
      </div>
      <div className='button-actions'>
        <Button color='blue' onClick={() => props.nextStep()}>
          {strings.buttonTextTryAgain}
        </Button>
      </div>
    </div>
  </div>
  );
};

export default StepNotFound;
