import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';

const UploadSuccess = ({ closeModal }) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-status-modal success'
      modalClassName='upload-modal'
      close={() => closeModal()}
      closeBtn={false}
    >
      <div className='content-wrapper success d-flex align-items-center justify-content-center'>
        <h3 className='headline'>{strings.onboardingUploadSuccessHeadline}</h3>
        <div className='status-icon'>
          <i className='di di-circle-check' />
        </div>
        <div className='subtitle'>
          {strings.onboardingUploadSuccessSubtitle}
        </div>

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => closeModal()}
          >
            {strings.close}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default UploadSuccess;
