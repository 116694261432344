import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody } from 'reactstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Fade from '../common/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { strings } from '../../constants/localization';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { getCompaniesForCompanyChain } from '../../actions/onboardingActions';
import { ROUTE_ONBOARDING } from '../../constants/routes';

const SmartLinksPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companiesForCompanyChain = useSelector(
    (state) => state.onboarding?.companiesForCompanyChain
  );
  const [tag, setTag] = useState('');
  const [companyChainId, setCompanyChainId] = useState('');
  const [clinicsListForCompanyChain, setClinicsListForCompanyChain] = useState(
    []
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [wrongCompanyChain, setWrongCompanyChain] = useState(false);

  useEffect(() => {
    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    document.body.classList.add('onboarding-flow');
    const url = new URL(window.location.href);
    const tagFromUrl = url.searchParams.get('tag') || 'sundhedplus.dk';
    const companyChainIdFromUrl = url.searchParams.get('companyChainId') || '';
    setTag(tagFromUrl);
    setCompanyChainId(companyChainIdFromUrl);
    // if the chainId param from url is missing redirect to onboarding without params
    if (companyChainIdFromUrl === '') {
      history.push({
        pathname: ROUTE_ONBOARDING,
      });
    }
    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
      document.body.classList.add('onboarding-flow');
    };
  }, []);

  useEffect(() => {
    if (companyChainId) {
      dispatch(getCompaniesForCompanyChain(companyChainId)).catch(() => {
        setWrongCompanyChain(true);
        setClinicsListForCompanyChain([]);
      });
    }
  }, [companyChainId]);

  useEffect(() => {
    if (
      companiesForCompanyChain &&
      companiesForCompanyChain?.companyPublicContentList?.length > 0
    ) {
      const companies = companiesForCompanyChain?.companyPublicContentList;
      const mappedCompanies = companies.map((item) => ({
        label: item.companyName,
        value: item.referenceNumber,
      }));
      setClinicsListForCompanyChain(mappedCompanies);
    }
  }, [companiesForCompanyChain]);

  return (
    <div id='onboarding-flow' className='flow-wrapper onboarding'>
      <TransitionGroup
        appear
        timeout={600}
        className={`slide-group slide-enter`}
      >
        <CSSTransition
          key={1}
          appear
          timeout={300}
          classNames='slide'
          className='transition-container'
        >
          <Fade show={true}>
            <div className='sundhed-wrapper'>
              <div className='sundhed-plus-logo'>
                <img
                  className='sundhed-logo'
                  src={sundhedLogo}
                  alt='Sundhed+'
                />
              </div>
              <Card className='wrapper card'>
                <div className='step smartlink'>
                  <CardBody className='step-content card-body'>
                    <div className='step-title'>
                      {strings.onboardingSmartlinkHeadline}
                    </div>
                    <div className='center font-18'>
                      <p>
                        {strings.onboardingSmartlinkSubtitle}
                        <br />
                        {strings.onboardingSmartlinkSelect}
                      </p>
                    </div>
                    {!wrongCompanyChain && (
                      <div className='select-wrapper'>
                        <div className='form-group'>
                          <Select
                            className='custom_select'
                            classNamePrefix='custom_select'
                            options={clinicsListForCompanyChain}
                            placeholder={strings.chainSelectLocation}
                            value={selectedCompany}
                            onChange={(clinic) => {
                              setSelectedCompany(clinic);
                              setShowErrorMessage(false);
                            }}
                            menuPlacement='auto'
                            maxMenuHeight={250}
                          />
                          {showErrorMessage && (
                            <div className='error'>
                              <p>{strings.onboardingSmartlinkNextError}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {wrongCompanyChain && (
                      <div className='error'>
                        <p>{strings.onboardingSmartlinkWrongCompanyChain}</p>
                      </div>
                    )}

                    <div className='actions-buttons step-buttons'>
                      <Button
                        className='btn btn-blue'
                        value
                        disabled={buttonLoading}
                        onClick={() => {
                          if (!selectedCompany) {
                            setShowErrorMessage(true);
                          } else {
                            const refNumber = selectedCompany?.value;
                            setButtonLoading(true);
                            if (refNumber) {
                              history.push({
                                pathname: ROUTE_ONBOARDING,
                                search: `?tag=${tag}&companyId=${refNumber}`,
                              });
                            } else {
                              history.push({
                                pathname: ROUTE_ONBOARDING,
                              });
                            }
                          }
                        }}
                      >
                        {buttonLoading ? (
                          <ButtonLoader />
                        ) : (
                          strings.onboardingSmartlinkNext
                        )}
                      </Button>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </div>
          </Fade>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default SmartLinksPage;
