import React, { useRef, useState, useEffect } from 'react';
import { moment, momentFormat } from '../../../constants';
import { strings } from '../../../constants/localization';
import { applicationStatusMap } from '../../../constants/mappings';

const ActiveFiltersDisplayApplication = (props) => {
  const { creditApplicationStatus, dateLower, dateUpper } =
    props.filterParams || {};
  const scrollFilterRef = useRef();
  let isDown = false;
  let startX = 0;
  let scrollLeft = 0;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(props.filterParams).length > 0) {
      setLoaded(true);
    }
  }, [props]);

  // handle horizontal slide on
  const handleMouseDown = (event) => {
    const slider = scrollFilterRef.current;
    isDown = true;
    // slider.classList.add('active');
    startX = event.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
  };

  const handleMouseUp = () => {
    isDown = false;
  };

  const handleMouseMove = (event) => {
    const slider = scrollFilterRef.current;
    if (!isDown) return;
    event.preventDefault();
    const offsetX = event.pageX - slider.offsetLeft;
    const walk = (offsetX - startX) * 2; // scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className='active-filters-container'>
      {loaded && (
        <div
          className='active-filters'
          ref={scrollFilterRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          {creditApplicationStatus && (
            <div className='badge badge-filter' key={creditApplicationStatus}>
              <span
                className='close-action'
                onClick={() => props.removeFilterStatus()}
              >
                <i className='di di-close' />
              </span>
              <span>
                {
                  strings[
                    applicationStatusMap[creditApplicationStatus].statusText
                  ]
                }
              </span>
            </div>
          )}

          {dateLower && dateUpper && (
            <div className='badge badge-filter'>
              <span className='close-action' onClick={() => props.clearDate()}>
                <i className='di di-close' />
              </span>
              <span>{`${moment(dateLower).format(momentFormat)} - ${moment(
                dateUpper
              ).format(momentFormat)}`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActiveFiltersDisplayApplication;
