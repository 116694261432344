import React from 'react';

import { strings } from '../../../constants/localization';
import './InvoiceSuccess.scss';
import { ReactComponent as IconAddPatientSuccess } from '../../../resources/images/checkmark_circle.svg';

export const InvoiceSuccess = ({ invoiceAmount = 0, brand = 'Denti'}) => (
  <div className='invoice_Success_Component'>

    <IconAddPatientSuccess className='success_icon' />

    <h2 className='invoice_title'>{strings.newInvoiceSuccessTitle}</h2>

    <div className='invoice_subtitle'>
      {strings.newInvoiceSuccessSubtitle}
      ...
    </div>

    <div className='invoice_steps'>

      <div className='invoice_step'>
        <div className='step'>1</div>
        <div>{strings.stepOne}</div>
      </div>

      <div className='invoice_step'>
        <div className='step'>2</div>
        <div>{strings.formatString(strings.stepTwo, <b>{invoiceAmount}</b>, brand)}</div>
      </div>

      <div className='invoice_step'>
        <div className='step'>3</div>
        <div>{strings.stepThree}</div>
      </div>
    </div>

    <div className='invoice_note'>
      {strings.newInvoiceSuccessNote}
    </div>
  </div>
)
