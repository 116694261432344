import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { strings } from '../../../constants/localization';
import {
  setCurrentChain,
  getChainLocations,
} from '../../../actions/companyChainActions';

const ChainListItem = (props) => {
  const { chain, active } = props;
  const dispatch = useDispatch();
  let logoStatus = 'grey';
  if (chain.status === 'ACTIVE' || chain.status === 'SMARTLINK') {
    logoStatus = 'green';
  }

  return (
    <>
      {chain && (
        <ListGroupItem
          active={active}
          onClick={() => {
            dispatch(setCurrentChain(chain));
            dispatch(getChainLocations(chain?.id));
          }}
        >
          <div
            className={`${
              active ? 'active' : ''
            } single-list-item company-details`}
          >
            <div className='logo'>
              <div className={`d-logo logo-${logoStatus}`}>
                {(chain.brand === 'Denti' || chain.brand === null) && (
                  <i className='di di-denti' />
                )}
                {chain.brand === 'Sundhed+' && (
                  <i className='di di-sundhedplus' />
                )}
              </div>
            </div>
            <div className='chain-data'>
              <ListGroupItemHeading>
                {`${chain.companyChainName} `}
              </ListGroupItemHeading>
              <ListGroupItemText tag='div'>
                {`CVR ${chain.cvrNumber ? chain.cvrNumber : '-'} | ${
                  chain.status === 'PASSIVE'
                    ? strings.PASSIVE_OUT_OF_COMPONENT_STATUS
                    : strings[chain.status]
                } `}
              </ListGroupItemText>
            </div>
            <div className='info-Chain'>
              <i className='lnir lnir-house-heart' />
              <span className='chain-count'>{chain.companies.length}</span>
            </div>
          </div>
        </ListGroupItem>
      )}
    </>
  );
};

ChainListItem.propTypes = {
  chain: PropTypes.object,
  active: PropTypes.bool,
  setCurrentChain: PropTypes.func,
};

export default ChainListItem;
