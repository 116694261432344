import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import DentiModal from '../common/DentiModal';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { clearOnboardingData } from '../../actions/onboardingActions';
import { ReactComponent as InfoCircle } from '../../resources/icons/info-circle.svg';

const RestartOnboardingModal = ({ closeModal, setNextStep }) => {
  const dispatch = useDispatch();
  const [loadingRestart, setLoadingRestart] = useState(false);
  const visitedSteps = useSelector((state) => state.onboarding.visitedSteps);
  const lastStepStored =
    visitedSteps && visitedSteps.length > 0
      ? visitedSteps[visitedSteps.length - 1]
      : 0;
  let timeoutUntilRestart = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutUntilRestart.current);
    };
  }, []);

  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-doc-modal'
      modalClassName='upload-modal'
      close={() => closeModal()}
      closeBtn={true}
    >
      <div className='restart-application-modal  d-flex align-items-center justify-content-center'>
        <div className='restart-application-icon'>
          <InfoCircle className='info-circle' />
        </div>
        <div className='px-2'>
          <h3 className='headline'>
            {strings.onboardingRestartApplicationHeadline}
          </h3>
          <div className='subtitle mt-5 mb-5'>
            {strings.onboardingRestartApplicationText}
          </div>
        </div>

        <div className='actions-buttons'>
          <Button
            className='btn btn-blue btn-cancel'
            onClick={() => {
              setLoadingRestart(true);
              timeoutUntilRestart.current = setTimeout(() => {
                dispatch(clearOnboardingData());
                // clear the localStorage from the confirmEmail
                localStorage.removeItem('emailConfirmedByTyping');
                localStorage.removeItem('emailConfirmedByLink');
                setLoadingRestart(false);
                closeModal();
                setNextStep(1);
              }, 1000);
            }}
          >
            {loadingRestart ? (
              <ButtonLoader />
            ) : (
              strings.onboardingRestartApplicationCancel
            )}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => {
              timeoutUntilRestart.current = setTimeout(() => {
                setNextStep(lastStepStored);
                closeModal();
              }, 1000);
            }}
          >
            {strings.onboardingRestartApplicationSuccess}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default RestartOnboardingModal;
