import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';

const StepFour = (props) => {
  const visitedBefore = (flag) => {
    props.nextStep({
      firstVisit: flag,
    });
  };
  return (
    <div className='step-content step-four'>
      <h1 className='step-title'>
        {strings.step4Title}
        <br />
        {props.acceptLoan.companyInvoiceName}
        ?
      </h1>
      <div className='visit-details'>
        {strings.firstVisitText}
      </div>
      <div className='visit-action-buttons'>
        <Button color='blue' onClick={() => visitedBefore(true)}>
          {strings.yesVisit}
        </Button>
        <Button color='blue' onClick={() => visitedBefore(false)}>
          {strings.noVisit}
        </Button>
      </div>
    </div>
  );
};
export default StepFour;
