import React from 'react';
import IframeResizer from 'iframe-resizer-react';

const TermsConditions = ({ link }) => (
  <div className='terms-conditions'>
    <IframeResizer
      title='denti-terms'
      checkOrigin={false}
      src={link}
      heightCalculationMethod='lowestElement'
      style={{
        width: '1px',
        minWidth: '100%',
        border: 0,
      }}
    />
  </div>
);

export default TermsConditions;
