import React, { useRef, useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyCorrectAddress } from '../../../actions/onboardingApplicationsActions';
import { toggleServerModalError } from '../../../actions/uiElementsActions';
import { strings } from '../../../constants/localization';
import { formatCurrencyDaStandard } from '../../../constants/utils';

const SectionExpandable = ({
  counter,
  label,
  value,
  childrenArray,
  carsArray,
  properties,
}) => {
  const dispatch = useDispatch();
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const sectionDescription = useRef(null);
  const collapsibleContent = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [correctAddressList, setCorrectAddressList] = useState([]);

  useEffect(() => {
    setIsOpen(false);
  }, [activeApplicationListItem]);

  useEffect(() => {
    setCorrectAddressList([]);
    if (properties && properties?.length > 0) {
      properties.forEach((property) => {
        getPropertyCorrectAddress(property?.address, property?.municipalityCode)
          .then((res) => {
            let finalAddress = '';
            if (res?.data && res.data?.length > 0) {
              finalAddress = res?.data[0]?.adgangsadresse?.adressebetegnelse;
            }

            setCorrectAddressList((prev) => [
              ...prev,
              finalAddress || property?.address,
            ]);
          })
          .catch(() => {
            setCorrectAddressList((prev) => [...prev, property?.address]);
            dispatch(toggleServerModalError(false));
          });
      });
    }
  }, [properties]);

  const renderBaseOnContent = () => {
    let content = '';
    if (carsArray && carsArray.length > 0) {
      content = (
        <div ref={collapsibleContent} className='collapsible-content cars'>
          {carsArray?.map((car, index) => (
            <div className='budget-entry' key={index}>
              <Label>{car?.CarType}</Label>
              <span>
                {car?.leasingAmount
                  ? formatCurrencyDaStandard(car?.leasingAmount)
                  : ''}
              </span>
            </div>
          ))}
        </div>
      );
    } else if (
      properties &&
      correctAddressList &&
      correctAddressList.length > 0
    ) {
      content = (
        <div
          ref={collapsibleContent}
          className='collapsible-content properties'
        >
          {correctAddressList?.map((property, index) => (
            <div className='budget-entry' key={index}>
              <Label>{label}</Label>
              <span>{property || '-'}</span>
            </div>
          ))}
        </div>
      );
    } else if (childrenArray && childrenArray.length > 0) {
      const getAgeLabel = (ageGroup) => {
        switch (ageGroup) {
          case 'ageGroup0To1':
            return strings.applicationChildrenAgeGroup1;
          case 'ageGroup2To6':
            return strings.applicationChildrenAgeGroup2;
          case 'ageGroup7To17':
            return strings.applicationChildrenAgeGroup3;
          default:
            return '';
        }
      };

      // Count occurrences of each unique string in the array
      const counts = childrenArray.reduce((acc, child) => {
        acc[child] = (acc[child] || 0) + 1;
        return acc;
      }, {});

      content = (
        <div ref={collapsibleContent} className='collapsible-content children'>
          {Object.entries(counts).map(([ageGroup, count]) => (
            <div key={ageGroup} className='budget-entry'>
              <Label>{getAgeLabel(ageGroup)}</Label>
              <span>{count}</span>
            </div>
          ))}
        </div>
      );
    } else if (value) {
      content = (
        <div ref={collapsibleContent} className='collapsible-content children'>
          <div className='budget-entry'>
            <Label>{label}</Label>
            <span>{value}</span>
          </div>
        </div>
      );
    } else {
      content = (
        <div ref={collapsibleContent} className='collapsible-content'>
          <div className='budget-entry'>
            <Label>{label}</Label>
            <span>{value || '-'}</span>
          </div>
        </div>
      );
    }
    return content;
  };

  return (
    <div className='section-item'>
      <div
        className='budget-entry expandable'
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Label>{strings.applicationBudgetQuantity}</Label>

        <span className='right'>
          {counter !== 0 ? <span className='counter'>{counter}</span> : '-'}
          {counter !== 0 && (
            <span className={`arrow ${isOpen ? 'active' : ''}`}></span>
          )}
        </span>
      </div>
      {counter !== 0 && (
        <div
          className='section-description'
          ref={sectionDescription}
          style={
            isOpen
              ? {
                  maxHeight:
                    (sectionDescription?.current?.scrollHeight || 0) +
                    (collapsibleContent?.current?.scrollHeight || 0),
                }
              : { maxHeight: '0px' }
          }
        >
          {renderBaseOnContent()}
        </div>
      )}
    </div>
  );
};

export default SectionExpandable;
