import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';

const DeleteConfirm = ({ file, closeModal, deleteFile }) => {
  return (
    <DentiModal
      key={1}
      close={() => closeModal()}
      modalClass='onboarding upload-doc-modal'
      modalClassName='upload-modal'
      closeBtn={true}
    >
      <div className='delete-file-modal d-flex align-items-center justify-content-around'>
        <h3 className='headline'>{strings.onboardingUploadDeleteHeadline}</h3>
        <div className='file-item'>
          <i className='lnir lnir-empty-file icon' />
          <div className='filename'>{file?.name}</div>
        </div>

        <div className='actions-buttons'>
          <Button
            className='btn btn-blue btn-cancel'
            onClick={() => closeModal()}
          >
            {strings.onboardingUploadDeleteNo}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => {
              deleteFile(file);
              closeModal();
            }}
          >
            {strings.onboardingUploadDeleteYes}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default DeleteConfirm;
