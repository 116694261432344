import {
  TOGGLE_MODAL_ERROR,
  SET_SERVER_MODAL_ERROR,
  INCREMENT_ONGOING_REQUESTS_COUNTER,
  DECREMENT_ONGOING_REQUESTS_COUNTER,
  OPEN_SEND_LINK_APPLICATION_FROM_WELCOME_PAGE,
} from '../constants';

const initialState = {
  ongoingRequestsCounter: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_ERROR:
      return { ...state, serverError: action.payload };
    case SET_SERVER_MODAL_ERROR:
      return { ...state, serverErrorDetails: action.payload };
    case INCREMENT_ONGOING_REQUESTS_COUNTER: {
      return {
        ...state,
        ongoingRequestsCounter: state.ongoingRequestsCounter + 1,
      };
    }
    case DECREMENT_ONGOING_REQUESTS_COUNTER:
      return {
        ...state,
        ongoingRequestsCounter: state.ongoingRequestsCounter - 1,
      };
    case OPEN_SEND_LINK_APPLICATION_FROM_WELCOME_PAGE:
      return {
        ...state,
        sendLinkApplicationFromWelcomePage: action.payload,
      };
    default:
      return state;
  }
}
