import React from 'react';
import { Row, Col } from 'reactstrap';

const ApplicationDataCard = ({ icon, headline, children }) => {
  return (
    <section className='entity-information-card custom-card card'>
      <div className='card-body'>
        <Row className='detailsArea'>
          <Col className='col-12 col-sm-12'>
            <div className='media application'>
              <div className='media-body'>
                <div className='media-body-title '>
                  <div className='logo media-left'>
                    <div className='d-logo'>{icon}</div>
                  </div>
                  <span className='heading'>{headline || '-'}</span>
                </div>
                <div className='media-body-content'>{children}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ApplicationDataCard;
