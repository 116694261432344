import React, { Component } from 'react';
import TransitionGroup from 'react-transition-group/CSSTransition';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

class DentiModal extends Component {
  constructor(props) {
    super(props);

    this.close = this.props.close;
  }

  render() {
    const modalClass = this.props.modalClass
      ? `${this.props.modalClass} modal-container denti-modal`
      : 'modal-container denti-modal';

    const child = (
      <div>
        <Modal
          isOpen
          toggle={this.close}
          className={modalClass}
          modalClassName={this.props.modalClassName}
          backdrop={this.props.backdrop}
          keyboard={this.props.keyboard}
          autoFocus={false}
        >
          {this.props.closeBtn && (
            <span className='close' onClick={this.close}>
              <i className='lnir lnir-close' />
            </span>
          )}
          <ModalBody>{this.props.children}</ModalBody>
          {this.props.footerCloseBtn && (
            <div className='button-actions-footerCloseBtn'>
              <Button
                color='blue'
                className='btn btn-cancel'
                onClick={this.close}
              >
                {this.props.footerCloseBtnText}
              </Button>
            </div>
          )}
        </Modal>
      </div>
    );

    return (
      <TransitionGroup appear exit timeout={600}>
        {child}
      </TransitionGroup>
    );
  }
}

DentiModal.propTypes = {
  close: PropTypes.func,
  modalClassName: PropTypes.string,
  modalClass: PropTypes.string,
  children: PropTypes.node,
  footerCloseBtnText: PropTypes.string,
  closeBtn: PropTypes.bool,
  footerCloseBtn: PropTypes.bool,
};

export default DentiModal;
