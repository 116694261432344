import { requestAxios as axios } from '../constants/utils';
import {
  SERVER_URL,
  GET_MIGRATING_CLIENT,
  GET_USER_DATA_FOR_CARD_UPDATE,
  CREATE_SUNHEDPLUS_USER_AND_GET_SESSION_ID,
  MIGRATE_USER_FROM_DENTI_TO_SUNDHED,
} from '../constants';

export function getMigratingClient(leadUuid, clientEmail) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/public/getMigratingClient?leadUuid=${leadUuid}&clientEmail=${clientEmail}`,
  });

  return {
    type: GET_MIGRATING_CLIENT,
    payload: request,
  };
}

export function getUserDataForCreditCardUpdate(leadUuid, clientEmail) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/public/getUserDataForCreditCardUpdate?leadUuid=${leadUuid}&clientEmail=${clientEmail}`,
  });

  return {
    type: GET_USER_DATA_FOR_CARD_UPDATE,
    payload: request,
  };
}

export function createSundhedplusUserAndGetSessionId(creditFacilityNumber) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/public/createSundhedplusUserAndGetSessionId/${creditFacilityNumber}`,
  });

  return {
    type: CREATE_SUNHEDPLUS_USER_AND_GET_SESSION_ID,
    payload: request,
  };
}

export function migrateUserFromDentiToSundhed(
  creditFacilityNumber,
  signupMethod,
  token
) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/reepay/migratePatientFromDentiToSundhed`,
    data: {
      creditFacilityNumber,
      installmentLoanNumber: null,
      signupMethod,
      token,
    },
  });

  return {
    type: MIGRATE_USER_FROM_DENTI_TO_SUNDHED,
    payload: request,
  };
}
