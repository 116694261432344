import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import '../resources/scss/bootstrap-extended.scss';
import '../resources/scss/style.scss'; // custom styles entry point

import { useSelector } from 'react-redux';
import { store } from '../store';

import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_NEW_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_UPDATE_CARD,
  ROUTE_ACCEPTANCE_LOAN,
  ROUTE_LIVE_AGENT,
  ROUTE_USER_REGISTRATION_FLOW,
  ROUTE_BOOKING_REQUEST,
  ROUTE_MIGRATE_CREDIT,
  ROUTE_UNSUBSCRIBE_BOOKING_REQUESTS,
  ROUTE_CLINIC_CLEAR_COOKIES,
  ROUTE_CLIENT_CLEAR_COOKIES,
  ROUTE_ONBOARDING,
  ROUTE_ONBOARDING_EXISTING_MEMBER,
  ROUTE_ONBOARDING_RENEW_CREDIT,
  ROUTE_SMARTLINK_ONBOARDING,
  ROUTE_LOAN_CALCULATOR,
  ROUTE_MIT_SUNDHEDPLUS,
  ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
  ROUTE_LINK_MISSING,
  ROUTE_START_CLIENT_PORTAL,
  ROUTE_MIT_SUNDHEDPLUS_HELP,
  ROUTE_MAIN_LOGIN,
  ROUTE_HOME,
  ROUTE_CLIENT_PARTIAL_REDEMPTION,
} from '../constants/routes';
import { isAuthenticated } from '../constants/utils';
import { setUserLanguage } from '../actions/userActions';
import CacheBusting from '../components/CacheBusting';
import UpdateCardDetails from '../components/credit-agreement/UpdateCardDetails';
import ForgotPassword from '../components/login/ForgotPassword';
import ResetPassword from '../components/login/ResetPassword';
import UserRegistrationFlow from '../components/UserRegistrationFlow/UserRegistrationFlow';
import LoanAcceptance from '../components/acceptance/LoanAcceptance';
import MigrateCreditFlow from '../components/migrate-credit/MigrateCreditFlow';
import BookingRequestPage from '../components/bookingRequest/BookingRequestPage';
import SupportPage from '../components/LiveAgent/SupportPage';
import UnsubscribeBookingRequest from '../components/bookingRequest/unsubscribe-page/UnsubscribeBookingRequest';
import ClearCookies from '../components/login/ClearCookies';
import LoanCalculator from '../components/loan-calculator/LoanCalculator';
import Onboarding from '../components/onboardFlow/Onboarding';
import OnboardingErrorBoundary from '../components/onboardFlow/OnboardingErrorBoundary';
import SmartLinksPage from '../components/onboardFlow/SmartlinksPage';
import PrivateRoute from './PrivateRoute';
import DAM from './DAM';

import MainLogin from '../components/login/MainLogin.tsx';

import SundhedplusMain from './mitSundhed/SundhedplusMain.tsx';
import { CriiptoVerifyProvider } from '@criipto/verify-react';
import LinkMissingPage from './common/LinkMissingPage';

import { config } from '../config';
import ClientPortalIntermediate from './login/ClientPortalIntermediate.tsx';
import PartialRedemptionMainComponent from './partialRedemption/PartialRedemptionMainComponent.tsx';

const App = () => {
  const currentDomain = window.location.hostname;
  const isLocalhost = currentDomain === 'localhost';
  const path = window.location.pathname;
  const url = new URL(window.location.href);
  const leadUuidFromUrl = url.searchParams.get('leadUuid');
  const creditExpirationDate = url.searchParams.get('creditExpirationDate');

  const mitIdState = useSelector((state) => state?.memberPortal?.mitIdState);

  const notClientPortalPath =
    path !== ROUTE_START_CLIENT_PORTAL &&
    path !== ROUTE_HOME &&
    path !== ROUTE_MIT_SUNDHEDPLUS &&
    path !== ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE;

  const [mitIdStateString, setMitIdStateString] = useState(null);

  useEffect(() => {
    if (!isAuthenticated()) {
      // set language - danish is default for public pages
      store.dispatch(setUserLanguage('da'));
      // save the liveagent redirect url in the session storage if the user is not authenticated
      const params = new URLSearchParams(window.location.search);
      const email = params.get('q');
      const pathname = window.location.pathname;
      if (email !== null) {
        sessionStorage.setItem('lastVisitedPath', `${pathname}?q=${email}`);
      }
    }
  }, []);

  useEffect(() => {
    if (mitIdState) {
      setMitIdStateString(mitIdState);
    }
  }, [mitIdState]);

  useEffect(() => {
    if (currentDomain === process.env.REACT_APP_CLIENT_PORTAL_DOMAIN) {
      if (notClientPortalPath) {
        window.location.pathname = ROUTE_HOME;
      }
    }
  }, [currentDomain, notClientPortalPath]);

  useEffect(() => {
    if (currentDomain === process.env.REACT_APP_FORNY_SUNDHEDPLUS) {
      // Get the current URL search parameters

      // Check if leadUuid is present
      if (leadUuidFromUrl) {
        // Construct the new URL before redirection
        const newUrl = `https://${process.env.REACT_APP_ONBOARDING_DOMAIN}/?tag=forny&companyId=X001&leadUuid=${leadUuidFromUrl}&creditExpirationDate=${creditExpirationDate}`;

        // Perform the redirection
        window.location.href = newUrl;
      } else {
        // If leadUuid is not present, redirect to the main kredit page
        const newUrl = `https://${process.env.REACT_APP_ONBOARDING_DOMAIN}`;

        // Perform the redirection
        window.location.href = newUrl;
      }
    }
  }, [history]);

  return (
    <CacheBusting>
      <BrowserRouter>
        <Switch>
          {/* PUBLIC ROUTES */}
          <Route
            exact
            path={ROUTE_FORGOT_PASSWORD || ROUTE_NEW_PASSWORD}
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path={ROUTE_RESET_PASSWORD}
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path={ROUTE_UPDATE_CARD}
            render={(props) => <UpdateCardDetails {...props} />}
          />
          <Route
            exact
            path={ROUTE_ACCEPTANCE_LOAN}
            render={(props) => <LoanAcceptance {...props} />}
          />
          <Route
            exact
            path={ROUTE_CLIENT_PARTIAL_REDEMPTION}
            render={(props) => <PartialRedemptionMainComponent {...props} />}
          />
          <Route
            exact
            path={ROUTE_LOAN_CALCULATOR}
            render={(props) => <LoanCalculator {...props} />}
          />

          <Route
            exact
            path={ROUTE_LIVE_AGENT}
            render={(props) => <SupportPage {...props} />}
          />
          <Route
            exact
            path={ROUTE_CLINIC_CLEAR_COOKIES}
            render={(props) => <ClearCookies {...props} />}
          />
          <Route
            exact
            path={ROUTE_CLIENT_CLEAR_COOKIES}
            render={(props) => <ClearCookies {...props} forClients={true} />}
          />
          <Route
            exact
            path={ROUTE_BOOKING_REQUEST}
            render={(props) => <BookingRequestPage {...props} />}
          />
          <Route
            exact
            path={ROUTE_UNSUBSCRIBE_BOOKING_REQUESTS}
            render={(props) => <UnsubscribeBookingRequest {...props} />}
          />
          <Route
            exact
            path={ROUTE_MIGRATE_CREDIT}
            render={(props) => <MigrateCreditFlow {...props} />}
          />

          {/* To be able to work at client portal using localhost we need to have this lines of code bellow */}
          <Route
            exact
            path={ROUTE_MIT_SUNDHEDPLUS}
            render={(props) => (
              <CriiptoVerifyProvider
                domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                state={mitIdStateString}
                redirectUri={
                  isLocalhost
                    ? 'http://localhost:3000/main'
                    : `${config.clientPortalUrl}${ROUTE_MIT_SUNDHEDPLUS}`
                }
              >
                <SundhedplusMain {...props} />
              </CriiptoVerifyProvider>
            )}
          />
          <Route
            exact
            path={ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE}
            render={(props) => (
              <CriiptoVerifyProvider
                domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                state={mitIdStateString}
                redirectUri={window.location.href}
              >
                <SundhedplusMain {...props} />
              </CriiptoVerifyProvider>
            )}
          />
          <Route
            exact
            path={ROUTE_MIT_SUNDHEDPLUS_HELP}
            render={(props) => (
              <CriiptoVerifyProvider
                domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                state={mitIdStateString}
                redirectUri={window.location.href}
              >
                <SundhedplusMain {...props} />
              </CriiptoVerifyProvider>
            )}
          />

          {/* Client Portal Routes */}
          {currentDomain === process.env.REACT_APP_CLIENT_PORTAL_DOMAIN && (
            <>
              <Route
                exact
                path={ROUTE_START_CLIENT_PORTAL}
                render={(props) => (
                  <CriiptoVerifyProvider
                    domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                    clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                    redirectUri={
                      isLocalhost
                        ? 'http://localhost:3000/main'
                        : `${config.clientPortalUrl}${ROUTE_MIT_SUNDHEDPLUS}`
                    }
                  >
                    <ClientPortalIntermediate {...props} />
                  </CriiptoVerifyProvider>
                )}
              />
              <Route
                exact
                path={ROUTE_MIT_SUNDHEDPLUS}
                render={(props) => (
                  <CriiptoVerifyProvider
                    domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                    clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                    state={mitIdStateString}
                    redirectUri={
                      isLocalhost
                        ? 'http://localhost:3000/main'
                        : `${config.clientPortalUrl}${ROUTE_MIT_SUNDHEDPLUS}`
                    }
                  >
                    <SundhedplusMain {...props} />
                  </CriiptoVerifyProvider>
                )}
              />
              <Route
                exact
                path={ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE}
                render={(props) => (
                  <CriiptoVerifyProvider
                    domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                    clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                    state={mitIdStateString}
                    redirectUri={window.location.href}
                  >
                    <SundhedplusMain {...props} />
                  </CriiptoVerifyProvider>
                )}
              />

              <Route
                exact
                path={ROUTE_HOME}
                render={() => <Redirect to={ROUTE_MIT_SUNDHEDPLUS} />}
              />
            </>
          )}

          <Route
            exact
            path={ROUTE_LINK_MISSING}
            render={(props) => <LinkMissingPage {...props} />}
          />

          {process.env.REACT_APP_DISABLE_ONBOARDING !== 'true' && (
            <Route
              exact
              path={ROUTE_ONBOARDING}
              render={(props) => (
                <OnboardingErrorBoundary>
                  <Onboarding {...props} />
                </OnboardingErrorBoundary>
              )}
            />
          )}

          {process.env.REACT_APP_DISABLE_RENEWAL !== 'true' && (
            <Route
              exact
              path={ROUTE_ONBOARDING_EXISTING_MEMBER}
              render={(props) => (
                <OnboardingErrorBoundary>
                  <Onboarding {...props} onboardingExistingMember />
                </OnboardingErrorBoundary>
              )}
            />
          )}
          {process.env.REACT_APP_DISABLE_RENEWAL !== 'true' && (
            <Route
              exact
              path={ROUTE_ONBOARDING_RENEW_CREDIT}
              render={(props) => (
                <OnboardingErrorBoundary>
                  <Onboarding {...props} renewCredit />
                </OnboardingErrorBoundary>
              )}
            />
          )}

          {process.env.REACT_APP_DISABLE_ONBOARDING !== 'true' && (
            <Route
              exact
              path={ROUTE_SMARTLINK_ONBOARDING}
              render={(props) => (
                <OnboardingErrorBoundary>
                  <SmartLinksPage {...props} />
                </OnboardingErrorBoundary>
              )}
            />
          )}
          <Route
            exact
            path={ROUTE_USER_REGISTRATION_FLOW}
            render={(props) => <UserRegistrationFlow {...props} />}
          />

          {process.env.REACT_APP_DISABLE_ONBOARDING !== 'true' &&
            currentDomain === process.env.REACT_APP_FORNY_SUNDHEDPLUS && (
              <>
                <Route
                  path={ROUTE_HOME}
                  render={(props) => (
                    <OnboardingErrorBoundary>
                      <Onboarding {...props} />
                    </OnboardingErrorBoundary>
                  )}
                />
              </>
            )}

          {/* For onboarding domain, we always redirect to Onboarding no matter the path, otherwise we redirect to DAM. */}
          {process.env.REACT_APP_DISABLE_ONBOARDING !== 'true' &&
          currentDomain === process.env.REACT_APP_ONBOARDING_DOMAIN ? (
            <>
              <Route
                path={ROUTE_HOME}
                render={(props) => (
                  <OnboardingErrorBoundary>
                    <Onboarding {...props} />
                  </OnboardingErrorBoundary>
                )}
              />
            </>
          ) : (
            <>
              <PrivateRoute component={DAM} />
              {/* It’s important to have this code below if we want to test the client portal at localhost */}
              {!isLocalhost ? (
                <Route
                  exact
                  path={ROUTE_MAIN_LOGIN}
                  component={(props) => <MainLogin {...props} />}
                />
              ) : (
                <Route
                  exact
                  path={ROUTE_MAIN_LOGIN}
                  render={(props) => (
                    <CriiptoVerifyProvider
                      domain={process.env.REACT_APP_MIT_CLIENT_PORTAL_DOMAIN_ID}
                      clientID={process.env.REACT_APP_MIT_CLIENT_ID}
                      state={mitIdStateString}
                      redirectUri='http://localhost:3000/main'
                    >
                      <MainLogin {...props} />
                    </CriiptoVerifyProvider>
                  )}
                />
              )}
            </>
          )}
        </Switch>
      </BrowserRouter>
    </CacheBusting>
  );
};

export default App;
