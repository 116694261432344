import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { Button } from 'reactstrap';

class LottieControl extends Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: this.props.animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={this.props.height}
          width={this.props.width}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        {this.props.controls && (
          <>
            <Button
              color='info'
              onClick={() => this.setState({ isStopped: true })}
            >
              stop
            </Button>
            <Button
              color='info'
              onClick={() => this.setState({ isStopped: false })}
            >
              play
            </Button>
            <Button
              color='info'
              onClick={() =>
                this.setState((prevState) => ({
                  isPaused: !prevState.isPaused,
                }))
              }
            >
              pause
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default LottieControl;
