import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isManager, isDentist } from '../../constants/utils';
import { Container } from 'reactstrap';
import { ReactComponent as UserIcon } from '../../resources/icons/user-alt-5.svg';
import dentiLogo from '../../resources/images/denti-logo-white_422x92px.png';
import sundhedLogo from '../../resources/images/sundhedplus-logo.svg';
import { brandConfig } from '../../config';
import { ROUTE_HOME, ROUTE_MAIN_LOGIN } from '../../constants/routes';
import { strings } from '../../constants/localization';
import UserInformation from './UserInformation';

const Navbar = ({
  toggleModal,
  modalShow,
  toggleCreateNewInvoice,
  createNewInvoice,
  isDashboard,
  userDashboard,
}) => {
  const user = useSelector((state) => state.user.user);
  const isAuth = useSelector((state) => state.user.isAuth);
  const companyChainStatus = useSelector(
    (state) => state.companyChain?.companyChainStatus
  );

  const [userInformationToggle, setUserInformationToggle] = useState(false);

  const brandEnvVariable = process.env.REACT_APP_BRAND;

  let shouldShowAddPatientButton = true;
  if (isManager(user)) {
    shouldShowAddPatientButton = companyChainStatus === 'ACTIVE';
  }
  if (isDentist(user)) {
    shouldShowAddPatientButton = user?.activeCompanyStatus === 'ACTIVE';
  }

  if (isAuth === false && !isDashboard) {
    return <Redirect to={ROUTE_MAIN_LOGIN} />;
  }

  const toggleUserInformation = () => {
    setUserInformationToggle(!userInformationToggle);
  };

  return (
    <div className='navbar-section'>
      <div className='logo'>
        <NavLink to={ROUTE_HOME}>
          {brandEnvVariable === 'Denti' && (
            <>
              <img
                className='brandLogo'
                src={dentiLogo}
                alt={`${brandConfig.logoAltText}`}
              />
            </>
          )}
          {brandEnvVariable === 'Sundhedplus' && (
            <>
              <img
                className='sundhedLogo'
                src={sundhedLogo}
                alt={`${brandConfig.logoAltText}`}
              />
            </>
          )}
        </NavLink>
      </div>
      {!isDashboard ? (
        <div className='menu d-flex'>
          <div className='menu-item'>
            <button
              type='button'
              className={`btn actions-btn ${createNewInvoice ? 'active' : ''}`}
              onClick={toggleCreateNewInvoice}
            >
              <span className='actions-btn-label'>
                <i className='lnil lnil-circle-plus' />
                {strings.registerInvoice}
              </span>
            </button>
          </div>
          <div className='menu-item'>
            {shouldShowAddPatientButton && (
              <button
                type='button'
                className={`btn add-patient-btn ${modalShow ? 'active' : ''}`}
                onClick={toggleModal}
              >
                <span className='add-patient-label'>
                  <i className='lnil lnil-circle-plus' />
                  {strings.sidebarMenuAddPatient}
                </span>
              </button>
            )}
          </div>

          <div className='menu-item'>
            <button
              type='button'
              className={`circle-btn ${userInformationToggle ? 'active' : ''}`}
              onClick={toggleUserInformation}
            >
              <UserIcon className='icon' />
            </button>
          </div>
        </div>
      ) : (
        <div className='menu d-flex'>
          <span className='userDashboard'>{userDashboard}</span>

          <div className='menu-item'>
            <button
              type='button'
              className={`circle-btn ${userInformationToggle ? 'active' : ''}`}
              onClick={toggleUserInformation}
            >
              <UserIcon className='icon' />
            </button>
          </div>
        </div>
      )}

      {userInformationToggle && (
        <Container
          className={`userInfo-wrapper ${isDashboard ? 'isDashboard' : ''}`}
        >
          <UserInformation
            toggleUserInformation={toggleUserInformation}
            isDashboard={isDashboard}
          />
        </Container>
      )}
    </div>
  );
};

Navbar.propTypes = {
  selectedCompany: PropTypes.object,
};

export default Navbar;
