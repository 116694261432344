import React from 'react';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { Card } from 'reactstrap';
import TechnicalError from './TechnicalError';

class OnboardingErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo?.message);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id='onboarding-flow' className='flow-wrapper onboarding'>
          <div className='sundhed-wrapper'>
            <div className='sundhed-plus-logo'>
              <img className='sundhed-logo' src={sundhedLogo} alt='Sundhed+' />
            </div>
            <Card className='wrapper'>
              <div className='step'>
                <TechnicalError hasJsError={true} hasBackBtn={false} />
              </div>
            </Card>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default OnboardingErrorBoundary;
