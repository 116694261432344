import React from 'react';

import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import ErrorPage from './ErrorPage';

const LinkMissingPage = () => {
  document.title = strings.linkMissingModalTitle;
  const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
      : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');

  return (
    <ErrorPage>
      <h1>{strings.linkMissingModalTitle}</h1>
      <p>{strings.linkMissingModalP1}</p>
      <p>{strings.linkMissingModalP2}</p>
      <p>
        {strings.formatString(
          strings.linkeMissingModalP3,
          <a href={`mailto:${brandConfig.contactEmail}`}>
            {brandConfig.contactEmail}
          </a>,
          <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>{phoneNumber}</a>
        )}
      </p>
    </ErrorPage>
  );
};

export default LinkMissingPage;
