import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import { config } from '../../config';
import DropDown from '../common/DropDown/DropDown';
import './style.scss';
import { strings } from '../../constants/localization';
import {
  retrieveBookingRequestData,
  updateSupportDecidedStatus,
  updateClinicDecidedStatus,
  updateAllowBookingRequestStatus,
  resendBookingRequest,
} from '../../actions/liveAgentActions';
import { moment } from '../../constants';
import { getCustomerIOLink } from '../../constants/utils';
import {
  bookingStatusesDropdownEN,
  bookingStatusesDropdownDA,
} from '../../constants/mappings';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import Matches from './Matches/Matches';
import { Sundhedplus } from '../../config';

const BookingRequestTab = (props) => {
  const dispatch = useDispatch();
  const { clinicName, bookingId, email, firstName, auth, subject } = props;
  const [clinicEmailConsent, setClinicEmailConsent] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [dropdownDecidedStatus, setDropdownDecidedStatus] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [companySlugUrl, setCompanySlugUrl] = useState('');
  const [companyStatus, setCompanyStatus] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [hoursUntilExpire, setHoursUntilExpire] = useState(0);
  const [minutesUntilExpire, setMinutesUntilExpire] = useState(0);
  const [secondsUntilExpire, setSecondsUntilExpire] = useState(0);
  const [timeExpired, setTimeExpired] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [bookingRequestUuid, setBookingRequestUuid] = useState('');
  const [contactCompanyReceiverList, setContactCompanyReceiverList] = useState(
    []
  );
  const [customerIoUrl, setCustomerIoUrl] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [emailSentDate, setEmailSentDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendBookingLoading, setResendBookingLoading] = useState(false);
  const [error, setError] = useState(false);

  let bookingStatuses = [];
  if (strings.getLanguage() === 'da') {
    bookingStatuses = bookingStatusesDropdownDA;
  } else {
    bookingStatuses = bookingStatusesDropdownEN;
  }

  // SET THE DATA ABOUT THE BOOKING REQUEST
  const setBookingRequestData = useCallback(
    (data) => {
      const receiverList = data.contactCompanyReceiverList || {};
      setClinicEmailConsent(data.allowBookingRequest);
      setCompanyName(data.companyName);
      setCompanySlugUrl(data.companySlugUrl);
      setCompanyStatus(data.companyStatus);
      setCreatedDate(data.created_date);
      setExpirationDate(data.expirationDate);
      setReferenceNumber(data.referenceNumber);
      setBookingRequestUuid(data.token);
      setContactCompanyReceiverList(receiverList);
      if (receiverList.length > 0) {
        setReceiverEmail(receiverList[0]?.username);
        setEmailSentDate(receiverList[0]?.createdDate);
        // is userId is present, use the customerIO url with userId
        if (receiverList[0]?.userId) {
          setCustomerIoUrl(
            config.customerIO.replace('$0', receiverList[0]?.userId)
          );
        }
        // is username is present, use the customerIO url with username
        else if (receiverList[0]?.username)
          setCustomerIoUrl(
            config.customerIOByEmail.replace(
              '$0',
              encodeURIComponent(receiverList[0]?.username)
            )
          );
      }

      const supportStatus = data.support_decided_status;
      const clinicStatus = data.clinicDecidedStatus;

      const foundStatus = bookingStatuses.find((status) => {
        if (
          status.value === 'OPEN' &&
          supportStatus === 'OPEN' &&
          clinicStatus === 'OPEN'
        ) {
          return true;
        }
        if (
          status.value === 'WATCHED' &&
          supportStatus === 'OPEN' &&
          clinicStatus === 'WATCHED'
        ) {
          return true;
        }
        if (status.value === 'CLINIC_DONE' && supportStatus === 'CLINIC_DONE') {
          return true;
        }
        if (status.value === 'DONE' && supportStatus === 'DONE') {
          return true;
        }
        return false;
      });

      if (foundStatus) {
        setDropdownDecidedStatus(foundStatus);
      }
    },
    [bookingStatuses]
  );

  const getBookingRequestData = () => {
    setLoading(true);
    dispatch(
      retrieveBookingRequestData(auth, bookingId, clinicName, email, firstName)
    )
      .then((response) => {
        if (response?.payload?.status === 200) {
          setLoading(false);
          setBookingRequestData(response.payload?.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  useEffect(() => {
    getBookingRequestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runCountDown = useCallback(() => {
    const now = moment.utc(new Date().toUTCString());
    const expiry = moment.utc(expirationDate, 'YYYY-MM-DD HH:mm:ss');
    const duration = moment.duration(expiry.diff(now));

    const days = duration.days();
    const hours = days * 24 + duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    setHoursUntilExpire(hours < 10 ? `0${hours}` : hours);
    setMinutesUntilExpire(minutes < 10 ? `0${minutes}` : minutes);
    setSecondsUntilExpire(seconds < 10 ? `0${seconds}` : seconds);
    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      setTimeExpired(true);
    }
  }, [expirationDate]);

  useEffect(() => {
    runCountDown();
    const interval = setInterval(() => {
      runCountDown();
    }, 1000);
    return () => clearInterval(interval);
  }, [runCountDown]);

  const onClinicEmailSwitch = () => {
    setLoading(true);
    setClinicEmailConsent(!clinicEmailConsent);
    dispatch(
      updateAllowBookingRequestStatus(
        auth,
        referenceNumber,
        !clinicEmailConsent
      )
    )
      .then((response) => {
        if (response?.payload?.status === 200) {
          setLoading(false);
          setIsSwitchDisabled(true);
          setTimeout(() => setIsSwitchDisabled(false), 1000);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  const updateDropdownStatusValue = (value) => {
    setLoading(true);
    dispatch(updateSupportDecidedStatus(auth, bookingRequestUuid, value))
      .then((response) => {
        if (response?.payload?.status === 200) {
          // if the ticket status is updated to "NEW" then send a request to also update clinicDecidedStatus
          if (value === 'OPEN') {
            dispatch(updateClinicDecidedStatus(bookingRequestUuid, value))
              .then((response) => {
                if (response?.payload?.status === 200) {
                  setLoading(false);
                }
              })
              .catch((e) => {
                setLoading(false);
                setError(true);
                console.log(e);
              });
          } else {
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  const resendBooking = () => {
    setResendBookingLoading(true);
    dispatch(resendBookingRequest(auth, bookingRequestUuid))
      .then((response) => {
        if (response?.payload?.status === 200) {
          setResendBookingLoading(false);
          getBookingRequestData();
        }
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      });
  };

  return (
    <Row>
      <Col sm='12'>
        {loading ? (
          <LoadingMessage />
        ) : !error ? (
          <div className='LiveAgent_content'>
            <p>
              <b>
                {subject && subject.includes('Bookingforespørgsel')
                  ? strings.liveAgentTabBookingParagraphV1
                  : subject.includes('Anmodning om at få behandler oprettet')
                  ? strings.liveAgentTabBookingParagraphV2
                  : ''}
              </b>
            </p>
            <DropDown
              options={bookingStatuses
                .filter((item) => item.value !== 'CLINIC_DONE')
                .filter((item) => item.value !== 'WATCHED')}
              currentValue={dropdownDecidedStatus}
              placeholder={strings.liveAgentTabBookingPlaceholder}
              setValue={(value) => {
                setDropdownDecidedStatus(value);
                updateDropdownStatusValue(value?.value);
              }}
            />
            <div className='patientdata_area'>
              <div className='dataRow'>
                <div className='leftItem'>
                  {strings.liveAgentTabBookingCreated}
                </div>
                <div className='rightItem'>
                  {moment(createdDate).format('DD. MMM YYYY').toLowerCase()}
                </div>
              </div>
              <div className='dataRow '>
                <div className='leftItem'>
                  {strings.liveAgentTabBookingExpiration}
                </div>
                <div className='rightItem'>
                  {!timeExpired &&
                    `${hoursUntilExpire}:${minutesUntilExpire}:${secondsUntilExpire}`}
                  {timeExpired && (
                    <>
                      <span className={`${timeExpired ? 'yelow' : ''}`}>
                        {`${strings.expired} `}
                      </span>
                      <span>
                        (
                        {moment
                          .utc(expirationDate)
                          .local()
                          .format('DD. MMM YYYY, HH:mm')
                          .toLowerCase()}
                        )
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className='dataRow'>
                <div className='leftItem'>
                  {strings.liveAgentTabBookingClinic}
                </div>
                <div className='rightItem'>
                  {companyName} ({referenceNumber})
                </div>
              </div>
              <div className='dataRow '>
                <div className='leftItem'>
                  {strings.liveAgentTabBookingClinicStatus}
                </div>
                <div className='rightItem'>{strings[companyStatus]}</div>
              </div>
              <div className='dataRow '>
                <div className='leftItem'>
                  {strings.liveAgentTabBookingSwitchText}
                </div>
                <div className='rightItem'>
                  <Switch
                    onChange={onClinicEmailSwitch}
                    checked={clinicEmailConsent}
                    disabled={isSwitchDisabled}
                    className='react-switch'
                  />
                </div>
              </div>

              {receiverEmail && (
                <div className='dataRow '>
                  <div className='leftItem'>
                    {strings.liveAgentTabBookingReceiverEmail}
                  </div>
                  <div className='rightItem'>{receiverEmail}</div>
                </div>
              )}

              {emailSentDate && (
                <div className='dataRow '>
                  <div className='leftItem'>
                    {strings.liveAgentTabBookingEmailCreatedDate}
                  </div>
                  <div className='rightItem'>
                    {moment
                      .utc(emailSentDate)
                      .local()
                      .format('DD. MMM YYYY, HH:mm')
                      .toLowerCase()}
                  </div>
                </div>
              )}

              <div className='buttons_area booking-tab'>
                {contactCompanyReceiverList.length === 0 && (
                  <Button
                    color='green'
                    tag='a'
                    disabled={!clinicEmailConsent || resendBookingLoading}
                    onClick={resendBooking}
                  >
                    {resendBookingLoading ? (
                      <ButtonLoader />
                    ) : (
                      strings.liveAgentTabBookingButtonSend
                    )}
                  </Button>
                )}
                {customerIoUrl && (
                  <Button
                    color='orange'
                    tag='a'
                    onClick={() => {
                      const linkFilters = getCustomerIOLink(new Date());
                      window.open(`${customerIoUrl}/${linkFilters}`, '_blank');
                    }}
                    target='_blank'
                  >
                    {strings.liveAgentTabBookingButtonCustomerIO}
                  </Button>
                )}
                {companySlugUrl && (
                  <Button
                    color='orange'
                    tag='a'
                    href={companySlugUrl}
                    target='_blank'
                  >
                    {strings.liveAgentTabBookingButtonCompanySlug}
                  </Button>
                )}
                <Button
                  color='orange'
                  tag='a'
                  href={`${Sundhedplus.mainPageUrl}/companies?q=${referenceNumber}`}
                  target='_blank'
                >
                  {strings.liveAgentTabBookingButtonSee}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Matches />
        )}
      </Col>
    </Row>
  );
};

export default BookingRequestTab;
