import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkEskatConsent,
  checkEskatConsentNoCpr,
  convertMemberToClient,
  addErrorLog,
  storeOnboardingData,
  leadDoublerCalculator,
  getPublicCreditApplicationDataForMonthio,
  populateOnboardinDataBasedCreditApplication,
} from '../../actions/onboardingActions';

import { strings } from '../../constants/localization';
import {
  isAdmin,
  isAuthenticated,
  getLoggedInUserDetails,
} from '../../constants/utils';
import LoadingSpinner from './common/LoadingSpinner/LoadingSpinner';

const CheckEskatConsent = ({ setNextStep, step }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const { uuid, cpr, bisnodeId, companyId, linkTag, creditApplicationUuid } =
    savedData || {};
  const userFromStorage = getLoggedInUserDetails();

  // useRef to ensure useEffect only runs once
  const hasFetchedData = useRef(false);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingSpinnerMessageEskatCompletes}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/eskat-validering',
        stepTitle: 'onboarding - eskat validering',
        event: 'onboarding',
      });
    }
  }, [visitedSteps, step]);

  const handleError = useCallback(
    (error) => {
      dispatch(
        addErrorLog({
          status: error?.response?.status,
          message: error?.response?.data,
        })
      );
      setNextStep(null);
    },
    [dispatch, setNextStep]
  );

  const handleLeadDoublerCalculator = useCallback(
    async (newUuid, cpr) => {
      if (newUuid && cpr) {
        try {
          const res = await dispatch(leadDoublerCalculator(cpr, newUuid));
          if (res.payload.status === 200) {
            const updatedData = {
              ...savedData,
              uuid: newUuid,
              leadDoublerCalculator: res.payload.data,
            };
            await dispatch(storeOnboardingData(updatedData));
            setNextStep(step + 1);
          }
        } catch (error) {
          handleError(error);
        }
      }
    },
    [dispatch, cpr, savedData, setNextStep, step, handleError]
  );

  const handleEskatConsentSuccess = useCallback(
    async (data) => {
      const updatedData = {
        ...savedData,
        cpr: data?.cpr,
        firstName: data?.firstName,
        lastName: data?.lastName,
        fullAddress: data?.fullAddress,
        email: data?.email,
        phoneNumber: data?.phone,
        eskatConsent: true,
      };
      try {
        await dispatch(storeOnboardingData(updatedData));
        const result = await dispatch(
          convertMemberToClient(
            bisnodeId,
            uuid,
            companyId,
            linkTag,
            creditApplicationUuid
          )
        );
        const newUuid = result.payload?.data;
        await handleLeadDoublerCalculator(newUuid, data?.cpr);
      } catch (error) {
        handleError(error);
      }
    },
    [
      dispatch,
      savedData,
      bisnodeId,
      uuid,
      companyId,
      linkTag,
      creditApplicationUuid,
      handleLeadDoublerCalculator,
      handleError,
    ]
  );

  const loadCheckEskatConsent = useCallback(
    async (cpr, uuid) => {
      const useRestrictedEndpoint = !(
        isAuthenticated() &&
        isAdmin(userFromStorage) &&
        process.env.REACT_APP_STAGE === 'staging'
      );

      try {
        const res = await dispatch(
          useRestrictedEndpoint
            ? checkEskatConsent(uuid, cpr)
            : checkEskatConsentNoCpr(uuid, cpr)
        );
        if (res.payload.status === 200) {
          await handleEskatConsentSuccess(res.payload.data);
        }
      } catch (error) {
        await dispatch(
          storeOnboardingData({ ...savedData, eskatConsent: false })
        );
        handleError(error);
      }
    },
    [
      dispatch,
      savedData,
      userFromStorage,
      handleEskatConsentSuccess,
      handleError,
    ]
  );

  useEffect(() => {
    const execute = async () => {
      if (hasFetchedData.current) return; // prevent multiple executions

      hasFetchedData.current = true;

      if (uuid && cpr) {
        await loadCheckEskatConsent(cpr, uuid);
      } else {
        try {
          const res = await dispatch(
            getPublicCreditApplicationDataForMonthio(creditApplicationUuid)
          );
          const creditApplicationData = res.payload?.data;
          const { clientEnteredData } = creditApplicationData || {};

          if (creditApplicationData) {
            await dispatch(
              populateOnboardinDataBasedCreditApplication(creditApplicationData)
            );
          }

          if (clientEnteredData?.cpr && clientEnteredData?.leadUuid) {
            await loadCheckEskatConsent(
              clientEnteredData.cpr,
              clientEnteredData.leadUuid
            );
          } else {
            setNextStep(null);
          }
        } catch (error) {
          handleError(error);
        }
      }
    };

    execute();
  }, [
    uuid,
    cpr,
    creditApplicationUuid,
    dispatch,
    loadCheckEskatConsent,
    setNextStep,
    handleError,
  ]);

  return (
    <LoadingSpinner
      title={strings.onboardingSpinnerTitle}
      subtitle={strings.onboardingSpinnerSubtitleEskatCompletes}
      loadingMessage={strings.onboardingSpinnerMessageEskatCompletes}
      loadingSecondMessage={strings.onboardingSpinnerMessageEskatWaitTime}
    />
  );
};

export default CheckEskatConsent;
