import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';

const DeleteFileConfirmation = ({
  closeModal,
  confirmAction,
  loadingDelete,
}) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-doc-modal'
      modalClassName='upload-modal'
      close={() => closeModal()}
      closeBtn={true}
    >
      <div className='cancel-upload-modal d-flex align-items-center justify-content-center'>
        <div className='px-2'>
          <h3 className='headline'>{strings.onboardingDeleteFileHeadline}</h3>
          <div className='subtitle mt-5 mb-5'>
            {strings.onboardingDeleteFileText}
          </div>
        </div>

        <div className='actions-buttons'>
          <Button
            className='btn btn-blue btn-cancel'
            onClick={() => closeModal()}
          >
            {strings.onboardingDeleteFileNo}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => confirmAction()}
          >
            {loadingDelete ? <ButtonLoader /> : strings.onboardingDeleteFileYes}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default DeleteFileConfirmation;
