import { useEffect, useRef, useState } from 'react';
import DentiModal from './common/DentiModal';
import ButtonLoader from './common/ButtonLoader/ButtonLoader';
import { strings } from '../constants/localization';
import { ReactComponent as ReloadBrowser } from '../resources/icons/reload-browser.svg';
import { Button } from 'reactstrap';
import {
  ROUTE_BOOKING_REQUEST,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LIVE_AGENT,
  ROUTE_LOAN_CALCULATOR,
  ROUTE_COMPANIES,
  ROUTE_COMPANY_CHAINS,
  ROUTE_CREDIT_APPLICATIONS,
  ROUTE_DOCUMENTATION,
  ROUTE_LEADS,
  ROUTE_PATIENTS_DUNNING,
  ROUTE_MIT_SUNDHEDPLUS,
  ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
  ROUTE_MAIN_LOGIN,
  ROUTE_START_CLIENT_PORTAL,
  ROUTE_ONBOARDING,
} from '../constants/routes';
import { isAuthenticated } from '../constants/utils';

const CacheBusting = ({ children }) => {
  const currentLocation = location.pathname;
  const previousLocation = useRef(currentLocation); // useRef to store the previous location
  const routesWithAutomaticUpdates = [
    ROUTE_MAIN_LOGIN,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_LIVE_AGENT,
    ROUTE_LOAN_CALCULATOR,
    ROUTE_BOOKING_REQUEST,
    ROUTE_START_CLIENT_PORTAL,
  ];
  const clinicUsersRoutes = [
    ROUTE_LEADS,
    ROUTE_COMPANIES,
    ROUTE_COMPANY_CHAINS,
    ROUTE_PATIENTS_DUNNING,
    ROUTE_CREDIT_APPLICATIONS,
    ROUTE_DOCUMENTATION,
    ROUTE_MIT_SUNDHEDPLUS,
    ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
    ROUTE_ONBOARDING,
  ];
  const [nextVersion, setNextVersion] = useState('');
  const [showNewVersionModal, setShowNewVersionModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [remindMeLater, setRemindMeLater] = useState(false);
  let remindInterval;

  //is a function that can format the version like "1.0.5" into the number 105, so we can compare the versions
  const parseVersion = (str) => {
    return +str.replace(/\D/g, '');
  };

  useEffect(() => {
    // listen for storage events to check if another tab has updated the checkedVersion
    window.addEventListener('storage', function (event) {
      if (event.key === 'latestAppVersion') {
        // update the value in localStorage
        localStorage.setItem('latestAppVersion', event.newValue);
        setShowNewVersionModal(false);
      }
    });

    checkVersion();
    // set an interval to call every hour to check if there's a new version and needs update
    const interval = setInterval(() => {
      checkVersion();
    }, 1000 * 60 * 60);

    // clean-up

    return () => {
      clearInterval(interval);
      if (remindInterval) {
        clearInterval(remindInterval);
      }
    };
  }, []);

  useEffect(() => {
    // if user clicked "remind me later" then set an interval to check every 5 minutes
    if (remindMeLater) {
      remindInterval = setInterval(() => {
        checkVersion();
      }, 1000 * 60 * 5);
    }
  }, [remindMeLater]);

  useEffect(() => {
    if (nextVersion && previousLocation.current !== currentLocation) {
      clearCacheAndReload(nextVersion);
    }
    previousLocation.current = currentLocation; // Update the previous location to the current location
  }, [currentLocation]);

  const checkVersion = async () => {
    try {
      // fetch meta.json file from the server without cache to get the latest version
      const response = await fetch('/meta.json', {
        cache: 'no-store',
      });
      if (!response.ok) {
        throw new Error(
          `Error fetching meta.json file! status: ${response.status}`
        );
      }
      const meta = await response.json();
      const latestVersion = parseVersion(meta.version);
      const stringVersion = localStorage.getItem('latestAppVersion');
      const localStorageVersion = stringVersion
        ? parseVersion(stringVersion)
        : '';

      // Check if the current hostname is in the list of allowed hostnames to automatically update
      const shouldPerformAutomaticUpdate =
        routesWithAutomaticUpdates.includes(currentLocation);

      // check the release metadata to see if the version is relevant for the current flow
      const { relevantFor } = meta.releaseMetadata || {};

      // Check if the current route is included in the clinic users lists
      const isClinicUsersRoute = clinicUsersRoutes.includes(currentLocation);

      // Check if at least one item from array1 exists in array2
      const hasSiblingRouteInReleaseArray = clinicUsersRoutes.some((item) =>
        relevantFor.includes(item)
      );

      // Check if the  current route is in the releaseMetadata array
      const isInReleaseMetadata = relevantFor.includes(currentLocation);
      const relevantForCurrentRoute =
        isInReleaseMetadata ||
        (isAuthenticated() &&
          isClinicUsersRoute &&
          hasSiblingRouteInReleaseArray);

      // if first time enter and no version stored yet to localStorage, then clear cache automatically
      if (!localStorageVersion) {
        setNextVersion(meta.version);
        clearCacheAndReload(meta.version);
      } else if (localStorageVersion !== latestVersion) {
        console.log(
          `New version - ${meta.version}. available need to force refresh`
        );
        setNextVersion(meta.version);
        if (shouldPerformAutomaticUpdate) {
          clearCacheAndReload(meta.version);
        } else if (relevantForCurrentRoute) {
          // if the version is relevant for the current route, show the modal to ask user to confirm the update
          setShowNewVersionModal(true);
        }
      } else {
        setShowNewVersionModal(false);
        console.log(
          `Already latest version - ${meta.version}. No refresh required.`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearCacheAndReload = (newVersion) => {
    const versionToStore = nextVersion || newVersion;
    localStorage.setItem('latestAppVersion', JSON.stringify(versionToStore)); // Store checkedVersion in localStorage
    setRemindMeLater(false);
    if (remindMeLater) {
      setLoadingAction(true);
    }

    //don't reload for first time entering the site
    if (!newVersion) {
      if (caches) {
        // deleting saved cache one by one
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      } // after deleting cached data hard reload the site
      window.location.reload(true);
    }
  };

  const renderConfirmNewVersionModal = () => {
    return (
      <DentiModal
        key={1}
        close={() => {
          setShowNewVersionModal(false);
          setRemindMeLater(true);
        }}
        modalClass='onboarding cache-busting-modal'
        modalClassName='confirm-action'
        closeBtn={true}
      >
        <div className='status-modal'>
          <div>
            <div className='reload-browser-icon'>
              <ReloadBrowser />
            </div>

            <h3 className='headline'>
              {strings.cacheBustingConfirmModalTitle}
            </h3>
            <p className='subtitle mt-5 mb-5'>
              {strings.formatString(
                strings.cacheBustingConfirmModalText,
                <br />
              )}
            </p>
          </div>
          <div className='actions-buttons'>
            <Button
              className='btn btn-blue btn-cancel'
              onClick={() => {
                setShowNewVersionModal(false);
                setRemindMeLater(true);
              }}
              disabled={loadingAction}
            >
              <span>{strings.cacheBustingConfirmModalCancelButton}</span>
            </Button>
            <Button
              className='btn btn-blue btn-success'
              onClick={() => clearCacheAndReload()}
              disabled={loadingAction}
            >
              {!loadingAction && (
                <span>{strings.cacheBustingConfirmModalConfirmButton}</span>
              )}
              {loadingAction && <ButtonLoader />}
            </Button>
          </div>
        </div>
      </DentiModal>
    );
  };

  return (
    <>
      {showNewVersionModal && renderConfirmNewVersionModal()}
      {children}
    </>
  );
};

export default CacheBusting;
