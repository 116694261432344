import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './style.scss';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import DocumentationList from './DocumentationList/DocumentationList';
import DocumentationItem from './DocumentationItem/DocumentationItem';
import WelcomePage from '../common/WelcomePage.tsx';

export default function DocumentationPage(props) {
  const scrollRef = createRef();
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );

  useEffect(() => {
    resetScrollPosition();
  }, []);

  useEffect(() => {
    if (activeDocListItem) {
      resetScrollPosition();
    }
  }, [activeDocListItem]);

  const resetScrollPosition = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.view.scrollTop = 0;
    }
  };

  return (
    <>
      <Row className='app-row-height'>
        <Col
          xs='12'
          md='12'
          xl='4'
          className='entities-list-section p-0 app-col-height'
        >
          <DocumentationList toggleMenu={props.toggleMenu} />
        </Col>
        <Col
          xs='12'
          md='12'
          xl='8'
          className={`entity-details-section ${
            activeDocListItem !== undefined && 'active'
          }`}
        >
          <Scrollbars
            ref={scrollRef}
            renderTrackVertical={({ style, ...props }) => (
              <div {...props} style={{ ...style, ...trackVerticalStyle }} />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
            )}
          >
            {activeDocListItem !== undefined ? (
              <DocumentationItem />
            ) : (
              <WelcomePage
                toggleModal={props.toggleModal}
                modalShow={props.modalShow}
                toggleCreateNewInvoice={props.toggleCreateNewInvoice}
                createNewInvoice={props.createNewInvoice}
              />
            )}
          </Scrollbars>
        </Col>
      </Row>
    </>
  );
}
