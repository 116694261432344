import React, { useEffect, useState } from 'react';
import './loadingSpinner.scss';
import { CardBody } from 'reactstrap';

const LoadingSpinner = ({
  title = '',
  subtitle = '',
  loadingMessage = '',
  loadingSecondMessage = '',
}) => {
  const [analyzingTextIndex, setAnalyzingTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnalyzingTextIndex(
        (prevIndex) => (prevIndex + 1) % loadingMessage.length
      );
    }, 8000); // 8 seconds change text

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  return (
    <CardBody className='step-content'>
      <div className='step-title center mt-4'>{title}</div>
      <div className='loading-spinner'>
        <div className='step-subtitle center'>{subtitle}</div>
        <div className='d-flex align-items-center justify-content-center '>
          <div className='LoadingSpinner'>
            <div className='lds-ring'>
              <div>
                <div>&nbsp;</div>

                <div>&nbsp;</div>

                <div>&nbsp;</div>

                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        {loadingMessage && (
          <div className='loadingMessage'>
            {
              // loadingMessage has one message only show it without changing
              loadingMessage && !Array.isArray(loadingMessage)
                ? loadingMessage
                : loadingMessage[analyzingTextIndex]
            }
          </div>
        )}
        {loadingSecondMessage && <div>{loadingSecondMessage}</div>}
      </div>
    </CardBody>
  );
};

export default LoadingSpinner;
