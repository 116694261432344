import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { success, error as notifError } from 'react-notification-system-redux';
import CompanyInformation from './CompanyInformation';
import InternalNote from '../../common/InternalNote';
import {
  setCurrentCompany,
  displayLeadsForCompany,
  updateCompanyInternalNote,
  setCompanyLoading,
  updateCompanyStatus,
  getCompanies,
  getCompaniesListForManagers,
} from '../../../actions/companyActions';
import { filterEmitted } from '../../../actions/leadActions';
import { ROUTE_LEADS } from '../../../constants/routes';
import { getLogsforCompanies } from '../../../actions/logActions';
import { companySidebarActiveStatus } from '../../../actions/statusActions';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../../constants/styleScrollbar';
import { updateCompanyChainStatusFromCompany } from '../../../actions/companyChainActions';
import CompanyOptions from './CompanyOptions';
import { strings } from '../../../constants/localization';
import ClinicIndexSection from './ClinicIndex/ClinicIndexSection';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import EntityStatus from '../../common/EntityStatus/EntityStatus ';
import WelcomePage from '../../common/WelcomePage.tsx';

class CompanyDetails extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();

    this.resetScrollPosition = this.resetScrollPosition.bind(this);
    this.markCompanyAsSelected = this.markCompanyAsSelected.bind(this);
  }

  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }

  markCompanyAsSelected() {
    const { referenceNumber, companyName } = this.props.activeCompany;

    this.props.filterEmitted({
      companyReferenceNumber: referenceNumber,
      companyName: companyName,
    });

    this.props.displayLeadsForCompany({
      referenceNumber,
      companyName,
    });
    this.props.history.push(ROUTE_LEADS);
  }

  componentDidMount() {
    this.resetScrollPosition();
  }

  componentDidUpdate() {
    this.resetScrollPosition();
  }

  render() {
    const company = this.props.activeCompany;

    return (
      <>
        <Scrollbars
          ref={this.scrollRef}
          // eslint-disable-next-line
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          // eslint-disable-next-line
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...thumbVerticalStyle,
              }}
            />
          )}
        >
          {company ? (
            <div className='main-content'>
              <button
                type='button'
                className='btn btn-white go-back-to-list'
                onClick={() => this.props.setCurrentCompany(undefined)}
              >
                <i className='lnil lnil-chevron-left' />
                <span>{strings.goToClinicsList}</span>
              </button>
              {this.props.companyLoading && <LoadingMessage />}
              <Row>
                <Col xs='12' lg='12'>
                  <CompanyInformation
                    companyDetails={company}
                    markCompanyAsSelectedFunc={this.markCompanyAsSelected}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs='12' lg='12'>
                  <EntityStatus
                    companyPage
                    fieldClass='CompanyStatusComponent'
                    entityDetails={company}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs='12' lg='12'>
                  <InternalNote
                    id={company.id}
                    activeObject={company}
                    saveInternalNote={this.props.updateCompanyInternalNote}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs='12' lg='12'>
                  <ClinicIndexSection companyDetails={company} />
                </Col>
              </Row>

              <Row>
                <Col xs='12' lg='12'>
                  <CompanyOptions
                    chains={this.props.activeCompany.companies}
                    id={this.props.activeCompany.id}
                    activeChain={this.props.activeCompany}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <WelcomePage
              toggleModal={this.props.toggleModal}
              modalShow={this.props.modalShow}
              toggleCreateNewInvoice={this.props.toggleCreateNewInvoice}
              createNewInvoice={this.props.createNewInvoice}
            />
          )}
        </Scrollbars>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    companyLoading: state.company.companyLoading,

    user: state.user.user,
    statusActive: state.status.companyStatusActive,
    activeCompany: state.company.activeCompany,
    activePage: state.company.activePage,
    searchActive: state.company.searchActive,
  };
}

CompanyDetails.propTypes = {
  activeCompany: PropTypes.object,
  setCurrentCompany: PropTypes.func,
  displayLeadsForCompany: PropTypes.func,
  filterEmitted: PropTypes.func,
  setCompanyLoading: PropTypes.func,

  updateCompanyStatus: PropTypes.func,
  getCompanies: PropTypes.func,
  activePage: PropTypes.number,
  statusActive: PropTypes.string,
  companyClient: PropTypes.object,
  id: PropTypes.number,
};

export default connect(mapStateToProps, {
  setCurrentCompany,
  displayLeadsForCompany,
  updateCompanyInternalNote,
  filterEmitted,
  setCompanyLoading,
  updateCompanyStatus,
  getCompanies,
  getLogsforCompanies,
  companySidebarActiveStatus,
  success,
  notifError,
  getCompaniesListForManagers,
  updateCompanyChainStatusFromCompany,
})(CompanyDetails);
