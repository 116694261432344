import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { connect } from 'react-redux';
import { strings } from '../../constants/localization';
import { getCompaniesForCompanyChain } from '../../actions/companyActions';
import { isAdmin, isManager, isDentist } from '../../constants/utils';
import SearchClinic from '../../components/common/SearchClinic/SearchClinic';
import DropDown from '../common/DropDown/DropDown';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';

const StepTwo = ({ user, nextStep, getCompaniesForCompanyChain }) => {
  const activeCompany = user?.activeCompany;
  const [patientCpr, setPatientCpr] = useState('');
  const [patientLetters, setPatientLetters] = useState('');
  const [clinic, setClinic] = useState({});
  const [clinicName, setClinicName] = useState('');
  const [clinicsListForCompanyChain, setClinicsListForCompanyChain] = useState(
    []
  );
  const [clinicReferenceNumber, setClinicReferenceNumber] = useState(
    isDentist(user) ? activeCompany : ''
  );
  const [loading, setLoading] = useState(false);
  const [addPatientLoading, setAddPatientLoading] = useState(false);
  const maskCharRegex = /_/g;
  const cprInputRef = useRef();
  const letterInputRef = useRef();

  const resetPosition = (inputField) => {
    const input = inputField;
    const iCaretPos = 0;
    // IE Support
    if (document.selection) {
      // Set focus on the element
      input.focus();

      // Create empty selection range
      const oSel = document.selection.createRange();

      // Move selection start and end to 0 position
      oSel.moveStart('character', -input.value.length);

      // Move selection start and end to desired position
      oSel.moveStart('character', iCaretPos);
      oSel.moveEnd('character', 0);
      oSel.select();
    }

    // Firefox support
    else if (input.selectionStart || input.selectionStart === '0') {
      input.selectionStart = iCaretPos;
      input.selectionEnd = iCaretPos;
      input.focus();
    }
  };

  useEffect(() => {
    resetPosition(cprInputRef.current.inputElement);
    // get the companies for the companyChain of the current manager user
    let companies = [];
    if (isManager(user) && user?.companyChainId) {
      setLoading(true);
      getCompaniesForCompanyChain(user.companyChainId).then((result) => {
        if (result.payload.status === 200) {
          if (result.payload.data.companyChains[0].companies) {
            companies = result.payload.data.companyChains[0].companies
              .filter((company) => company.status !== 'DEACTIVE')
              .map((item) => ({
                front: item.referenceNumber,
                top: item.companyName,
                bottom: `${item.address}, ${item.zipCode} ${item.city}`,
                right: item.status,
                value: item.referenceNumber,
              }));
            // if there are more than one company update the list, otherwise set the current to the single company available
            if (companies.length > 1) {
              setClinicsListForCompanyChain(companies);
            } else if (companies.length === 1)
              setClinicReferenceNumber(companies[0].value);
            setClinicName(companies[0].top);
          }
          setLoading(false);
        }
        setLoading(false);
      });
    }
  }, [user, getCompaniesForCompanyChain]);

  // set the reference number for company based on user role
  useEffect(() => {
    let referenceNumber = '';
    if (Object.keys(clinic).length > 0) {
      if (isAdmin(user)) {
        referenceNumber = clinic.referenceNumber;
        setClinicName(clinic.companyName);
      }
      if (isManager(user)) {
        referenceNumber = clinic.value;
        setClinicName(clinic.top);
      }
    }
    if (isDentist(user)) {
      referenceNumber = user.activeCompany;
      setClinicName(user.companyName);
    }

    setClinicReferenceNumber(referenceNumber);
  }, [clinic, user]);

  const isDisabled = () => {
    const cprValue = patientCpr.replace(maskCharRegex, '');
    const letterValue = patientLetters.replace(maskCharRegex, '');

    if (cprValue !== '' && letterValue !== '' && clinicReferenceNumber !== '') {
      return false;
    }
    return true;
  };

  const setCprNumber = (value) => {
    const newValue = value.replace(maskCharRegex, '');
    setPatientCpr(newValue);

    if (newValue.length > 10) {
      resetPosition(letterInputRef.current.inputElement);
    }
  };

  const setNameLetters = (value) => {
    const newValue = value.replace(maskCharRegex, '').toUpperCase();
    setPatientLetters(newValue);
  };

  return (
    <div className='step-content'>
      <div className='step-header'>
        <i className='di di-add-user' />
        <div className='title-text'>{strings.apStep2Title}</div>
      </div>
      <div className='step-body'>
        <div className='input-container'>
          {isAdmin(user) && (
            <div className='form-group'>
              <SearchClinic
                selectClinic={setClinic}
                selected={clinic}
                fieldClass={!clinic.referenceNumber ? 'input_danger' : ''}
                format
                showAdress
              />
            </div>
          )}

          {isManager(user) && (
            <div className='form-group'>
              <label>{strings.apClinicLabel}</label>
              <DropDown
                loading={loading}
                options={clinicsListForCompanyChain}
                currentValue={clinic}
                placeholder={strings.chainSelectLocation}
                setValue={(value) => {
                  setClinic(value);
                }}
              />
            </div>
          )}
          <div className='form-group'>
            <label htmlFor='patientCpr'>{strings.apCprNumber}</label>
            <MaskedInput
              ref={cprInputRef}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              type='text'
              className='form-control'
              id='patientCpr'
              value={patientCpr}
              guide
              showMask
              onChange={(event) => setCprNumber(event.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='patientLetters'>{strings.apLettersName}</label>
            <MaskedInput
              ref={letterInputRef}
              mask={[/[a-zA-ZåæéøÅÆÉØ]/, /[a-zA-ZåæéøÅÆÉØ]/]}
              value={patientLetters}
              type='text'
              className='form-control'
              id='patientLetters'
              guide
              showMask
              onChange={(event) => setNameLetters(event.target.value)}
            />
          </div>
        </div>
        <div className='text-information'>
          {strings.formatString(
            strings.apStep2TextInfo,
            clinicName !== ''
              ? clinicName
              : strings.apStep2PlaceholderTextForClinic
          )}
        </div>
        <div className='button-actions'>
          <Button
            color='blue'
            className='btn-add-patient'
            onClick={() => {
              setAddPatientLoading(true);
              nextStep({
                companyReferenceNumber: clinicReferenceNumber,
                clientCpr: patientCpr.replace('-', ''),
                clientName: patientLetters,
              });
            }}
            disabled={isDisabled()}
          >
            {!addPatientLoading ? (
              <>
                <i className='di di-check' />
                {strings.buttonTextAddPatient}
              </>
            ) : (
              <ButtonLoader />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getCompaniesForCompanyChain })(StepTwo);
