import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { strings } from '../../../constants/localization';

import './style.scss';

function Step1({ email, code, setCode, next, disableButton }) {
  const [minLengthValid, setMinLengthValid] = useState(false);
  const [minUppercase, setMinUppercase] = useState(false);
  const [minLowercase, setMinLowercase] = useState(false);
  const [minNumber, setMinNumber] = useState(false);
  const [passwordDontMatch, setPasswordDontMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordEnabled, setConfirmPasswordEnabled] = useState(false);

  useEffect(() => {
    const isMinLengthValid = code.length >= 8;
    const hasMinUppercase = code.replace(/[^A-Z]/g, '').length > 0;
    const hasMinLowercase = code.replace(/[^a-z]/g, '').length > 0;
    const hasMinNumber = code.replace(/[^0-9]/g, '').length > 0;
    const isPasswordMatching =
      code.substring(0, confirmPassword.length) !== confirmPassword;

    setMinLengthValid(isMinLengthValid);
    setMinUppercase(hasMinUppercase);
    setMinLowercase(hasMinLowercase);
    setMinNumber(hasMinNumber);
    setPasswordDontMatch(isPasswordMatching);
    setConfirmPasswordEnabled(
      isMinLengthValid && hasMinUppercase && hasMinLowercase && hasMinNumber
    );

    disableButton(
      !(
        isMinLengthValid &&
        hasMinUppercase &&
        hasMinLowercase &&
        hasMinNumber &&
        !isPasswordMatching &&
        code.length === confirmPassword.length &&
        code === confirmPassword
      )
    );

    // eslint-disable-next-line
  }, [code, confirmPassword]);

  return (
    <div className='Step1 section'>
      <div className='title'>{strings.userRegistrationCreateUaser}</div>
      <div className='note'>{strings.userRegistrationCreateSecurePassword}</div>
      <div className='content'>
        <div className='inputContainer'>
          <label>{strings.email}</label>
          <input value={email} readOnly />
        </div>
        <div className='inputContainer passwordContainer'>
          <label>{strings.userRegistrationPassword}</label>
          <input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type={showPassword ? 'text' : 'password'}
          />
          <div
            className='showPassword-Container'
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {!showPassword && <i className='lnir lnir-eye' />}
            {showPassword && (
              <div className='hideIcon'>
                <i className='lnir lnir-eye' />
                <div className='oblick-line' />
              </div>
            )}
          </div>
        </div>
        <div className='inputContainer passwordContainer'>
          <label>{strings.userRegistrationPasswordRepeat}</label>
          <input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={showConfirmPassword ? 'text' : 'password'}
            disabled={!confirmPasswordEnabled}
          />
          <div
            className='showPassword-Container'
            onClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
          >
            {!showConfirmPassword && <i className='lnir lnir-eye' />}
            {showConfirmPassword && (
              <div className='hideIcon'>
                <i className='lnir lnir-eye' />
                <div className='oblick-line' />
              </div>
            )}
          </div>
          {passwordDontMatch && (
            <div className='validations'>
              <span className='invalid'>{strings.passwordDontMatch}</span>
            </div>
          )}

          <div className='validations'>
            <div className='validations_note'>
              {strings.userRegistrationPasswordRequermets}
            </div>

            <Row>
              <Col
                sm='6'
                className={`${next ? 'invalid' : ''} ${
                  minLengthValid ? 'valid' : ''
                }`}
              >
                {!next && !minLengthValid && (
                  <i className='lnir lnir-circle-minus' />
                )}
                {minLengthValid && <i className='lnir lnir-checkmark-circle' />}
                {next && !minLengthValid && (
                  <i className='lnir lnir-cross-circle' />
                )}
                {strings.userRegistrationPasswordRequermets1}
              </Col>
              <Col
                sm='6'
                className={`${next ? 'invalid' : ''} ${
                  minUppercase ? 'valid' : ''
                }`}
              >
                {!next && !minUppercase && (
                  <i className='lnir lnir-circle-minus' />
                )}
                {minUppercase && <i className='lnir lnir-checkmark-circle' />}
                {next && !minUppercase && (
                  <i className='lnir lnir-cross-circle' />
                )}
                {strings.userRegistrationPasswordRequermets2}
              </Col>
            </Row>

            <Row>
              <Col
                sm='6'
                className={`${next ? 'invalid' : ''} ${
                  minLowercase ? 'valid' : ''
                }`}
              >
                {!next && !minLowercase && (
                  <i className='lnir lnir-circle-minus' />
                )}
                {minLowercase && <i className='lnir lnir-checkmark-circle' />}
                {next && !minLowercase && (
                  <i className='lnir lnir-cross-circle' />
                )}
                {strings.userRegistrationPasswordRequermets3}
              </Col>
              <Col
                sm='6'
                className={`${next ? 'invalid' : ''} ${
                  minNumber ? 'valid' : ''
                }`}
              >
                {!next && !minNumber && (
                  <i className='lnir lnir-circle-minus' />
                )}
                {minNumber && <i className='lnir lnir-checkmark-circle' />}
                {next && !minNumber && <i className='lnir lnir-cross-circle' />}
                {strings.userRegistrationPasswordRequermets4}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

Step1.propTypes = {
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
};

export default Step1;
