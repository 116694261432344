import React from 'react';

import * as animationData from '../../../resources/animations/add-patient_accept_lottie.json';
import LottieControl from '../../add-patient/LottieControl';

export const SuccessfullCreation = () => (
  <div className='successfull-creation_Component'>
    <LottieControl animationData={animationData} height={312} width={335} />
  </div>
)
