import {
  SET_CURRENT_APPLICATION_ITEM,
  SET_CURRENT_APPLICATION_UUID,
  APPLICATIONS_LOADING,
  GET_ALL_APPLICATIONS,
  APPLICATIONS_EMPTY,
  APPLICATIONS_SUCCESS,
  APPLICATIONS_FAILURE,
  UPDATE_APPLICATIONS_FILTER_PARAMS,
  GET_APPLICATION_DATA,
  SET_APPLICATION_LOADING,
  SET_APPLICATION_ACTIVE_PAGE,
  SET_APPLICATION_DENY_REASON,
  UPDATE_APPLICATION_IN_APPLICATION_LIST,
} from '../constants';

const defaultState = {
  data: [],
  activeApplicationListItem: undefined,
  filterParams: [],
  applicationData: {},
  denyReason: '',
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_CURRENT_APPLICATION_ITEM:
      return {
        ...state,
        activeApplicationListItem: action.payload,
      };
    case SET_CURRENT_APPLICATION_UUID:
      return {
        ...state,
        currentApplicationUuid: action.payload,
      };
    case SET_APPLICATION_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case GET_ALL_APPLICATIONS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_APPLICATION_DATA:
      return {
        ...state,
        applicationData: action.payload,
      };
    case UPDATE_APPLICATION_IN_APPLICATION_LIST:
      const foundIndex = state.data.content.findIndex(
        (obj) =>
          obj.creditApplicationUuid ===
          state.activeApplicationListItem?.creditApplication
            ?.creditApplicationUuid
      );
      const newContentArray = [...state.data.content]; //make a new array
      newContentArray[foundIndex].creditApplicationStatus = action.payload; // update the status
      return {
        ...state,
        data: {
          ...state.data,
          content: newContentArray,
        },
      };
    case APPLICATIONS_EMPTY:
    case APPLICATIONS_FAILURE:
    case APPLICATIONS_SUCCESS:
    case APPLICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_APPLICATIONS_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };
    case SET_APPLICATION_LOADING:
      return {
        ...state,
        applicationLoading: action.payload,
      };
    case SET_APPLICATION_DENY_REASON:
      return {
        ...state,
        denyReason: action.payload,
      };
    default:
      return state;
  }
}
