import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { debounce } from 'lodash';
import { strings } from '../../../constants/localization';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import { getSimpleSearchClinicPaginated } from '../../../actions/companyActions';

const SearchClinic = ({
  selected,
  format,
  classAtribute,
  fieldClass,
  inputHasFocus,
  hideLabel,
  showStatus,
  selectClinic,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(
    Object.keys(selected).length > 0
      ? format
        ? `${selected.referenceNumber} - ${selected.companyName}`
        : `${selected.companyName} (${selected.referenceNumber})`
      : ''
  );
  const [loading, setLoading] = useState(false);
  const [filteredClinics, setFilteredClinics] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [hasSelectedItem, setHasSelectedItem] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [abortController, setAbortController] = useState(new AbortController());
  const debouncedSearchRef = useRef(null);

  useEffect(() => {
    // Initialize the debounced function
    // prevResults is used because the filteredClinics looses the reference when used inside the debounced function
    debouncedSearchRef.current = debounce(
      ({ updatedPageNumber, updatedSearchTerm, prevResults }) => {
        fetchPaginatedClinics(
          updatedPageNumber,
          updatedSearchTerm,
          prevResults
        );
      },
      500
    );
  }, []);

  // when the page number is updated, call the debounced function to make a new request
  useEffect(() => {
    if (pageNumber > 0) {
      debouncedSearchRef.current({
        updatedPageNumber: pageNumber,
        updatedSearchTerm: searchTerm,
        prevResults: filteredClinics,
      });
    }
  }, [pageNumber]);

  // function to fetch the company chains based on the search term when the user has stopped typing
  const fetchPaginatedClinics = (
    activePageNumber,
    activeSearchTerm,
    prevResults
  ) => {
    try {
      if (activePageNumber === 0) {
        // abort any pending requests
        if (abortController) {
          abortController.abort();
        }
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      dispatch(
        getSimpleSearchClinicPaginated(
          activePageNumber,
          activeSearchTerm,
          newAbortController.signal
        )
      ).then((response) => {
        const clinicsSuggestions = response?.payload?.data?.content;
        let newFilteredClinics = [];
        if (activePageNumber === 0) {
          newFilteredClinics = clinicsSuggestions;
        } else {
          newFilteredClinics = prevResults.concat(clinicsSuggestions);
        }

        setFilteredClinics(newFilteredClinics);

        if (clinicsSuggestions.length < 20) {
          setHasMore(false);
          setLoading(false);
        } else {
          setPageNumber(activePageNumber + 1);
          setHasMore(true);
          setLoading(true);
        }
      });
    } catch (error) {
      console.log('Error on fetching clinics', error);
    }
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
    // reset
    setSearchTerm(value);
    setPageNumber(0);
    setHasSelectedItem(false);

    // Cancel the previous debounced function
    if (debouncedSearchRef.current) {
      debouncedSearchRef.current.cancel();
    }

    // Call the debounced function
    if (value) {
      // abort any pending requests
      if (abortController) {
        abortController.abort();
      }
      setLoading(true);
      setFilteredClinics([]);
      debouncedSearchRef.current({
        updatedPageNumber: 0,
        updatedSearchTerm: value,
        prevResults: [],
      });
    } else {
      setLoading(false);
    }
  };

  const handleDropdownSelection = (selection) => {
    const text = format
      ? `${selection.referenceNumber} - ${selection.companyName}`
      : `${selection.companyName} (${selection.referenceNumber})`;
    selectClinic(selection);
    setSearchTerm(text);
    setHasSelectedItem(true);
  };

  return (
    <div className='clinic-search form-group'>
      {!hideLabel && <Label for='clinicSearch'>{strings.clinic}</Label>}
      <div
        className={
          classAtribute ? 'sendApplication-search' : 'search-input-wrapper'
        }
      >
        <Input
          type='text'
          name='clinicSearch'
          id='clinicSearch'
          className={`clinic-search-input ${fieldClass || ''}`}
          onChange={handleInputChange}
          value={searchTerm}
          placeholder={strings.filterSearchPlaceholder}
          autoFocus={inputHasFocus}
          // autoComplete='off'
        />
        {!loading ? (
          <i className='lnir lnir-search-alt' />
        ) : (
          <div className='filter-loader'>
            <ButtonLoader />
          </div>
        )}
      </div>

      {!hasSelectedItem && searchTerm !== '' && (
        <ListGroup className='suggestions'>
          {filteredClinics.map((suggestion, i) => {
            if (suggestion.status === 'DEACTIVE') {
              return null;
            }
            const className = '';
            return (
              <ListGroupItem
                className={className}
                key={i}
                onClick={() => handleDropdownSelection(suggestion)}
              >
                <div className='sugestion_refrenceid'>
                  {suggestion.referenceNumber}
                </div>
                <div className='sugestion_data'>
                  {suggestion.companyName}
                  <div className='clinic_search_address'>{`${suggestion.address}, ${suggestion.zipCode} ${suggestion.city}`}</div>
                </div>
                {showStatus && (
                  <div
                    className={`sugestion_status ${
                      suggestion.status ? '' : 'displayNone'
                    }`}
                  >
                    <span
                      className={`status-${suggestion.status} badge badge-light badge-pill`}
                    >
                      {strings[suggestion.status]}
                    </span>
                  </div>
                )}
              </ListGroupItem>
            );
          })}
          {filteredClinics.length > 0 && hasMore && (
            <ListGroupItem className='suggestions-loader'>
              <ButtonLoader />
            </ListGroupItem>
          )}
        </ListGroup>
      )}
      {filteredClinics.length === 0 &&
        searchTerm !== '' &&
        hasMore === false &&
        !loading && (
          <ListGroup className='no-suggestions'>
            <ListGroupItem>{strings.searchEmpty}</ListGroupItem>
          </ListGroup>
        )}
    </div>
  );
};

export default SearchClinic;
