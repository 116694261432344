import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './style.scss';
import { connect } from 'react-redux';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import LoadingMessage from '../statusMessages/LoadingMessage';
import { strings } from '../../constants/localization';
import StepBullets from '../common/StepBullets/StepBullets';
import {
  addNewCompanyLocation,
  getPublicCompanyCategories,
} from '../../actions/companyChainActions';

// Helper Class used to decide if the Step is valid
class ValidationAcumulator {
  constructor() {
    this.changed = false;
    this.keys = [];
    this.valid = {};
  }
  // set keys that will be monitored by the validation by the calling component
  initialize(...keys) {
    this.changed = true;
    this.keys = keys;
    this.valid = {};
    keys.forEach((key) => {
      this.valid[key] = false;
    });
  }

  hasKey(key) {
    return this.keys.find((found) => found === key);
  }

  // returns true if the validation has been initialized/reinitialzed
  isChanged() {
    return !!this.changed;
  }

  // returns true if the validation has been initialized/reinitialzed and resets the change flag
  acknowledgeChange() {
    if (this.changed) {
      this.changed = false;
      return true;
    }
    return false;
  }

  // returns the sum of the validation state as one boolean
  isValid() {
    let result = true;
    this.keys.forEach((key) => {
      if (typeof this.valid[key] === 'string') {
        return false;
      }
      result = result && this.valid[key];
    });
    return result;
  }

  // sets the validation for one given key to the coresponding value
  setValid(key, value) {
    if (this.keys.find((found) => found === key)) this.valid[key] = value;
  }
}

class AddCompanyLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentStep: 1,
      parentCvr: props.parentCvr,

      chainData: props.chainData,
      hasErrors: false,
      location: '',
      address: '',
      zipCode: '',
      city: '',
      cvr: '',
      showParentCvr: false,

      phone: '',
      email: '',
      website: '',
      allCategories: [],
      selectedCategories: [],
      hideLocation: true,
      useNemKonto: true,
      step3AnotherBank: false,
      registrationNo: '',
      accountNo: '',

      Step1Valid: new ValidationAcumulator(),
      Step2Valid: new ValidationAcumulator(),
      Step3Valid: new ValidationAcumulator(),

      Step1isValid: true,
      Step2isValid: true,
      Step3isValid: true,

      Step1Clean: true,
      Step2Clean: true,
      Step3Clean: true,
    };
  }

  // Use the submitted data to set the state
  handleChange = (event) => {
    if (this.state.Step3Valid.acknowledgeChange()) {
      this.setState({ Step3Clean: true });
    }
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    //jump focus input when is reached maxlength
    if (name === 'registrationNo' && value.length === 4) {
      document.getElementById('accountNo').focus();
    }
  };

  nextStep = () => {
    let currentStep = this.state.currentStep;
    let continueToNext = false;

    // check if the Step is valid, before continue to next
    switch (currentStep) {
      case 1: {
        continueToNext = this.state.Step1Valid.isValid();
        this.setState({
          Step1Clean: false,
          Step1isValid: continueToNext,
        });
        this.getAllPublicCompanyCategories();
        break;
      }
      case 2: {
        continueToNext = this.state.Step2Valid.isValid();
        this.setState({
          Step2Clean: false,
          Step2isValid: continueToNext,
        });
        break;
      }
      case 3: {
        continueToNext = this.state.Step3Valid.isValid();
        this.setState({
          Step3Clean: false,
          Step3isValid: continueToNext,
        });
        break;
      }
      default:
        break;
    }

    // if continue is allowed, then update the current step, otherwise mark the step us unclean (has been visited)
    if (continueToNext) {
      // if we are allowed to continue and current step is 3, then submit data
      if (currentStep === 3) {
        this.submitData();
        this.setState({
          loading: false,
          currentStep: currentStep + 1,
        });
      } else {
        currentStep = currentStep >= 3 ? 4 : currentStep + 1;
        this.setState({
          currentStep: currentStep,
        });
      }
    }
  };

  prevStep = () => {
    //reset step error message
    this.stepErrorMsg = '';
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep > 1 && currentStep < 4) {
      return (
        <Button color='blue' onClick={this.prevStep}>
          {strings.locationFlow_step2_back}
        </Button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 4, then render the "next" button
    if (currentStep < 4) {
      return (
        <Button color='blue' onClick={this.nextStep}>
          {strings.locationFlow_step2_continue}
        </Button>
      );
    }
    if (currentStep === 4) {
      return (
        <Button color='blue' onClick={this.props.close}>
          {strings.locationFlow_step4_close}
        </Button>
      );
    }
    return null;
  }

  async submitData() {
    this.setState({
      loading: true,
    });
    let mapCategories = this.state.selectedCategories.map(
      (categ) => categ.label
    );
    const data = {
      address: this.state.address,
      cardNumber: this.state.accountNo ? this.state.accountNo : null,
      chainCompanyId: this.state.chainData.id,
      city: this.state.city,
      companyName: this.state.location,
      cvr: this.state.cvr.replace(/\s+/g, ''),
      email: this.state.email,
      phone: this.state.phone.replace(/\s+/g, ''),
      publicCompanyCategories: mapCategories,
      registrationNumber: this.state.registrationNo
        ? this.state.registrationNo
        : null,
      showInIndex: this.state.hidelocation,
      sorId: '',
      website: this.state.website,
      zipCode: this.state.zipCode,
      pNumber: '',
    };

    try {
     // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.props.addNewCompanyLocation(data).then((response) => {
        that.props.update();
        if (response.payload.status === 200) {
          return response.payload.data;
        }
      });
    } catch (err) {
      console.log('errorFromCompany', err);
      // Handle Error Here
      this.setState({ loading: false });
      return err;
    }
  }

  getAllPublicCompanyCategories() {
    this.props
      .getPublicCompanyCategories()
      .then((response) => {
        if (response.payload.status === 200) {
          const cat = [];
          response.payload.data.forEach((val) => {
            cat.push({
              value: val.id,
              label: val.categoryName,
            });
          });
          this.setState({
            loading: false,
            allCategories: cat,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  renderStepErrorMsg() {
    let stepErrorMsg;
    switch (this.state.currentStep) {
      case 1:
        if (!this.state.Step1Clean && !this.state.Step1Valid.isValid()) {
          stepErrorMsg = strings.locationFlow_step1_requerdField;
        }
        break;
      case 2:
        if (!this.state.Step2Clean && !this.state.Step2Valid.isValid()) {
          stepErrorMsg = strings.locationFlow_step2_error2;
        }
        break;
      case 3:
        if (
          !this.state.Step3Valid.isChanged() &&
          !this.state.Step3Clean &&
          !this.state.Step3Valid.isValid()
        ) {
          // verify if there was a selection made
          if (this.state.Step3Valid.keys.find((found) => found === 'step3')) {
            stepErrorMsg = strings.locationFlow_step3_selectOption;
            // then check the inputs validation
          } else if (this.state.Step3Valid.keys.length > 0) {
            stepErrorMsg = strings.locationFlow_step3_enterNumber;
          }
        }
        break;
      default:
        stepErrorMsg = undefined;
        break;
    }
    return stepErrorMsg ? (
      <div className='step_error showing'>{stepErrorMsg}</div>
    ) : (
      <div className='step_error not_showing'></div>
    );
  }

  // when the user switch the selection to the first one, then we need to reset the values
  resetStep3Values = () => {
    this.setState({
      registrationNo: '',
      accountNo: '',
    });
  };

  render() {
    return (
      <div id='addCompanyLocation'>
        {this.state.loading && <LoadingMessage />}
        {!this.state.loading && (
          <div className='content'>
            <Step1
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              location={this.state.location}
              address={this.state.address}
              zipCode={this.state.zipCode}
              city={this.state.city}
              cvr={this.state.cvr}
              parentCvr={this.state.parentCvr}
              hasErrors={this.state.hasErrors}
              validator={this.state.Step1Valid}
              cleanStep={this.state.Step1Clean}
              stepIsValid={this.state.Step1isValid}
            />
            <Step2
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              phone={this.state.phone}
              email={this.state.email}
              website={this.state.website}
              chainBrand={this.props.chainData.brand}
              allCategories={this.state.allCategories}
              selectedCategories={this.state.selectedCategories}
              hideLocation={this.state.hideLocation}
              validator={this.state.Step2Valid}
              cleanStep={this.state.Step2Clean}
              stepIsValid={this.state.Step2isValid}
            />
            <Step3
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              cvr={this.state.cvr}
              useNemKonto={this.state.useNemKonto}
              registrationNo={this.state.registrationNo}
              accountNo={this.state.accountNo}
              chainBrand={this.props.chainData.brand}
              step3AnotherBank={this.state.step3AnotherBank}
              validator={this.state.Step3Valid}
              cleanStep={this.state.Step3Clean}
              resetStep3Values={this.resetStep3Values}
              stepIsValid={this.state.Step3isValid}
            />
            <Step4
              currentStep={this.state.currentStep}
              location={this.state.location}
              address={this.state.address}
              zipCode={this.state.zipCode}
              city={this.state.city}
              cvr={this.state.cvr}
              phone={this.state.phone}
              email={this.state.email}
              website={this.state.website}
              hideLocation={this.state.hideLocation}
              handleChange={this.handleChange}
              registrationNo={this.state.registrationNo}
              accountNo={this.state.accountNo}
              close={this.props.close}
              createNewUserHandler={this.props.createNewUserHandler}
            />

            <div className='controls'>
              {this.renderStepErrorMsg()}
              <div className='actions-section'>
                {this.previousButton()}
                {this.nextButton()}
              </div>
              <StepBullets maxSteps={4} step={this.state.currentStep} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, {
  addNewCompanyLocation,
  getPublicCompanyCategories,
})(AddCompanyLocation);
