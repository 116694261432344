import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const KebabMenu = ({ children, menuPlacement, scrollToBottom }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggle = () => {
    setOpen(!open);
    scrollToBottom?.();
  };

  return (
    <div ref={wrapperRef} className='KebabMenu_Component' onClick={toggle}>
      <div className='menu-container'>
        <div className='menu-dots'>
          <i className='lnir lnir-more-alt' />
        </div>
        <div
          className={`menu-items ${
            menuPlacement === 'top' ? 'on-top' : 'on-bottom'
          } ${open ? 'open' : 'close'}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

KebabMenu.propTypes = {
  children: PropTypes.node,
};

export default KebabMenu;
