import React from 'react';

import { strings } from '../../../constants/localization';

export default function SupportNoteDisplay({ supportEntries }) {
  return (
    <div className='support-note-display'>
      <h1 className='support-note-title'>
        {strings.documentationSupportNoteTitle}
      </h1>
      {supportEntries.map((entry, index) => (
        <div className='note-card mb-2' key={index}>
          <div className='support-note-content'>{entry?.supportNote}</div>
          <div className='support-info'>
            {entry?.timestamp}
            <p className='support-user'>{entry?.user}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
