import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button } from 'reactstrap';
import { connect } from 'react-redux';
import {
  toggleServerModalError,
  setServerModalError,
} from '../../actions/uiElementsActions';
import DentiModal from './DentiModal';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import brokenRobot from '../../resources/images/broken-robot.png';
import { moment } from '../../constants';

class ServerModalError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      activePanel: false,
      serverRebootingModal: false,
    };

    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.toggleServerRebootingModal =
      this.toggleServerRebootingModal.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
  }

  componentDidMount() {
    const serverErrorStored = this.props.serverErrorDetails;
    const { message, cause } = serverErrorStored.data || {};
    //f we receive 500 with "EntityFactoryManager is closed" or 502, then show this rebooting modal
    const serverIsDown =
      typeof serverErrorStored !== 'object' ||
      serverErrorStored === 'Network Error';
    if (
      serverIsDown ||
      (serverErrorStored?.status === 500 &&
        (message?.includes('EntityManagerFactory is closed') ||
          cause?.includes('EntityManagerFactory is closed')))
    ) {
      this.setState({
        serverRebootingModal: true,
      });
    }
  }

  toggleErrorModal() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
    this.props.toggleServerModalError(false);
  }

  toggleServerRebootingModal() {
    this.setState({
      serverRebootingModal: false,
      modal: false,
    });
    this.props.toggleServerModalError(false);
    this.props.setServerModalError(null);
  }

  togglePanel() {
    this.setState({
      activePanel: !this.state.activePanel,
    });
  }

  render() {
    const phoneNumber =
    strings.getLanguage() === 'en'
      ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
      : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');
    const serverErrorDetails = this.props.serverErrorDetails;
    const serverIsDown =
      typeof serverErrorDetails !== 'object' ||
      serverErrorDetails === 'Network Error';
    if (this.state.serverRebootingModal) {
      return (
        <DentiModal
          close={() => this.toggleServerRebootingModal()}
          modalClass='notification-modal'
          modalClassName='confirm-action'
          closeBtn={false}
        >
          <div>
            <div className='icon'>
              <i className='lnil lnil-reload' />
            </div>
            <div>
              <h3 className='headline'>{strings.serverRebootingHeadline}</h3>
              <p className='notification-message'>
                {strings.serverRebootingText}
              </p>
            </div>
            <div className='bottom-section action-btn cf'>
              <button
                className='submit-btn btn btn-blue center-block'
                type='button'
                onClick={() => this.toggleServerRebootingModal()}
              >
                <span>{strings.serverRebootingButton}</span>
              </button>
            </div>
          </div>
        </DentiModal>
      );
    } else if (this.state.modal) {
      return (
        <DentiModal
          close={this.toggleErrorModal}
          modalClassName='server-error'
          modalClass='server-error-modal modal-dialog-centered'
        >
          <Card>
            <CardBody>
              <div className='something-went-wrong'>
                <h1 className='title'>
                  {serverIsDown
                    ? strings.errorServerDownTitle
                    : strings.errorServerTitle}
                </h1>
                <div className='error-info'>
                  <p>
                    {serverIsDown
                      ? strings.errorServerDownText1
                      : strings.errorServerText1}
                  </p>
                  <p>
                    {serverIsDown
                      ? strings.errorServerDownText2
                      : strings.errorServerText2}
                  </p>
                </div>
                <div className='error-image-container'>
                  <img
                    src={brokenRobot}
                    alt='broken-robot'
                    className='image-broken-robot'
                  />
                </div>
                <div className='visit-action-buttons'>
                  <Button color='blue' tag='a' href={`tel:${phoneNumber}`}>
                    {strings.formatString(
                      strings.declineCallText,
                      '',
                      phoneNumber
                    )}
                  </Button>
                  <Button
                    color='blue'
                    tag='a'
                    href={`mailto:${brandConfig.contactEmail}`}
                  >
                    {strings.declineEmailText}
                  </Button>

                  {/* More tehnical information section */}
                  {!serverIsDown && (
                    <>
                      <div
                        className='accordion-header'
                        onClick={this.togglePanel}
                      >
                        {strings.showMoreTehnicalInformation}
                        <i className='lnil lnil-chevron-down'></i>
                      </div>
                      <div
                        className={`panel ${
                          this.state.activePanel ? 'active' : ''
                        } `}
                      >
                        <div>
                          <b>{strings.httpText} </b>
                          {serverErrorDetails && serverErrorDetails?.status
                            ? serverErrorDetails.status
                            : ''}
                        </div>

                        <div>
                          <b>{strings.httpMessage} </b>
                          {this.props.serverErrorDetails &&
                          this.props.serverErrorDetails?.data.cause
                            ? this.props.serverErrorDetails?.data.cause
                            : ''}
                          {this.props.serverErrorDetails &&
                          this.props.serverErrorDetails?.data.message &&
                          !this.props.serverErrorDetails?.data.cause
                            ? this.props.serverErrorDetails?.data.message
                            : ''}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className='timestamp'>
                  {moment().format('DD-MM-YYYY  HH:mm:ss')}
                </div>
              </div>
            </CardBody>
          </Card>
        </DentiModal>
      );
    } else {
      return null;
    }
  }
}

ServerModalError.propTypes = {
  toggleServerModalError: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    serverErrorDetails: state.ui.serverErrorDetails,
  };
}

export default connect(mapStateToProps, {
  toggleServerModalError,
  setServerModalError,
})(ServerModalError);
