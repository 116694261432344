import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Button } from 'reactstrap';
import { userLogout, setAuthUser } from '../../actions/userActions';
import { ROUTE_LOGIN } from '../../constants/routes';
import DentiModal from '../common/DentiModal';
import { strings } from '../../constants/localization';
import {
  timeoutMinutesSessionExpiration,
  timeoutMinutesSessionWarningShow,
} from '../../config';
import { isAuthenticated } from '../../constants/utils';

const SessionTimeout = () => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const activeLeadsOnExpiredSession = useSelector(
    (state) => state.lead?.activeLead
  );
  const activeCompaniesOnExpiredSession = useSelector(
    (state) => state.company?.activeCompany
  );
  const activeChainOnExpiredSession = useSelector(
    (state) => state.companyChain?.activeChain
  );
  const activeCreditApplicationsOnExpiredSession = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const activeDocumentationOnExpiredSession = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );

  let warningSessionExpiredInterval = useRef();
  let timeoutAfterClearingStorage = useRef();

  useEffect(() => {
    //check on every minute if the session is expired
    warningSessionExpiredInterval.current = setInterval(() => {
      // check is session expired only if not already showing the warning
      if (!state.showWarning || !state.isSessionExpired) {
        const now = moment(new Date());
        const lastLoginTimestamp = localStorage.getItem('loginTime');
        const lastLoginDate = moment.unix(lastLoginTimestamp / 1000);
        const diff = moment.duration(now.diff(lastLoginDate));
        const minPast =
          diff.days() * 24 * 60 + diff.hours() * 60 + diff.minutes();

        const minutesBeforeExpiration =
          timeoutMinutesSessionExpiration - minPast;

        // show the warning when the session is about to expire in 5 minutes
        if (
          minutesBeforeExpiration <= timeoutMinutesSessionWarningShow &&
          minutesBeforeExpiration > 0
        ) {
          storeSession();
          setState({
            showWarning: true,
            isSessionExpired: false,
          });
        } else if (minutesBeforeExpiration < 0) {
          setState({
            showWarning: true,
            isSessionExpired: true,
          });
        }
      }
    }, 60 * 1000); // 60 seconds * 1000 milliseconds

    return () => {
      clearTimeout(timeoutAfterClearingStorage.current);
      clearInterval(warningSessionExpiredInterval.current);
      setState({});
    };
  }, []);

  useEffect(() => {
    // make sure to keep the isAuth flag in sync for the redux store
    if (isAuthenticated()) {
      dispatch(setAuthUser());
    } else {
      history.push(ROUTE_LOGIN);
    }

    // listen for storage events to check if another tab has logged in
    window.addEventListener('storage', function (event) {
      switch (event.key) {
        case 'loginTime':
          if (event.newValue && event.newValue !== event.oldValue) {
            localStorage.setItem('loginTime', event.newValue);
          }
          break;
        case 'isAuth':
          if (event.newValue && event.newValue !== event.oldValue) {
            localStorage.setItem('isAuth', event.newValue);
            // make sure the user auth is saved to the redux store as well
            if (JSON.parse(event.newValue) === true) {
              dispatch(setAuthUser(true));
            }
          }
          break;
        case 'isSessionExpiredModalDismissed':
          if (event.newValue && event.newValue !== event.oldValue) {
            localStorage.setItem(
              'isSessionExpiredModalDismissed',
              event.newValue
            );
          }
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    // Check if the modal has been dismissed in any tab
    const handleVisibilityChange = () => {
      const isModalDismissed = JSON.parse(
        localStorage.getItem('isSessionExpiredModalDismissed')
      );
      if (!document.hidden && state.showWarning && isModalDismissed) {
        const now = moment.now();
        const isAuth = JSON.parse(localStorage.getItem('isAuth'));
        const loginTime = JSON.parse(localStorage.getItem('loginTime'));
        const isAuthenticated =
          isAuth === true &&
          loginTime &&
          now - loginTime < timeoutMinutesSessionExpiration * 60 * 1000;
        // check if the modal has been dismissed in another tab
        if (isAuthenticated) {
          setState({
            showWarning: false,
            isSessionExpired: false,
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [state.showWarning]);

  const storeSession = useCallback(() => {
    const locationPathName = history.location.pathname;
    switch (locationPathName) {
      case '/leads':
        if (activeLeadsOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeLeadsOnExpiredSession?.leadClientInformation?.cpr}`
          );
        }
        break;
      case '/companies':
        if (activeCompaniesOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeCompaniesOnExpiredSession?.referenceNumber}`
          );
        }
        break;
      case '/companyChains':
        if (activeChainOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeChainOnExpiredSession?.cvrNumber}`
          );
        }
        break;
      case '/creditApplications':
        if (activeCreditApplicationsOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeCreditApplicationsOnExpiredSession?.creditApplication?.creditApplicationUuid}`
          );
        }
        break;
      case '/documentation':
        if (
          activeDocumentationOnExpiredSession.documents?.length > 0 &&
          activeDocumentationOnExpiredSession.documents[0]?.documentationUuid
        ) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeDocumentationOnExpiredSession?.documents[0]?.documentationUuid}`
          );
        }
        break;
      default:
        sessionStorage.setItem('lastVisitedPath', `${locationPathName}`);
        break;
    }
  }, [
    activeLeadsOnExpiredSession,
    activeCompaniesOnExpiredSession,
    activeCreditApplicationsOnExpiredSession,
    activeDocumentationOnExpiredSession,
    history.location.pathname,
    state,
  ]);

  const handleClose = () => {
    // Set a flag in localStorage indicating that the modal has been dismissed
    localStorage.setItem('isSessionExpiredModalDismissed', 'true');
    setState({
      showWarning: false,
    });

    if (!state.isSessionExpired) {
      // is not expired, logout in order to be able to login again
      dispatch(userLogout());
      history.push(ROUTE_LOGIN);
    } else {
      clearInterval(warningSessionExpiredInterval.current);
      dispatch(setAuthUser(false));
      history.push(ROUTE_LOGIN);
    }
  };

  if (state.showWarning) {
    return (
      <DentiModal
        close={() => handleClose()}
        modalClassName='server-error'
        modalClass='warning-modal modal-dialog-centered'
      >
        <div>
          <div className='icon'>
            <i className='lnil lnil-warning' />
          </div>
          <div>
            <h3 className='headline'>{strings.sessionExpiredHeadline}</h3>
            <p className='warning-message'>{strings.sessionExpiredText}</p>
          </div>
          <div className='bottom-section action-btn cf'>
            <Button
              onClick={() => handleClose()}
              className='login-button btn btn-blue'
            >
              {strings.goToLogin}
            </Button>
          </div>
        </div>
      </DentiModal>
    );
  } else {
    return null;
  }
};

export default SessionTimeout;
