import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { strings } from '../../../constants/localization';

import { getLogsforCompanies } from '../../../actions/logActions';

import EntityInformationTabs from '../../common/EntityInformationTabs';
import CompanyLogDetails from './CompanyLogDetails';

const CompanyOptions = (props) => {
  const { referenceNumber, activeChain } = props;

  const [logsForCompany, setLogsForCompany] = useState([]);

  const getlogs = useCallback(() => {
    getLogsforCompanies(referenceNumber, activeChain.id);
  }, [referenceNumber, activeChain.id]);

  useEffect(() => {
    setLogsForCompany(getLogsforCompanies);
  }, [getlogs]);

  return (
    <EntityInformationTabs
      referenceNumber={logsForCompany}
      atherClass='forChain'
      tabsContent={[
        {
          name: strings.logTab,
          getContent: (activeTab) => (
            <CompanyLogDetails
              activeTab={activeTab}
              activeChain={activeChain}
            />
          ),
          show: true,
        },
      ]}
    />
  );
};

CompanyOptions.propTypes = {
  referenceNumber: PropTypes.number,
  activeChain: PropTypes.object,
};

export default connect(null, {
  getLogsforCompanies,
})(CompanyOptions);
