import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { strings } from '../../../../constants/localization';
import {
  currencyFormatDA,
  formatInterestRate,
} from '../../../../constants/utils';
import { moment, momentFormatLong } from '../../../../constants';
import RestructureForm from './RestructureForm';

const LoanRestructure = (props) => {
  const [loans, setLoans] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    if (
      props.instalmentLoans !== undefined &&
      props.instalmentLoans.length > 0
    ) {
      setLoans(props.instalmentLoans);
      const sumBalance = props.instalmentLoans.reduce(
        (sum, field) => sum + (field.remainingBalance || 0),
        0
      );
      setTotalBalance(sumBalance);
    }
  }, [props.instalmentLoans]);

  if (loans !== undefined) {
    return (
      <Card className='custom-card logs table-responsive-sm'>
        <CardBody>
          <div className='header-information'>
            <CardTitle>{strings.restTitle}</CardTitle>
          </div>
          <div className='loan-invoices'>
            <div className='list-installment-loans'>
              <div className='list-header-cell'>{strings.restDate}</div>
              <div className='list-header-cell'>{strings.restReference}</div>
              <div className='list-header-cell'>{strings.restInterest}</div>
              <div className='list-header-cell'>{strings.restDuration}</div>
              <div className='list-header-cell text-right'>
                {strings.restAmount}
              </div>
              <div className='list-header-cell text-right'>
                {strings.restBalance}
              </div>
              {loans.map((loan) => (
                <Fragment key={loan.number}>
                  <div className='list-entry-cell'>
                    {moment(loan.startDate)
                      .format(momentFormatLong)
                      .toLowerCase()}
                  </div>
                  <div className='list-entry-cell'>{loan.invoiceNumber}</div>
                  <div className='list-entry-cell'>
                    {formatInterestRate(loan.interestRate)}
                  </div>
                  <div className='list-entry-cell'>
                    {strings.formatString(strings.restMonths, loan.duration)}
                  </div>
                  <div className='list-entry-cell text-right'>
                    {currencyFormatDA(loan.amount, true, true)}
                  </div>
                  <div className='list-entry-cell text-right'>
                    {currencyFormatDA(loan.remainingBalance, true, true)}
                  </div>
                </Fragment>
              ))}
            </div>
            <Row className='total-balance'>
              <Col xs='12' md='10' className='text-right text-uppercase py-3'>
                {strings.formatString(
                  strings.restTotalRemBalance,
                  moment().format(momentFormatLong)
                )}
              </Col>
              <Col
                xs='12'
                md='2'
                className='text-right py-3 total-balance-value'
              >
                {currencyFormatDA(totalBalance, true, true)}
              </Col>
            </Row>
            <RestructureForm
              totalBalance={totalBalance}
              setActiveTab={props.setActiveTab}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
  return <div />;
};
function mapStateToProps(state) {
  return {
    instalmentLoans: state.lead.leadLoans,
    activeLead: state.lead.activeLead,
  };
}

export default connect(mapStateToProps)(LoanRestructure);
