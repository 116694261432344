import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody } from 'reactstrap';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { storeOnboardingData } from '../../actions/onboardingActions';

const HowTheApplicationWorks = ({ setNextStep, step, previousStep }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [goBackButtonLoading, setGoBackButtonLoading] = useState(false);

  // add handler to go to next step on Enter key pressed
  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingConsentTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/beskrivelse-ansoegningsproces',
        stepTitle: 'onboarding - beskrivelse ansoegningsproces',
        event: 'onboarding',
      });
    }
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handleSubmit = () => {
    setButtonLoading(true);
    dispatch(storeOnboardingData({ ...savedData, autorized: true }));
    setNextStep(step + 1);
  };

  return (
    <CardBody className='step-content'>
      <div className='step-title mt-4'>{strings.onboardingConsentTitle}</div>
      <section>
        <h3 className='bold mb-2 p-0 existingMemberTitle '>
          {strings.onboardingConsentDataTitle}
        </h3>
        <p className='existingMemberSubtitle'>
          {strings.onboardingConsentDataText}
        </p>
        <p className='existingMemberSubtitle'>
          {strings.onboardingConsentDataText2}
        </p>
      </section>
      <section>
        <h3 className='bold mb-2 p-0 existingMemberTitle mt-0'>
          {strings.onboardingConsentHandlingDataTitle}
        </h3>
        <p className='contactForQuestions'>
          {strings.formatString(
            strings.onboardingConsentHandlingDataText,
            <a
              className='color'
              href={`${brandConfig.privacyPolicy}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {strings.onboardingConsentHandlingDataLink}
            </a>
          )}
        </p>
      </section>

      <div className='actions-buttons step-buttons'>
        <Button
          className='btn btn-blue btn-cancel goBackButton'
          disabled={goBackButtonLoading}
          onClick={() => {
            setGoBackButtonLoading(true);
            previousStep();
          }}
        >
          {goBackButtonLoading ? <ButtonLoader /> : strings.onboardingGoBack}
        </Button>
        <Button
          className='btn btn-blue btn-success'
          onClick={() => handleSubmit()}
          disabled={buttonLoading}
        >
          {buttonLoading ? <ButtonLoader /> : strings.onboardingContinue}
        </Button>
      </div>
    </CardBody>
  );
};

export default HowTheApplicationWorks;
