import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonLoader from './ButtonLoader/ButtonLoader';

class ExportConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.confirmAction = this.props.confirmAction;
    this.cancelAction = this.props.cancelAction;
    this.loading = this.props.loading;
  }

  render() {
    return (
      <div className='status-modal'>
        {this.props.children}
        <div className='bottom-section action-btn cf'>
          <button
            className='submit-btn btn btn-blue center-block'
            type='button'
            onClick={() => {
              this.confirmAction();
              this.loading = true;
            }}
            disabled={!!this.loading}
          >
            {!this.loading ? (
              <>
                <i className='di di-accept' />
                <span>{this.props.confirmText}</span>
              </>
            ) : (
              <ButtonLoader />
            )}
          </button>

          <button
            className='cancel-btn btn btn-light center-block'
            type='button'
            onClick={this.cancelAction}
          >
            <i className='di di-cancel' />
            <span>{this.props.cancelText}</span>
          </button>
        </div>
      </div>
    );
  }
}

ExportConfirmationModal.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default ExportConfirmationModal;
