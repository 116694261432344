import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import DocumentPreview from './DocumentPreview';
import { strings } from '../../../constants/localization';
import { denyDocumentation } from '../../../actions/onboardingDocumentationActions';
import { moment } from '../../../constants';
import { getDocumentsSupportNotesEntries } from '../../../constants/utils';
// import moment from 'moment';
import DentiModal from '../../common/DentiModal';
import { Fragment } from 'react';
import Fade from '../../common/Fade';
import DocumentClientNote from './DocumentClientNote';
import ModalSupportNote from './ModalSupportNote';
import SupportNoteDisplay from './SupportNoteDisplay';

const DocumentsDisplay = ({ status, setStatus }) => {
  const dispatch = useDispatch();
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );

  const loggedUser = useSelector((state) => state.user?.user);

  const { cpr, supportNote, documents, type } = activeDocListItem || {};

  const supportEntries = getDocumentsSupportNotesEntries(supportNote) || [];

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [documentClientNoteIsOpen, setDocumentClientNoteIsOpen] =
    useState(false);
  const [modalCanNotBeClosed, setModalCanNotBeClosed] = useState(false);

  const [loading, setLoading] = useState(false);

  const [clientNote, setClientNote] = useState('');

  useEffect(() => {
    if (activeDocListItem) {
      setClientNote(activeDocListItem.clientNote);
    }
  }, [activeDocListItem]);

  const handleSuportNoteChange = (newNoteEntry) => {
    const { firstName, lastName } = loggedUser || {};
    const userDetails = `(${firstName} ${lastName})`;
    const dateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const newEntry = `${dateNow}${userDetails}: ${newNoteEntry} ---`;
    let newSupportNote = '';
    if (supportNote === null) {
      newSupportNote = newEntry;
    } else {
      newSupportNote = `${supportNote} ${newEntry}`;
    }

    return newSupportNote;
  };

  const handleButtonSubmit = (newNoteEntry) => {
    setLoading(true);

    const newSuportNote = handleSuportNoteChange(newNoteEntry);

    dispatch(denyDocumentation(null, cpr, type, newSuportNote, true)).then(
      (res) => {
        if (res.status === 200) {
          setLoading(false);
          setStatus('REJECTED');
          setModalIsOpen(false);
        }
      }
    );
  };

  const toggleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const toggleDocumentClientNoteModal = () => {
    setDocumentClientNoteIsOpen(!documentClientNoteIsOpen);
  };

  return (
    <div id='documentsTypeList' className='entity-status-card custom-card card'>
      <div className='wrapper'>
        <div className='descriptionWraper'>
          <div className='iconArea'>
            <i className='lnir lnir-empty-file'></i>
          </div>
          <div className='titleArea'>
            {strings.documentationDocumentsUploaded}
          </div>
        </div>

        <Row className='w-100'>
          {documents?.length > 0 &&
            documents.map((doc, index) => {
              if (doc.fileName !== 'no-file') {
                return (
                  <Row key={index}>
                    <Col xs='6' md='4' lg='3'>
                      <div className=''>
                        <DocumentPreview
                          docURL={doc?.previewLink}
                          documentFileName={doc?.fileName}
                          thumbnailLink={doc?.thumbnailLink}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              } else {
                return null; // Or any other element to render for excluded documents
              }
            })}

          {clientNote !== null && clientNote !== '' && (
            <Col xs='6' md='4' lg='3'>
              <Fade show='true'>
                <section
                  className='small-card ml-0'
                  onClick={toggleDocumentClientNoteModal}
                >
                  <Row className='detailsArea'>
                    <Col xs='12' lg='12'>
                      <div className='textarea-wrapper'>
                        <div className='mt-4' aria-hidden='true'>
                          <div className=''>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                              <Fragment key={index}>
                                <div
                                  className={`skeleton-row ${
                                    index === 8
                                      ? 'col-2'
                                      : index < 3
                                      ? 'col-7'
                                      : ''
                                  }`}
                                ></div>
                                {index === 2 && <br />}
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className='client-note'>
                    {strings.documentationClientNote}
                  </div>
                </section>
              </Fade>
            </Col>
          )}
        </Row>
        {documentClientNoteIsOpen && (
          <DentiModal
            key={1}
            close={toggleDocumentClientNoteModal}
            modalClass='cliente-note-modal'
            modalClassName='confirm-action'
            closeBtn={true}
          >
            <DocumentClientNote
              close={toggleDocumentClientNoteModal}
              clientNote={clientNote || strings.documentationClientNoteDefault}
            />
          </DentiModal>
        )}

        {supportEntries.length > 0 && (
          <SupportNoteDisplay supportEntries={supportEntries} />
        )}

        <div className='text-center mt-4'>
          {status === 'PENDING' && (
            <Button
              className='btn btn-blue btn-cancel'
              onClick={toggleModalIsOpen}
            >
              <i className='lnil lnil-envelope'></i>
              <span className='center_Text'>
                {strings.documentationRequestDocumentation}
              </span>
            </Button>
          )}
          {modalIsOpen && (
            <DentiModal
              key={1}
              close={toggleModalIsOpen}
              modalClass='documentation-note-modal'
              modalClassName='confirm-action'
              closeBtn={true}
            >
              <ModalSupportNote
                setModaIsOpen={setModalIsOpen}
                modalCanNotBeClosed={modalCanNotBeClosed}
                setModalCanNotBeClosed={setModalCanNotBeClosed}
                modalClass='document-note-section'
                handleButtonSubmit={handleButtonSubmit}
                loading={loading}
              />
            </DentiModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsDisplay;
