import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';

const CancelEditNote = ({ noAction, yesAction }) => {
  return (
    <DentiModal
      key={1}
      modalClass='onboarding upload-doc-modal'
      modalClassName='upload-modal'
      close={() => noAction()}
      closeBtn={true}
    >
      <div className='cancel-upload-modal d-flex align-items-center justify-content-center'>
        <div className='px-2'>
          <h3 className='headline'>
            {strings.onboardingEditNoteCancelHeadline}
          </h3>
          <div className='subtitle mt-5 mb-5'>
            {strings.onboardingEditNoteCancelText}
          </div>
        </div>

        <div className='actions-buttons'>
          <Button
            className='btn btn-blue btn-cancel'
            onClick={() => noAction()}
          >
            {strings.onboardingUploadCancelNo}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => yesAction()}
          >
            {strings.onboardingUploadCancelYes}
          </Button>
        </div>
      </div>
    </DentiModal>
  );
};

export default CancelEditNote;
